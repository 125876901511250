import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IBaseTableHeadCellProps {
  children?: React.ReactNode;
  colspan?: number;
  rowspan?: number;
  className?: string;
}

const BaseTableHeadCell: React.FC<IBaseTableHeadCellProps> = ({ children, colspan, rowspan, className }) => {
  return (
    <th className={cx(className, { cell: true })} colSpan={colspan} rowSpan={rowspan}>
      {children}
    </th>
  );
};

export default BaseTableHeadCell;
