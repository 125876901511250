import { House } from "./house";
import { Form, FormFiled, FormValidator, Dictionary } from "./";

export namespace Documents {
  export type Directory = {
    Id: number;
    Name: string;
    FilesCount: number;
    Order: number;
  };

  export class DirectoryWithFiles {
    directory: Directory;
    documents: UsefulDocument[];
    isFetched: boolean = false;

    constructor(directory: Directory) {
      this.directory = { ...directory };
      this.documents = [];
    }
  }

  export class EditDirectoryForm extends Form {
    Name: FormFiled = new FormFiled();

    constructor(dir?: Directory) {
      super();
      this.Name = new FormFiled(dir?.Name ?? "", true, false, 28);
    }
  }

  export class EditDirectoryFormValidator {
    public static isInvalid(form: EditDirectoryForm) {
      const errors = {
        Title: FormValidator.getFieldErrorMessage(form.Name.value.trim(), form.Name.isRequired),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export type UsefulDocument = Omit<Dictionary.File, "DocumentId" | "URL"> & {
    DirectoryId: number;
    Path: string;
    HouseIds: Array<House.Item["Id"]>;
  };

  export type UploadFileRequestBody = {
    Attachment: File;
    Houses: Array<House.Item["Id"]>;
  };

  export type UpdateDocumentRequestBody = {
    Name?: string;
    Houses?: Array<House.Item["Id"]>;
  };

  export class EditDocumentForm extends Form {
    Name: FormFiled = new FormFiled();
    Houses: Array<House.Item["Id"]> = [];

    constructor(document?: UsefulDocument) {
      super();
      this.Name = new FormFiled(document?.Name ?? "", false, false, 255);
      this.Houses = document?.HouseIds ?? [];
    }
  }

  export class EditDocumentFormValidator {
    public static isInvalid(form: EditDocumentForm) {
      const errors = {
        Title: FormValidator.getFieldErrorMessage(form.Name.value.trim(), form.Name.isRequired),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }
}
