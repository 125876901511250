import { SxProps } from "@mui/material";
import InputMask from "react-input-mask";
import classNames from "classnames/bind";
import { lockedIcon } from "@/consts/icons";
import BaseLabel from "../BaseLabel";
import StyledInput from "./styled-input";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IPhoneInputProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  errorMessage?: string;
  mask?: string;
  className?: string;
  sx?: SxProps;
  maskPlaceholder?: string | null | undefined;
  endAdornment?: React.ReactNode;
  name?: string;
  locked?: boolean;
}

const PhoneInput = ({
  value,
  onChange,
  label,
  type,
  disabled,
  required,
  placeholder,
  errorMessage,
  mask = "+7 (999) 999-99-99",
  className,
  sx,
  maskPlaceholder,
  endAdornment,
  name,
  locked = false,
}: IPhoneInputProps) => {
  return (
    <div data-error={errorMessage} className={cx(className, { container: true, has_error: !!errorMessage, locked })}>
      {label && <BaseLabel required={required}>{label}</BaseLabel>}

      <InputMask
        mask={mask}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.value.replace(/[^0-9+]/g, ""));
        }}
        maskPlaceholder={maskPlaceholder}
      >
        <div className={styles.field}>
          <StyledInput
            sx={sx}
            placeholder={placeholder}
            error={!!errorMessage}
            type={type ?? "text"}
            className={styles.input}
            name={name}
            readOnly={locked}
            disabled={disabled}
            fullWidth
            endAdornment={endAdornment}
          />
          {locked && <span className={styles.endAdornment}>{lockedIcon}</span>}
        </div>
      </InputMask>
    </div>
  );
};

export default PhoneInput;
