import { Pagination } from "@/types";
import { HouseUsers } from "@/types/house-users";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface HouseUsersState {
  isLoading: boolean;
  query: string;
  queryType: HouseUsers.QueryType;
  filters: {
    Esia: boolean | null;
    CanOccVote: boolean | null;
    Registry: boolean | null;
    Ownership: string | null;
  };
  statuses: HouseUsers.Status[];
  users: HouseUsers.User[];
  pagination: Pagination | null;
  userDetailsModal: {
    user: HouseUsers.User | null;
    isOpen: boolean;
  };
}

const houseUsersSlice = createSlice({
  name: "house-users",

  initialState: {
    isLoading: false,
    query: "",
    queryType: HouseUsers.QueryType.Fio,
    filters: {
      Esia: null,
      CanOccVote: null,
      Registry: null,
      Ownership: null,
    },
    statuses: [],
    users: [],
    pagination: null,
    userDetailsModal: {
      user: null,
      isOpen: false,
    },
  } as HouseUsersState,

  reducers: {
    toggleLoading: (state, action: PayloadAction<HouseUsersState["isLoading"]>) => {
      state.isLoading = action.payload;
    },

    setQuery: (state, action: PayloadAction<HouseUsersState["query"]>) => {
      state.query = action.payload;
    },

    setQueryType: (state, action: PayloadAction<HouseUsersState["queryType"]>) => {
      state.queryType = action.payload;
    },

    setFilters: (state, action: PayloadAction<HouseUsersState["filters"]>) => {
      state.filters = action.payload;
    },

    setStatuses: (state, action: PayloadAction<HouseUsersState["statuses"]>) => {
      state.statuses = action.payload;
    },

    setUsers: (state, action: PayloadAction<HouseUsersState["users"]>) => {
      state.users = action.payload;
    },

    pushUsers: (state, action: PayloadAction<HouseUsersState["users"]>) => {
      state.users.push(...action.payload);
    },

    setPagination: (state, action: PayloadAction<HouseUsersState["pagination"]>) => {
      state.pagination = action.payload;
    },

    updateUserRole: (
      state,
      action: PayloadAction<{ userId: HouseUsers.User["UserId"]; role: HouseUsers.User["Role"] }>
    ) => {
      const updated = state.users.map((it: HouseUsers.User) =>
        it.UserId === action.payload.userId ? { ...it, Role: action.payload.role } : it
      );
      state.users = updated;

      if (state.userDetailsModal.user?.UserId === action.payload.userId) {
        state.userDetailsModal.user = { ...state.userDetailsModal.user, Role: action.payload.role };
      }
    },

    toggleUserDetailsModal: (
      state,
      action: PayloadAction<{
        isOpen: HouseUsersState["userDetailsModal"]["isOpen"];
        user?: HouseUsersState["userDetailsModal"]["user"];
      }>
    ) => {
      if (action.payload.isOpen && action.payload.user) {
        state.userDetailsModal.isOpen = action.payload.isOpen;
        state.userDetailsModal.user = action.payload.user;
        return;
      }

      state.userDetailsModal.isOpen = false;
    },
  },
});

export default houseUsersSlice.reducer;
export * from "./actions";
export const {
  toggleLoading,
  setQuery,
  setQueryType,
  setFilters,
  setStatuses,
  setUsers,
  pushUsers,
  setPagination,
  updateUserRole,
  toggleUserDetailsModal,
} = houseUsersSlice.actions;
