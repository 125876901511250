import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Profile, SntPassportAnalytics } from "@/types";
import { SntPassportService } from "@/services/v2";
import { RootState } from "@/app/store";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import BaseButton from "@/components/base-button";
import AnalyticsBuilder from "../Builder";
import styles from "../styles.module.scss";

const InstitutionAnalyticsInfrastructure: React.FC = () => {
  const institution = useSelector((state: RootState) => state.user.institution);

  const [district, setDistrict] = useState<Profile.MunicipalDistrict | null>(null);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [count, setCount] = useState<number>();
  const [statistics, setStatistics] = useState<SntPassportAnalytics.AnalyticsData>();

  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    let years = [];
    for (let i = currentYear; i >= 2024; i--) {
      years.push(i);
    }
    return years;
  }, []);

  async function applyFilters(isReset?: boolean) {
    setIsLoading(true);

    try {
      const resp = await Promise.all([
        SntPassportService.getSntCount(!isReset ? district?.Name : undefined),
        SntPassportService.getInfrastructureStatistics({
          MunicipalDistrict: !isReset ? district?.Name : undefined,
          Year: !isReset ? year : new Date().getFullYear(),
        }),
      ]);

      setCount(resp[0].data.Data.Count);
      setStatistics(resp[1].data.Data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function resetFilters() {
    setDistrict(null);
    setYear(new Date().getFullYear());

    applyFilters(true);
  }

  async function fetchBoardData() {
    try {
      const resp = await Promise.all([
        SntPassportService.getSntCount(),
        SntPassportService.getInfrastructureStatistics({
          Year: year,
        }),
      ]);

      setCount(resp[0].data.Data.Count);
      setStatistics(resp[1].data.Data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchBoardData();
  }, []);

  return (
    <div>
      <div className={styles["b-analytics-filter"]}>
        <div>
          <p className="headline-h2 sf-bold color--text-primary">
            Всего садоводств: <span className="color--primary">{count}</span>
          </p>
        </div>
        <div className={styles["b-analytics-filter__base"]}>
          <div className={styles["b-analytics-filter__filters"]}>
            <div className={styles["b-analytics-filter__field"]} style={{ flexBasis: "499px", minWidth: "300px" }}>
              <span className="color--text-primary sf-text-regular text--primary">Район:</span>
              <BaseDropdown
                value={district?.Id ?? null}
                display={district?.Name ?? "Все"}
                isSelectable={true}
                placeholder="Все"
                variant="formfield"
                onSelect={(value) => {
                  const dist = institution?.MunicipalDistricts.find((it) => it.Id === value);
                  setDistrict(dist ?? null);
                }}
              >
                <BaseDropdownMenuItem value={null}>
                  <span className="sf-text-medium">Все</span>
                </BaseDropdownMenuItem>
                {institution?.MunicipalDistricts &&
                  institution.MunicipalDistricts?.map((district) => (
                    <BaseDropdownMenuItem key={district.Id} value={district.Id}>
                      <span className="sf-text-medium">{district.Name}</span>
                    </BaseDropdownMenuItem>
                  ))}
              </BaseDropdown>
            </div>

            <div className={styles["b-analytics-filter__field"]} style={{ flexBasis: "193px", minWidth: "150px" }}>
              <span className="color--text-primary sf-text-regular text--primary">Год:</span>
              <BaseDropdown
                value={year}
                display={year.toString()}
                isSelectable={true}
                placeholder={new Date().getFullYear().toString()}
                variant="formfield"
                onSelect={setYear}
              >
                {years.map((it) => (
                  <BaseDropdownMenuItem key={it} value={it}>
                    <span className="sf-text-medium">{it}</span>
                  </BaseDropdownMenuItem>
                ))}
              </BaseDropdown>
            </div>
          </div>

          <div className={styles["b-analytics-filter__actions"]}>
            <BaseButton size="medium" onClick={() => applyFilters()}>
              Показать
            </BaseButton>
            <BaseButton variant="secondary" appearance="negative" size="medium" onClick={resetFilters}>
              Сбросить фильтр
            </BaseButton>
          </div>
        </div>
      </div>

      <AnalyticsBuilder
        isLoading={isLoading}
        statistics={statistics}
        bottomInfo={
          <div style={{ display: "flex", alignItems: "center", minHeight: "64px", padding: "24px" }}>
            <p className="caption--middle color--text-subhead sf-text-regular">
              На основе предоставленных данных председателями садоводств в "Локоло"
            </p>
          </div>
        }
      />
    </div>
  );
};

export default InstitutionAnalyticsInfrastructure;
