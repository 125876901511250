import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { paths } from "@/consts/routes";
import { RootState } from "@/app/store";
import CreateOccItemLayout from "@/features/CreateOccNew/OccItem/OccLayout";

const UKCreateOCCLayout: React.FC = () => {
  const location = useLocation();

  const occId = useSelector((state: RootState) => state.createOcc.occId);

  const activeStep: number = useMemo(() => {
    const _occId = occId ?? "new";
    switch (location.pathname) {
      case paths.uk().fullPath().createOCC.item.create(_occId):
        return 1;
      case paths.uk().fullPath().createOCC.item.author(_occId):
        return 2;
      case paths.uk().fullPath().createOCC.item.administrator(_occId):
        return 3;
      case paths.uk().fullPath().createOCC.item.agenda(_occId):
        return 4;
      case paths.uk().fullPath().createOCC.item.preview(_occId):
        return 5;
      case paths.uk().fullPath().createOCC.item.finish(_occId):
        return 6;

      default:
        return 1;
    }
  }, [location, occId]);

  return (
    <div>
      <CreateOccItemLayout
        steps={[
          "Порядок собрания",
          "Инициатор",
          "Администратор",
          "Создание повестки",
          "Просмотр собрания",
          "Создание завершено",
        ]}
        activeStep={activeStep}
        isBlockerProceed={(blocker) =>
          blocker?.location?.pathname?.startsWith(paths.uk().fullPath().createOCC.subroot) ?? false
        }
        createNewPath={paths.uk().fullPath().createOCC.item.create("new")}
        draftsListPath={"/create-occ"}
      />
    </div>
  );
};

export default UKCreateOCCLayout;
