import { useCallback, useState } from "react";
import styles from "./styles.module.scss";
import { Templates } from "@/types";
import BaseButton from "@/components/base-button";
import BaseTooltip from "@/components/base-tooltip";

type ITemplateQuestionItemProps = {
  question: Templates.Question;
  onEdit: () => void;
  onRemove: () => void;
};

const TemplateQuestionItem: React.FC<ITemplateQuestionItemProps> = ({ question, onEdit, onRemove }) => {
  const [isContentMultiline, setContentMultiline] = useState<boolean>(false);

  const onRefChange = useCallback((node: HTMLElement) => {
    setContentMultiline(node && node.offsetHeight > 20);
  }, []);

  const handleEdit = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    onEdit();
  };

  const handleRemove = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    onRemove();
  };

  return (
    <div className={styles.question}>
      <div className={`${styles.question__title} ${isContentMultiline ? styles.question__title__multiline : ""}`}>
        <span ref={onRefChange} className="sf-text-regular text--primary">
          {question.Title}
        </span>
      </div>
      <div className={styles.question_actions}>
        <BaseTooltip title="Редактировать">
          <BaseButton className={styles.button} variant="icon-basic" onClick={handleEdit}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.40499 3.18425L4.69699 7.89225C4.69499 7.89425 4.44699 8.16325 4.34799 8.68425C4.20799 9.42525 4.10599 9.96525 4.00799 10.4853C3.97999 10.6333 4.02799 10.7823 4.11499 10.8853C4.21799 10.9722 4.36699 11.0203 4.51499 10.9923C5.03499 10.8942 5.57499 10.7922 6.31599 10.6523C6.83799 10.5533 7.10699 10.3042 7.10799 10.3033L8.31399 9.09825L11.816 5.59525L9.40499 3.18425ZM11.38 1.20925C11.658 0.93025 12.111 0.93025 12.39 1.20925L13.791 2.61025C14.07 2.88925 14.07 3.34225 13.791 3.62025L12.84 4.57125L10.429 2.16025L11.38 1.20925ZM3 13.0003C2.44772 13.0003 2 13.448 2 14.0003C2 14.5525 2.44772 15.0003 3 15.0003H13C13.5523 15.0003 14 14.5525 14 14.0003C14 13.448 13.5523 13.0003 13 13.0003H3Z"
                fill="#818C99"
              />
            </svg>
          </BaseButton>
        </BaseTooltip>

        <BaseTooltip title="Удалить">
          <BaseButton className={styles.button} variant="icon-basic" onClick={handleRemove}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 2.3C9 2.01997 9 1.87996 8.9455 1.773C8.89757 1.67892 8.82108 1.60243 8.727 1.5545C8.62004 1.5 8.48003 1.5 8.2 1.5H7.8C7.51997 1.5 7.37996 1.5 7.273 1.5545C7.17892 1.60243 7.10243 1.67892 7.0545 1.773C7 1.87996 7 2.01997 7 2.3V3H9V2.3ZM5.5 2.4V3.09091L1.71304 3.43518C1.30921 3.47189 1 3.81048 1 4.21598C1 4.64898 1.35102 5 1.78402 5H5.5H10.5H14.216C14.649 5 15 4.64898 15 4.21598C15 3.81048 14.6908 3.47189 14.287 3.43518L10.5 3.09091V2.4C10.5 1.55992 10.5 1.13988 10.3365 0.819014C10.1927 0.536771 9.96323 0.3073 9.68099 0.16349C9.36012 0 8.94008 0 8.1 0H7.9C7.05992 0 6.63988 0 6.31901 0.16349C6.03677 0.3073 5.8073 0.536771 5.66349 0.819014C5.5 1.13988 5.5 1.55992 5.5 2.4ZM3.0969 6.30816C3.04746 6.42715 3.06454 6.58088 3.0987 6.88834L3.76278 12.865C3.84667 13.62 3.88861 13.9975 4.06276 14.2824C4.21617 14.5333 4.43996 14.7336 4.70631 14.8584C5.00868 15 5.38849 15 6.1481 15H9.8519C10.6115 15 10.9913 15 11.2937 14.8584C11.56 14.7336 11.7838 14.5333 11.9372 14.2824C12.1114 13.9975 12.1533 13.62 12.2372 12.865L12.9013 6.88835C12.9355 6.58088 12.9525 6.42715 12.9031 6.30816C12.8597 6.20366 12.7821 6.11697 12.683 6.06228C12.5702 6 12.4155 6 12.1062 6H3.89381C3.58446 6 3.42978 6 3.31698 6.06228C3.21791 6.11697 3.14032 6.20366 3.0969 6.30816Z"
                fill="#818C99"
              />
            </svg>
          </BaseButton>
        </BaseTooltip>
      </div>
    </div>
  );
};

export default TemplateQuestionItem;
