import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router";
import { SntPassport } from "@/types";
import { paths } from "@/consts/routes";
import PageHeader from "@/components/PageHeader";
import SegmentedСontrols from "@/components/SegmentedСontrols";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import BaseButton from "@/components/base-button";
import { analyticsIcon, institutionSntsMapIcon } from "@/consts/icons";

const BACK_LINK_STORAGE_NAME = "institution-from-path";

const InstitutionSntItemDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = useParams();

  const { sntData } = useOutletContext<{ sntData: SntPassport.InstitutionSntItem }>();

  function handleBackClick() {
    const path = localStorage.getItem(BACK_LINK_STORAGE_NAME);
    if (path) {
      navigate(path);
    } else {
      navigate(paths.institution().root);
    }
  }

  function showOnMap() {
    navigate(`${paths.institution().fullPath().map}?id=${urlParams.sntId}`);
  }

  useEffect(() => {
    if (location.state && location.state.startsWith("/institution")) {
      localStorage.setItem(BACK_LINK_STORAGE_NAME, location.state);
    }

    return () => {
      localStorage.removeItem(BACK_LINK_STORAGE_NAME);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader title={sntData?.Name ?? ""} defaultLink={paths.institution().root} onLinkClick={handleBackClick}>
        {!!sntData?.Geopoint && (
          <BaseButton endIcon={institutionSntsMapIcon("white")} onClick={showOnMap}>
            Показать на карте
          </BaseButton>
        )}
      </PageHeader>

      <div className={styles["tabs"]}>
        <div className="flex middle between">
          <SegmentedСontrols
            list={[
              {
                text: "Данные Минсельхоза",
                link: paths.institution(urlParams.sntId).fullPath().sntItemAgricultureMinistry,
              },
              {
                text: "Данные правления",
                link: paths.institution(urlParams.sntId).fullPath().sntItemBoard,
              },
            ]}
          />

          <NavLink to={paths.institution(urlParams.sntId).fullPath().sntItemSummary}>
            <BaseButton variant="secondary" appearance="neutral" endIcon={analyticsIcon}>
              Сводка
            </BaseButton>
          </NavLink>
        </div>
      </div>

      <div>
        <Outlet context={{ sntData }} />
      </div>
    </>
  );
};

export default InstitutionSntItemDetails;
