import { Typography } from "@mui/material";

import styles from "./add.module.scss";
import Checkbox from "../../../../components/checkbox";

interface IAddressLabel {
  onChange: (value: boolean) => void;
  label: string;
  all?: boolean;
  checked?: boolean;
  disabled?: boolean;
}

const AddressLabel = ({
  onChange,
  label,
  all,
  checked,
  disabled,
}: IAddressLabel) => (
  <div className={styles.row}>
    <Checkbox
      checked={checked}
      onChange={onChange}
      all={all}
      disabled={disabled}
    />
    <Typography className={`${styles.label} ${all && styles.label_all}`}>
      {label}
    </Typography>
  </div>
);

export { AddressLabel };
