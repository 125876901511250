import isPast from "date-fns/isPast";
import addDays from "date-fns/addDays";
import isToday from "date-fns/isToday";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { VoteEnum } from "./enum";
import { Form, FormFiled, DatepickerField, FormValidator, Dictionary } from "./";
import errorMessages from "@/helpers/errorMessages.json";

export type OccType = {
  Id: number;
  Uuid: string;
  StartTime: number;
  EndTime: number;
  StatusId: number;
  TypeId: number;
  StartDate: string;
  EndDate: string;
  Status: string;
  Type: string;
  FiasId: string;
  Number: string;
  UpdatedAt: number;
  UpdatedAtTimestamp: number;
};

export type OptionType = {
  id: number;
  name: string;
  key: string;
};

export type OwnerRegistryType = {
  Id: number;
  ApartmentNumber: number;
  FullName: string;
  Area: number;
  TypeOwnership: string;
  SizeShares: string;
  DateRecordNumber: string;
  ExternalId: number;
  CadastralNumber: string;
  LokoloUserId: string;
  DecisionIsMade: VoteEnum;
};

export type OwnerType = {
  Id: number;
  ApartmentNumber: number;
  FullName: string;
  Area: number;
  TypeOwnership: string;
  SizeShares: string;
  DateRecordNumber: string;
  ExternalId: number;
  CadastralNumber: string;
  LokoloUserId: string;
  DecisionIsMade: boolean;
};

export type QuestionType<T> = {
  Id: number;
  QuorumId: number;
  Title: number;
  Value: number;
  Files: T;
};

export type OccQuestionResultType = {
  QuestionId: number;
  Result: string;
};

export type OccQuestionsResultType = {
  OccId: number;
  OwnerId: number;
  Results: Array<OccQuestionResultType>;
  Files: Array<File>;
};

export type QuorumType = {
  id: number;
  name: string;
  key: string;
};

export type OccCommonData = {
  Address: number;
  Area: number;
  EndDate: string;
  EndTime: number;
  FiasId: string;
  FormId: number;
  FormName: string;
  Id: number;
  LocationId: number;
  LocationName: string;
  Number: string;
  ProcedureForFamiliarizationWithMaterialsOfOCC: string;
  ProcedureForMakingWrittenDecisions: string;
  PublicationDate: string;
  PublicationTime: number;
  StartDate: string;
  StartTime: number;
  StatusId: number;
  StatusName: string;
  TypeId: number;
  TypeName: string;
  Uuid: number;
};

export namespace OCC {
  export const DefaultValueProcedureForFamiliarization =
    "Порядок ознакомления с информацией и (или) материалами, которые будут представлены на собрании, и место (адрес), где с ними можно ознакомиться лично по адресу _____(укажите адрес). Период ознакомления с _ часов до _ часов ___ (укажите дни недели или календарные дни) номер телефона __(укажите номер) . По звонку информация предоставляется в устной форме, по сообщению информация предоставляется посредством мессенджеров WhatsApp, Viber, Telegram.  По адресу электронной почты ______@______.___(укажите адрес эл. почты) в ответном письме.";

  export type OCCStatus = {
    id: string;
    name: string;
    key: string;
  };

  export type OccOrderBase = {
    Id: number;
    Uuid: string;
    StartTime: number;
    EndTime: number;
    StatusId: Dictionary.DictionaryItem<Dictionary.OCCStatus>["id"];
    TypeId: Dictionary.DictionaryItem<Dictionary.OCCType>["id"];
    StartDate: string;
    EndDate: string;
    FiasId: string;
    Number: string;
    UpdatedAt: number;
    UpdatedAtTimestamp: number;
    Address: string;
    IntramuralLocation: string;
  };

  export type OccOrderItem = OccOrderBase & {
    Status: Dictionary.DictionaryItem<Dictionary.OCCStatus>["name"];
    Type: Dictionary.DictionaryItem<Dictionary.OCCType>["name"];
  };

  export type OccOrderItemFull = OccOrderBase & {
    Area: number | null;
    FormId: Dictionary.DictionaryItem<Dictionary.OCCForm>["id"];
    FormName: Dictionary.DictionaryItem<Dictionary.OCCForm>["name"];
    LocationId: Dictionary.DictionaryItem<Dictionary.OCCLocation>["id"];
    LocationName: Dictionary.DictionaryItem<Dictionary.OCCLocation>["name"];
    ProcedureForFamiliarizationWithMaterialsOfOCC: string;
    ProcedureForMakingWrittenDecisions: string;
    PublicationDate: string;
    PublicationTime: number;
    StatusName: Dictionary.DictionaryItem<Dictionary.OCCStatus>["name"];
    TypeName: Dictionary.DictionaryItem<Dictionary.OCCType>["name"];
    CreatedAtTimestamp: number;
    CreatedAt: string;
  };

  export type CreateOCCBody = {
    OccId?: number;
    TypeId: Dictionary.DictionaryItem["id"];
    FormId: Dictionary.DictionaryItem["id"];
    LocationId?: Dictionary.DictionaryItem["id"];
    FiasId?: string;
    Number: string;
    ProcedureForMakingWrittenDecisions: string;
    ProcedureForFamiliarizationWithMaterialsOfOCC: string;
    StartTimestamp: number;
    EndTimestamp: number;
    PublicationTimestamp: number;
    IntramuralLocation?: string;
  };

  export type Question = {
    Id: number;
    QuorumId: Dictionary.DictionaryItem<Dictionary.Quorum>["id"];
    Title: string;
    Value: string;
    Order: number;
    OnlyHorticultureMembers: boolean;
    Files: Dictionary.File[];
  };

  export class CreateOCCForm extends Form {
    readonly occId: number | null = null;
    Number: FormFiled = new FormFiled();
    Type: Dictionary.DictionaryItem<Dictionary.OCCType> | null = null;
    TypeError: string = "";
    Form: Dictionary.DictionaryItem<Dictionary.OCCForm> | null = null;
    FormError: string = "";
    IntramuralLocation: FormFiled = new FormFiled();
    ProcedureForMakingWrittenDecisions: FormFiled = new FormFiled();
    ProcedureForFamiliarizationWithMaterialsOfOCC: FormFiled = new FormFiled();
    StartTimestamp: DatepickerField = new DatepickerField({});
    EndTimestamp: DatepickerField = new DatepickerField({});
    PublicationTimestamp: DatepickerField = new DatepickerField({});

    constructor(
      occ?: OccOrderItemFull,
      address?: string,
      types?: Dictionary.DictionaryItem<Dictionary.OCCType>[],
      forms?: Dictionary.DictionaryItem<Dictionary.OCCForm>[]
    ) {
      super();
      this.occId = occ?.Id ?? null;
      this.Number = new FormFiled(occ?.Number ?? "", true);
      this.IntramuralLocation = new FormFiled(occ?.IntramuralLocation ? occ?.IntramuralLocation : address ?? "", true);
      this.ProcedureForMakingWrittenDecisions = new FormFiled(occ?.ProcedureForMakingWrittenDecisions ?? "", true);
      this.ProcedureForFamiliarizationWithMaterialsOfOCC = new FormFiled(
        occ?.ProcedureForFamiliarizationWithMaterialsOfOCC ?? DefaultValueProcedureForFamiliarization,
        true
      );
      const tomorrow = addDays(new Date(), 1);

      const publication =
        occ?.PublicationTime && new Date(occ?.PublicationTime).isValid()
          ? new Date(occ?.PublicationTime.parseFromEpochSeconds())
          : undefined;
      const start =
        occ?.StartTime && new Date(occ?.StartTime).isValid()
          ? new Date(occ?.StartTime.parseFromEpochSeconds())
          : undefined;
      const end =
        occ?.EndTime && new Date(occ?.EndTime).isValid() ? new Date(occ?.EndTime.parseFromEpochSeconds()) : undefined;

      this.PublicationTimestamp = new DatepickerField({
        value: publication,
        minDate: tomorrow,
        isRequired: true,
      });

      this.StartTimestamp = new DatepickerField({
        value: start,
        minDate: addDays(publication ?? tomorrow, 14),
        isRequired: true,
      });

      this.EndTimestamp = new DatepickerField({
        value: end,
        minDate: start ? addDays(start, 7) : addDays(tomorrow, 21),
        maxDate: start ? addDays(start, 60) : undefined,
        isRequired: true,
      });

      if (occ?.TypeId && types && types.length > 0) {
        const type = types.find((it) => it.id === occ?.TypeId);
        this.Type = type ?? null;
      } else this.Type = null;

      if (occ?.FormId && forms && forms.length > 0) {
        const form = forms.find((it) => it.id === occ?.FormId);
        this.Form = form ?? null;
      } else this.Form = null;
    }
  }

  export class CreateOCCFormValidator {
    private static instance: CreateOCCFormValidator;

    public static get(): CreateOCCFormValidator {
      if (!CreateOCCFormValidator.instance) {
        CreateOCCFormValidator.instance = new CreateOCCFormValidator();
      }
      return CreateOCCFormValidator.instance;
    }

    validateStartTimestamp(start: CreateOCCForm["StartTimestamp"]) {
      if (start.value === null) return errorMessages.empty;

      if (start.value && start.minDate && isBefore(start.value, start.minDate)) {
        return "Должно пройти 14 дней с даты публикации сообщения";
      }

      return "";
    }

    validateEndTimestamp(end: CreateOCCForm["EndTimestamp"]) {
      if (end.value === null) return errorMessages.empty;

      if (end.value && end.minDate && isBefore(end.value, end.minDate)) {
        return "Продолжительность голосования не менее 7 дней с даты начала голосования";
      }

      if (end.value && end.maxDate && isAfter(end.value, end.maxDate)) {
        return "Продолжительность голосования не более 14 дней с даты начала голосования";
      }

      return "";
    }

    validatePublicationTimestamp(publication: CreateOCCForm["PublicationTimestamp"]) {
      if (publication.value === null) return errorMessages.empty;

      if (publication.value && isPast(publication.value) && !isToday(publication.value)) {
        return "Для выбора доступны даты, начиная со следующего календарного дня.";
      }

      return "";
    }

    validateIntramuralLocation(form: CreateOCCForm) {
      const isRequired = form.Form?.key === Dictionary.OCCForm.IntramuralExtramural;
      return FormValidator.getFieldErrorMessage(form.IntramuralLocation.value.trim(), isRequired);
    }

    isInvalid(form: CreateOCCForm) {
      const errors = {
        Number: FormValidator.getFieldErrorMessage(form.Number.value.trim(), form.Number.isRequired),
        ProcedureForMakingWrittenDecisions: FormValidator.getFieldErrorMessage(
          form.ProcedureForMakingWrittenDecisions.value.trim(),
          form.ProcedureForMakingWrittenDecisions.isRequired
        ),
        ProcedureForFamiliarizationWithMaterialsOfOCC: FormValidator.getFieldErrorMessage(
          form.ProcedureForFamiliarizationWithMaterialsOfOCC.value.trim(),
          form.ProcedureForFamiliarizationWithMaterialsOfOCC.isRequired
        ),
        StartTimestamp: this.validateStartTimestamp(form.StartTimestamp),
        EndTimestamp: this.validateEndTimestamp(form.EndTimestamp),
        PublicationTimestamp: this.validatePublicationTimestamp(form.PublicationTimestamp),
        IntramuralLocation: this.validateIntramuralLocation(form),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export class CreateOCCQuestionForm extends Form {
    readonly questionId: Question["Id"] | null = null;
    Quorum: Dictionary.DictionaryItem<Dictionary.Quorum> | null = null;
    QuorumError: string = "";
    Title: FormFiled;
    Value: FormFiled;
    AvailableForAllSectionOwner: boolean = false;
    Files: Dictionary.File[] = [];

    constructor(question?: Question, quorums?: Dictionary.DictionaryItem<Dictionary.Quorum>[]) {
      super();
      this.questionId = question?.Id ?? null;
      this.Title = new FormFiled(question?.Title.trim().replace(/ +(?= )/g, "") ?? "", true, false, 1000);
      this.Value = new FormFiled(question?.Value.trim().replace(/ +(?= )/g, "") ?? "", true, false, 5000);
      this.AvailableForAllSectionOwner = question ? !question?.OnlyHorticultureMembers : false;
      this.Files = question?.Files ?? [];
      if (question?.QuorumId && quorums && quorums.length > 0) {
        const quorum = quorums.find((it) => it.id === question?.QuorumId);
        this.Quorum = quorum ?? null;
      } else this.Quorum = null;
    }
  }

  export class CreateOCCQuestionFormValidator {
    private static instance: CreateOCCQuestionFormValidator;

    public static get(): CreateOCCQuestionFormValidator {
      if (!CreateOCCQuestionFormValidator.instance) {
        CreateOCCQuestionFormValidator.instance = new CreateOCCQuestionFormValidator();
      }
      return CreateOCCQuestionFormValidator.instance;
    }

    isInvalid(form: CreateOCCQuestionForm) {
      const errors = {
        Title: FormValidator.getFieldErrorMessage(form.Title.value.trim(), form.Title.isRequired),
        Value: FormValidator.getFieldErrorMessage(form.Value.value.trim(), form.Value.isRequired),
        Quorum: "",
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export type CreateQuestion = {
    Id?: Question["Id"];
    QuorumId: Dictionary.DictionaryItem<Dictionary.Quorum>["id"];
    Title: string;
    Value: string;
    Order?: number;
    AvailableForAllSectionOwner?: boolean;
    FileIds: Array<Dictionary.File["Id"]>;
    Files?: Array<Dictionary.File>;
  };

  export type CreateQuestionBody = {
    OccId: OccOrderBase["Id"];
    Questions: CreateQuestion[];
  };

  export type OccFullDetails = {
    Order: OccOrderItemFull;
    Author: OCCUser;
    Administrator: OCCUser;
    Questions: Array<Question>;
    PlannedEvents: {
      Publication: PlannedEvent;
      Start: PlannedEvent;
      End: PlannedEvent;
      CreateProtocol: PlannedEvent;
    };
  };

  export type PlannedEvent = {
    Date: string;
    Text: string;
  };

  export type OCCUser = {
    Type: number;
    Name: string;
    Phone: string | null;
    Surname?: string;
    LegalForm?: string;
    Patronymic?: string | null;
    OGRN?: string | null;
    RoomAddress?: string | null;
    FoundationAgreementDetails?: string | null;
    OwnershipDocumentDetails?: string | null;
    Location?: string | null;
    PermanentResidencePlace?: string | null;
    MailingAddress?: string | null;
    Email?: string | null;
    Site?: string | null;
    Series?: string | null;
    Number?: string | null;
    IssuedBy?: string | null;
    IssueDate?: string | null;
    DepartmentCode?: string | null;
  };

  export type OCCDocument = OCCDocumentCreating | OCCDocumentCreated;

  export type OCCDocumentCreating = {
    DocumentType: string;
    IsCreated: boolean;
    IsCreatedText: string;
  };

  export type OCCDocumentCreated = Dictionary.File &
    OCCDocumentCreating & {
      CreatedAt: string;
    };

  export enum DecisionIsMade {
    NotMade = 0,
    OnlineViaLokolo = 1,
    Offline = 2,
  }

  export type RegistryOwner = {
    Id: number;
    ApartmentNumber: string;
    FullName: string;
    Area: string;
    TypeOwnership: string;
    SizeShares: string;
    DateRecordNumber: string;
    ExternalId: number;
    CadastralNumber: unknown;
    LokoloUserId: unknown;
    DecisionIsMade: DecisionIsMade;
    IsMember: boolean;
  };

  export enum Decision {
    Agree = "agree",
    Disagree = "disagree",
    Abstain = "abstain",
  }

  export type OwnerDecision = {
    QuestionId: Question["Id"];
    Result: Decision | null;
  };

  export type SetOwnerResultRequestBody = {
    OccId: OccOrderBase["Id"];
    OwnerId: RegistryOwner["Id"];
    Files?: File[];
    Results: Array<OwnerDecision>;
  };

  export enum QuestionDecisionFormat {
    Online = 0,
    Offline = 1,
  }

  export type OwnerQuestionDecision = {
    Format: QuestionDecisionFormat;
    QuestionId: Question["Id"];
    ResultTypeId: number;
    ResultTypeName: string;
  };

  export type OwnerOCCResults = {
    OccId: OccOrderBase["Id"];
    OwnerId: RegistryOwner["Id"];
    QuestionsResults: Array<OwnerQuestionDecision>;
    QuestionsResultsBallot: unknown;
    QuestionsResultsBallots: Array<unknown>;
  };

  export type Progress = {
    TotalVotes: 0;
    VotersNumber: 0;
    VotersNumberPercentage: 0;
    QuorumCompleted: boolean;
    TotalVotesText: string;
    VotersNumberText: string;
    Status: string;
    TotalVotesAreaText: string;
    VotersNumberAreaText: string;
  };

  export type QuestionResult = {
    QuestionId: Question["Id"];
    QuestionTitle: Question["Title"];
    VotersPercentage: number;
    NonVotersPercentage: number;
    Quorum: Dictionary.DictionaryItem<Dictionary.Quorum>["name"];
    QuorumPresentValuePercentage: number;
    QuorumCompleted: boolean;
    AgreePercentageTotalVotes: number;
    Order: Question["Order"];
  };

  export type OwnerResult = {
    QuestionId: Question["Id"];
    ResultIsSet: boolean;
    ResultTypeId: number;
    ResultTypeKey: Decision;
    ResultTypeName: string;
  };

  export type OwnersQuestionsResultsResponse = {
    ApartmentNumber: string | number;
    FullName: string;
    IsParticipated: boolean;
    VoteIsCounted: boolean;
    QuestionsResults: Array<OwnerResult>;
  };

  export type UpdateRequestBody = CreateOCCBody & {
    Questions: Array<CreateQuestion>;
    DeleteQuestions: Array<Question["Id"]>;
  };
}
