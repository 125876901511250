import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SntPassport, SntPassportMap } from "@/types";
import { SntPassportService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { setSelectSntModalOpen, setSntForGeopoint, updateSntForGeopoint } from "@/app/store/slices/snt/sntsMap";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import AutocompleteDropdown from "@/components/AutocompleteDropdown";

interface ISelectSntForGeopointModalProps {
  onSntChange: (snt: SntPassportMap.SntItem) => void;
}

const SelectSntForGeopointModal: React.FC<ISelectSntForGeopointModalProps> = ({ onSntChange }) => {
  const dispatch = useAppDispatch();

  const [selectValue, setSelectValue] = useState<SntPassport.InstitutionSntItem | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { markers, selected, isSelectSntLoading, isSelectSntModalOpen, state } = useSelector(
    (state: RootState) => state.sntsMap
  );

  async function searchForSnt(query: string) {
    const { data } = await SntPassportService.getSntList({
      Value: query,
      Column: SntPassport.QueryColumn.Name,
    });
    return data.Data;
  }

  async function onClose() {
    dispatch(setSelectSntModalOpen(false));
  }

  async function saveGeopoint() {
    setErrorMessage("");

    if (!selectValue?.Id) {
      setErrorMessage("Выберите cадоводство");
      return;
    }

    const existedMarker = markers.find((it) => it.Id === selectValue.Id);
    if (existedMarker && (!selected.snt || (selected.snt && existedMarker.Id !== selected.snt?.Id))) {
      console.log("2");
      setErrorMessage("Метка выбранного садоводства уже есть на карте");
      return;
    }

    if (selected.snt) {
      try {
        const data = await dispatch(updateSntForGeopoint({ new: selectValue.Id, old: selected.snt.Id })).unwrap();
        onSntChange(data);
      } catch (error) {
        console.warn(error);
      }
    } else {
      try {
        await dispatch(setSntForGeopoint(selectValue.Id)).unwrap();
      } catch (error) {
        console.warn(error);
      }
    }
  }

  useEffect(() => {
    setSelectValue(selected.snt ?? null);
    setErrorMessage("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectSntModalOpen]);

  return (
    <BaseModal isOpen={isSelectSntModalOpen} minWidth="520px" maxWidth="520px">
      <BaseModalHeader
        title={
          state === SntPassportMap.MapState.Default && selected.snt ? "Изменить садоводство" : "Выберите садоводство"
        }
        onClose={() => onClose()}
      />

      <BaseModalContent sx={{ padding: "20px 24px" }}>
        <AutocompleteDropdown
          label="Садоводство"
          required={true}
          value={selectValue}
          valueKey="Id"
          displayKey="Name"
          errorMessage={errorMessage}
          placeholder="Начните вводить наименование"
          emptyMessage="Начните вводить наименование"
          noResultsMessage={
            <div>
              <p>Мы ничего не нашли :(</p>
              <p>Попробуйте изменить или проверьте правильность написания.</p>
            </div>
          }
          callback={(query) => searchForSnt(query)}
          onSelect={(snt: any) => setSelectValue(snt)}
        />
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton variant="secondary" onClick={() => onClose()}>
          Отменить
        </BaseButton>

        <BaseButton isLoading={isSelectSntLoading} onClick={saveGeopoint}>
          {state === SntPassportMap.MapState.Default && selected.snt ? "Подтвердить" : "Сохранить"}
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default SelectSntForGeopointModal;
