import { SntPassportEnums } from "../enums";

export namespace SntPassportBoard {
  export type Association = {
    Id: number;
    Name: string;
  };

  export type Details = {
    FoundationYear?: number | null; // Год основания
    RegistrationDate?: string | null; // Дата государственной регистрации
    INN?: string; // ИНН
    MunicipalDistrict?: string; // id района
    AssociationId?: Association["Id"] | null; // id Ассоциации
    AssociationMembership?: boolean | null; // Наличие членства в ассоциации
    CadastralNumber?: string | null; // Кадастровый номер земель общего пользования
    ChairmanName?: string; // ФИО председателя
    ChairmanPhone?: string | null; // Контактный телефон председателя
    ChairmanEmail?: string | null; // Электронная почта председателя
  };

  export type QuantitativeData = {
    TotalArea: number | null; // Общая площадь территории садоводства (м2)
    PublicArea: number | null; // Площадь земель общего пользования (м2)
    SectionsArea: number | null; // Площадь участков садоводов (м2)
    TotalSectionsCount: number | null; // Общее количество участков
    UsedSectionsCount: number | null; // Количество используемых участков
    UnUsedSectionsCount: number | null; // Количество неиспользуемых участков
    RegisteredSectionsCount: number | null; // Количество участков зарегистрированных в собственность
    ResidentialBuildingsCount?: number | null; // Количество домов признанных жилыми
    RegisteredCitizensCount?: number | null; // Количество зарегистрированных в садоводстве граждан
    ContributionsAggregateAmount: number | null; // Совокупный размер всех видов взносов за 1 сотку
  };

  export type Infrastructure = {
    ElectricitySupply: {
      IsAvailable?: boolean | null; // Наличие электроснабжения
      TypeKey?: string | null; // Электросети, ключ из словаря?
      CTS?: boolean | null; // Наличие КТП
      PowerLineLength?: number | string | null; // Длина ЛЭП
    };
    WaterSupply: {
      IsAvailable?: boolean | null; // Наличие водоснабжения
      TypeKey?: Array<SntPassportEnums.WaterSupplyType>; // Источник водоснабжения
      License?: string | null; // Номер лицензии водоснабжения, год выдачи
      WaterWellCount?: number | null; // Количество скважин
      SeasonalFireWaterSupply?: boolean | null; // Наличие сезонного пожарного водоснабжения
      YearRoundFireWaterSupply?: boolean | null; // Наличие круглогодичного пожарного водоснабжения
    };
    GasSupply: {
      IsAvailable?: boolean | null; // Газификация садоводства
      CommissioningYear?: number | string | null; // Год ввода в эксплуатацию
      ConnectedBuildingsCount?: number | null; // Количество подкл.домов к сетевому газу
    };
    TKO: {
      WasteCollectionSitesCount?: number | null; // Площадки для сбора ТКО (кол-во)
      HoppersCount?: number | null; // Бункер (кол-во)
      ContainersCount?: number | null; // Контейнер (кол-во)
    };
    SectionOuterRoad: {
      CommissioningYear?: number | string | null; // Год постройки
      SurfaceWorn?: number | null; // Изношенность покрытия, %
      PublicTransportStopsDistance?: number | string | null; // Расстояние до ост. общ-го транспорта (км)
    };
    SectionInnerRoad: {
      Length?: number | null; // Протяженность (м, км)
      AlleyWidth?: number | null; // Ширина аллей
      SurfaceTypeKey?: SntPassportEnums.RoadSurfaceType | null; // Покрытие
      SurfaceTypeKeyOther?: string | null;
    };
    Additional: {
      FiberOptic?: boolean | null; // Оптоволоконная сеть (Интернет)
      SecurityRoom?: boolean | null; // Наличие помещения охраны
      AccessControlSystems?: boolean | null; // Наличие систем контроля доступа
      VideoSurveillanceSystems?: boolean | null; // Наличие систем видеонаблюдения
      Fence?: boolean | null; // Наличие ограждения
    };
  };

  export type BoardData = {
    Details: Details;
    QuantitativeData?: QuantitativeData;
    Infrastructure?: Infrastructure;
  };

  export type BoardDataResponse = {
    PublicationDatetime: number;
    Form: BoardData;
  };
}
