import { useMemo } from "react";
import { UKRegistry } from "@/types";

type IRosreestrApartmentStatusProps = {
  status: UKRegistry.RosreestrСomparisonStatus;
  name?: string;
};

const RosreestrApartmentStatus: React.FC<IRosreestrApartmentStatusProps> = ({ status, name }) => {
  const formatted: { icon: JSX.Element; name: string } = useMemo(() => {
    switch (status) {
      case UKRegistry.RosreestrСomparisonStatus.Match:
        return {
          icon: RosreestrCompliantIcon,
          name: "Соответствует Росреестру",
        };

      case UKRegistry.RosreestrСomparisonStatus.DontMatch:
        return {
          icon: DiscrepanciesIcon,
          name: "Расхождения",
        };

      case UKRegistry.RosreestrСomparisonStatus.ReestrDataMissing:
        return {
          icon: NotInRegistry,
          name: "Нет в моем реестре",
        };

      case UKRegistry.RosreestrСomparisonStatus.InProgress:
        return {
          icon: DatasUpdating,
          name: "Данные обновляются",
        };

      default:
        return {
          icon: DatasUpdating,
          name: "Данные обновляются",
        };
    }
  }, [status]);

  return (
    <span className="flex middle" style={{ gap: "8px" }}>
      {formatted.icon}
      <span className="sf-text-regular">{name ? name : formatted.name}</span>
    </span>
  );
};

export default RosreestrApartmentStatus;

const RosreestrCompliantIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 8.00011C12.5 9.04838 11.8222 10.1734 10.6949 11.2615C9.75873 12.1652 8.68917 12.8717 8.00001 13.2827L8 2.58125L12.5 4.08125V8.00011ZM6.60577 14.1805C4.83314 13.0252 2 10.7487 2 8.00011V3.72087C2 3.29044 2.27543 2.9083 2.68377 2.77218L6.68378 1.43885L7.68378 1.10552C7.88904 1.03709 8.11097 1.03709 8.31623 1.10552L13.3162 2.77218C13.7246 2.9083 14 3.29044 14 3.72087V8.00011C14 11.3393 9.81875 13.9816 8.43718 14.7635C8.16326 14.9185 7.83675 14.9185 7.56282 14.7635C7.31862 14.6253 6.98694 14.4289 6.60634 14.1809"
      fill="#0DC268"
    />
  </svg>
);

const DiscrepanciesIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.63172 11.9411L6.16505 3.44108C6.76095 2.32376 7.05891 1.76509 7.45565 1.58202C7.80127 1.42253 8.19942 1.42253 8.54503 1.58202C8.94178 1.76509 9.23973 2.32376 9.83564 3.44108L14.369 11.9411C14.9071 12.95 15.1761 13.4545 15.123 13.8662C15.0766 14.2256 14.8824 14.5493 14.5871 14.7593C14.2488 14.9999 13.6771 14.9999 12.5337 14.9999H3.46701C2.32357 14.9999 1.75186 14.9999 1.41354 14.7593C1.11827 14.5493 0.924039 14.2256 0.87769 13.8662C0.824584 13.4545 1.09363 12.95 1.63172 11.9411ZM8.92613 11.8437C8.92613 12.3549 8.51165 12.7694 8.00036 12.7694C7.48907 12.7694 7.07458 12.3549 7.07458 11.8437C7.07458 11.3324 7.48907 10.9179 8.00036 10.9179C8.51165 10.9179 8.92613 11.3324 8.92613 11.8437ZM8.00034 4.99991C7.58613 4.99991 7.25034 5.33569 7.25034 5.74991V9.24991C7.25034 9.66412 7.58613 9.99991 8.00034 9.99991C8.41455 9.99991 8.75034 9.66412 8.75034 9.24991V5.74991C8.75034 5.33569 8.41455 4.99991 8.00034 4.99991Z"
      fill="#DD2B0E"
    />
  </svg>
);

const NotInRegistry = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M5.77396 14.3251C5.26129 16.1605 6.03101 16.5407 7.40799 15.2008L13.1921 9.29309C14.5303 7.84115 14.1951 6.67459 12.2679 6.38296L9.47917 5.92388C9.31625 5.89706 9.20864 5.75793 9.23881 5.61311C9.23981 5.60834 9.24095 5.60358 9.24224 5.59886L10.3154 1.66293C10.8295 -0.184073 9.97265 -0.518563 8.68733 0.790335C6.84052 2.64129 5.43706 4.03955 4.47696 4.9851C4.14222 5.31476 3.60342 5.82932 2.86054 6.52877C1.23245 8.13469 2.08933 9.29935 3.97248 9.59098L6.63767 10.0157C6.80075 10.0416 6.90926 10.1802 6.88002 10.3252C6.8789 10.3308 6.87757 10.3363 6.87605 10.3418L5.77396 14.3251Z"
      fill="#DD2B0E"
    />
  </svg>
);

const DatasUpdating = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      opacity="0.3"
      d="M2.34315 13.6569C1.22433 12.538 0.4624 11.1126 0.153718 9.56072C-0.154964 8.00887 0.00346269 6.40034 0.608964 4.93853C1.21446 3.47672 2.23985 2.22729 3.55544 1.34824C4.87103 0.469192 6.41775 -1.88681e-08 8 0V8L2.34315 13.6569Z"
      fill="#898D93"
    />
    <circle cx="8" cy="8" r="7.25" stroke="#898D93" strokeWidth="1.5" />
    <path d="M8 4V8L5 11" stroke="#898D93" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
