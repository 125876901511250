import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { paths } from "@/consts/routes";
import { Access } from "@/types";
import { addIcon24, deleteGarbageIcon, ukRoleIcon, sntRoleIcon, editIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { activeAddress, isCompanyTypeUK } from "@/app/store/slices/user";
import { toggleAddAccessModalOpen } from "@/app/store/slices/access";
import { fetchAccessRoles, fetchAccessUsers, getMyAccessRole } from "@/app/store/slices/access/actions";
import { Spinner } from "@/components/spinner";
import PageHeader from "@/components/PageHeader";
import BaseTooltip from "@/components/base-tooltip";
import BaseButton from "@/components/base-button";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import BaseHeadlineAlert from "@/components/BaseHeadlineAlert";
import SetRoleForUserModal from "./modals/SetRoleForUserModal";
import ErrorModal from "./modals/ErrorModal";
import SuccessModal from "./modals/SuccessModal";
import CreateUserModal from "./modals/CreateUserModal";
import SetUserAddressModal from "./modals/SetUserAddressModal";
import RemoveUserAccessModal from "./modals/RemoveUserAccessModal";
import EditUserRoleModal from "./modals/EditUserRoleModal";
import styles from "./styles.module.scss";

const AccessSettings = () => {
  const dispatch = useAppDispatch();

  const address = useSelector(activeAddress);
  const isUK = useSelector(isCompanyTypeUK);
  const profile = useSelector((state: RootState) => state.user.profile);
  const company = useSelector((state: RootState) => state.user.company);
  const { me, users, roles } = useSelector((state: RootState) => state.access);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRemoveAccessModalShown, setRemoveAccessModalShown] = useState<boolean>(false);
  const [isEditModalShown, setEditModalShown] = useState<boolean>(false);
  const [selectedUser, selectUser] = useState<Access.User | null>(null);

  const userInitials: string = useMemo(() => {
    let initials = profile?.last_name ?? "";
    if (profile?.first_name) initials += ` ${profile?.first_name}`;
    if (profile?.middle_name) initials += ` ${profile?.middle_name}`;
    return initials;
  }, [profile]);

  const role: string = useMemo(() => {
    const role = roles.find((it) => it.Key === me?.Role.Key);
    return role ? `${role.Value} – ${company?.Name}` : me ? `${me?.Role.Value ?? ""} – ${company?.Name}` : "";
  }, [company, me, roles]);

  const allowAccess = () => {
    dispatch(toggleAddAccessModalOpen(true));
  };

  function showRemoveAccessModal(user: Access.User) {
    selectUser(user);
    setRemoveAccessModalShown(true);
  }

  function showEditUserRoleModal(user: Access.User) {
    selectUser(user);
    setEditModalShown(true);
  }

  async function fetch() {
    setIsLoading(true);
    try {
      await Promise.all([
        dispatch(fetchAccessRoles()).unwrap(),
        dispatch(fetchAccessUsers()).unwrap(),
        dispatch(getMyAccessRole()).unwrap(),
      ]);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const locoloAccessIcon = isUK ? ukRoleIcon : sntRoleIcon;

  return (
    <>
      <PageHeader title="Настройки доступа" defaultLink={paths.settings} />

      <div>
        <BaseHeadlineAlert name="access-settings" title="Что это такое?" verticalMargin="0">
          <p className="text--primary">
            Вы можете предоставить своим коллегам расширенные права в мобильном приложении "Локоло". Это позволит им
            отвечать на обращения и публиковать контент от имени вашей организации
          </p>
        </BaseHeadlineAlert>
      </div>

      <div className={styles.me}>
        <div className={styles.me_head}>
          <h3 className="headline-h3 sf-bold">Вы:</h3>
        </div>
        <div className={styles.me_content}>
          <BaseUserAvatar>
            <h4 className="color--text-primary headline-h4 sf-text-bold">{userInitials}</h4>
          </BaseUserAvatar>
          {role && <span className="text--primary sf-text-regular color--text-primary">{role}</span>}
          {locoloAccessIcon}
        </div>
      </div>
      <div className={styles.users}>
        <div className={styles.users_head}>
          <h3 className="headline-h3 sf-bold">Участники вашей группы:</h3>
          <div>
            <BaseButton startIcon={addIcon24()} onClick={allowAccess}>
              Предоставить доступ
            </BaseButton>
          </div>
        </div>
        <div className={styles.users_list}>
          {isLoading ? (
            <div className={styles.users_loading}>
              <Spinner size={36} />
            </div>
          ) : (
            users.map((user) => (
              <div className={styles.user_item} key={user.Id}>
                <h4 className="color--text-primary headline-h4 sf-text-bold">{user.User.Phone.formatPhone()}</h4>
                <span className="text--primary sf-text-regular color--text-primary">
                  {[user.Role.Value, company?.Name].join(" – ")}
                </span>
                {locoloAccessIcon}
                {isUK && user.CanUpdate && (
                  <BaseTooltip title="Редактировать">
                    <BaseButton style={{ padding: 0 }} variant="icon-basic" onClick={() => showEditUserRoleModal(user)}>
                      {editIcon("#A8ACB1")}
                    </BaseButton>
                  </BaseTooltip>
                )}
                {user.CanDelete && (
                  <BaseTooltip title="Удалить">
                    <BaseButton style={{ padding: 0 }} variant="icon-basic" onClick={() => showRemoveAccessModal(user)}>
                      {deleteGarbageIcon("#A8ACB1")}
                    </BaseButton>
                  </BaseTooltip>
                )}
              </div>
            ))
          )}
        </div>
      </div>

      <SetRoleForUserModal />
      <ErrorModal />
      <SuccessModal />
      <CreateUserModal />
      <SetUserAddressModal />

      {selectedUser && (
        <RemoveUserAccessModal
          user={selectedUser}
          isOpen={isRemoveAccessModalShown}
          onClose={() => setRemoveAccessModalShown(false)}
        />
      )}

      {selectedUser && (
        <EditUserRoleModal user={selectedUser} isOpen={isEditModalShown} onClose={() => setEditModalShown(false)} />
      )}
    </>
  );
};

export default AccessSettings;
