import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import OccStatus from "@/components/occ-status";
import styles from "./styles.module.scss";
import { Profile } from "@/types";

const SntMyOCCItemDetails: React.FC = () => {
  const company = useSelector((state: RootState) => state.user.company);
  const occ = useSelector((state: RootState) => state.sntMyOcc.occ);

  return (
    <div className={styles.details}>
      <div>
        <span className="text--primary sf-text-regular">Номер собрания:</span>
        <span className="text--primary sf-text-semibold color--text-primary">{occ?.Order.Number}</span>
      </div>

      <div>
        <span className="text--primary sf-text-regular">Дата и время инициализации:</span>
        <span className="text--primary sf-text-semibold color--text-primary">
          {occ?.Order?.PublicationTime
            ? new Date(occ?.Order?.PublicationTime?.parseFromEpochSeconds()).formatDateTime()
            : occ?.Order?.PublicationDate}
        </span>
      </div>

      <div>
        <span className="text--primary sf-text-regular">Форма собрания:</span>
        <span className="text--primary sf-text-semibold color--text-primary">{occ?.Order.FormName}</span>
      </div>

      <div>
        <span className="text--primary sf-text-regular">Период голосования:</span>
        <span className="text--primary sf-text-semibold color--text-primary">
          {occ?.Order?.StartTime
            ? new Date(occ?.Order?.StartTime?.parseFromEpochSeconds()).formatByPattern("dd.MM.yyyy")
            : occ?.Order?.StartDate}{" "}
          -{" "}
          {occ?.Order?.EndTime
            ? new Date(occ?.Order?.EndTime?.parseFromEpochSeconds()).formatByPattern("dd.MM.yyyy")
            : occ?.Order?.EndDate}
        </span>
      </div>

      <div>
        <span className="text--primary sf-text-regular">Статус:</span>
        <span className="text--primary">
          {occ && <OccStatus statusId={occ?.Order.StatusId} statusName={occ?.Order.StatusName} />}
        </span>
      </div>

      {company?.Type === Profile.CompanyType.UK && (
        <div>
          <span className="text--primary sf-text-regular">Адрес:</span>
          <span className="text--primary">{occ?.Order.Address}</span>
        </div>
      )}
    </div>
  );
};

export default SntMyOCCItemDetails;
