import { useMemo } from "react";
import BaseInput from "@/components/base-input";
import PhoneInput from "@/components/base-input/phoneInput";
import { clearFieldIcon } from "@/consts/icons";
import styles from "./styles.module.scss";

const sx = {
  "&": {
    fontSize: "22px",
    fontFamily: "SF Pro Text",
    fontWeight: "bold",
    height: "46px",
    borderRadius: "0",
    outline: "none",
    paddingRight: "0",
  },
  "& .MuiInputBase-input": {
    padding: "0",
    height: "46px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRight: "none",
    borderLeft: "none",
    borderTop: "none",
    borderColor: "rgba(43, 57, 73, 0.16)",
  },
};

interface IBaseInputProps {
  value: string;
  onChange?: (value: string) => void;
  label?: string;
  type?: "text" | "password" | "phone";
  placeholder?: string;
  name?: string;
  endAdornment?: React.ReactNode;
  errorMessage?: string;
}

const AuthorizationField = ({
  value,
  onChange,
  label,
  type = "text",
  placeholder,
  name,
  endAdornment,
  errorMessage,
}: IBaseInputProps) => {
  const props = useMemo(() => {
    return {
      value,
      name,
      placeholder,
      onChange: handleChange,
      sx,
      errorMessage,
      endAdornment: (
        <span className={styles["field__adornment"]}>
          {endAdornment}
          <span onClick={clearField}>{clearFieldIcon()}</span>
        </span>
      ),
    };
  }, [value, name, type, errorMessage]);

  function handleChange(value: string) {
    if (onChange) {
      onChange(value);
    }
  }

  function clearField() {
    if (onChange) {
      onChange("");
    }
  }

  return (
    <div className={styles["field"]}>
      {label && <p className={`color--text-placeholder sf-text-regular ${styles["label"]}`}>{label}</p>}
      {type === "phone" ? <PhoneInput type="text" {...props} /> : <BaseInput type={type} {...props} />}
    </div>
  );
};

export default AuthorizationField;
