import { useEffect, useMemo, useState } from "react";
import { BaseDropdown } from "@/components/base-dropdown";
import PeriodYearsPickerOptions from "./options";

interface IPeriodYearsPickerProps {
  start?: number;
  end?: number;
  onChange: ({ start, end }: { start: number | undefined; end: number | undefined }) => void;
}

const PeriodYearsPicker: React.FC<IPeriodYearsPickerProps> = ({ start, end, onChange }) => {
  const [startYear, setStartYear] = useState<number>();
  const [endYear, setEndYear] = useState<number>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const display = useMemo(() => {
    if (isOpen) {
      if (startYear === endYear) return startYear?.toString();
      else return [startYear, endYear].filter((it) => !!it).join("-");
    } else {
      if (start === end) return start?.toString();
      else return [start, end].filter((it) => !!it).join("-");
    }
  }, [isOpen, startYear, endYear, start, end]);

  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    let years = [];
    for (let i = currentYear; i >= 2020; i--) {
      years.push(i);
    }
    return years;
  }, []);

  function onYearSelect(value: number) {
    if (value === startYear) {
      if (value === endYear) {
        setStartYear(undefined);
        setEndYear(undefined);
        return;
      }

      if (endYear && endYear > startYear) {
        setStartYear(value + 1);
        return;
      }
    }

    if (value === endYear && startYear && startYear < endYear) {
      setEndYear(value - 1);
      return;
    }

    if (!startYear && !endYear) {
      setStartYear(value);
      setEndYear(value);
      return;
    }

    if (startYear && value >= startYear) {
      setEndYear(value);
      return;
    }

    if (startYear && value < startYear) {
      setStartYear(value);
      return;
    }
  }

  function apply() {
    if (onChange) {
      onChange({ start: startYear, end: endYear });
    }
  }

  function reset() {
    setStartYear(start ?? undefined);
    setEndYear(end ?? undefined);
  }

  useEffect(() => {
    reset();
  }, [start, end]);

  return (
    <BaseDropdown
      display={display}
      placeholder={`2020-${new Date().getFullYear()}`}
      variant="formfield"
      onSelect={onYearSelect}
    >
      <PeriodYearsPickerOptions
        years={years}
        startYear={startYear}
        endYear={endYear}
        setStartYear={setStartYear}
        setEndYear={setEndYear}
        applyYears={apply}
        reset={reset}
        setOpen={setIsOpen}
      />
    </BaseDropdown>
  );
};

export default PeriodYearsPicker;
