import { useContext, useMemo } from "react";
import { UKRegistry } from "@/types";
import AccordionContext from "@/components/base-accordion/context";
import BaseButton from "@/components/base-button";
import ApartmentСomparisonAccordionCard from "../AccordionCard";
import { RegistryDetailsTableCard, RegistryDetailsTableGroup } from "../RegistryDetailsTableCard";
import styles from "../styles.module.scss";

interface IOwnerDetailsСomparisonProps {
  comparison: UKRegistry.RosreestrСomparisonDetailsMapped["owners"][0];
  id: string;
  isApartmentInMyReestr: boolean;
  isApartmentInRosreestr: boolean;
  updateData: (key: keyof UKRegistry.OwnerСomparison, updating: boolean) => void;
  onAdd: () => void;
  onRemove: () => void;
}

const OwnerDetailsСomparison: React.FC<IOwnerDetailsСomparisonProps> = ({
  comparison,
  id,
  isApartmentInMyReestr,
  isApartmentInRosreestr,
  updateData,
  onAdd,
  onRemove,
}) => {
  const context = useContext(AccordionContext);

  const successText: string = useMemo(() => {
    if (!comparison.initial.Reestr && comparison.initial.Rosreestr && comparison.isCreated) {
      return "Добавлен";
    }

    return "";
  }, [comparison]);

  const errorText: string = useMemo(() => {
    if (
      isApartmentInRosreestr &&
      isApartmentInMyReestr &&
      !comparison.initial.Reestr &&
      comparison.initial.Rosreestr &&
      !comparison.isCreated
    ) {
      return "Собственника нет в вашем реестре, но есть в Росреестре";
    }

    if (isApartmentInRosreestr && isApartmentInMyReestr && comparison.initial.Reestr && !comparison.initial.Rosreestr) {
      return comparison.isDeleted ? "Удален" : "Собственник есть в вашем реестре, но его нет в Росреестре";
    }

    if (comparison.initial.Reestr && comparison.initial.Rosreestr && comparison.initial.Differences.length > 0) {
      return "Расхождения с данными Росреестра";
    }

    return "";
  }, [comparison, isApartmentInRosreestr, isApartmentInMyReestr]);

  function handleUpdateData(key: keyof UKRegistry.OwnerСomparison, updating: boolean) {
    if (updateData) {
      updateData(key, updating);
    }
  }

  function addToMyReestr(event: React.SyntheticEvent) {
    event.stopPropagation();
    if (!comparison.isCreated) {
      context?.handleOpen("");
    }

    onAdd();
  }

  function removeFromMyReestr(event: React.SyntheticEvent) {
    event.stopPropagation();
    if (!comparison.isDeleted) {
      context?.handleOpen("");
    }

    onRemove();
  }

  return (
    <ApartmentСomparisonAccordionCard
      title={comparison.initial.FullName}
      id={id}
      succesText={successText}
      errorText={errorText}
      togglerPrefix="данные собственника"
      actionButton={
        isApartmentInRosreestr &&
        isApartmentInMyReestr &&
        comparison.initial.Reestr &&
        !comparison.initial.Rosreestr ? (
          comparison.isDeleted ? (
            <BaseButton size="medium" onClick={removeFromMyReestr}>
              Вернуть
            </BaseButton>
          ) : (
            <BaseButton size="medium" onClick={removeFromMyReestr}>
              Удалить собственника
            </BaseButton>
          )
        ) : isApartmentInRosreestr &&
          isApartmentInMyReestr &&
          !comparison.initial.Reestr &&
          comparison.initial.Rosreestr ? (
          comparison.isCreated ? (
            <BaseButton size="medium" onClick={addToMyReestr}>
              Не добавлять
            </BaseButton>
          ) : (
            <BaseButton size="medium" onClick={addToMyReestr}>
              Добавить собственника
            </BaseButton>
          )
        ) : null
      }
    >
      <div className={styles.accordion_content}>
        <h5 className="headline-h5 sf-text-medium color--text-secondary">ДАННЫЕ СОБСТВЕННИКА:</h5>
        <div>
          {comparison.value && (
            <RegistryDetailsTableCard title="В моем реестре:">
              <RegistryDetailsTableGroup
                title="Вид собственности:"
                value={comparison.value.TypeOwnership ?? "–"}
                isHasDifference={
                  comparison.initial.Differences.includes("TypeOwnership") &&
                  comparison.initial.Reestr?.TypeOwnership !== comparison.initial.Rosreestr?.TypeOwnership
                }
                comparison={comparison.initial.Rosreestr?.TypeOwnership}
                toggleUpdate={(updating) => handleUpdateData("TypeOwnership", updating)}
              />
              <RegistryDetailsTableGroup
                title="Доля:"
                value={comparison.value.SizeShares}
                isHasDifference={
                  comparison.initial.Differences.includes("SizeShares") &&
                  comparison.initial.Reestr?.SizeShares !== comparison.initial.Rosreestr?.SizeShares
                }
                comparison={comparison.initial.Rosreestr?.SizeShares ?? "–"}
                toggleUpdate={(updating) => handleUpdateData("SizeShares", updating)}
              />
              <RegistryDetailsTableGroup
                title="Документ о праве собственности:"
                value={comparison.value.DateRecordNumber ?? "–"}
                isHasDifference={
                  comparison.initial.Differences.includes("DateRecordNumber") &&
                  comparison.initial.Reestr?.DateRecordNumber !== comparison.initial.Rosreestr?.DateRecordNumber
                }
                comparison={comparison.initial.Rosreestr?.DateRecordNumber ?? "–"}
                toggleUpdate={(updating) => handleUpdateData("DateRecordNumber", updating)}
              />
              <RegistryDetailsTableGroup
                title="Дата выдачи документа:"
                value={comparison.value.RecordDatetime ?? "–"}
                isHasDifference={
                  comparison.initial.Differences.includes("RecordDatetime") &&
                  comparison.initial.Reestr?.RecordDatetime !== comparison.initial.Rosreestr?.RecordDatetime
                }
                comparison={comparison.initial.Rosreestr?.RecordDatetime ?? "–"}
                toggleUpdate={(updating) => handleUpdateData("RecordDatetime", updating)}
              />
              <RegistryDetailsTableGroup
                title="ОГРН:"
                value={comparison.value.Ogrn ?? "–"}
                isHasDifference={
                  comparison.initial.Differences.includes("Ogrn") &&
                  comparison.initial.Reestr?.Ogrn !== comparison.initial.Rosreestr?.Ogrn
                }
                comparison={comparison.initial.Rosreestr?.Ogrn ?? "–"}
                toggleUpdate={(updating) => handleUpdateData("Ogrn", updating)}
              />
            </RegistryDetailsTableCard>
          )}

          {comparison.initial.Rosreestr && (
            <RegistryDetailsTableCard title="В Росреестре:">
              <RegistryDetailsTableGroup
                title="Вид собственности:"
                value={comparison.initial.Rosreestr.TypeOwnership ?? "–"}
              />
              <RegistryDetailsTableGroup title="Доля:" value={comparison.initial.Rosreestr.SizeShares ?? "–"} />
              <RegistryDetailsTableGroup
                title="Документ о праве собственности:"
                value={comparison.initial.Rosreestr.DateRecordNumber ?? "–"}
              />
              <RegistryDetailsTableGroup
                title="Дата выдачи документа:"
                value={comparison.initial.Rosreestr.RecordDatetime ?? "–"}
              />
              <RegistryDetailsTableGroup title="ОГРН:" value={comparison.initial.Rosreestr.Ogrn ?? "–"} />
            </RegistryDetailsTableCard>
          )}
        </div>
      </div>
    </ApartmentСomparisonAccordionCard>
  );
};

export default OwnerDetailsСomparison;
