import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { customStylePaths } from "./custom-style-paths";
import styles from "./styles.module.scss";

interface IContentProps {
  children: JSX.Element | JSX.Element[];
}

const Content: React.FC<IContentProps> = ({ children }) => {
  const { pathname } = useLocation();
  const urlParams = useParams();

  const style: React.CSSProperties = useMemo(() => {
    return customStylePaths(urlParams).some((it) => pathname.startsWith(it))
      ? { backgroundColor: "#ebecef", padding: 0 }
      : {};
  }, [pathname, urlParams]);

  return (
    <main className={styles.content} style={style}>
      {children}
    </main>
  );
};

export default Content;
