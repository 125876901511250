import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BaseButton from "@/components/base-button";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import BaseInput from "@/components/base-input";
import BaseTextarea from "@/components/base-textarea";
import Checkbox from "@/components/checkbox";
import BaseFileItem from "@/components/base-file-item";
import { RootState } from "@/app/store";
import { OCC, Profile } from "@/types";
import styles from "./styles.module.scss";

interface IQuestionDetailsModalProps {
  isOpen: boolean;
  selectedQuestion?: OCC.Question | null;
  onClose: () => void;
}

const QuestionDetailsModal: React.FC<IQuestionDetailsModalProps> = ({ isOpen, selectedQuestion, onClose }) => {
  const company = useSelector((state: RootState) => state.user.company);
  const quorumsList = useSelector((state: RootState) => state.dictionary.quorums.list);
  const [form, setForm] = useState<OCC.CreateOCCQuestionForm>(new OCC.CreateOCCQuestionForm());

  useEffect(() => {
    setForm(new OCC.CreateOCCQuestionForm(selectedQuestion ?? undefined, quorumsList));
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} minWidth="670px" maxWidth="670px">
      <BaseModalHeader title="Просмотр вопроса" onClose={onClose} />

      <BaseModalContent sx={{ padding: "16px 24px" }}>
        <form className={styles.from}>
          {company?.Type === Profile.CompanyType.SNT && (
            <div>
              <div className="flex middle" style={{ gap: "10px" }}>
                <Checkbox disabled={true} checked={form.AvailableForAllSectionOwner} />
                <p className="sf-text-regular text--primary">
                  Голосование доступно для собственников, не являющихся членами садоводства
                </p>
              </div>
            </div>
          )}

          <div>
            <BaseDropdown
              disabled={true}
              value={form.Quorum?.id ?? null}
              display={form.Quorum?.name}
              isSelectable={true}
              label="Кворум по вопросу повестки"
              tooltipMessage="Общее собрание считается правомочным, если в нем приняли участие более чем 50% членов садоводства. Результаты голосования по вопросу повестки рассчитываются исходя из количества принявших участие в общем собрании."
              placeholder="Не менее 2/3 от голосов, принявших участие в голосовании"
              variant="formfield"
              required={true}
            >
              {quorumsList.map((quorum) => (
                <BaseDropdownMenuItem key={quorum.id} value={quorum.id}>
                  <span className="sf-text-medium">{quorum.name}</span>
                </BaseDropdownMenuItem>
              ))}
            </BaseDropdown>
          </div>

          <div>
            <BaseInput
              disabled={true}
              value={form.Title.value}
              label="Заголовок вопроса"
              required={form.Title.isRequired}
              placeholder="Например: Выбрать систему для проведения общих собраний"
              errorMessage={form.Title.error}
              maxlength={form.Title.maxlength}
              counter={true}
            />
          </div>

          <div>
            <BaseTextarea
              minRows={1}
              disabled={true}
              value={form.Value.value}
              label="Текст вопроса"
              required={form.Value.isRequired}
              tooltipMessage="Введите текст вопроса. Опишите предполагаемый вариант решения"
              placeholder="Например: Применять государственную информационную систему Республики Татарстан «Локоло» при проведении общих собраний в форме заочного голосования с использованием системы"
              errorMessage={form.Value.error}
              maxlength={form.Value.maxlength}
              counter={true}
            />
          </div>

          {form.Files.length > 0 && (
            <div>
              <h4 className="headline-h4 sf-text-bold">Прикрепленные файлы:</h4>

              <div className={styles.files_list}>
                {form.Files.map((file) => (
                  <BaseFileItem key={file.Id} file={file}>
                    <a href={file.URL} className="sf-text-regular color--primary" download>
                      Скачать
                    </a>
                  </BaseFileItem>
                ))}
              </div>
            </div>
          )}
        </form>
      </BaseModalContent>

      <BaseModalActions variant="bordered">
        <BaseButton onClick={onClose}>Назад</BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default QuestionDetailsModal;
