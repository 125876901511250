import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SntRegistry, Pagination } from "@/types";

export interface SntRegistryState {
  details: SntRegistry.SntDetails | null;
  isLoading: boolean;
  isSntOwner: boolean | null;
  query: string;
  sections: SntRegistry.Section[];
  isSectionsLoading: boolean;
  pagination: Pagination | null;
  isInstructionsModalOpen: boolean;
  deleteModal: {
    isOpen: boolean;
    isLoading: boolean;
    section: SntRegistry.Section | null;
  };
  editSectionModal: {
    mode: "view" | "add" | "edit" | null;
    isOpen: boolean;
    isLoading: boolean;
    sectionId: SntRegistry.SectionIds["Id"] | null;
  };
}

const initialState: SntRegistryState = {
  details: null,
  isLoading: false,
  isSntOwner: null,
  query: "",
  sections: [],
  isSectionsLoading: false,
  pagination: null,
  isInstructionsModalOpen: false,
  deleteModal: {
    isOpen: false,
    isLoading: false,
    section: null,
  },
  editSectionModal: {
    mode: null,
    isOpen: false,
    isLoading: false,
    sectionId: null,
  },
};

const sntRegistrySlice = createSlice({
  name: "snt-registry",
  initialState,
  reducers: {
    setDetails: (state, action: PayloadAction<SntRegistry.SntDetails>) => {
      state.details = action.payload;
    },

    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },

    toggleSntOwner: (state, action: PayloadAction<boolean | null>) => {
      state.isSntOwner = action.payload;
    },

    setPagination: (state, action: PayloadAction<SntRegistryState["pagination"]>) => {
      state.pagination = action.payload;
    },

    toggleSectionsLoading: (state, action: PayloadAction<boolean>) => {
      state.isSectionsLoading = action.payload;
    },

    setSections: (state, action: PayloadAction<SntRegistry.Section[]>) => {
      state.sections = action.payload;
    },

    pushSections: (state, action: PayloadAction<SntRegistry.Section[]>) => {
      state.sections.push(...action.payload);
    },

    toggleInstructions: (state, action: PayloadAction<boolean>) => {
      state.isInstructionsModalOpen = action.payload;
    },

    toggleDeleteModal: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        section?: SntRegistryState["deleteModal"]["section"];
      }>
    ) => {
      if (action.payload.isOpen && action.payload.section) {
        state.deleteModal.isOpen = action.payload.isOpen;
        state.deleteModal.section = action.payload.section;
        return;
      }

      state.deleteModal.isOpen = false;
    },

    toggleDeleteModalLoading: (state, action: PayloadAction<boolean>) => {
      state.deleteModal.isLoading = action.payload;
    },

    removeSectionFromList: (state, action: PayloadAction<SntRegistry.SectionIds["Id"]>) => {
      const index = state.sections.findIndex((it) => it.Section.Id === action.payload);
      if (index >= 0) {
        state.sections.splice(index, 1);
      }
    },

    updateSectionState: (state, action: PayloadAction<SntRegistry.Section>) => {
      const sections = state.sections.map((it) => {
        if (it.Section.Id === action.payload.Section.Id) {
          return { ...action.payload };
        }
        return it;
      });
      state.sections = sections;
    },

    toggleEditSectionModal: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        mode?: SntRegistryState["editSectionModal"]["mode"];
        sectionId?: SntRegistryState["editSectionModal"]["sectionId"];
      }>
    ) => {
      if (action.payload.isOpen) {
        state.editSectionModal.isOpen = action.payload.isOpen;
        state.editSectionModal.mode = action.payload.mode ?? "view";
        state.editSectionModal.sectionId = action.payload.sectionId ?? null;
        return;
      }

      state.editSectionModal.isOpen = false;
    },

    toggleEditSectionModalLoading: (state, action: PayloadAction<boolean>) => {
      state.editSectionModal.isLoading = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setDetails,
  toggleLoading,
  toggleSntOwner,
  setQuery,
  setPagination,
  toggleSectionsLoading,
  setSections,
  pushSections,
  toggleInstructions,
  toggleDeleteModal,
  toggleDeleteModalLoading,
  removeSectionFromList,
  updateSectionState,
  toggleEditSectionModal,
  toggleEditSectionModalLoading,
} = sntRegistrySlice.actions;

export default sntRegistrySlice.reducer;
