import { Fragment, Children } from "react";
import styles from "./styles.module.scss";

interface IBaseTableToolbarProps {
  children?: JSX.Element | JSX.Element[];
  caption?: JSX.Element | JSX.Element[] | string | null;
}

const BaseTableToolbar = ({ caption, children }: IBaseTableToolbarProps) => {
  const childs = Children.toArray(children);
  return (
    <div className={styles.toolbar}>
      <span className="text--primary sf-text-regular color--text-secondary">{caption}</span>
      <div className={styles.tools}>
        {childs.length > 1 && <span className="text--primary sf-text-regular">Выберите действие:</span>}
        <div>
          {childs.map((element, index) => {
            const isLast = index === childs.length - 1;
            return (
              <Fragment key={index}>
                <div className={styles.tool_item}>{element}</div>
                {!isLast && <div className={styles.separator}></div>}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BaseTableToolbar;
