import { forwardRef } from "react";
import StyledInput from "./styled-input";
import styles from "./styles.module.scss";
import BaseLabel from "../BaseLabel";
import { IMaskInput } from "react-imask";

interface ICadastralNumberInputProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  tooltipMessage?: string;
  placeholder?: string;
  errorMessage?: string;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMask = forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      mask={"00:00:000000[0]:0[000]"}
      inputRef={ref}
      {...other}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
    />
  );
});

const CadastralNumberInput = ({
  value,
  onChange,
  label,
  type,
  disabled,
  required,
  tooltipMessage,
  placeholder,
  errorMessage,
}: ICadastralNumberInputProps) => {
  const handleChange = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div data-error={errorMessage} className={`${styles.container} ${!!errorMessage ? styles.has_error : ""}`}>
      {label && (
        <BaseLabel required={required} tooltipMessage={tooltipMessage}>
          {label}
        </BaseLabel>
      )}

      <StyledInput
        value={value}
        placeholder={placeholder}
        error={!!errorMessage}
        type={type ?? "text"}
        fullWidth
        disabled={disabled}
        onChange={(e) => handleChange(e.target.value)}
        inputComponent={TextMask as any}
      />
    </div>
  );
};

export default CadastralNumberInput;
