import { Dictionary } from "@/types/dictionary";
import { OCCV2Order } from "../order";

export namespace OCCV2Questions {
  export type Item = {
    Id: number;
    QuorumId: Dictionary.DictionaryItem<Dictionary.Quorum>["id"];
    Title: string;
    Value: string;
    Order: number;
    OnlyHorticultureMembers: boolean;
    Files: Dictionary.File[];
  };

  export type CreateItem = {
    Id?: Item["Id"];
    QuorumId: Dictionary.DictionaryItem<Dictionary.Quorum>["id"];
    Title: string;
    Value: string;
    Order?: number;
    AvailableForAllSectionOwner: boolean;
    FileIds: Array<Dictionary.File["Id"]>;
    Files?: Array<Dictionary.File>;
  };

  export type CreateBody = {
    OccId: OCCV2Order.Base["Id"];
    Questions: CreateItem[];
  };
}

export * from "./forms";
