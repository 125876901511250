import { UKRegistry } from "@/types";
import { chevronRight24Icon } from "@/consts/icons";
import { BaseTableRow, BaseTableHeadCell, BaseTableCell } from "@/components/base-table";
import RosreestrApartmentStatus from "../ApartmentStatus";
import styles from "../styles.module.scss";

interface IApartmentRow {
  apartment: UKRegistry.RosreestrСomparison;
  onSelect: () => void;
}

const RosreestrApartmentRow: React.FC<IApartmentRow> = ({ apartment, onSelect }) => {
  function handleSelect() {
    onSelect();
  }

  return (
    <BaseTableRow className={styles.apartment_row} onClick={handleSelect}>
      <BaseTableHeadCell>{apartment.Number}</BaseTableHeadCell>
      <BaseTableCell>{apartment.Area}</BaseTableCell>
      <BaseTableCell>{apartment.CadastralNumber}</BaseTableCell>
      <BaseTableCell>{apartment.Type}</BaseTableCell>
      <BaseTableCell>
        <RosreestrApartmentStatus status={apartment.Status.Key} name={apartment.Status.Message} />
      </BaseTableCell>
      <BaseTableCell>{chevronRight24Icon("#898D93")}</BaseTableCell>
    </BaseTableRow>
  );
};

export default RosreestrApartmentRow;
