import UsefulCompaniesContacts from "@/features/useful-contacts";
import AddContact from "./modals/add-contact";
import RemoveContact from "./modals/remove-contact";
import { useAppDispatch } from "@/app/store";
import { toggleEditModal } from "@/app/store/slices/contacts";
import { addIcon24 } from "@/consts/icons";
import BaseButton from "@/components/base-button";
import PageHeader from "@/components/PageHeader";

const Contacts = () => {
  const dispatch = useAppDispatch();
  const handleToggleAddModal = () => {
    dispatch(toggleEditModal({ isOpen: true }));
  };

  return (
    <>
      <PageHeader title="Полезные контакты">
        <BaseButton startIcon={addIcon24()} onClick={handleToggleAddModal}>
          Добавить контакт
        </BaseButton>
      </PageHeader>
      <UsefulCompaniesContacts />
      <AddContact />
      <RemoveContact />
    </>
  );
};

export default Contacts;
