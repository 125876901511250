import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { CSSTransition } from "react-transition-group";
import { useSearchParams } from "react-router-dom";
import { paths } from "@/consts/routes";
import { SntPassportMap } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import {
  selectMarker,
  fetchSntMarkers,
  setDeleteOpen,
  setState,
  setGeopoint,
  setSelectSntModalOpen,
  updateSntGeopoint,
} from "@/app/store/slices/snt/sntsMap";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import { addIcon24, chevronRight24Icon, closeIcon24 } from "@/consts/icons";
import PageHeader from "@/components/PageHeader";
import BaseButton from "@/components/base-button";
import Mapbox from "@/features/Mapbox";
import DeleteMapMakerModal from "./modals/DeleteMarker";
import SelectSntForGeopointModal from "./modals/SelectSntForGeopoint";
import styles from "./styles.module.scss";

const InstitutionSntsMap: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  const { markers, state, geopoint, selected } = useSelector((state: RootState) => state.sntsMap);

  const selectedDetailsRef = useRef<HTMLDivElement>(null);

  function _selectMarker(sntId: SntPassportMap.SntItem["Id"]) {
    setSearchParams({ id: sntId.toString() });
  }

  function setNewMarkerPoint(geo: SntPassportMap.Geopoint | null) {
    dispatch(setGeopoint(geo));
  }

  function toggleState(_state: SntPassportMap.MapState) {
    dispatch(setState(_state));
  }

  function closeSidebar() {
    setSearchParams();
  }

  function openDeleteModal() {
    dispatch(setDeleteOpen(true));
  }

  function applyGeopoint() {
    if (selected.snt) {
      dispatch(updateSntGeopoint());
    } else {
      dispatch(setSelectSntModalOpen(true));
    }
  }

  function changeSnt() {
    dispatch(setSelectSntModalOpen(true));
  }

  function navigateSntCard(sntId: SntPassportMap.SntItem["Id"] | null) {
    if (!sntId) {
      return;
    }

    navigate(paths.institution(sntId.toString()).fullPath().sntItem, { state: location.pathname + location.search });
  }

  async function fetchSntDetails() {
    const sntId = searchParams.get("id");
    if (!sntId) {
      dispatch(selectMarker(null));
      if (state === SntPassportMap.MapState.ChangeSntGeo) {
        toggleState(SntPassportMap.MapState.Default);
      }
      return;
    }

    dispatch(selectMarker(parseInt(sntId)));
  }

  useEffect(() => {
    dispatch(fetchSntMarkers());

    return () => {
      toggleState(SntPassportMap.MapState.Default);
      dispatch(selectMarker(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSntDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <div>
        <PageHeader title="Карта Республики Татарстан">
          <div className={styles["b-map-toolbar-buttons"]}>
            {state === SntPassportMap.MapState.Default ? (
              <BaseButton
                startIcon={addIcon24()}
                onClick={() => {
                  toggleState(SntPassportMap.MapState.AddMarker);
                  closeSidebar();
                }}
              >
                Новая метка
              </BaseButton>
            ) : (
              <>
                <BaseButton
                  variant="secondary"
                  onClick={() => {
                    toggleState(SntPassportMap.MapState.Default);
                    setNewMarkerPoint(null);
                  }}
                >
                  Отменить
                </BaseButton>
                <BaseButton disabled={!geopoint} isLoading={selected.isUpdateGeoLoading} onClick={applyGeopoint}>
                  Подтвердить место
                </BaseButton>
              </>
            )}
          </div>
        </PageHeader>

        <div
          ref={emptyBlockRef}
          className={styles["b-map-container"]}
          style={{
            minHeight: `${emptyBlockHeight + 24}px`,
          }}
        >
          {emptyBlockHeight !== 0 && (
            <Mapbox
              markers={markers}
              state={state}
              newMarkerPoint={geopoint}
              onMarkerAdd={(point) => setNewMarkerPoint(point)}
              onMarkerSelect={(sntId) => _selectMarker(sntId)}
            />
          )}

          <CSSTransition
            in={!!selected.snt && state === SntPassportMap.MapState.Default}
            nodeRef={selectedDetailsRef}
            timeout={300}
            classNames={{ ...styles }}
            unmountOnExit
          >
            <div ref={selectedDetailsRef} className={styles["b-selected-marker"]}>
              <div className={styles["b-selected-marker__header"]}>
                <h3 className="headline-h3 sf-bold color--text-primary">О метке</h3>
                <BaseButton variant="icon-basic" style={{ padding: "0" }} onClick={closeSidebar}>
                  {closeIcon24()}
                </BaseButton>
              </div>

              {selected.snt && (
                <div className={styles["b-selected-marker__content"]}>
                  <div className={selected.isLoading ? styles["is-loading"] : ""}>
                    <div className={styles["b-selected-marker__content_card"]}>
                      <div
                        className="flex middle between"
                        onClick={() => navigateSntCard(selected.snt?.Id ?? null)}
                        role="button"
                      >
                        <h4 className="headline-h4 sf-text-bold color--text-primary">{selected.snt?.Name ?? ""}</h4>
                        {chevronRight24Icon("var(--icons-ghost)")}
                      </div>

                      <span
                        className={`flex middle ${!selected.snt.Geopoint ? styles["disabled"] : ""}`}
                        role="button"
                        onClick={changeSnt}
                      >
                        <span className="sf-text-regular text--primary color--primary">Изменить</span>
                        {chevronRight24Icon("var(--main-primary)")}
                      </span>
                    </div>

                    <div className={styles["b-selected-marker__content_details"]}>
                      <div>
                        <h4 className="headline-h4 sf-text-bold color--text-primary">ИНН:</h4>
                        <p className="text--primary sf-text-regular color--text-secondary">{selected.snt?.Inn ?? ""}</p>
                      </div>
                      <div>
                        <h4 className="headline-h4 sf-text-bold color--text-primary">Учетная запись в Локоло:</h4>
                        <p className="text--primary sf-text-regular">
                          {selected.snt?.LokoloUserExists ? (
                            <span className="color--text-success">Есть</span>
                          ) : (
                            <span className="color--text-error">Нет</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  {selected.snt.Geopoint && (
                    <div className={styles["b-selected-marker__footer"]}>
                      <BaseButton
                        disabled={selected.isLoading}
                        startIcon={
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6.5 7C7.05228 7 7.5 6.55228 7.5 6C7.5 5.44772 7.05228 5 6.5 5C5.94772 5 5.5 5.44772 5.5 6C5.5 6.55228 5.94772 7 6.5 7ZM6.5 9C7.80622 9 8.91746 8.16519 9.32929 7H17.5C18.6046 7 19.5 7.89543 19.5 9C19.5 10.1046 18.6046 11 17.5 11H7.5C5.29086 11 3.5 12.7909 3.5 15C3.5 17.2091 5.29086 19 7.5 19H15.6707C16.0825 20.1652 17.1938 21 18.5 21C20.1569 21 21.5 19.6569 21.5 18C21.5 16.3431 20.1569 15 18.5 15C17.1938 15 16.0825 15.8348 15.6707 17H7.5C6.39543 17 5.5 16.1046 5.5 15C5.5 13.8954 6.39543 13 7.5 13H17.5C19.7091 13 21.5 11.2091 21.5 9C21.5 6.79086 19.7091 5 17.5 5H9.32929C8.91746 3.83481 7.80622 3 6.5 3C4.84315 3 3.5 4.34315 3.5 6C3.5 7.65685 4.84315 9 6.5 9ZM19.5 18C19.5 18.5523 19.0523 19 18.5 19C17.9477 19 17.5 18.5523 17.5 18C17.5 17.4477 17.9477 17 18.5 17C19.0523 17 19.5 17.4477 19.5 18Z"
                              fill="white"
                            />
                          </svg>
                        }
                        onClick={() => toggleState(SntPassportMap.MapState.ChangeSntGeo)}
                      >
                        Переместить метку
                      </BaseButton>
                      <BaseButton
                        variant="secondary"
                        appearance="negative"
                        disabled={selected.isLoading || !selected.snt.Geopoint}
                        onClick={openDeleteModal}
                      >
                        Удалить метку
                      </BaseButton>
                    </div>
                  )}
                </div>
              )}
            </div>
          </CSSTransition>
        </div>
      </div>

      <SelectSntForGeopointModal onSntChange={(snt) => _selectMarker(snt.Id)} />
      {selected.snt && <DeleteMapMakerModal />}
    </>
  );
};

export default InstitutionSntsMap;
