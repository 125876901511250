import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import { getQuestionsResultByOccId } from "@/app/store/slices/snt/myOcc/actions";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseContainer,
} from "@/components/base-table";
import styles from "./styles.module.scss";
import { Profile } from "@/types";

const SntMyOCCItemResultsQuestions: React.FC = () => {
  const dispatch = useAppDispatch();

  const company = useSelector((state: RootState) => state.user.company);
  const occ = useSelector((state: RootState) => state.sntMyOcc.occ);
  const questions = useSelector((state: RootState) => state.sntMyOcc.results.questions);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetch = async () => {
    setIsLoading(true);
    try {
      if (occ?.Order.Id) {
        await dispatch(getQuestionsResultByOccId(occ?.Order.Id)).unwrap();
      }
    } catch (error: any) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div>
      <BaseContainer className={styles.table}>
        <div className={styles.table_container}>
          <BaseTable>
            <BaseTableHead className={styles.table_head}>
              <BaseTableRow className={styles.table_row}>
                <BaseTableHeadCell>№ Вопроса</BaseTableHeadCell>
                <BaseTableHeadCell>Заголовок вопроса</BaseTableHeadCell>
                <BaseTableHeadCell>Проголосовало</BaseTableHeadCell>
                <BaseTableHeadCell>Размер кворума</BaseTableHeadCell>

                {company?.Type === Profile.CompanyType.SNT && (
                  <BaseTableHeadCell>Текущее значение ЗА</BaseTableHeadCell>
                )}

                {company?.Type === Profile.CompanyType.UK && (
                  <>
                    <BaseTableHeadCell>Текущее значение ЗА от площади</BaseTableHeadCell>
                    <BaseTableHeadCell>Текущее значение ЗА от проголосовавших</BaseTableHeadCell>
                  </>
                )}

                <BaseTableHeadCell>Кворум выполнен</BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            <BaseTableBody className={styles.table_body}>
              {questions.map((question) => (
                <BaseTableRow className={styles.table_row} key={question.QuestionId}>
                  <BaseTableHeadCell>
                    <span className="sf-text-semibold">{question.Order}</span>
                  </BaseTableHeadCell>

                  <BaseTableCell>
                    <span className="sf-text-semibold">{question.QuestionTitle}</span>
                  </BaseTableCell>

                  <BaseTableCell>
                    <span
                      className={`sf-text-semibold ${
                        question.QuorumCompleted ? styles.completed : styles.not_completed
                      }`}
                    >
                      {question.VotersPercentage} %
                    </span>
                  </BaseTableCell>

                  <BaseTableCell>
                    <span className="sf-text-semibold">{question.Quorum}</span>
                  </BaseTableCell>

                  {company?.Type === Profile.CompanyType.UK && (
                    <BaseTableCell>
                      <span
                        className={`sf-text-semibold ${
                          question.QuorumCompleted ? styles.completed : styles.not_completed
                        }`}
                      >
                        {question.QuorumPresentValuePercentage} %
                      </span>
                    </BaseTableCell>
                  )}

                  <BaseTableCell>
                    <span
                      className={`sf-text-semibold ${
                        question.QuorumCompleted ? styles.completed : styles.not_completed
                      }`}
                    >
                      {question.AgreePercentageTotalVotes} %
                    </span>
                  </BaseTableCell>

                  <BaseTableCell>
                    <span
                      className={`sf-text-semibold ${
                        question.QuorumCompleted ? styles.completed : styles.not_completed
                      }`}
                    >
                      {question.QuorumCompleted ? "Да" : " Нет"}
                    </span>
                  </BaseTableCell>
                </BaseTableRow>
              ))}
            </BaseTableBody>
          </BaseTable>
        </div>
      </BaseContainer>
    </div>
  );
};

export default SntMyOCCItemResultsQuestions;
