import { createContext } from "react";
import mapboxGl from "mapbox-gl";

type MapContextValue = {
  map?: InstanceType<typeof mapboxGl.Map> | null;
};

const MapContext = createContext<MapContextValue | undefined | null>(null);

export default MapContext;
