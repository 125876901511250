import BaseButton from "@/components/base-button";
import styles from "./styles.module.scss";

interface IIconButtonProps {
  children: JSX.Element | JSX.Element[];
  onClick?: React.EventHandler<any>;
}

const IconButton: React.FC<IIconButtonProps> = ({ children, onClick }) => (
  <BaseButton className={styles["b-icon-btn"]} variant="icon-basic" onClick={onClick}>
    {children}
  </BaseButton>
);
export default IconButton;
