export namespace Dictionary {
  export enum OCCStatus {
    Draft = "draft",
    OccPlanned = "occ_planned",
    OccIsComingStartMakingDecisionsAfterXDays = "occ_is_coming_start_making_decisions_after_X_days",
    OccIsComingDecisionMaking = "occ_is_coming_decision_making",
    OccIsComingProtocolFormation = "occ_is_coming_protocol_formation",
    CompletedProtocolPrepared = "completed_protocol_prepared",
    Archived = "archived",
    OccIsComingInitialDocumentsFormation = "occ_is_coming_initial_documents_formation",
    OccIsComingRegenerateDocuments = "occ_is_coming_regenerate_documents",
  }

  export enum OCCType {
    Annual = "annual",
    Unscheduled = "unscheduled",
  }

  export enum OCCForm {
    IntramuralExtramural = "intramural_extramural",
    Extramural = "extramural",
  }

  export enum OCCLocation {
    InformationSystemLokolo = "information_system_lokolo",
  }

  export enum Quorum {
    SimpleMajority = "simple_majority",
    FiftyPercentPlusOne = "fifty_percent_plus_one",
    TwoThirds = "two_thirds",
    Unanimity = "unanimity",
  }

  export type DictionaryItem<T = string> = {
    id: number;
    name: string;
    key: T;
  };

  export type File = {
    Id: number;
    DocumentId: number;
    URL: string;
    Name: string;
    Extension: string;
    Size: number;
  };

  export enum ApartmentType {
    Residential = "Жилое",
    NonResidential = "Нежилое",
  }

  export enum ApartmentOwnership {
    Owner = "Собственность",
    JointOwnership = "Совместная",
    EquityOwnership = "Долевая",
  }

  export type ApartmentDictionaryItem<T = string> = {
    Name: string;
    ShortName: T;
  };

  export type AlternativeDictionaryItem<T = string> = {
    Value: string;
    Key: T;
  };

  export type AddressSearchResult = {
    Value: string;
    City?: string | null;
    Street?: string | null;
    House?: string | null;
    Lat?: string | null;
    Lon?: string | null;
  };
}
