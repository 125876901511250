import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleDeleteModal } from "@/app/store/slices/uk/registry";
import { deleteApartment } from "@/app/store/slices/uk/registry/actions";
import BaseButton from "@/components/base-button";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { errorInfoIcon } from "@/consts/icons";

const DeleteApartmentModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isOpen, apartment, isLoading } = useSelector((state: RootState) => state.ukRegistry.deleteModal);

  const [isErrorShown, setErrorShown] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const closeModal = () => {
    dispatch(toggleDeleteModal({ isOpen: false }));
  };

  function closeErrorModal() {
    setErrorShown(false);
    closeModal();
  }

  const removeSection = () => {
    if (!apartment) {
      return;
    }

    dispatch(deleteApartment(apartment))
      .unwrap()
      .then((res) => {
        closeModal();
        dispatch(
          showSnackbar({
            key: `delete-apartment-${apartment.Id}`,
            body: "Помещение удалено",
            type: SnackbarType.SUCCESS,
          })
        );
      })
      .catch((err: any) => {
        if (err?.Data?.Errors?.Apartment?.length > 0) {
          setErrorMessage(err.Data.Errors.Apartment[0]);
        } else {
          setErrorMessage(err.Data.Message);
        }
        setErrorShown(true);
      });
  };

  return (
    <>
      <BaseModal isOpen={isOpen} maxWidth="520px" minWidth="520px">
        <BaseModalHeader title="Удалить помещение из реестра?" onClose={closeModal} />

        <BaseModalContent>
          <p className="text--primary sf-text-regular">
            Вы уверены что хотите удалить помещение №{apartment?.Number ?? "-"} из реестра?
          </p>
        </BaseModalContent>

        <BaseModalActions>
          <BaseButton variant="secondary" onClick={closeModal}>
            Отменить
          </BaseButton>

          <BaseButton isLoading={isLoading} onClick={removeSection}>
            Удалить
          </BaseButton>
        </BaseModalActions>
      </BaseModal>

      <BaseModal isOpen={isErrorShown} minWidth="520px">
        <BaseModalHeader variant="info-iconed" icon={errorInfoIcon} title={errorMessage} onClose={closeErrorModal} />

        <BaseModalActions>
          <BaseButton onClick={closeErrorModal}>Понятно</BaseButton>
        </BaseModalActions>
      </BaseModal>
    </>
  );
};

export default DeleteApartmentModal;
