import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Dictionary, UKRegistry } from "@/types";
import { UKRegistryService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleEditModal } from "@/app/store/slices/uk/registry";
import { createApartment, updateApartment } from "@/app/store/slices/uk/registry/actions";
import { Spinner } from "@/components/spinner";
import BaseButton from "@/components/base-button";
import BaseInput from "@/components/base-input";
import NumberInput from "@/components/base-input/NumberInput";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import EditOwnerModal from "../EditOwner";
import OwnerItem from "./OwnerItem";
import styles from "./styles.module.scss";

const EditApartmentModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const { isOpen, apartment, mode } = useSelector((state: RootState) => state.ukRegistry.editModal);
  const details = useSelector((state: RootState) => state.ukRegistry.details);
  const typesList = useSelector((state: RootState) => state.dictionary.apartmentType.list);

  const [isOwnerModalShown, setOwnerModalShown] = useState<boolean>(false);
  const [selectedOwner, selectOwner] = useState<UKRegistry.ApartmentOwner | UKRegistry.CreatedApartmentOwner | null>(
    null
  );
  const [form, setForm] = useState<UKRegistry.ApartmentForm>(new UKRegistry.ApartmentForm(details ?? undefined));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [subModalLeft, setSubModalLeft] = useState<number>(0);

  const title: string = useMemo(() => {
    switch (mode) {
      case "edit":
        return "Редактировать";
      case "view":
        return "Подробнее";

      default:
        return "Добавить помещение в реестр";
    }
  }, [mode]);

  const isReadonly: boolean = useMemo(() => {
    return mode === "view";
  }, [mode]);

  const updateFormFieldValue = (key: "Number" | "Area" | "CadastralNumber", value: string) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: { ...form[key], value },
    }));
  };

  const updateAppartmentType = (value: Dictionary.ApartmentDictionaryItem["ShortName"]) => {
    const type = typesList.find((it) => it.ShortName === value);
    if (type) {
      setForm((prevState) => ({
        ...prevState,
        Type: {
          ...prevState.Type,
          value: type,
        },
      }));
    }
  };

  function showOwnerModal() {
    setOwnerModalShown(true);
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setSubModalLeft(rect.left + 123);
    }
  }

  const showAddOwnerModal = () => {
    selectOwner(null);
    showOwnerModal();
  };

  const showEditOwnerModal = (owner: UKRegistry.ApartmentOwner | UKRegistry.CreatedApartmentOwner) => {
    selectOwner(owner);
    showOwnerModal();
  };

  const addOwner = (_owner: Omit<UKRegistry.CreatedApartmentOwner, "Id">) => {
    const owner: UKRegistry.CreatedApartmentOwner = {
      Id: -form.Owners.length,
      ..._owner,
    };

    const owners = new Set(form.Owners);
    owners.add(owner);

    const created = new Set(form.CreatedOwners);
    created.add(owner);

    setForm((prevState) => ({
      ...prevState,
      CreatedOwners: Array.from(created),
      Owners: Array.from(owners),
      OwnersError: [],
    }));
    setOwnerModalShown(false);
  };

  const updateOwner = (owner: UKRegistry.ApartmentOwner | UKRegistry.CreatedApartmentOwner) => {
    const owners = form.Owners.map((it) => {
      if (it.Id === owner.Id) {
        return {
          ...owner,
        };
      }
      return it;
    });
    const createdOwners = form.CreatedOwners.map((it) => {
      if (it.Id === owner.Id && !("LokoloUserId" in owner)) {
        return {
          ...owner,
        };
      }
      return it;
    });

    setForm((prevState) => ({
      ...prevState,
      CreatedOwners: createdOwners,
      Owners: owners,
    }));

    if ("Id" in owner && owner.Id > 0 && "LokoloUserId" in owner) {
      let updated = [...form.UpdatedOwners];
      if (updated.find((it) => it.Id === owner.Id)) {
        updated = form.UpdatedOwners.map((it) => {
          if (it.Id === owner.Id) {
            return {
              ...owner,
            };
          }
          return it;
        });
      } else {
        updated.push(owner);
      }
      setForm((prevState) => ({
        ...prevState,
        UpdatedOwners: updated,
      }));
    }
    setOwnerModalShown(false);
  };

  const removeOwner = (owner: UKRegistry.ApartmentOwner | UKRegistry.CreatedApartmentOwner) => {
    const owners = form.Owners.filter((it) => it.Id !== owner.Id);
    const created = form.CreatedOwners.filter((it) => it.Id !== owner.Id);

    setForm((prevState) => ({
      ...prevState,
      Owners: owners,
      CreatedOwners: created,
    }));

    if ("Id" in owner && owner.Id > 0 && "LokoloUserId" in owner) {
      const deleted = new Set(form.DeletedOwners);
      deleted.add(owner.Id);

      const updated = form.UpdatedOwners.filter((it) => it.Id !== owner.Id);
      setForm((prevState) => ({
        ...prevState,
        DeletedOwners: Array.from(deleted),
        UpdatedOwners: updated,
      }));
    }
  };

  const onClose = () => {
    dispatch(toggleEditModal({ isOpen: false }));
  };

  const handleError = (payload: any) => {
    if (payload?.Errors) {
      Object.keys(payload.Errors).forEach((key: any) => {
        const message = Array.isArray(payload.Errors[key]) ? payload.Errors[key][0] : payload.Errors[key];
        switch (key) {
          case "Apartment.Number":
          case "Number": {
            setForm((prevState) => ({
              ...prevState,
              Number: { ...form.Number, error: message },
            }));
            break;
          }

          case "Apartment.Area":
          case "Area": {
            setForm((prevState) => ({
              ...prevState,
              Area: { ...form.Area, error: message },
            }));
            break;
          }

          case "Apartment.CadastralNumber":
          case "CadastralNumber": {
            setForm((prevState) => ({
              ...prevState,
              CadastralNumber: { ...form.CadastralNumber, error: message },
            }));
            break;
          }

          // case "Owners": {
          //   setForm((prevState) => ({
          //     ...prevState,
          //     OwnersError: [...prevState.OwnersError, message],
          //   }));
          //   break;
          // }

          default: {
            if (
              String(key).startsWith("CreatedOwners.") ||
              String(key).startsWith("UpdatedOwners.") ||
              String(key).startsWith("DeletedOwners.")
            ) {
              const split = key.split(".");
              const index = parseInt(split[1]);
              const owner = (form as any)[split[0]][index];
              if (owner?.FullName) {
                setForm((prevState) => ({
                  ...prevState,
                  OwnersError: [...prevState.OwnersError, `Ошибка у собственника ${owner?.FullName}: ${message}`],
                }));
              } else if (owner?.Id) {
                const _owner = form.Owners.find((it) => it.Id === owner?.Id);
                setForm((prevState) => ({
                  ...prevState,
                  OwnersError: [...prevState.OwnersError, `Ошибка у собственника ${_owner?.FullName}: ${message}`],
                }));
              } else {
                setForm((prevState) => ({ ...prevState, error: message }));
              }
            } else {
              setForm((prevState) => ({ ...prevState, error: message }));
            }
            break;
          }
        }
      });
    } else {
      setForm((prevState) => ({ ...prevState, error: payload?.Message }));
    }
  };

  const isFormValid = () => {
    const validator = UKRegistry.ApartmentFormValidator.isInvalid(form);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        Number: {
          ...prevState.Number,
          error: validator.Number,
        },
        Area: {
          ...prevState.Area,
          error: validator.Area,
        },
        Type: {
          ...prevState.Type,
          error: validator.Type,
        },
        CadastralNumber: {
          ...prevState.CadastralNumber,
          error: validator.CadastralNumber,
        },
        OwnersError: [validator.Owners],
      }));
    }

    return !validator;
  };

  const handleSubmit = async () => {
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });
    setForm((prevState) => ({ ...prevState, error: "", OwnersError: [] }));

    if (!isFormValid()) {
      return;
    }

    if (!form.HouseId || !form.HouseFiasId || !form.Type.value?.ShortName) {
      setForm((prevState) => ({
        ...prevState,
        error: "Техническая ошибка. Попробуйте позже еще раз",
      }));
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      if (apartment) {
        await dispatch(
          updateApartment({
            Id: apartment.Id,
            HouseId: form.HouseId,
            HouseFiasId: form.HouseFiasId,
            Number: form.Number.value,
            Area: form.Area.value,
            Type: form.Type.value?.ShortName,
            CadastralNumber: form.CadastralNumber.value,
            CreatedOwners: form.CreatedOwners,
            UpdatedOwners: form.UpdatedOwners,
            DeletedOwners: form.DeletedOwners.map((it) => ({ Id: it })),
          })
        ).unwrap();
      } else {
        await dispatch(
          createApartment({
            HouseId: form.HouseId,
            HouseFiasId: form.HouseFiasId,
            Number: form.Number.value,
            Area: form.Area.value,
            Type: form.Type.value?.ShortName,
            CadastralNumber: form.CadastralNumber.value,
            CreatedOwners: form.CreatedOwners,
          })
        ).unwrap();
      }
      onClose();
    } catch (error: any) {
      console.warn(error);
      handleError(error.Data);
    } finally {
      setForm((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const fetch = async () => {
    if (details && apartment) {
      setLoading(true);
      try {
        const { data } = await UKRegistryService.getApartmentDetailsById(details.FiasId, apartment.Id);
        setForm(new UKRegistry.ApartmentForm(details, data.Data, typesList, data.Included));
      } finally {
        setLoading(false);
      }
    } else {
      setForm(new UKRegistry.ApartmentForm(details ?? undefined));
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetch();
    } else {
      setTimeout(() => {
        setForm(new UKRegistry.ApartmentForm(details ?? undefined));
      }, 1000);
    }
  }, [isOpen, details]);

  return (
    <>
      <BaseModal isOpen={isOpen} maxWidth="863px" minWidth="863px">
        <div ref={containerRef}>
          <BaseModalHeader title={title} onClose={onClose} />

          <BaseModalContent sx={{ padding: "16px 24px" }}>
            {isLoading ? (
              <div className={styles.loading}>
                <Spinner size={36} color="#226dff" />
              </div>
            ) : (
              <div>
                <div className={styles.section}>
                  <BaseInput
                    value={form.Number.value}
                    label="№ помещения"
                    tooltipMessage="Введите номер помещения или квартиры по кадастровому паспорту"
                    required={form.Number.isRequired}
                    disabled={isReadonly}
                    placeholder="Введите номер"
                    errorMessage={form.Number.error}
                    maxlength={form.Number.maxlength}
                    onChange={(value) => updateFormFieldValue("Number", value)}
                  />

                  <NumberInput
                    value={form.Area.value}
                    label="Площадь (м²)"
                    tooltipMessage="Укажите площадь помещения в квадратных метрах"
                    required={form.Area.isRequired}
                    disabled={isReadonly}
                    placeholder="Введите площадь"
                    errorMessage={form.Area.error}
                    onChange={(value) => updateFormFieldValue("Area", value)}
                  />

                  <BaseInput
                    value={form.CadastralNumber.value}
                    label="Кадастровый номер"
                    tooltipMessage="Укажите кадастровый номер помещения. Если значение неизвестно, то поле можно оставить пустым"
                    required={form.CadastralNumber.isRequired}
                    disabled={isReadonly}
                    placeholder="Введите номер"
                    errorMessage={form.CadastralNumber.error}
                    onChange={(value) => updateFormFieldValue("CadastralNumber", value)}
                  />

                  <BaseDropdown
                    value={form.Type?.value?.ShortName ?? null}
                    display={form.Type?.value?.ShortName}
                    isSelectable={true}
                    label="Тип"
                    placeholder="Выберите тип"
                    variant="formfield"
                    required={form.Type.isRequired}
                    disabled={isReadonly}
                    errorMessage={form.Type.error}
                    onSelect={updateAppartmentType}
                  >
                    {typesList.map((type) => (
                      <BaseDropdownMenuItem key={type.ShortName} value={type.ShortName}>
                        <span className="sf-text-medium">{type.ShortName}</span>
                      </BaseDropdownMenuItem>
                    ))}
                  </BaseDropdown>
                </div>

                <div className={styles.owners}>
                  <h4 className="headline-h4 sf-text-bold">Собственники:</h4>
                  {!isReadonly && (
                    <div className={styles.owners_add_button}>
                      <div>
                        <BaseButton
                          size="medium"
                          startIcon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.0001 4.1665C11.0001 3.61422 10.5524 3.1665 10.0001 3.1665C9.4478 3.1665 9.00008 3.61422 9.00008 4.1665V8.99984H4.16675C3.61446 8.99984 3.16675 9.44755 3.16675 9.99984C3.16675 10.5521 3.61446 10.9998 4.16675 10.9998H9.00008V15.8332C9.00008 16.3855 9.4478 16.8332 10.0001 16.8332C10.5524 16.8332 11.0001 16.3855 11.0001 15.8332V10.9998H15.8334C16.3857 10.9998 16.8334 10.5521 16.8334 9.99984C16.8334 9.44755 16.3857 8.99984 15.8334 8.99984H11.0001V4.1665Z"
                                fill="#226DFF"
                              />
                            </svg>
                          }
                          variant="secondary"
                          onClick={showAddOwnerModal}
                        >
                          Добавить собственника
                        </BaseButton>
                      </div>
                    </div>
                  )}

                  {form.Owners.length > 0 &&
                    form.Owners.map((owner, index) => (
                      <OwnerItem
                        key={`${owner.FullName}_${index}`}
                        owner={owner}
                        ownerChange={form.OwnerChange}
                        readonly={isReadonly}
                        onOpen={() => showEditOwnerModal(owner)}
                        onEdit={() => showEditOwnerModal(owner)}
                        onRemove={() => removeOwner(owner)}
                      />
                    ))}
                </div>
                {form.OwnersError.length > 0 && (
                  <p className="sf-text-regular text--primary color--red">{form.OwnersError.join("\n")}</p>
                )}
                {form.error && (
                  <p className={styles.common_error}>
                    <span className="sf-text-regular text--primary color--red">{form.error}</span>
                  </p>
                )}
              </div>
            )}
          </BaseModalContent>

          <BaseModalActions>
            {mode === "view" ? (
              <BaseButton onClick={onClose}>Закрыть</BaseButton>
            ) : mode === "edit" ? (
              <>
                <BaseButton variant="secondary" onClick={onClose}>
                  Отменить
                </BaseButton>
                <BaseButton isLoading={form.isLoading} onClick={handleSubmit}>
                  Сохранить
                </BaseButton>
              </>
            ) : (
              <>
                <BaseButton variant="secondary" onClick={onClose}>
                  Отменить
                </BaseButton>
                <BaseButton isLoading={form.isLoading} onClick={handleSubmit}>
                  Добавить
                </BaseButton>
              </>
            )}
          </BaseModalActions>

          {isOwnerModalShown && (
            <EditOwnerModal
              readonly={isReadonly}
              owner={selectedOwner}
              allOwners={form.Owners}
              left={subModalLeft}
              isOpen={isOwnerModalShown}
              onClose={() => setOwnerModalShown(false)}
              onAdd={(owner) => addOwner(owner)}
              onEdit={(owner) => updateOwner(owner)}
            />
          )}
        </div>
      </BaseModal>
    </>
  );
};

export default EditApartmentModal;
