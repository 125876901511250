import { Dictionary, SntPassport, SntPassportBoard, SntPassportEnums } from "@/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface SntPassportState {
  roadSurfaceTypesList: Dictionary.AlternativeDictionaryItem<SntPassportEnums.RoadSurfaceType>[];
  waterSupplyTypesList: Dictionary.AlternativeDictionaryItem<SntPassportEnums.WaterSupplyType>[];
  electricitySupplyTypesList: Dictionary.AlternativeDictionaryItem<SntPassportEnums.ElectricitySupplyType>[];
  internalInfrastructureWorkTypesList: Dictionary.AlternativeDictionaryItem<SntPassportEnums.InternalInfrastructureWorkType>[];
  externalInfrastructureWorkTypesList: Dictionary.AlternativeDictionaryItem<SntPassportEnums.ExternalInfrastructureWorkType>[];
  associationsList: SntPassportBoard.Association[];
  // Institution
  query: string;
  queryColumn: SntPassport.QueryColumn;
  isLokoloUserExists: boolean | null;
}

const SntPassportSlice = createSlice({
  name: "snt-passport",

  initialState: {
    roadSurfaceTypesList: [],
    waterSupplyTypesList: [],
    electricitySupplyTypesList: [],
    internalInfrastructureWorkTypesList: [],
    externalInfrastructureWorkTypesList: [],
    associationsList: [],
    query: "",
    queryColumn: SntPassport.QueryColumn.Inn,
    isLokoloUserExists: null,
  } as SntPassportState,

  reducers: {
    setRoadSurfaceTypesList: (state, action: PayloadAction<SntPassportState["roadSurfaceTypesList"]>) => {
      state.roadSurfaceTypesList = action.payload;
    },

    setWaterSupplyTypesList: (state, action: PayloadAction<SntPassportState["waterSupplyTypesList"]>) => {
      state.waterSupplyTypesList = action.payload;
    },

    setElectricitySupplyTypesList: (state, action: PayloadAction<SntPassportState["electricitySupplyTypesList"]>) => {
      state.electricitySupplyTypesList = action.payload;
    },

    setInternalInfrastructureWorkTypesList: (
      state,
      action: PayloadAction<SntPassportState["internalInfrastructureWorkTypesList"]>
    ) => {
      state.internalInfrastructureWorkTypesList = action.payload;
    },

    setExternalInfrastructureWorkTypesList: (
      state,
      action: PayloadAction<SntPassportState["externalInfrastructureWorkTypesList"]>
    ) => {
      state.externalInfrastructureWorkTypesList = action.payload;
    },

    setAssociationsList: (state, action: PayloadAction<SntPassportState["associationsList"]>) => {
      state.associationsList = action.payload;
    },

    setQuery: (state, action: PayloadAction<SntPassportState["query"]>) => {
      state.query = action.payload;
    },

    setQueryColumn: (state, action: PayloadAction<SntPassportState["queryColumn"]>) => {
      state.queryColumn = action.payload;
    },

    setLokoloUserExists: (state, action: PayloadAction<SntPassportState["isLokoloUserExists"]>) => {
      state.isLokoloUserExists = action.payload;
    },
  },
});

export default SntPassportSlice.reducer;
export * from "./actions";
export const {
  setRoadSurfaceTypesList,
  setWaterSupplyTypesList,
  setElectricitySupplyTypesList,
  setInternalInfrastructureWorkTypesList,
  setExternalInfrastructureWorkTypesList,
  setAssociationsList,
  setQuery,
  setQueryColumn,
  setLokoloUserExists,
} = SntPassportSlice.actions;
