import { SntRegistry } from "@/types";

export namespace SntPassportMap {
  export enum MapState {
    Default = "default",
    AddMarker = "add-marker",
    ChangeSntGeo = "change-snt-geo",
  }

  export type Geopoint = {
    Lat: number;
    Lon: number;
  };

  export type SntItem = {
    Id: SntRegistry.SntDetails["Id"];
    Name: string;
    Geopoint?: Geopoint | null;
  };
}
