import { Box } from "@mui/material";
import Headline from "../../components/headline";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../app/store";
import { Accordion, Collapsed, Expanded } from "../../components/accordion";
import { useEffect, useMemo } from "react";
import { fetchContactsAll, toggleRemoveModal, toggleEditModal } from "../../app/store/slices/contacts";
import { UsefulContacts } from "../../types/contacts";
import ContactExpanded from "./contact-expanded";
import ContactCollapsed from "./contact-collapsed";

const UsefulCompaniesContacts = () => {
  const company = useSelector((state: RootState) => state.user.company);
  const contactsAll = useSelector((state: RootState) => state.contacts.contactsAll);
  const dispatch = useAppDispatch();

  const contacts: Array<UsefulContacts.UsefulContactUnitedType> = useMemo(() => {
    let arr = [];
    if (company) {
      arr.push({ ...company, Id: -company.Id });
    }

    if (contactsAll?.data?.length) {
      arr.push(...contactsAll.data);
    }
    return arr;
  }, [company, contactsAll.data]);

  const getContactsAll = () => {
    dispatch(fetchContactsAll());
  };

  const showRemoveContactsModal = (id: UsefulContacts.UsefulContactId) => {
    dispatch(toggleRemoveModal({ isOpen: true, id }));
  };

  const showEditContactsModal = (id: UsefulContacts.UsefulContactId) => {
    dispatch(toggleEditModal({ isOpen: true, id }));
  };

  useEffect(() => {
    getContactsAll();
  }, []);

  return (
    <>
      <Headline
        title="Как создать новый контакт?"
        text={`Нажмите на кнопку “Добавить контакт” и добавьте в него сведения. Добавляйте контакты, которые у вас чаще всего запрашивают. Эта информация будет отображена в разделе "Сервисы" приложения Локоло`}
      />

      <Box mt={2} sx={{ display: "flex", gap: "16px", flexDirection: "column" }}>
        {!!contacts.length && (
          <Accordion initial="">
            {contacts.map((contact) => (
              <Accordion.Item key={contact.Id}>
                <Collapsed id={contact.Id}>
                  <ContactCollapsed
                    company={contact}
                    onEdit={(id) => showEditContactsModal(id)}
                    onRemove={(id) => showRemoveContactsModal(id)}
                  />
                </Collapsed>
                <Expanded>
                  <ContactExpanded company={contact} />
                </Expanded>
              </Accordion.Item>
            ))}
          </Accordion>
        )}
      </Box>
    </>
  );
};

export default UsefulCompaniesContacts;
