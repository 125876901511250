import { useMemo, useState, useContext } from "react";
import { FormFiled, FormValidator } from "@/types";
import { ProfileService } from "@/services/v2";
import BaseButton from "@/components/base-button";
import AuthContext from "../context";
import AuthorizationField from "./Field";
import styles from "../auth.module.scss";

function AuthFormByPhone() {
  const context = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [phone, setPhone] = useState<FormFiled>(new FormFiled(context?.phone ?? ""));

  const isDisabled: boolean = useMemo(() => {
    return !!FormValidator.getPhoneErrorMessage(phone.value, true);
  }, [phone]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setErrorMessage("");
    setPhone((prevState) => ({ ...prevState, error: "" }));

    try {
      setIsLoading(true);
      const { data } = await ProfileService.otpSend(phone.value);
      context?.setIsCodeSended({
        expiresAt: (data as any).Data.ExpiresAt,
        phone: phone.value,
        value: true,
      });
    } catch (err: any) {
      const errData = err?.response?.data?.Data;
      if (errData?.ErrorCode === "adminNotFound") {
        setErrorMessage("По вашему номеру пользователь не найден");
      } else {
        setErrorMessage(errData?.Message ?? "Неизвестная ошибка. Попробуйте позже");
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form className={styles["p-auth__form"]} onSubmit={handleSubmit}>
      <div>
        <AuthorizationField
          value={phone.value}
          type="phone"
          name="phone"
          label="Номер телефона"
          placeholder="+7-999-999-99-99"
          errorMessage={phone.error}
          onChange={(value) => setPhone((prevState) => ({ ...prevState, value }))}
        />
      </div>

      <div>
        <BaseButton type="submit" disabled={isDisabled} className={styles["p-auth__form_submit"]} isLoading={isLoading}>
          Запросить код
        </BaseButton>
      </div>

      {errorMessage && <p className="color--red text--primary sf-text-regular center">{errorMessage}</p>}
    </form>
  );
}

export default AuthFormByPhone;
