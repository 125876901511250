import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { point, booleanPointInPolygon, multiPolygon } from "@turf/turf";
import mapboxGl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { RootState } from "@/app/store";
import { SntPassportMap } from "@/types";
import { addIcon24, subtractIcon24 } from "@/consts/icons";
import BaseTooltip from "@/components/base-tooltip";
import BaseButton from "@/components/base-button";
import MapboxMarker, { MarkerVariant } from "./Marker";
import MapContext from "./context";
import TATARSTAN_POLYGON from "./tatarstan-polygon.json";
import { addMarkerIcon } from "./Marker/icons";
import "./styles.scss";

interface IMapboxProps {
  markers: SntPassportMap.SntItem[];
  state?: SntPassportMap.MapState;
  newMarkerPoint?: SntPassportMap.Geopoint | null;
  onMarkerAdd?: (point: SntPassportMap.Geopoint) => void;
  onMarkerSelect?: (sntId: SntPassportMap.SntItem["Id"]) => void;
}

const Mapbox: React.FC<IMapboxProps> = ({
  markers,
  state = SntPassportMap.MapState.Default,
  newMarkerPoint,
  onMarkerAdd,
  onMarkerSelect,
}) => {
  mapboxGl.accessToken = String(process.env.REACT_APP_MAPBOX_TOKEN);

  const { selected } = useSelector((state: RootState) => state.sntsMap);

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<InstanceType<typeof mapboxGl.Map> | null>(null);
  const stateRef = useRef<SntPassportMap.MapState>(state);

  const [isZoomInEnable, setZoomInEnable] = useState<boolean>(true);
  const [isZoomOutEnable, setZoomOutEnable] = useState<boolean>(true);
  const [isCursorTooltipShown, toggleCursorTooltipShown] = useState<boolean>(false);
  const [cursorTooltipPosition, setCursorTooltipPosition] = useState<{ left: number; top: number }>({
    left: 0,
    top: 0,
  });

  stateRef.current = state;

  const _markers: typeof markers = useMemo(() => {
    return state === SntPassportMap.MapState.Default ? markers.slice() : [];
  }, [state, markers]);

  function _onMarkerSelect(sntId: SntPassportMap.SntItem["Id"]) {
    if (onMarkerSelect) {
      onMarkerSelect(sntId);
    }
  }

  function zoomIn() {
    mapRef.current?.zoomIn();
  }

  function zoomOut() {
    mapRef.current?.zoomOut();
  }

  function onMapClick(e: any) {
    if (
      [SntPassportMap.MapState.AddMarker, SntPassportMap.MapState.ChangeSntGeo].includes(stateRef.current) &&
      onMarkerAdd
    ) {
      const tatarstanPoly = multiPolygon(TATARSTAN_POLYGON.coordinates);
      const clickPoint = point([e.lngLat.lng, e.lngLat.lat]);
      if (booleanPointInPolygon(clickPoint, tatarstanPoly)) {
        onMarkerAdd({ Lat: e.lngLat.lat, Lon: e.lngLat.lng });
      }
    }
  }

  function onMouseMove(e: any) {
    setCursorTooltipPosition({ left: e.pageX, top: e.pageY });
  }

  useEffect(() => {
    if (!mapContainerRef.current) {
      return;
    }

    if (mapRef.current) {
      return;
    }

    mapRef.current = new mapboxGl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [50.4763591, 55.448217],
      zoom: 6,
      minZoom: 5,
      language: "Russian",
    });

    mapRef.current.on("click", (e) => onMapClick(e));
    mapRef.current.on("zoomend", (e) => {
      if (!mapRef.current) {
        return;
      }

      const currentZoom = mapRef.current.getZoom();
      setZoomInEnable(currentZoom < mapRef.current.getMaxZoom());
      setZoomOutEnable(currentZoom > mapRef.current.getMinZoom());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapContainerRef.current]);

  return (
    <MapContext.Provider value={{ map: mapRef.current }}>
      <div className="b-map-container">
        <div
          ref={mapContainerRef}
          id="map"
          className={`map-state--${state.toLowerCase()}`}
          onMouseEnter={() => toggleCursorTooltipShown(true)}
          onMouseLeave={() => toggleCursorTooltipShown(false)}
          onMouseMove={onMouseMove}
        ></div>

        {mapRef.current && (
          <>
            {_markers
              .filter((it) => !!it.Geopoint)
              .map((marker) =>
                marker.Geopoint ? (
                  <div key={marker.Id}>
                    <MapboxMarker
                      geopoint={marker.Geopoint}
                      label={marker.Name}
                      selected={selected.sntId === marker.Id}
                      onSelect={() => _onMarkerSelect(marker.Id)}
                    />
                  </div>
                ) : null
              )}

            {[SntPassportMap.MapState.AddMarker, SntPassportMap.MapState.ChangeSntGeo].includes(state) && (
              <>
                {newMarkerPoint && (
                  <div>
                    <MapboxMarker
                      geopoint={newMarkerPoint}
                      label="Подтвердите место"
                      variant={MarkerVariant.AddingNew}
                    />
                  </div>
                )}

                {isCursorTooltipShown && (
                  <div
                    className="b-cursor-tooltip b-marker b-marker-variant--adding-new"
                    style={{
                      left: `${cursorTooltipPosition.left}px`,
                      top: `${cursorTooltipPosition.top}px`,
                    }}
                  >
                    <div className="b-marker__tooltip">
                      <span className="table--small sf-text-semibold color--text-white">Выберите место</span>
                    </div>
                    <span>{addMarkerIcon}</span>
                  </div>
                )}
              </>
            )}

            <div className="b-map-zoom-controls">
              <BaseTooltip title="Приблизить" placement="left">
                <BaseButton
                  className={`b-map-zoom-controls__btn ${!isZoomInEnable ? "b-map-zoom-controls__btn--disabled" : ""}`}
                  variant="icon-basic"
                  onClick={() => zoomIn()}
                >
                  {isZoomInEnable ? addIcon24("var(--icons-primary)") : null}
                </BaseButton>
              </BaseTooltip>
              <BaseTooltip title="Отдалить" placement="left">
                <BaseButton
                  className={`b-map-zoom-controls__btn ${!isZoomOutEnable ? "b-map-zoom-controls__btn--disabled" : ""}`}
                  variant="icon-basic"
                  onClick={() => zoomOut()}
                >
                  {subtractIcon24("var(--icons-primary)")}
                </BaseButton>
              </BaseTooltip>
            </div>
          </>
        )}
      </div>
    </MapContext.Provider>
  );
};

export default Mapbox;
