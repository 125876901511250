interface IPageSubheaderProps {
  children: JSX.Element | JSX.Element[] | React.ReactNode | string | null;
}

const CreateOccItemPageSubheader: React.FC<IPageSubheaderProps> = ({ children }) => {
  return (
    <div className="flex" style={{ flexDirection: "column", padding: "28px 0", gap: "12px" }}>
      {children}
    </div>
  );
};

export default CreateOccItemPageSubheader;
