import { useMemo } from "react";
import BaseSwitcher from "@/components/BaseSwitcher";
import styles from "./styles.module.scss";

interface IRegistryDetailsTableCardProps {
  title: string;
  value: JSX.Element | JSX.Element[] | string;
  isHasDifference?: boolean;
  comparison?: JSX.Element | JSX.Element[] | string;
  toggleUpdate?: (value: boolean) => void;
}

const RegistryDetailsTableGroup: React.FC<IRegistryDetailsTableCardProps> = ({
  title,
  value,
  isHasDifference,
  comparison,
  toggleUpdate,
}) => {
  const isChecked: boolean = useMemo(() => {
    return (isHasDifference && value === comparison) ?? false;
  }, [isHasDifference, value, comparison]);

  function handleChange(checked: boolean) {
    if (toggleUpdate) {
      toggleUpdate(checked);
    }
  }

  return (
    <div className={styles.group}>
      <div className={styles.group_title}>
        <p className="text--primary sf-text-regular color--text-secondary">{title}</p>
        <div
          className={`text--primary sf-text-medium ${
            isHasDifference
              ? value === comparison
                ? "color--text-success"
                : "color--text-error"
              : "color--text-primary"
          }`}
        >
          {value}
        </div>
      </div>
      {isHasDifference && (
        <div>
          <BaseSwitcher value={isChecked} onChange={handleChange}>
            <span className="sf-text-regular text--primary color--text-primary">
              {isChecked ? "Исправлено" : "Исправить"}
            </span>
          </BaseSwitcher>
        </div>
      )}
    </div>
  );
};

export default RegistryDetailsTableGroup;
