import { createContext } from "react";

export type SendCodePayload = {
  value: boolean;
  phone?: AuthContextValue["phone"];
  expiresAt?: AuthContextValue["expiresAt"];
};
export type AuthContextValue = {
  phone: string;
  expiresAt: number | null;
  setIsCodeSended: (payload: SendCodePayload) => void;
};

const AuthContext = createContext<AuthContextValue | null>(null);

export default AuthContext;
