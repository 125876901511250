import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UsefulContacts } from "@/types/contacts";
import { UsefulContactsService } from "@/services/v2";
import { setCompany } from "./user";

interface ContactsState {
  contactsAll: {
    data: Array<UsefulContacts.Item>;
    loading: boolean;
    error: string;
  };
  removeModal: {
    isOpen: boolean;
    isLoading: boolean;
    errorMessage: string;
    usefulContactId: UsefulContacts.UsefulContactId;
  };
  editModal: {
    isOpen: boolean;
    isLoading: boolean;
    usefulContactId: UsefulContacts.UsefulContactId;
  };
}

const initialState: ContactsState = {
  contactsAll: {
    data: [],
    loading: false,
    error: "",
  },
  removeModal: {
    isOpen: false,
    isLoading: false,
    errorMessage: "",
    usefulContactId: null,
  },
  editModal: {
    isOpen: false,
    isLoading: false,
    usefulContactId: null,
  },
};

const fetchContactsAll = createAsyncThunk("useful-contacts/fetch-all", async (data: undefined, { rejectWithValue }) => {
  try {
    const response = await UsefulContactsService.getUsefulCompanyContacts();

    return response.data.Data;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data?.Data?.Message);
  }
});

const deleteUsefulContacts = createAsyncThunk(
  "useful-contacts/delete-contact",
  async (id: UsefulContacts.UsefulContactId, { rejectWithValue, dispatch }) => {
    try {
      dispatch(onUsefulContactUpdatePending());
      const response = await UsefulContactsService.deleteUsefulCompanyContacts(id);

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    } finally {
      dispatch(onUsefulContactUpdateFinished());
    }
  }
);

const createUsefulContacts = createAsyncThunk(
  "useful-contacts/create-contact",
  async (data: UsefulContacts.CreateContactBody, { rejectWithValue, dispatch }) => {
    try {
      dispatch(onUsefulContactUpdatePending());
      const response = await UsefulContactsService.createUsefulCompanyContact(data);
      dispatch(addNewUsefulContacts(response.data.Data));
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data);
    } finally {
      dispatch(onUsefulContactUpdateFinished());
    }
  }
);

const updateUsefulContacts = createAsyncThunk(
  "useful-contacts/update-contact",
  async (data: UsefulContacts.UpdateContactBody, { rejectWithValue, dispatch }) => {
    try {
      dispatch(onUsefulContactUpdatePending());
      const response = await UsefulContactsService.updateUsefulCompanyContact(data);
      dispatch(updateUsefulContactsById(response.data.Data));

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data);
    } finally {
      dispatch(onUsefulContactUpdateFinished());
    }
  }
);

const updateMyContacts = createAsyncThunk(
  "useful-contacts/create-my-contacts",
  async (payload: UsefulContacts.UpdateMyContactsBody, { rejectWithValue, dispatch }) => {
    try {
      dispatch(onUsefulContactUpdatePending());
      const { data } = await UsefulContactsService.updateUserCompanyData(payload);
      dispatch(setCompany(data.Data));

      return data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data);
    } finally {
      dispatch(onUsefulContactUpdateFinished());
    }
  }
);

const contactsSlice = createSlice({
  name: "useful-contacts",
  initialState,
  reducers: {
    toggleRemoveModal: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        id?: UsefulContacts.UsefulContactId;
      }>
    ) => {
      if (action.payload.isOpen && !!action.payload.id) {
        state.removeModal.isOpen = action.payload.isOpen;
        state.removeModal.usefulContactId = action.payload.id;
        return;
      }

      state.removeModal.isOpen = false;
    },
    toggleEditModal: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        id?: UsefulContacts.UsefulContactId;
      }>
    ) => {
      if (action.payload.isOpen) {
        state.editModal.usefulContactId = action.payload.id ?? null;
      }

      state.editModal.isOpen = action.payload.isOpen;
    },

    addNewUsefulContacts: (state, action: PayloadAction<UsefulContacts.Item>) => {
      state.contactsAll.data.unshift(action.payload);
    },

    updateUsefulContactsById: (state, action: PayloadAction<UsefulContacts.Item>) => {
      const contacts = state.contactsAll.data.map((it) => {
        if (it.Id === action.payload.Id) {
          return {
            ...action.payload,
          };
        }
        return it;
      });
      state.contactsAll.data = contacts;
    },

    onUsefulContactUpdatePending: (state) => {
      state.editModal.isLoading = true;
    },
    onUsefulContactUpdateFinished: (state) => {
      state.editModal.isLoading = false;
    },
  },
  extraReducers: {
    // @fetchContactsAll
    [fetchContactsAll.pending.type]: (state) => {
      state.contactsAll.loading = true;
      state.contactsAll.error = "";
    },
    [fetchContactsAll.fulfilled.type]: (state, action: PayloadAction<UsefulContacts.Item[]>) => {
      state.contactsAll.loading = false;
      state.contactsAll.data = action.payload.sort((a, b) => b.Id - a.Id);
    },
    [fetchContactsAll.rejected.type]: (state, action: PayloadAction<any>) => {
      state.contactsAll.loading = false;

      if (action.payload) {
        state.contactsAll.error = action.payload;
      }
    },

    // @deleteUsefulContacts
    [deleteUsefulContacts.pending.type]: (state) => {
      state.removeModal.isLoading = true;
      state.removeModal.errorMessage = "";
    },
    [deleteUsefulContacts.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.removeModal.isLoading = false;
      state.removeModal.isOpen = false;

      const index = state.contactsAll.data.findIndex((it) => it.Id === state.removeModal.usefulContactId);
      if (index >= 0) {
        state.contactsAll.data.splice(index, 1);
      }
    },
    [deleteUsefulContacts.rejected.type]: (state, action: PayloadAction<any>) => {
      state.removeModal.isLoading = false;
      state.removeModal.errorMessage = action.payload;
    },
  },
});

export { fetchContactsAll, deleteUsefulContacts, createUsefulContacts, updateUsefulContacts, updateMyContacts };

export const {
  toggleRemoveModal,
  toggleEditModal,
  addNewUsefulContacts,
  updateUsefulContactsById,
  onUsefulContactUpdatePending,
  onUsefulContactUpdateFinished,
} = contactsSlice.actions;

export default contactsSlice.reducer;
