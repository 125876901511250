import React from "react";
import styles from "./styles.module.scss";

interface IBaseUserAvatarProps {
  children: React.ReactNode;
  avatar?: string;
  size?: React.CSSProperties["width"];
  bgStyle?: React.CSSProperties["background"];
}

const BaseUserAvatar: React.FC<IBaseUserAvatarProps> = ({ children, avatar, size = "40px", bgStyle }) => {
  return (
    <div className={styles.base}>
      <span className={styles.base_avatar} style={{ width: size, height: size, minWidth: size, background: bgStyle }}>
        {avatar ? <img src={avatar} alt="" /> : <span className="headline-h4 sf-text-bold color--text-white">♡</span>}
      </span>
      {children}
    </div>
  );
};

export default BaseUserAvatar;
