import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation, To } from "react-router-dom";
import { OCCV2Questions } from "@/types";
import { addIcon24, arrowRightWhiteIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { setOccId, fetchOccQustions } from "@/app/store/slices/createOcc";
import BaseButton from "@/components/base-button";
import OccQuestionItem from "@/components/occ-question-item";
import BottomBar from "../../BottomBar";
import CreateOccItemPageLoader from "../../PageLoader";
import CreateOccItemPageSubheader from "../../PageSubheader";
import CreateQuestionModal from "./CreateQuestionModal";
import styles from "./styles.module.scss";

interface IAgendaStepProps {
  prevLink: To;
  nextLink: To;
  quorumTooltipMessage: string;
}

const CreateOccItemAgendaStep: React.FC<IAgendaStepProps> = ({ prevLink, nextLink, quorumTooltipMessage }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = useParams();

  const { occId, questions } = useSelector((state: RootState) => state.createOcc);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<OCCV2Questions.Item | null>(null);
  const [isFormOpen, setFormOpen] = useState<boolean>(false);

  function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    navigate(nextLink, { state: location.state });
  }

  async function fetchQuestionsList() {
    if (questions.length === 0) {
      setIsLoading(true);
    }

    try {
      if (!occId && urlParams?.occId) {
        dispatch(setOccId(parseInt(urlParams?.occId)));
      }

      await dispatch(fetchOccQustions()).unwrap();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function onCreateModalShown() {
    setSelectedQuestion(null);
    setFormOpen(true);
  }

  function onQuestionSelect(question: OCCV2Questions.Item) {
    setSelectedQuestion(question);
    setFormOpen(true);
  }

  useEffect(() => {
    fetchQuestionsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CreateOccItemPageSubheader>
        <h2 className="headline-h2 sf-bold">
          Добавьте вопросы в повестку
          <span className="color--primary" style={{ marginLeft: "8px" }}>
            {questions.length}
          </span>
        </h2>
        <div>
          <BaseButton startIcon={addIcon24()} onClick={onCreateModalShown}>
            Добавить вопрос
          </BaseButton>
        </div>
      </CreateOccItemPageSubheader>

      {isLoading ? (
        <CreateOccItemPageLoader />
      ) : (
        <div className={styles["questions"]}>
          {questions.map((question) => (
            <OccQuestionItem key={question.Id} question={question} onSelect={() => onQuestionSelect(question)} />
          ))}
        </div>
      )}

      <BottomBar prevLink={prevLink}>
        <BaseButton
          disabled={questions.length === 0}
          variant="primary"
          appearance="positive"
          endIcon={arrowRightWhiteIcon}
          onClick={handleOnSubmit}
        >
          Дальше
        </BaseButton>
      </BottomBar>

      <CreateQuestionModal
        isOpen={isFormOpen}
        selectedQuestion={selectedQuestion}
        quorumTooltipMessage={quorumTooltipMessage}
        onClose={() => setFormOpen(false)}
      />
    </div>
  );
};

export default CreateOccItemAgendaStep;
