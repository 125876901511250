import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import SnackbarToast from "./toast";
import styles from "./styles.module.scss";

const SnackbarController = () => {
  const { toasts } = useSelector((state: RootState) => state.snackbar);

  return (
    <div className={styles.root}>
      {toasts.map((it) => (
        <SnackbarToast
          key={it.key}
          idKey={it.key}
          title={it.title}
          body={it.body}
          type={it.type}
          duration={it.duration}
          closeBtn={it.closeBtn}
        />
      ))}
    </div>
  );
};

export default SnackbarController;
