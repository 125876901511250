import { OutlinedInput } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledInput = styled(OutlinedInput)({
  "&": {
    fontSize: "14px",
    fontFamily: "SF Pro Text",
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: "-0.154px",
    height: "var(--base-field-height)",
    borderRadius: "var(--base-field-border-radius)",
    outline: "none",
  },
  "& .MuiInputBase-input": {
    height: "var(--base-field-height)",
    padding: "0 12px",
  },
  "& .MuiInputBase-input::-webkit-input-placeholder": {
    color: "#818C99",
    opacity: "1",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(43, 57, 73, 0.16)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.87)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#226dff",
  },
  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#FA4547",
  },
  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(43, 57, 73, 0.16)",
  },
  "&.Mui-disabled": {
    color: "currentColor",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    color: "currentColor",
  },
});

export default StyledInput;
