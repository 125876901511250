import { createContext } from "react";

export type SelectMenuItem = {
  display: string;
  value: any;
};

type MultipleSelectContextValue = {
  value: SelectMenuItem["value"];
  onSelect: (value: SelectMenuItem["value"]) => void;
};

const MultipleSelectContext = createContext<MultipleSelectContextValue | null>(null);

export default MultipleSelectContext;
