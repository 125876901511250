import { createAsyncThunk } from "@reduxjs/toolkit";
import { DocumentsService } from "@/services/v2";
import {
  pushDirectory,
  pushDocumentToDirectory,
  removeDirectory,
  removeDocument,
  setDirectories,
  updateDirectoriesOrder,
  setDocumentsByDirectoryId,
  updateDirectoryById,
  updateDocumentById,
} from ".";
import { Documents } from "@/types";
import { debounce } from "lodash";
import { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { RootState } from "../..";
// import { DocumentsService } from "@/types";

export const fetchDirectories = createAsyncThunk<Documents.Directory[], undefined>(
  "documents/fetch-directories",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await DocumentsService.getDirectories();
      await dispatch(setDirectories(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createDirectory = createAsyncThunk<Documents.Directory, string>(
  "documents/create-directory",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await DocumentsService.createDirectory(payload);
      await dispatch(pushDirectory(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateDirectory = createAsyncThunk<Documents.Directory, Documents.Directory>(
  "documents/update-directory",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await DocumentsService.updateDirectory(payload.Id, payload.Name);
      await dispatch(updateDirectoryById(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteDirectory = createAsyncThunk<unknown, Documents.Directory["Id"]>(
  "documents/delete-directory",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await DocumentsService.deleteDirectory(payload);
      await dispatch(removeDirectory(payload));
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const saveDirectoriesOrder = async ({ dispatch, getState }: BaseThunkAPI<unknown, unknown>) => {
  try {
    const state = (getState() as RootState).documents;
    const body = state.directories
      .slice()
      .reduce((obj, it, index) => ({ ...obj, [it.directory.Id]: it.directory.Order }), {});
    await DocumentsService.updateDirectoriesOrder(body);
  } catch (err) {
    console.error(err);
  }
};

const debouncedHandler = debounce(saveDirectoriesOrder, 1500);

export const setDirectoriesOrder = createAsyncThunk<unknown, { fromIndex: number; toIndex: number }>(
  "documents/update-directories-order",
  async (payload, { dispatch, getState }) => {
    dispatch(updateDirectoriesOrder(payload));
    debouncedHandler({ dispatch, getState } as BaseThunkAPI<unknown, unknown>);
  }
);

// DOCUMENTS
export const fetchFilesByDirectoryId = createAsyncThunk<Documents.UsefulDocument[], Documents.Directory["Id"]>(
  "documents/fetch-files-by-directory-id",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await DocumentsService.getFilesByDirectoryId(payload);
      await dispatch(setDocumentsByDirectoryId({ directoryId: payload, documents: data.Data }));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const uploadFileByDirectoryId = createAsyncThunk<
  Documents.UsefulDocument,
  { directoryId: Documents.Directory["Id"]; body: Documents.UploadFileRequestBody }
>("documents/upload-file-by-directory-id", async (payload, { dispatch, rejectWithValue }) => {
  try {
    const { data } = await DocumentsService.uploadFileByDirectoryId(payload.directoryId, payload.body);
    await dispatch(pushDocumentToDirectory({ directoryId: payload.directoryId, document: data.Data }));
    return data.Data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.Data);
  }
});

export const updateDocumentByDirectoryId = createAsyncThunk<
  Documents.UsefulDocument,
  {
    directoryId: Documents.Directory["Id"];
    fileId: Documents.UsefulDocument["Id"];
    body: Documents.UpdateDocumentRequestBody;
  }
>("documents/upload-file-by-directory-id", async (payload, { dispatch, rejectWithValue }) => {
  try {
    const { data } = await DocumentsService.updateFileByDirectoryId(payload.directoryId, payload.fileId, payload.body);
    await dispatch(updateDocumentById({ directoryId: payload.directoryId, document: data.Data }));
    return data.Data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data);
  }
});

export const deleteFileByDirectoryId = createAsyncThunk<
  unknown,
  { directoryId: Documents.Directory["Id"]; fileId: Documents.UsefulDocument["Id"] }
>("documents/delete-file", async (payload, { dispatch, rejectWithValue }) => {
  try {
    const { data } = await DocumentsService.deleteFileByDirectoryId(payload.directoryId, payload.fileId);
    await dispatch(removeDocument({ directoryId: payload.directoryId, fileId: payload.fileId }));
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data);
  }
});
