import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { paths } from "@/consts/routes";
import CreateOccItemCreateAgendaStep from "@/features/CreateOccNew/OccItem/Steps/CreateAgenda";

const UKCreateOCCCreateAgendaStep: React.FC = () => {
  const { occId } = useSelector((state: RootState) => state.createOcc);

  return (
    <div>
      <CreateOccItemCreateAgendaStep
        nextLink={paths
          .uk()
          .fullPath()
          .createOCC.item.preview(occId ?? "new")}
        prevLink={paths
          .uk()
          .fullPath()
          .createOCC.item.administrator(occId ?? "new")}
        quorumTooltipMessage="Выберите значение из списка - из какого расчета будет определяться набрался ли кворум"
      />
    </div>
  );
};

export default UKCreateOCCCreateAgendaStep;
