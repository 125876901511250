import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import { getOccDocumentsByOccId } from "@/app/store/slices/snt/myOcc/actions";
import BaseFileItem from "@/components/base-file-item";
import styles from "./styles.module.scss";

const SntMyOCCItemDocuments: React.FC = () => {
  const dispatch = useAppDispatch();

  const occ = useSelector((state: RootState) => state.sntMyOcc.occ);
  const documents = useSelector((state: RootState) => state.sntMyOcc.documents);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetch = async () => {
    setIsLoading(true);
    try {
      if (occ?.Order.Id) {
        await dispatch(getOccDocumentsByOccId(occ?.Order.Id)).unwrap();
      }
    } catch (error: any) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={styles.documents}>
      {documents.map((file, i) => (
        <BaseFileItem key={i} file={file} />
      ))}
    </div>
  );
};

export default SntMyOCCItemDocuments;
