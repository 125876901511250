import { useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Profile } from "@/types";
import { paths } from "@/consts/routes";
import { LogoIcon, LogoutIcon, SettingsIcon } from "@/consts/icons-v2";
import { RootState, useAppDispatch } from "@/app/store";
import { authLogout } from "@/app/store/slices/user";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import BaseTooltip from "@/components/base-tooltip";
import IconButton from "@/components/IconButton";
import styles from "./styles.module.scss";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();

  const { profile, company } = useSelector((state: RootState) => state.user);

  const userInitials: string = useMemo(() => {
    if (profile?.role === Profile.UserRole.Institution) {
      return "Министерство сельского хозяйства и продовольствия РТ";
    }

    if (profile?.company_type === Profile.CompanyType.UK) {
      return company?.Name ?? "";
    }

    let initials = profile?.last_name ?? "";
    if (profile?.first_name) initials += ` ${profile?.first_name?.charAt(0)}.`;
    if (profile?.middle_name) initials += ` ${profile?.middle_name?.charAt(0)}.`;
    return initials;
  }, [profile, company]);

  const avatarBgStyle: string = useMemo(() => {
    if (profile?.role === Profile.UserRole.Institution) {
      return "var(--avatar-initials-deep-blue)";
    }

    if (profile?.company_type === Profile.CompanyType.UK) {
      return "var(--avatar-initials-violet)";
    }

    return "var(--avatar-initials-red)";
  }, [profile]);

  function handleLogout() {
    dispatch(authLogout()).unwrap();
  }

  return (
    <header className={styles["b-header"]}>
      <div className={styles["b-header__base"]}>
        <div>
          <div className={styles["b-header__logo"]}>
            {LogoIcon()}
            <p className="sf-text-regular color--text-black">
              Государственная информационная <br />
              система <span className="sf-text-semibold">«ЛОКОЛО»</span>
            </p>
          </div>

          <div className={styles["b-header__calendar"]}>
            <div>
              <span className="caption--small sf-text-medium color--text-primary">
                {new Date().formatByPattern("d")}
              </span>
            </div>
            <span className="text--primary sf-text-medium color--text-primary">
              {new Date().formatByPattern("EEEEEE, d MMMM, yyyy").capitalize()}
            </span>
          </div>
        </div>

        <div>
          <BaseUserAvatar bgStyle={avatarBgStyle}>
            <p className="sf-text-semibold color--text-primary text--primary">{userInitials}</p>
          </BaseUserAvatar>

          <BaseTooltip title="Настройки">
            <NavLink to={paths.settings}>
              <IconButton>{SettingsIcon()}</IconButton>
            </NavLink>
          </BaseTooltip>

          <BaseTooltip title="Выход">
            <IconButton onClick={handleLogout}>{LogoutIcon()}</IconButton>
          </BaseTooltip>
        </div>
      </div>
    </header>
  );
};

export default Header;
