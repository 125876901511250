import { useEffect, useMemo, useState } from "react";
import {
  Outlet,
  useLocation,
  useParams,
  useNavigate,
  unstable_useBlocker,
  unstable_Blocker,
  To,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { paths } from "@/consts/routes";
import { Dictionary } from "@/types";
import { OCCService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { reset } from "@/app/store/slices/snt/myOcc";
import { selectStatusById } from "@/app/store/slices/dictionary";
import { setOccId, setCancelModalOpen, getOccDetails, updateOcc } from "@/app/store/slices/createOcc";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import PageHeader from "@/components/PageHeader";
import StepperButton from "../StepperButton";
import styles from "./styles.module.scss";

interface ICreateOccItemLayoutProps {
  steps: string[];
  activeStep: number;
  isBlockerProceed: (blocker: unstable_Blocker) => boolean;
  draftsListPath: To;
  createNewPath: To;
}

const CreateOccItemLayout: React.FC<ICreateOccItemLayoutProps> = ({
  steps,
  activeStep,
  isBlockerProceed,
  draftsListPath,
  createNewPath,
}) => {
  const { occId } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const order = useSelector((state: RootState) => state.createOcc.order);
  const status = useSelector(selectStatusById(order?.StatusId));
  const storedOccId = useSelector((state: RootState) => state.createOcc.occId);
  const unsavedBody = useSelector((state: RootState) => state.createOcc.unsavedBody);
  const isCancelModalOpen = useSelector((state: RootState) => state.createOcc.isCancelModalOpen);
  const isAcceptOccLoading = useSelector((state: RootState) => state.createOcc.isAcceptOccLoading);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isLeaveModalOpen, setLeaveModalOpen] = useState<boolean>(false);

  const isEditing: boolean = useMemo(() => {
    return (status && status?.key !== Dictionary.OCCStatus.Draft) ?? false;
  }, [status]);

  async function fetch() {
    setIsLoading(true);
    try {
      if (occId === "new") {
        dispatch(reset());
      } else if (occId) {
        dispatch(setOccId(parseInt(occId)));
        await dispatch(getOccDetails()).unwrap();
      }
    } catch (error: any) {
      if (error?.Data?.Errors?.OccId[0] === "Неизвестный id OCC") {
        navigate(createNewPath);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const blocker = unstable_useBlocker(
    !isCancelModalOpen &&
      ((status?.key === Dictionary.OCCStatus.OccPlanned &&
        (!!unsavedBody.occ || unsavedBody.deleteQuestions.length > 0 || unsavedBody.questions.length > 0) &&
        !unsavedBody.isSaved) ||
        (status?.key === Dictionary.OCCStatus.Draft && activeStep !== steps.length))
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (isBlockerProceed(blocker)) {
        blocker?.proceed();
        return;
      }

      setLeaveModalOpen(true);
    }
  }, [blocker]);

  function closeLeaveModal(event: React.SyntheticEvent) {
    event.preventDefault();
    setLeaveModalOpen(false);
  }

  function leaveOccCreating() {
    if (blocker && blocker?.proceed) {
      blocker?.proceed();
      navigate(blocker?.location);
    }
  }

  async function saveChanges() {
    try {
      await dispatch(updateOcc()).unwrap();
      await dispatch(reset());
      leaveOccCreating();
    } catch (error) {
      console.warn(error);
    }
  }

  function closeCancelModalOpen() {
    dispatch(setCancelModalOpen(false));
  }

  async function cancelOccCreating() {
    setDeleteLoading(true);
    try {
      if (storedOccId && occId !== "new") {
        await OCCService.deleteOcc(storedOccId);
      }

      if (location.state?.returnPath) {
        navigate(location.state?.returnPath);
      } else if (status?.key !== Dictionary.OCCStatus.Draft) {
        navigate(paths.myOcc);
      } else {
        navigate(draftsListPath);
      }

      closeCancelModalOpen();
    } catch (error) {
      console.warn(error);
    } finally {
      setDeleteLoading(false);
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div>
      <PageHeader title="Создание собрания" />

      <div className="flex">
        {steps.map((step, index) => (
          <StepperButton step={index + 1} activeStep={activeStep} key={index}>
            {step}
          </StepperButton>
        ))}
      </div>

      {!isLoading && (
        <div className={styles.content}>
          <Outlet />
        </div>
      )}

      {isEditing ? (
        <BaseModal isOpen={isLeaveModalOpen} maxWidth="421px" minWidth="421px">
          <BaseModalHeader title="Вы покидаете редактирование" onClose={(event) => closeLeaveModal(event as any)} />

          <BaseModalContent>
            <p className="text--primary sf-text-regular">Сохранить изменения?</p>
          </BaseModalContent>

          <BaseModalActions>
            <BaseButton variant="secondary" onClick={leaveOccCreating}>
              Нет
            </BaseButton>

            <BaseButton isLoading={isAcceptOccLoading} onClick={saveChanges}>
              Да
            </BaseButton>
          </BaseModalActions>
        </BaseModal>
      ) : (
        <BaseModal isOpen={isLeaveModalOpen} maxWidth="421px" minWidth="421px">
          <BaseModalHeader title="Вы покидаете создание собрания" onClose={(event) => closeLeaveModal(event as any)} />

          <BaseModalContent>
            <p className="text--primary sf-text-regular">Ваше собрание будет сохранено в черновиках</p>
          </BaseModalContent>

          <BaseModalActions>
            <BaseButton variant="secondary" onClick={closeLeaveModal}>
              Отменить
            </BaseButton>

            <BaseButton onClick={leaveOccCreating}>Покинуть создание</BaseButton>
          </BaseModalActions>
        </BaseModal>
      )}

      <BaseModal isOpen={isCancelModalOpen} maxWidth="426px" minWidth="426px">
        <BaseModalHeader title="Удалить собрание?" onClose={closeCancelModalOpen} />

        <BaseModalContent>
          <p className="text--primary sf-text-regular">Все внесенные данные будут удалены</p>
        </BaseModalContent>

        <BaseModalActions>
          <BaseButton variant="secondary" onClick={closeCancelModalOpen}>
            Не хочу удалять
          </BaseButton>

          <BaseButton variant="secondary" isLoading={isDeleteLoading} appearance="negative" onClick={cancelOccCreating}>
            Удалить
          </BaseButton>
        </BaseModalActions>
      </BaseModal>
    </div>
  );
};

export default CreateOccItemLayout;
