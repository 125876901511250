import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Access } from "@/types";
import { infoBlueCircleIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleSetUserAddressModalOpen } from "@/app/store/slices/access";
import { setAddressAndRoleForUser } from "@/app/store/slices/access/actions";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import BaseInput from "@/components/base-input";
import Checkbox from "@/components/checkbox";
import styles from "./styles.module.scss";

const SetUserAddressModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isSetUserAddressModalOpen, Phone, Role } = useSelector((state: RootState) => state.access.setRoleDetails);

  const [form, setForm] = useState<Access.SetAddressForm>(new Access.SetAddressForm());

  const close = () => {
    dispatch(toggleSetUserAddressModalOpen(false));
  };

  const isFormValid = () => {
    const validator = Access.SetAddressFormValidator.isInvalid(form);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        AddressObjectNum: {
          ...form.AddressObjectNum,
          error: validator.AddressObjectNum,
        },
      }));
    }
    return !validator;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });
    setForm((prevState) => ({
      ...prevState,
      error: "",
    }));

    if (!isFormValid() || !Role) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      await dispatch(
        setAddressAndRoleForUser({
          Phone: Phone,
          Role: Role,
          IsOwner: form.IsOwner,
          AddressObjectNum: form.IsOwner ? form.AddressObjectNum.value : undefined,
        })
      ).unwrap();
    } catch (error: any) {
      const errorData = error.Data;
      if (errorData.Errors) {
        Object.keys(errorData.Errors).forEach((key: any) => {
          const message = Array.isArray(errorData.Errors[key]) ? errorData.Errors[key][0] : errorData.Errors[key];
          switch (key) {
            case "AddressObjectNum": {
              setForm((prevState) => ({
                ...prevState,
                AddressObjectNum: {
                  ...form.AddressObjectNum,
                  error: message,
                },
              }));
              break;
            }

            default: {
              setForm((prevState) => ({ ...prevState, error: message }));
              dispatch(
                showSnackbar({
                  key: "set-user-address",
                  body: message,
                  type: SnackbarType.ERROR,
                })
              );
              break;
            }
          }
        });
      } else {
        setForm((prevState) => ({
          ...prevState,
          error: errorData.Message,
        }));
        dispatch(
          showSnackbar({
            key: "set-user-address",
            body: errorData.Message,
            type: SnackbarType.ERROR,
          })
        );
      }
    } finally {
      setForm((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    if (isSetUserAddressModalOpen) {
      setForm(new Access.SetAddressForm());
    }
  }, [isSetUserAddressModalOpen]);

  return (
    <BaseModal isOpen={isSetUserAddressModalOpen} minWidth="520px" maxWidth="520px">
      <BaseModalHeader
        variant="info-iconed"
        iconBgColor="#CCECFC"
        icon={infoBlueCircleIcon}
        title="Пользователь найден"
        subtitle={
          <p className="text--primary center">
            Пользователь с указанным номером телефона <span className="sf-text-bold">{Phone.formatPhone()}</span>{" "}
            существует в мобильном приложении Локоло. <br />
            Необходимо добавить соответствующий адрес в его профиль. Для этого заполните информацию ниже.
          </p>
        }
        onClose={close}
      />

      <BaseModalContent>
        <div className={styles.create_user}>
          <form className={styles.create_user_form} onSubmit={handleSubmit}>
            <div className={styles.full_field}>
              <div className={styles.checkbox_field}>
                <Checkbox
                  checked={form.IsOwner}
                  onChange={(value) =>
                    setForm((prevState) => ({
                      ...prevState,
                      IsOwner: value,
                    }))
                  }
                />
                <p className="sf-text-regular text--primary">Является жителем садоводства</p>
              </div>
            </div>

            {form.IsOwner && (
              <div className={styles.full_field}>
                <BaseInput
                  value={form.AddressObjectNum.value}
                  label="Номер участка"
                  required={false}
                  placeholder="Введите номер участка"
                  errorMessage={form.AddressObjectNum.error}
                  onChange={(value) =>
                    setForm((prevState) => ({
                      ...prevState,
                      AddressObjectNum: { ...prevState.AddressObjectNum, value },
                    }))
                  }
                />
              </div>
            )}
          </form>
        </div>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton variant="secondary" onClick={close}>
          Отменить
        </BaseButton>
        <BaseButton isLoading={form.isLoading} onClick={handleSubmit}>
          Добавить
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default SetUserAddressModal;
