import React, { useRef } from "react";
import styles from "./styles.module.scss";

interface IUploadFileButtonProps {
  children: JSX.Element;
  accept?: string;
  id?: string;
  onChangeFile: (file: File) => void;
}

const UploadFileButton: React.FC<IUploadFileButtonProps> = ({
  children,
  accept = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
  id = "upload-file",
  onChangeFile,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  function emitInputFocus(event: React.SyntheticEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.click();
    }
  }

  function handleOnChange(event: React.FormEvent) {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      onChangeFile(files[0]);
    }
  }

  return (
    <div className={styles.container}>
      <div onClick={emitInputFocus}>{children}</div>
      <input ref={inputRef} id={id} type="file" accept={accept} onChange={handleOnChange} />
    </div>
  );
};

export default UploadFileButton;
