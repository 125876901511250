import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

type UseDebounceReturnType<T> = [T, T, Dispatch<SetStateAction<T>>];

export default function useDebounce<T extends string>(
  value: T,
  delay: number
): UseDebounceReturnType<T> {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const prevValueRef = useRef<T>("" as T);

  useEffect(() => {
    prevValueRef.current = debouncedValue;

    const handler = setTimeout(() => {
      setDebouncedValue((prevValue) => {
        prevValueRef.current = prevValue;
        return value;
      });
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return [debouncedValue, prevValueRef.current, setDebouncedValue];
}
