import { objectToFormData } from "@/helpers/common";
import client, { formDataClient } from "./config";
import { Documents, ResponseAbstract } from "@/types";

export namespace DocumentsService {
  export function getDirectories() {
    return client.get<ResponseAbstract<Documents.Directory[]>>("/api/user/useful/documents/directories");
  }

  export function createDirectory(Name: string) {
    return client.post<ResponseAbstract<Documents.Directory>>("/api/user/useful/documents/directories", { Name });
  }

  export function updateDirectory(directoryId: Documents.Directory["Id"], Name: string) {
    return client.patch<ResponseAbstract<Documents.Directory>>(
      `/api/user/useful/documents/directories/${directoryId}`,
      {
        Name,
      }
    );
  }

  export function updateDirectoriesOrder(Order: { [key: Documents.Directory["Id"]]: number }) {
    return client.put<ResponseAbstract<unknown>>(`/api/user/useful/documents/directories/order`, {
      Order,
    });
  }

  export function deleteDirectory(directoryId: Documents.Directory["Id"]) {
    return client.delete<ResponseAbstract<unknown>>(`/api/user/useful/documents/directories/${directoryId}`);
  }

  // FILES

  export function getFilesByDirectoryId(directoryId: Documents.Directory["Id"]) {
    return client.get<ResponseAbstract<Documents.UsefulDocument[]>>(
      `/api/user/useful/documents/directories/${directoryId}/files`
    );
  }

  export function uploadFileByDirectoryId(
    directoryId: Documents.Directory["Id"],
    body: Documents.UploadFileRequestBody
  ) {
    const formdata = objectToFormData(body);

    return formDataClient.post<ResponseAbstract<Documents.UsefulDocument>>(
      `/api/user/useful/documents/directories/${directoryId}/files`,
      formdata
    );
  }

  export function updateFileByDirectoryId(
    directoryId: Documents.Directory["Id"],
    fileId: unknown,
    body: Documents.UpdateDocumentRequestBody
  ) {
    return client.patch<ResponseAbstract<Documents.UsefulDocument>>(
      `/api/user/useful/documents/directories/${directoryId}/files/${fileId}`,
      body
    );
  }

  export function deleteFileByDirectoryId(directoryId: Documents.Directory["Id"], fileId: unknown) {
    return client.delete<ResponseAbstract<unknown>>(
      `/api/user/useful/documents/directories/${directoryId}/files/${fileId}`
    );
  }
}
