import { useSelector } from "react-redux";
import { Profile } from "@/types";
import { RootState } from "@/app/store";
import { paths } from "@/consts/routes";
import { sidebar, NavLinkType } from "./sidebar";
import NavigationItem from "./NavigationItem";
import styles from "./styles.module.scss";

const RoutesManager: React.FC = () => {
  const profile = useSelector((state: RootState) => state.user.profile);
  const company = useSelector((state: RootState) => state.user.company);
  const sntDetails = useSelector((state: RootState) => state.sntRegistry.details);

  function filterList(list: NavLinkType[]): NavLinkType[] {
    return list.filter(
      (it) =>
        it.role === profile?.role &&
        ((profile.role === Profile.UserRole.Company && (it.type === profile.company_type || it.type === null)) ||
          profile.role === Profile.UserRole.Institution)
    );
  }

  const linksFiltered = filterList(sidebar);

  return (
    <ul className={styles.navigation}>
      {linksFiltered.map((link, index) => (
        <li key={index}>
          <NavigationItem
            link={link}
            filterChildren={filterList}
            action={
              link.path === paths.uk().fullPath().houses ? (
                <span className="sf-text-medium color--text-secondary text--primary">{company?.Addresses.length}</span>
              ) : link.path === "/snt/registry" ? (
                <span className="sf-text-medium color--text-secondary text--primary">{sntDetails?.SectionsCount}</span>
              ) : null
            }
          />
        </li>
      ))}
    </ul>
  );
};

export default RoutesManager;
