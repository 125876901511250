import { Spinner } from "../spinner";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

type IBaseButtonProps = {
  children: React.ReactNode;
  isLoading?: boolean;
  type?: "button" | "submit";
  variant?: "primary" | "secondary" | "tertiary" | "icon-round" | "icon-squared" | "icon-basic";
  appearance?: "default" | "positive" | "negative" | "neutral" | "accent";
  size?: "normal" | "medium" | "small";
  onClick?: React.EventHandler<any>;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
};

const BaseButton: React.FC<IBaseButtonProps> = ({
  children,
  onClick,
  isLoading = false,
  type = "button",
  variant = "primary",
  appearance = "default",
  size = "normal",
  startIcon,
  endIcon,
  className,
  disabled = false,
  style,
}) => {
  const spinnerColor = variant === "secondary" ? "var(--main-primary)" : "var(--main-white)";

  const classname = cx(className, {
    btn: true,
    [`appearance-${appearance}`]: appearance,
    [`variant-${variant}`]: variant,
    [`size-${size}`]: size,
    loading: isLoading,
    disabled,
  });

  return (
    <button style={style} className={classname} type={type} onClick={onClick} disabled={disabled}>
      {isLoading && (
        <span className={styles.loader}>
          <Spinner color={spinnerColor} />
        </span>
      )}
      <span className={styles.content}>
        {startIcon && <span className={styles.start_icon}>{startIcon}</span>}
        {children}
        {endIcon && <span className={styles.end_icon}>{endIcon}</span>}
      </span>
    </button>
  );
};

export default BaseButton;
