import { useSelector } from "react-redux";
import { successIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleHouseDetailsCompliantModal } from "@/app/store/slices/uk/rosreestr";
import BaseModal, { BaseModalActions, BaseModalHeader } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";

const HouseDetailsCompliantModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isShown, apartmentNumber } = useSelector((state: RootState) => state.ukRosreestr.houseDetailsCompliantModal);

  function onClose() {
    dispatch(toggleHouseDetailsCompliantModal({ isShown: false }));
  }

  return (
    <BaseModal isOpen={isShown} minWidth="520px">
      <BaseModalHeader
        variant="info-iconed"
        icon={successIcon}
        iconBgColor="#CFF3E1"
        title={`Данные по помещению № ${
          apartmentNumber ?? ""
        } и собственникам этого помещения соответствуют Росреестру`}
        onClose={onClose}
      />

      <BaseModalActions>
        <BaseButton onClick={onClose}>Понятно</BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default HouseDetailsCompliantModal;
