import { To, NavLink } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

export type SegmentedСontrolItem = {
  text: string;
  link?: To;
  disabled?: boolean;
  onSelect?: () => void;
  isActive?: boolean;
};

type ISegmentedСontrolsProps = {
  list: SegmentedСontrolItem[];
};

const SegmentedСontrols: React.FC<ISegmentedСontrolsProps> = ({ list }) => {
  return (
    <div className={styles.tabs}>
      {list.map((item, index) =>
        item.link ? (
          <NavLink
            className={({ isActive }) => cx({ active: isActive || item.isActive, disabled: item.disabled })}
            to={item.link}
            key={`linked-${index}`}
            onClick={() => (item.onSelect ? item.onSelect() : null)}
          >
            <span className="text--primary sf-text-medium">{item.text}</span>
          </NavLink>
        ) : (
          <span
            className={cx({ active: item.isActive, disabled: item.disabled })}
            key={`btn-${index}`}
            onClick={() => (item.onSelect ? item.onSelect() : null)}
          >
            <span className="text--primary sf-text-medium">{item.text}</span>
          </span>
        )
      )}
    </div>
  );
};

export default SegmentedСontrols;
