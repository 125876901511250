import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Profile, SntPassportAnalytics } from "@/types";
import { SntPassportService } from "@/services/v2";
import { RootState } from "@/app/store";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import BaseButton from "@/components/base-button";
import AnalyticsBuilder from "../Builder";
import PeriodYearsPicker from "./PeriodYearsPicker";
import styles from "../styles.module.scss";

const InstitutionAnalyticsStateSupport: React.FC = () => {
  const institution = useSelector((state: RootState) => state.user.institution);

  const [startYear, setStartYear] = useState<number>();
  const [endYear, setEndYear] = useState<number>();
  const [district, setDistrict] = useState<Profile.MunicipalDistrict | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [statistics, setStatistics] = useState<SntPassportAnalytics.AnalyticsData>();

  async function applyFilters(isReset?: boolean) {
    setIsLoading(true);

    try {
      const { data } = await SntPassportService.getStateSupportStatistics({
        MunicipalDistrict: !isReset ? district?.Name : undefined,
        StartYear: !isReset ? startYear : undefined,
        EndYear: !isReset ? endYear : undefined,
      });

      setStatistics(data.Data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function resetFilters() {
    setDistrict(null);
    setStartYear(undefined);
    setEndYear(undefined);

    applyFilters(true);
  }

  async function fetchBoardData() {
    try {
      const { data } = await SntPassportService.getStateSupportStatistics({});
      setStatistics(data.Data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchBoardData();
  }, []);

  return (
    <div>
      <div className={styles["b-analytics-filter"]}>
        <div className={styles["b-analytics-filter__base"]}>
          <div className={styles["b-analytics-filter__filters"]}>
            <div className={styles["b-analytics-filter__field"]} style={{ flexBasis: "499px", minWidth: "300px" }}>
              <span className="color--text-primary sf-text-regular text--primary">Район:</span>
              <BaseDropdown
                value={district?.Id ?? null}
                display={district?.Name}
                isSelectable={true}
                placeholder="Все"
                variant="formfield"
                onSelect={(value) => {
                  const dist = institution?.MunicipalDistricts.find((it) => it.Id === value);
                  setDistrict(dist ?? null);
                }}
              >
                <BaseDropdownMenuItem value={null}>
                  <span className="sf-text-medium">Все</span>
                </BaseDropdownMenuItem>
                {institution?.MunicipalDistricts &&
                  institution.MunicipalDistricts?.map((district) => (
                    <BaseDropdownMenuItem key={district.Id} value={district.Id}>
                      <span className="sf-text-medium">{district.Name}</span>
                    </BaseDropdownMenuItem>
                  ))}
              </BaseDropdown>
            </div>

            <div className={styles["b-analytics-filter__field"]} style={{ flexBasis: "208px", minWidth: "150px" }}>
              <span className="color--text-primary sf-text-regular text--primary">Период:</span>
              <PeriodYearsPicker
                start={startYear}
                end={endYear}
                onChange={({ start, end }: { start?: number; end?: number }) => {
                  setStartYear(start);
                  setEndYear(end);
                }}
              />
            </div>
          </div>

          <div className={styles["b-analytics-filter__actions"]}>
            <BaseButton size="medium" onClick={() => applyFilters()}>
              Показать
            </BaseButton>
            <BaseButton variant="secondary" appearance="negative" size="medium" onClick={resetFilters}>
              Сбросить фильтр
            </BaseButton>
          </div>
        </div>
      </div>

      <AnalyticsBuilder isLoading={isLoading} statistics={statistics} maxInRow={4} />
    </div>
  );
};

export default InstitutionAnalyticsStateSupport;
