/***
 *
 * @function transformInToFormObject
 * @param {{ [key: string]: any }} data - FormData
 * @returns {number} - FormData object
 *
 ***/
export const transformInToFormObject = (data: { [key: string]: any }) => {
  let formData: FormData = new FormData();

  for (let key in data) {
    if (key === "Files") {
      for (let innerKey in data[key]) {
        formData.append(`Files[${innerKey}]`, data[key][innerKey]);
      }
    } else if (Array.isArray(data[key])) {
      data[key].forEach((obj: any, index: any) => {
        let keyList = Object.keys(obj);
        keyList.forEach((keyItem) => {
          let keyName = [key, "[", index, "]", "[", keyItem, "]"].join("");
          formData.append(keyName, obj[keyItem]);
        });
      });
    } else {
      formData.append(key, data[key]);
    }
  }

  return formData;
};

export const objectToFormData = (obj: any, form?: any, namespace?: string) => {
  let fd = form || new FormData();
  let formKey;

  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }

      if (typeof obj[property] === "object" && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
};

/***
 *
 * @function cutFileName
 * @param {string} name - Имя файла.
 * @param {number} maxLength - Максимальная длина возвращаемой строки.
 * @param {number} ellipsisLength - Количество символов, которые нужно заменить.
 * @returns {number} - Возвращает отформатированную строку
 *
 ***/
export const cutFileName = (name: string, maxLength: number, ellipsisLength: number): string => {
  if (name.length > maxLength) {
    return name.substring(0, maxLength - ellipsisLength - 3) + "..." + name.substring(name.length - ellipsisLength);
  }

  return name;
};

export const cutName = (text: string, max_length: number = 17): string => text.substring(0, max_length) + "...";

export const formatDigitals = (digitals: string): string => {
  const [integer, float] = digitals.split(".");

  if (!float) {
    return integer.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return integer.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "." + float;
};

export const getLocalStorageData = (keys: string | Array<string>) => {
  let data = {};

  if (typeof keys === "string") {
    const value = localStorage.getItem(keys);

    if (value) {
      return JSON.parse(value);
    }
  }

  if (Array.isArray(keys)) {
    keys.forEach((key: string) => {
      const value = localStorage.getItem(key);

      if (value) {
        data = { ...data, ...JSON.parse(value) };
      }
    });

    return data;
  }
};
