import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import styles from "./styles.module.scss";

const SntMyOCCItemEvents: React.FC = () => {
  const occ = useSelector((state: RootState) => state.sntMyOcc.occ);

  return (
    <div className={styles.events}>
      <div>
        <h4 className="headline-h4 sf-text-bold">{occ?.PlannedEvents?.Publication.Date}</h4>
        <p className="text--primary sf-text-regular color--text-primary">{occ?.PlannedEvents?.Publication.Text}</p>
      </div>

      <div>
        <h4 className="headline-h4 sf-text-bold">{occ?.PlannedEvents?.Start.Date}</h4>
        <p className="text--primary sf-text-regular color--text-primary">{occ?.PlannedEvents?.Start.Text}</p>
      </div>

      <div>
        <h4 className="headline-h4 sf-text-bold">{occ?.PlannedEvents?.End.Date}</h4>
        <p className="text--primary sf-text-regular color--text-primary">{occ?.PlannedEvents?.End.Text}</p>
      </div>

      <div>
        <h4 className="headline-h4 sf-text-bold">{occ?.PlannedEvents?.CreateProtocol.Date}</h4>
        <p className="text--primary sf-text-regular color--text-primary">{occ?.PlannedEvents?.CreateProtocol.Text}</p>
      </div>
    </div>
  );
};

export default SntMyOCCItemEvents;
