import { House } from "./house";
import { Form, FormFiled, FormValidator } from "./ui";
import errorMessages from "@/helpers/errorMessages.json";

export namespace Access {
  export enum RoleKey {
    Chairman = "CHAIRMAN",
    Representative = "REPRESENTATIVE",
    Specialist = "SPECIALIST",
    GardeningBoardMember = "GARDENING_BOARD_MEMBER",
  }

  export type Role = {
    Key: RoleKey;
    Value: string;
  };

  export type User = {
    Id: number;
    User: {
      Id: number;
      Phone: string;
      FirstName: string;
      LastName: string;
    };
    Role: Role;
    House: {
      Id: House.Item["Id"];
      Address: House.Item["Address"];
    };
    Houses: Array<House.Item["Id"]> | null;
    CanUpdate: boolean;
    CanDelete: boolean;
  };

  export class SetRoleForm extends Form {
    Phone: FormFiled = new FormFiled("+7", false);
    Role: Role | null = null;
    HouseIds: Array<House.Item["Id"]> = [];
    HouseIdsError: string = "";

    constructor(roles: Role[], addresses?: House.Item[]) {
      super();
      this.Role = roles[0];
      this.HouseIds = addresses?.map((it) => it.Id) ?? [];
    }
  }

  export class SetRoleFormValidator {
    static validateHouses(houses: SetRoleForm["HouseIds"]) {
      if (houses.length === 0) return errorMessages.houses.empty;

      return "";
    }

    public static isInvalid(form: SetRoleForm, isUK: boolean) {
      const errors = {
        Phone: FormValidator.getPhoneErrorMessage(form.Phone.value.trim(), form.Phone.isRequired),
        HouseIds: isUK ? this.validateHouses(form.HouseIds) : "",
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export class CreateUserForm extends Form {
    FirstName: FormFiled = new FormFiled("", true);
    LastName: FormFiled = new FormFiled("", true);
    AddressObjectNum: FormFiled = new FormFiled("", true);
    IsOwner: boolean = false;
  }

  export class CreateUserFormValidator {
    static validateAddressObjectNum(form: CreateUserForm) {
      if (!form.IsOwner) return "";

      return FormValidator.getFieldErrorMessage(form.AddressObjectNum.value.trim(), form.AddressObjectNum.isRequired);
    }

    public static isInvalid(form: CreateUserForm) {
      const errors = {
        FirstName: FormValidator.validateCyrillicField(form.FirstName.value.trim(), form.FirstName.isRequired),
        LastName: FormValidator.validateCyrillicField(form.LastName.value.trim(), form.LastName.isRequired),
        AddressObjectNum: this.validateAddressObjectNum(form),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export class SetAddressForm extends Form {
    AddressObjectNum: FormFiled = new FormFiled("", true);
    IsOwner: boolean = false;
  }

  export class SetAddressFormValidator {
    static validateAddressObjectNum(form: SetAddressForm) {
      if (!form.IsOwner) return "";

      return FormValidator.getFieldErrorMessage(form.AddressObjectNum.value.trim(), form.AddressObjectNum.isRequired);
    }

    public static isInvalid(form: SetAddressForm) {
      const errors = {
        AddressObjectNum: this.validateAddressObjectNum(form),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export class EditRoleForm extends Form {
    HouseIds: Array<House.Item["Id"]> = [];
    HouseIdsError: string = "";

    constructor(user: User) {
      super();
      this.HouseIds = user.Houses ?? [];
    }
  }

  export class EditRoleFormValidator {
    static validateHouses(houses: SetRoleForm["HouseIds"]) {
      if (houses.length === 0) return errorMessages.houses.empty;

      return "";
    }

    public static isInvalid(form: EditRoleForm, isUK: boolean) {
      const errors = {
        HouseIds: isUK ? this.validateHouses(form.HouseIds) : "",
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export type SetAddressAndRoleRequestBody = {
    Phone: string;
    Role: Access.Role["Key"];
    IsOwner: boolean;
    AddressObjectNum?: string;
  };

  export type CreateUserRequestBody = {
    FirstName: string;
    LastName: string;
    Phone: string;
    Role: Access.Role["Key"];
    IsOwner?: boolean;
    AddressObjectNum?: string;
    HouseIds?: Array<House.Item["Id"]>;
  };
}
