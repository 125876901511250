import React from "react";
import classNames from "classnames/bind";
import { chevronRight16Icon } from "@/consts/icons";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface ISelectAddressDefaultButtonProps {
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  errorMessage?: string;
  disabled?: boolean;
  onClick: () => void;
}

const SelectAddressDefaultButton: React.FC<ISelectAddressDefaultButtonProps> = ({
  children,
  errorMessage,
  disabled,
  onClick,
}) => {
  return (
    <div
      className={cx("b-select-address-button", { "b-select-address-button--disabled": disabled })}
      data-error={errorMessage ?? ""}
    >
      <div role="button" onClick={onClick}>
        <p className="color--text-primary form-field--text sf-text-regular">{children}</p>
        <span className="flex middle center">{chevronRight16Icon("#818C99")}</span>
      </div>
    </div>
  );
};

export default SelectAddressDefaultButton;
