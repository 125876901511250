import BaseModal, { BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import { useAppDispatch } from "@/app/store";
import { deleteFileByDirectoryId } from "@/app/store/slices/documents/actions";
import { useState } from "react";
import { Documents } from "@/types";

interface IDeleteDocumentModalProps {
  isOpen: boolean;
  document: Documents.UsefulDocument;
  onClose: () => void;
}

const DeleteDocumentModal: React.FC<IDeleteDocumentModalProps> = ({ isOpen, document, onClose }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
  };

  const handleDeleteDocument = async () => {
    setLoading(true);
    try {
      await dispatch(deleteFileByDirectoryId({ directoryId: document.DirectoryId, fileId: document.Id })).unwrap();
      onClose();
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal isOpen={isOpen} minWidth="426px">
      <BaseModalHeader title="Удалить документ?" onClose={handleClose} />

      <BaseModalContent>
        <p className="sf-text-regular text--primary">Вы уверены что хотите удалить документ?</p>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton variant="secondary" onClick={onClose}>
          Отменить
        </BaseButton>
        <BaseButton isLoading={isLoading} onClick={handleDeleteDocument}>
          Удалить
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default DeleteDocumentModal;
