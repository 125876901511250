import { House, Pagination, ResponseAbstract, ResponseAbstractWithPagination, UKRegistry } from "@/types";
import client, { blobClient, formDataClient } from "../config";

export namespace UKRegistryService {
  export function getHouseRegistryDetails(FiasId: House.Item["FiasId"], OnlyActual: boolean = false) {
    return client.post<ResponseAbstract<UKRegistry.HouseRegistryDetails>>("/api/reestr/mkd/get", {
      FiasId,
      OnlyActual,
    });
  }

  export function getHouseApartments({
    body,
    page = 1,
    limit = 50,
  }: {
    body: UKRegistry.GetHouseApartmentsRequestBody;
    page?: Pagination["Index"];
    limit?: Pagination["Limit"];
  }) {
    const bodyWithSorting = {
      ...body,
      Sort: {
        Field: "number",
        Direction: "asc",
      },
    };
    return client.post<ResponseAbstractWithPagination<UKRegistry.Apartment[]>>(
      "/api/reestr/mkd/apartment/get",
      bodyWithSorting,
      {
        params: { page, limit },
      }
    );
  }

  export function saveHouseArea(FiasId: House.Item["FiasId"], Area: number | string) {
    return client.post<ResponseAbstract<boolean>>("/api/reestr/mkd/area/send", { FiasId, Area });
  }

  export function importRegistryFile(
    File: File,
    Address: House.Item["Address"],
    CadastralNumber?: string,
    Area?: number | string
  ) {
    let formdata = new FormData();
    formdata.append("File", File);
    formdata.append("Address", Address);
    if (CadastralNumber) {
      formdata.append("CadastralNumber", CadastralNumber);
    }
    if (Area) {
      formdata.append("Area", Area.toString());
    }
    return formDataClient.post<unknown>("/api/reestr/import/mkd", formdata);
  }
  export function exportRegistry({
    HouseFiasId,
    page = 1,
    limit,
    OwnerSearch,
    Source,
  }: {
    HouseFiasId: House.Item["FiasId"];
    page: number;
    limit: number;
    OwnerSearch?: string;
    Source?: UKRegistry.OwnerSource;
  }) {
    return blobClient.get<any>("/api/reestr/mkd/apartment/export", {
      params: { HouseFiasId, page, limit, OwnerSearch, Source },
    });
  }

  export function deleteApartment(params: {
    HouseFiasId: House.Item["FiasId"];
    HouseId: House.Item["Id"];
    Id: UKRegistry.Apartment["Id"];
    Number: UKRegistry.Apartment["Number"];
  }) {
    return client.delete<ResponseAbstract<string>>("/api/reestr/mkd/apartment", { params });
  }

  export function getApartmentDetailsById(HouseFiasId: House.Item["FiasId"], Id: UKRegistry.Apartment["Id"]) {
    return client.post<UKRegistry.ApartmentDetailsResponse>(
      "/api/reestr/mkd/apartment/get-by-id",
      { HouseFiasId, Id },
      {
        params: { Include: ["OwnerChange"] },
      }
    );
  }

  export function createApartment(body: UKRegistry.CreateApartmentRequestBody) {
    return client.post<ResponseAbstract<UKRegistry.ApartmentFullDetails>>("/api/reestr/mkd/apartment", body);
  }

  export function updateApartment(body: UKRegistry.UpdateApartmentRequestBody) {
    return client.patch<ResponseAbstract<UKRegistry.ApartmentFullDetails>>("/api/reestr/mkd/apartment", body);
  }

  export function getApartmentsComparison({
    HouseFiasId,
    ApartmentNumber,
    Status,
    page = 1,
    limit = 50,
  }: {
    HouseFiasId: House.Item["FiasId"];
    ApartmentNumber?: string;
    Status?: UKRegistry.RosreestrСomparisonStatus;
    page?: Pagination["Index"];
    limit?: Pagination["Limit"];
  }) {
    return client.post<ResponseAbstractWithPagination<UKRegistry.RosreestrСomparison[]>>(
      "/api/reestr/mkd/apartments-comparison",
      { HouseFiasId, ApartmentNumber, Status },
      { params: { page, limit } }
    );
  }

  export function startRosreestrDataLoad(FiasId: House.Item["FiasId"]) {
    return client.post<ResponseAbstract<unknown>>("/api/reestr/mkd/rosreestr-data-load/start", {
      FiasId,
    });
  }

  export function getApartmentComparisonById(comparisonId: UKRegistry.RosreestrСomparison["Id"]) {
    return client.get<ResponseAbstract<UKRegistry.RosreestrСomparisonDetails>>(
      `/api/reestr/mkd/apartments-comparison/${comparisonId}/differences`
    );
  }

  export function getRosreestrDataLoadInfo(FiasId: House.Item["FiasId"]) {
    return client.post<ResponseAbstract<UKRegistry.RosreestrDataLoadInfo>>("api/reestr/mkd/rosreestr-data-load/info", {
      FiasId,
    });
  }

  export function saveApartmentComparisonChanges(
    comparisonId: UKRegistry.RosreestrСomparison["Id"],
    body: UKRegistry.SaveApartmentComparisonChangesRequestBody
  ) {
    return client.post<ResponseAbstract<unknown>>(`/api/reestr/mkd/apartments-comparison/${comparisonId}`, body);
  }

  export function saveReestrMissingData(HouseFiasId: House.Item["FiasId"]) {
    return client.post<ResponseAbstract<unknown>>("/api/reestr/mkd/apartments-comparison/save-reestr-missing-data", {
      HouseFiasId,
    });
  }
}
