import { useMemo } from "react";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import styles from "./styles.module.scss";

type TypeOption = {
  value: string | number;
  text: string | number;
};

interface ISearchByQueryTypeProps {
  query: string;
  type: TypeOption["value"] | null;
  typeOptions: TypeOption[];
  onQueryChange: (value: string) => void;
  onTypeChange: (value: TypeOption["value"]) => void;
}

const SearchByQueryType: React.FC<ISearchByQueryTypeProps> = ({
  query,
  type,
  typeOptions,
  onQueryChange,
  onTypeChange,
}) => {
  const typeText = useMemo(() => {
    const option = typeOptions.find((it) => it.value === type);
    return option?.text ?? "Фильтр";
  }, [type, typeOptions]);

  function handleQueryChange(event: React.SyntheticEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (value !== query) {
      onQueryChange(value);
    }
  }

  function handleQueryTypeChange(value: TypeOption["value"]) {
    if (value !== type) {
      onTypeChange(value);
    }
  }

  return (
    <div className={styles["b-search-by-type"]}>
      <div>
        <BaseDropdown
          value={type}
          customToggler={
            <div className={styles["b-search-by-type__select"]}>
              <span className="text--primary color--text-primary sf-text-regular">{typeText}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.171 7.36222C5.064 7.21889 5 7.03444 5 6.83333C5 6.37333 5.336 6 5.75 6H10.25C10.664 6 11 6.37333 11 6.83333C11 7.03444 10.936 7.21889 10.829 7.36222L8.606 10.6578C8.47 10.8644 8.249 11 8 11C7.751 11 7.53 10.8644 7.394 10.6578L5.171 7.36222Z"
                  fill="var(--text-placeholder)"
                />
              </svg>
            </div>
          }
          isSelectable={true}
          onSelect={handleQueryTypeChange}
        >
          {typeOptions.map((it) => (
            <BaseDropdownMenuItem value={it.value} key={it.value}>
              <span className="sf-text-regular">{it.text}</span>
            </BaseDropdownMenuItem>
          ))}
        </BaseDropdown>
      </div>

      <span></span>

      <div className={styles["b-search-by-type__query"]}>
        <svg className={styles["b-search-by-type__query_icon"]} width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5 7C11.5 9.48528 9.48528 11.5 7 11.5C4.51472 11.5 2.5 9.48528 2.5 7C2.5 4.51472 4.51472 2.5 7 2.5C9.48528 2.5 11.5 4.51472 11.5 7ZM10.4765 11.8907C9.49572 12.5892 8.29583 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 8.29583 12.5892 9.49572 11.8907 10.4765L14.7071 13.2929C15.0976 13.6834 15.0976 14.3166 14.7071 14.7071C14.3166 15.0976 13.6834 15.0976 13.2929 14.7071L10.4765 11.8907Z"
            fill="#818C99"
          />
        </svg>
        <input
          className="text--primary color--text-primary sf-text-regular"
          type="text"
          value={query}
          onChange={handleQueryChange}
          placeholder="Поиск"
        />
      </div>
    </div>
  );
};

export default SearchByQueryType;
