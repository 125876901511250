import { Form, FormFiled, FormValidator, Profile, SelectField } from "@/types";
import { OCCV2Author } from "./";

export namespace OCCV2AuthorForms {
  export const LEGAL_FORMS = [
    "Общество с ограниченной ответственностью",
    "Открытое акционерное общество",
    "Закрытое акционерное общество",
    "Товарищество собственников жилья",
    "Товарищество собственников недвижимости",
  ];

  export class SetAuthorForm extends Form {
    Name: FormFiled = new FormFiled("", true);
    Surname: FormFiled = new FormFiled();
    Patronymic: FormFiled = new FormFiled();
    RoomAddress: FormFiled = new FormFiled();
    Location: FormFiled = new FormFiled();
    EntityLegalForm: SelectField<string | null> = new SelectField(null);
    EntityOGRN: FormFiled = new FormFiled();
    Phone: FormFiled = new FormFiled("", true);
    FoundationAgreementDetails: FormFiled = new FormFiled("", true);
    UkLegalForm: SelectField<string | null> = new SelectField(null, true);
    UkOGRN: FormFiled = new FormFiled("", true);

    constructor(author?: OCCV2Author.Person, company?: Profile.CompanyData) {
      super();
      if (author) {
        this.Name = new FormFiled(author.Name, true);
        this.Surname = new FormFiled(author.Type === OCCV2Author.PersonType.Individual ? author.Surname : "");
        this.Patronymic = new FormFiled(
          author.Type === OCCV2Author.PersonType.Individual ? author.Patronymic ?? "" : ""
        );
        this.RoomAddress = new FormFiled(
          author.Type === OCCV2Author.PersonType.Individual ? author.RoomAddress ?? "" : ""
        );
        this.Location = new FormFiled(author.Type === OCCV2Author.PersonType.LegalEntity ? author.Location ?? "" : "");
        this.EntityLegalForm = new SelectField(
          author.Type === OCCV2Author.PersonType.LegalEntity ? author.EntityLegalForm ?? null : null
        );
        this.EntityOGRN = new FormFiled(
          author.Type === OCCV2Author.PersonType.LegalEntity ? author.EntityOGRN ?? "" : ""
        );
        this.Phone = new FormFiled(
          author.Phone
            ? author.Phone.toString().startsWith("+")
              ? author.Phone.toString()
              : `+${author.Phone}`?.toString()
            : "",
          true
        );
        this.FoundationAgreementDetails = new FormFiled(
          author.Type === OCCV2Author.PersonType.ManagementCompany ? author.FoundationAgreementDetails ?? "" : ""
        );
        this.UkLegalForm = new SelectField(
          author.Type === OCCV2Author.PersonType.ManagementCompany ? author.UkLegalForm ?? null : null,
          true
        );
        this.UkOGRN = new FormFiled(
          author.Type === OCCV2Author.PersonType.ManagementCompany ? author.UkOGRN ?? "" : "",
          true
        );
      } else if (company) {
        this.Name = new FormFiled(company.Name, true);
        this.Phone = new FormFiled(
          company.Phone
            ? company.Phone.toString().startsWith("+")
              ? company.Phone.toString()
              : `+${company.Phone}`?.toString()
            : "",
          true
        );
        this.FoundationAgreementDetails = new FormFiled(company.FoundationAgreementDetails);
        this.UkLegalForm = new SelectField(company.LegalForm, true);
        this.UkOGRN = new FormFiled(company.OGRN, true);
      }
    }
  }

  export class SetAuthorFormValidator {
    public static isInvalid(form: SetAuthorForm) {
      const errors = {
        Name: FormValidator.getFieldErrorMessage(form.Name.value.trim(), form.Name.isRequired),
        Phone: FormValidator.getPhoneErrorMessage(form.Phone.value, form.Phone.isRequired),
        FoundationAgreementDetails: FormValidator.getFieldErrorMessage(
          form.FoundationAgreementDetails.value.trim(),
          form.FoundationAgreementDetails.isRequired
        ),
        UkLegalForm: FormValidator.getSelectFieldErrorMessage(form.UkLegalForm.value, form.UkLegalForm.isRequired),
        UkOGRN: FormValidator.getFieldErrorMessage(form.UkOGRN.value.trim(), form.UkOGRN.isRequired),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export class SetAdministratorForm extends Form {
    Name: FormFiled = new FormFiled("", true);
    Phone: FormFiled = new FormFiled("", true);
    Surname: FormFiled = new FormFiled();
    Patronymic: FormFiled = new FormFiled();
    PermanentResidencePlace: FormFiled = new FormFiled();
    Email: FormFiled = new FormFiled();
    Series: FormFiled = new FormFiled();
    Number: FormFiled = new FormFiled();
    IssuedBy: FormFiled = new FormFiled();
    IssueDate: FormFiled = new FormFiled();
    DepartmentCode: FormFiled = new FormFiled();
    Location: FormFiled = new FormFiled();
    MailingAddress: FormFiled = new FormFiled();
    Site: FormFiled = new FormFiled();
    FoundationAgreementDetails: FormFiled = new FormFiled("", true);
    OGRN: FormFiled = new FormFiled("", true);
    LegalForm: SelectField<string | null> = new SelectField(null, true);

    constructor(administrator?: OCCV2Author.Person, company?: Profile.CompanyData) {
      super();
      if (administrator) {
        this.Name = new FormFiled(administrator.Name, true);
        this.Phone = new FormFiled(
          administrator.Phone
            ? administrator.Phone.toString().startsWith("+")
              ? administrator.Phone.toString()
              : `+${administrator.Phone}`?.toString()
            : "",
          true
        );
        this.Surname = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.Individual ? administrator.Surname : ""
        );
        this.Patronymic = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.Individual ? administrator.Patronymic ?? "" : ""
        );
        this.PermanentResidencePlace = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.Individual ? administrator.PermanentResidencePlace ?? "" : ""
        );
        this.Email = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.Individual ? administrator.Email ?? "" : ""
        );
        this.Series = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.Individual ? administrator.Series ?? "" : ""
        );
        this.Number = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.Individual ? administrator.Number ?? "" : ""
        );
        this.IssuedBy = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.Individual ? administrator.IssuedBy ?? "" : ""
        );
        this.IssueDate = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.Individual ? administrator.IssueDate ?? "" : ""
        );
        this.DepartmentCode = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.Individual ? administrator.DepartmentCode ?? "" : ""
        );

        this.Location = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.LegalEntity ? administrator.Location ?? "" : ""
        );
        this.MailingAddress = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.LegalEntity ? administrator.MailingAddress ?? "" : ""
        );

        this.Site = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.LegalEntity ? administrator.Site ?? "" : ""
        );
        this.FoundationAgreementDetails = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.ManagementCompany
            ? administrator.FoundationAgreementDetails ?? ""
            : "",
          true
        );
        this.LegalForm = new SelectField(
          administrator.Type === OCCV2Author.PersonType.LegalEntity
            ? administrator.LegalForm ?? null
            : administrator.Type === OCCV2Author.PersonType.ManagementCompany
            ? administrator.UkLegalForm ?? null
            : null,
          true
        );
        this.OGRN = new FormFiled(
          administrator.Type === OCCV2Author.PersonType.ManagementCompany
            ? administrator.UkOGRN ?? administrator.OGRN ?? ""
            : "",
          true
        );
      } else if (company) {
        this.Name = new FormFiled(company.Name, true);
        this.Phone = new FormFiled(
          company.Phone
            ? company.Phone.toString().startsWith("+")
              ? company.Phone.toString()
              : `+${company.Phone}`?.toString()
            : "",
          true
        );
        this.FoundationAgreementDetails = new FormFiled(company.FoundationAgreementDetails ?? "", true);
        this.LegalForm = new SelectField(company.LegalForm, true);
        this.OGRN = new FormFiled(company.OGRN, true);
      }
    }
  }

  export class SetAdministratorFormValidator {
    public static isInvalid(form: SetAdministratorForm) {
      const errors = {
        Name: FormValidator.getFieldErrorMessage(form.Name.value.trim(), form.Name.isRequired),
        Phone: FormValidator.getPhoneErrorMessage(form.Phone.value, form.Phone.isRequired),
        FoundationAgreementDetails: FormValidator.getFieldErrorMessage(
          form.FoundationAgreementDetails.value.trim(),
          form.FoundationAgreementDetails.isRequired
        ),
        LegalForm: FormValidator.getSelectFieldErrorMessage(form.LegalForm.value, form.LegalForm.isRequired),
        OGRN: FormValidator.getFieldErrorMessage(form.OGRN.value.trim(), form.OGRN.isRequired),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }
}
