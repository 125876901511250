import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleInstructions } from "@/app/store/slices/snt/registry";
import BaseButton from "@/components/base-button";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import styles from "../styles.module.scss";

const InstructionsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const isInstructionsModalOpen = useSelector((state: RootState) => state.sntRegistry.isInstructionsModalOpen);

  const closeInstructionsModal = () => {
    dispatch(toggleInstructions(false));
  };

  return (
    <BaseModal isOpen={isInstructionsModalOpen} maxWidth="588px" minWidth="588px">
      <BaseModalHeader title="Как заполнять правильно?" onClose={closeInstructionsModal} />

      <BaseModalContent>
        <p className="text--primary sf-text-regular">
          Для работы системы необходимо загрузить реестр участков, составленный по шаблону для заполнения. Ознакомиться
          с инструкцией по заполнению шаблона вы можете скачав соответствующий файл.
        </p>
        <ul className={styles.instructions_list}>
          <li>
            <span className="sf-text-semibold text--primary">1</span>
            <p className="text--primary sf-text-regular">Ознакомьтесь с инструкцией</p>
          </li>
          <li>
            <span className="sf-text-semibold text--primary">2</span>
            <p className="text--primary sf-text-regular">Скачайте шаблон для заполнения</p>
          </li>
          <li>
            <span className="sf-text-semibold text--primary">3</span>
            <p className="text--primary sf-text-regular">Заполните реестр по инструкции и шаблону</p>
          </li>
          <li>
            <span className="sf-text-semibold text--primary">4</span>
            <p className="text--primary sf-text-regular">Загрузите составленный реестр в систему</p>
          </li>
        </ul>
      </BaseModalContent>

      <BaseModalActions>
        <a href="/documents/snt/registry_template.xlsx" download>
          <BaseButton startIcon={templateIcon} variant="secondary">
            Шаблон реестра
          </BaseButton>
        </a>

        <a href="/documents/snt/Инструкция.pdf" target="_blank">
          <BaseButton startIcon={instructionsIcon}>Инструкция по заполнению</BaseButton>
        </a>
      </BaseModalActions>
    </BaseModal>
  );
};

export default InstructionsModal;

const templateIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M8 7C7.44772 7 7 7.44772 7 8C7 8.55229 7.44772 9 8 9H16C16.5523 9 17 8.55229 17 8C17 7.44772 16.5523 7 16 7H8Z"
      fill="#226DFF"
    />
    <path
      d="M7 12C7 11.4477 7.44772 11 8 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H8C7.44772 13 7 12.5523 7 12Z"
      fill="#226DFF"
    />
    <path
      d="M14 15C13.4477 15 13 15.4477 13 16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15H14Z"
      fill="#226DFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.7587 3C7.95374 2.99999 7.28937 2.99998 6.74817 3.04419C6.18608 3.09012 5.66937 3.18868 5.18404 3.43598C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404C3.18868 5.66937 3.09012 6.18608 3.04419 6.74818C2.99998 7.28937 2.99999 7.95373 3 8.7587V15.2413C2.99999 16.0463 2.99998 16.7106 3.04419 17.2518C3.09012 17.8139 3.18868 18.3306 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.66937 20.8113 6.18608 20.9099 6.74817 20.9558C7.28936 21 7.95372 21 8.75868 21H15.2413C16.0463 21 16.7106 21 17.2518 20.9558C17.8139 20.9099 18.3306 20.8113 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.8113 18.3306 20.9099 17.8139 20.9558 17.2518C21 16.7106 21 16.0463 21 15.2413V8.75868C21 7.95372 21 7.28937 20.9558 6.74818C20.9099 6.18608 20.8113 5.66937 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43598C18.3306 3.18868 17.8139 3.09012 17.2518 3.04419C16.7106 2.99998 16.0463 2.99999 15.2413 3H8.7587ZM6.09202 5.21799C6.24842 5.1383 6.47262 5.07337 6.91104 5.03755C7.36113 5.00078 7.94342 5 8.8 5H15.2C16.0566 5 16.6389 5.00078 17.089 5.03755C17.5274 5.07337 17.7516 5.1383 17.908 5.21799C18.2843 5.40974 18.5903 5.7157 18.782 6.09202C18.8617 6.24842 18.9266 6.47262 18.9624 6.91104C18.9992 7.36113 19 7.94342 19 8.8V15.2C19 16.0566 18.9992 16.6389 18.9624 17.089C18.9266 17.5274 18.8617 17.7516 18.782 17.908C18.5903 18.2843 18.2843 18.5903 17.908 18.782C17.7516 18.8617 17.5274 18.9266 17.089 18.9624C16.6389 18.9992 16.0566 19 15.2 19H8.8C7.94342 19 7.36113 18.9992 6.91104 18.9624C6.47262 18.9266 6.24842 18.8617 6.09202 18.782C5.7157 18.5903 5.40973 18.2843 5.21799 17.908C5.1383 17.7516 5.07337 17.5274 5.03755 17.089C5.00078 16.6389 5 16.0566 5 15.2V8.8C5 7.94342 5.00078 7.36113 5.03755 6.91104C5.07337 6.47262 5.1383 6.24842 5.21799 6.09202C5.40973 5.7157 5.7157 5.40974 6.09202 5.21799Z"
      fill="#226DFF"
    />
  </svg>
);

const instructionsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M11 8C11 7.44772 11.4477 7 12 7H16C16.5523 7 17 7.44772 17 8C17 8.55229 16.5523 9 16 9H12C11.4477 9 11 8.55229 11 8Z"
      fill="white"
    />
    <path
      d="M12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H12Z"
      fill="white"
    />
    <path
      d="M11 16C11 15.4477 11.4477 15 12 15H16C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17H12C11.4477 17 11 16.5523 11 16Z"
      fill="white"
    />
    <path
      d="M8 7C7.44772 7 7 7.44772 7 8C7 8.55229 7.44772 9 8 9H9C9.55229 9 10 8.55229 10 8C10 7.44772 9.55229 7 9 7H8Z"
      fill="white"
    />
    <path
      d="M7 12C7 11.4477 7.44772 11 8 11H9C9.55229 11 10 11.4477 10 12C10 12.5523 9.55229 13 9 13H8C7.44772 13 7 12.5523 7 12Z"
      fill="white"
    />
    <path
      d="M8 15C7.44772 15 7 15.4477 7 16C7 16.5523 7.44772 17 8 17H9C9.55229 17 10 16.5523 10 16C10 15.4477 9.55229 15 9 15H8Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.7587 2H15.2413C16.0463 1.99999 16.7106 1.99998 17.2518 2.04419C17.8139 2.09012 18.3306 2.18868 18.816 2.43598C19.5686 2.81947 20.1805 3.43139 20.564 4.18404C20.8113 4.66937 20.9099 5.18608 20.9558 5.74818C21 6.28937 21 6.95372 21 7.75868V16.2413C21 17.0463 21 17.7106 20.9558 18.2518C20.9099 18.8139 20.8113 19.3306 20.564 19.816C20.1805 20.5686 19.5686 21.1805 18.816 21.564C18.3306 21.8113 17.8139 21.9099 17.2518 21.9558C16.7106 22 16.0463 22 15.2413 22H8.75868C7.95372 22 7.28937 22 6.74818 21.9558C6.18608 21.9099 5.66937 21.8113 5.18404 21.564C4.43139 21.1805 3.81947 20.5686 3.43598 19.816C3.18868 19.3306 3.09012 18.8139 3.04419 18.2518C2.99998 17.7106 2.99999 17.0463 3 16.2413V7.7587C2.99999 6.95374 2.99998 6.28937 3.04419 5.74818C3.09012 5.18608 3.18868 4.66937 3.43598 4.18404C3.81947 3.43139 4.43139 2.81947 5.18404 2.43598C5.66937 2.18868 6.18608 2.09012 6.74818 2.04419C7.28937 1.99998 7.95374 1.99999 8.7587 2ZM6.91104 4.03755C6.47262 4.07337 6.24842 4.1383 6.09202 4.21799C5.7157 4.40973 5.40973 4.7157 5.21799 5.09202C5.1383 5.24842 5.07337 5.47262 5.03755 5.91104C5.00078 6.36113 5 6.94342 5 7.8V16.2C5 17.0566 5.00078 17.6389 5.03755 18.089C5.07337 18.5274 5.1383 18.7516 5.21799 18.908C5.40973 19.2843 5.7157 19.5903 6.09202 19.782C6.24842 19.8617 6.47262 19.9266 6.91104 19.9624C7.36113 19.9992 7.94342 20 8.8 20H15.2C16.0566 20 16.6389 19.9992 17.089 19.9624C17.5274 19.9266 17.7516 19.8617 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C18.8617 18.7516 18.9266 18.5274 18.9624 18.089C18.9992 17.6389 19 17.0566 19 16.2V7.8C19 6.94342 18.9992 6.36113 18.9624 5.91104C18.9266 5.47262 18.8617 5.24842 18.782 5.09202C18.5903 4.7157 18.2843 4.40973 17.908 4.21799C17.7516 4.1383 17.5274 4.07337 17.089 4.03755C16.6389 4.00078 16.0566 4 15.2 4H8.8C7.94342 4 7.36113 4.00078 6.91104 4.03755Z"
      fill="white"
    />
  </svg>
);
