import { useMemo } from "react";
import classNames from "classnames/bind";
import { Dictionary, Documents, OCC } from "@/types";
import { xlsFileIcon, docFileIcon, pdfFileIcon, otherFileIcon } from "@/consts/icons";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IBaseFileItemProps {
  file: Dictionary.File | OCC.OCCDocument | Documents.UsefulDocument;
  onDelete?: () => void;
  onSelect?: () => void;
  children?: JSX.Element | JSX.Element[];
}

const BaseFileItem: React.FC<IBaseFileItemProps> = ({ file, onDelete, onSelect, children }) => {
  const isDisabled: boolean = useMemo(() => {
    return "IsCreated" in file && !file.IsCreated;
  }, [file]);

  const extension: Dictionary.File["Extension"] | null = useMemo(() => {
    return "Extension" in file ? file.Extension : null;
  }, [file]);

  const name: string = useMemo(() => {
    return "DocumentType" in file ? file.DocumentType : "Name" in file ? file.Name : "";
  }, [file]);

  const size: string = useMemo(() => {
    if ("Size" in file && typeof file.Size === "string") return file.Size;
    if (!("Size" in file) || !+file.Size) return "0 Байт";

    if (typeof file.Size === "string") return file.Size;

    const k = 1024;
    const sizes = ["Байт", "КБ", "МБ", "ГБ", "ТБ", "ПиБ", "EiB", "ZiB", "YiB"];

    const i = Math.floor(Math.log(file.Size) / Math.log(k));

    return `${parseFloat((file.Size / Math.pow(k, i)).toFixed(0))}${sizes[i]}`;
  }, [file]);

  const parsed: { bg: string; icon: JSX.Element } = useMemo(() => {
    const xlsFormats = ["xlsx", "xlsm", "xlsb", "xltx", "xltm", "xls", "xlt", "xml"];
    const docFormats = ["doc", "docx", "docm", "dotx", "dotm", "docb"];

    if (extension && xlsFormats.includes(extension.toLowerCase())) {
      return {
        bg: "#DFFCEE",
        icon: xlsFileIcon,
      };
    }

    if (extension && docFormats.includes(extension.toLowerCase())) {
      return {
        bg: "#DFECFF",
        icon: docFileIcon,
      };
    }

    if (extension && extension.toLowerCase() === "pdf") {
      return {
        bg: "#FFDFDF",
        icon: pdfFileIcon,
      };
    }

    return {
      bg: "#EDF1F5",
      icon: otherFileIcon,
    };
  }, [extension]);

  const handleSelect = () => {
    if (onSelect) {
      onSelect();
    }
  };

  return (
    <div className={cx({ file: true, disabled: isDisabled })}>
      <span className="flex middle center" style={{ backgroundColor: parsed.bg }}>
        {parsed.icon}
      </span>
      <div>
        <p className="text--primary sf-text-medium color--text-primary">
          <span className={cx({ selectable: onSelect })} onClick={handleSelect}>
            {name}
          </span>
        </p>
        <div className={`flex middle caption--middle ${styles.actions}`}>
          {"Size" in file && "Extension" in file && (
            <span className="color--text-secondary sf-text-regular">
              {extension?.toUpperCase()}, {size}
            </span>
          )}

          {"IsCreated" in file && file.IsCreated && "URL" in file && (
            <a href={(file as Dictionary.File).URL} className="sf-text-regular color--primary--btn-hover" download>
              Скачать
            </a>
          )}

          {"IsCreated" in file && !file.IsCreated && (
            <span className="sf-text-regular color--text-secondary">{file.IsCreatedText}</span>
          )}

          {onDelete && (
            <span className="sf-text-regular color--primary--btn-hover" role="button" onClick={onDelete}>
              Удалить
            </span>
          )}

          {children}
        </div>
      </div>
    </div>
  );
};

export default BaseFileItem;
