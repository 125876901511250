import { createAsyncThunk } from "@reduxjs/toolkit";
import { SntRegistryService } from "@/services/v2";
import { RootState } from "@/app/store";
import { activeAddress } from "@/app/store/slices/user";
import {
  setDetails,
  toggleLoading,
  toggleSectionsLoading,
  setPagination,
  setSections,
  pushSections,
  setQuery,
  toggleSntOwner,
  SntRegistryState,
  toggleDeleteModalLoading,
  removeSectionFromList,
  updateSectionState,
} from "./";
import { Pagination, SntRegistry } from "@/types";

export const getSntDetails = createAsyncThunk<SntRegistry.SntDetails, undefined>(
  "snt-registry/get-snt-details",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    if (rootState.sntRegistry.details) {
      return rootState.sntRegistry.details;
    }

    const address = activeAddress(rootState);
    try {
      if (address?.Id) {
        const { data } = await SntRegistryService.getDetails(address?.Id);
        dispatch(setDetails(data.Data));

        return data.Data;
      }

      throw Error("no address id");
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    } finally {
      dispatch(toggleSectionsLoading(false));
    }
  }
);

export const fetchSntDetails = createAsyncThunk<SntRegistry.Section[], undefined>(
  "snt-registry/fetch-snt-details",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);
    dispatch(setQuery(""));
    dispatch(toggleSntOwner(null));
    dispatch(toggleLoading(true));
    dispatch(toggleSectionsLoading(true));
    try {
      if (address?.Id) {
        const { data } = await SntRegistryService.getDetails(address?.Id);
        dispatch(setDetails(data.Data));
        dispatch(toggleLoading(false));
        const sectionsResp = await dispatch(loadSections()).unwrap();
        return sectionsResp;
      }

      throw Error("no address id");
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    } finally {
      dispatch(toggleSectionsLoading(false));
    }
  }
);

export const updateSntDetailsOnBackground = createAsyncThunk<unknown, undefined>(
  "snt-registry/update-snt-details-on-background",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);
    try {
      if (address?.Id) {
        const { data } = await SntRegistryService.getDetails(address?.Id);
        dispatch(setDetails(data.Data));
        return data.Data;
      }
      return null;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

export const loadSections = createAsyncThunk<SntRegistry.Section[], undefined>(
  "snt-registry/load-sections",
  async (data, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    dispatch(toggleSectionsLoading(true));
    dispatch(setPagination(null));
    try {
      if (rootState.sntRegistry.details?.Id) {
        let params: any = { SntId: rootState.sntRegistry.details?.Id };
        if (rootState.sntRegistry.query) {
          params["SearchQuery"] = rootState.sntRegistry.query;
        }
        if (typeof rootState.sntRegistry.isSntOwner === "boolean") {
          params["IsSntOwner"] = rootState.sntRegistry.isSntOwner;
        }
        const { data } = await SntRegistryService.getSections(params);
        dispatch(setSections(data.Data));
        dispatch(setPagination(data.Page));
        return data.Data;
      }

      throw Error("no snt id");
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    } finally {
      dispatch(toggleSectionsLoading(false));
    }
  }
);

export const loadMoreSections = createAsyncThunk<Pagination, undefined>(
  "snt-registry/load-more-sections",
  async (data, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    try {
      if (rootState.sntRegistry.details?.Id) {
        let params: any = {
          SntId: rootState.sntRegistry.details?.Id,
          Page: (rootState.sntRegistry.pagination?.Index ?? 0) + 1,
        };
        if (rootState.sntRegistry.query) {
          params["SearchQuery"] = rootState.sntRegistry.query;
        }
        if (typeof rootState.sntRegistry.isSntOwner === "boolean") {
          params["isSntOwner"] = rootState.sntRegistry.isSntOwner;
        }

        const { data } = await SntRegistryService.getSections(params);
        dispatch(pushSections(data.Data));
        dispatch(setPagination(data.Page));
        return data.Page;
      }

      throw Error("no snt id");
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

export const setSearchQuery = createAsyncThunk(
  "snt-registry/set-search-query",
  async (query: string, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    if (rootState.sntRegistry.query === query) {
      return;
    }

    dispatch(setQuery(query));
    dispatch(loadSections());
  }
);

export const setSntOwnerFilter = createAsyncThunk(
  "snt-registry/set-search-query",
  async (isSntOwner: SntRegistryState["isSntOwner"], { rejectWithValue, getState, dispatch }) => {
    dispatch(toggleSntOwner(isSntOwner));
    dispatch(loadSections());
  }
);

export const checkIfHasSntRegistry = createAsyncThunk<boolean, undefined>(
  "snt-registry/check-if-has-registry",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const rootState = getState() as RootState;
      if (rootState.sntRegistry.sections.length > 0) return true;

      const address = activeAddress(rootState);
      if (address?.Id) {
        const detailsResp = await SntRegistryService.getDetails(address?.Id);
        let params: any = { SntId: detailsResp.data?.Data?.Id };
        const sectionsResp = await SntRegistryService.getSections(params);
        return sectionsResp.data?.Data && Array.isArray(sectionsResp.data?.Data) && sectionsResp.data?.Data?.length > 0;
      }
      return false;
    } catch (err: any) {
      return false;
    }
  }
);

export const deleteSection = createAsyncThunk<unknown, SntRegistry.SectionIds["Id"]>(
  "snt-registry/delete-section",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    dispatch(toggleDeleteModalLoading(true));
    try {
      if (rootState.sntRegistry.details?.Id) {
        const response = await SntRegistryService.deleteSection(rootState.sntRegistry.details?.Id, payload);
        await dispatch(removeSectionFromList(payload));
        dispatch(updateSntDetailsOnBackground());
        return response.data;
      }

      throw Error("no snt id");
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    } finally {
      dispatch(toggleDeleteModalLoading(false));
    }
  }
);

export const createSection = createAsyncThunk<unknown, SntRegistry.CreateSectionRequestBody>(
  "snt-registry/create-section",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await SntRegistryService.createSection(payload);
      await dispatch(loadSections());
      dispatch(updateSntDetailsOnBackground());
      return data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const updateSection = createAsyncThunk<unknown, SntRegistry.UpdateSectionRequestBody>(
  "snt-registry/update-section",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await SntRegistryService.updateSection(payload);
      dispatch(updateSntDetailsOnBackground());
      if (response.data) {
        await dispatch(updateSectionState(response.data.Data));
        return response.data;
      } else {
        await dispatch(loadSections());
        return;
      }
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);
