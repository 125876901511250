import Checkbox from "@/components/checkbox";
import styles from "./styles.module.scss";

interface IAddressCheckbox {
  onChange: (value: boolean) => void;
  label: string;
  all?: boolean;
  checked?: boolean;
  disabled?: boolean;
}

const AddressCheckbox: React.FC<IAddressCheckbox> = ({ onChange, label, all, checked, disabled }) => (
  <div className={styles["b-address-checkbox"]}>
    <Checkbox checked={checked} onChange={onChange} all={all} disabled={disabled} />
    <p className={`${all ? "sf-text-semibold" : "sf-text-regular"}`}>
      <span className="text--primary color--text-primary">{label}</span>
    </p>
  </div>
);

export default AddressCheckbox;
