import { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { useOutletContext } from "react-router";
import { SntPassport } from "@/types";
import { paths } from "@/consts/routes";
import { SntPassportService } from "@/services/v2";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import PageHeader from "@/components/PageHeader";
import Buble from "./Buble";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const InstitutionSntItemSummary: React.FC = () => {
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();
  const { sntData } = useOutletContext<{ sntData: SntPassport.InstitutionSntItem }>();

  const [summary, setSummary] = useState<SntPassport.InstitutionSntItemSummary>();

  async function fetchSnt() {
    if (!sntData?.Id) {
      return;
    }

    try {
      const { data } = await SntPassportService.getSntSummaryById(sntData.Id);
      setSummary(data.Data);
    } catch (error: any) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchSnt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sntData]);

  return (
    <div>
      <div className={cx("b-snt-summary-header")}>
        <PageHeader
          title={sntData?.Name ? `Сводка по ${sntData?.Name}` : ""}
          defaultLink={paths.institution(sntData?.Id?.toString()).fullPath().sntItem}
        />
        <div className={cx("b-snt-summary-header__content")}>
          <p className="headline-h2 sf-bold color--text-primary">
            Всего участков: <span className="color--primary">{summary?.SectionsCount ?? 0}</span>
          </p>
        </div>
      </div>

      <div
        ref={emptyBlockRef}
        className={cx("b-snt-summary-content")}
        style={{ minHeight: `${emptyBlockHeight + 24}px` }}
      >
        {summary && (
          <>
            <div className={cx("b-snt-summary-content__graphics")} style={{ height: `${emptyBlockHeight - 64}px` }}>
              <div className={cx("b-snt-summary-container")}>
                <Buble
                  className={cx(["b-snt-summary-item", "b-snt-summary-item--sections-owners"])}
                  width="400px"
                  height="400px"
                  backgroundColor="var(--main-primary)"
                >
                  <div className={cx(["b-snt-summary-item__content", "color--text-white"])}>
                    <p className="sf-text-semibold headline-h2">Количество садоводов</p>
                    <span className="sf-text-bold">{summary?.OwnersCount ?? 0}</span>
                  </div>
                </Buble>

                <Buble
                  className={cx(["b-snt-summary-item", "b-snt-summary-item--lokolo-users"])}
                  width="200px"
                  height="200px"
                  backgroundColor="#3c4350"
                >
                  <div className={cx(["b-snt-summary-item__content", "color--text-white"])}>
                    <p className="sf-text-semibold headline-h4">Количество пользователей в Локоло</p>
                    <span className="sf-text-bold">{summary?.LokoloUsersCount ?? 0}</span>
                  </div>
                </Buble>

                <Buble
                  className={cx(["b-snt-summary-item", "b-snt-summary-item--sections-garden-owners"])}
                  width="300px"
                  height="300px"
                  backgroundColor="#2d3038"
                >
                  <div className={cx(["b-snt-summary-item__content", "color--text-white"])}>
                    <p className="sf-text-semibold headline-h3">Количество членов садоводства</p>
                    <span className="sf-text-bold">{summary?.MembersCount ?? 0}</span>
                  </div>
                </Buble>
              </div>
            </div>

            <div className={cx("b-snt-summary-content__bottom-text")}>
              <p className="caption--middle color--text-subhead sf-text-regular">
                На основе предоставленных данных председателями садоводств в "Локоло"
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InstitutionSntItemSummary;
