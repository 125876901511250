import { useMemo } from "react";
import { OCC } from "@/types";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface QuestionDecisionRadioProps {
  value: OCC.Decision;
  selected: OCC.Decision | null;
  isСombined?: boolean;
  onChange: (value: QuestionDecisionRadioProps["value"]) => void;
  disabled?: boolean;
}

const QuestionDecisionRadio: React.FC<QuestionDecisionRadioProps> = ({
  value,
  selected,
  isСombined = false,
  onChange,
  disabled,
}) => {
  const isChecked: boolean = useMemo(() => {
    return value === selected;
  }, [value, selected]);

  const formatted: { name: string; color: string; hoverBg: string } = useMemo(() => {
    switch (value) {
      case OCC.Decision.Abstain:
        return { name: "«Воздержусь»", color: "#A0AEBE", hoverBg: "#E9EDF3" };
      case OCC.Decision.Agree:
        return { name: "«За»", color: "#25C168", hoverBg: "#CFF3E1" };
      case OCC.Decision.Disagree:
        return { name: "«Против»", color: "#ED0A34", hoverBg: "#F3CFCF" };
      default:
        return { name: "", color: "#A0AEBE", hoverBg: "#E9EDF3" };
    }
  }, [value]);

  const handleCheckboxChange = () => {
    onChange(value);
  };

  return (
    <label className={cx({ radio: true, disabled })}>
      <span className={styles.input}>
        <input type="radio" value={value} checked={isChecked} onChange={handleCheckboxChange} disabled={disabled} />
        <span className="flex middle center">
          {isChecked ? (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.2071 9.20711C18.5976 8.81658 18.5976 8.18342 18.2071 7.79289C17.8166 7.40237 17.1834 7.40237 16.7929 7.79289L10 14.5858L7.20711 11.7929C6.81658 11.4024 6.18342 11.4024 5.79289 11.7929C5.40237 12.1834 5.40237 12.8166 5.79289 13.2071L9.29289 16.7071C9.68342 17.0976 10.3166 17.0976 10.7071 16.7071L18.2071 9.20711Z"
                fill={formatted.color}
              />
            </svg>
          ) : (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect width="24" height="24" rx="12" fill={formatted.hoverBg} />
              <path
                d="M12 0C18.6289 0 24 5.37258 24 12C24 18.6289 18.6274 24 12 24C5.37113 24 0 18.6274 0 12C0 5.37113 5.37258 0 12 0ZM12 1.5C6.20044 1.5 1.5 6.20005 1.5 12C1.5 17.7996 6.20005 22.5 12 22.5C17.7996 22.5 22.5 17.8 22.5 12C22.5 6.20044 17.8 1.5 12 1.5Z"
                fill={formatted.color}
              />
            </svg>
          )}
        </span>
      </span>
      <span className="text--primary color--text-primary sf-text-medium">
        {isСombined && "Все "}
        {formatted.name}
      </span>
    </label>
  );
};

export default QuestionDecisionRadio;
