import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SntRedirect: React.FC = () => {
  const { pathname } = useLocation();

  if (pathname.startsWith("/snt/my-occ")) {
    return <Navigate to={pathname.replace("/snt", "")} replace={true} />;
  }

  return <Navigate to="/" replace={true} />;
};

export default SntRedirect;
