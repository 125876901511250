import { useMemo } from "react";
import BaseTooltip from "@/components/base-tooltip";
import BaseButton from "@/components/base-button";
import { editIcon } from "@/consts/icons";
import { RegistryBase, UKRegistry } from "@/types";
import styles from "./styles.module.scss";

interface IOwnerItemProps {
  owner: UKRegistry.ApartmentOwner | UKRegistry.CreatedApartmentOwner;
  readonly: boolean;
  ownerChange: RegistryBase.OwnerChangeInclude[];
  onOpen: () => void;
  onEdit: () => void;
  onRemove: () => void;
}

const OwnerItem: React.FC<IOwnerItemProps> = ({ owner, readonly, ownerChange, onEdit, onOpen, onRemove }) => {
  const isEditDisabled: boolean = useMemo(() => {
    return ownerChange.find((it) => it.OwnerId === owner.Id && !it.Attributes.Updated) != null;
  }, [owner, ownerChange]);

  const isDeleteDisabled: boolean = useMemo(() => {
    return ownerChange.find((it) => it.OwnerId === owner.Id && !it.Attributes.Deleted) != null;
  }, [owner, ownerChange]);

  return (
    <div className={styles.owner_item} aria-readonly={readonly}>
      <div className={styles.owner_name}>
        <p className="sf-text-semibold text--primary color--text-primary">{owner.FullName}</p>
        <p className="sf-text-regular text--primary color--text-secondary">Собственник</p>
      </div>

      <div className={styles.owner_actions}>
        {readonly ? (
          <BaseTooltip title="Данные собственника">
            <BaseButton className={styles.owner_btn} variant="icon-basic" onClick={onOpen}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.8101 7.904C15.9229 8.16554 15.7724 8.45937 15.4997 8.54153L14.4566 8.85575C14.2004 8.93293 13.931 8.7936 13.8213 8.54958C13.3768 7.56078 12.6465 6.70116 11.7066 6.0681C10.6286 5.34196 9.33028 4.95121 7.99798 4.95191C6.66567 4.9526 5.36784 5.34471 4.29069 6.07197C3.35162 6.70599 2.62236 7.56634 2.17906 8.55557C2.06963 8.79975 1.80033 8.93939 1.54405 8.86248L0.500555 8.54933C0.227783 8.46747 0.0769418 8.17385 0.189379 7.91219C0.775445 6.54836 1.76636 5.36204 3.05221 4.49388C4.48885 3.5239 6.21983 3.00093 7.99679 3C9.77375 2.99907 11.5054 3.52024 12.9432 4.48871C14.2301 5.35552 15.2224 6.54079 15.8101 7.904ZM8.00005 13C9.6569 13 11 11.6569 11 10C11 8.34315 9.6569 7 8.00005 7C6.34319 7 5.00005 8.34315 5.00005 10C5.00005 11.6569 6.34319 13 8.00005 13Z"
                  fill="#3A3B3D"
                />
              </svg>
            </BaseButton>
          </BaseTooltip>
        ) : (
          <>
            <BaseTooltip title={!isDeleteDisabled ? "Удалить" : ""}>
              <BaseButton
                className={styles.owner_btn}
                disabled={isDeleteDisabled}
                variant="icon-basic"
                onClick={onRemove}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 2.3C9 2.01997 9 1.87996 8.9455 1.773C8.89757 1.67892 8.82108 1.60243 8.727 1.5545C8.62004 1.5 8.48003 1.5 8.2 1.5H7.8C7.51997 1.5 7.37996 1.5 7.273 1.5545C7.17892 1.60243 7.10243 1.67892 7.0545 1.773C7 1.87996 7 2.01997 7 2.3V3H9V2.3ZM5.5 2.4V3.09091L1.71304 3.43518C1.30921 3.47189 1 3.81048 1 4.21598C1 4.64898 1.35102 5 1.78402 5H5.5H10.5H14.216C14.649 5 15 4.64898 15 4.21598C15 3.81048 14.6908 3.47189 14.287 3.43518L10.5 3.09091V2.4C10.5 1.55992 10.5 1.13988 10.3365 0.819014C10.1927 0.536771 9.96323 0.3073 9.68099 0.16349C9.36012 0 8.94008 0 8.1 0H7.9C7.05992 0 6.63988 0 6.31901 0.16349C6.03677 0.3073 5.8073 0.536771 5.66349 0.819014C5.5 1.13988 5.5 1.55992 5.5 2.4ZM3.0969 6.30816C3.04746 6.42715 3.06454 6.58088 3.0987 6.88834L3.76278 12.865C3.84667 13.62 3.88861 13.9975 4.06276 14.2824C4.21617 14.5333 4.43996 14.7336 4.70631 14.8584C5.00868 15 5.38849 15 6.1481 15H9.8519C10.6115 15 10.9913 15 11.2937 14.8584C11.56 14.7336 11.7838 14.5333 11.9372 14.2824C12.1114 13.9975 12.1533 13.62 12.2372 12.865L12.9013 6.88835C12.9355 6.58088 12.9525 6.42715 12.9031 6.30816C12.8597 6.20366 12.7821 6.11697 12.683 6.06228C12.5702 6 12.4155 6 12.1062 6H3.89381C3.58446 6 3.42978 6 3.31698 6.06228C3.21791 6.11697 3.14032 6.20366 3.0969 6.30816Z"
                    fill="#3A3B3D"
                  />
                </svg>
              </BaseButton>
            </BaseTooltip>

            <BaseTooltip title={!isEditDisabled ? "Редактировать" : ""}>
              <BaseButton className={styles.owner_btn} disabled={isEditDisabled} variant="icon-basic" onClick={onEdit}>
                {editIcon("#3A3B3D")}
              </BaseButton>
            </BaseTooltip>
          </>
        )}
      </div>
    </div>
  );
};

export default OwnerItem;
