import { createAsyncThunk } from "@reduxjs/toolkit";
import { TemplatesService } from "@/services/v2";
import {
  setGroups,
  setDefaultGroups,
  setQuestionsByGroupId,
  setDefaultQuestionsByGroupId,
  removeQuestion,
  pushQuestion,
  updateQuestionById,
  removeGroup,
  pushGroup,
  updateGroupById,
} from "./index";
import { Templates } from "@/types";

export const fetchTemplateGroups = createAsyncThunk(
  "templates/fetch-groups",
  async (payload: undefined, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await TemplatesService.getTemplateGroups();
      await dispatch(setGroups(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    }
  }
);

export const fetchDefaultTemplateGroups = createAsyncThunk(
  "templates/fetch-default-template-groups",
  async (payload: undefined, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await TemplatesService.getDefaultTemplateGroups();
      dispatch(setDefaultGroups(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    }
  }
);

export const fetchUKTemplateGroups = createAsyncThunk(
  "templates/fetch-uk-groups",
  async (payload: undefined, { dispatch, rejectWithValue }) => {
    return await Promise.all([
      dispatch(fetchTemplateGroups()).unwrap(),
      dispatch(fetchDefaultTemplateGroups()).unwrap(),
    ]);
  }
);

export const fetchQuestionsByGroupId = createAsyncThunk<Templates.Question[], Templates.Group["Id"]>(
  "templates/fetch-questions-by-group-id",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await TemplatesService.getTemplateQuestionsByGroupId(payload);
      dispatch(setQuestionsByGroupId({ groupId: payload, questions: data.Data }));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    }
  }
);

export const fetchDefaultQuestionsByGroupId = createAsyncThunk<Templates.Question[], Templates.Group["Id"]>(
  "templates/fetch-default-questions-by-group-id",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await TemplatesService.getDefaultTemplateQuestionsByGroupId(payload);
      dispatch(setDefaultQuestionsByGroupId({ groupId: payload, questions: data.Data }));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    }
  }
);

export const deleteQuestionById = createAsyncThunk<
  unknown,
  { questionId: Templates.Question["Id"]; groupId: Templates.Group["Id"] }
>("templates/delete-question-by-id", async (payload, { dispatch, rejectWithValue }) => {
  try {
    const { data } = await TemplatesService.deleteTemplateQuestion(payload.questionId);
    await dispatch(removeQuestion(payload));
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.Data?.Message);
  }
});

export const deleteGroupById = createAsyncThunk<unknown, Templates.Group["Id"]>(
  "templates/delete-group-by-id",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await TemplatesService.deleteTemplateGroup(payload);
      await dispatch(removeGroup(payload));
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    }
  }
);

export const createGroup = createAsyncThunk<Templates.Group, string>(
  "templates/create-group",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await TemplatesService.createTemplateGroup(payload);
      await dispatch(pushGroup(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateGroup = createAsyncThunk<Templates.Group, Templates.Group>(
  "templates/update-group",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await TemplatesService.updateTemplateGroup(payload.Id, payload.Title, payload.Order);
      await dispatch(updateGroupById(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createQuestion = createAsyncThunk<Templates.Question, Templates.CreateQuestionBody>(
  "templates/create-question",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await TemplatesService.createTemplateQuestion(payload);
      await dispatch(pushQuestion({ groupId: payload.GroupId, question: data.Data }));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateQuestion = createAsyncThunk<Templates.Question, Templates.Question>(
  "templates/update-question",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await TemplatesService.updateTemplateQuestion(payload.Id, {
        Title: payload.Title,
        Value: payload.Value,
        QuorumId: payload.QuorumId,
        Order: payload.Order,
      });
      await dispatch(updateQuestionById({ groupId: payload.GroupId, question: data.Data }));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
