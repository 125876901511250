import styles from "./styles.module.scss";

interface IBaseLabelProps {
  value: boolean;
  onChange?: (value: boolean) => void;
  children?: JSX.Element | JSX.Element[] | string | null;
}

const BaseSwitcher: React.FC<IBaseLabelProps> = ({ value, onChange, children }) => {
  function handleClick() {
    if (onChange) {
      onChange(!value);
    }
  }

  function handleChange(event: any) {
    // if (onChange) {
    //   onChange(event?.target?.checked ?? false);
    // }
  }

  return (
    <div>
      <input className={styles.input} type="checkbox" checked={value} onChange={handleChange} />

      <label className={styles.base} onClick={handleClick}>
        {children}
        <div className={styles.track}>
          <div className={styles.thumb}></div>
        </div>
      </label>
    </div>
  );
};

export default BaseSwitcher;
