import { useState } from "react";
import { closeIcon, infoIcon24 } from "@/consts/icons";
import styles from "./styles.module.scss";

interface IBaseHeadlineAlertProps {
  title: string;
  name: string;
  children?: JSX.Element;
  icon?: JSX.Element;
  verticalMargin?: string;
}

const BaseHeadlineAlert: React.FC<IBaseHeadlineAlertProps> = ({
  title,
  name,
  children,
  icon,
  verticalMargin = "12px 0",
}) => {
  const storageName = "hidden--alerts";
  const [isHidden, setHidden] = useState<boolean>(false);

  const hideAlert = () => {
    const stored = localStorage.getItem(storageName);
    const list = stored ? (JSON.parse(stored) as Array<string>) : [];
    list.push(name);
    localStorage.setItem(storageName, JSON.stringify(list));

    setHidden(true);
  };

  const storedAlerts = localStorage.getItem(storageName);
  if (isHidden || (storedAlerts && JSON.parse(storedAlerts).includes(name))) {
    return null;
  }

  return (
    <div style={{ padding: verticalMargin }}>
      <section className={styles.headline_alert}>
        <div className={styles.content}>
          <span className={styles.icon}>{icon ? icon : infoIcon24()}</span>

          <div className={styles.text}>
            <h4 className="headline-h4 sf-text-bold color--text-primary">{title}</h4>
            {children && <div className="sf-text-regular text--primary color--text-primary">{children}</div>}
          </div>
        </div>

        <span className={styles.close} role="button" onClick={hideAlert}>
          {closeIcon("#898D93")}
        </span>
      </section>
    </div>
  );
};

export default BaseHeadlineAlert;
