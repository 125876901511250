import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams, useParams } from "react-router-dom";
import { addIcon24 } from "@/consts/icons";
import { SntPassportService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { fetchSntPassportDictionaries } from "@/app/store/slices/snt/passport";
import { SnackbarType, showSnackbar } from "@/app/store/slices/snackbar";
import { SntPassportAgricultureMinistry, SntPassportAgricultureMinistryForms, SntPassportEnums } from "@/types";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import SntPassportContent from "@/components/SntPassport/Content";
import SntPassportBlockWrapper from "@/components/SntPassport/BlockWrapper";
import SntPassportBlockContentForm from "@/components/SntPassport/BlockContent/Form";
import SntPassportYearsFilter from "@/components/SntPassport/YearsFilter";
import SntPassportBottomBar from "@/components/SntPassport/BottomBar";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import BaseInput from "@/components/base-input";
import NumberInput from "@/components/base-input/NumberInput";
import BaseButton from "@/components/base-button";
import { Spinner } from "@/components/spinner";

const InstitutionSntItemAgricultureMinistry: React.FC = () => {
  const urlParams = useParams();
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  const { roadSurfaceTypesList, externalInfrastructureWorkTypesList, internalInfrastructureWorkTypesList } =
    useSelector((state: RootState) => state.sntPassport);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaveLoading, toggleSaveLoading] = useState<boolean>(false);
  const [isPublishLoading, togglePublishLoading] = useState<boolean>(false);
  const [agricultureMinistryData, setAgricultureMinistryData] =
    useState<SntPassportAgricultureMinistry.AgricultureMinistryDataResponse>();
  const [externalForm, setExternalForm] = useState<SntPassportAgricultureMinistryForms.ExternalInfrastructureForm>(
    new SntPassportAgricultureMinistryForms.ExternalInfrastructureForm()
  );
  const [internalForm, setInternalForm] = useState<SntPassportAgricultureMinistryForms.InternalInfrastructureForm>(
    new SntPassportAgricultureMinistryForms.InternalInfrastructureForm()
  );

  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    let years = [];
    for (let i = currentYear; i >= 2020; i--) {
      years.push(i);
    }
    return years;
  }, []);

  const filter = useMemo(() => {
    const params = searchParams.get("year");
    return params ? parseInt(params) : years[0];
  }, [years, searchParams]);

  function handleFilterChange(year: number) {
    setSearchParams({ year: year.toString() });
  }

  function onValidationError() {
    dispatch(
      showSnackbar({
        key: "snt-passport-error",
        title: "Ошибка",
        body: "Некорректный формат полей или не заполнены обязательные поля",
        duration: 10000,
        type: SnackbarType.Block,
        closeBtn: true,
      })
    );

    setTimeout(() => {
      const element = document.querySelector("[data-error*=' ']");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 0);
  }

  function updateExternalFormFieldValue(
    index: number,
    key: keyof SntPassportAgricultureMinistryForms.WorkTypeBaseForm,
    value: unknown
  ) {
    const updated = [...externalForm.groups];
    const field = updated[index][key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      updated[index][key].value = value as any;
      setExternalForm((prevState) => ({
        ...prevState,
        groups: [...updated],
      }));
    }
  }

  function updateInternalFormFieldValue(
    index: number,
    key: keyof SntPassportAgricultureMinistryForms.WorkTypeBaseForm,
    value: unknown
  ) {
    const updated = [...internalForm.groups];
    const field = updated[index][key];
    if ((typeof field === "object" || typeof field === "function") && field !== null && "value" in field) {
      updated[index][key].value = value as any;
      setInternalForm((prevState) => ({
        ...prevState,
        groups: [...updated],
      }));
    }
  }

  function handleError(payload: any) {
    if (payload?.Errors) {
      Object.keys(payload.Errors).forEach((key: any) => {
        const message = Array.isArray(payload.Errors[key]) ? payload.Errors[key][0] : payload.Errors[key];
        const [base, form, workType, field] = key.split(".");
        switch (form) {
          case "ExternalInfrastructure": {
            setExternalForm((prevState) => {
              const index = prevState.groups.findIndex((it) => it.WorkType.value?.Key === workType);
              if (
                index > -1 &&
                field in prevState.groups[index] &&
                (typeof prevState.groups[index] === "object" || typeof prevState.groups[index] === "function") &&
                prevState.groups[index] !== null &&
                "error" in (prevState.groups[index] as any)[field]
              ) {
                const updated = prevState.groups;
                updated[index] = {
                  ...updated[index],
                  [field]: {
                    ...(updated[index] as any)[field],
                    error: message,
                  },
                };
                return {
                  ...prevState,
                  groups: updated,
                };
              } else {
                return {
                  ...prevState,
                };
              }
            });
            break;
          }

          case "InternalInfrastructure": {
            setInternalForm((prevState) => {
              const index = prevState.groups.findIndex((it) => it.WorkType.value?.Key === workType);
              if (
                index > -1 &&
                field in prevState.groups[index] &&
                (typeof prevState.groups[index] === "object" || typeof prevState.groups[index] === "function") &&
                prevState.groups[index] !== null &&
                "error" in (prevState.groups[index] as any)[field]
              ) {
                const updated = prevState.groups;
                updated[index] = {
                  ...updated[index],
                  [field]: {
                    ...(updated[index] as any)[field],
                    error: message,
                  },
                };
                return {
                  ...prevState,
                  groups: updated,
                };
              } else {
                return {
                  ...prevState,
                };
              }
            });
            break;
          }

          default: {
            dispatch(showSnackbar({ key: "snt-passport-error", body: message, type: SnackbarType.ERROR }));
            break;
          }
        }
      });

      onValidationError();
    } else {
      dispatch(
        showSnackbar({
          key: "snt-passport-error",
          body: payload?.Message ?? "Неизвестная ошибка",
          type: SnackbarType.ERROR,
        })
      );
    }
  }

  function isExternalFormValid(needCheckRequired: boolean) {
    const validator = SntPassportAgricultureMinistryForms.ExternalInfrastructureFormValidator.isInvalid(
      externalForm,
      needCheckRequired
    );

    const updated = [...externalForm.groups];
    updated.forEach((item, index) => {
      const formError = validator && validator[index] ? validator[index] : null;
      updated[index] = {
        ...updated[index],
        WorkType: {
          ...updated[index].WorkType,
          error: formError?.WorkType ?? "",
        },
        BasisDocumentName: {
          ...updated[index].BasisDocumentName,
          error: formError?.BasisDocumentName ?? "",
        },
        Note: {
          ...updated[index].Note,
          error: formError?.Note ?? "",
        },
        WorkCost: {
          ...updated[index].WorkCost,
          error: formError?.WorkCost ?? "",
        },
        WorkTypeKeyOther: {
          ...updated[index].WorkTypeKeyOther,
          error: formError?.WorkTypeKeyOther ?? "",
        },
        SCDF: {
          ...updated[index].SCDF,
          error: formError?.SCDF ?? "",
        },
        RoadLength: {
          ...updated[index].RoadLength,
          error: formError?.RoadLength ?? "",
        },
        SurfaceType: {
          ...updated[index].SurfaceType,
          error: formError?.SurfaceType ?? "",
        },
        SurfaceTypeKeyOther: {
          ...updated[index].SurfaceTypeKeyOther,
          error: formError?.SurfaceTypeKeyOther ?? "",
        },
        WorkScope: {
          ...updated[index].WorkScope,
          error: formError?.WorkScope ?? "",
        },
        LEPWorkScope: {
          ...updated[index].LEPWorkScope,
          error: formError?.LEPWorkScope ?? "",
        },
        KTPWorkScope: {
          ...updated[index].KTPWorkScope,
          error: formError?.KTPWorkScope ?? "",
        },
        WellWorkScope: {
          ...updated[index].WellWorkScope,
          error: formError?.WellWorkScope ?? "",
        },
        ReservoirWorkScope: {
          ...updated[index].ReservoirWorkScope,
          error: formError?.ReservoirWorkScope ?? "",
        },
        PumpWorkScope: {
          ...updated[index].PumpWorkScope,
          error: formError?.PumpWorkScope ?? "",
        },
        WaterPipesWorkScope: {
          ...updated[index].WaterPipesWorkScope,
          error: formError?.WaterPipesWorkScope ?? "",
        },
        TKOAreaWorkScope: {
          ...updated[index].TKOAreaWorkScope,
          error: formError?.TKOAreaWorkScope ?? "",
        },
        BunkerWorkScope: {
          ...updated[index].BunkerWorkScope,
          error: formError?.BunkerWorkScope ?? "",
        },
        ContainerWorkScope: {
          ...updated[index].ContainerWorkScope,
          error: formError?.ContainerWorkScope ?? "",
        },
      };
    });

    setExternalForm((prevState) => ({
      ...prevState,
      groups: [...updated],
    }));

    return !validator;
  }

  function isInternalFormValid(needCheckRequired: boolean) {
    const validator = SntPassportAgricultureMinistryForms.InternalInfrastructureFormValidator.isInvalid(
      internalForm,
      needCheckRequired
    );

    const updated = [...internalForm.groups];
    updated.forEach((item, index) => {
      const formError = validator && validator[index] ? validator[index] : null;
      updated[index] = {
        ...updated[index],
        WorkType: {
          ...updated[index].WorkType,
          error: formError?.WorkType ?? "",
        },
        BasisDocumentName: {
          ...updated[index].BasisDocumentName,
          error: formError?.BasisDocumentName ?? "",
        },
        Note: {
          ...updated[index].Note,
          error: formError?.Note ?? "",
        },
        WorkCost: {
          ...updated[index].WorkCost,
          error: formError?.WorkCost ?? "",
        },
        WorkTypeKeyOther: {
          ...updated[index].WorkTypeKeyOther,
          error: formError?.WorkTypeKeyOther ?? "",
        },
        WorkScope: {
          ...updated[index].WorkScope,
          error: formError?.WorkScope ?? "",
        },
        SurfaceType: {
          ...updated[index].SurfaceType,
          error: formError?.SurfaceType ?? "",
        },
        SurfaceTypeKeyOther: {
          ...updated[index].SurfaceTypeKeyOther,
          error: formError?.SurfaceTypeKeyOther ?? "",
        },
        LEPWorkScope: {
          ...updated[index].LEPWorkScope,
          error: formError?.LEPWorkScope ?? "",
        },
        KTPWorkScope: {
          ...updated[index].KTPWorkScope,
          error: formError?.KTPWorkScope ?? "",
        },
        ASKUEWorkScope: {
          ...updated[index].ASKUEWorkScope,
          error: formError?.ASKUEWorkScope ?? "",
        },
      };
    });

    setInternalForm((prevState) => ({
      ...prevState,
      groups: [...updated],
    }));

    return !validator;
  }

  function addExternalWork() {
    setExternalForm((prevState) => ({
      ...prevState,
      groups: [...prevState.groups, new SntPassportAgricultureMinistryForms.WorkTypeBaseForm()],
    }));
  }

  function removeExternalWork(index: number) {
    setExternalForm((prevState) => ({
      ...prevState,
      groups: [...prevState.groups.slice(0, index), ...prevState.groups.slice(index + 1)],
    }));
  }

  function addInternalWork() {
    setInternalForm((prevState) => ({
      ...prevState,
      groups: [...prevState.groups, new SntPassportAgricultureMinistryForms.WorkTypeBaseForm()],
    }));
  }

  function removeInternalWork(index: number) {
    setInternalForm((prevState) => ({
      ...prevState,
      groups: [...prevState.groups.slice(0, index), ...prevState.groups.slice(index + 1)],
    }));
  }

  async function saveData() {
    const externalValidation = isExternalFormValid(false);
    const internalValidation = isInternalFormValid(false);

    if (!externalValidation || !internalValidation) {
      onValidationError();
      return;
    }

    if (!urlParams.sntId) {
      return;
    }

    toggleSaveLoading(true);
    try {
      const body = SntPassportAgricultureMinistryForms.saveRequestBody(externalForm, internalForm);
      await SntPassportService.saveAgricultureMinistryData(parseInt(urlParams.sntId), filter, body);
      const { data } = await SntPassportService.getAgricultureMinistryData(parseInt(urlParams.sntId), filter);
      setAgricultureMinistryData((prevState: any) => ({
        ...prevState,
        PublicationDatetime: data.Data.PublicationDatetime,
      }));
      dispatch(
        showSnackbar({
          key: "snt-passport-save",
          title: "Успешно сохранено",
          duration: 10000,
          type: SnackbarType.SUCCESS,
          closeBtn: true,
        })
      );
    } catch (error: any) {
      handleError(error?.response?.data?.Data);
    } finally {
      toggleSaveLoading(false);
    }
  }

  async function publishData() {
    const externalValidation = isExternalFormValid(true);
    const internalValidation = isInternalFormValid(true);

    if (!externalValidation || !internalValidation) {
      onValidationError();
      return;
    }

    if (!urlParams.sntId) {
      return;
    }

    togglePublishLoading(true);
    try {
      const body = SntPassportAgricultureMinistryForms.saveRequestBody(externalForm, internalForm);
      await SntPassportService.saveAgricultureMinistryData(parseInt(urlParams.sntId), filter, body);
      await SntPassportService.publishAgricultureMinistryData(parseInt(urlParams.sntId), filter);
      const { data } = await SntPassportService.getAgricultureMinistryData(parseInt(urlParams.sntId), filter);
      setAgricultureMinistryData((prevState: any) => ({
        ...prevState,
        PublicationDatetime: data.Data.PublicationDatetime,
      }));
      dispatch(
        showSnackbar({
          key: "snt-passport-publish",
          title: "Успешно опубликовано",
          duration: 10000,
          type: SnackbarType.SUCCESS,
          closeBtn: true,
        })
      );
    } catch (error: any) {
      handleError(error?.response?.data?.Data);
    } finally {
      togglePublishLoading(false);
    }
  }

  async function fetchBoardData() {
    if (!urlParams.sntId) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    setAgricultureMinistryData(undefined);

    try {
      await dispatch(fetchSntPassportDictionaries()).unwrap();
      const { data } = await SntPassportService.getAgricultureMinistryData(parseInt(urlParams.sntId), filter);
      setAgricultureMinistryData(data.Data);
      return data.Data.Form;
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchBoardData();
  }, [filter]);

  useEffect(() => {
    setExternalForm(
      new SntPassportAgricultureMinistryForms.ExternalInfrastructureForm(
        agricultureMinistryData?.Form.ExternalInfrastructure,
        externalInfrastructureWorkTypesList,
        roadSurfaceTypesList
      )
    );
  }, [agricultureMinistryData?.Form.ExternalInfrastructure, externalInfrastructureWorkTypesList, roadSurfaceTypesList]);

  useEffect(() => {
    setInternalForm(
      new SntPassportAgricultureMinistryForms.InternalInfrastructureForm(
        agricultureMinistryData?.Form.InternalInfrastructure,
        internalInfrastructureWorkTypesList,
        roadSurfaceTypesList
      )
    );
  }, [agricultureMinistryData?.Form.InternalInfrastructure, internalInfrastructureWorkTypesList, roadSurfaceTypesList]);

  return (
    <div>
      <SntPassportYearsFilter value={filter} options={years} onChange={handleFilterChange} />

      {isLoading ? (
        <div
          ref={emptyBlockRef}
          className="center"
          style={{ display: "flex", alignItems: "center", height: `${emptyBlockHeight}px`, minHeight: "350px" }}
        >
          <Spinner size={36} />
        </div>
      ) : (
        <>
          <div style={{ padding: "12px 0 88px" }}>
            <SntPassportContent>
              <SntPassportBlockWrapper
                title="Внешняя инфраструктура"
                headerAction={
                  <div>
                    <BaseButton
                      variant="secondary"
                      startIcon={addIcon24("var(--text-primary")}
                      appearance="neutral"
                      disabled={externalForm.groups.length >= externalInfrastructureWorkTypesList.length}
                      onClick={addExternalWork}
                    >
                      Добавить вид работ
                    </BaseButton>
                  </div>
                }
              >
                {externalForm.groups && externalForm.groups.length > 0
                  ? externalForm.groups.map((form, index) => (
                      <div key={index}>
                        <SntPassportBlockContentForm>
                          <BaseDropdown
                            value={form.WorkType?.value?.Key ?? null}
                            display={form.WorkType?.value?.Value}
                            isSelectable={true}
                            label="Вид работ"
                            placeholder="Выберите"
                            variant="formfield"
                            required={form.WorkType.isRequired}
                            errorMessage={form.WorkType.error}
                            onSelect={(value) => {
                              const workType = externalInfrastructureWorkTypesList.find((it) => it.Key === value);
                              if (workType) updateExternalFormFieldValue(index, "WorkType", workType);
                            }}
                          >
                            {externalInfrastructureWorkTypesList.map((workType) => (
                              <BaseDropdownMenuItem key={workType.Key} value={workType.Key}>
                                <span className="sf-text-medium">{workType.Value}</span>
                              </BaseDropdownMenuItem>
                            ))}
                          </BaseDropdown>

                          {form.WorkType?.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.Other && (
                            <BaseInput
                              value={form.WorkTypeKeyOther.value}
                              label="Вид работ (Другое)"
                              required={true}
                              placeholder="Укажите"
                              errorMessage={form.WorkTypeKeyOther.error}
                              maxlength={form.WorkTypeKeyOther.maxlength}
                              onChange={(value) => updateExternalFormFieldValue(index, "WorkTypeKeyOther", value)}
                            />
                          )}

                          <BaseInput
                            value={form.BasisDocumentName.value}
                            label="Наименования документа основания"
                            required={form.BasisDocumentName.isRequired}
                            placeholder="Введите наименование"
                            errorMessage={form.BasisDocumentName.error}
                            maxlength={form.BasisDocumentName.maxlength}
                            onChange={(value) => updateExternalFormFieldValue(index, "BasisDocumentName", value)}
                          />

                          {form.WorkType?.value?.Key ===
                          SntPassportEnums.ExternalInfrastructureWorkType.ElectricitySupplyRepair ? (
                            <>
                              <NumberInput
                                value={form.LEPWorkScope.value}
                                label="ЛЭП, объем работ (км.)"
                                required={form.LEPWorkScope.isRequired}
                                placeholder="Укажите"
                                errorMessage={form.LEPWorkScope.error}
                                max={99999999}
                                digitsAfterPoint={3}
                                radix=","
                                onChange={(value) => updateExternalFormFieldValue(index, "LEPWorkScope", value)}
                              />
                              <NumberInput
                                value={form.KTPWorkScope.value}
                                label="КТП, объем работ (шт.)"
                                required={form.KTPWorkScope.isRequired}
                                placeholder="Укажите"
                                errorMessage={form.KTPWorkScope.error}
                                max={99999999}
                                digitsAfterPoint={3}
                                radix=","
                                onChange={(value) => updateExternalFormFieldValue(index, "KTPWorkScope", value)}
                              />
                            </>
                          ) : form.WorkType?.value?.Key ===
                            SntPassportEnums.ExternalInfrastructureWorkType.WaterSupplyRepair ? (
                            <>
                              <NumberInput
                                value={form.WellWorkScope.value}
                                label="Скважина, объем работ (шт.)"
                                required={form.WellWorkScope.isRequired}
                                placeholder="Укажите"
                                errorMessage={form.WellWorkScope.error}
                                max={99999999}
                                digitsAfterPoint={3}
                                radix=","
                                onChange={(value) => updateExternalFormFieldValue(index, "WellWorkScope", value)}
                              />
                              <NumberInput
                                value={form.ReservoirWorkScope.value}
                                label="ВНБ и резервуар, объем работ (шт.)"
                                required={form.ReservoirWorkScope.isRequired}
                                placeholder="Укажите"
                                errorMessage={form.ReservoirWorkScope.error}
                                max={99999999}
                                digitsAfterPoint={3}
                                radix=","
                                onChange={(value) => updateExternalFormFieldValue(index, "ReservoirWorkScope", value)}
                              />
                              <NumberInput
                                value={form.PumpWorkScope.value}
                                label="Насос, объем работ (шт.)"
                                required={form.PumpWorkScope.isRequired}
                                placeholder="Укажите"
                                errorMessage={form.PumpWorkScope.error}
                                max={99999999}
                                digitsAfterPoint={3}
                                radix=","
                                onChange={(value) => updateExternalFormFieldValue(index, "PumpWorkScope", value)}
                              />
                              <NumberInput
                                value={form.WaterPipesWorkScope.value}
                                label="Водопровод, объем работ (км.)"
                                required={form.WaterPipesWorkScope.isRequired}
                                placeholder="Укажите"
                                errorMessage={form.WaterPipesWorkScope.error}
                                max={99999999}
                                digitsAfterPoint={3}
                                radix=","
                                onChange={(value) => updateExternalFormFieldValue(index, "WaterPipesWorkScope", value)}
                              />
                            </>
                          ) : form.WorkType?.value?.Key ===
                            SntPassportEnums.ExternalInfrastructureWorkType.TKORepair ? (
                            <>
                              <NumberInput
                                value={form.TKOAreaWorkScope.value}
                                label="Площадка ТКО, объем работ (шт.)"
                                required={form.TKOAreaWorkScope.isRequired}
                                placeholder="Укажите"
                                errorMessage={form.TKOAreaWorkScope.error}
                                max={99999999}
                                digitsAfterPoint={3}
                                radix=","
                                onChange={(value) => updateExternalFormFieldValue(index, "TKOAreaWorkScope", value)}
                              />
                              <NumberInput
                                value={form.BunkerWorkScope.value}
                                label="Бункер, объем работ (шт.)"
                                required={form.BunkerWorkScope.isRequired}
                                placeholder="Укажите"
                                errorMessage={form.BunkerWorkScope.error}
                                max={99999999}
                                digitsAfterPoint={3}
                                radix=","
                                onChange={(value) => updateExternalFormFieldValue(index, "BunkerWorkScope", value)}
                              />
                              <NumberInput
                                value={form.ContainerWorkScope.value}
                                label="Контейнер, объем работ (шт.)"
                                required={form.ContainerWorkScope.isRequired}
                                placeholder="Укажите"
                                errorMessage={form.ContainerWorkScope.error}
                                max={99999999}
                                digitsAfterPoint={3}
                                radix=","
                                onChange={(value) => updateExternalFormFieldValue(index, "ContainerWorkScope", value)}
                              />
                            </>
                          ) : (
                            <NumberInput
                              value={form.WorkScope.value}
                              label="Объем работ (км.,шт.)"
                              required={form.WorkScope.isRequired}
                              placeholder="Укажите"
                              errorMessage={form.WorkScope.error}
                              max={99999999}
                              digitsAfterPoint={3}
                              radix=","
                              onChange={(value) => updateExternalFormFieldValue(index, "WorkScope", value)}
                            />
                          )}

                          <div
                            className={
                              form.WorkType?.value?.Key &&
                              [
                                SntPassportEnums.ExternalInfrastructureWorkType.ElectricitySupplyRepair.toString(),
                                SntPassportEnums.ExternalInfrastructureWorkType.WaterSupplyRepair.toString(),
                                SntPassportEnums.ExternalInfrastructureWorkType.Other.toString(),
                              ].includes(form.WorkType.value.Key)
                                ? "full-field"
                                : ""
                            }
                          >
                            <NumberInput
                              value={form.WorkCost.value}
                              label="Стоимость работ (₽)"
                              required={form.WorkCost.isRequired}
                              placeholder="Укажите"
                              errorMessage={form.WorkCost.error}
                              max={999999999999}
                              digitsAfterPoint={2}
                              radix=","
                              thousandsSeparator=" "
                              onChange={(value) => updateExternalFormFieldValue(index, "WorkCost", value)}
                            />
                          </div>

                          {form.WorkType?.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.RoadRepair && (
                            <>
                              <NumberInput
                                value={form.SCDF.value}
                                label="СКДФ"
                                required={true}
                                placeholder="Укажите"
                                errorMessage={form.SCDF.error}
                                max={99999999}
                                digitsAfterPoint={0}
                                onChange={(value) => updateExternalFormFieldValue(index, "SCDF", value)}
                              />

                              <NumberInput
                                value={form.RoadLength.value}
                                label="Протяженность (м, км)"
                                required={true}
                                placeholder="Укажите"
                                errorMessage={form.RoadLength.error}
                                max={99999999}
                                digitsAfterPoint={0}
                                onChange={(value) => updateExternalFormFieldValue(index, "RoadLength", value)}
                              />

                              <BaseDropdown
                                className={
                                  form.SurfaceType?.value?.Key !== SntPassportEnums.RoadSurfaceType.Other
                                    ? "full-field"
                                    : ""
                                }
                                value={form.SurfaceType?.value?.Key ?? null}
                                display={form.SurfaceType?.value?.Value}
                                isSelectable={true}
                                label="Покрытие"
                                placeholder="Выберите"
                                variant="formfield"
                                required={true}
                                errorMessage={form.SurfaceType.error}
                                onSelect={(value) => {
                                  const roadSurface = roadSurfaceTypesList.find((it) => it.Key === value);
                                  if (roadSurface) updateExternalFormFieldValue(index, "SurfaceType", roadSurface);
                                }}
                              >
                                {roadSurfaceTypesList.map((type) => (
                                  <BaseDropdownMenuItem key={type.Key} value={type.Key}>
                                    <span className="sf-text-medium">{type.Value}</span>
                                  </BaseDropdownMenuItem>
                                ))}
                              </BaseDropdown>

                              {form.SurfaceType?.value?.Key === SntPassportEnums.RoadSurfaceType.Other && (
                                <BaseInput
                                  value={form.SurfaceTypeKeyOther.value}
                                  label="Покрытие (Другое)"
                                  required={true}
                                  placeholder="Укажите"
                                  errorMessage={form.SurfaceTypeKeyOther.error}
                                  maxlength={form.SurfaceTypeKeyOther.maxlength}
                                  onChange={(value) =>
                                    updateExternalFormFieldValue(index, "SurfaceTypeKeyOther", value)
                                  }
                                />
                              )}
                            </>
                          )}

                          <div className="full-field">
                            <BaseInput
                              value={form.Note.value}
                              label="Примечание"
                              required={form.Note.isRequired}
                              placeholder="Введите примечание"
                              errorMessage={form.Note.error}
                              maxlength={form.Note.maxlength}
                              onChange={(value) => updateExternalFormFieldValue(index, "Note", value)}
                            />
                          </div>

                          <div className="full-field flex" style={{ justifyContent: "flex-end" }}>
                            <BaseButton
                              variant="secondary"
                              appearance="negative"
                              onClick={() => removeExternalWork(index)}
                              size="small"
                            >
                              Удалить
                            </BaseButton>
                          </div>
                        </SntPassportBlockContentForm>
                      </div>
                    ))
                  : null}
              </SntPassportBlockWrapper>

              <SntPassportBlockWrapper
                title="Внутренняя инфраструктура"
                headerAction={
                  <div>
                    <BaseButton
                      variant="secondary"
                      startIcon={addIcon24("var(--text-primary")}
                      appearance="neutral"
                      disabled={internalForm.groups.length >= internalInfrastructureWorkTypesList.length}
                      onClick={addInternalWork}
                    >
                      Добавить вид работ
                    </BaseButton>
                  </div>
                }
              >
                {internalForm.groups && internalForm.groups.length > 0
                  ? internalForm.groups.map((form, index) => (
                      <div key={index}>
                        <SntPassportBlockContentForm>
                          <BaseDropdown
                            value={form.WorkType?.value?.Key ?? null}
                            display={form.WorkType?.value?.Value}
                            isSelectable={true}
                            label="Вид работ"
                            placeholder="Выберите"
                            variant="formfield"
                            required={form.WorkType.isRequired}
                            errorMessage={form.WorkType.error}
                            onSelect={(value) => {
                              const workType = internalInfrastructureWorkTypesList.find((it) => it.Key === value);
                              if (workType) updateInternalFormFieldValue(index, "WorkType", workType);
                            }}
                          >
                            {internalInfrastructureWorkTypesList.map((workType) => (
                              <BaseDropdownMenuItem key={workType.Key} value={workType.Key}>
                                <span className="sf-text-medium">{workType.Value}</span>
                              </BaseDropdownMenuItem>
                            ))}
                          </BaseDropdown>

                          {form.WorkType?.value?.Key === SntPassportEnums.InternalInfrastructureWorkType.Other && (
                            <BaseInput
                              value={form.WorkTypeKeyOther.value}
                              label="Вид работ (Другое)"
                              required={true}
                              placeholder="Укажите"
                              errorMessage={form.WorkTypeKeyOther.error}
                              maxlength={form.WorkTypeKeyOther.maxlength}
                              onChange={(value) => updateInternalFormFieldValue(index, "WorkTypeKeyOther", value)}
                            />
                          )}

                          <BaseInput
                            value={form.BasisDocumentName.value}
                            label="Наименования документа основания"
                            required={form.BasisDocumentName.isRequired}
                            placeholder="Введите наименование"
                            errorMessage={form.BasisDocumentName.error}
                            maxlength={form.BasisDocumentName.maxlength}
                            onChange={(value) => updateInternalFormFieldValue(index, "BasisDocumentName", value)}
                          />

                          {form.WorkType?.value?.Key ===
                          SntPassportEnums.InternalInfrastructureWorkType.ElectricitySupplyRepair ? (
                            <>
                              <NumberInput
                                value={form.LEPWorkScope.value}
                                label="ЛЭП, объем работ (км.)"
                                required={form.LEPWorkScope.isRequired}
                                placeholder="Укажите"
                                errorMessage={form.LEPWorkScope.error}
                                max={99999999}
                                digitsAfterPoint={3}
                                radix=","
                                onChange={(value) => updateInternalFormFieldValue(index, "LEPWorkScope", value)}
                              />
                              <NumberInput
                                value={form.ASKUEWorkScope.value}
                                label="АСКУЭ, объем работ (шт.)"
                                required={form.ASKUEWorkScope.isRequired}
                                placeholder="Укажите"
                                errorMessage={form.ASKUEWorkScope.error}
                                max={99999999}
                                digitsAfterPoint={3}
                                radix=","
                                onChange={(value) => updateInternalFormFieldValue(index, "ASKUEWorkScope", value)}
                              />
                              <NumberInput
                                value={form.KTPWorkScope.value}
                                label="КТП, объем работ (шт.)"
                                required={form.KTPWorkScope.isRequired}
                                placeholder="Укажите"
                                errorMessage={form.KTPWorkScope.error}
                                max={99999999}
                                digitsAfterPoint={3}
                                radix=","
                                onChange={(value) => updateInternalFormFieldValue(index, "KTPWorkScope", value)}
                              />
                            </>
                          ) : (
                            <NumberInput
                              value={form.WorkScope.value}
                              label={
                                form.WorkType?.value?.Key ===
                                SntPassportEnums.InternalInfrastructureWorkType.InnerRoadRepair
                                  ? "Протяженность (м, км)"
                                  : form.WorkType?.value?.Key ===
                                    SntPassportEnums.InternalInfrastructureWorkType.WaterSupplyRepair
                                  ? "Объем работ (км.)"
                                  : "Объем работ (км.,шт.)"
                              }
                              required={form.WorkScope.isRequired}
                              placeholder="Укажите"
                              errorMessage={form.WorkScope.error}
                              max={99999999}
                              digitsAfterPoint={3}
                              radix=","
                              onChange={(value) => updateInternalFormFieldValue(index, "WorkScope", value)}
                            />
                          )}

                          {form.WorkType?.value?.Key ===
                            SntPassportEnums.InternalInfrastructureWorkType.InnerRoadRepair && (
                            <>
                              <BaseDropdown
                                value={form.SurfaceType?.value?.Key ?? null}
                                display={form.SurfaceType?.value?.Value}
                                isSelectable={true}
                                label="Покрытие"
                                placeholder="Выберите"
                                variant="formfield"
                                required={true}
                                errorMessage={form.SurfaceType.error}
                                onSelect={(value) => {
                                  const roadSurface = roadSurfaceTypesList.find((it) => it.Key === value);
                                  if (roadSurface) updateInternalFormFieldValue(index, "SurfaceType", roadSurface);
                                }}
                              >
                                {roadSurfaceTypesList.map((type) => (
                                  <BaseDropdownMenuItem key={type.Key} value={type.Key}>
                                    <span className="sf-text-medium">{type.Value}</span>
                                  </BaseDropdownMenuItem>
                                ))}
                              </BaseDropdown>

                              {form.SurfaceType?.value?.Key === SntPassportEnums.RoadSurfaceType.Other && (
                                <BaseInput
                                  value={form.SurfaceTypeKeyOther.value}
                                  label="Покрытие (Другое)"
                                  required={true}
                                  placeholder="Укажите"
                                  errorMessage={form.SurfaceTypeKeyOther.error}
                                  maxlength={form.SurfaceTypeKeyOther.maxlength}
                                  onChange={(value) =>
                                    updateInternalFormFieldValue(index, "SurfaceTypeKeyOther", value)
                                  }
                                />
                              )}
                            </>
                          )}

                          <div
                            className={
                              form.WorkType?.value?.Key === SntPassportEnums.InternalInfrastructureWorkType.Other ||
                              (form.WorkType?.value?.Key ===
                                SntPassportEnums.InternalInfrastructureWorkType.InnerRoadRepair &&
                                form.SurfaceType?.value?.Key !== SntPassportEnums.RoadSurfaceType.Other)
                                ? "full-field"
                                : ""
                            }
                          >
                            <NumberInput
                              value={form.WorkCost.value}
                              label="Стоимость работ (₽)"
                              required={form.WorkCost.isRequired}
                              placeholder="Укажите"
                              errorMessage={form.WorkCost.error}
                              max={999999999999}
                              digitsAfterPoint={2}
                              radix=","
                              thousandsSeparator=" "
                              onChange={(value) => updateInternalFormFieldValue(index, "WorkCost", value)}
                            />
                          </div>

                          <div className="full-field">
                            <BaseInput
                              value={form.Note.value}
                              label="Примечание"
                              required={form.Note.isRequired}
                              placeholder="Введите примечание"
                              errorMessage={form.Note.error}
                              maxlength={form.Note.maxlength}
                              onChange={(value) => updateInternalFormFieldValue(index, "Note", value)}
                            />
                          </div>

                          <div className="full-field flex" style={{ justifyContent: "flex-end" }}>
                            <BaseButton
                              variant="secondary"
                              appearance="negative"
                              onClick={() => removeInternalWork(index)}
                              size="small"
                            >
                              Удалить
                            </BaseButton>
                          </div>
                        </SntPassportBlockContentForm>
                      </div>
                    ))
                  : null}
              </SntPassportBlockWrapper>
            </SntPassportContent>
          </div>

          <SntPassportBottomBar
            publicationText={
              agricultureMinistryData?.PublicationDatetime
                ? `Данные опубликованы ${agricultureMinistryData.PublicationDatetime.parseFromEpochSeconds().formatDate()}`
                : "Данные пока не опубликованы"
            }
            isSaveLoading={isSaveLoading}
            isPublishLoading={isPublishLoading}
            onPublish={publishData}
            onSave={saveData}
            publishBtnText="Опубликовать для председателя"
          />
        </>
      )}
    </div>
  );
};

export default InstitutionSntItemAgricultureMinistry;
