import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { To, useNavigate, useParams } from "react-router-dom";
import { paths } from "@/consts/routes";
import { Dictionary, OCC } from "@/types";
import { arrowRightWhiteIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { reset } from "@/app/store/slices/snt/myOcc";
import { setOccId, getOccDetails, updateOcc } from "@/app/store/slices/createOcc";
import { getDraftStatus, selectStatusById } from "@/app/store/slices/dictionary";
import BaseButton from "@/components/base-button";
import OccQuestionItem from "@/components/occ-question-item";
import QuestionDetailsModal from "@/components/occ-question-details";
import BottomBar from "../../BottomBar";
import CreateOccItemPageLoader from "../../PageLoader";
import CreateOccItemPageSubheader from "../../PageSubheader";
import ConfirmModal from "./ConfirmModal";
import styles from "./styles.module.scss";

interface IPreviewStepProps {
  boxes: Array<{
    title: string;
    content: Array<{ name: string; value: string } | null>;
  }>;
  prevLink: To;
  nextLink: To;
}

const CreateOccItemPreviewStep: React.FC<IPreviewStepProps> = ({ boxes, prevLink, nextLink }) => {
  const dispatch = useAppDispatch();
  const urlParams = useParams();
  const navigate = useNavigate();

  const order = useSelector((state: RootState) => state.createOcc.order as OCC.OccOrderItemFull);
  const status = useSelector(selectStatusById(order?.StatusId));
  const plannedEvents = useSelector((state: RootState) => state.createOcc.plannedEvents);
  const isAcceptOccLoading = useSelector((state: RootState) => state.createOcc.isAcceptOccLoading);
  const questions = useSelector((state: RootState) => state.createOcc.questions);
  const occId = useSelector((state: RootState) => state.createOcc.occId);
  const draftStatus = useSelector(getDraftStatus);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmShown, setConfirmShown] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<OCC.Question | null>(null);
  const [isQuestionDetailsOpen, setQuestionDetailsOpen] = useState<boolean>(false);

  async function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setConfirmShown(true);
  }

  async function openQuestionDetails(question: OCC.Question) {
    setSelectedQuestion(question);
    setQuestionDetailsOpen(true);
  }

  async function saveChanges() {
    try {
      await dispatch(updateOcc()).unwrap();
      await dispatch(reset());
      if (occId) {
        navigate(paths.myOccById(occId).index);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  async function fetch() {
    setIsLoading(true);
    try {
      if (!occId && urlParams?.occId) {
        dispatch(setOccId(parseInt(urlParams?.occId)));
      }

      await dispatch(getOccDetails()).unwrap();
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!order || !plannedEvents || questions.length === 0) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CreateOccItemPageSubheader>
        <h2 className="headline-h2 sf-bold">Проверьте правильность данных</h2>
      </CreateOccItemPageSubheader>

      {isLoading ? (
        <CreateOccItemPageLoader />
      ) : (
        <div className={styles["preview-content"]}>
          <div className={styles["preview-content__details"]}>
            <div className={styles["preview-content__details__box"]}>
              <div className={styles["preview-content__details__box--title"]}>
                <h3 className="headline-h3 sf-bold">Время проведения</h3>
              </div>
              <div className={styles["preview-content__details__box--content"]}>
                <div>
                  <h4 className="headline-h4 sf-text-bold">{plannedEvents?.Publication.Date}</h4>
                  <p className="text--primary sf-text-regular">{plannedEvents?.Publication.Text}</p>
                </div>
                <div>
                  <h4 className="headline-h4 sf-text-bold">{plannedEvents?.Start.Date}</h4>
                  <p className="text--primary sf-text-regular">{plannedEvents?.Start.Text}</p>
                </div>
                <div>
                  <h4 className="headline-h4 sf-text-bold">{plannedEvents?.End.Date}</h4>
                  <p className="text--primary sf-text-regular">{plannedEvents?.End.Text}</p>
                </div>
                <div>
                  <h4 className="headline-h4 sf-text-bold">{plannedEvents?.CreateProtocol.Date}</h4>
                  <p className="text--primary sf-text-regular">{plannedEvents?.CreateProtocol.Text}</p>
                </div>
              </div>
            </div>

            {boxes.map((box) => (
              <div className={styles["preview-content__details__box"]} key={box.title}>
                <div className={styles["preview-content__details__box--title"]}>
                  <h3 className="headline-h3 sf-bold">{box.title}</h3>
                </div>
                <div className={styles["preview-content__details__box--content"]}>
                  {box.content.map((it, index) =>
                    it ? (
                      <div key={`${box.title}--${index}`}>
                        <p className="text--primary sf-text-regular">{it.name}:</p>
                        <p className="text--primary sf-text-semibold">{it.value}</p>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className={styles["preview-content__questions"]}>
            <h3 className="headline-h3 sf-bold">Повестка:</h3>
            <div>
              {questions.map((question) => (
                <OccQuestionItem key={question.Id} question={question} onSelect={() => openQuestionDetails(question)} />
              ))}
            </div>
          </div>
        </div>
      )}

      <BottomBar prevLink={prevLink}>
        {status?.key === Dictionary.OCCStatus.OccPlanned ? (
          <BaseButton
            isLoading={isAcceptOccLoading}
            variant="primary"
            appearance="positive"
            endIcon={arrowRightWhiteIcon}
            onClick={saveChanges}
          >
            Сохранить изменения
          </BaseButton>
        ) : (
          <BaseButton
            disabled={isLoading || !order || order?.StatusId !== draftStatus?.id}
            isLoading={isAcceptOccLoading}
            variant="primary"
            appearance="positive"
            endIcon={arrowRightWhiteIcon}
            onClick={handleOnSubmit}
          >
            Создать собрание
          </BaseButton>
        )}
      </BottomBar>

      <ConfirmModal isOpen={isConfirmShown} nextLink={nextLink} onClose={() => setConfirmShown(false)} />

      <QuestionDetailsModal
        selectedQuestion={selectedQuestion}
        isOpen={isQuestionDetailsOpen}
        onClose={() => setQuestionDetailsOpen(false)}
      />
    </div>
  );
};

export default CreateOccItemPreviewStep;
