export namespace RegistryBase {
  export type Owner = {
    Id: number;
    FullName: string;
    LokoloUserId: number | null;
  };

  export type OwnerChangeInclude = {
    OwnerId: Owner["Id"];
    Type: "OwnerChange";
    Attributes: {
      Deleted: boolean;
      Updated: boolean;
    };
  };
}
