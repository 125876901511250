/* eslint-disable no-extend-native */
declare global {
  interface Number {
    formatRU: () => string;
    formatCurrency: () => string;
    parseFromEpochSeconds: () => Date;
    formatCompact: () => string;
  }
}

Number.prototype.formatRU = function (): string {
  return this.toLocaleString("ru-RU");
};

Number.prototype.formatCurrency = function (): string {
  return this.toLocaleString("ru-RU", {
    style: "currency",
    currency: "RUB",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

Number.prototype.parseFromEpochSeconds = function (): Date {
  return new Date(new Date(0).setUTCSeconds(this as number));
};

Number.prototype.formatCompact = function (): string {
  const _self = this as number;
  if (_self >= 1000000000) {
    const millions = _self / 1000000000;
    return `${millions
      .toLocaleString("ru-RU", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })
      .replace(".", ",")} млрд`;
  } else if (_self >= 1000000) {
    const millions = _self / 1000000;
    return `${millions
      .toLocaleString("ru-RU", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })
      .replace(".", ",")} млн`;
  } else if (_self >= 1000) {
    const thousands = _self / 1000;
    return `${thousands.toFixed(1).replace(".", ",")} тыс`;
  } else {
    return typeof _self === "number" ? this.formatRU() : _self;
  }
};

export type {};
