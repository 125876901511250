import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "@/app/store";
import { getOccDetailsById, getOwnerReestrById } from "@/app/store/slices/snt/myOcc/actions";
import { OCCService } from "@/services/v2";
import BaseButton from "@/components/base-button";
import UploadFileModal from "@/components/upload-file-modal";
import BaseTooltip from "@/components/base-tooltip";
import UploadingFileItem from "@/components/base-file-item/uploading-file-item";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import QuestionDecision from "./question-decision";
import QuestionDecisionRadio from "./decision-checkbox";
import styles from "./styles.module.scss";
import { Dictionary, OCC, Profile } from "@/types";
import { selectStatusById } from "@/app/store/slices/dictionary";
import { paths } from "@/consts/routes";
import { fileUploadIcon } from "@/consts/icons";

const SntMyOCCItemDecisionOwner: React.FC = () => {
  const { occId, ownerId } = useParams();
  const dispatch = useAppDispatch();

  const company = useSelector((state: RootState) => state.user.company);
  const occ = useSelector((state: RootState) => state.sntMyOcc.occ);
  const status = useSelector(selectStatusById(occ?.Order.StatusId));

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccessModalShown, setSuccessModalShown] = useState<boolean>(false);
  const [commonError, setCommonError] = useState<string>("");
  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
  const [owner, setOwner] = useState<OCC.RegistryOwner | null>(null);
  // const [ownerResult, setOwnerResult] = useState<OCC.OwnerOCCResults | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [decisions, setDecisions] = useState<{ [key: OCC.OwnerDecision["QuestionId"]]: OCC.OwnerDecision["Result"] }>(
    []
  );

  const allDecisions: OCC.OwnerDecision["Result"] = useMemo(() => {
    const values = Object.values(decisions);
    return occ?.Questions.every((it) => decisions[it.Id] && decisions[it.Id] === values[0]) ? values[0] : null;
  }, [decisions, occ?.Questions]);

  const isAnyDisabled = useMemo(() => {
    return (
      company?.Type === Profile.CompanyType.SNT &&
      occ?.Questions.some((it) => it.OnlyHorticultureMembers) &&
      !owner?.IsMember
    );
  }, [company?.Type, occ, owner]);

  const setAllDecisions = (value: OCC.Decision) => {
    const _decisions = occ?.Questions.slice().reduce((obj, it) => ({ ...obj, [it.Id]: value }), {});
    if (_decisions) {
      setDecisions(_decisions);
    }
  };

  async function handleChangeFile(file: File | null) {
    if (file) {
      setFiles((prevState) => [...prevState, file]);
    }
  }

  const onDeleteFile = (file: File) => {
    const index = files.findIndex((it) => it.name === file.name && it.size === file.size);
    setFiles(index >= 0 ? [...files.slice(0, index), ...files.slice(index + 1)] : [...files]);
  };

  async function handleSubmit() {
    if (!occ || !ownerId) {
      throw Error("no id");
    }

    if (
      status?.key === Dictionary.OCCStatus.Draft ||
      status?.key === Dictionary.OCCStatus.OccPlanned ||
      status?.key === Dictionary.OCCStatus.OccIsComingStartMakingDecisionsAfterXDays ||
      status?.key === Dictionary.OCCStatus.OccIsComingInitialDocumentsFormation ||
      status?.key === Dictionary.OCCStatus.OccIsComingRegenerateDocuments
    ) {
      setCommonError("Внесение решения недоступно, так как период голосования еще не наступил");
      return;
    }

    if (
      status?.key === Dictionary.OCCStatus.OccIsComingProtocolFormation ||
      status?.key === Dictionary.OCCStatus.CompletedProtocolPrepared ||
      status?.key === Dictionary.OCCStatus.Archived
    ) {
      setCommonError("Внесение решения недоступно, так как период голосования еще не наступил");
      return;
    }

    setCommonError("");

    const filered = occ.Questions.filter((it) => owner?.IsMember || (!owner?.IsMember && !it.OnlyHorticultureMembers));
    if (!filered.every((it) => decisions.hasOwnProperty(it.Id) && decisions[it.Id] !== null)) {
      setCommonError(
        `Не отмечены вопросы: (
          ${filered.filter((it) => !decisions.hasOwnProperty(it.Id) || !decisions[it.Id]).map((it) => it.Order)}
        )`
      );
      return;
    }

    if (company?.Type === Profile.CompanyType.UK && files.length === 0) {
      setCommonError("Для внесения решения необходимо приложить скан бюллетеня");
      return;
    }

    setSubmitLoading(true);
    try {
      let body: OCC.SetOwnerResultRequestBody = {
        OccId: occ?.Order.Id,
        OwnerId: parseInt(ownerId),
        Results: Object.keys(decisions).map((it: any) => ({ QuestionId: it, Result: decisions[it] })),
      };
      if (files.length > 0) {
        body["Files"] = files;
      }
      await OCCService.saveOwnerResult(body);
      setSuccessModalShown(true);
    } catch (error: any) {
      console.error(error?.response?.data);
      if (error?.response?.data?.Data?.Message) {
        setCommonError(error?.response?.data?.Data?.Message);
      }
    } finally {
      setSubmitLoading(false);
    }
  }

  const fetch = async () => {
    setIsLoading(true);
    try {
      if (!occId || !ownerId) {
        throw Error("no id");
      }

      const resp = await Promise.allSettled([
        OCCService.getOwnerResult(parseInt(occId), parseInt(ownerId)),
        dispatch(getOccDetailsById(parseInt(occId))).unwrap(),
        dispatch(getOwnerReestrById({ OccId: parseInt(occId), OwnerReestrId: parseInt(ownerId) })).unwrap(),
      ]);

      if (resp[2].status === "fulfilled") {
        setOwner(resp[2].value);
      }

      if (resp[0].status === "fulfilled" && resp[1].status === "fulfilled") {
        // setOwnerResult(resp[0].value.data?.Data ?? null);
        const _decisions = resp[1].value.Questions.slice().reduce((obj, it) => {
          const result =
            resp[0].status === "fulfilled"
              ? resp[0].value.data.Data?.QuestionsResults.find((result) => result.QuestionId === it.Id)
              : null;
          const decision = () => {
            switch (result?.ResultTypeId) {
              case 0:
                return OCC.Decision.Agree;
              case 1:
                return OCC.Decision.Disagree;
              case 2:
                return OCC.Decision.Abstain;

              default:
                return null;
            }
          };
          return { ...obj, [it.Id]: decision() };
        }, {});
        setDecisions(_decisions);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className="flex middle between">
          <h1 className="title-h1">Внести решение</h1>
        </div>
        <NavLink className={styles.back_link} to={paths.myOccById(occ?.Order.Id).decision}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.20711 2.79289C7.59763 3.18342 7.59763 3.81658 7.20711 4.20711L4.41421 7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H4.41421L7.20711 11.7929C7.59763 12.1834 7.59763 12.8166 7.20711 13.2071C6.81658 13.5976 6.18342 13.5976 5.79289 13.2071L1.29289 8.70711C0.902369 8.31658 0.902369 7.68342 1.29289 7.29289L5.79289 2.79289C6.18342 2.40237 6.81658 2.40237 7.20711 2.79289Z"
              fill="#226DFF"
            />
          </svg>
          <span className="sf-text-regular text--primary color--primary">Вернуться назад</span>
        </NavLink>
      </div>

      <div className={styles.content}>
        <div className={styles.owner}>
          <h2 className="headline-h3 sf-bold color--text-primary">{owner?.FullName}</h2>
          {company?.Type === Profile.CompanyType.UK && (
            <div className={styles.owner_details}>
              <div>
                <span className="text--primary sf-text-regular">Помещение:</span>
                <span className="text--primary sf-text-semibold color--text-primary">{owner?.ApartmentNumber}</span>
              </div>
              <div>
                <span className="text--primary sf-text-regular">Площадь (м²):</span>
                <span className="text--primary sf-text-semibold color--text-primary">{owner?.Area ?? ""}</span>
              </div>
              <div>
                <span className="text--primary sf-text-regular">Доля:</span>
                <span className="text--primary sf-text-semibold color--text-primary">{owner?.SizeShares ?? ""}</span>
              </div>
              <div>
                <span className="text--primary sf-text-regular">Документ о праве собств.:</span>
                <span className="text--primary sf-text-semibold color--text-primary">
                  {owner?.DateRecordNumber ?? ""}
                </span>
              </div>
            </div>
          )}
          <div>
            <UploadFileModal
              title="Загрузить скан бюллетеня"
              id="upload-ballot"
              accept="*"
              onChangeFile={(file) => handleChangeFile(file)}
            >
              <BaseButton
                variant="secondary"
                appearance="neutral"
                startIcon={fileUploadIcon()}
                onClick={(event) => event.preventDefault()}
              >
                Загрузить скан бюллетеня
              </BaseButton>
            </UploadFileModal>

            {files.length > 0 && (
              <div className={styles.files}>
                {files.map((file, i) => (
                  <UploadingFileItem key={`${file.name}_${i}`} file={file} onDelete={() => onDeleteFile(file)} />
                ))}
              </div>
            )}
          </div>
        </div>

        <div className={styles.questions}>
          <div className={styles.questions_header}>
            <p className="text--primary color--text-secondary sf-text-medium">Отметить все ответы</p>
            <div className="flex">
              <BaseTooltip
                title={
                  isAnyDisabled
                    ? "Функция массовой отметки недоступна т.к некоторые вопросы доступны только для членов садоводства"
                    : ""
                }
                placement="bottom"
              >
                <div className={styles.questions_header_selects}>
                  <QuestionDecisionRadio
                    isСombined={true}
                    value={OCC.Decision.Agree}
                    selected={allDecisions}
                    onChange={setAllDecisions}
                    disabled={isAnyDisabled}
                  />
                  <QuestionDecisionRadio
                    isСombined={true}
                    value={OCC.Decision.Disagree}
                    selected={allDecisions}
                    onChange={setAllDecisions}
                    disabled={isAnyDisabled}
                  />
                  <QuestionDecisionRadio
                    isСombined={true}
                    value={OCC.Decision.Abstain}
                    selected={allDecisions}
                    onChange={setAllDecisions}
                    disabled={isAnyDisabled}
                  />
                </div>
              </BaseTooltip>
            </div>
          </div>

          <div className={styles.questions_list}>
            {occ?.Questions.map((question) => (
              <QuestionDecision
                key={question.Id}
                question={question}
                decision={decisions[question.Id]}
                owner={owner}
                setDecision={(value) =>
                  setDecisions((prevState) => ({
                    ...prevState,
                    [question.Id]: value,
                  }))
                }
              />
            ))}
          </div>
        </div>
      </div>

      <div className={styles.bottom_bar}>
        <div>{commonError && <p className="color--text-error text--primary sf-text-semibold">{commonError}</p>}</div>
        <div>
          <BaseButton isLoading={isSubmitLoading} onClick={handleSubmit}>
            {company?.Type === Profile.CompanyType.SNT ? "Отправить решение" : "Внести решение"}
          </BaseButton>
        </div>
      </div>

      <BaseModal isOpen={isSuccessModalShown} minWidth="352px" maxWidth="352px">
        <BaseModalHeader
          variant="info-iconed"
          icon={
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.5295 7.08987C27.8855 5.4662 23.8143 5.06396 19.923 5.94314C16.0317 6.82233 12.5288 8.93584 9.93682 11.9685C7.34483 15.0011 5.80259 18.7903 5.5401 22.7711C5.27762 26.7518 6.30894 30.7107 8.48028 34.0574C10.6516 37.4042 13.8466 39.9593 17.5888 41.3419C21.3309 42.7244 25.4198 42.8602 29.2454 41.7291C33.0711 40.598 36.4286 38.2605 38.8173 35.0653C41.2059 31.87 42.4977 27.9883 42.5 23.9989V22.1597C42.5 21.3313 43.1716 20.6597 44 20.6597C44.8284 20.6597 45.5 21.3313 45.5 22.1597V23.9997C45.4974 28.636 43.9961 33.1481 41.2201 36.8615C38.4441 40.5749 34.5421 43.2914 30.096 44.606C25.65 45.9205 20.8981 45.7627 16.5491 44.1559C12.2001 42.5492 8.48701 39.5797 5.96357 35.6903C3.44012 31.8009 2.24155 27.1999 2.54661 22.5737C2.85166 17.9474 4.644 13.5437 7.6563 10.0193C10.6686 6.4949 14.7395 4.03866 19.2618 3.0169C23.7841 1.99514 28.5156 2.46261 32.7505 4.34959C33.5072 4.68676 33.8473 5.57352 33.5102 6.33023C33.173 7.08694 32.2862 7.42704 31.5295 7.08987ZM45.0601 6.93843C45.6462 7.52392 45.6467 8.47367 45.0612 9.05975L25.0612 29.0798C24.7799 29.3613 24.3983 29.5195 24.0004 29.5196C23.6024 29.5197 23.2207 29.3617 22.9393 29.0803L16.9393 23.0803C16.3536 22.4945 16.3536 21.5447 16.9393 20.959C17.5251 20.3732 18.4749 20.3732 19.0607 20.959L23.9995 25.8978L42.9388 6.93949C43.5243 6.35341 44.4741 6.35294 45.0601 6.93843Z"
                fill="#25C168"
              />
            </svg>
          }
          title="Решение принято!"
          onClose={() => setSuccessModalShown(false)}
        />

        <BaseModalContent>
          <p className="center text--primary sf-text-regular color--text-primary">Данные внесены</p>
        </BaseModalContent>

        <BaseModalActions>
          <NavLink className={styles.success_modal_actions} to={paths.myOccById(occ?.Order.Id).decision}>
            <BaseButton>К собранию</BaseButton>
          </NavLink>
        </BaseModalActions>
      </BaseModal>
    </div>
  );
};

export default SntMyOCCItemDecisionOwner;
