import React, { useMemo } from "react";
import classNames from "classnames/bind";
import { xlsFileIcon, docFileIcon, pdfFileIcon, otherFileIcon } from "@/consts/icons";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface UploadingIFileItemProps {
  file: File;
  progress?: boolean;
  onDelete: () => void;
}

const UploadingFileItem: React.FC<UploadingIFileItemProps> = ({ file, progress = false, onDelete }) => {
  const extension: string = useMemo(() => {
    return file.name.split(".")[1];
  }, [file]);

  const name: string = useMemo(() => {
    return file.name.split(".")[0];
  }, [file]);

  const size: string = useMemo(() => {
    if (!+file.size) return "0 Байт";

    const k = 1024;
    const sizes = ["Байт", "КБ", "МБ", "ГБ", "ТБ", "ПиБ", "EiB", "ZiB", "YiB"];

    const i = Math.floor(Math.log(file.size) / Math.log(k));

    return `${parseFloat((file.size / Math.pow(k, i)).toFixed(0))}${sizes[i]}`;
  }, [file]);

  const parsed: { bg: string; icon: JSX.Element } = useMemo(() => {
    const xlsFormats = ["xlsx", "xlsm", "xlsb", "xltx", "xltm", "xls", "xlt", "xml"];
    const docFormats = ["doc", "docx", "docm", "dotx", "dotm", "docb"];

    if (extension && xlsFormats.includes(extension.toLowerCase())) {
      return {
        bg: "#DFFCEE",
        icon: xlsFileIcon,
      };
    }

    if (extension && docFormats.includes(extension.toLowerCase())) {
      return {
        bg: "#DFECFF",
        icon: docFileIcon,
      };
    }

    if (extension && extension.toLowerCase() === "pdf") {
      return {
        bg: "#FFDFDF",
        icon: pdfFileIcon,
      };
    }

    return {
      bg: "#EDF1F5",
      icon: otherFileIcon,
    };
  }, [file, extension]);

  return (
    <div className={cx({ file: true })}>
      <span className="flex middle center" style={{ backgroundColor: parsed.bg }}>
        {parsed.icon}
      </span>
      <div>
        <div className={styles.file_name}>
          <p className="text--primary sf-text-medium color--text-primary">{name}</p>
          {progress && file && (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M17.3737 4.29289C17.7642 4.68342 17.7642 5.31658 17.3737 5.70711L8.20703 14.8738C7.8165 15.2643 7.18334 15.2643 6.79281 14.8738L2.62615 10.7071C2.23562 10.3166 2.23562 9.68342 2.62615 9.29289C3.01667 8.90237 3.64983 8.90237 4.04036 9.29289L7.49992 12.7525L15.9595 4.29289C16.35 3.90237 16.9832 3.90237 17.3737 4.29289Z"
                fill="#32C262"
              />
            </svg>
          )}
        </div>
        <div className={`flex middle caption--middle ${styles.actions}`}>
          <span className="color--text-secondary sf-text-regular">
            {extension?.toUpperCase()}, {size}
          </span>

          {onDelete && (
            <span className="sf-text-regular color--text-error--btn-hover" role="button" onClick={onDelete}>
              Удалить
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadingFileItem;
