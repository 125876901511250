import { useMemo, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement } from "chart.js";
import styles from "./styles.module.scss";

ChartJS.register(ArcElement);

interface IChartProps {
  data: number[];
  total: number;
  labels: string[];
  colors: string[];
  legendBgColors: string[];
  formatLegend?: (value: number) => number | string;
  formatSelected?: (value: number) => number | string;
}

const Chart: React.FC<IChartProps> = ({
  data,
  total,
  labels,
  colors,
  legendBgColors,
  formatLegend,
  formatSelected,
}) => {
  const chartRef = useRef<ChartJS>(null);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [legendsColors] = useState<string[]>(colors.slice());

  const chartData = {
    datasets: [
      {
        cutout: "75%",
        data,
        backgroundColor: colors,
        borderWidth: 0,
        aspectRatio: 1,
      },
    ],
  };

  const chartOptions = {
    aspectRatio: 1,
    plugins: {
      legend: { display: true },
    },
  };

  const accentValue: number = useMemo(() => {
    if (activeIndex !== null) {
      return data[activeIndex] ?? 0;
    } else {
      return total;
    }
  }, [activeIndex, data, total]);

  function onClickHandle(index: number | null) {
    if (!chartRef.current) {
      return;
    }

    if (
      chartRef.current.data.datasets[0].backgroundColor &&
      Array.isArray(chartRef.current.data.datasets[0].backgroundColor)
    ) {
      chartRef.current.data.datasets[0].backgroundColor.forEach((it, i, __colors) => {
        if (index !== undefined && index !== null && index !== i) {
          __colors[i] = it.length === 9 ? it : it + "33";
        } else {
          colors[i] = it.length === 9 ? it.slice(0, -2) : it;
        }
      });
    }

    setActiveIndex(index !== undefined && index !== null ? index : null);
    chartRef.current?.update();
    chartRef.current?.update();
  }

  return (
    <div className={styles["b-chart"]}>
      <div className={styles["b-chart__donut"]}>
        <Doughnut ref={chartRef as any} data={chartData} options={chartOptions} />
        <h4 className="color--text-primary headline-h4 sf-text-bold center">
          {formatSelected ? formatSelected(accentValue) : accentValue}
        </h4>
      </div>

      <div className={styles["b-chart__list"]}>
        <div
          className={`${styles["b-chart__list_item"]} ${
            activeIndex === null ? styles["b-chart__list_item--active"] : ""
          }`}
          onClick={() => onClickHandle(null)}
        >
          <div>
            <div className={styles["b-chart__list_item__backdrop"]} style={{ backgroundColor: "#e0e5ec" }}></div>
            <div className={styles["b-chart__list_item__content"]}>
              <p className="caption--middle color--text-primary sf-text-regular">Всего</p>
              <span className="caption--middle sf-text-regular" style={{ backgroundColor: "var(--text-primary)" }}>
                {formatLegend ? formatLegend(total) : total}
              </span>
            </div>
          </div>
        </div>

        {data.map((it, index) => (
          <div
            key={index}
            className={`${styles["b-chart__list_item"]} ${
              index === activeIndex ? styles["b-chart__list_item--active"] : ""
            }`}
            onClick={() => onClickHandle(index)}
          >
            <div>
              <div
                className={styles["b-chart__list_item__backdrop"]}
                style={{ backgroundColor: legendBgColors[index] }}
              ></div>
              <div className={styles["b-chart__list_item__content"]}>
                <p className="caption--middle color--text-primary sf-text-regular">{labels[index] ?? ""}</p>
                <span
                  className="caption--middle sf-text-regular"
                  style={{ backgroundColor: legendsColors[index] ?? "var(--text-primary)" }}
                >
                  {formatLegend ? formatLegend(it) : it}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Chart;
