import { useSelector } from "react-redux";
import { successIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleRosreestrDataLoadStartedModal } from "@/app/store/slices/uk/rosreestr";
import BaseModal, { BaseModalActions, BaseModalHeader, BaseModalContent } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";

const RosreestrDataLoadStartedModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const isOpen = useSelector((state: RootState) => state.ukRosreestr.isRosreestrDataLoadStartedModalShown);

  function onClose() {
    dispatch(toggleRosreestrDataLoadStartedModal(false));
  }

  return (
    <BaseModal isOpen={isOpen} minWidth="520px">
      <BaseModalHeader
        variant="info-iconed"
        iconBgColor="#CFF3E1"
        icon={successIcon}
        title="Информация из Росреестра запрошена"
        onClose={onClose}
      />

      <BaseModalContent>
        <p className="text--primary sf-text-regular center color--text-primary">
          Получение и обработка данных обычно занимает <span className="sf-text-semibold">1-2</span> полных дня.
          Отслеживать получение данных вы можете во вкладке <br />
          «Данные из Росреестра».
        </p>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton onClick={onClose}>Понятно</BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default RosreestrDataLoadStartedModal;
