import styles from "./styles.module.scss";
import BaseTableBody from "./body";
import BaseTableHead from "./head";
import BaseTableRow from "./row";
import BaseTableCell from "./cell";
import BaseTableHeadCell from "./headCell";
import BaseTableToolbar from "./toolbar";
import BaseContainer from "./container";

interface IBaseTableProps {
  children: React.ReactNode;
  className?: string;
}

const BaseTable: React.FC<IBaseTableProps> = ({ children, className }) => {
  return <table className={[styles.table, className?.split(" ")].join(" ")}>{children}</table>;
};

export default BaseTable;
export {
  BaseTable,
  BaseTableBody,
  BaseTableHead,
  BaseTableRow,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableToolbar,
  BaseContainer,
};
