import Sidebar from "./Sidebar";
import Header from "./Header";
import Content from "./Content";
import styles from "./styles.module.scss";

interface ILayoutProps {
  children: JSX.Element | JSX.Element[];
}

const Layout: React.FC<ILayoutProps> = ({ children }) => (
  <div className={styles.layout}>
    <Header />
    <div className={styles.wrapper}>
      <aside>
        <Sidebar />
      </aside>
      <div>
        <Content>{children}</Content>
      </div>
    </div>
  </div>
);
export default Layout;
