import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "&": {
    zIndex: "9999",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.16), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)",
    backgroundColor: "rgba(0, 0, 0, 0.88)",
    padding: "8px 10px",
    fontSize: "13px",
    fontFamily: "SF Pro Text",
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "16px",
    letterSpacing: "-0.078px",
    borderRadius: "4px",
    color: "white",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(0, 0, 0, 0.88)!important",
  },
}));

const BaseTooltip = ({ children, className, title, placement = "top", sx, open }: TooltipProps) => (
  <StyledTooltip sx={sx} className={className} title={title} arrow placement={placement} open={open}>
    <span>{children}</span>
  </StyledTooltip>
);

export default BaseTooltip;
