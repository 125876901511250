import { useEffect, useState } from "react";
import BaseInput from "../base-input";
import useDebounce from "@/hooks/useDebounce";
import styles from "./styles.module.scss";

interface IBaseQuerySearchProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
}

const BaseQuerySearch: React.FC<IBaseQuerySearchProps> = ({
  value,
  onChange,
  placeholder = "Поиск",
  style = {},
}) => {
  const [query, setQuery] = useState<string>(value);
  const [debouncedQuery] = useDebounce(query, 300);

  useEffect(() => {
    onChange(debouncedQuery);
  }, [debouncedQuery]);

  useEffect(() => {
    setQuery(value);
  }, [value]);

  return (
    <div className={styles.search_query} style={style}>
      <svg
        className={styles.icon}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5 7C11.5 9.48528 9.48528 11.5 7 11.5C4.51472 11.5 2.5 9.48528 2.5 7C2.5 4.51472 4.51472 2.5 7 2.5C9.48528 2.5 11.5 4.51472 11.5 7ZM10.4765 11.8907C9.49572 12.5892 8.29583 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 8.29583 12.5892 9.49572 11.8907 10.4765L14.7071 13.2929C15.0976 13.6834 15.0976 14.3166 14.7071 14.7071C14.3166 15.0976 13.6834 15.0976 13.2929 14.7071L10.4765 11.8907Z"
          fill="#818C99"
        />
      </svg>
      <BaseInput
        value={query}
        placeholder={placeholder}
        onChange={(value) => setQuery(value)}
        sx={{
          "&": {
            "& .MuiInputBase-input": {
              padding: "0 12px 0 32px",
            },
          },
        }}
      />
    </div>
  );
};

export default BaseQuerySearch;
