import { SntPassportAnalytics } from "@/types";
import styles from "../styles.module.scss";

interface IAnalyticsCountPercentCardCardProps {
  card: SntPassportAnalytics.CardCountPercent;
}

const AnalyticsCountPercentCardCard: React.FC<IAnalyticsCountPercentCardCardProps> = ({ card }) => {
  return (
    <div className={styles["b-card--has-percent"]}>
      <h4 className="headline-h4 sf-text-bold color--text-primary">{card.Title}</h4>
      <div>
        <p className="headline-h1 sf-bold color--primary">{card.Count}</p>
        {card.Percent !== null && (
          <div className={styles["b-card__percentage"]}>
            <span className="headline-h4 sf-text-bold color--text-secondary color--text-success">{card.Percent}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnalyticsCountPercentCardCard;
