import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { FormFiled, FormValidator } from "@/types";
import { eyeIcon } from "@/consts/icons";
import { useAppDispatch } from "@/app/store";
import { authLogin, fetchUser } from "@/app/store/slices/user";
import AuthorizationField from "./Field";
import BaseButton from "@/components/base-button";
import styles from "../auth.module.scss";

function AuthFormByLogin() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [email, setEmail] = useState<FormFiled>(new FormFiled(""));
  const [password, setPassword] = useState<FormFiled>(new FormFiled(""));
  const [isPasswordShown, setPasswordShown] = useState<boolean>(false);

  const isDisabled: boolean = useMemo(() => {
    return email.value.length === 0 || password.value.length === 0;
  }, [email, password]);

  function isFieldsNotValid() {
    const errors = {
      email: FormValidator.getEmailErrorMessage(email.value.trim(), true),
      password: FormValidator.getFieldErrorMessage(password.value.trim(), true),
    };

    if (Object.values(errors).some((it) => !!it)) {
      setEmail((prevState) => ({ ...prevState, error: errors.email }));
      setPassword((prevState) => ({ ...prevState, error: errors.password }));
      return errors;
    }

    return false;
  }
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setErrorMessage("");
    setEmail((prevState) => ({ ...prevState, error: "" }));
    setPassword((prevState) => ({ ...prevState, error: "" }));

    if (isFieldsNotValid()) {
      return;
    }

    try {
      setIsLoading(true);
      await dispatch(authLogin({ email: email.value.trim(), password: password.value.trim() })).unwrap();
      await dispatch(fetchUser()).unwrap();

      navigate("/");
    } catch (err: any) {
      setErrorMessage(err?.Data?.Message ?? "Неизвестная ошибка. Попробуйте позже");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form className={styles["p-auth__form"]} onSubmit={handleSubmit}>
      <div>
        <AuthorizationField
          value={email.value}
          type="text"
          name="email"
          label="Ваш логин"
          placeholder="Locolo@gmail.com"
          errorMessage={email.error}
          onChange={(value) => setEmail((prevState) => ({ ...prevState, value }))}
        />
      </div>

      <div>
        <AuthorizationField
          value={password.value}
          type={isPasswordShown ? "text" : "password"}
          name="password"
          label="Ваш пароль"
          placeholder="12345"
          errorMessage={password.error}
          onChange={(value) => setPassword((prevState) => ({ ...prevState, value }))}
          endAdornment={<span onClick={() => setPasswordShown(!isPasswordShown)}>{eyeIcon}</span>}
        />
      </div>

      <div>
        <BaseButton type="submit" disabled={isDisabled} className={styles["p-auth__form_submit"]} isLoading={isLoading}>
          Войти
        </BaseButton>
      </div>

      {errorMessage && <p className="color--red text--primary sf-text-regular center">{errorMessage}</p>}
    </form>
  );
}

export default AuthFormByLogin;
