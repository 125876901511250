export type HouseType = {
  FiasId: string;
  Address: string;
  Occ?: {
    Id: number;
    Status: string;
    StatusId: number;
    PublicationDate: string;
    PublicationTime?: number;
    Number: string;
    StartTime?: number;
    EndTime?: number;
    VotingPeriod: string;
    OwnerReestrUpdatedAtDate: string;
    OwnerReestrUpdatedAtTime: number;
  };
};

export namespace House {
  export type Item = {
    Id: number;
    FiasId: string;
    Address: string;
    GeoPoint: {
      Latitude: number;
      Longitude: number;
    };
    Type: unknown;
    TypeName: unknown;
    CadastralNumber: string;
  };

  export type Address = {
    City: string;
    House: string;
    Lat: string;
    Lon: string;
    Street: string;
    Value: string;
  };
}
