import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { OCCV2Order } from "@/types";
import { useAppDispatch } from "@/app/store";
import { checkIfHasSntRegistry } from "@/app/store/slices/snt/registry/actions";
import Headline from "@/components/headline";
import DraftsList from "@/features/CreateOccNew/DraftsList";

function SntCreateOCC() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isRegistryCheckLoading, setRegistryCheckLoading] = useState<boolean>(false);
  const [isEmptyRegistryModalOpen, setEmptyRegistryModalOpen] = useState<boolean>(false);

  function onSelectDraft(occ: OCCV2Order.Item) {
    navigate(`/snt/create-occ/${occ.Id}/create`, { state: occ.Id });
  }

  async function onCreateOcc() {
    setRegistryCheckLoading(true);
    try {
      const resp = await dispatch(checkIfHasSntRegistry()).unwrap();
      if (!resp) {
        setEmptyRegistryModalOpen(true);
        return;
      }

      navigate("/snt/create-occ/new/create");
    } catch (error) {
      console.warn(error);
    } finally {
      setRegistryCheckLoading(false);
    }
  }

  return (
    <div>
      <DraftsList
        isCreateOccCheckLoading={isRegistryCheckLoading}
        isEmptyRegistryModalOpen={isEmptyRegistryModalOpen}
        createOcc={onCreateOcc}
        toggleEmptyRegistryModal={setEmptyRegistryModalOpen}
        onSelectDraft={(occ) => onSelectDraft(occ)}
        badge={
          <Headline
            backgroundColor="#C9F2BB"
            iconColor="#71B05C"
            title="Онлайн голосование"
            text={
              "Чтобы онлайн голосование было юридически значимым, необходимо прописать в Уставе садоводства перечень вопросов и последовательность действий для заочного голосования.\n Напоминаем, что внесение изменений в Устав необходимо утвердить на общем собрании."
            }
          />
        }
      />
    </div>
  );
}

export default SntCreateOCC;
