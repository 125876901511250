import React from "react";
import { useSelector } from "react-redux";
import { infoBlueCircleIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleErrorModalOpen } from "@/app/store/slices/access";
import { BaseModal, BaseModalHeader, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";

const ErrorModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const isOpen = useSelector((state: RootState) => state.access.setRoleDetails.isErrorModalOpen);

  const close = () => {
    dispatch(toggleErrorModalOpen(false));
  };

  return (
    <BaseModal isOpen={isOpen} minWidth="304px" maxWidth="304px">
      <BaseModalHeader
        variant="info-iconed"
        iconBgColor="#CCECFC"
        icon={infoBlueCircleIcon}
        title="Ошибка"
        subtitle={<p className="text--primary center">Мы уже работаем с неполадками. Скоро все починим :)</p>}
        onClose={close}
      />

      <BaseModalActions>
        <BaseButton style={{ width: "100%" }} variant="secondary" onClick={close}>
          Закрыть
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default ErrorModal;
