import { House, ResponseAbstract, ResponseAbstractWithPagination, SntRegistry } from "@/types";
import client from "./config";
import { HouseUsers } from "@/types/house-users";

export namespace HouseUsersService {
  export function getHouseUsersList({
    Query,
    Address,
    LokoloAddressId,
    Esia,
    CanOccVote,
    Registry,
    Ownership,
    Page,
  }: {
    Query: { Type: HouseUsers.QueryType; Value?: string };
    Address: House.Item["FiasId"] | SntRegistry.SntDetails["Id"];
    LokoloAddressId: House.Item["Id"] | SntRegistry.SntDetails["Id"];
    Esia?: boolean;
    CanOccVote?: boolean;
    Registry?: boolean;
    Ownership?: string;
    Page?: number;
  }) {
    let params: any = {
      Address,
      LokoloAddressId,
      "Query[Type]": Query.Type,
    };
    if (Page) {
      params["Page"] = Page;
    }
    if (Query.Value) {
      params["Query[Value]"] = Query.Value;
    }
    if (typeof Esia === "boolean") {
      params["Esia"] = Esia;
    }
    if (typeof CanOccVote === "boolean") {
      params["CanOccVote"] = CanOccVote;
    }
    if (typeof Registry === "boolean") {
      params["Registry"] = Registry;
    }
    if (Ownership) {
      params["Ownership"] = Ownership;
    }
    return client.get<ResponseAbstractWithPagination<HouseUsers.User[]>>("/api/house-users", { params });
  }

  export function getOwnershipStatuses() {
    return client.get<ResponseAbstract<HouseUsers.Status[]>>("/api/ownership-statuses");
  }
}
