import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface IBaseTableBodyProps {
  children: React.ReactNode;
  className?: string;
  refProp?: React.RefObject<any>;
}

const BaseTableBody: React.FC<IBaseTableBodyProps> = ({ children, className, refProp }) => {
  return (
    <tbody ref={refProp} className={cx(className, { body: true })}>
      {children}
    </tbody>
  );
};

export default BaseTableBody;
