import styles from "./headline.module.scss";

interface IHeadline {
  title: string;
  text?: string;
  backgroundColor?: string;
  iconColor?: string;
  children?: JSX.Element;
}

const Headline = ({ title, text, backgroundColor = "#c9f2bb", iconColor = "#71B05C", children }: IHeadline) => (
  <section className={styles.root} style={{ backgroundColor }}>
    <div className={styles.title__wrapper}>
      <span className={styles.icon}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0C12.415 0 16 3.585 16 8C16 12.415 12.415 16 8 16C3.585 16 0 12.415 0 8C0 3.585 3.585 0 8 0ZM9.331 12C9.881 12 10.327 11.554 10.327 11.004C10.327 10.454 9.881 10.007 9.331 10.007H8.998V6.757C8.998 6.314 8.759 6 8.318 6C8.315 6 6.683 6 6.683 6C6.129 6 5.678 6.45 5.678 7.005C5.678 7.56 6.129 8.01 6.683 8.01H6.995V10.007H6.654C6.104 10.007 5.657 10.454 5.657 11.004C5.657 11.554 6.104 12 6.654 12H9.331ZM8 2.658C8.741 2.658 9.342 3.259 9.342 4C9.342 4.741 8.741 5.342 8 5.342C7.259 5.342 6.658 4.741 6.658 4C6.658 3.259 7.259 2.658 8 2.658Z"
            fill={iconColor}
          />
        </svg>
      </span>
      <p className="headline-h3 sf-bold">{title}</p>
    </div>
    {text && (
      <div className={styles.content}>
        <p className="text--primary sf-text-regular">{text}</p>
        {children && <div>{children}</div>}
      </div>
    )}
  </section>
);

export default Headline;
