import { useSelector } from "react-redux";
import CreateOccItemCreateAgendaStep from "@/features/CreateOccNew/OccItem/Steps/CreateAgenda";
import { RootState } from "@/app/store";

const SntCreateOCCCreateAgendaStep: React.FC = () => {
  const { occId } = useSelector((state: RootState) => state.createOcc);

  return (
    <div>
      <CreateOccItemCreateAgendaStep
        nextLink={`/snt/create-occ/${occId}/preview`}
        prevLink={`/snt/create-occ/${occId}/create`}
        quorumTooltipMessage="Выберите значение из списка - из какого расчета будет определяться набрался ли кворум"
      />
    </div>
  );
};

export default SntCreateOCCCreateAgendaStep;
