import React, { CSSProperties, SyntheticEvent, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames/bind";
import useDebounce from "@/hooks/useDebounce";
import { checkedIcon, dropDownIcon } from "@/consts/icons";
import BaseInput from "../base-input";
import { Spinner } from "../spinner";
import styles from "./styles.module.scss";
import BaseLabel from "../BaseLabel";

const cx = classNames.bind(styles);

interface IAutocompleteDropdownProps {
  value: any;
  callback: (query: string) => Promise<IAutocompleteDropdownProps["value"][]>;
  label?: string;
  required?: boolean;
  placeholder?: string;
  tooltipMessage?: string;
  emptyMessage?: JSX.Element | JSX.Element[] | React.ReactNode;
  noResultsMessage?: JSX.Element | JSX.Element[] | React.ReactNode;
  errorMessage?: JSX.Element | JSX.Element[] | React.ReactNode;
  displayKey: string;
  valueKey: string;
  onSelect: (value: IAutocompleteDropdownProps["value"]) => void;
}

const AutocompleteDropdown: React.FC<IAutocompleteDropdownProps> = ({
  value,
  callback,
  label,
  required,
  placeholder,
  tooltipMessage,
  emptyMessage,
  noResultsMessage,
  errorMessage,
  displayKey,
  valueKey,
  onSelect,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const [isFocus, setFocus] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [options, setOptions] = useState<Array<any>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [debouncedQuery] = useDebounce(query, 300);
  const [position, setPostion] = useState<{
    left: CSSProperties["left"];
    top: CSSProperties["top"];
    width: CSSProperties["width"];
    height: CSSProperties["height"];
  }>({
    left: "0",
    top: "0",
    width: "auto",
    height: "212px",
  });

  function openDropdown() {
    const rect = ref.current?.getBoundingClientRect();

    const scrolltop = window?.scrollY ?? 0;
    const windowHeight = window?.innerHeight;
    const top = scrolltop + (rect?.top ?? 0) + (rect?.height ?? 0);
    const left = rect?.left ?? 0;
    const rectBottom = (rect?.top ?? 0) + 296;
    const height = rectBottom > windowHeight ? windowHeight - (rect?.top ?? 0) - 84 : 228;
    setPostion({
      top: `${top}px`,
      left: `${left}px`,
      width: `${rect?.width}px`,
      height: `${height}px`,
    });
    setFocus(true);
  }

  function selectOption(e: SyntheticEvent<HTMLElement>, val: any) {
    e.stopPropagation();
    e.preventDefault();

    const item = options.find((it) => it[valueKey] === val);
    if (item) {
      setQuery(item[displayKey]);
      onSelect(item);
    }
    setFocus(false);
  }

  async function searchOptions(q: string) {
    setLoading(true);
    try {
      const data = await callback(q);
      setOptions(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    searchOptions(debouncedQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  useEffect(() => {
    if (value && displayKey) {
      setQuery(displayKey in value ? (value as any)[displayKey] : value);
    } else {
      setQuery("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function handleClickOutside(event: any) {
    if (
      isFocus &&
      ref.current &&
      !ref.current.contains(event.target) &&
      bodyRef.current &&
      !bodyRef.current.contains(event.target) &&
      !event.defaultPrevented
    ) {
      setFocus(false);
    }
  }

  function onResize() {
    setFocus(false);
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    window.addEventListener("resize", onResize, true);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", onResize, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, bodyRef, isFocus]);

  return (
    <div
      className={cx("b-autocomplete-dropdown", { "b-autocomplete-dropdown--has-error": !!errorMessage })}
      ref={ref}
      data-error={errorMessage}
    >
      {label && (
        <BaseLabel required={required} tooltipMessage={tooltipMessage}>
          {label}
        </BaseLabel>
      )}

      <div className={styles["b-autocomplete-dropdown__field"]}>
        <BaseInput
          sx={{ paddingRight: "26px" }}
          value={query}
          placeholder={placeholder}
          onChange={(q) => setQuery(q)}
          onFocus={() => openDropdown()}
        />
        {dropDownIcon}
      </div>

      {isFocus &&
        createPortal(
          <div
            ref={bodyRef}
            className={`${styles["b-autocomplete-dropdown__options"]} custom-scrollbar`}
            style={{
              left: position.left,
              top: position.top,
              width: position.width,
              height: position.height,
            }}
          >
            {isLoading ? (
              <div className={styles["b-autocomplete-dropdown__empty"]}>
                <Spinner size={36} />
              </div>
            ) : options.length > 0 ? (
              options.map((it) => (
                <div
                  className={styles["b-autocomplete-dropdown__options_item"]}
                  key={it[valueKey]}
                  onClick={(e) => selectOption(e, it[valueKey])}
                >
                  <div>
                    <span className="sf-text-regular color--text-primary text--primary">{it[displayKey]}</span>
                    {value && it[valueKey] === value[valueKey] ? checkedIcon() : null}
                  </div>
                </div>
              ))
            ) : query.length > 0 ? (
              <div className={styles["b-autocomplete-dropdown__empty"]}>
                <div className="color--text-placeholder sf-text-regular">{noResultsMessage}</div>
              </div>
            ) : (
              <div className={styles["b-autocomplete-dropdown__empty"]}>
                <div className="color--text-placeholder sf-text-regular">{emptyMessage}</div>
              </div>
            )}
          </div>,
          document.body
        )}
    </div>
  );
};

export default AutocompleteDropdown;
