import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface IValueLengthCounterProps {
  value: string;
  maxlength?: number;
}

const ValueLengthCounter: React.FC<IValueLengthCounterProps> = ({ value = "", maxlength = 200 }) => (
  <div className={styles.counter}>
    <span className="text--primary sf-text-regular">
      <span
        className={cx({
          counter_error: value.length > maxlength,
          counter_positive: value.length > 0 && value.length <= maxlength,
        })}
      >
        {value.length}
      </span>
      /{maxlength}
    </span>
  </div>
);

export default ValueLengthCounter;
