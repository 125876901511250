import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import { getProgressByOccId } from "@/app/store/slices/snt/myOcc/actions";
import styles from "./styles.module.scss";
import { Profile } from "@/types";

const SntMyOCCItemResultsMeetings: React.FC = () => {
  const dispatch = useAppDispatch();

  const company = useSelector((state: RootState) => state.user.company);
  const occ = useSelector((state: RootState) => state.sntMyOcc.occ);
  const progress = useSelector((state: RootState) => state.sntMyOcc.results.progress);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetch = async () => {
    setIsLoading(true);
    try {
      if (occ?.Order.Id) {
        await dispatch(getProgressByOccId(occ?.Order.Id)).unwrap();
      }
    } catch (error: any) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={styles.meeting}>
      <div>
        <h4 className="headline-h4 sf-text-bold color--text-primary">
          {company?.Type === Profile.CompanyType.SNT ? "Всего (членов садодства)" : "Всего голосов"}
        </h4>
        <div>
          <span className={`sf-bold color--text-secondary ${styles.accent_value}`}>
            {progress?.TotalVotes ?? 0}
            {company?.Type === Profile.CompanyType.UK && <span> м²</span>}
          </span>
          <span
            className={`sf-text-semibold text--primary ${styles.counter} ${
              progress?.QuorumCompleted ? styles.completed : styles.not_completed
            }`}
          >
            100%
          </span>
        </div>
      </div>
      <div>
        <h4 className="headline-h4 sf-text-bold color--text-primary">
          {company?.Type === Profile.CompanyType.SNT ? "Проголосовало" : "Приняло участие"}
        </h4>
        <div>
          <span className={`sf-bold color--text-secondary ${styles.accent_value}`}>
            {progress?.VotersNumber}
            {company?.Type === Profile.CompanyType.UK && <span> м²</span>}
          </span>
          <span
            className={`sf-text-semibold text--primary ${styles.counter} ${
              progress?.QuorumCompleted ? styles.completed : styles.not_completed
            }`}
          >
            {progress?.VotersNumberPercentage ?? 0}%
          </span>
        </div>
      </div>
      <div>
        <h4 className="headline-h4 sf-text-bold color--text-primary">Кворум собрания</h4>
        <div>
          <span
            className={`sf-bold ${styles.accent_value} ${
              progress?.QuorumCompleted ? styles.completed : styles.not_completed
            }`}
          >
            {progress?.QuorumCompleted ? "Да" : "Нет"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SntMyOCCItemResultsMeetings;
