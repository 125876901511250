import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccessSettingsService } from "@/services/v2";
import { Access, House } from "@/types";
import { RootState } from "../..";
import {
  setMyAccessRole,
  setAccessUsers,
  setPhoneForRoleModals,
  setRoleForRoleModals,
  setRoles,
  toggleAddAccessModalOpen,
  toggleCreateUserModalOpen,
  toggleErrorModalOpen,
  toggleSetUserAddressModalOpen,
  toggleSuccessModalOpen,
  removeUser,
  setHouseIdsForRoleModals,
} from ".";

export const getMyAccessRole = createAsyncThunk<Access.User, undefined>(
  "access/get-my-access-role",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await AccessSettingsService.getMyAccessRole();
      dispatch(setMyAccessRole(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAccessUsers = createAsyncThunk<Access.User[], undefined>(
  "access/fetch-users",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await AccessSettingsService.getAccessUsersList();
      await dispatch(setAccessUsers(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAccessRoles = createAsyncThunk<Access.Role[], undefined>(
  "access/fetch-roles",
  async (payload, { dispatch, rejectWithValue, getState }) => {
    const state = (getState() as RootState).access;
    if (state.roles.length > 0) {
      return state.roles;
    }

    try {
      const { data } = await AccessSettingsService.getRoles();
      await dispatch(setRoles(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setRoleForUser = createAsyncThunk<
  unknown,
  {
    Phone: string;
    Role: Access.Role["Key"];
    HouseIds?: Array<House.Item["Id"]>;
  }
>("access/set-role-for-user", async (payload, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setPhoneForRoleModals(payload.Phone));
    dispatch(setRoleForRoleModals(payload.Role));
    if (payload.HouseIds) {
      dispatch(setHouseIdsForRoleModals(payload.HouseIds));
    }
    const { data } = await AccessSettingsService.setRoleForUser(payload);
    if (data.Code === "ok") {
      await dispatch(fetchAccessUsers());
      dispatch(toggleSuccessModalOpen(true));
      dispatch(toggleAddAccessModalOpen(false));
    }
    return data.Data;
  } catch (error: any) {
    const errorData = error?.response?.data?.Data;
    switch (errorData?.ErrorCode) {
      case "user_address_not_found":
        dispatch(toggleSetUserAddressModalOpen(true));
        dispatch(toggleAddAccessModalOpen(false));
        break;

      case "user_not_found":
        dispatch(toggleCreateUserModalOpen(true));
        dispatch(toggleAddAccessModalOpen(false));
        break;

      case "other":
        return rejectWithValue(error?.response?.data);

      default:
        if (errorData?.Errors?.HouseIds) {
          return rejectWithValue(error?.response?.data);
        }

        dispatch(toggleErrorModalOpen(true));
        dispatch(toggleAddAccessModalOpen(false));
    }
  }
});

export const setAddressAndRoleForUser = createAsyncThunk<unknown, Access.SetAddressAndRoleRequestBody>(
  "access/set-address-and-role-for-user",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await AccessSettingsService.setAddressAndRoleForUser(payload);
      await dispatch(fetchAccessUsers());
      dispatch(toggleSuccessModalOpen(true));
      dispatch(toggleSetUserAddressModalOpen(false));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createUserAndSetRole = createAsyncThunk<unknown, Access.CreateUserRequestBody>(
  "access/create-user-and-set-role",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await AccessSettingsService.createUserAndSetRole(payload);
      await dispatch(fetchAccessUsers());
      dispatch(toggleSuccessModalOpen(true));
      dispatch(toggleCreateUserModalOpen(false));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeUserRole = createAsyncThunk<unknown, Access.User["Id"]>(
  "access/remove-user-role",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await AccessSettingsService.removeUserRole(payload);
      await dispatch(removeUser(payload));
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateUserRoleHouses = createAsyncThunk<
  unknown,
  { UserId: Access.User["User"]["Id"]; HouseIds: Array<House.Item["Id"]> }
>("access/update-user-role", async (payload, { dispatch, rejectWithValue }) => {
  try {
    const { data } = await AccessSettingsService.updateRoleHousesForUser(payload);
    if (data.Code === "ok") {
      await dispatch(fetchAccessUsers());
    }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data);
  }
});
