import { forwardRef } from "react";
import StyledInput from "./styled-input";
import styles from "./styles.module.scss";
import BaseLabel from "../BaseLabel";
import { IMaskInput } from "react-imask";

interface INumberInputProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  tooltipMessage?: string;
  placeholder?: string;
  errorMessage?: string;
  min?: number;
  max?: number;
  radix?: "." | ",";
  digitsAfterPoint?: number;
  thousandsSeparator?: string;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  min: number;
  max: number;
  radix: "." | ",";
  digitsAfterPoint: number;
  thousandsSeparator: string;
}

const TextMask = forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { min, max, radix, digitsAfterPoint, thousandsSeparator, onChange, ...other } = props;
  return (
    <IMaskInput
      mask={Number}
      radix={radix}
      scale={digitsAfterPoint}
      inputRef={ref}
      {...other}
      unmask={true}
      min={min ?? 0}
      max={max}
      mapToRadix={[".", ","]}
      thousandsSeparator={thousandsSeparator ?? ""}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
    />
  );
});

const NumberInput = ({
  value,
  onChange,
  label,
  type,
  disabled,
  required,
  tooltipMessage,
  placeholder,
  errorMessage,
  min = 0,
  max = 9999999.99,
  radix = ".",
  digitsAfterPoint = 2,
  thousandsSeparator = "",
}: INumberInputProps) => {
  const handleChange = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div data-error={errorMessage} className={`${styles.container} ${!!errorMessage ? styles.has_error : ""}`}>
      {label && (
        <BaseLabel required={required} tooltipMessage={tooltipMessage}>
          {label}
        </BaseLabel>
      )}

      <StyledInput
        value={value}
        placeholder={placeholder}
        error={!!errorMessage}
        type={type ?? "text"}
        fullWidth
        disabled={disabled}
        onChange={(e) => handleChange(e.target.value)}
        inputComponent={TextMask as any}
        inputProps={{
          min,
          max,
          radix,
          digitsAfterPoint,
          thousandsSeparator,
        }}
      />
    </div>
  );
};

export default NumberInput;
