export namespace SntPassportAnalytics {
  export type AnalyticsData = {
    Top: Array<Card> | null;
    Groups: Array<Group> | null;
  };

  export type Group = {
    Title: string | null;
    Cards: Array<Card>;
  };

  export enum CardType {
    RingGraphWithTotalCount = "RingGraphWithTotalCount",
    CountTotalCountPercent = "CountTotalCountPercent",
    Count = "Count",
    CountPercent = "CountPercent",
    WorkScopeArraySum = "WorkScopeArraySum",
  }

  export type Card =
    | CardRingGraphWithTotalCount
    | CardCountTotalCountPercent
    | CardCount
    | CardCountPercent
    | CardWorkScopeArraySum;

  export type CardBase = {
    Type: CardType;
    Title: string;
  };

  export type CardRingGraphWithTotalCount = CardBase & {
    Type: CardType.RingGraphWithTotalCount;
    Count: string | null;
    TotalCount: number | null;
    Values: Array<{ Title: string; Value: number }>;
  };

  export type CardCountTotalCountPercent = CardBase & {
    Type: CardType.CountTotalCountPercent;
    Count?: string | null;
    TotalCount: string | null;
    Percent: string | null;
  };

  export type CardCount = CardBase & {
    Type: CardType.Count;
    Count: string | null;
  };

  export type CardCountPercent = CardBase & {
    Type: CardType.CountPercent;
    Count: string | null;
    Percent: string | null;
  };

  export type CardWorkScopeArraySum = CardBase & {
    Type: CardType.WorkScopeArraySum;
    Sum: number | null;
    IsSummary: boolean | null;
    WorkScopes: Array<{ Title: string | null; Value: number | null }>;
  };
}
