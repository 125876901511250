import { Profile, ResponseAbstract, UsefulContacts } from "../../types";
import client from "./config";

export namespace UsefulContactsService {
  export function getUsefulCompanyContacts() {
    return client.get<ResponseAbstract<UsefulContacts.Item[]>>("/api/user/useful-company-contact");
  }

  export function createUsefulCompanyContact(data: UsefulContacts.CreateContactBody) {
    return client.post<ResponseAbstract<UsefulContacts.Item>>("/api/user/useful-company-contact", data);
  }

  export function updateUsefulCompanyContact(data: UsefulContacts.UpdateContactBody) {
    return client.patch<ResponseAbstract<UsefulContacts.Item>>("/api/user/useful-company-contact", data);
  }

  export function updateUserCompanyData(data: UsefulContacts.UpdateMyContactsBody) {
    return client.patch<ResponseAbstract<Profile.CompanyData>>("/api/user/company/update-data", data);
  }

  export function deleteUsefulCompanyContacts(Id: UsefulContacts.UsefulContactId) {
    return client.delete<unknown>("/api/user/useful-company-contact", { params: { Id } });
  }
}
