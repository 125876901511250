import React, { useContext, useMemo } from "react";
import AccordionContext from "./context";
import classNames from "classnames/bind";
import styles from "./accordion.module.scss";

const cx = classNames.bind(styles);

interface ICustomCollapsedProps {
  children: React.ReactNode;
  id: string | number;
  className?: string;
  upAndDownIconAnimation?: boolean;
}

const CustomCollapsed: React.FC<ICustomCollapsedProps> = ({ children, id, className, upAndDownIconAnimation }) => {
  const context = useContext(AccordionContext);

  const isOpen: boolean = useMemo(() => {
    return id === context?.opened;
  }, [context?.opened, id]);

  const handleToggle = (event: React.SyntheticEvent) => {
    if (context?.handleOpen) {
      context?.handleOpen(id);
    }
  };

  return (
    <div
      className={cx(className, {
        custom_collapsed: true,
        opened: isOpen,
        "up-down-transtition": upAndDownIconAnimation,
      })}
      onClick={handleToggle}
      role="button"
    >
      {children}
    </div>
  );
};

export default CustomCollapsed;
