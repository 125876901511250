import { useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";
import { RootState } from "@/app/store";
import { paths } from "@/consts/routes";
import { selectStatusById } from "@/app/store/slices/dictionary";
import styles from "./styles.module.scss";
import { Dictionary } from "@/types";

const SntMyOCCItemResults: React.FC = () => {
  const occ = useSelector((state: RootState) => state.sntMyOcc.occ);
  const status = useSelector(selectStatusById(occ?.Order.StatusId));

  const isStarted = useMemo(() => {
    return (
      status &&
      [
        Dictionary.OCCStatus.OccIsComingDecisionMaking,
        Dictionary.OCCStatus.OccIsComingProtocolFormation,
        Dictionary.OCCStatus.CompletedProtocolPrepared,
      ].includes(status?.key)
    );
  }, [status]);

  return (
    <div className={styles.results}>
      {!isStarted ? (
        <div className={styles.not_started}>
          <h3 className="headline-h3 sf-bold">Голосование еще не началось</h3>
        </div>
      ) : (
        <>
          <div className={styles.tabs}>
            <div className="flex">
              <div className={styles.tabs_wrapper}>
                <NavLink
                  className={({ isActive }) => (isActive ? styles.active_tab : undefined)}
                  to={paths.myOccById(occ?.Order.Id).resultsMeeting}
                >
                  <span className="text--primary sf-text-medium">Собрание</span>
                </NavLink>

                <NavLink
                  className={({ isActive }) => (isActive ? styles.active_tab : undefined)}
                  to={paths.myOccById(occ?.Order.Id).resultsQuestions}
                >
                  <span className="text--primary sf-text-medium">Вопросы</span>
                </NavLink>

                <NavLink
                  className={({ isActive }) => (isActive ? styles.active_tab : undefined)}
                  to={paths.myOccById(occ?.Order.Id).resultsOwners}
                >
                  <span className="text--primary sf-text-medium">Собственники</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className={styles.content}>
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
};

export default SntMyOCCItemResults;
