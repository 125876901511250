import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";

import router from "./router";
// import reportWebVitals from "./reportWebVitals";
import { theme } from "./theme/mainTheme";
import store from "./app/store";
import { Provider } from "react-redux";
import "@/extensions/date";
import "@/extensions/number";
import "@/extensions/string";
import "./assets/styles/common.scss";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </Provider>
);
