import { SntPassportEnums } from "../enums";

export namespace SntPassportAgricultureMinistry {
  export type WorkTypeBase = {
    BasisDocumentName: string | null;
    WorkCost: number | null;
    Note: string | null;
  };

  export type RoadRepairWorkType = WorkTypeBase & {
    RoadLength: number | null;
    SCDF: number | null;
    SurfaceTypeKey: SntPassportEnums.RoadSurfaceType | null;
    SurfaceTypeKeyOther: string | null;
    WorkScope: number | null;
  };

  export type ElectricitySupplyRepairWorkType = WorkTypeBase & {
    LEPWorkScope: number | null;
    KTPWorkScope: number | null;
  };

  export type WaterSupplyRepairWorkType = WorkTypeBase & {
    WellWorkScope: number | null;
    ReservoirWorkScope: number | null;
    PumpWorkScope: number | null;
    WaterPipesWorkScope: number | null;
  };

  export type TKORepairWorkType = WorkTypeBase & {
    TKOAreaWorkScope: number | null;
    BunkerWorkScope: number | null;
    ContainerWorkScope: number | null;
  };

  export type OtherWorkType = WorkTypeBase & {
    Name: string | null;
    WorkScope: number | null;
  };

  export type ExternalInfrastructure = {
    [SntPassportEnums.ExternalInfrastructureWorkType.ElectricitySupplyRepair]?: ElectricitySupplyRepairWorkType | null;
    [SntPassportEnums.ExternalInfrastructureWorkType.TKORepair]?: TKORepairWorkType | null;
    [SntPassportEnums.ExternalInfrastructureWorkType.WaterSupplyRepair]?: WaterSupplyRepairWorkType | null;
    [SntPassportEnums.ExternalInfrastructureWorkType.RoadRepair]?: RoadRepairWorkType | null;
    [SntPassportEnums.ExternalInfrastructureWorkType.Other]?: OtherWorkType | null;
  };

  export type InternalElectricitySupplyRepairWorkType = WorkTypeBase & {
    LEPWorkScope: number | null;
    KTPWorkScope: number | null;
    ASKUEWorkScope: number | null;
  };

  export type InnerRoadRepairWorkType = WorkTypeBase & {
    WorkScope: number | null;
    SurfaceTypeKey: SntPassportEnums.RoadSurfaceType | null;
    SurfaceTypeKeyOther: string | null;
  };

  export type InternalWaterSupplyRepairWorkType = WorkTypeBase & {
    WorkScope: number | null;
  };

  export type InternalInfrastructure = {
    [SntPassportEnums.InternalInfrastructureWorkType
      .ElectricitySupplyRepair]?: InternalElectricitySupplyRepairWorkType | null;
    [SntPassportEnums.InternalInfrastructureWorkType.InnerRoadRepair]?: InnerRoadRepairWorkType | null;
    [SntPassportEnums.InternalInfrastructureWorkType.WaterSupplyRepair]?: InternalWaterSupplyRepairWorkType | null;
    [SntPassportEnums.InternalInfrastructureWorkType.Other]?: OtherWorkType | null;
  };

  export type AgricultureMinistryData = {
    ExternalInfrastructure: ExternalInfrastructure | null;
    InternalInfrastructure: InternalInfrastructure | null;
  };

  export type AgricultureMinistryDataResponse = {
    PublicationDatetime: number;
    Form: AgricultureMinistryData;
  };
}
