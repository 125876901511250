import BaseModal, { BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import { useAppDispatch } from "@/app/store";
import { deleteGroupById } from "@/app/store/slices/templates/actions";
import { useState } from "react";
import { Templates } from "@/types";

interface IDeleteGroupModalProps {
  isOpen: boolean;
  group: Templates.Group;
  onClose: () => void;
}

const DeleteGroupModal = ({ isOpen, group, onClose }: IDeleteGroupModalProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
  };

  const deleteGroup = async () => {
    setLoading(true);
    try {
      await dispatch(deleteGroupById(group.Id)).unwrap();
      onClose();
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal isOpen={isOpen} minWidth="426px">
      <BaseModalHeader title="Удалить группу шаблонов?" onClose={handleClose} />

      <BaseModalContent>
        <p className="sf-text-regular text--primary">Шаблоны внутри группы также будут удалены</p>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton variant="secondary" onClick={onClose}>
          Не хочу удалять
        </BaseButton>
        <BaseButton variant="secondary" appearance="negative" isLoading={isLoading} onClick={deleteGroup}>
          Удалить
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default DeleteGroupModal;
