import { ResponseAbstract, SntPassportMap, SntRegistry } from "@/types";
import client from "../config";

export namespace InstitutionSntMapService {
  export function getList() {
    return client.get<ResponseAbstract<SntPassportMap.SntItem[]>>("/api/user/institution/snt-map/get");
  }

  export function updateSntGeopoint(SntId: SntRegistry.SntDetails["Id"], Geopoint: SntPassportMap.Geopoint) {
    return client.post<ResponseAbstract<SntPassportMap.SntItem>>("/api/user/institution/snt-map/update", {
      SntId,
      Geopoint,
    });
  }

  export function deleteSntGeopoint(SntId: SntRegistry.SntDetails["Id"]) {
    return client.delete<ResponseAbstract<SntPassportMap.SntItem>>("/api/user/institution/snt-map/delete", {
      params: { SntId },
    });
  }
}
