import { requiredIcon } from "@/consts/icons";
import BaseTooltip from "@/components/base-tooltip";
import styles from "./styles.module.scss";

interface IBaseLabelProps {
  children: React.ReactNode;
  required?: boolean;
  className?: string;
  tooltipMessage?: string;
}

const BaseLabel = ({ children, required, className, tooltipMessage }: IBaseLabelProps) => {
  return (
    <div className={[styles.root, className].join(" ")}>
      <label className="sf-text-semibold color--text-primary">{children}</label>
      {required && requiredIcon}
      {tooltipMessage && (
        <BaseTooltip title={tooltipMessage}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 0C12.415 0 16 3.585 16 8C16 12.415 12.415 16 8 16C3.585 16 0 12.415 0 8C0 3.585 3.585 0 8 0ZM6.964 11.478C6.964 12.041 7.422 12.499 7.985 12.499C8.548 12.499 9.006 12.041 9.006 11.478C9.006 10.915 8.548 10.457 7.985 10.457C7.422 10.457 6.964 10.915 6.964 11.478ZM8.116 3.521C6.388 3.521 5.392 4.974 5.392 5.681C5.392 5.995 5.563 6.493 6.126 6.493C6.558 6.493 6.846 6.179 6.859 5.904C6.898 5.629 7.186 5 8.064 5C8.692 5 9.268 5.445 9.268 5.995C9.268 7.095 7.121 7.183 7.121 8.807C7.121 9.357 7.501 9.75 7.972 9.75C8.469 9.75 8.849 9.357 8.849 8.859C8.849 7.979 10.918 7.841 10.918 5.956C10.918 4.581 9.648 3.521 8.116 3.521Z"
              fill="#B4BAC2"
            />
          </svg>
        </BaseTooltip>
      )}
    </div>
  );
};

export default BaseLabel;
