import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { Profile } from "@/types";
import { paths } from "@/consts/routes";

import BaseLayout from "@/pages/BaseLayout";

import Authorization from "@/pages/authorization";

import UKRegistry from "@/pages/UK/Registry";
import UKRegistryMain from "@/pages/UK/Registry/Main";
import UKRegistryRosreestr from "@/pages/UK/Registry/Rosreestr";
import UKRegistryAnalytics from "@/pages/UK/Registry/Analytics";
import UKGisZhkh from "@/pages/UK/GisZhkh";

import UKCreateOCC from "@/pages/UK/CreateOcc";
import UKCreateOCCLayout from "@/pages/UK/CreateOcc/_occId";
import UKCreateOccCreatingStep from "@/pages/UK/CreateOcc/_occId/CreateOcc";
import UKCreateOCCCreateAgendaStep from "@/pages/UK/CreateOcc/_occId/CreateAgenda";
import UKCreateOCCPreviewStep from "@/pages/UK/CreateOcc/_occId/Preview";
import UKCreateOCCSetAuthorStep from "@/pages/UK/CreateOcc/_occId/SetAuthor";
import UKCreateOCCSetAdministratorStep from "@/pages/UK/CreateOcc/_occId/SetAdministrator";
import UKCreateOCCFinishStep from "@/pages/UK/CreateOcc/_occId/Finish";

import Houses from "@/pages/Houses";
import Contacts from "@/pages/contacts";
import SntLandRegistry from "@/pages/snt/registry";

import SntPassportLayout from "@/pages/snt/Passport";
import SntPassportBoard from "@/pages/snt/Passport/Board";
import SntPassportAgricultureMinistry from "@/pages/snt/Passport/AgricultureMinistry";

import SntMyOCC from "@/pages/snt/my-occ";
import SntMyOCCItemLayout from "@/pages/snt/my-occ/_occId/layout";
import SntMyOCCItemDetails from "@/pages/snt/my-occ/_occId/details";
import SntMyOCCItemResults from "@/pages/snt/my-occ/_occId/results";
import SntMyOCCItemResultsMeetings from "@/pages/snt/my-occ/_occId/results/meeting";
import SntMyOCCItemResultsQuestions from "@/pages/snt/my-occ/_occId/results/questions";
import SntMyOCCItemResultsOwners from "@/pages/snt/my-occ/_occId/results/owners";
import SntMyOCCItemQuestions from "@/pages/snt/my-occ/_occId/questions";
import SntMyOCCItemEvents from "@/pages/snt/my-occ/_occId/events";
import SntMyOCCItemDocuments from "@/pages/snt/my-occ/_occId/documents";
import SntMyOCCItemDecision from "@/pages/snt/my-occ/_occId/decision";
import SntMyOCCItemDecisionOwner from "@/pages/snt/my-occ/_occId/decision/_ownerId";

import SntCreateOCC from "@/pages/snt/create-occ";
import SntCreateOCCLayout from "@/pages/snt/create-occ/_occId";
import SntCreateOccCreatingStep from "@/pages/snt/create-occ/_occId/CreateOcc";
import SntCreateOCCCreateAgendaStep from "@/pages/snt/create-occ/_occId/CreateAgenda";
import SntCreateOCCPreviewStep from "@/pages/snt/create-occ/_occId/Preview";
import SntCreateOCCFinishStep from "@/pages/snt/create-occ/_occId/Finish";

import InstitutionLayout from "@/pages/Institution";
import InstitutionSntList from "@/pages/Institution/SntList";
import InstitutionSntItem from "@/pages/Institution/SntList/_SntId";
import InstitutionSntItemDetails from "@/pages/Institution/SntList/_SntId/Details";
import InstitutionSntItemBoard from "@/pages/Institution/SntList/_SntId/Details/Board";
import InstitutionSntItemAgricultureMinistry from "@/pages/Institution/SntList/_SntId/Details/AgricultureMinistry";
import InstitutionSntItemSummary from "@/pages/Institution/SntList/_SntId/Summary";
import InstitutionAnalytics from "@/pages/Institution/Analytics";
import InstitutionAnalyticsStateSupport from "@/pages/Institution/Analytics/StateSupport";
import InstitutionAnalyticsInfrastructure from "@/pages/Institution/Analytics/Infrastructure";
import InstitutionSntsMap from "@/pages/Institution/Map";

import Settings from "@/pages/settings";
import UsefulDocuments from "@/pages/UsefulDocuments";
import AccessSettings from "@/pages/settings/AccessSettings";
import CadastralNumber from "@/pages/settings/CadastralNumber";
import ManagementTemplate from "@/pages/management-template";
import LokoloUsers from "@/pages/LokoloUsers";
import App from "../app/App";
import ProtectedRoute from "./ProtectedRoute";
import RootRedirect from "./RootRedirect";
import SntRedirect from "./SntRedirect";

const router = createBrowserRouter([
  {
    path: paths.authorization,
    Component: Authorization,
  },
  {
    id: "root",
    path: "/",
    Component: App,
    children: [
      {
        path: "",
        Component: RootRedirect,
      },

      // INSTITUTION START
      {
        path: paths.institution().root,
        element: (
          <ProtectedRoute role={Profile.UserRole.Institution}>
            <InstitutionLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: "",
            element: <Navigate to={paths.institution().sntsList} replace={true} />,
          },
          {
            path: paths.institution().sntsList,
            element: <Outlet />,
            children: [
              {
                path: "",
                Component: InstitutionSntList,
              },
              {
                path: paths.institution().analytics,
                Component: InstitutionAnalytics,
                children: [
                  {
                    path: "",
                    element: <Navigate to={paths.institution().analyticsStateSupport} replace={true} />,
                  },
                  {
                    path: paths.institution().analyticsStateSupport,
                    Component: InstitutionAnalyticsStateSupport,
                  },
                  {
                    path: paths.institution().analyticsInfrastructure,
                    Component: InstitutionAnalyticsInfrastructure,
                  },
                ],
              },
              {
                path: paths.institution().sntItem,
                Component: InstitutionSntItem,
                children: [
                  {
                    path: paths.institution().sntItemSummary,
                    Component: InstitutionSntItemSummary,
                  },
                  {
                    path: "",
                    Component: InstitutionSntItemDetails,
                    children: [
                      {
                        path: "",
                        element: <Navigate to={paths.institution().sntItemAgricultureMinistry} replace={true} />,
                      },
                      {
                        path: paths.institution().sntItemAgricultureMinistry,
                        Component: InstitutionSntItemAgricultureMinistry,
                      },
                      {
                        path: paths.institution().sntItemBoard,
                        Component: InstitutionSntItemBoard,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: paths.institution().map,
            Component: InstitutionSntsMap,
          },
        ],
      },
      // INSTITUTION END

      {
        path: "",
        element: (
          <ProtectedRoute role={Profile.UserRole.Company}>
            <BaseLayout />
          </ProtectedRoute>
        ),
        children: [
          // UK START
          {
            path: paths.uk().root,
            element: (
              <ProtectedRoute role={Profile.UserRole.Company} companyType={Profile.CompanyType.UK}>
                <Outlet />
              </ProtectedRoute>
            ),
            children: [
              {
                path: "",
                element: <Navigate to={paths.uk().registry.subroot} replace={true} />,
              },
              {
                path: `${paths.uk().registry.subroot}/${paths.uk().registry.analytics}`,
                Component: UKRegistryAnalytics,
              },
              {
                path: paths.uk().registry.subroot,
                Component: UKRegistry,
                children: [
                  {
                    path: "",
                    element: <Navigate to={paths.uk().registry.main} replace={true} />,
                  },
                  {
                    path: paths.uk().registry.main,
                    Component: UKRegistryMain,
                  },
                  {
                    path: paths.uk().registry.rosreestr,
                    Component: UKRegistryRosreestr,
                  },
                ],
              },
              {
                path: paths.uk().houses,
                Component: Houses,
              },
              {
                path: paths.uk().cadastralNumber,
                Component: CadastralNumber,
              },
              {
                path: paths.uk().giszhkh,
                Component: UKGisZhkh,
              },

              {
                path: paths.uk().createOCC.subroot,
                Component: UKCreateOCC,
              },
              {
                path: paths.uk().createOCC.item.layout,
                Component: UKCreateOCCLayout,
                children: [
                  {
                    path: "",
                    element: <Navigate to={paths.uk().createOCC.item.create} replace={true} />,
                  },
                  {
                    path: paths.uk().createOCC.item.create,
                    Component: UKCreateOccCreatingStep,
                  },
                  {
                    path: paths.uk().createOCC.item.author,
                    Component: UKCreateOCCSetAuthorStep,
                  },
                  {
                    path: paths.uk().createOCC.item.administrator,
                    Component: UKCreateOCCSetAdministratorStep,
                  },
                  {
                    path: paths.uk().createOCC.item.agenda,
                    Component: UKCreateOCCCreateAgendaStep,
                  },
                  {
                    path: paths.uk().createOCC.item.preview,
                    Component: UKCreateOCCPreviewStep,
                  },
                  {
                    path: paths.uk().createOCC.item.finish,
                    Component: UKCreateOCCFinishStep,
                  },
                ],
              },
            ],
          },
          // UK END

          // SNT START
          {
            path: paths.snt().root,
            element: (
              <ProtectedRoute role={Profile.UserRole.Company} companyType={Profile.CompanyType.SNT}>
                <Outlet />
              </ProtectedRoute>
            ),
            children: [
              {
                path: "",
                element: <Navigate to="/snt/registry" />,
              },
              {
                path: "registry",
                Component: SntLandRegistry,
              },
              {
                path: "my-occ",
                element: <Navigate to={paths.myOcc} />,
              },
              {
                path: "my-occ/*",
                Component: SntRedirect,
              },
              {
                path: "create-occ",
                Component: SntCreateOCC,
              },
              {
                path: "create-occ/:occId",
                Component: SntCreateOCCLayout,
                children: [
                  // {
                  //   path: "",
                  //   element: <Navigate to="create" replace={true} />,
                  // },
                  {
                    path: "create",
                    Component: SntCreateOccCreatingStep,
                  },
                  {
                    path: "agenda",
                    Component: SntCreateOCCCreateAgendaStep,
                  },
                  {
                    path: "preview",
                    Component: SntCreateOCCPreviewStep,
                  },
                  {
                    path: "finish",
                    Component: SntCreateOCCFinishStep,
                  },
                ],
              },
              {
                path: paths.snt().passport.root,
                Component: SntPassportLayout,
                children: [
                  {
                    path: "",
                    element: <Navigate to={paths.snt().passport.main} replace={true} />,
                  },
                  {
                    path: paths.snt().passport.main,
                    Component: SntPassportBoard,
                  },
                  {
                    path: paths.snt().passport.minselhoz,
                    Component: SntPassportAgricultureMinistry,
                  },
                  {
                    path: "*",
                    element: <Navigate to={paths.snt().passport.main} replace={true} />,
                  },
                ],
              },
            ],
          },
          // SNT END

          {
            path: paths.settings,
            Component: Settings,
          },
          {
            path: paths.contacts,
            Component: Contacts,
          },
          {
            path: paths.documents,
            Component: UsefulDocuments,
          },
          {
            path: paths.users,
            Component: LokoloUsers,
          },
          {
            path: paths.managementTemplate,
            Component: ManagementTemplate,
          },
          {
            path: paths.accessSettings,
            Component: AccessSettings,
          },

          // myOcc
          {
            path: paths.myOcc,
            Component: SntMyOCC,
          },
          {
            path: paths.myOccById().decision,
            Component: SntMyOCCItemDecision,
          },
          {
            path: paths.myOccById().decisionByOwner,
            Component: SntMyOCCItemDecisionOwner,
          },
          {
            path: paths.myOccById().index,
            Component: SntMyOCCItemLayout,
            children: [
              {
                path: "",
                element: <Navigate to="details" replace={true} />,
              },
              {
                path: "details",
                Component: SntMyOCCItemDetails,
              },
              {
                path: "results",
                Component: SntMyOCCItemResults,
                children: [
                  {
                    path: "",
                    element: <Navigate to="meeting" replace={true} />,
                  },
                  {
                    path: "meeting",
                    Component: SntMyOCCItemResultsMeetings,
                  },
                  {
                    path: "questions",
                    Component: SntMyOCCItemResultsQuestions,
                  },
                  {
                    path: "owners",
                    Component: SntMyOCCItemResultsOwners,
                  },
                ],
              },
              {
                path: "questions",
                Component: SntMyOCCItemQuestions,
              },
              {
                path: "events",
                Component: SntMyOCCItemEvents,
              },
              {
                path: "documents",
                Component: SntMyOCCItemDocuments,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <div>404</div>,
      },
    ],
  },
]);

export default router;
