import { useSelector } from "react-redux";
import Chart from "@/components/Chart";
import { RootState } from "@/app/store";
import BaseTooltip from "@/components/base-tooltip";
import styles from "./styles.module.scss";

interface IChartProps {
  name: string;
  data: number[];
  labels: string[];
  colors: string[];
  legendBgColors: string[];
  fractionDigits?: number;
  tooltipMessage?: string;
}

const AnalyticsChart: React.FC<IChartProps> = ({
  name,
  data,
  labels,
  colors,
  legendBgColors,
  fractionDigits = 0,
  tooltipMessage,
}) => {
  const { details, apartments, isLoading } = useSelector((state: RootState) => state.ukRegistry);

  return (
    <div className={styles["b-chart-card"]}>
      <div className={styles["b-chart-card--header"]}>
        <h4 className="headline-h4 sf-text-bold">{name}</h4>
        {tooltipMessage && details && apartments.length > 0 && !isLoading && (
          <BaseTooltip title={tooltipMessage}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0C12.415 0 16 3.585 16 8C16 12.415 12.415 16 8 16C3.585 16 0 12.415 0 8C0 3.585 3.585 0 8 0ZM6.964 11.478C6.964 12.041 7.422 12.499 7.985 12.499C8.548 12.499 9.006 12.041 9.006 11.478C9.006 10.915 8.548 10.457 7.985 10.457C7.422 10.457 6.964 10.915 6.964 11.478ZM8.116 3.521C6.388 3.521 5.392 4.974 5.392 5.681C5.392 5.995 5.563 6.493 6.126 6.493C6.558 6.493 6.846 6.179 6.859 5.904C6.898 5.629 7.186 5 8.064 5C8.692 5 9.268 5.445 9.268 5.995C9.268 7.095 7.121 7.183 7.121 8.807C7.121 9.357 7.501 9.75 7.972 9.75C8.469 9.75 8.849 9.357 8.849 8.859C8.849 7.979 10.918 7.841 10.918 5.956C10.918 4.581 9.648 3.521 8.116 3.521Z"
                fill="#A8ACB1"
              />
            </svg>
          </BaseTooltip>
        )}
      </div>

      <Chart
        data={data}
        total={parseFloat(Number(data.reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(fractionDigits))}
        colors={colors}
        legendBgColors={legendBgColors}
        labels={labels}
      />
    </div>
  );
};

export default AnalyticsChart;
