import { useContext } from "react";
import classNames from "classnames/bind";
import Checkbox from "@/components/checkbox";
import MultipleSelectContext, { SelectMenuItem } from "./context";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IMultipleSelectMenuItemProps {
  children?: JSX.Element | JSX.Element[];
  value: SelectMenuItem["value"];
  className?: string;
  defaultHover?: boolean;
}

const MultipleSelectMenuItem = ({ children, value, className, defaultHover }: IMultipleSelectMenuItemProps) => {
  const context = useContext(MultipleSelectContext);

  function onClick(event: React.SyntheticEvent) {
    event.stopPropagation();
    event.preventDefault();
    context?.onSelect(value);
  }

  return (
    <div
      className={cx(className, {
        menu_item: true,
        selected: context?.value.includes(value),
        default_hover: defaultHover,
      })}
      role="button"
      onClick={onClick}
    >
      <Checkbox checked={context?.value.includes(value)} />
      {children}
    </div>
  );
};

export default MultipleSelectMenuItem;
