import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Dictionary, UKRegistry } from "@/types";
import { UKRegistryService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { activeAddress } from "@/app/store/slices/user";
import {
  toggleApartmentСomparisonModal,
  toggleSizeSharesErrorModal,
  updateApartmentsComparisonOnBackground,
} from "@/app/store/slices/uk/rosreestr";
import { fetchApartmentOwnerships } from "@/app/store/slices/dictionary/actions";
import { SnackbarType, showSnackbar } from "@/app/store/slices/snackbar";
import { BaseModal, BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import { Spinner } from "@/components/spinner";
import { Accordion } from "@/components/base-accordion";
import BaseButton from "@/components/base-button";
import ApartmentDetailsComparison from "./ApartmentDetailsComparison";
import OwnerDetailsСomparison from "./OwnerDetailsСomparison";
import styles from "./styles.module.scss";

const ApartmentСomparisonModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const address = useSelector(activeAddress);
  const { isShown, comparisonId } = useSelector((state: RootState) => state.ukRosreestr.apartmentСomparisonModal);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSaveLoading, setSaveLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<UKRegistry.RosreestrСomparisonDetailsMapped | null>(null);

  const saveBody: UKRegistry.SaveApartmentComparisonChangesRequestBody | null = useMemo(() => {
    if (!address || !details) {
      return null;
    }

    let body: UKRegistry.SaveApartmentComparisonChangesRequestBody = {
      HouseFiasId: address.FiasId,
      Type: details.apartment.isCreated
        ? UKRegistry.SaveApartmentComparisonChangesType.Create
        : details.apartment.isDeleted
        ? UKRegistry.SaveApartmentComparisonChangesType.Delete
        : UKRegistry.SaveApartmentComparisonChangesType.Change,
    };

    if (
      details.apartment.initial.Reestr &&
      details.apartment.initial.Rosreestr &&
      details.apartment.value &&
      Object.keys(details.apartment.value).some(
        (x) =>
          details.apartment.value &&
          details.apartment.initial.Reestr &&
          details.apartment.value[x as keyof UKRegistry.ApartmentСomparison] !==
            details.apartment.initial.Reestr[x as keyof UKRegistry.ApartmentСomparison]
      )
    ) {
      body["ApartmentFields"] = Object.keys(details.apartment.value).filter(
        (x) =>
          details.apartment.value &&
          details.apartment.initial.Reestr &&
          details.apartment.value[x as keyof UKRegistry.ApartmentСomparison] !==
            details.apartment.initial.Reestr[x as keyof UKRegistry.ApartmentСomparison]
      ) as Array<keyof UKRegistry.ApartmentСomparison>;
    }

    const createdOwners = details.owners.filter((it) => it.isCreated && it.initial.Rosreestr);
    if (createdOwners.length > 0) {
      body["OwnersCreate"] = createdOwners.map((it) => (it.initial.Rosreestr as UKRegistry.OwnerСomparison).Id);
    }

    const deletedOwners = details.owners.filter((it) => it.isDeleted && it.initial.Reestr);
    if (deletedOwners.length > 0) {
      body["OwnersDelete"] = deletedOwners.map((it) => (it.initial.Reestr as UKRegistry.OwnerСomparison).Id);
    }

    details.owners.forEach((it) => {
      if (
        it.initial.Reestr &&
        it.initial.Rosreestr &&
        it.value &&
        Object.keys(it.value).some(
          (x) =>
            it.value &&
            it.initial.Reestr &&
            x !== "Id" &&
            it.value[x as keyof UKRegistry.OwnerСomparison] !== it.initial.Reestr[x as keyof UKRegistry.OwnerСomparison]
        )
      ) {
        const change = {
          ReestrId: it.initial.Reestr.Id,
          RosreestrId: it.initial.Rosreestr.Id,
          Fields: Object.keys(it.value).filter(
            (x) =>
              it.value &&
              it.initial.Reestr &&
              x !== "Id" &&
              it.value[x as keyof UKRegistry.OwnerСomparison] !==
                it.initial.Reestr[x as keyof UKRegistry.OwnerСomparison]
          ) as Array<keyof UKRegistry.OwnerСomparison>,
        };
        if (body["OwnersChange"]) {
          body["OwnersChange"].push(change);
        } else {
          body["OwnersChange"] = [change];
        }
      }
    });

    return body;
  }, [details, address]);

  const isEnabled: boolean | undefined = useMemo(() => {
    return (
      saveBody?.Type === UKRegistry.SaveApartmentComparisonChangesType.Create ||
      saveBody?.Type === UKRegistry.SaveApartmentComparisonChangesType.Delete ||
      (saveBody?.ApartmentFields && saveBody?.ApartmentFields?.length > 0) ||
      (saveBody?.OwnersCreate && saveBody?.OwnersCreate?.length > 0) ||
      (saveBody?.OwnersDelete && saveBody?.OwnersDelete?.length > 0) ||
      (saveBody?.OwnersChange && saveBody?.OwnersChange?.length > 0)
    );
  }, [saveBody]);

  function handleClose() {
    dispatch(toggleApartmentСomparisonModal({ isShown: false }));
  }

  function onApartmentAdd() {
    setDetails((prevState) => {
      if (!prevState) {
        return null;
      }

      return {
        ...prevState,
        apartment: {
          ...prevState.apartment,
          isCreated: !prevState?.apartment.isCreated,
        },
      };
    });
  }

  function onApartmentRemove() {
    setDetails((prevState) => {
      if (!prevState) {
        return null;
      }

      return {
        ...prevState,
        apartment: {
          ...prevState.apartment,
          isDeleted: !prevState?.apartment.isDeleted,
        },
      };
    });
  }

  function updateApartmentData(key: keyof UKRegistry.ApartmentСomparison, updating: boolean) {
    if (!details) {
      return;
    }

    setDetails((prevState) => {
      if (!prevState) {
        return null;
      }

      return {
        ...prevState,
        apartment: {
          ...prevState.apartment,
          value: {
            ...prevState.apartment.value,
            [key]:
              updating && prevState.apartment.initial.Rosreestr
                ? prevState.apartment.initial.Rosreestr[key]
                : prevState.apartment.initial.Reestr
                ? prevState.apartment.initial.Reestr[key]
                : "",
          },
        },
      } as any;
    });
  }

  function onOwnerAdd(index: number) {
    setDetails((prevState) => {
      if (!prevState) {
        return null;
      }

      return {
        ...prevState,
        owners: prevState.owners.map((it, i) => {
          if (i === index) {
            return {
              ...it,
              isCreated: !it.isCreated,
            };
          }

          return it;
        }),
      };
    });
  }

  function onOwnerRemove(index: number) {
    setDetails((prevState) => {
      if (!prevState) {
        return null;
      }

      return {
        ...prevState,
        owners: prevState.owners.map((it, i) => {
          if (i === index) {
            return {
              ...it,
              isDeleted: !it.isDeleted,
            };
          }

          return it;
        }),
      };
    });
  }

  function updateOwnerData(index: number, key: keyof UKRegistry.OwnerСomparison, updating: boolean) {
    if (!details) {
      return;
    }

    setDetails((prevState) => {
      if (!prevState) {
        return null;
      }

      return {
        ...prevState,
        owners: prevState.owners.map((it, i) => {
          const comparision = prevState.owners[index].initial;
          if (i === index && comparision.Reestr && comparision.Rosreestr) {
            return {
              ...it,
              value: {
                ...it.value,
                [key]: updating ? comparision.Rosreestr[key] : comparision.Reestr[key],
              },
            };
          }

          return it;
        }),
      } as any;
    });
  }

  async function handleSubmit() {
    setSaveLoading(true);

    try {
      if (!comparisonId || !saveBody) {
        throw Error("no comparison id");
      }

      const totalSizeShares = details?.owners.reduce((prev, curr) => {
        if (!curr.value?.SizeShares) {
          return prev + 0;
        }

        if (isNaN(Number(curr.value.SizeShares)) && curr.value.SizeShares.includes("/")) {
          const [first, second] = curr.value.SizeShares.split("/");
          return prev + Number(first) / Number(second);
        }

        return prev + Number(curr.value.SizeShares);
      }, 0);

      const isNoJointOwnership = async () => {
        const ownershipsList = await dispatch(fetchApartmentOwnerships()).unwrap();
        const jointOwnership = ownershipsList.find(
          (it) =>
            it.ShortName === Dictionary.ApartmentOwnership.JointOwnership ||
            it.Name === Dictionary.ApartmentOwnership.JointOwnership
        );

        return !details?.owners.some(
          (it) =>
            it.value?.TypeOwnership === jointOwnership?.Name || it.value?.TypeOwnership === jointOwnership?.ShortName
        );
      };

      if ((await isNoJointOwnership()) && totalSizeShares && totalSizeShares > 1) {
        dispatch(toggleSizeSharesErrorModal(true));
        return;
      }

      await UKRegistryService.saveApartmentComparisonChanges(comparisonId, saveBody);
      await dispatch(updateApartmentsComparisonOnBackground()).unwrap();
      handleClose();
    } catch (error: any) {
      const errorsData = error?.response?.data?.Data?.Errors;
      if (errorsData && errorsData.length > 0) {
        Object.keys(errorsData).forEach((key: any) => {
          const message = Array.isArray(errorsData[key]) ? errorsData[key][0] : errorsData[key];
          switch (key) {
            case "SizeShares": {
              dispatch(toggleSizeSharesErrorModal(true));
              break;
            }

            default: {
              dispatch(
                showSnackbar({
                  key: "save-apartment-comparison-changes",
                  body: message ?? "Неизвестная ошибка. Попробуйте позже еще раз",
                  type: SnackbarType.ERROR,
                })
              );
              break;
            }
          }
        });
      } else {
        dispatch(
          showSnackbar({
            key: "save-apartment-comparison-changes",
            body: error?.response?.data?.Data?.Message ?? "Неизвестная ошибка. Попробуйте позже еще раз",
            type: SnackbarType.ERROR,
          })
        );
      }
    } finally {
      setSaveLoading(false);
    }
  }

  async function fetch() {
    setLoading(true);
    try {
      if (!comparisonId) {
        throw Error("no id");
      }

      const { data } = await UKRegistryService.getApartmentComparisonById(comparisonId);
      setDetails(new UKRegistry.RosreestrСomparisonDetailsMapped(data.Data));
    } finally {
      setLoading(false);
    }
  }

  return (
    <BaseModal isOpen={isShown} minWidth="900px" maxWidth="900px" onEnter={() => setLoading(true)} onEntered={fetch}>
      <BaseModalHeader
        title="Сверка данных моего реестра с Росреестром"
        subtitle="После внесения изменений нажмите на кнопку «Сохранить изменения в моем реестре». Система обработает изменения и обновит данные."
        onClose={handleClose}
      />

      <BaseModalContent sx={{ padding: "20px 0 0" }}>
        <div className={styles.content}>
          {isLoading ? (
            <div className={styles.loader}>
              <Spinner color="#226dff" size={36} />
            </div>
          ) : (
            <>
              <div className={styles.group}>
                <h4 className="headline-h4 sf-text-bold color--text-secondary">Помещение:</h4>
                <Accordion>
                  <div className={styles.accordion_list}>
                    {details?.apartment && (
                      <ApartmentDetailsComparison
                        comparison={details.apartment}
                        onAdd={onApartmentAdd}
                        onRemove={onApartmentRemove}
                        updateData={(key, updating) => updateApartmentData(key, updating)}
                      />
                    )}
                  </div>
                </Accordion>
              </div>

              <div className={styles.divider}></div>

              {details?.owners && details?.owners.length > 0 && (
                <div className={styles.group}>
                  <h4 className="headline-h4 sf-text-bold color--text-secondary">Собственники:</h4>
                  <Accordion>
                    <div className={styles.accordion_list}>
                      {details?.owners.map((owner, index) => (
                        <OwnerDetailsСomparison
                          key={`${owner.initial.FullName}_${index}`}
                          comparison={owner}
                          id={`${owner.initial.FullName}_${index}`}
                          isApartmentInMyReestr={details.apartment.initial.Reestr !== null}
                          isApartmentInRosreestr={details.apartment.initial.Rosreestr !== null}
                          onAdd={() => onOwnerAdd(index)}
                          onRemove={() => onOwnerRemove(index)}
                          updateData={(key, updating) => updateOwnerData(index, key, updating)}
                        />
                      ))}
                    </div>
                  </Accordion>
                </div>
              )}
            </>
          )}
        </div>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton variant="secondary" onClick={handleClose}>
          Отмена
        </BaseButton>
        <BaseButton disabled={!isEnabled} isLoading={isSaveLoading} onClick={handleSubmit}>
          Сохранить изменения в моем реестре
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default ApartmentСomparisonModal;
