import {
  SntPassportBoard,
  ResponseAbstract,
  Dictionary,
  SntPassportAgricultureMinistry,
  SntRegistry,
  SntPassport,
  ResponseAbstractWithPagination,
  SntPassportAnalytics,
  Profile,
} from "@/types";
import client from "../config";

export namespace SntPassportService {
  export function getSntList(params: {
    Column?: SntPassport.QueryColumn;
    Value?: string;
    Page?: number;
    Limit?: number;
    LokoloUserExists?: boolean;
  }) {
    return client.get<ResponseAbstractWithPagination<SntPassport.InstitutionSntItem[]>>(
      "/api/user/institution/get-snt",
      { params }
    );
  }

  export function getSntById(SntId: SntRegistry.SntDetails["Id"]) {
    return client.get<ResponseAbstract<SntPassport.InstitutionSntItem>>("/api/user/institution/get-snt-by-id", {
      params: { SntId },
    });
  }

  export function getSntSummaryById(SntId: SntRegistry.SntDetails["Id"]) {
    return client.get<ResponseAbstract<SntPassport.InstitutionSntItemSummary>>("/api/passport-snt/snt", {
      params: { SntId },
    });
  }

  // board-data
  export function getBoardData(SntId: SntRegistry.SntDetails["Id"]) {
    return client.get<ResponseAbstract<SntPassportBoard.BoardDataResponse>>("/api/passport-snt/board-data", {
      params: { SntId },
    });
  }

  export function getFieldGuideByField<T = unknown>(
    Field:
      | "RoadSurfaceType"
      | "WaterSupplyType"
      | "ElectricitySupplyType"
      | "InternalInfrastructureWorkType"
      | "ExternalInfrastructureWorkType"
  ) {
    return client.get<ResponseAbstract<Dictionary.AlternativeDictionaryItem<T>[]>>("/api/passport-snt/field-guide", {
      params: { Field },
    });
  }

  export function getAssociationsList() {
    return client.get<{ data: SntPassportBoard.Association[] }>("/api/passport-snt/field-guide/association");
  }

  export function saveBoardData(Form: SntPassportBoard.BoardData) {
    return client.post<ResponseAbstract<string>>("/api/passport-snt/board-data", { Form });
  }

  export function publishBoardData() {
    return client.post<ResponseAbstract<string>>("/api/passport-snt/board-data/publish");
  }

  // agriculture-ministry-data
  export function getAgricultureMinistryData(SntId: SntRegistry.SntDetails["Id"], Year: number) {
    return client.get<ResponseAbstract<SntPassportAgricultureMinistry.AgricultureMinistryDataResponse>>(
      "/api/passport-snt/agriculture-ministry-data",
      { params: { SntId, Year } }
    );
  }

  export function saveAgricultureMinistryData(
    SntId: SntRegistry.SntDetails["Id"],
    Year: number,
    Form: SntPassportAgricultureMinistry.AgricultureMinistryData
  ) {
    return client.post<ResponseAbstract<string>>(
      "/api/passport-snt/agriculture-ministry-data",
      { Form },
      { params: { SntId, Year } }
    );
  }

  export function publishAgricultureMinistryData(SntId: SntRegistry.SntDetails["Id"], Year: number) {
    return client.post<ResponseAbstract<string>>("/api/passport-snt/agriculture-ministry-data/publish", {
      SntId,
      Year,
    });
  }

  export function getSntCount(MunicipalDistrict?: Profile.MunicipalDistrict["Name"]) {
    return client.get<ResponseAbstract<{ Count: number }>>("/api/user/institution/get-snt-count", {
      params: { MunicipalDistrict },
    });
  }

  export function getInfrastructureStatistics({
    MunicipalDistrict,
    Year,
  }: {
    MunicipalDistrict?: Profile.MunicipalDistrict["Name"];
    Year?: number;
  }) {
    return client.post<ResponseAbstract<SntPassportAnalytics.AnalyticsData>>(
      "/api/passport-snt/statistic/infrastructure",
      null,
      { params: { MunicipalDistrict, Year } }
    );
  }

  export function getStateSupportStatistics({
    MunicipalDistrict,
    StartYear,
    EndYear,
  }: {
    MunicipalDistrict?: Profile.MunicipalDistrict["Name"];
    StartYear?: number;
    EndYear?: number;
  }) {
    return client.post<ResponseAbstract<SntPassportAnalytics.AnalyticsData>>(
      "/api/passport-snt/statistic/state-support",
      null,
      { params: { MunicipalDistrict, StartYear, EndYear } }
    );
  }
}
