import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import { chevronRight24Icon, lightbulbIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import {
  applySearchFilters,
  fetchUsers,
  getStatuses,
  loadMoreUsers,
  resetSearchFilters,
  setSearchQuery,
  setSearchQueryType,
  toggleUserDetailsModal,
} from "@/app/store/slices/houseUsers";
import { Accordion, CustomCollapsed, Expanded } from "@/components/base-accordion";
import BaseHeadlineAlert from "@/components/BaseHeadlineAlert";
import BaseTooltip from "@/components/base-tooltip";
import BaseButton from "@/components/base-button";
import BaseRadio from "@/components/BaseRadio";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import { Spinner } from "@/components/spinner";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseContainer,
} from "@/components/base-table";
import UserDetailsModal from "./modals/UserDetailsModal";
import { HouseUsers } from "@/types/house-users";
import { activeAddress, isCompanyTypeUK } from "@/app/store/slices/user";
import UserStatus from "./UserStatus";
import styles from "./styles.module.scss";
import { fetchAccessRoles } from "@/app/store/slices/access/actions";
import { QuestionTooltip16Icon } from "@/consts/icons-v2";
import PageHeader from "@/components/PageHeader";

const cx = classNames.bind(styles);
const filterAccordionId = "filter";

const LokoloUsers: React.FC = () => {
  const dispatch = useAppDispatch();

  const address = useSelector(activeAddress);
  const isUK = useSelector(isCompanyTypeUK);
  const { isLoading, filters, query, queryType, users, statuses, pagination } = useSelector(
    (state: RootState) => state.houseUsers
  );

  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();
  const { measureRef, isIntersecting, observer, hasMore } = useInfiniteScroll({ pagination: pagination ?? undefined });

  const [hoveredRow, setHoveredRow] = useState<{
    key: HouseUsers.User["Key"];
    userId: HouseUsers.User["UserId"];
  } | null>(null);
  const [clickedRow, setClickedRow] = useState<{
    key: HouseUsers.User["Key"];
    userId: HouseUsers.User["UserId"];
  } | null>(null);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [filtersLocal, setFiltersLocal] = useState<typeof filters>(filters);

  function onRowHoverIn(user: HouseUsers.User) {
    setHoveredRow({ key: user.Key, userId: user.UserId });
  }
  function onRowHoverOut() {
    setHoveredRow(null);
  }
  function onRowMouseDown(user: HouseUsers.User) {
    setClickedRow({ key: user.Key, userId: user.UserId });
  }
  function onRowMouseUp() {
    setClickedRow(null);
  }

  function handleQueryChange(event: React.SyntheticEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (value !== query) {
      dispatch(setSearchQuery(value));
    }
  }

  function handleQueryTypeChange(type: HouseUsers.QueryType) {
    if (queryType !== type) {
      dispatch(setSearchQueryType(type));
    }
  }

  async function syncFilters() {
    return await setFiltersLocal(filters);
  }

  async function applyFilters() {
    await dispatch(applySearchFilters(filtersLocal)).unwrap();
  }

  async function resetFilters() {
    setFiltersLocal({
      Esia: null,
      CanOccVote: null,
      Registry: null,
      Ownership: null,
    });
    await dispatch(resetSearchFilters()).unwrap();
  }

  function handleUserSelect(user: HouseUsers.User) {
    dispatch(toggleUserDetailsModal({ isOpen: true, user }));
  }

  function onScroll(e: React.SyntheticEvent) {
    const scrollLeft = (e.target as HTMLElement)?.scrollLeft;
    setScrollLeft(scrollLeft);
  }

  async function fetch() {
    if (!address) {
      return;
    }

    try {
      await Promise.all([
        dispatch(fetchUsers()).unwrap(),
        dispatch(getStatuses()).unwrap(),
        dispatch(fetchAccessRoles()).unwrap(),
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchMoreData() {
    if (users.length === 0) {
      return;
    }

    try {
      const { Index, Count } = await dispatch(loadMoreUsers()).unwrap();
      if (observer && isIntersecting && Index === Count) {
        observer.disconnect();
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
  }, [isIntersecting]);

  useEffect(() => {
    fetch();
  }, [address]);

  return (
    <>
      <PageHeader title="Пользователи Локоло">
        <div className={styles.search}>
          <div>
            <BaseDropdown
              value={queryType}
              customToggler={
                <div className={styles.select}>
                  <span className="text--primary color--text-primary sf-text-regular">
                    {queryType === HouseUsers.QueryType.Fio
                      ? "По пользователям"
                      : queryType === HouseUsers.QueryType.Number
                      ? !isUK
                        ? "По участкам"
                        : "По помещениям"
                      : "Фильтр"}
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.171 7.36222C5.064 7.21889 5 7.03444 5 6.83333C5 6.37333 5.336 6 5.75 6H10.25C10.664 6 11 6.37333 11 6.83333C11 7.03444 10.936 7.21889 10.829 7.36222L8.606 10.6578C8.47 10.8644 8.249 11 8 11C7.751 11 7.53 10.8644 7.394 10.6578L5.171 7.36222Z"
                      fill="#818C99"
                    />
                  </svg>
                </div>
              }
              isSelectable={true}
              onSelect={handleQueryTypeChange}
            >
              <>
                <BaseDropdownMenuItem value={HouseUsers.QueryType.Fio}>
                  <span className="sf-text-regular">По пользователям</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={HouseUsers.QueryType.Number}>
                  <span className="sf-text-regular">{!isUK ? "По участкам" : "По помещениям"}</span>
                </BaseDropdownMenuItem>
              </>
            </BaseDropdown>
          </div>
          <span></span>
          <div className={styles.query}>
            <svg className={styles.icon} width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 7C11.5 9.48528 9.48528 11.5 7 11.5C4.51472 11.5 2.5 9.48528 2.5 7C2.5 4.51472 4.51472 2.5 7 2.5C9.48528 2.5 11.5 4.51472 11.5 7ZM10.4765 11.8907C9.49572 12.5892 8.29583 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 8.29583 12.5892 9.49572 11.8907 10.4765L14.7071 13.2929C15.0976 13.6834 15.0976 14.3166 14.7071 14.7071C14.3166 15.0976 13.6834 15.0976 13.2929 14.7071L10.4765 11.8907Z"
                fill="#818C99"
              />
            </svg>
            <input
              className="text--primary color--text-primary sf-text-regular"
              type="text"
              value={query}
              onChange={handleQueryChange}
              placeholder="Поиск"
            />
          </div>
        </div>
      </PageHeader>

      <div>
        <BaseHeadlineAlert name="access-settings" title="Что это такое?" verticalMargin="0" icon={lightbulbIcon}>
          {isUK ? (
            <p className="text--primary">
              Чтобы у собственника была возможность голосовать в мобильном приложении «Локоло», у него должна быть
              подтверждена собственность через ваш Реестр собственников. Также в системе реализовано упрощенное
              подтверждение собственности, но при таком подтверждении пользователь не сможет участвовать в общих
              собраниях. Здесь вы можете отслеживать статусы пользователей мобильного приложения
            </p>
          ) : (
            <p className="text--primary">
              Чтобы у собственника была возможность голосовать в мобильном приложении «Локоло», у него должна быть
              подтверждена собственность. Здесь вы можете отслеживать статусы пользователей мобильного приложения
            </p>
          )}
        </BaseHeadlineAlert>
      </div>

      <div className={styles.filters}>
        <Accordion isAsync={true} callback={syncFilters}>
          <div className="flex">
            <CustomCollapsed id={filterAccordionId} upAndDownIconAnimation={true}>
              <BaseButton
                variant="secondary"
                endIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    role="spinbutton"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.7803 7.71968C14.4874 7.42679 14.0126 7.42679 13.7197 7.71969L10 11.4394L6.28033 7.71968C5.98743 7.42679 5.51256 7.42679 5.21967 7.71969C4.92678 8.01258 4.92678 8.48745 5.21967 8.78035L9.46969 13.0303C9.76259 13.3232 10.2375 13.3232 10.5304 13.0303L14.7803 8.78034C15.0732 8.48745 15.0732 8.01258 14.7803 7.71968Z"
                      fill="#226DFF"
                    />
                  </svg>
                }
              >
                Фильтр
              </BaseButton>
            </CustomCollapsed>
          </div>

          <Expanded id={filterAccordionId}>
            <div className={styles.filters_content}>
              <div className={styles.filters_table}>
                <div className={styles.filters_row}>
                  <div className={styles.filters_head_cell}>
                    <p className="text--primary color--text-primary sf-text-regular">Статус в приложении:</p>
                  </div>
                  <div>
                    <div style={{ minWidth: "300px" }}>
                      <BaseDropdown
                        value={filtersLocal.Ownership}
                        display={
                          filtersLocal.Ownership === null
                            ? "Все"
                            : statuses.find((it) => it.key === filtersLocal.Ownership)?.name
                        }
                        variant="formfield"
                        isSelectable={true}
                        onSelect={(value) => {
                          setFiltersLocal((prevState) => ({ ...prevState, Ownership: value }));
                        }}
                      >
                        <>
                          <BaseDropdownMenuItem value={null}>
                            <span className="sf-text-regular">Все</span>
                          </BaseDropdownMenuItem>
                          {statuses.map((status) => (
                            <BaseDropdownMenuItem key={status.key} value={status.key}>
                              <span className="sf-text-regular">{status.name}</span>
                            </BaseDropdownMenuItem>
                          ))}
                        </>
                      </BaseDropdown>
                    </div>
                  </div>
                </div>

                <div className={styles.filters_row}>
                  <div className={styles.filters_head_cell}>
                    <p className="text--primary color--text-primary sf-text-regular">Есть в моем реестре:</p>
                  </div>
                  <div>
                    <div className={styles.filters_radio}>
                      <BaseRadio
                        value={null}
                        selected={filtersLocal?.Registry}
                        onChange={(value: typeof filters.Registry) =>
                          setFiltersLocal((prevState) => ({ ...prevState, Registry: value }))
                        }
                      >
                        Все
                      </BaseRadio>
                      <BaseRadio
                        value={true}
                        selected={filtersLocal?.Registry}
                        onChange={(value: typeof filters.Registry) =>
                          setFiltersLocal((prevState) => ({ ...prevState, Registry: value }))
                        }
                      >
                        Да
                      </BaseRadio>
                      <BaseRadio
                        value={false}
                        selected={filtersLocal?.Registry}
                        onChange={(value: typeof filters.Registry) =>
                          setFiltersLocal((prevState) => ({ ...prevState, Registry: value }))
                        }
                      >
                        Нет
                      </BaseRadio>
                    </div>
                  </div>
                </div>

                <div className={styles.filters_row}>
                  <div className={styles.filters_head_cell}>
                    <p className="text--primary color--text-primary sf-text-regular">Вход через ЕСИА:</p>
                  </div>
                  <div>
                    <div className={styles.filters_radio}>
                      <BaseRadio
                        value={null}
                        selected={filtersLocal?.Esia}
                        onChange={(value: typeof filters.Esia) =>
                          setFiltersLocal((prevState) => ({ ...prevState, Esia: value }))
                        }
                      >
                        Все
                      </BaseRadio>
                      <BaseRadio
                        value={true}
                        selected={filtersLocal?.Esia}
                        onChange={(value: typeof filters.Esia) =>
                          setFiltersLocal((prevState) => ({ ...prevState, Esia: value }))
                        }
                      >
                        Да
                      </BaseRadio>
                      <BaseRadio
                        value={false}
                        selected={filtersLocal?.Esia}
                        onChange={(value: typeof filters.Esia) =>
                          setFiltersLocal((prevState) => ({ ...prevState, Esia: value }))
                        }
                      >
                        Нет
                      </BaseRadio>
                    </div>
                  </div>
                </div>

                {isUK && (
                  <div className={styles.filters_row}>
                    <div className={styles.filters_head_cell}>
                      <p className="text--primary color--text-primary sf-text-regular">
                        Может участвовать в собраниях:
                      </p>
                    </div>
                    <div>
                      <div className={styles.filters_radio}>
                        <BaseRadio
                          value={null}
                          selected={filtersLocal?.CanOccVote}
                          onChange={(value: typeof filters.CanOccVote) =>
                            setFiltersLocal((prevState) => ({ ...prevState, CanOccVote: value }))
                          }
                        >
                          Все
                        </BaseRadio>
                        <BaseRadio
                          value={true}
                          selected={filtersLocal?.CanOccVote}
                          onChange={(value: typeof filters.CanOccVote) =>
                            setFiltersLocal((prevState) => ({ ...prevState, CanOccVote: value }))
                          }
                        >
                          Да
                        </BaseRadio>
                        <BaseRadio
                          value={false}
                          selected={filtersLocal?.CanOccVote}
                          onChange={(value: typeof filters.CanOccVote) =>
                            setFiltersLocal((prevState) => ({ ...prevState, CanOccVote: value }))
                          }
                        >
                          Нет
                        </BaseRadio>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.filters_actions}>
                <div>
                  <BaseButton onClick={applyFilters}>Показать</BaseButton>
                </div>
                <div>
                  <BaseButton variant="secondary" appearance="negative" onClick={resetFilters}>
                    Сбросить фильтр
                  </BaseButton>
                </div>
              </div>
            </div>
          </Expanded>
        </Accordion>
      </div>

      <div>
        <BaseContainer className={styles.table}>
          <div
            className={cx("custom-vertical-scrollbar", { table_container: true, shadowed: scrollLeft > 10 })}
            onScroll={onScroll}
          >
            <BaseTable className={styles.sticky}>
              <BaseTableHead>
                <BaseTableRow className={styles.table_head_row}>
                  <BaseTableHeadCell>{!isUK ? "№ участка" : "№ помещения"}</BaseTableHeadCell>
                  <BaseTableHeadCell>Данные собственника</BaseTableHeadCell>
                </BaseTableRow>
              </BaseTableHead>

              {!isLoading && users.length > 0 && (
                <BaseTableBody className={styles.table_body}>
                  {users.map((it, index) => (
                    <BaseTableRow
                      className={cx({
                        table_row: true,
                        hovered: hoveredRow?.key === it.Key && hoveredRow?.userId === it.UserId,
                        active: clickedRow?.key === it.Key && clickedRow?.userId === it.UserId,
                      })}
                      key={`${it.ObjectNumber}-${it.UserId}-${index}`}
                      onClick={() => handleUserSelect(it)}
                      onMouseEnter={() => onRowHoverIn(it)}
                      onMouseLeave={() => onRowHoverOut()}
                      onMouseDown={() => onRowMouseDown(it)}
                      onMouseUp={() => onRowMouseUp()}
                    >
                      <BaseTableHeadCell>
                        <span>{it.ObjectNumber}</span>
                      </BaseTableHeadCell>
                      <BaseTableCell>
                        <span className="sf-text-semibold">{it.Fio}</span>
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                </BaseTableBody>
              )}
            </BaseTable>

            <BaseTable>
              <BaseTableHead>
                <BaseTableRow className={styles.table_head_row}>
                  <BaseTableHeadCell>Вход через ЕСИА</BaseTableHeadCell>
                  <BaseTableHeadCell>Есть в моем реестре</BaseTableHeadCell>
                  {isUK && <BaseTableHeadCell>Может участвовать в собраниях</BaseTableHeadCell>}
                  <BaseTableHeadCell>
                    <span className="flex middle" style={{ gap: "4px" }}>
                      Статус в приложении
                      {!isUK && (
                        <BaseTooltip title="Для подтверждения собственности пользователь должен авторизоваться в приложении через ЕСИА, а также он должен быть в вашем реестре участков">
                          {QuestionTooltip16Icon("var(--icons-primary)")}
                        </BaseTooltip>
                      )}
                    </span>
                  </BaseTableHeadCell>
                  <BaseTableHeadCell></BaseTableHeadCell>
                </BaseTableRow>
              </BaseTableHead>

              {!isLoading && users.length > 0 && (
                <BaseTableBody className={styles.table_body}>
                  {users.map((it, index) => (
                    <BaseTableRow
                      className={cx({
                        table_row: true,
                        hovered: hoveredRow?.key === it.Key && hoveredRow?.userId === it.UserId,
                        active: clickedRow?.key === it.Key && clickedRow?.userId === it.UserId,
                      })}
                      key={`2-${it.ObjectNumber}-${it.UserId}-${index}`}
                      onClick={() => handleUserSelect(it)}
                      onMouseEnter={() => onRowHoverIn(it)}
                      onMouseLeave={() => onRowHoverOut()}
                      onMouseDown={() => onRowMouseDown(it)}
                      onMouseUp={() => onRowMouseUp()}
                    >
                      <BaseTableCell>
                        <span>{it.EsiaExists ? "Да" : "Нет"}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span>{it.InRegistry ? "Да" : "Нет"}</span>
                      </BaseTableCell>
                      {isUK && (
                        <BaseTableCell>
                          {it.CanOccVote ? (
                            <span>Да</span>
                          ) : (
                            <span className="flex middle" style={{ gap: "4px" }}>
                              Нет
                              <BaseTooltip title="Для участия в общих собраниях пользователь должен авторизоваться в приложении через ЕСИА, а также он должен быть в вашем Реестре собственников">
                                {QuestionTooltip16Icon()}
                              </BaseTooltip>
                            </span>
                          )}
                        </BaseTableCell>
                      )}
                      <BaseTableCell>
                        <span>
                          <UserStatus status={it.Ownership} />
                        </span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span className={styles.table_row_icon}>{chevronRight24Icon("var(--text-secondary)")}</span>
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                </BaseTableBody>
              )}
            </BaseTable>
          </div>

          {isLoading ? (
            <div
              className="flex center middle"
              ref={emptyBlockRef}
              style={{ height: `${emptyBlockHeight}px`, minHeight: "350px" }}
            >
              <Spinner size={36} />
            </div>
          ) : users.length === 0 ? (
            <div
              className="flex center middle"
              ref={emptyBlockRef}
              style={{ height: `${emptyBlockHeight}px`, minHeight: "350px" }}
            >
              <div>
                {query.length > 0 ? (
                  <p className="text--primary sf-pro-regular color--text-primary">Поиск не дал результатов</p>
                ) : (
                  <p className="text--primary sf-pro-regular color--text-primary">Пользователей пока нет</p>
                )}
              </div>
            </div>
          ) : hasMore ? (
            <div className="flex center" ref={measureRef}>
              <div style={{ padding: "8px" }}>
                <Spinner size={36} />
              </div>
            </div>
          ) : null}
        </BaseContainer>
      </div>

      <UserDetailsModal />
    </>
  );
};

export default LokoloUsers;
