import PinInput from "react-pin-input";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { ProfileService } from "@/services/v2";
import { useAppDispatch } from "@/app/store";
import { arrowLeftIcon, logoIcon } from "@/consts/icons";
import AuthContext from "../context";
import BaseButton from "@/components/base-button";
import styles from "../auth.module.scss";
import { authLogin, fetchUser } from "@/app/store/slices/user";

function AuthFormByPhoneCodeSended() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const context = useContext(AuthContext);
  let pinRef: any;

  let timer: any;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [counter, setCounter] = useState(60);

  async function resendOtp() {
    if (!context?.phone) {
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await ProfileService.otpSend(context.phone);
      context?.setIsCodeSended({
        expiresAt: (data as any).Data.ExpiresAt,
        phone: context.phone,
        value: true,
      });
      setCounter(60);
    } catch (err: any) {
      context?.setIsCodeSended({
        value: false,
      });
    } finally {
      setIsLoading(false);
    }
  }
  async function onChange(index: number) {
    setErrorMessage("");
    if (pinRef?.elements && Array.isArray(pinRef?.elements) && pinRef?.elements[index - 1]) {
      const element = pinRef?.elements[index - 1];
      if (element?.input?.style && "backgroundColor" in element.input.style) {
        element.input.style.backgroundColor = "#F5F6FA";
      }
    }
  }

  function handleBack() {
    context?.setIsCodeSended({
      value: false,
    });
  }

  async function onComplete(code: string) {
    const self = pinRef;
    try {
      onChange(4);
      await dispatch(authLogin({ phone: context?.phone, code })).unwrap();
      setIsLoading(true);
      await dispatch(fetchUser()).unwrap();

      navigate("/");
    } catch (error: any) {
      if (error?.Data?.Message === "Неверный логин или пароль") {
        if (self?.elements && Array.isArray(self?.elements)) {
          self?.elements?.forEach((element: any) => {
            if (element?.input?.style && "backgroundColor" in element.input.style) {
              element.input.style.backgroundColor = "#FFEBEB";
            }
          });
        }
        self?.clear();
        return;
      }

      setErrorMessage(error?.Data?.Message ? error?.Data?.Message : "Неизвестная ошибка. Попробуйте позже");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (counter > 0) {
      timer = setInterval(() => setCounter(counter - 1), 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  return (
    <>
      <div className={styles["p-auth__card_logo"]}>
        <span className={styles["p-auth__back"]} onClick={handleBack}>
          {arrowLeftIcon("#898D93")}
        </span>
        {logoIcon()}
        <h2 className="headline-h2 sf-bold color--text-primary center">Введите последние 4 цифры</h2>
      </div>

      <div className={styles["p-auth__form"]}>
        <p className="color--text-primary headline-h4 sf-text-regular center">
          Мы звоним на {context?.phone?.formatPhone("-")}, <br />
          введите последние 4 цифры входящего номера
        </p>
        <div>
          <PinInput
            ref={(p) => (pinRef = p)}
            length={4}
            initialValue=""
            onChange={(value, index) => onChange(index)}
            type="numeric"
            inputMode="number"
            style={{ display: "flex", justifyContent: "space-between" }}
            inputStyle={{
              background: "#F5F6FA",
              borderRadius: "12px",
              width: "64px",
              height: "64px",
              border: "none",
              margin: "0",
              fontSize: "24px",
              fontFamily: "SF Pro Display",
              fontWeight: "bold",
              fontStyle: "normal",
              color: "var(--text-primary)",
            }}
            onComplete={(value, index) => onComplete(value)}
            autoSelect={true}
            focus={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
        </div>

        <div>
          <BaseButton
            type="button"
            disabled={counter > 0}
            className={styles["p-auth__form_submit"]}
            isLoading={isLoading}
            onClick={resendOtp}
          >
            {counter > 0
              ? `Запросить через ${counter} секунд${counter > 1 && counter < 5 ? "ы" : counter > 1 ? "у" : ""}`
              : "Запросить еще раз"}
          </BaseButton>
        </div>

        {errorMessage && <p className="color--red text--primary sf-text-regular center">{errorMessage}</p>}
      </div>
    </>
  );
}

export default AuthFormByPhoneCodeSended;
