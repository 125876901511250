import { useMemo } from "react";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IBaseRadioProps {
  value: any;
  selected: IBaseRadioProps["value"];
  onChange: (value: IBaseRadioProps["value"]) => void;
  disabled?: boolean;
  children?: JSX.Element | JSX.Element[] | string | null | undefined;
}

const BaseRadio: React.FC<IBaseRadioProps> = ({ value, selected, onChange, disabled, children }) => {
  const isChecked: boolean = useMemo(() => {
    return value === selected;
  }, [value, selected]);

  const handleChange = () => {
    onChange(value);
  };

  return (
    <label className={cx({ radio: true, disabled, checked: isChecked })}>
      <span className={styles.input}>
        <input type="radio" value={value?.toString()} checked={isChecked} onChange={handleChange} disabled={disabled} />
        <span className={styles.control}></span>
      </span>
      <span className="text--primary color--text-primary sf-text-regular">{children}</span>
    </label>
  );
};

export default BaseRadio;
