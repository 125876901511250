import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OCC, OCCV2Author } from "@/types";

interface SntCreateOccState {
  occId: OCC.OccOrderBase["Id"] | null;
  isOccCreateLoading: boolean;
  isAcceptOccLoading: boolean;
  order: OCC.OccOrderItem | OCC.OccOrderItemFull | null;
  questions: OCC.Question[];
  author: OCCV2Author.Person | null;
  administrator: OCCV2Author.Person | null;
  plannedEvents: OCC.OccFullDetails["PlannedEvents"] | null;
  isCancelModalOpen: boolean;
  unsavedBody: {
    isSaved: boolean;
    occ: OCC.CreateOCCBody | null;
    questions: Array<OCC.CreateQuestion> | [];
    deleteQuestions: Array<OCC.Question["Id"]> | [];
  };
}

const initialState: SntCreateOccState = {
  occId: null,
  isOccCreateLoading: false,
  isAcceptOccLoading: false,
  order: null,
  questions: [],
  author: null,
  administrator: null,
  plannedEvents: null,
  isCancelModalOpen: false,
  unsavedBody: {
    isSaved: false,
    occ: null,
    questions: [],
    deleteQuestions: [],
  },
};

const sntCreateOccSlice = createSlice({
  name: "snt-create-occ",
  initialState,
  reducers: {
    setOccId: (state, action: PayloadAction<SntCreateOccState["occId"]>) => {
      state.occId = action.payload;
    },

    toggleOccCreateLoading: (state, action: PayloadAction<boolean>) => {
      state.isOccCreateLoading = action.payload;
    },

    toggleAcceptOccLoading: (state, action: PayloadAction<boolean>) => {
      state.isAcceptOccLoading = action.payload;
    },

    setOrder: (state, action: PayloadAction<SntCreateOccState["order"]>) => {
      state.order = action.payload;
    },

    setQuestions: (state, action: PayloadAction<SntCreateOccState["questions"]>) => {
      state.questions = action.payload;
    },

    setPlannedEvents: (state, action: PayloadAction<SntCreateOccState["plannedEvents"]>) => {
      state.plannedEvents = action.payload;
    },

    setCancelModalOpen: (state, action: PayloadAction<SntCreateOccState["isCancelModalOpen"]>) => {
      state.isCancelModalOpen = action.payload;
    },

    setUnsavedOccBody: (state, action: PayloadAction<SntCreateOccState["unsavedBody"]["occ"]>) => {
      state.unsavedBody.occ = action.payload;
    },

    setUnsavedQuestions: (state, action: PayloadAction<Array<OCC.CreateQuestion>>) => {
      state.unsavedBody.questions = action.payload;
    },

    setUnsavedQuestionsToDelete: (state, action: PayloadAction<Array<OCC.Question["Id"]>>) => {
      state.unsavedBody.deleteQuestions = action.payload;
    },

    setIsSaved: (state, action: PayloadAction<SntCreateOccState["unsavedBody"]["isSaved"]>) => {
      state.unsavedBody.isSaved = action.payload;
    },

    setAuthor: (state, action: PayloadAction<SntCreateOccState["author"]>) => {
      if (typeof action.payload === "object" && action.payload !== null && "Name" in action.payload) {
        state.author = action.payload;
      }
    },

    setAdministrator: (state, action: PayloadAction<SntCreateOccState["administrator"]>) => {
      if (typeof action.payload === "object" && action.payload !== null && "Name" in action.payload) {
        state.administrator = action.payload;
      }
    },
  },
  extraReducers: {},
});

export default sntCreateOccSlice.reducer;
export * from "./actions";
export const {
  setOccId,
  toggleOccCreateLoading,
  toggleAcceptOccLoading,
  setOrder,
  setQuestions,
  setPlannedEvents,
  setCancelModalOpen,
  setUnsavedOccBody,
  setUnsavedQuestions,
  setUnsavedQuestionsToDelete,
  setIsSaved,
  setAuthor,
  setAdministrator,
} = sntCreateOccSlice.actions;
