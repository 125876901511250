import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Documents } from "@/types";

interface DocumentsState {
  directories: Documents.DirectoryWithFiles[];
}

const initialState: DocumentsState = {
  directories: [],
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setDirectories: (state, action: PayloadAction<Documents.Directory[]>) => {
      state.directories = action.payload.map((it) => JSON.parse(JSON.stringify(new Documents.DirectoryWithFiles(it))));
    },

    updateDirectoriesOrder: (state, action: PayloadAction<{ fromIndex: number; toIndex: number }>) => {
      const item = state.directories.splice(action.payload.fromIndex, 1)[0];
      state.directories.splice(action.payload.toIndex, 0, item);
      const _directories: Documents.DirectoryWithFiles[] = state.directories.map((it, index) => ({
        ...it,
        directory: { ...it.directory, Order: index },
      }));

      state.directories = _directories;
    },

    pushDirectory: (state, action: PayloadAction<Documents.Directory>) => {
      state.directories.push(JSON.parse(JSON.stringify(new Documents.DirectoryWithFiles(action.payload))));
    },

    updateDirectoryById: (state, action: PayloadAction<Documents.Directory>) => {
      const directories = state.directories.map((it) => {
        if (it.directory.Id === action.payload.Id) {
          return {
            ...it,
            directory: {
              ...action.payload,
            },
          };
        }
        return it;
      });

      state.directories = directories;
    },

    removeDirectory: (state, action: PayloadAction<Documents.Directory["Id"]>) => {
      const index = state.directories.findIndex((it) => it.directory.Id === action.payload);
      if (index >= 0) {
        state.directories.splice(index, 1);
      }
    },

    setDocumentsByDirectoryId: (
      state,
      action: PayloadAction<{ directoryId: Documents.Directory["Id"]; documents: Documents.UsefulDocument[] }>
    ) => {
      const directory = state.directories.find((it) => it.directory.Id === action.payload.directoryId);
      if (directory) {
        directory.documents = action.payload.documents;
        directory.isFetched = true;
      }
    },

    pushDocumentToDirectory: (
      state,
      action: PayloadAction<{ directoryId: Documents.Directory["Id"]; document: Documents.UsefulDocument }>
    ) => {
      const directory = state.directories.find((it) => it.directory.Id === action.payload.directoryId);
      if (!directory) return;

      directory.documents.unshift(action.payload.document);
      directory.directory.FilesCount++;
    },

    updateDocumentById: (
      state,
      action: PayloadAction<{ directoryId: Documents.Directory["Id"]; document: Documents.UsefulDocument }>
    ) => {
      const directory = state.directories.find((it) => it.directory.Id === action.payload.directoryId);
      if (!directory) return;

      const documents = directory.documents.map((it) => {
        if (it.Id === action.payload.document.Id) {
          return { ...action.payload.document };
        }
        return it;
      });
      directory.documents = documents;
    },

    removeDocument: (
      state,
      action: PayloadAction<{ directoryId: Documents.Directory["Id"]; fileId: Documents.UsefulDocument["Id"] }>
    ) => {
      const directory = state.directories.find((it) => it.directory.Id === action.payload.directoryId);
      if (!directory) return;

      const index = directory.documents.findIndex((it) => it.Id === action.payload.fileId);
      if (index >= 0) {
        directory.documents.splice(index, 1);
        directory.directory.FilesCount--;
      }
    },
  },
  extraReducers: {},
});

export const {
  setDirectories,
  updateDirectoriesOrder,
  pushDirectory,
  updateDirectoryById,
  removeDirectory,
  setDocumentsByDirectoryId,
  pushDocumentToDirectory,
  updateDocumentById,
  removeDocument,
} = documentsSlice.actions;

export default documentsSlice.reducer;
