import { ResponseAbstract, Access, House } from "@/types";
import client from "./config";

export namespace AccessSettingsService {
  export function getMyAccessRole() {
    return client.get<ResponseAbstract<Access.User>>("/api/address/access/user/current");
  }

  export function getUserRole(UserId: Access.User["User"]["Id"]) {
    return client.get<ResponseAbstract<Access.User>>("/api/address/access/user/get-by-user", { params: { UserId } });
  }

  export function getAccessUsersList() {
    return client.get<ResponseAbstract<Access.User[]>>("/api/address/access/user");
  }

  export function getRoles() {
    return client.get<ResponseAbstract<Access.Role[]>>("/api/address/access/role");
  }

  export function setRoleForUser(body: {
    Phone: string;
    Role: Access.Role["Key"];
    HouseIds?: Array<House.Item["Id"]>;
  }) {
    return client.post<ResponseAbstract<unknown>>("/api/address/access/user", body);
  }

  export function updateRoleHousesForUser(body: {
    UserId: Access.User["User"]["Id"];
    HouseIds: Array<House.Item["Id"]>;
  }) {
    return client.patch<ResponseAbstract<unknown>>("/api/address/access/user", body);
  }

  export function createUserAndSetRole(body: Access.CreateUserRequestBody) {
    return client.post<ResponseAbstract<unknown>>("/api/address/access/user/create-user", body);
  }

  export function setAddressAndRoleForUser(body: Access.SetAddressAndRoleRequestBody) {
    return client.post<ResponseAbstract<unknown>>("/api/address/access/user/set-address", body);
  }

  export function removeUserRole(Id: Access.User["Id"]) {
    return client.delete<unknown>("/api/address/access/user", { params: { Id } });
  }
}
