import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IBaseModalActionsProps {
  children: React.ReactNode;
  variant?: "default" | "bordered";
  className?: string;
}

const BaseModalActions = ({ className, children, variant = "default" }: IBaseModalActionsProps) => {
  return <div className={cx(className, { actions: true, [variant]: true })}>{children}</div>;
};

export default BaseModalActions;
