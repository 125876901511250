import { Form, FormFiled, FormValidator, Dictionary } from "@/types";
import { OCCV2Questions } from "./";

export namespace OCCV2QuestionForms {
  export class CreateQuestion extends Form {
    readonly questionId: OCCV2Questions.Item["Id"] | null = null;
    Quorum: Dictionary.DictionaryItem<Dictionary.Quorum> | null = null;
    QuorumError: string = "";
    Title: FormFiled;
    Value: FormFiled;
    AvailableForAllSectionOwner: boolean = false;
    Files: Dictionary.File[] = [];

    constructor(question?: OCCV2Questions.Item, quorums?: Dictionary.DictionaryItem<Dictionary.Quorum>[]) {
      super();
      this.questionId = question?.Id ?? null;
      this.Title = new FormFiled(question?.Title.trim().replace(/ +(?= )/g, "") ?? "", true, false, 1000);
      this.Value = new FormFiled(question?.Value.trim().replace(/ +(?= )/g, "") ?? "", true, false, 5000);
      this.AvailableForAllSectionOwner = question ? !question?.OnlyHorticultureMembers : false;
      this.Files = question?.Files ?? [];
      if (question?.QuorumId && quorums && quorums.length > 0) {
        const quorum = quorums.find((it) => it.id === question?.QuorumId);
        this.Quorum = quorum ?? null;
      } else this.Quorum = null;
    }
  }

  export class CreateQuestionValidator {
    private static instance: CreateQuestionValidator;

    public static get(): CreateQuestionValidator {
      if (!CreateQuestionValidator.instance) {
        CreateQuestionValidator.instance = new CreateQuestionValidator();
      }
      return CreateQuestionValidator.instance;
    }

    isInvalid(form: CreateQuestion) {
      const errors = {
        Title: FormValidator.getFieldErrorMessage(form.Title.value.trim(), form.Title.isRequired),
        Value: FormValidator.getFieldErrorMessage(form.Value.value.trim(), form.Value.isRequired),
        Quorum: "",
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }
}
