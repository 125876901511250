import { Dictionary } from "@/types";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
import { selectStatusById } from "@/app/store/slices/dictionary";
import { useMemo } from "react";

const cx = classNames.bind(styles);

interface IOccStatusProps {
  statusId: Dictionary.DictionaryItem<Dictionary.OCCStatus>["id"];
  statusName?: Dictionary.DictionaryItem<Dictionary.OCCStatus>["name"];
  className?: string;
}

const OccStatus: React.FC<IOccStatusProps> = ({ statusId, statusName, className }) => {
  const status = useSelector(selectStatusById(statusId));

  const statusColor: string = useMemo(() => {
    switch (status?.key) {
      case Dictionary.OCCStatus.Archived:
        return "#7E858E";

      case Dictionary.OCCStatus.Draft:
        return "#232425";

      case Dictionary.OCCStatus.OccPlanned:
        return "#F72C32";

      case Dictionary.OCCStatus.OccIsComingStartMakingDecisionsAfterXDays:
        return "#583CF1";

      case Dictionary.OCCStatus.OccIsComingDecisionMaking:
        return "#0041E5";

      case Dictionary.OCCStatus.OccIsComingProtocolFormation:
        return "#FC9F1C";

      case Dictionary.OCCStatus.CompletedProtocolPrepared:
        return "#0AC153";

      case Dictionary.OCCStatus.OccIsComingInitialDocumentsFormation:
        return "#1E93FF";

      case Dictionary.OCCStatus.OccIsComingRegenerateDocuments:
        return "#1E93FF";

      default:
        return "#232425";
    }
  }, [status]);

  return (
    <div className={cx(className, { status: true })} style={{ color: statusColor }}>
      <span></span>
      <div className="caption--middle sf-text-medium">{statusName ? statusName : status?.name}</div>
    </div>
  );
};

export default OccStatus;
