import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Dictionary, UKRegistry } from "@/types";
import { RootState } from "@/app/store";
import { BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import BaseInput from "@/components/base-input";
import MaskedInput from "@/components/base-input/MaskedInput";
import DatepickerInput from "@/components/base-input/datepicker";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import styles from "./styles.module.scss";

interface IEditOwnerModalProps {
  isOpen: boolean;
  owner: UKRegistry.ApartmentOwner | UKRegistry.CreatedApartmentOwner | null | undefined;
  allOwners: Array<UKRegistry.CreatedApartmentOwner | UKRegistry.ApartmentOwner>;
  readonly: boolean;
  onClose: () => void;
  onEdit: (owner: UKRegistry.CreatedApartmentOwner | UKRegistry.ApartmentOwner) => void;
  onAdd: (owner: Omit<UKRegistry.CreatedApartmentOwner, "Id">) => void;
  left: number;
}

const EditOwnerModal: React.FC<IEditOwnerModalProps> = ({
  isOpen,
  owner,
  allOwners,
  readonly,
  left,
  onClose,
  onEdit,
  onAdd,
}) => {
  const ownershipsList = useSelector((state: RootState) => state.dictionary.ownership.list);
  const apartment = useSelector((state: RootState) => state.ukRegistry.editModal.apartment);

  const [form, setForm] = useState<UKRegistry.OwnerForm>(new UKRegistry.OwnerForm());

  const title: string = useMemo(() => {
    if (readonly) return "Подробнее";
    if (owner) return "Редактировать собственника";
    return "Добавить собственника";
  }, [readonly, owner]);

  const updateFormFieldValue = (key: "FullName" | "OGRN" | "SizeShares" | "DateRecordNumber", value: string) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: { ...form[key], value },
    }));
  };

  const updateTypeOwnership = (value: Dictionary.ApartmentDictionaryItem["ShortName"]) => {
    const ownership = ownershipsList.find((it) => it.ShortName === value);
    if (ownership) {
      setForm((prevState) => ({
        ...prevState,
        TypeOwnership: {
          ...prevState.TypeOwnership,
          value: ownership,
        },
      }));
    }
  };

  const handleClose = () => {
    onClose();
  };

  const isFormValid = () => {
    const validator = UKRegistry.OwnerFormValidator.isInvalid(form, allOwners);
    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        FullName: {
          ...prevState.FullName,
          error: validator.FullName,
        },
        TypeOwnership: {
          ...prevState.TypeOwnership,
          error: validator.TypeOwnership,
        },
        OGRN: {
          ...prevState.OGRN,
          error: validator.OGRN,
        },
        SizeShares: {
          ...prevState.SizeShares,
          error: validator.SizeShares,
        },
        DateRecordNumber: {
          ...prevState.DateRecordNumber,
          error: validator.DateRecordNumber,
        },
        RecordDatetime: {
          ...prevState.RecordDatetime,
          error: validator.RecordDatetime,
        },
      }));
    }
    return !validator;
  };

  const handleSubmit = () => {
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });
    setForm((prevState) => ({ ...prevState, error: "" }));

    if (!isFormValid() || !form.TypeOwnership.value) {
      return;
    }

    if (owner) {
      onEdit({
        ...owner,
        FullName: form.FullName.value,
        OGRN: form.OGRN.value,
        TypeOwnership: form.TypeOwnership.value.ShortName,
        SizeShares: form.SizeShares.value,
        DateRecordNumber: form.DateRecordNumber.value,
        RecordDatetime: form.RecordDatetime.value?.formatDate() ?? "",
      });
    } else {
      onAdd({
        FullName: form.FullName.value,
        OGRN: form.OGRN.value,
        TypeOwnership: form.TypeOwnership.value.ShortName,
        SizeShares: form.SizeShares.value,
        DateRecordNumber: form.DateRecordNumber.value,
        RecordDatetime: form.RecordDatetime.value?.formatDate() ?? "",
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setForm(new UKRegistry.OwnerForm(owner ?? undefined, ownershipsList));
    }
  }, [isOpen]);

  return (
    <div className={styles.owner_modal} style={{ left: `${left}px` }}>
      <BaseModalHeader title={title} onClose={handleClose} />

      <BaseModalContent sx={{ padding: "16px 24px" }}>
        <div className={styles.owner_form}>
          <div className={styles.full_field}>
            <BaseInput
              value={form.FullName.value}
              disabled={readonly}
              label="Данные собственника"
              required={form.FullName.isRequired}
              placeholder="Введите ФИО собственника или наименование юридического лица"
              errorMessage={form.FullName.error}
              maxlength={form.FullName.maxlength}
              onChange={(value) => updateFormFieldValue("FullName", value)}
            />
          </div>

          <div className={styles.full_field}>
            <MaskedInput
              value={form.OGRN.value}
              disabled={readonly}
              label="ОГРН (для юридических лиц)"
              required={form.OGRN.isRequired}
              placeholder="XXXXXXXXXXXXX"
              tooltipMessage="Введите ОГРН юридического лица. Если собственник физическое лицо, то поле не заполняется"
              errorMessage={form.OGRN.error}
              mask="0000000000000"
              onChange={(value) => updateFormFieldValue("OGRN", value)}
            />
          </div>

          <div>
            <BaseDropdown
              value={form.TypeOwnership?.value?.ShortName ?? null}
              display={form.TypeOwnership?.value?.ShortName}
              isSelectable={true}
              label="Вид собственности"
              placeholder="Выберите"
              variant="formfield"
              required={form.TypeOwnership.isRequired}
              disabled={readonly}
              errorMessage={form.TypeOwnership.error}
              onSelect={updateTypeOwnership}
            >
              {ownershipsList.map((ownership) => (
                <BaseDropdownMenuItem key={ownership.ShortName} value={ownership.ShortName}>
                  <span className="sf-text-medium">{ownership.ShortName}</span>
                </BaseDropdownMenuItem>
              ))}
            </BaseDropdown>
          </div>

          <div>
            <BaseInput
              value={form.SizeShares.value}
              disabled={readonly}
              label="Доля"
              required={form.SizeShares.isRequired}
              placeholder="Например: (1/4)"
              tooltipMessage="Укажите размер доли собственника в праве собственности в дробном формате. Пример: 1/4; 1/2; 1/3"
              errorMessage={form.SizeShares.error}
              maxlength={form.SizeShares.maxlength}
              onChange={(value) => updateFormFieldValue("SizeShares", value)}
            />
          </div>

          <div>
            <BaseInput
              value={form.DateRecordNumber.value}
              disabled={readonly}
              label="Документ о праве собственности"
              required={form.DateRecordNumber.isRequired}
              placeholder="Введите номер документа"
              tooltipMessage="Укажите номер выписки из ЕГРН или реквизиты другого документа, подтверждающего право собственности"
              errorMessage={form.DateRecordNumber.error}
              maxlength={form.DateRecordNumber.maxlength}
              onChange={(value) => updateFormFieldValue("DateRecordNumber", value)}
            />
          </div>

          <div>
            <DatepickerInput
              required={form.RecordDatetime.isRequired}
              minDate={form.RecordDatetime.minDate}
              maxDate={form.RecordDatetime.maxDate}
              label="Дата выдачи документа"
              tooltipMessage="Укажите дату выдачи выписки ЕГРН или другого документа, подтверждающего право собственности"
              value={form.RecordDatetime.value}
              errorMessage={form.RecordDatetime.error}
              disabled={readonly}
              placeholder="Введите дату"
              onChange={(date) =>
                setForm((prevState) => ({
                  ...prevState,
                  RecordDatetime: { ...prevState.RecordDatetime, value: date },
                }))
              }
            />
          </div>
        </div>
      </BaseModalContent>

      <BaseModalActions>
        {readonly ? (
          <BaseButton onClick={handleClose}>Закрыть</BaseButton>
        ) : owner ? (
          <>
            <BaseButton variant="secondary" onClick={handleClose}>
              Отменить
            </BaseButton>
            <BaseButton onClick={handleSubmit}>Сохранить</BaseButton>
          </>
        ) : (
          <>
            <BaseButton variant="secondary" onClick={handleClose}>
              Отменить
            </BaseButton>
            <BaseButton onClick={handleSubmit}>Добавить</BaseButton>
          </>
        )}
      </BaseModalActions>
    </div>
  );
};

export default EditOwnerModal;
