import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams, useNavigate, To } from "react-router-dom";
import { OCC } from "@/types";
import { paths } from "@/consts/routes";
import { arrowLeftIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { getDraftStatus } from "@/app/store/slices/dictionary";
import { setOccId, getOccDetails } from "@/app/store/slices/createOcc";
import CreateOccItemPageLoader from "../../PageLoader";
import CreateOccItemPageSubheader from "../../PageSubheader";
import BaseButton from "@/components/base-button";
import styles from "./styles.module.scss";

interface IFinishStepProps {
  adminTitle: JSX.Element | JSX.Element[] | React.ReactNode | string | null;
  adminList: Array<JSX.Element | JSX.Element[] | React.ReactNode | string | null>;
  prevLink: To;
  createNewPath: To;
}

const CreateOccItemFinishStep: React.FC<IFinishStepProps> = ({ adminTitle, adminList, prevLink, createNewPath }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const urlParams = useParams();

  const { occId, order, plannedEvents } = useSelector((state: RootState) => state.createOcc);
  const draftStatus = useSelector(getDraftStatus);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function fetch() {
    setIsLoading(true);
    try {
      if (!occId && urlParams?.occId) {
        dispatch(setOccId(parseInt(urlParams?.occId)));
      }

      await dispatch(getOccDetails()).unwrap();
    } catch (error: any) {
      if (error.message === "no occ id") {
        navigate(createNewPath);
      }
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!order || !plannedEvents) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isLoading ? (
        <CreateOccItemPageLoader />
      ) : order?.StatusId !== draftStatus?.id ? (
        <>
          <CreateOccItemPageSubheader>
            <h1 className={`title-h1 ${styles["created-title"]}`}>
              Cобрание {order?.Number && `№${order?.Number}`} <br />
              успешно создано!
            </h1>
            <div>
              <NavLink to={paths.myOcc}>
                <BaseButton variant="primary" appearance="positive">
                  Мои собрания
                </BaseButton>
              </NavLink>
            </div>
          </CreateOccItemPageSubheader>

          <div className={styles["finish-step"]}>
            <div className={styles["finish-step__content"]}>
              <div>
                <h3 className="headline-h3 sf-bold color--text-primary">Что мы сделаем за Вас?</h3>
                <div className={styles["finish-step__content--lokoloside"]}>
                  <div>
                    <h4 className="headline-h4 sf-text-bold">{plannedEvents?.Publication.Date}</h4>
                    <p className="text--primary sf-text-regular color--text-primary">
                      {plannedEvents?.Publication.Text}
                    </p>
                  </div>
                  <div>
                    <h4 className="headline-h4 sf-text-bold">{plannedEvents?.Start.Date}</h4>
                    <p className="text--primary sf-text-regular color--text-primary">{plannedEvents?.Start.Text}</p>
                  </div>
                  <div>
                    <h4 className="headline-h4 sf-text-bold">{plannedEvents?.End.Date}</h4>
                    <p className="text--primary sf-text-regular color--text-primary">{plannedEvents?.End.Text}</p>
                  </div>
                  <div>
                    <h4 className="headline-h4 sf-text-bold">{plannedEvents?.CreateProtocol.Date}</h4>
                    <p className="text--primary sf-text-regular color--text-primary">
                      {plannedEvents?.CreateProtocol.Text}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="headline-h3 sf-bold color--text-primary">{adminTitle}</h3>
                <div className={styles["finish-step__content--authorside"]}>
                  {adminList.map((it, index) => (
                    <div key={index}>
                      <span className="flex middle center headline-h4 sf-text-bold">{index + 1}</span>
                      <div>
                        <p className="text--primary sf-text-medium color--text-primary">{it}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <CreateOccItemPageSubheader>
          <h2 className="headline-h2 sf-bold">
            Статус собрания {order?.Number && `№${order?.Number}`} – {(order as OCC.OccOrderItemFull)?.StatusName}
          </h2>
          <div>
            <NavLink to={prevLink}>
              <BaseButton variant="secondary" appearance="positive" startIcon={arrowLeftIcon()}>
                Назад
              </BaseButton>
            </NavLink>
          </div>
        </CreateOccItemPageSubheader>
      )}
    </div>
  );
};

export default CreateOccItemFinishStep;
