import React, { useCallback, useEffect, useState } from "react";
import { Collapsed } from "./collapsed";
import { Expanded } from "./expanded";

import styles from "./accordion.module.scss";

/**
 * @prop {React.ReactElement<AccordionItemProps>[]} children - React elements representing the accordion items.
 * Each accordion item should consist of two parts: a Collapsed section and an Expanded section.
 *
 * @prop {string} initial - The initial ID of the item to be open when the accordion is rendered.
 * Provide the `id` of the item you want to be open by default.
 *
 * @prop {React.CSSProperties} style - Custom styles
 */
interface IAccordion {
  children: React.ReactElement<AccordionItemProps>[] | React.ReactElement<AccordionItemProps>;
  initial?: string | number;
  style?: React.CSSProperties;
}

/**
 * @prop {string} id - The ID of the Accordion Item.
 * @prop {(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void} handleOpen - Function to handle the click event for expanding and collapsing the item.
 */
interface AccordionItemProps {
  handleOpen?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isOpen?: Boolean;
}

const Accordion = ({ children, initial = "", style }: IAccordion) => {
  const [open, setOpen] = useState<string | number>(initial);

  const handleOpen = useCallback(
    (id: string | number) => {
      if (String(id) === String(open)) {
        setOpen("");
      } else {
        setOpen(id);
      }
    },
    [open]
  );

  useEffect(() => {
    if (initial) setOpen(initial);
  }, [initial]);

  return (
    <section className={styles.root}>
      {React.Children.map(children, (child: any, index: number) => {
        const id = child?.props?.children[0]?.props?.id;
        const isOpen = open === id;

        const collapsed = React.cloneElement(child.props.children[0], {
          id,
          isOpen,
          handleOpen,
        });
        const expanded = React.cloneElement(child.props.children[1], {
          id,
          isOpen,
        });

        return (
          <div style={style}>
            {collapsed}
            {expanded}
          </div>
        );
      })}
    </section>
  );
};

Accordion.Item = ({ children }: any) => children;

export { Accordion, Collapsed, Expanded };
