import React, { useContext, useMemo } from "react";
import { CustomCollapsed, Expanded } from "@/components/base-accordion";
import AccordionContext from "@/components/base-accordion/context";
import styles from "./styles.module.scss";
import { chevronRight16Icon } from "@/consts/icons";

interface IAccordionCardProps {
  id: string;
  title: string;
  children: JSX.Element | JSX.Element[];
  togglerPrefix: JSX.Element | string;
  actionButton?: JSX.Element | JSX.Element[] | null;
  errorText?: JSX.Element | string;
  succesText?: JSX.Element | string;
}

const ApartmentСomparisonAccordionCard: React.FC<IAccordionCardProps> = ({
  id,
  title,
  children,
  togglerPrefix,
  actionButton,
  errorText,
  succesText,
}) => {
  const context = useContext(AccordionContext);

  const isOpen: boolean = useMemo(() => {
    return id === context?.opened;
  }, [context?.opened, id]);

  return (
    <div className={styles.card}>
      <CustomCollapsed id={id}>
        <div className={styles.collapsed}>
          <div className={styles.title}>
            <h4 className="headline-h4 sf-text-bold color--text-primary">{title}</h4>
            {succesText && <p className="text--primary sf-text-medium color--text-success">{succesText}</p>}
            {errorText && <p className="text--primary sf-text-medium color--text-error">{errorText}</p>}
            <div className={styles.toggler}>
              <p className="sf-text-medium text--primary color--primary">
                {isOpen ? "Свернуть" : "Развернуть"} {togglerPrefix}
              </p>
              <span className="flex middle center" role="spinbutton">
                {chevronRight16Icon("var(--main-primary)")}
              </span>
            </div>
          </div>
          {actionButton && <div>{actionButton}</div>}
        </div>
      </CustomCollapsed>

      <Expanded id={id}>
        <div className={styles.content}>{children}</div>
      </Expanded>
    </div>
  );
};

export default ApartmentСomparisonAccordionCard;
