import styles from "./address-search.module.scss";

const AddressItem = ({
  address,
  onSelect,
}: {
  address: string;
  onSelect: () => void;
}) => {
  return (
    <div className={styles.item} onClick={onSelect} role="button">
      {address}
    </div>
  );
};

export default AddressItem;
