import { createContext } from "react";

export type SelectMenuItem = {
  display: string;
  value: any;
};

type DropdownContextValue = {
  isOpen: boolean;
  value: SelectMenuItem["value"];
  onSelect: (value: SelectMenuItem["value"]) => void;
  toggleOpen: (value: boolean) => void;
};

const DropdownContext = createContext<DropdownContextValue | null>(null);

export default DropdownContext;
