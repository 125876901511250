import { memo } from "react";
import styles from "./spinner.module.scss";

interface ISpinnerProps {
  alt?: string;
  color?: string;
  size?: number;
}

const Spinner = memo(({ alt, color = "var(--main-primary)", size = 24 }: ISpinnerProps) => (
  <div
    className={styles.loadingSpinner}
    style={{
      width: `${size}px`,
      height: `${size}px`,
    }}
  >
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 3.04975C12 2.46999 11.5287 1.9943 10.9521 2.05504C9.34478 2.2244 7.79683 2.78157 6.4443 3.6853C4.79981 4.78412 3.51808 6.3459 2.7612 8.17317C2.00433 10.0004 1.80629 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.2184 16.2032 21.7756 14.6552 21.945 13.0479C22.0057 12.4713 21.53 12 20.9503 12C20.3705 12 19.9077 12.472 19.8309 13.0467C19.6716 14.2381 19.2419 15.3822 18.569 16.3893C17.7009 17.6885 16.467 18.7011 15.0234 19.2991C13.5798 19.8971 11.9912 20.0535 10.4587 19.7487C8.92614 19.4439 7.51841 18.6914 6.4135 17.5865C5.3086 16.4816 4.55615 15.0739 4.25131 13.5413C3.94646 12.0088 4.10292 10.4202 4.70089 8.97661C5.29886 7.53298 6.31149 6.29909 7.61072 5.43097C8.61777 4.75809 9.76195 4.32838 10.9533 4.16914C11.528 4.09233 12 3.62951 12 3.04975Z"
        fill={color}
      />
    </svg>
  </div>
));

export { Spinner };
