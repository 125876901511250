import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Dictionary, OCCV2QuestionForms, OCCV2Questions, Profile } from "@/types";
import { DictionariesService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { selectStatusById } from "@/app/store/slices/dictionary";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { createOccQuestion, deleteOccQuestion, saveLocalOccChanges } from "@/app/store/slices/createOcc";
import BaseButton from "@/components/base-button";
import BaseInput from "@/components/base-input";
import BaseTextarea from "@/components/base-textarea";
import Checkbox from "@/components/checkbox";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import UploadFileButton from "@/components/upload-file-button";
import BaseFileItem from "@/components/base-file-item";
import QuestionTemplateDropdown from "./QuestionTemplateDropdown";
import styles from "./styles.module.scss";

interface ICreateQuestionModalProps {
  isOpen: boolean;
  selectedQuestion?: OCCV2Questions.Item | null;
  onClose: () => void;
  quorumTooltipMessage?: string;
}

const CreateQuestionModal: React.FC<ICreateQuestionModalProps> = ({
  isOpen,
  selectedQuestion,
  onClose,
  quorumTooltipMessage,
}) => {
  const dispatch = useAppDispatch();

  const profile = useSelector((state: RootState) => state.user.profile);
  const quorumsList = useSelector((state: RootState) => state.dictionary.quorums.list);
  const order = useSelector((state: RootState) => state.createOcc.order);
  const status = useSelector(selectStatusById(order?.StatusId));

  const [form, setForm] = useState<OCCV2QuestionForms.CreateQuestion>(new OCCV2QuestionForms.CreateQuestion());
  const [isFileLoading, setFileLoading] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<any | null>(null);

  const isSNTProfile: boolean = useMemo(
    () => profile?.role === Profile.UserRole.Company && profile?.company_type === Profile.CompanyType.SNT,
    [profile]
  );

  function updateFormFieldValue(key: "Title" | "Value", value: string) {
    setForm((prevState) => ({
      ...prevState,
      [key]: { ...form[key], value },
    }));
  }

  function updateQuorumValue(value: Dictionary.DictionaryItem["id"]) {
    const type = quorumsList.find((it) => it.id === value);
    if (type) {
      setForm((prevState) => ({
        ...prevState,
        Quorum: { ...type },
      }));
    }
  }

  async function handleChangeFile(file: File) {
    setFileLoading(true);
    try {
      const { data } = await DictionariesService.saveDocument(file);
      setForm((prevState) => ({
        ...prevState,
        Files: [...prevState.Files, data.Data],
      }));
    } catch (error) {
      console.warn(error);
    } finally {
      setFileLoading(false);
    }
  }

  function handleError(payload: any) {
    if (payload.Errors) {
      Object.keys(payload.Errors).forEach((key: any) => {
        const message = Array.isArray(payload.Errors[key]) ? payload.Errors[key][0] : payload.Errors[key];
        switch (key) {
          case "Questions.0.Title": {
            setForm((prevState) => ({
              ...prevState,
              Title: { ...form.Title, error: message },
            }));
            break;
          }

          case "Questions.0.Value": {
            setForm((prevState) => ({
              ...prevState,
              Value: { ...form.Value, error: message },
            }));
            break;
          }

          case "Questions.0.Quorum": {
            setForm((prevState) => ({
              ...prevState,
              QuorumError: message,
            }));
            break;
          }

          default: {
            setForm((prevState) => ({ ...prevState, error: message }));
            dispatch(showSnackbar({ key: "create-occ-question-error", body: message, type: SnackbarType.ERROR }));
            break;
          }
        }
      });
    } else {
      setForm((prevState) => ({ ...prevState, error: payload.Message }));
      dispatch(showSnackbar({ key: "create-occ-question-error", body: payload.Message, type: SnackbarType.ERROR }));
    }
  }

  function isFormValid() {
    const validator = OCCV2QuestionForms.CreateQuestionValidator.get().isInvalid(form);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        Title: {
          ...form.Title,
          error: validator.Title,
        },
        Value: {
          ...form.Value,
          error: validator.Value,
        },
        QuorumError: validator.Quorum,
      }));
    }
    return !validator;
  }

  async function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });

    setForm((prevState) => ({
      ...prevState,
      error: "",
      QuorumError: "",
    }));

    if (!isFormValid()) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    if (form.Quorum) {
      try {
        if (order && status && status.key === Dictionary.OCCStatus.OccPlanned) {
          await dispatch(
            saveLocalOccChanges({
              question: {
                Id: form.questionId ?? undefined,
                QuorumId: form.Quorum?.id,
                Title: form.Title.value.trim().replace(/ +(?= )/g, ""),
                Value: form.Value.value.trim().replace(/ +(?= )/g, ""),
                AvailableForAllSectionOwner: isSNTProfile ? form.AvailableForAllSectionOwner : undefined,
                FileIds: form.Files.map((it) => it.DocumentId) ?? [],
                Files: form.Files,
                Order: selectedQuestion?.Order ?? undefined,
              },
            })
          ).unwrap();
        } else {
          await dispatch(
            createOccQuestion({
              Id: form.questionId ?? undefined,
              QuorumId: form.Quorum?.id,
              Title: form.Title.value.trim().replace(/ +(?= )/g, ""),
              Value: form.Value.value.trim().replace(/ +(?= )/g, ""),
              AvailableForAllSectionOwner: isSNTProfile ? form.AvailableForAllSectionOwner : undefined,
              FileIds: form.Files.map((it) => it.DocumentId) ?? [],
              Order: selectedQuestion?.Order ?? undefined,
            })
          ).unwrap();
        }

        onClose();
      } catch (error: any) {
        console.warn(error);
        handleError(error.Data);
      } finally {
        setForm((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    }
  }

  async function onQuestionDelete() {
    if (!form.questionId) {
      return;
    }

    setDeleteLoading(true);
    try {
      if (order && status && status.key === Dictionary.OCCStatus.OccPlanned) {
        await dispatch(
          saveLocalOccChanges({
            deleteQuestion: form.questionId,
          })
        ).unwrap();
      } else {
        await dispatch(deleteOccQuestion(form.questionId)).unwrap();
      }
      onClose();
    } catch (error) {
      console.warn(error);
    } finally {
      setDeleteLoading(false);
    }
  }

  async function onFileDelete(documentId: Dictionary.File["DocumentId"]) {
    const fileIndex = form.Files.findIndex((it) => it.DocumentId === documentId);
    const files =
      fileIndex >= 0 ? [...form.Files.slice(0, fileIndex), ...form.Files.slice(fileIndex + 1)] : [...form.Files];

    if (form.questionId && form.Quorum) {
      try {
        await dispatch(
          createOccQuestion({
            Id: form.questionId ?? undefined,
            QuorumId: form.Quorum?.id,
            Title: form.Title.value,
            Value: form.Value.value,
            AvailableForAllSectionOwner: form.AvailableForAllSectionOwner,
            FileIds: files.map((it) => it.DocumentId) ?? [],
          })
        ).unwrap();
      } catch (error: any) {
        console.warn(error);
      }
    }

    setForm((prevState) => ({
      ...prevState,
      Files: files,
    }));
  }

  function onTemplateSelect(question: any) {
    const quorum = quorumsList.find((it) => it.id === question.QuorumId);
    setForm((prevState) => ({
      ...prevState,
      Quorum: quorum ? { ...quorum } : null,
      Title: { ...prevState.Title, value: question.Title },
      Value: { ...prevState.Value, value: question.Value },
    }));

    setSelectedTemplateId(question.Id);
  }

  useEffect(() => {
    setForm(new OCCV2QuestionForms.CreateQuestion(selectedQuestion ?? undefined, quorumsList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const isSubmitDisabled: boolean = useMemo(
    () =>
      form.Title.value.length === 0 ||
      (form.Title.maxlength && form.Title.value.length > form.Title.maxlength) ||
      form.Value.value.length === 0 ||
      (form.Value.maxlength && form.Value.value.length > form.Value.maxlength) ||
      form.Quorum === null,
    [form]
  );

  return (
    <BaseModal isOpen={isOpen} minWidth="670px" maxWidth="670px">
      <BaseModalHeader title={selectedQuestion ? "Редактировать вопрос" : "Добавить вопрос"} onClose={onClose}>
        <BaseDropdown
          customToggler={
            <BaseButton appearance="positive" size="small">
              Шаблоны
            </BaseButton>
          }
        >
          <QuestionTemplateDropdown selectedId={selectedTemplateId} onSelect={onTemplateSelect} />
        </BaseDropdown>
      </BaseModalHeader>

      <BaseModalContent sx={{ padding: "16px 24px" }}>
        <form className={styles["b-create-question-form"]} onSubmit={handleOnSubmit}>
          {isSNTProfile && (
            <div>
              <div className="flex middle" style={{ gap: "10px" }}>
                <Checkbox
                  checked={form.AvailableForAllSectionOwner}
                  onChange={(value) =>
                    setForm((prevState) => ({
                      ...prevState,
                      AvailableForAllSectionOwner: value,
                    }))
                  }
                />
                <p className="sf-text-regular text--primary">
                  Голосование доступно для собственников, не являющихся членами садоводства
                </p>
              </div>
            </div>
          )}

          <div>
            <BaseDropdown
              value={form.Quorum?.id ?? null}
              display={form.Quorum?.name}
              isSelectable={true}
              label="Кворум по вопросу повестки"
              tooltipMessage={quorumTooltipMessage}
              placeholder="Не менее 2/3 от голосов, принявших участие в голосовании"
              variant="formfield"
              required={true}
              onSelect={updateQuorumValue}
            >
              {quorumsList.map((quorum) => (
                <BaseDropdownMenuItem key={quorum.id} value={quorum.id}>
                  <span className="sf-text-medium">{quorum.name}</span>
                </BaseDropdownMenuItem>
              ))}
            </BaseDropdown>
          </div>

          <div>
            <BaseInput
              value={form.Title.value}
              label="Заголовок вопроса"
              required={form.Title.isRequired}
              placeholder="Например: Выбрать систему для проведения общих собраний"
              errorMessage={form.Title.error}
              maxlength={form.Title.maxlength}
              counter={true}
              onChange={(value) => updateFormFieldValue("Title", value)}
            />
          </div>

          <div>
            <BaseTextarea
              minRows={1}
              value={form.Value.value}
              label="Введите текст вопроса"
              required={form.Value.isRequired}
              tooltipMessage="Введите текст вопроса. Опишите предполагаемый вариант решения"
              placeholder="Например: Применять государственную информационную систему Республики Татарстан «Локоло» при проведении общих собраний в форме заочного голосования с использованием системы"
              errorMessage={form.Value.error}
              maxlength={form.Value.maxlength}
              counter={true}
              onChange={(value) => updateFormFieldValue("Value", value)}
            />
          </div>

          {form.Files.length > 0 && (
            <div>
              <h4 className="headline-h4 sf-text-bold">Прикрепленные файлы:</h4>
              <div className={styles["b-create-question-form__files-list"]}>
                {form.Files.map((file) => (
                  <BaseFileItem key={file.Id} file={file} onDelete={() => onFileDelete(file.DocumentId)} />
                ))}
              </div>
            </div>
          )}

          <div>
            <UploadFileButton accept="*" onChangeFile={(file) => handleChangeFile(file)}>
              <BaseButton
                variant="secondary"
                size="medium"
                isLoading={isFileLoading}
                onClick={(event) => event.preventDefault()}
                startIcon={
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.0003 4.1665C11.0003 3.61422 10.5526 3.1665 10.0003 3.1665C9.44804 3.1665 9.00033 3.61422 9.00033 4.1665V8.99984H4.16699C3.61471 8.99984 3.16699 9.44755 3.16699 9.99984C3.16699 10.5521 3.61471 10.9998 4.16699 10.9998H9.00033V15.8332C9.00033 16.3855 9.44804 16.8332 10.0003 16.8332C10.5526 16.8332 11.0003 16.3855 11.0003 15.8332V10.9998H15.8337C16.3859 10.9998 16.8337 10.5521 16.8337 9.99984C16.8337 9.44755 16.3859 8.99984 15.8337 8.99984H11.0003V4.1665Z"
                      fill="#226DFF"
                    />
                  </svg>
                }
              >
                <span>Прикрепить файл</span>
              </BaseButton>
            </UploadFileButton>
          </div>
        </form>
      </BaseModalContent>

      <BaseModalActions variant="bordered">
        <div className={styles["b-create-question-form__submit"]}>
          <div>
            {form.questionId && (
              <BaseButton appearance="negative" isLoading={isDeleteLoading} onClick={onQuestionDelete}>
                Удалить
              </BaseButton>
            )}
          </div>

          <div>
            <BaseButton variant="secondary" onClick={onClose}>
              Отменить
            </BaseButton>

            <BaseButton disabled={isSubmitDisabled} isLoading={form.isLoading} onClick={handleOnSubmit}>
              {form.questionId ? "Сохранить" : "Добавить"}
            </BaseButton>
          </div>
        </div>
      </BaseModalActions>
    </BaseModal>
  );
};

export default CreateQuestionModal;
