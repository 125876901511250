import styles from "./styles.module.scss";

type ISntPassportContentProps = {
  children: JSX.Element | JSX.Element[] | React.ReactNode;
};

const SntPassportContent: React.FC<ISntPassportContentProps> = ({ children }) => {
  return <div className={styles["b-snt-passport-content"]}>{children}</div>;
};

export default SntPassportContent;
