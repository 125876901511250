import { To, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/app/store";
import { setCancelModalOpen } from "@/app/store/slices/createOcc";
import { arrowLeftIcon } from "@/consts/icons";
import BaseButton from "@/components/base-button";
import styles from "./styles.module.scss";

interface IBottomBarProps {
  children: JSX.Element;
  prevLink?: To;
}

const BottomBar: React.FC<IBottomBarProps> = ({ children, prevLink }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handlePrevClick = () => {
    if (prevLink) {
      navigate(prevLink, { state: location.state });
    }
  };

  const showCancelModalOpen = () => {
    dispatch(setCancelModalOpen(true));
  };

  return (
    <div className={styles["b-bottom-bar"]}>
      <div>
        <BaseButton variant="secondary" appearance="negative" onClick={showCancelModalOpen}>
          Удалить
        </BaseButton>
      </div>
      <div className={styles["b-bottom-bar__submit"]}>
        {prevLink && (
          <BaseButton variant="secondary" appearance="positive" startIcon={arrowLeftIcon()} onClick={handlePrevClick}>
            Назад
          </BaseButton>
        )}

        {children}
      </div>
    </div>
  );
};

export default BottomBar;
