import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OCC, Pagination } from "@/types";

export interface SntMyOccState {
  occ: OCC.OccFullDetails | null;
  list: OCC.OccOrderItem[];
  isLoading: boolean;
  IsActual: boolean | null;
  query: string;
  page: Pagination | null;
  documents: OCC.OCCDocument[];
  owners: OCC.RegistryOwner[];
  results: {
    progress: OCC.Progress | null;
    owners: OCC.OwnersQuestionsResultsResponse[];
    questions: OCC.QuestionResult[];
  };
}

const initialState: SntMyOccState = {
  occ: null,
  list: [],
  isLoading: false,
  IsActual: null,
  query: "",
  page: null,
  documents: [],
  owners: [],
  results: {
    progress: null,
    owners: [],
    questions: [],
  },
};

const sntMyOccSlice = createSlice({
  name: "snt-my-occ",
  initialState,
  reducers: {
    setOCC: (state, action: PayloadAction<SntMyOccState["occ"]>) => {
      state.occ = action.payload;
    },

    setList: (state, action: PayloadAction<SntMyOccState["list"]>) => {
      state.list = action.payload;
    },

    pushList: (state, action: PayloadAction<SntMyOccState["list"]>) => {
      state.list.push(...action.payload);
    },

    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },

    toggleIsActual: (state, action: PayloadAction<SntMyOccState["IsActual"]>) => {
      state.IsActual = action.payload;
    },

    setPagination: (state, action: PayloadAction<SntMyOccState["page"]>) => {
      state.page = action.payload;
    },

    setDocuments: (state, action: PayloadAction<SntMyOccState["documents"]>) => {
      state.documents = action.payload;
    },

    setOwners: (state, action: PayloadAction<SntMyOccState["owners"]>) => {
      state.owners = action.payload;
    },

    setProgress: (state, action: PayloadAction<SntMyOccState["results"]["progress"]>) => {
      state.results.progress = action.payload;
    },

    setOwnersResults: (state, action: PayloadAction<SntMyOccState["results"]["owners"]>) => {
      state.results.owners = action.payload;
    },

    setQuestionsResults: (state, action: PayloadAction<SntMyOccState["results"]["questions"]>) => {
      state.results.questions = action.payload;
    },

    reset: (state) => {
      state.occ = null;
      state.documents = [];
      state.owners = [];
      state.results = {
        progress: null,
        owners: [],
        questions: [],
      };
    },
  },
  extraReducers: {},
});

export const {
  setOCC,
  setList,
  pushList,
  toggleLoading,
  setQuery,
  toggleIsActual,
  setPagination,
  setDocuments,
  setOwners,
  setProgress,
  setOwnersResults,
  setQuestionsResults,
  reset,
} = sntMyOccSlice.actions;

export default sntMyOccSlice.reducer;
