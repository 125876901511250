import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

type ISntPassportYearsFilterProps = {
  value: number;
  options: number[];
  onChange: (value: ISntPassportYearsFilterProps["value"]) => void;
};

const SntPassportYearsFilter: React.FC<ISntPassportYearsFilterProps> = ({ value, options, onChange }) => {
  function handleOnClick(year: ISntPassportYearsFilterProps["value"]) {
    onChange(year);
  }

  return (
    <div className={styles["b-snt-passport-years-filter"]}>
      <div className={styles["b-snt-passport-years-filter__row"]}>
        {options.map((it) => (
          <span
            key={it}
            className={cx("b-snt-passport-years-filter__item", {
              "b-snt-passport-years-filter__item--active": value === it,
            })}
            role="button"
            onClick={() => handleOnClick(it)}
          >
            <span className="text--primary sf-text-medium">{it}</span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default SntPassportYearsFilter;
