import { Form, FormFiled, FormValidator, ResponseAbstract } from "../";
import errorMessages from "@/helpers/errorMessages.json";

export namespace SntRegistry {
  export type Section = {
    Section: SectionIds;
    Owners: SectionOwner[];
  };

  export type SectionIds = {
    Id: number;
    Number: string;
  };

  export type SectionOwner = {
    Id: number;
    FullName: string;
    Phone: string;
    IsMember: boolean;
    RegistrationAddress: string;
    MailingAddress: string;
    Email: string;
    LokoloUserId: number;
  };

  export type SntDetails = {
    Id: number;
    Name: string;
    SectionsCount: number;
    SectionsOwnerCount: number;
    SectionsGardenOwnerCount: number;
    SectionsOwnerLastUpdatedAt: string;
    IsCanUpdateReestr: boolean;
  };

  export type SectionFullDetails = {
    Section: SectionIds & {
      CadastralNumber?: string;
      ArchivalNumber?: string;
      Area?: string;
    };
    Owners: SectionOwner[];
  };

  export class SectionForm extends Form {
    readonly SntId: SntDetails["Id"] | null = null;
    Number: FormFiled;
    Area: FormFiled;
    CadastralNumber: FormFiled;
    ArchivalNumber: FormFiled;
    Owners: Array<CreatedOwner | SectionOwner> = [];
    CreatedOwners: CreatedOwner[] = [];
    UpdatedOwners: SectionOwner[] = [];
    DeletedOwners: SectionOwner["Id"][] = [];
    OwnersError: string = "";

    constructor(SntId?: SntDetails["Id"], section?: SectionFullDetails) {
      super();
      this.SntId = SntId ?? null;
      this.Number = new FormFiled(section?.Section.Number ?? "", true, false, 7);
      this.Area = new FormFiled(section?.Section.Area ?? "", false);
      this.CadastralNumber = new FormFiled(section?.Section?.CadastralNumber ?? "", false);
      this.ArchivalNumber = new FormFiled(section?.Section.ArchivalNumber ?? "", false);
      this.Owners = section?.Owners ?? [];
    }
  }

  export class SectionFormValidator {
    static validateOwners(owners: SectionForm["Owners"]) {
      if (owners.length === 0) return errorMessages.owners.empty;

      return "";
    }

    static validateNumber(field: SectionForm["Number"]) {
      const errorMessage = FormValidator.getFieldErrorMessage(field.value.trim(), field.isRequired);

      if (errorMessage) {
        return errorMessage;
      }

      const regex = /^\d[а-яА-Я\d\/ -]*$/i;
      if (
        (field.isRequired || (!field.isRequired && field.value.trim().length > 0)) &&
        !regex.test(field.value.trim())
      ) {
        return `Неверный формат номера участка. Номер должен начинаться с цифры, а также может содержать кириллические буквы и символы "/-"`;
      }

      return "";
    }

    public static isInvalid(form: SectionForm) {
      const errors = {
        Number: this.validateNumber(form.Number),
        Area: FormValidator.getFieldErrorMessage(form.Area.value.trim(), form.Area.isRequired),
        CadastralNumber: FormValidator.validateCadastralNumber(
          form.CadastralNumber.value.trim(),
          form.CadastralNumber.isRequired
        ),
        ArchivalNumber: FormValidator.getFieldErrorMessage(
          form.ArchivalNumber.value.trim(),
          form.ArchivalNumber.isRequired
        ),
        Owners: this.validateOwners(form.Owners),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export class OwnerForm extends Form {
    FullName: FormFiled;
    RegistrationAddress: FormFiled;
    MailingAddress: FormFiled;
    Email: FormFiled;
    Phone: FormFiled;
    IsMember: boolean;

    constructor(owner?: SectionOwner | CreatedOwner) {
      super();
      this.FullName = new FormFiled(owner?.FullName ?? "", true);
      this.RegistrationAddress = new FormFiled(owner?.RegistrationAddress ?? "", false);
      this.MailingAddress = new FormFiled(owner?.MailingAddress ?? "", false);
      this.Email = new FormFiled(owner?.Email ?? "", false);
      this.Phone = new FormFiled(owner?.Phone ?? "", false);
      this.IsMember = owner?.IsMember ?? false;
    }
  }

  export class OwnerFormValidator {
    public static isInvalid(form: OwnerForm) {
      const errors = {
        FullName: FormValidator.getFieldErrorMessage(form.FullName.value.trim(), form.FullName.isRequired),
        RegistrationAddress: FormValidator.getFieldErrorMessage(
          form.RegistrationAddress.value.trim(),
          form.RegistrationAddress.isRequired
        ),
        MailingAddress: FormValidator.getFieldErrorMessage(
          form.MailingAddress.value.trim(),
          form.MailingAddress.isRequired
        ),
        Email: FormValidator.getEmailErrorMessage(form.Email.value.trim(), form.Email.isRequired),
        Phone: FormValidator.getPhoneErrorMessage(form.Phone.value.trim(), form.Phone.isRequired),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export type CreateSectionRequestBody = {
    SntId: SntDetails["Id"];
    Number: string;
    Area?: string;
    CadastralNumber?: string;
    ArchivalNumber?: string;
    CreatedOwners: Omit<CreatedOwner, "Id">[];
  };

  export type UpdateSectionRequestBody = CreateSectionRequestBody & {
    Id: SectionIds["Id"];
    CreatedOwners?: Omit<CreatedOwner, "Id">[];
    UpdatedOwners?: SectionOwner[];
    DeletedOwners?: Array<{ Id: SectionOwner["Id"] }>;
  };

  export type CreatedOwner = {
    Id: number;
    FullName: string;
    RegistrationAddress: string;
    MailingAddress: string;
    Email: string;
    Phone: string;
    IsMember: boolean;
  };

  export type OwnerChangeInclude = {
    OwnerId: SectionOwner["Id"];
    Type: "OwnerChange";
    Attributes: {
      Deleted: boolean;
      Updated: boolean;
    };
  };

  export type SectionDetailsResponse = ResponseAbstract<SectionFullDetails> & {
    Included: OwnerChangeInclude[];
  };
}
