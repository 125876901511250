import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  analyticsIcon,
  dropdownSecondaryChevron,
  fileUploadIcon,
  tableToolbarAddIcon,
  tableToolbarUploadIcon,
  xlsFileIcon,
} from "@/consts/icons";
import { paths } from "@/consts/routes";
import { UKRegistryService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { activeAddress } from "@/app/store/slices/user";
import { SnackbarType, showSnackbar } from "@/app/store/slices/snackbar";
import {
  toggleDeleteModal,
  toggleEditModal,
  checkTotalArea,
  fetchHouseDetails,
  loadMoreHouseApartments,
  setOwnersSourceFilter,
} from "@/app/store/slices/uk/registry";
import { startRosreestrDataUpload } from "@/app/store/slices/uk/rosreestr";
import { UKRegistry } from "@/types";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import registryFolderIcon from "@/assets/images/registryFolder.svg";
import registrySearchIcon from "@/assets/images/registrySearch.svg";
import BaseButton from "@/components/base-button";
import BaseTooltip from "@/components/base-tooltip";
import BaseHeadlineAlert from "@/components/BaseHeadlineAlert";
import RegistryFileErrorsModal from "@/features/RegistryFileErrorsModal";
import { Spinner } from "@/components/spinner";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseTableToolbar,
  BaseContainer,
} from "@/components/base-table";
import UploadFileModal from "@/components/upload-file-modal";
import ApartmentRow from "./ApartmentRow";
import DeleteApartmentModal from "./modals/DeleteApartment";
import EditApartmentModal from "./modals/EditApartment";
import styles from "./styles.module.scss";

const UKRegistryMain: React.FC = () => {
  const dispatch = useAppDispatch();
  const address = useSelector(activeAddress);
  const { query, ownersSource, apartments, isLoading, pagination, details } = useSelector(
    (state: RootState) => state.ukRegistry
  );
  const isStartRosreestrUploadLoading = useSelector(
    (state: RootState) => state.ukRosreestr.isStartRosreestrUploadLoading
  );
  const rosreestrDataLoadInfo = useSelector((state: RootState) => state.ukRosreestr.details);

  const { measureRef, isIntersecting, observer } = useInfiniteScroll();
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  const [isRegistryFileErrorsShown, setRegistryFileErrorsShown] = useState<boolean>(false);
  const [registryFileErrors, setRegistryFileErrors] = useState<string[]>([]);

  const hasMore = useMemo(() => pagination && pagination.Index < pagination.Count, [pagination]);

  function checkHouseArea() {
    return dispatch(checkTotalArea()).unwrap();
  }

  function loadFromRosreestr() {
    dispatch(startRosreestrDataUpload());
  }

  const showAddModal = async () => {
    const resp = await dispatch(checkTotalArea()).unwrap();
    if (resp) {
      dispatch(toggleEditModal({ isOpen: true, mode: "add" }));
    }
  };

  const showViewModal = (apartment: UKRegistry.Apartment) => {
    dispatch(
      toggleEditModal({
        isOpen: true,
        apartment,
        mode: "view",
      })
    );
  };

  const showEditModal = (apartment: UKRegistry.Apartment) => {
    dispatch(
      toggleEditModal({
        isOpen: true,
        apartment,
        mode: "edit",
      })
    );
  };

  const showDeleteModal = (apartment: UKRegistry.Apartment) => {
    dispatch(toggleDeleteModal({ isOpen: true, apartment }));
  };

  const updateFilter = (source: UKRegistry.OwnerSource) => {
    if (ownersSource === source) {
      dispatch(setOwnersSourceFilter(null));
      return;
    }

    dispatch(setOwnersSourceFilter(source));
  };

  async function exportFile() {
    if (details?.Id) {
      let params: any = {};
      if (query.length > 0) {
        params["OwnerSearch"] = query;
      }
      if (ownersSource) {
        params["Source"] = ownersSource;
      }
      const { data } = await UKRegistryService.exportRegistry({
        ...params,
        HouseFiasId: address?.FiasId,
        limit: pagination ? pagination.Count * pagination.Limit : 1000,
      });
      let filename = "Реестр_помещений.xlsx";
      filename = decodeURI(filename);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    }
  }

  async function handleChangeFile(file: File | null) {
    if (!file || !address?.Address) return;

    try {
      await UKRegistryService.importRegistryFile(file, address?.Address);
      await dispatch(fetchHouseDetails()).unwrap();
    } catch (error: any) {
      if (error?.response?.data?.Data?.Errors?.length > 0) {
        setRegistryFileErrors(error?.response?.data?.Data?.Errors);
        setRegistryFileErrorsShown(true);
        return;
      }

      dispatch(
        showSnackbar({
          key: "import-registry-file-error",
          body: error?.response?.data?.Data?.Message ?? "Неизвестная ошибка",
          type: SnackbarType.ERROR,
        })
      );
    }
  }

  async function fetch() {
    try {
      await dispatch(fetchHouseDetails()).unwrap();
    } catch (error) {
      dispatch(
        showSnackbar({
          key: "fetch-house-details",
          body: error && typeof error === "string" ? error : "Неизвестная ошибка. Попробуйте позже еще раз",
          type: SnackbarType.ERROR,
        })
      );
    }
  }

  const fetchMoreData = async () => {
    try {
      const { Index, Count } = await dispatch(loadMoreHouseApartments()).unwrap();
      if (observer && isIntersecting && Index === Count) {
        observer.disconnect();
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
  }, [isIntersecting]);

  useEffect(() => {
    fetch();
  }, [address]);

  return (
    <>
      <div className={styles.toolbar}>
        <UploadFileModal
          onBeforeModalOpen={checkHouseArea}
          title="Загрузить реестр собственников"
          id="upload-snt-land-registry"
          onChangeFile={(file) => handleChangeFile(file)}
        >
          <BaseButton startIcon={fileUploadIcon("white")}>Загрузить реестр</BaseButton>
        </UploadFileModal>

        <NavLink to={paths.uk().fullPath().registry.analytics}>
          <BaseButton variant="secondary" appearance="neutral" endIcon={analyticsIcon}>
            Аналитика по реестру
          </BaseButton>
        </NavLink>
      </div>

      {query.length === 0 && ownersSource === null && !isLoading && apartments.length === 0 && (
        <BaseHeadlineAlert
          title="Для работы с системой необходим реестр собственников недвижимости"
          name="upload-registry"
        >
          <p>
            Загрузите реестр участков в формате .xlsx, добавляйте вручную или пропустите эти шаги, нажав на кнопку
            «Запросить из Росреестра»
          </p>
        </BaseHeadlineAlert>
      )}

      <BaseContainer className={styles.table}>
        <BaseTableToolbar
          caption={
            details?.UpdatedAt
              ? `Последнее обновление: ${new Date(details?.UpdatedAt.parseFromEpochSeconds()).formatDateTime()}`
              : "Данных по реестру нет"
          }
        >
          <div className="flex middle" style={{ gap: "8px" }}>
            <BaseTooltip title="Добавить помещение вручную">
              <BaseButton variant="icon-basic" onClick={showAddModal}>
                {tableToolbarAddIcon}
              </BaseButton>
            </BaseTooltip>
            <UploadFileModal
              onBeforeModalOpen={checkHouseArea}
              title="Загрузить реестр собственников"
              id="upload-snt-land-registry"
              onChangeFile={(file) => handleChangeFile(file)}
            >
              <BaseTooltip title="Загрузить реестр собственников">
                <BaseButton variant="icon-basic">{tableToolbarUploadIcon}</BaseButton>
              </BaseTooltip>
            </UploadFileModal>
          </div>

          <BaseTooltip title="Cкачать файл Excel">
            <BaseButton variant="icon-basic" disabled={apartments.length === 0} onClick={exportFile}>
              {xlsFileIcon}
            </BaseButton>
          </BaseTooltip>

          <BaseDropdown
            className={styles.filters}
            placeholder="Фильтр"
            value={ownersSource}
            isSelectable={true}
            disabled={(query.length === 0 && ownersSource === null && apartments.length === 0) || isLoading}
            onSelect={(value) => updateFilter(value)}
            customIcon={dropdownSecondaryChevron}
          >
            <div className={styles.filters_options}>
              <BaseDropdownMenuItem value={UKRegistry.OwnerSource.LokoloMobileApp} defaultHover>
                <span className="sf-text-regular">Мобильное приложение</span>
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={UKRegistry.OwnerSource.Rosreestr} defaultHover>
                <span className="sf-text-regular">Росреестр</span>
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={UKRegistry.OwnerSource.ManualLoading} defaultHover>
                <span className="sf-text-regular">Загружены вручную</span>
              </BaseDropdownMenuItem>
            </div>
          </BaseDropdown>
        </BaseTableToolbar>

        <div className={styles.table_container}>
          <BaseTable className={styles.table_itself}>
            <BaseTableHead className={styles.table_head}>
              <BaseTableRow className={styles.table_row}>
                <BaseTableHeadCell>№ помещения</BaseTableHeadCell>
                <BaseTableHeadCell>Площадь (м²)</BaseTableHeadCell>
                <BaseTableHeadCell>Данные собственника</BaseTableHeadCell>
                <BaseTableHeadCell>Вид собственности</BaseTableHeadCell>
                <BaseTableHeadCell>Доля</BaseTableHeadCell>
                <BaseTableHeadCell>Откуда поступили данные</BaseTableHeadCell>
                <BaseTableHeadCell></BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            {isLoading ? (
              <BaseTableBody className={styles.empty} refProp={emptyBlockRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={8} style={{ height: `${emptyBlockHeight}px` }}>
                    <div className="flex center">
                      <Spinner color="#226dff" size={36} />
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            ) : apartments.length ? (
              <>
                {apartments.map((it, index) => (
                  <ApartmentRow
                    key={it.Id}
                    apartment={it}
                    onOpen={() => showViewModal(it)}
                    onEdit={() => showEditModal(it)}
                    onDelete={() => showDeleteModal(it)}
                  />
                ))}
                {hasMore && (
                  <tbody className={styles.loading_more} ref={measureRef}>
                    <tr>
                      <td colSpan={8}>
                        <div className="flex center">
                          <Spinner color="#226dff" size={36} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </>
            ) : (
              <BaseTableBody className={styles.empty} refProp={emptyBlockRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={8} style={{ height: `${emptyBlockHeight}px` }}>
                    <div className="center">
                      {query.length > 0 || ownersSource !== null ? (
                        <p className="text--primary sf-pro-regular color--text-primary">Поиск не дал результатов</p>
                      ) : rosreestrDataLoadInfo?.LastLoadDate && !rosreestrDataLoadInfo?.IsLoaded ? (
                        <div className={styles.empty_content}>
                          <img width="200" height="200" src={registryFolderIcon} alt="" />
                          <div>
                            <h4 className="headline-h4 sf-text-bold color--text-primary">
                              Информация из Росреестра запрошена
                            </h4>
                            <p className="text--primary sf-pro-regular color--text-secondary">
                              Получение и обработка данных обычно занимает 1-2 полных дня. <br />
                              Отслеживать поступление данных вы можете на вкладке "Данные из Росреестра". <br />
                              Вы можете работать с полученными данными и переносить их в свой реестр не дожидаясь
                              завершения.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.empty_content}>
                          <img width="200" height="200" src={registrySearchIcon} alt="" />
                          <div>
                            <h4 className="headline-h4 sf-text-bold color--text-primary">
                              Реестра собственников пока нет
                            </h4>
                            <p className="text--primary sf-pro-regular color--text-primary">
                              Вы можете загрузить свой реестр или запросить данные из Росреестра
                            </p>
                          </div>
                          <BaseButton
                            disabled={
                              !!(
                                rosreestrDataLoadInfo?.LastLoadDate &&
                                rosreestrDataLoadInfo?.IsLoaded &&
                                !rosreestrDataLoadInfo?.CanReload
                              )
                            }
                            onClick={loadFromRosreestr}
                            isLoading={isStartRosreestrUploadLoading}
                          >
                            Запросить из Росреестра
                          </BaseButton>
                        </div>
                      )}
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            )}
          </BaseTable>
        </div>
      </BaseContainer>

      <RegistryFileErrorsModal
        isOpen={isRegistryFileErrorsShown}
        errors={registryFileErrors}
        onClose={() => setRegistryFileErrorsShown(false)}
      />

      <DeleteApartmentModal />
      <EditApartmentModal />
    </>
  );
};

export default UKRegistryMain;
