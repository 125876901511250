export const markerIcon = (
  <svg width="33" height="40" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_7031_38549)">
      <rect className="marker-primary-color" x="0.5" width="32" height="32" rx="16" />
      <path d="M16.5 23V16" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M9.60002 15H10.5C12.919 15 14.9367 16.7178 15.4 19H14.5C12.081 19 10.0633 17.2822 9.60002 15Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M16.5709 14.9996C20.0654 14.965 22.9469 12.3697 23.4291 9.00035C19.9346 9.03505 17.0531 11.6303 16.5709 14.9996Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <path className="marker-primary-color" d="M11.5 31H21.5L16.5 40L11.5 31Z" />
    <defs>
      <filter
        id="filter0_b_7031_38549"
        x="-3.5"
        y="-4"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7031_38549" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7031_38549" result="shape" />
      </filter>
    </defs>
  </svg>
);

export const addMarkerIcon = (
  <svg width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_7059_100593)">
      <rect width="32" height="32" rx="16" fill="url(#paint0_linear_7059_100593)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4 9C16.731 9 17 9.269 17 9.6V15H22.4C22.731 15 23 15.269 23 15.6V16.4C23 16.731 22.731 17 22.4 17H17V22.4C17 22.731 16.731 23 16.4 23H15.6C15.269 23 15 22.731 15 22.4V17H9.6C9.269 17 9 16.731 9 16.4V15.6C9 15.269 9.269 15 9.6 15H15V9.6C15 9.269 15.269 9 15.6 9H16.4Z"
        fill="white"
      />
    </g>
    <rect x="14" y="33.5" width="4" height="4" rx="2" fill="url(#paint1_linear_7059_100593)" />
    <defs>
      <filter
        id="filter0_b_7059_100593"
        x="-4"
        y="-4"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7059_100593" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7059_100593" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_7059_100593"
        x1="2.66667"
        y1="16"
        x2="29.2709"
        y2="17.2889"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF6445" />
        <stop offset="1" stopColor="#FF3F97" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7059_100593"
        x1="14.3333"
        y1="35.5"
        x2="17.6589"
        y2="35.6611"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF6445" />
        <stop offset="1" stopColor="#FF3F97" />
      </linearGradient>
    </defs>
  </svg>
);
