import { removeUserRole } from "@/app/store/slices/access/actions";
import BaseButton from "@/components/base-button";
import BaseModal, { BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import { useAppDispatch } from "@/app/store";
import { useState } from "react";
import { Access } from "@/types";

interface IRemoveUserAccessModalProps {
  isOpen: boolean;
  user: Access.User;
  onClose: () => void;
}

const RemoveUserAccessModal: React.FC<IRemoveUserAccessModalProps> = ({ isOpen, user, onClose }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
  };

  const handleRemoveAccess = async () => {
    setLoading(true);
    try {
      await dispatch(removeUserRole(user.Id)).unwrap();
      onClose();
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal isOpen={isOpen} minWidth="426px">
      <BaseModalHeader title="Удалить участника группы?" onClose={handleClose} />

      <BaseModalContent>
        <p className="sf-text-regular text--primary">
          Вы уверены что хотите удалить участника группы <br />
          <span className="sf-text-semibold">«{user?.User?.Phone?.formatPhone()}</span>»?
        </p>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton variant="secondary" onClick={onClose}>
          Отменить
        </BaseButton>
        <BaseButton isLoading={isLoading} onClick={handleRemoveAccess}>
          Удалить
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default RemoveUserAccessModal;
