import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleDeleteModal } from "@/app/store/slices/snt/registry";
import { deleteSection } from "@/app/store/slices/snt/registry/actions";
import BaseButton from "@/components/base-button";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { useMemo } from "react";

const DeleteModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isOpen, section, isLoading } = useSelector((state: RootState) => state.sntRegistry.deleteModal);

  const isHasLokoloUserId: boolean = useMemo(() => {
    return section?.Owners.some((it) => it.LokoloUserId !== null) ?? false;
  }, [section]);

  function closeModal() {
    dispatch(toggleDeleteModal({ isOpen: false }));
  }

  async function removeSection() {
    if (section?.Section.Id === undefined || section?.Section.Id === null) {
      return;
    }

    try {
      await dispatch(deleteSection(section?.Section.Id)).unwrap();
      closeModal();
      dispatch(
        showSnackbar({
          key: `delete-section-${section?.Section.Id}`,
          body: "Участок удален",
          type: SnackbarType.SUCCESS,
        })
      );
    } catch (error: any) {
      let message;
      if (error?.Data?.Errors?.Section && Array.isArray(error?.Data?.Errors?.Section)) {
        message = error.Data.Errors.Section[0];
      } else if (error?.Data?.Message) {
        message = error.Data.Message;
      }
      dispatch(
        showSnackbar({
          key: `delete-section-${section?.Section.Id}`,
          body: message ?? "Неизвестная ошибка. Попробуйте позже",
          type: SnackbarType.ERROR,
        })
      );
    }
  }

  return (
    <BaseModal isOpen={isOpen} maxWidth="426px" minWidth="426px">
      <BaseModalHeader title="Удалить участок из реестра?" onClose={closeModal} />

      {isHasLokoloUserId ? (
        <>
          <BaseModalContent>
            <p className="text--primary sf-text-regular">
              Вы пытаетесь удалить запись с подтвержденной собственностью в мобильном приложении Локоло. В случае
              удаления данной записи - подтверждение собственности в мобильном приложении садовода будет отменено.
              Удалить запись?
            </p>
          </BaseModalContent>

          <BaseModalActions>
            <BaseButton variant="secondary" onClick={closeModal}>
              Нет
            </BaseButton>

            <BaseButton isLoading={isLoading} onClick={removeSection}>
              Да
            </BaseButton>
          </BaseModalActions>
        </>
      ) : (
        <>
          <BaseModalContent>
            <p className="text--primary sf-text-regular">
              Вы уверены что хотите удалить участок <span className="sf-text-semibold">№{section?.Section.Number}</span>{" "}
              из реестра?
            </p>
          </BaseModalContent>

          <BaseModalActions>
            <BaseButton variant="secondary" onClick={closeModal}>
              Отменить
            </BaseButton>

            <BaseButton isLoading={isLoading} onClick={removeSection}>
              Удалить
            </BaseButton>
          </BaseModalActions>
        </>
      )}
    </BaseModal>
  );
};

export default DeleteModal;
