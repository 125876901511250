import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProfileService } from "@/services/v2";
import { House, Profile } from "@/types";
import { RootState } from "../..";
import {
  setUser,
  setCompany,
  setActiveAddressFiasId,
  setToken,
  TOKEN_STORAGE_KEY,
  activeAddress,
  setCadastralNumber,
  setCadastralNumberValue,
  setInstitution,
} from ".";
import { clearStoredDictionaries, fetchDictionaries } from "../dictionary/actions";
import { getSntDetails } from "../snt/registry/actions";

const fetchUser = createAsyncThunk("user/fetch-user", async (data: undefined, { rejectWithValue, dispatch }) => {
  try {
    const userResponse = await ProfileService.getUser();
    dispatch(setUser(userResponse.data));

    if (userResponse.data.role === Profile.UserRole.Company) {
      const { data } = await ProfileService.getCompanyData();
      dispatch(setCompany(data.Data));

      const addressResponse = await ProfileService.getActiveAddress();
      dispatch(setActiveAddressFiasId(addressResponse.data.Data.ActiveAddress));
      await dispatch(fetchDictionaries()).unwrap();

      if (data.Data.Type === Profile.CompanyType.UK) {
        let address = data.Data.Addresses.find((it) => addressResponse.data.Data.ActiveAddress);
        if (!address && data.Data.Addresses.length > 0) {
          await dispatch(setActiveAddress(data.Data.Addresses[0])).unwrap();
          address = data.Data.Addresses[0];
        }
      } else if (data.Data.Type === Profile.CompanyType.SNT) {
        dispatch(getSntDetails()).unwrap();
      }
    } else if (userResponse.data.role === Profile.UserRole.Institution) {
      const { data } = await ProfileService.getInstitutionData();
      dispatch(setInstitution(data.Data));
    }

    return userResponse.data;
  } catch (err: any) {
    if (err?.response?.status === 403) {
      return rejectWithValue({
        Data: { Message: "Нет доступа" },
      });
    }
    return rejectWithValue(err?.response?.data);
  }
});

const authLogin = createAsyncThunk(
  "user/login",
  async (
    payload: { email?: string; password?: string; phone?: string; code?: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await ProfileService.login(payload);

      dispatch(setToken(data.Token));
      return data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const authLogout = createAsyncThunk("user/logout", async (data: undefined, { rejectWithValue, dispatch }) => {
  try {
    await ProfileService.logout();
    dispatch(clearStoredDictionaries());
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    localStorage.removeItem("hidden--alerts");
    localStorage.removeItem("hidden--system-messages");
    window.location.reload();
  } catch (err: any) {
    return rejectWithValue(err?.response?.data);
  }
});

const setActiveAddress = createAsyncThunk(
  "user/set-active-address",
  async (payload: House.Item, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setActiveAddressFiasId(payload.FiasId));
      await ProfileService.setActiveAddress(payload.FiasId);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getCadastralNumber = createAsyncThunk<Profile.CadastralNumber, undefined>(
  "user/get-cadastral-number",
  async (payload: undefined, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);

    if (!address?.Id) {
      throw Error("no address id");
    }

    try {
      const { data } = await ProfileService.getCadastralNumber(address.Id);
      dispatch(setCadastralNumber(data.Data));
      return data.Data;
    } catch (err: any) {
      dispatch(setCadastralNumber(null));
      return rejectWithValue(err?.response?.data);
    }
  }
);

const checkIfCadastralNumberExist = createAsyncThunk<boolean, undefined>(
  "user/check-if-cadastral-number-exist",
  async (payload: undefined, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);

    if (!address?.Id) {
      throw Error("no address id");
    }

    if (
      rootState.user.cadastralNumber?.CadastralNumber &&
      rootState.user.cadastralNumber?.AddressId === address.Id &&
      rootState.user.cadastralNumber?.Status.Key === Profile.CadastralNumberStatus.Confirmed
    ) {
      return true;
    }

    try {
      const data = await dispatch(getCadastralNumber()).unwrap();
      if (
        data.CadastralNumber &&
        data.AddressId === address.Id &&
        data.Status.Key === Profile.CadastralNumberStatus.Confirmed
      ) {
        return true;
      }

      return false;
    } catch (err: any) {
      return false;
    }
  }
);

const saveCadastralNumber = createAsyncThunk(
  "user/save-cadastral-number",
  async (payload: Profile.CadastralNumber["CadastralNumber"], { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);

    if (!address?.Id) {
      throw Error("no address id");
    }

    try {
      await ProfileService.setCadastralNumber(address.Id, payload);
      dispatch(setCadastralNumberValue(payload));
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);

export {
  fetchUser,
  authLogin,
  authLogout,
  setActiveAddress,
  getCadastralNumber,
  checkIfCadastralNumberExist,
  saveCadastralNumber,
};
