export namespace TemplateGroups {
  export type GroupQuestionsResponse = {
    Id: number;
    Title: string;
    Order: number;
    IsDefault: boolean;
    QuorumId?: number;
  };

  export type GroupQuestionsCreate = {
    Order?: number;
    Title: string;
  };
}
