import { useContext, useEffect } from "react";
import classNames from "classnames/bind";
import DropdownContext from "@/components/base-dropdown/context";
import BaseButton from "@/components/base-button";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IPeriodYearsPickerOptionsProps {
  years: number[];
  startYear?: number;
  endYear?: number;
  reset: () => void;
  setStartYear: (value?: number) => void;
  setEndYear: (value?: number) => void;
  applyYears: () => void;
  setOpen: (value: boolean) => void;
}

const PeriodYearsPickerOptions: React.FC<IPeriodYearsPickerOptionsProps> = ({
  years,
  startYear,
  endYear,
  setStartYear,
  setEndYear,
  applyYears,
  reset,
  setOpen,
}) => {
  const context = useContext(DropdownContext);

  function onYearSelect(value: number) {
    if (value === startYear) {
      if (value === endYear) {
        setStartYear(undefined);
        setEndYear(undefined);
        return;
      }

      if (endYear && endYear > startYear) {
        setStartYear(value + 1);
        return;
      }
    }

    if (value === endYear && startYear && startYear < endYear) {
      setEndYear(value - 1);
      return;
    }

    if (!startYear && !endYear) {
      setStartYear(value);
      setEndYear(value);
      return;
    }

    if (startYear && value >= startYear) {
      setEndYear(value);
      return;
    }

    if (startYear && value < startYear) {
      setStartYear(value);
      return;
    }
  }

  function apply() {
    applyYears();
    context?.toggleOpen(false);
  }

  useEffect(() => {
    reset();
    setOpen(true);

    return () => {
      setOpen(false);
    };
  }, []);

  return (
    <div className={styles["b-period-years-picker__options"]}>
      <div className={styles["b-period-years-picker__options_years"]}>
        {years.map((year) => (
          <span
            className={cx("b-period-years-picker__item", {
              "b-period-years-picker__item--selected": startYear && endYear && year >= startYear && year <= endYear,
            })}
            key={year}
            onClick={() => onYearSelect(year)}
          >
            <span className="sf-text-medium color--text-primary text--primary">{year}</span>
          </span>
        ))}
      </div>
      <BaseButton size="medium" style={{ width: "100%" }} onClick={apply}>
        Применить
      </BaseButton>
    </div>
  );
};

export default PeriodYearsPickerOptions;
