import client from "./config";
import { ResponseAbstract, Templates } from "@/types";

export namespace TemplatesService {
  // Groups

  export function getDefaultTemplateGroups() {
    return client.get<ResponseAbstract<Templates.Group[]>>("/api/default-data/occ-questions/get-groups");
  }

  export function getTemplateGroups() {
    return client.get<ResponseAbstract<Templates.Group[]>>("/api/occ/templates/groups");
  }

  export function getTemplateGroupById(id: Templates.Group["Id"]) {
    return client.get<ResponseAbstract<Templates.Group>>(`/api/occ/templates/groups/${id}`);
  }

  export function createTemplateGroup(Title: string, Order?: number) {
    return client.post<ResponseAbstract<Templates.Group>>("/api/occ/templates/groups", { Title, Order });
  }

  export function updateTemplateGroup(id: Templates.Group["Id"], Title?: string, Order?: number) {
    return client.patch<ResponseAbstract<Templates.Group>>(`/api/occ/templates/groups/${id}`, {
      Title,
      Order,
    });
  }

  export function deleteTemplateGroup(id: Templates.Group["Id"]) {
    return client.delete<unknown>(`/api/occ/templates/groups/${id}`);
  }

  // Questions

  export function getDefaultTemplateQuestionsByGroupId(GroupId: Templates.Group["Id"]) {
    return client.get<ResponseAbstract<Templates.Question[]>>(
      "/api/default-data/occ-questions/get-questions-by-group",
      {
        params: { GroupId },
      }
    );
  }

  export function getTemplateQuestionsByGroupId(GroupId: Templates.Group["Id"], Title?: string) {
    return client.get<ResponseAbstract<Templates.Question[]>>("/api/occ/templates/questions", {
      params: { GroupId, Title },
    });
  }

  export function getTemplateQuestionById(id: Templates.Question["Id"]) {
    return client.get<ResponseAbstract<Templates.Question>>(`/api/occ/templates/questions/${id}`);
  }

  export function createTemplateQuestion(body: Templates.CreateQuestionBody) {
    return client.post<ResponseAbstract<Templates.Question>>("/api/occ/templates/questions", body);
  }

  export function updateTemplateQuestion(
    id: Templates.Question["Id"],
    {
      Title,
      Value,
      QuorumId,
      Order,
    }: {
      Title?: Templates.CreateQuestionBody["Title"];
      Value?: Templates.CreateQuestionBody["Value"];
      QuorumId?: Templates.CreateQuestionBody["QuorumId"];
      Order?: Templates.CreateQuestionBody["Order"];
    }
  ) {
    return client.patch<ResponseAbstract<Templates.Question>>(`/api/occ/templates/questions/${id}`, {
      Title,
      Value,
      QuorumId,
      Order,
    });
  }

  export function deleteTemplateQuestion(id: Templates.Question["Id"]) {
    return client.delete<unknown>(`/api/occ/templates/questions/${id}`);
  }
}
