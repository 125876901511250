import { createAsyncThunk } from "@reduxjs/toolkit";
import { UKRegistryService } from "@/services/v2";
import { RootState } from "@/app/store";
import {
  setDetails,
  toggleLoading,
  setPagination,
  setApartments,
  pushApartments,
  setQuery,
  setOwnersSource,
  toggleDeleteModalLoading,
  removeApartmentFromList,
  updateApartmentById,
  UKRegistryState,
  toggleAcceptAreaModal,
} from "./";
import { setDetails as setRosreestrInfoDetails } from "../rosreestr";
import { activeAddress } from "@/app/store/slices/user";
import { Pagination, UKRegistry } from "@/types";

export const fetchHouseDetails = createAsyncThunk<UKRegistry.Apartment[], undefined>(
  "uk-registry/fetch-house-details",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);
    dispatch(setQuery(""));
    dispatch(setOwnersSource(null));
    dispatch(toggleLoading(true));
    dispatch(setDetails(null));
    dispatch(setApartments([]));
    dispatch(setPagination(null));
    dispatch(setRosreestrInfoDetails(null));
    // dispatch(toggleSectionsLoading(true));
    try {
      if (address?.FiasId) {
        const { data } = await UKRegistryService.getHouseRegistryDetails(address?.FiasId);
        dispatch(setDetails(data.Data));
        const rosreestrInfo = await UKRegistryService.getRosreestrDataLoadInfo(address?.FiasId);
        dispatch(setRosreestrInfoDetails(rosreestrInfo.data.Data));
        dispatch(toggleLoading(false));
        const apartmentsResponse = await dispatch(fetchHouseApartments()).unwrap();
        return apartmentsResponse;
      }

      throw Error("no address id");
    } catch (err: any) {
      dispatch(toggleLoading(false));
      return rejectWithValue(err?.response?.data?.Data?.Message);
    } finally {
      // dispatch(toggleSectionsLoading(false));
    }
  }
);

export const updateHouseDetailsOnBackground = createAsyncThunk<unknown, undefined>(
  "uk-registry/update-house-details-on-background",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);
    try {
      if (address?.FiasId) {
        const { data } = await UKRegistryService.getHouseRegistryDetails(address?.FiasId);
        dispatch(setDetails(data.Data));
        return data.Data;
      }
      return null;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

export const fetchHouseApartments = createAsyncThunk<UKRegistry.Apartment[], undefined>(
  "uk-registry/fetch-house-apartments",
  async (data, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);
    dispatch(toggleLoading(true));
    dispatch(setApartments([]));
    dispatch(setPagination(null));
    try {
      if (address?.FiasId) {
        let body: UKRegistry.GetHouseApartmentsRequestBody = {
          HouseFiasId: address?.FiasId,
        };
        if (rootState.ukRegistry.query) {
          body["OwnerSearch"] = rootState.ukRegistry.query;
        }
        if (rootState.ukRegistry.ownersSource) {
          body["Source"] = rootState.ukRegistry.ownersSource;
        }
        const { data } = await UKRegistryService.getHouseApartments({ body });
        dispatch(setApartments(data.Data));
        dispatch(setPagination(data.Page));
        return data.Data;
      }

      throw Error("no address");
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    } finally {
      dispatch(toggleLoading(false));
    }
  }
);

export const loadMoreHouseApartments = createAsyncThunk<Pagination, undefined>(
  "uk-registry/load-more-house-apartments",
  async (data, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);
    try {
      if (address?.FiasId) {
        let body: UKRegistry.GetHouseApartmentsRequestBody = {
          HouseFiasId: address?.FiasId,
        };
        if (rootState.ukRegistry.query) {
          body["OwnerSearch"] = rootState.ukRegistry.query;
        }
        if (rootState.ukRegistry.ownersSource) {
          body["Source"] = rootState.ukRegistry.ownersSource;
        }
        const { data } = await UKRegistryService.getHouseApartments({
          body,
          page: (rootState.ukRegistry.pagination?.Index ?? 0) + 1,
        });
        dispatch(pushApartments(data.Data));
        dispatch(setPagination(data.Page));
        return data.Page;
      }

      throw Error("no id");
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

export const setSearchQuery = createAsyncThunk(
  "uk-registry/set-search-query",
  async (query: string, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    if (rootState.ukRegistry.query === query) {
      return;
    }

    dispatch(setQuery(query));
    dispatch(fetchHouseApartments());
  }
);

export const setOwnersSourceFilter = createAsyncThunk(
  "uk-registry/set-owners-source-filter",
  async (source: UKRegistryState["ownersSource"], { rejectWithValue, getState, dispatch }) => {
    dispatch(setOwnersSource(source));
    dispatch(fetchHouseApartments());
  }
);

export const deleteApartment = createAsyncThunk<unknown, UKRegistry.Apartment>(
  "uk-registry/delete-apartment",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    dispatch(toggleDeleteModalLoading(true));
    try {
      if (rootState.ukRegistry.details?.Id) {
        const response = await UKRegistryService.deleteApartment({
          HouseFiasId: rootState.ukRegistry.details?.FiasId,
          HouseId: rootState.ukRegistry.details?.Id,
          Id: payload.Id,
          Number: payload.Number,
        });
        await dispatch(removeApartmentFromList(payload.Id));
        dispatch(updateHouseDetailsOnBackground());
        return response.data;
      }

      throw Error("no snt id");
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    } finally {
      dispatch(toggleDeleteModalLoading(false));
    }
  }
);

export const createApartment = createAsyncThunk<unknown, UKRegistry.CreateApartmentRequestBody>(
  "uk-registry/create-apartment",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await UKRegistryService.createApartment(payload);
      await dispatch(fetchHouseApartments());
      dispatch(updateHouseDetailsOnBackground());
      return data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const updateApartment = createAsyncThunk<unknown, UKRegistry.UpdateApartmentRequestBody>(
  "uk-registry/update-apartment",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await UKRegistryService.updateApartment(payload);
      dispatch(updateHouseDetailsOnBackground());
      if (response.data) {
        await dispatch(updateApartmentById(response.data.Data));
        return response.data;
      } else {
        await dispatch(fetchHouseApartments());
        return;
      }
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const checkTotalArea = createAsyncThunk<boolean, undefined>(
  "uk-registry/check-total-area",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);
    if (!rootState.ukRegistry.details && address?.FiasId) {
      try {
        const { data } = await UKRegistryService.getHouseRegistryDetails(address?.FiasId);
        dispatch(setDetails(data.Data));
        if (!data?.Data?.Area) {
          dispatch(toggleAcceptAreaModal(true));
          return false;
        }

        return true;
      } catch (error) {
        dispatch(toggleAcceptAreaModal(true));
        return false;
      }
    }

    if (!rootState.ukRegistry.details?.Area) {
      dispatch(toggleAcceptAreaModal(true));
      return false;
    }

    return true;
  }
);

export const saveHouseArea = createAsyncThunk<unknown, string>(
  "uk-registry/save-house-area",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);

    try {
      if (!address?.FiasId) {
        throw Error("no address id");
      }
      const response = await UKRegistryService.saveHouseArea(address?.FiasId, payload);
      if (!response.data.Data) {
        throw Error("unknown");
      }
      dispatch(updateHouseDetailsOnBackground());
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);
