import { useSelector } from "react-redux";
import { privacyIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleSizeSharesErrorModal } from "@/app/store/slices/uk/rosreestr";
import BaseButton from "@/components/base-button";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";

const SizeSharesErrorModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useSelector((state: RootState) => state.ukRosreestr.isSizeSharesErrorModalShown);

  const closeModal = () => {
    dispatch(toggleSizeSharesErrorModal(false));
  };

  return (
    <BaseModal isOpen={isOpen} maxWidth="520px" minWidth="520px">
      <BaseModalHeader
        variant="info-iconed"
        iconBgColor="#FFF5CE"
        icon={privacyIcon}
        title="Ошибка в долях"
        onClose={closeModal}
      />

      <BaseModalContent>
        <p className="text--primary sf-text-regular color--text-primary">
          Некорректно указаны доли собственников. Это влияет на правильность расчета голосов
        </p>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton onClick={closeModal}>Исправить</BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default SizeSharesErrorModal;
