import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { Profile, Templates } from "@/types";
import { RootState } from "../../";

interface TemplatesState {
  groups: Templates.GroupWithQuestions[];
  defaultGroups: Templates.GroupWithQuestions[];
}

const initialState: TemplatesState = {
  groups: [],
  defaultGroups: [Templates.mandatoryQuestions],
};

const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<Templates.Group[]>) => {
      state.groups = action.payload.map((it) => JSON.parse(JSON.stringify(new Templates.GroupWithQuestions(it))));
    },

    setDefaultGroups: (state, action: PayloadAction<Templates.Group[]>) => {
      const groups = action.payload.map((it) => JSON.parse(JSON.stringify(new Templates.GroupWithQuestions(it, true))));
      state.defaultGroups = [Templates.mandatoryQuestions, ...groups];
    },

    setQuestionsByGroupId: (
      state,
      action: PayloadAction<{ groupId: Templates.Group["Id"]; questions: Templates.Question[] }>
    ) => {
      const group = state.groups.find((it) => it.group.Id === action.payload.groupId);
      if (group) {
        group.questions = action.payload.questions;
      }
    },

    setDefaultQuestionsByGroupId: (
      state,
      action: PayloadAction<{ groupId: Templates.Group["Id"]; questions: Templates.Question[] }>
    ) => {
      const group = state.defaultGroups.find((it) => it.group.Id === action.payload.groupId);
      if (group) {
        group.questions = action.payload.questions;
      }
    },

    removeQuestion: (
      state,
      action: PayloadAction<{ groupId: Templates.Group["Id"]; questionId: Templates.Question["Id"] }>
    ) => {
      const group = state.groups.find((it) => it.group.Id === action.payload.groupId);
      if (!group) return;

      const index = group.questions.findIndex((it) => it.Id === action.payload.questionId);
      if (index >= 0) {
        group.questions.splice(index, 1);
      }
    },

    pushQuestion: (state, action: PayloadAction<{ groupId: Templates.Group["Id"]; question: Templates.Question }>) => {
      const group = state.groups.find((it) => it.group.Id === action.payload.groupId);
      if (!group) return;

      group.questions.push(action.payload.question);
    },

    updateQuestionById: (
      state,
      action: PayloadAction<{ groupId: Templates.Group["Id"]; question: Templates.Question }>
    ) => {
      const group = state.groups.find((it) => it.group.Id === action.payload.groupId);
      if (!group) return;

      const questions = group.questions.map((it) => {
        if (it.Id === action.payload.question.Id) {
          return { ...action.payload.question };
        }
        return it;
      });
      group.questions = questions;
    },

    removeGroup: (state, action: PayloadAction<Templates.Group["Id"]>) => {
      const index = state.groups.findIndex((it) => it.group.Id === action.payload);
      if (index >= 0) {
        state.groups.splice(index, 1);
      }
    },

    pushGroup: (state, action: PayloadAction<Templates.Group>) => {
      state.groups.push(JSON.parse(JSON.stringify(new Templates.GroupWithQuestions(action.payload))));
    },

    updateGroupById: (state, action: PayloadAction<Templates.Group>) => {
      const groups = state.groups.map((it) => {
        if (it.group.Id === action.payload.Id) {
          return {
            ...it,
            group: {
              ...it.group,
              Title: action.payload.Title,
              Order: action.payload.Order,
            },
          };
        }
        return it;
      });

      state.groups = groups;
    },
  },
  extraReducers: {},
});

const templatesSelector = (state: RootState) => state.templates;

export const getTemplateGroups = (type?: Profile.CompanyType): ((state: RootState) => Templates.GroupWithQuestions[]) =>
  createSelector(templatesSelector, (state) => {
    if (type && type === Profile.CompanyType.UK) {
      return [...state.groups, ...state.defaultGroups];
    }

    return state.groups;
  });

export const {
  setGroups,
  setDefaultGroups,
  setQuestionsByGroupId,
  setDefaultQuestionsByGroupId,
  removeQuestion,
  pushQuestion,
  updateQuestionById,
  removeGroup,
  pushGroup,
  updateGroupById,
} = templatesSlice.actions;

export default templatesSlice.reducer;
