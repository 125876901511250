import { useEffect, useState } from "react";
import BaseModal, { BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import BaseInput from "@/components/base-input";
import { useAppDispatch } from "@/app/store";
import { updateGroup, createGroup } from "@/app/store/slices/templates/actions";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { Templates } from "@/types";

interface IEditGroupModalProps {
  isOpen: boolean;
  group?: Templates.Group | null;
  onClose: () => void;
}

const EditGroupModal: React.FC<IEditGroupModalProps> = ({ isOpen, group, onClose }) => {
  const dispatch = useAppDispatch();
  const [form, setForm] = useState<Templates.EditGroupForm>(new Templates.EditGroupForm());

  const handleClose = () => {
    onClose();
  };

  const isFormValid = () => {
    const validator = Templates.EditGroupFormValidator.isInvalid(form);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        Title: {
          ...form.Title,
          error: validator.Title,
        },
      }));
    }
    return !validator;
  };

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setForm((prevState) => ({
      ...prevState,
      Title: { ...prevState.Title, error: "" },
      error: "",
    }));

    if (!isFormValid()) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      if (group?.Id) {
        await dispatch(
          updateGroup({
            Id: group?.Id,
            Title: form.Title.value.trim(),
            Order: group.Order,
          })
        ).unwrap();
      } else {
        await dispatch(createGroup(form.Title.value.trim())).unwrap();
      }
      onClose();
    } catch (error: any) {
      const errorData = error.Data;
      console.log(error);
      if (errorData?.Errors) {
        Object.keys(errorData.Errors).forEach((key: any) => {
          const message = Array.isArray(errorData.Errors[key]) ? errorData.Errors[key][0] : errorData.Errors[key];
          switch (key) {
            case "Title": {
              setForm((prevState) => ({
                ...prevState,
                Title: { ...form.Title, error: message },
              }));
              break;
            }

            default: {
              setForm((prevState) => ({ ...prevState, error: message }));
              dispatch(showSnackbar({ key: "create-template-group-error", body: message, type: SnackbarType.ERROR }));
              break;
            }
          }
        });
      } else {
        setForm((prevState) => ({ ...prevState, error: errorData?.Message }));
        dispatch(
          showSnackbar({
            key: "create-template-group-error",
            body: errorData?.Message ?? "Неизвестная ошибка",
            type: SnackbarType.ERROR,
          })
        );
      }
    } finally {
      setForm((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    setForm(new Templates.EditGroupForm(group ?? undefined));
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} minWidth="670px" maxWidth="670px">
      <BaseModalHeader
        title={!!group ? "Редактировать группу шаблонов" : "Создать группу шаблонов"}
        onClose={handleClose}
      />

      <BaseModalContent>
        <form onSubmit={handleOnSubmit} style={{ padding: "16px 0" }}>
          <BaseInput
            value={form.Title.value}
            label="Название группы"
            required={form.Title.isRequired}
            placeholder="Например: (Сохраненные разделы)"
            errorMessage={form.Title.error}
            maxlength={form.Title.maxlength}
            counter={true}
            onChange={(value) =>
              setForm((prevState) => ({
                ...prevState,
                Title: { ...prevState.Title, value },
              }))
            }
          />
        </form>
      </BaseModalContent>

      <BaseModalActions variant="bordered">
        <BaseButton variant="secondary" onClick={onClose}>
          Отменить
        </BaseButton>
        <BaseButton isLoading={form.isLoading} disabled={form.Title.value.length === 0} onClick={handleOnSubmit}>
          {!!group ? "Сохранить" : "Создать"}
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default EditGroupModal;
