import { SntRegistry, Profile, ResponseAbstract, ResponseAbstractWithPagination, House, Pagination } from "@/types";
import client, { formDataClient, blobClient } from "../config";

export namespace SntRegistryService {
  export function getDetails(ExternalId: House.Item["Id"]) {
    return client.post<ResponseAbstract<SntRegistry.SntDetails>>("/api/reestr/snt/get", { ExternalId });
  }

  export function getSections(params: {
    SntId: SntRegistry.SntDetails["Id"];
    SearchQuery?: string;
    IsSntOwner?: boolean;
    Page?: Pagination["Index"];
    Limit?: number;
  }) {
    return client.get<ResponseAbstractWithPagination<SntRegistry.Section[]>>("/api/reestr/snt/sections", { params });
  }

  export function importRegistryFile(file: File) {
    let formdata = new FormData();
    formdata.append("File", file);
    return formDataClient.post<unknown>("/api/reestr/import/snt", formdata);
  }

  export function exportRegistry(params: {
    SntId: Profile.CompanyData["Id"];
    SearchQuery?: string;
    IsSntOwner?: boolean;
    Limit?: number;
  }) {
    return blobClient.get<any>("/api/reestr/snt/sections/export", { params });
  }

  export function deleteSection(SntId: SntRegistry.SntDetails["Id"], Id: SntRegistry.SectionIds["Id"]) {
    return client.delete<ResponseAbstract<string>>("/api/reestr/snt/sections", { params: { SntId, Id } });
  }

  export function getSectionDetailsById(SntId: SntRegistry.SntDetails["Id"], Id: SntRegistry.SectionIds["Id"]) {
    return client.get<SntRegistry.SectionDetailsResponse>("/api/reestr/snt/sections/get-by-id", {
      params: { SntId, Id, Include: ["OwnerChange"] },
    });
  }

  export function isOwnerDeletePossible(
    SntId: SntRegistry.SntDetails["Id"],
    SectionNumber: SntRegistry.SectionIds["Number"],
    Id: SntRegistry.SectionOwner["Id"]
  ) {
    return client.get<ResponseAbstract<boolean>>("/api/reestr/snt/owner/delete-is-possible", {
      params: { SntId, SectionNumber, Id },
    });
  }

  export function createSection(body: SntRegistry.CreateSectionRequestBody) {
    return client.post<ResponseAbstract<SntRegistry.SectionFullDetails>>("/api/reestr/snt/sections", body);
  }

  export function updateSection(body: SntRegistry.UpdateSectionRequestBody) {
    return client.patch<ResponseAbstract<SntRegistry.SectionFullDetails>>("/api/reestr/snt/sections", body);
  }
}
