import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Access } from "@/types";
import { infoBlueCircleIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { isCompanyTypeUK } from "@/app/store/slices/user";
import { toggleCreateUserModalOpen } from "@/app/store/slices/access";
import { createUserAndSetRole } from "@/app/store/slices/access/actions";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import BaseInput from "@/components/base-input";
import Checkbox from "@/components/checkbox";
import styles from "./styles.module.scss";

const CreateUserModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const isUK = useSelector(isCompanyTypeUK);
  const { isCreateUserModalOpen, Phone, Role, HouseIds } = useSelector(
    (state: RootState) => state.access.setRoleDetails
  );

  const [form, setForm] = useState<Access.CreateUserForm>(new Access.CreateUserForm());

  const close = () => {
    dispatch(toggleCreateUserModalOpen(false));
  };

  const isFormValid = () => {
    const validator = Access.CreateUserFormValidator.isInvalid(form);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        FirstName: {
          ...form.FirstName,
          error: validator.FirstName,
        },
        LastName: {
          ...form.LastName,
          error: validator.LastName,
        },
        AddressObjectNum: {
          ...form.AddressObjectNum,
          error: validator.AddressObjectNum,
        },
      }));
    }
    return !validator;
  };

  const handleError = (payload: any) => {
    if (payload.Errors) {
      Object.keys(payload.Errors).forEach((key: any) => {
        const message = Array.isArray(payload.Errors[key]) ? payload.Errors[key][0] : payload.Errors[key];
        switch (key) {
          case "FirstName": {
            setForm((prevState) => ({
              ...prevState,
              FirstName: { ...form.FirstName, error: message },
            }));
            break;
          }

          case "LastName": {
            setForm((prevState) => ({
              ...prevState,
              LastName: { ...form.LastName, error: message },
            }));
            break;
          }

          case "AddressObjectNum": {
            setForm((prevState) => ({
              ...prevState,
              AddressObjectNum: { ...form.AddressObjectNum, error: message },
            }));
            break;
          }

          default: {
            setForm((prevState) => ({ ...prevState, error: message }));
            dispatch(showSnackbar({ key: "create-user-and-set-role", body: message, type: SnackbarType.ERROR }));
            break;
          }
        }
      });
    } else {
      setForm((prevState) => ({ ...prevState, error: payload.Message }));
      dispatch(showSnackbar({ key: "create-user-and-set-role", body: payload.Message, type: SnackbarType.ERROR }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });

    setForm((prevState) => ({
      ...prevState,
      error: "",
    }));

    if (!isFormValid() || !Role) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      await dispatch(
        createUserAndSetRole({
          FirstName: form.FirstName.value,
          LastName: form.LastName.value,
          Phone: Phone,
          Role: Role,
          IsOwner: !isUK ? form.IsOwner : undefined,
          AddressObjectNum: !isUK && form.IsOwner ? form.AddressObjectNum.value : undefined,
          HouseIds: isUK ? HouseIds : undefined,
        })
      ).unwrap();
    } catch (error: any) {
      handleError(error.Data);
    } finally {
      setForm((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    if (isCreateUserModalOpen) {
      setForm(new Access.CreateUserForm());
    }
  }, [isCreateUserModalOpen]);

  return (
    <BaseModal isOpen={isCreateUserModalOpen} minWidth="520px" maxWidth="520px">
      <BaseModalHeader
        variant="info-iconed"
        iconBgColor="#CCECFC"
        icon={infoBlueCircleIcon}
        title="Пользователь не найден"
        subtitle={
          <p className="text--primary center">
            Пользователя с указанным номером <span className="sf-text-bold">{Phone.formatPhone()}</span> телефона не
            существует в мобильном приложении Локоло. <br />
            Для создания профиля заполните информацию ниже.
          </p>
        }
        onClose={close}
      />

      <BaseModalContent>
        <div className={styles.create_user}>
          <form className={styles.create_user_form} onSubmit={handleSubmit}>
            <div>
              <BaseInput
                value={form.LastName.value}
                label="Фамилия"
                required={false}
                placeholder="Введите фамилию"
                errorMessage={form.LastName.error}
                onChange={(value) =>
                  setForm((prevState) => ({
                    ...prevState,
                    LastName: { ...prevState.LastName, value },
                  }))
                }
              />
            </div>

            <div>
              <BaseInput
                value={form.FirstName.value}
                label="Имя"
                required={false}
                placeholder="Введите имя"
                errorMessage={form.FirstName.error}
                onChange={(value) =>
                  setForm((prevState) => ({
                    ...prevState,
                    FirstName: { ...prevState.FirstName, value },
                  }))
                }
              />
            </div>

            {!isUK && (
              <>
                <div className={styles.full_field}>
                  <div className={styles.checkbox_field}>
                    <Checkbox
                      checked={form.IsOwner}
                      onChange={(value) =>
                        setForm((prevState) => ({
                          ...prevState,
                          IsOwner: value,
                        }))
                      }
                    />
                    <p className="sf-text-regular text--primary">Является жителем садоводства</p>
                  </div>
                </div>

                {form.IsOwner && (
                  <div className={styles.full_field}>
                    <BaseInput
                      value={form.AddressObjectNum.value}
                      label="Номер участка"
                      required={false}
                      placeholder="Введите номер участка"
                      errorMessage={form.AddressObjectNum.error}
                      onChange={(value) =>
                        setForm((prevState) => ({
                          ...prevState,
                          AddressObjectNum: { ...prevState.AddressObjectNum, value },
                        }))
                      }
                    />
                  </div>
                )}
              </>
            )}
          </form>
        </div>
      </BaseModalContent>

      <BaseModalActions>
        {isUK ? (
          <BaseButton
            disabled={form.FirstName.value.trim().length === 0 || form.LastName.value.trim().length === 0}
            isLoading={form.isLoading}
            onClick={handleSubmit}
          >
            Готово
          </BaseButton>
        ) : (
          <>
            <BaseButton variant="secondary" onClick={close}>
              Отменить
            </BaseButton>
            <BaseButton isLoading={form.isLoading} onClick={handleSubmit}>
              Создать профиль
            </BaseButton>
          </>
        )}
      </BaseModalActions>
    </BaseModal>
  );
};

export default CreateUserModal;
