import { SntPassportAnalytics } from "@/types";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import { Spinner } from "@/components/spinner";
import AnalyticsCountCard from "./CountCard";
import AnalyticsCountPercentCard from "./CountPercentCard";
import AnalyticsCountTotalCountPercentCard from "./CountTotalCountPercentCard";
import AnalyticsRingGraphWithTotalCountCard from "./RingGraphWithTotalCountCard";
import AnalyticsWorkScopeArraySumCardCard from "./WorkScopeArraySumCard";
import styles from "./styles.module.scss";

interface IAnalyticsBuilderProps {
  isLoading?: boolean;
  statistics?: SntPassportAnalytics.AnalyticsData;
  bottomInfo?: JSX.Element | JSX.Element[] | React.ReactNode;
  maxInRow?: number;
}

const AnalyticsBuilder: React.FC<IAnalyticsBuilderProps> = ({
  statistics,
  isLoading = false,
  bottomInfo,
  maxInRow = 3,
}) => {
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  function renderCard(card: SntPassportAnalytics.Card) {
    switch (card.Type) {
      case SntPassportAnalytics.CardType.RingGraphWithTotalCount:
        return <AnalyticsRingGraphWithTotalCountCard card={card} />;

      case SntPassportAnalytics.CardType.CountTotalCountPercent:
        return <AnalyticsCountTotalCountPercentCard card={card} />;

      case SntPassportAnalytics.CardType.CountPercent:
        return <AnalyticsCountPercentCard card={card} />;

      case SntPassportAnalytics.CardType.Count:
        return <AnalyticsCountCard card={card} />;

      case SntPassportAnalytics.CardType.WorkScopeArraySum:
        return <AnalyticsWorkScopeArraySumCardCard card={card} />;
    }
  }

  if (isLoading) {
    return (
      <div
        ref={emptyBlockRef}
        className="center"
        style={{ display: "flex", alignItems: "center", height: `${emptyBlockHeight + 24}px`, minHeight: "350px" }}
      >
        <Spinner size={36} />
      </div>
    );
  }

  if ((statistics?.Top && statistics?.Top.length > 0) || (statistics?.Groups && statistics?.Groups?.length > 0)) {
    return (
      <div ref={emptyBlockRef} style={{ minHeight: `${emptyBlockHeight + 24}px` }} className={styles["b-statistics"]}>
        <div className={styles["b-statistics-wrapper"]}>
          {statistics?.Top && (
            <div className={styles["b-top"]}>
              <div className={styles["b-top__cards"]}>
                <div>
                  {statistics?.Top.map((card, i) => (
                    <div key={i}>
                      <div className={[styles["b-card"], styles["b-card--big"]].join(" ")}>{renderCard(card)}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {statistics?.Groups &&
            statistics?.Groups.map((group, i) => (
              <div className={[styles["b-group"], group.Title ? styles["b-group--titled"] : ""].join(" ")} key={i}>
                {group.Title && <h2 className="headline-h2 sf-bold color--text-primary">{group.Title}</h2>}
                {group.Cards && (
                  <div className={styles["b-group__cards"]}>
                    <div>
                      {group.Cards.map((card, j) => (
                        <div
                          key={j}
                          style={{
                            flexBasis: `calc(100% / ${
                              card.Type === SntPassportAnalytics.CardType.WorkScopeArraySum && card.IsSummary
                                ? 1
                                : maxInRow
                            })`,
                          }}
                        >
                          <div className={[styles["b-card"], styles["b-card--small"]].join(" ")}>
                            {renderCard(card)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>

        {bottomInfo}
      </div>
    );
  }

  return (
    <div
      ref={emptyBlockRef}
      className="center"
      style={{ display: "flex", alignItems: "center", height: `${emptyBlockHeight + 24}px`, minHeight: "350px" }}
    >
      <p className="text--primary sf-pro-regular color--text-primary">Данных пока нет</p>
    </div>
  );
};

export default AnalyticsBuilder;
