import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface IBaseTableHeadProps {
  children: React.ReactNode;
  className?: string;
}

const BaseTableHead: React.FC<IBaseTableHeadProps> = ({ children, className }) => {
  return <thead className={cx(className, { head: true })}>{children}</thead>;
};

export default BaseTableHead;
