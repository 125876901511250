import { errorInfoIcon } from "@/consts/icons";
import { BaseModal, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import styles from "./styles.module.scss";

interface IRegistryFileErrorsModalProps {
  isOpen: boolean;
  errors: string[];
  onClose: () => void;
}

const RegistryFileErrorsModal: React.FC<IRegistryFileErrorsModalProps> = ({ isOpen, errors, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <BaseModal isOpen={isOpen} minWidth="685px" maxWidth="685px">
      <div className={styles.header}>
        <div className="flex between">
          <div className="flex">
            {errorInfoIcon}
            <div className={styles.header_title}>
              <h2 className="headline-h3 sf-text-bold">Невозможно сохранить данные из реестра.</h2>
              <p className="sf-text-semibold text--primary">
                Найдено ошибок: <span className={styles.errors_count}>{errors.length}</span>
              </p>
            </div>
          </div>
          <svg
            className={styles.close_btn}
            onClick={handleClose}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            role="button"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.4142 12L16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289C16.3166 6.90237 15.6834 6.90237 15.2929 7.29289L12 10.5858L8.70711 7.29289C8.31658 6.90237 7.68342 6.90237 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L10.5858 12L7.2929 15.2929C6.90238 15.6834 6.90238 16.3166 7.2929 16.7071C7.68343 17.0976 8.31659 17.0976 8.70712 16.7071L12 13.4142L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L13.4142 12Z"
              fill="#3A3B3D"
            />
          </svg>
        </div>
      </div>

      <BaseModalContent className={styles.import_file_errors_content} sx={{ padding: "16px 24px 16px 80px" }}>
        <div className={styles.import_file_errors}>
          {errors.map((it, index) => (
            <div key={index}>
              <p className="text--primary sf-text-regular">{it}</p>
            </div>
          ))}
        </div>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton variant="secondary" onClick={handleClose}>
          Закрыть
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default RegistryFileErrorsModal;
