import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { paths } from "@/consts/routes";
import { Profile } from "@/types";
import { RootState } from "@/app/store";
import PageHeader from "@/components/PageHeader";
import styles from "./styles.module.scss";

const Settings = () => {
  const company = useSelector((state: RootState) => state.user.company);

  return (
    <>
      <PageHeader title="Настройки системы" />

      <div className={styles.content}>
        <NavLink className={styles.item} to={paths.managementTemplate}>
          <span className={styles.item_content}>
            <h4 className="headline-h4 sf-bold">Управление шаблонами</h4>
            <p className="text--primary sf-text-regular">Создайте и настройте шаблоны вопросов</p>
          </span>
        </NavLink>

        <NavLink className={styles.item} to={paths.accessSettings}>
          <span className={styles.item_content}>
            <h4 className="headline-h4 sf-bold">Настройки доступа</h4>
            <p className="text--primary sf-text-regular">
              Предоставьте своим коллегам расширенные права в мобильном приложении “Локоло”
            </p>
          </span>
        </NavLink>

        {company?.Type === Profile.CompanyType.UK && (
          <>
            <NavLink className={styles.item} to={paths.uk().fullPath().cadastralNumber}>
              <span className={styles.item_content}>
                <h4 className="headline-h4 sf-bold">Кадастровые номера домов</h4>
                <p className="text--primary sf-text-regular">
                  Заполните кадастровые номера домов, по которым вам необходимо получить данные из Росреестра
                </p>
              </span>
            </NavLink>

            <NavLink className={styles.item} to={paths.uk().fullPath().giszhkh}>
              <span className={styles.item_content}>
                <h4 className="headline-h4 sf-bold">Интеграция с ГИС ЖКХ</h4>
                <p className="text--primary sf-text-regular">
                  Предоставьте нам доступ и вы сможете отправлять итоги собраний напрямую в ГИС ЖКХ
                </p>
              </span>
            </NavLink>
          </>
        )}
      </div>
    </>
  );
};

export default Settings;
