import { Typography } from "@mui/material";
import { useMemo } from "react";
import { RootState, useAppDispatch } from "@/app/store";
import { useSelector } from "react-redux";
import { deleteUsefulContacts, toggleRemoveModal } from "@/app/store/slices/contacts";
import { UsefulContacts } from "@/types/contacts";
import BaseButton from "@/components/base-button";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";

const RemoveContact = () => {
  const dispatch = useAppDispatch();
  const { isLoading, isOpen, usefulContactId } = useSelector((state: RootState) => state.contacts.removeModal);
  const usefulContacts = useSelector((state: RootState) => state.contacts.contactsAll.data);
  const company = useSelector((state: RootState) => state.user.company);

  const selectedContact: UsefulContacts.Item | null = useMemo(
    () => usefulContacts.find((it) => it.Id === usefulContactId) ?? null,
    [usefulContactId, usefulContacts]
  );

  const isUK: boolean = useMemo(
    () => usefulContactId != null && usefulContactId < 0 && Math.abs(usefulContactId) === company?.Id,
    [usefulContactId, company]
  );

  const closeRemoveContactModal = () => {
    dispatch(toggleRemoveModal({ isOpen: false }));
  };

  const deleteUsefulContact = async () => {
    dispatch(deleteUsefulContacts(usefulContactId))
      .unwrap()
      .then((res) => {
        dispatch(
          showSnackbar({
            key: `delete-useful-contact-${usefulContactId}`,
            body: "Полезный контакт удален",
            type: SnackbarType.SUCCESS,
          })
        );
      });
  };

  return (
    <BaseModal isOpen={isOpen}>
      <BaseModalHeader title="Удалить контакт" onClose={closeRemoveContactModal} />

      <BaseModalContent>
        <Typography
          fontFamily="SF Pro Text"
          sx={{
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "-0.154px",
          }}
        >
          {isUK
            ? "Контакт вашей организации можно удалить через обращение в техническую поддержку. Для других добавленных контактов функция доступна."
            : `Вы уверены что хотите удалить контакт${selectedContact?.Name ? ` “${selectedContact?.Name}”` : ""}?`}
        </Typography>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton variant="secondary" onClick={closeRemoveContactModal}>
          {isUK ? "Закрыть" : "Отменить"}
        </BaseButton>

        {isUK ? (
          <BaseButton onClick={closeRemoveContactModal}>Понятно</BaseButton>
        ) : (
          <BaseButton isLoading={isLoading} onClick={deleteUsefulContact}>
            Удалить
          </BaseButton>
        )}
      </BaseModalActions>
    </BaseModal>
  );
};

export default RemoveContact;
