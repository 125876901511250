import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { getIntramuralExtramuralForm } from "@/app/store/slices/dictionary";
import CreateOccItemFinishStep from "@/features/CreateOccNew/OccItem/Steps/Preview";

const SntCreateOCCPreviewStep: React.FC = () => {
  const { occId, order } = useSelector((state: RootState) => state.createOcc);
  const intramuralExtramuralForm = useSelector(getIntramuralExtramuralForm);

  const boxes = useMemo(() => {
    return [
      {
        title: "Порядок собрания",
        content: [
          {
            name: "Место проведения заочного собрания",
            value: order && "LocationName" in order ? order?.LocationName : "",
          },
          order && "FormId" in order && order.FormId === intramuralExtramuralForm?.id
            ? {
                name: "Место проведения очного собрания",
                value: order && "IntramuralLocation" in order ? order?.IntramuralLocation : "",
              }
            : null,
          {
            name: "Садоводство",
            value: order?.Address ?? "",
          },
          {
            name: "Номер собрания",
            value: order?.Number ?? "",
          },
          {
            name: "Вид собрания",
            value: order && "TypeName" in order ? order?.TypeName : "",
          },
          {
            name: "Форма собрания",
            value: order && "FormName" in order ? order?.FormName : "",
          },
          {
            name: "Дата публикации сообщения",
            value:
              order && "PublicationTime" in order
                ? order?.PublicationTime
                  ? new Date(order?.PublicationTime?.parseFromEpochSeconds()).formatByPattern("dd.MM.yyyy")
                  : order?.PublicationDate
                : "",
          },
          {
            name: "Старт приема решений",
            value:
              order && "StartTime" in order
                ? order?.StartTime
                  ? new Date(order?.StartTime?.parseFromEpochSeconds()).formatByPattern("dd.MM.yyyy")
                  : order?.StartDate
                : "",
          },
          {
            name: "Окончание приема решений",
            value:
              order && "EndTime" in order
                ? order?.EndTime
                  ? new Date(order?.EndTime?.parseFromEpochSeconds()).formatByPattern("dd.MM.yyyy")
                  : order?.EndDate
                : "",
          },
        ].filter((it) => it?.name),
      },
    ];
  }, [order, intramuralExtramuralForm]);

  return (
    <div>
      <CreateOccItemFinishStep
        boxes={boxes}
        prevLink={`/snt/create-occ/${occId}/agenda`}
        nextLink={`/snt/create-occ/${occId}/finish`}
      />
    </div>
  );
};

export default SntCreateOCCPreviewStep;
