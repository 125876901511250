import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Access, House } from "@/types";

interface AccessState {
  me: Access.User | null;
  users: Access.User[];
  roles: Access.Role[];
  setRoleDetails: {
    isAddAccessModalOpen: boolean;
    isErrorModalOpen: boolean;
    isSuccessModalOpen: boolean;
    isCreateUserModalOpen: boolean;
    isSetUserAddressModalOpen: boolean;
    Phone: string;
    Role: Access.Role["Key"] | null;
    HouseIds: Array<House.Item["Id"]>;
  };
}

const initialState: AccessState = {
  me: null,
  users: [],
  roles: [],
  setRoleDetails: {
    isAddAccessModalOpen: false,
    isErrorModalOpen: false,
    isSuccessModalOpen: false,
    isCreateUserModalOpen: false,
    isSetUserAddressModalOpen: false,
    Phone: "",
    Role: null,
    HouseIds: [],
  },
};

const accessSlice = createSlice({
  name: "access",
  initialState,
  reducers: {
    setMyAccessRole: (state, action: PayloadAction<AccessState["me"]>) => {
      state.me = action.payload;
    },

    setAccessUsers: (state, action: PayloadAction<AccessState["users"]>) => {
      state.users = action.payload;
    },

    setRoles: (state, action: PayloadAction<AccessState["roles"]>) => {
      state.roles = action.payload;
    },

    setRoleForRoleModals: (state, action: PayloadAction<AccessState["setRoleDetails"]["Role"]>) => {
      state.setRoleDetails.Role = action.payload;
    },

    setPhoneForRoleModals: (state, action: PayloadAction<AccessState["setRoleDetails"]["Phone"]>) => {
      state.setRoleDetails.Phone = action.payload;
    },

    setHouseIdsForRoleModals: (state, action: PayloadAction<AccessState["setRoleDetails"]["HouseIds"]>) => {
      state.setRoleDetails.HouseIds = action.payload;
    },

    toggleAddAccessModalOpen: (state, action: PayloadAction<AccessState["setRoleDetails"]["isAddAccessModalOpen"]>) => {
      state.setRoleDetails.isAddAccessModalOpen = action.payload;
    },

    toggleErrorModalOpen: (state, action: PayloadAction<AccessState["setRoleDetails"]["isErrorModalOpen"]>) => {
      state.setRoleDetails.isErrorModalOpen = action.payload;
    },

    toggleSuccessModalOpen: (state, action: PayloadAction<AccessState["setRoleDetails"]["isSuccessModalOpen"]>) => {
      state.setRoleDetails.isSuccessModalOpen = action.payload;
    },

    toggleCreateUserModalOpen: (
      state,
      action: PayloadAction<AccessState["setRoleDetails"]["isCreateUserModalOpen"]>
    ) => {
      state.setRoleDetails.isCreateUserModalOpen = action.payload;
    },

    toggleSetUserAddressModalOpen: (
      state,
      action: PayloadAction<AccessState["setRoleDetails"]["isSetUserAddressModalOpen"]>
    ) => {
      state.setRoleDetails.isSetUserAddressModalOpen = action.payload;
    },

    removeUser: (state, action: PayloadAction<Access.User["Id"]>) => {
      const index = state.users.findIndex((it) => it.Id === action.payload);
      if (index >= 0) {
        state.users.splice(index, 1);
      }
    },
  },
  extraReducers: {},
});

export const {
  setMyAccessRole,
  setAccessUsers,
  setRoles,
  setRoleForRoleModals,
  setPhoneForRoleModals,
  setHouseIdsForRoleModals,
  toggleAddAccessModalOpen,
  toggleErrorModalOpen,
  toggleSuccessModalOpen,
  toggleCreateUserModalOpen,
  toggleSetUserAddressModalOpen,
  removeUser,
} = accessSlice.actions;

export default accessSlice.reducer;
