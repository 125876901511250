import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import UploadingFileItem from "@/components/base-file-item/uploading-file-item";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const BASE_XLS_FORMAT = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";

interface IUploadFileModalProps {
  children: JSX.Element;
  accept?: string;
  id?: string;
  title: string;
  onChangeFile: (file: File | null) => void;
  onBeforeModalOpen?: () => Promise<boolean>;
  disabled?: boolean;
}

const UploadFileModal: React.FC<IUploadFileModalProps> = ({
  children,
  accept = BASE_XLS_FORMAT,
  id = "upload-file",
  title,
  onChangeFile,
  onBeforeModalOpen,
  disabled,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string>("");
  const [isOpen, setOpen] = useState<boolean>(false);
  const [dragActive, setDragActive] = React.useState(false);

  async function showModal() {
    if (disabled) {
      return;
    }

    if (onBeforeModalOpen) {
      const check = await onBeforeModalOpen();
      if (!check) {
        return;
      }
    }

    setOpen(true);
  }

  const handleDrag = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true);
    } else if (event.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (event: React.DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event?.dataTransfer?.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0]);
    }
  };

  const checkFile = (file: File | Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onabort = reader.onerror = (e) => {
        return reject(`Неизвестная ошибка`);
      };

      if (file instanceof File && accept !== "*") {
        const xlsFormats = ["xlsx", "xlsm", "xlsb", "xltx", "xltm", "xls", "xlt", "xml"];
        const name = file.name.split(".");
        const ext = name[name.length - 1];
        if (accept === BASE_XLS_FORMAT && !xlsFormats.includes(ext)) {
          return reject(`Неверный формат файла: ${ext}`);
        }
      }

      reader.onload = (e) => {
        const kb = file.size / 1024;
        if (kb > 10240) {
          return reject(`Размер файла не должен превышать 10 МБ`);
        }
      };

      reader.onloadend = () => {
        return resolve(reader.result);
      };

      reader.readAsDataURL(file);
    });
  };

  const resetInput = () => {
    setFile(null);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleOnChange = (event: React.FormEvent) => {
    setFileError("");

    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      checkFile(files[0])
        .then((res) => {
          setFile(files[0]);
        })
        .catch((error: string) => {
          setFileError(error);
          resetInput();
        });
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await onChangeFile(file);
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    resetInput();
  }, [isOpen]);

  return (
    <div className={styles.container}>
      <div onClick={showModal}>{children}</div>

      <BaseModal isOpen={isOpen} minWidth="545px" maxWidth="545px">
        <BaseModalHeader title={title} subtitle="Размер файла не более 10 MB" onClose={() => setOpen(false)} />

        <BaseModalContent>
          <div className={cx({ wrapper: true })} onDragEnter={handleDrag}>
            <form className={cx({ form: true, has_error: !!fileError })} onSubmit={handleSubmit} data-error={fileError}>
              <input ref={inputRef} id={id} type="file" accept={accept} multiple={false} onChange={handleOnChange} />
              <label htmlFor={id} className={cx({ drag_active: dragActive, disabled: !!file })}>
                <div>
                  <svg width="49" height="48" viewBox="0 0 49 48" fill="none">
                    <path
                      d="M28.5 4H12.5C11.4391 4 10.4217 4.42143 9.67157 5.17157C8.92143 5.92172 8.5 6.93913 8.5 8V40C8.5 41.0609 8.92143 42.0783 9.67157 42.8284C10.4217 43.5786 11.4391 44 12.5 44H36.5C37.5609 44 38.5783 43.5786 39.3284 42.8284C40.0786 42.0783 40.5 41.0609 40.5 40V16L28.5 4Z"
                      fill="#CFDDFF"
                    />
                    <path
                      d="M28.5 4V12.8C28.5 13.9201 28.5 14.4802 28.718 14.908C28.9097 15.2843 29.2157 15.5903 29.592 15.782C30.0198 16 30.5799 16 31.7 16H40.5"
                      fill="#226DFF"
                    />
                  </svg>
                  <div className="center">
                    <p className="text--primary color--text-secondary sf-text-regular">
                      Перетащите в эту область или{" "}
                      <span role="button" className="color--primary">
                        загрузите
                      </span>
                    </p>
                    <p className="text--primary color--text-secondary sf-text-regular">не более 1 документа</p>
                  </div>
                </div>
              </label>
              {dragActive && (
                <div
                  className={styles.drag_zone}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
            {file && (
              <div className={styles.files}>
                <UploadingFileItem file={file} progress={true} onDelete={() => setFile(null)} />
              </div>
            )}
          </div>
        </BaseModalContent>

        <BaseModalActions>
          <BaseButton variant="secondary" onClick={() => setOpen(false)}>
            Отмена
          </BaseButton>
          <BaseButton isLoading={isLoading} disabled={!file} onClick={handleSubmit}>
            Сохранить
          </BaseButton>
        </BaseModalActions>
      </BaseModal>
    </div>
  );
};

export default UploadFileModal;
