import { isPast, addDays, isToday, isAfter, isBefore } from "date-fns";
import { Form, FormFiled, DatepickerField, FormValidator, Dictionary, SelectField, House } from "@/types";
import errorMessages from "@/helpers/errorMessages.json";
import { OCCV2Order } from "./";

const DefaultValueProcedureForFamiliarization =
  "Порядок ознакомления с информацией и (или) материалами, которые будут представлены на собрании, и место (адрес), где с ними можно ознакомиться лично по адресу _____(укажите адрес). Период ознакомления с _ часов до _ часов ___ (укажите дни недели или календарные дни) номер телефона __(укажите номер) . По звонку информация предоставляется в устной форме, по сообщению информация предоставляется посредством мессенджеров WhatsApp, Viber, Telegram.  По адресу электронной почты ______@______.___(укажите адрес эл. почты) в ответном письме.";

export namespace OCCV2OrderForms {
  export class CreateForm extends Form {
    readonly occId: number | null = null;
    Number: FormFiled = new FormFiled();
    FiasId: SelectField<House.Item | null> = new SelectField(null, true);
    Type: SelectField<Dictionary.DictionaryItem<Dictionary.OCCType> | null> = new SelectField(null, true);
    Form: SelectField<Dictionary.DictionaryItem<Dictionary.OCCForm> | null> = new SelectField(null, true);
    IntramuralLocation: FormFiled = new FormFiled();
    ProcedureForMakingWrittenDecisions: FormFiled = new FormFiled();
    ProcedureForFamiliarizationWithMaterialsOfOCC: FormFiled = new FormFiled();
    StartTimestamp: DatepickerField = new DatepickerField({});
    EndTimestamp: DatepickerField = new DatepickerField({});
    PublicationTimestamp: DatepickerField = new DatepickerField({});

    constructor({
      occ,
      address,
      types,
      forms,
      minDaysBeforeStart = 14,
      votingDaysMin = 7,
      votingDaysMax = 60,
      addressesList,
    }: {
      occ?: OCCV2Order.ItemFull;
      address?: House.Item | null;
      types?: Dictionary.DictionaryItem<Dictionary.OCCType>[];
      forms?: Dictionary.DictionaryItem<Dictionary.OCCForm>[];
      minDaysBeforeStart?: number;
      votingDaysMin?: number;
      votingDaysMax?: number;
      addressesList?: Array<House.Item>;
    }) {
      super();
      this.occId = occ?.Id ?? null;
      this.Number = new FormFiled(occ?.Number ?? "", true);
      this.IntramuralLocation = new FormFiled(
        occ?.IntramuralLocation ? occ?.IntramuralLocation : address?.Address ?? "",
        true
      );
      this.ProcedureForMakingWrittenDecisions = new FormFiled(occ?.ProcedureForMakingWrittenDecisions ?? "", true);
      this.ProcedureForFamiliarizationWithMaterialsOfOCC = new FormFiled(
        occ?.ProcedureForFamiliarizationWithMaterialsOfOCC ?? DefaultValueProcedureForFamiliarization,
        true
      );
      const tomorrow = addDays(new Date(), 1);

      const publication =
        occ?.PublicationTime && new Date(occ?.PublicationTime).isValid()
          ? new Date(occ?.PublicationTime.parseFromEpochSeconds())
          : undefined;
      const start =
        occ?.StartTime && new Date(occ?.StartTime).isValid()
          ? new Date(occ?.StartTime.parseFromEpochSeconds())
          : undefined;
      const end =
        occ?.EndTime && new Date(occ?.EndTime).isValid() ? new Date(occ?.EndTime.parseFromEpochSeconds()) : undefined;

      this.PublicationTimestamp = new DatepickerField({
        value: publication,
        minDate: tomorrow,
        isRequired: true,
      });

      this.StartTimestamp = new DatepickerField({
        value: start,
        minDate: addDays(publication ?? tomorrow, minDaysBeforeStart),
        isRequired: true,
      });

      this.EndTimestamp = new DatepickerField({
        value: end,
        minDate: start ? addDays(start, votingDaysMin) : addDays(tomorrow, minDaysBeforeStart + votingDaysMin),
        maxDate: start ? addDays(start, votingDaysMax) : undefined,
        isRequired: true,
      });

      if (occ?.TypeId && types && types.length > 0) {
        const type = types.find((it) => it.id === occ?.TypeId);
        this.Type = new SelectField(type ?? null, true);
      }

      if (occ?.FormId && forms && forms.length > 0) {
        const form = forms.find((it) => it.id === occ?.FormId);
        this.Form = new SelectField(form ?? null, true);
      }

      if (occ?.FiasId && addressesList && addressesList.length > 0) {
        const addr = addressesList.find((it) => it.FiasId === occ?.FiasId);
        this.FiasId = new SelectField(addr ?? null, true);
      } else {
        this.FiasId = new SelectField(address ?? null, true);
      }
    }
  }

  export class CreateFormValidator {
    static validateStartTimestamp(start: CreateForm["StartTimestamp"]) {
      if (start.value === null) return errorMessages.empty;

      if (start.value && start.minDate && isBefore(start.value, start.minDate)) {
        return "Должно пройти 14 дней с даты публикации сообщения";
      }

      return "";
    }

    static validateEndTimestamp(end: CreateForm["EndTimestamp"]) {
      if (end.value === null) return errorMessages.empty;

      if (end.value && end.minDate && isBefore(end.value, end.minDate)) {
        return "Продолжительность голосования не менее 7 дней с даты начала голосования";
      }

      if (end.value && end.maxDate && isAfter(end.value, end.maxDate)) {
        return "Продолжительность голосования не более 14 дней с даты начала голосования";
      }

      return "";
    }

    static validatePublicationTimestamp(publication: CreateForm["PublicationTimestamp"]) {
      if (publication.value === null) return errorMessages.empty;

      if (publication.value && isPast(publication.value) && !isToday(publication.value)) {
        return "Для выбора доступны даты, начиная со следующего календарного дня.";
      }

      return "";
    }

    static validateIntramuralLocation(form: CreateForm) {
      const isRequired = form.Form?.value?.key === Dictionary.OCCForm.IntramuralExtramural;
      return FormValidator.getFieldErrorMessage(form.IntramuralLocation.value.trim(), isRequired);
    }

    public static isInvalid(form: CreateForm) {
      const errors = {
        Number: FormValidator.getFieldErrorMessage(form.Number.value.trim(), form.Number.isRequired),
        ProcedureForMakingWrittenDecisions: FormValidator.getFieldErrorMessage(
          form.ProcedureForMakingWrittenDecisions.value.trim(),
          form.ProcedureForMakingWrittenDecisions.isRequired
        ),
        ProcedureForFamiliarizationWithMaterialsOfOCC: FormValidator.getFieldErrorMessage(
          form.ProcedureForFamiliarizationWithMaterialsOfOCC.value.trim(),
          form.ProcedureForFamiliarizationWithMaterialsOfOCC.isRequired
        ),
        StartTimestamp: this.validateStartTimestamp(form.StartTimestamp),
        EndTimestamp: this.validateEndTimestamp(form.EndTimestamp),
        PublicationTimestamp: this.validatePublicationTimestamp(form.PublicationTimestamp),
        IntramuralLocation: this.validateIntramuralLocation(form),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }
}
