import { NavLink } from "react-router-dom";
import classNames from "classnames/bind";
import { NavLinkType } from "../sidebar";
import { Accordion } from "@/components/base-accordion";
import NestedNavigationItem from "./Nested";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface INavigationItemProps {
  link: NavLinkType;
  action?: JSX.Element | JSX.Element[] | React.ReactNode;
  filterChildren?: (children: NavLinkType[]) => NavLinkType[];
}

const NavigationItem: React.FC<INavigationItemProps> = ({ link, action, filterChildren }) => {
  return (
    <div className="b-navigation-item">
      {link.path ? (
        <NavLink
          className={({ isActive }) => cx("b-navigation-item__btn", { "b-navigation-item__btn--active": isActive })}
          to={link.path}
        >
          <div>
            <div className={styles["b-navigation-item__btn_icon"]}>{link.icon}</div>
            <span className="sf-text-medium text--primary color--text-primary">{link.text}</span>
          </div>
          {action && <span>{action}</span>}
        </NavLink>
      ) : (
        <Accordion>
          <NestedNavigationItem link={link} filterChildren={filterChildren} />
        </Accordion>
      )}
    </div>
  );
};

export default NavigationItem;
