import React, { useContext, useMemo } from "react";
import AccordionContext from "./context";
import { accordionIcon } from "@/consts/icons";
import classNames from "classnames/bind";
import styles from "./accordion.module.scss";

const cx = classNames.bind(styles);

interface ICollapsedProps {
  children: React.ReactNode;
  id: string | number;
  className?: string;
  customIcon?: JSX.Element;
  actions?: JSX.Element;
  upAndDownIconAnimation?: boolean;
}

const Collapsed: React.FC<ICollapsedProps> = ({
  children,
  id,
  className,
  customIcon,
  actions,
  upAndDownIconAnimation = false,
}) => {
  const context = useContext(AccordionContext);

  const isOpen: boolean = useMemo(() => {
    return id === context?.opened;
  }, [context?.opened, id]);

  const handleToggle = (event: React.SyntheticEvent) => {
    if (context?.handleOpen) {
      context?.handleOpen(id);
    }
  };

  return (
    <div
      className={cx(className, { collapsed: true })}
      onClick={handleToggle}
      data-accordion-item-id={id}
      role="button"
    >
      <div className={styles.collapsed_box}>
        {children}
        <span
          className={styles.icon}
          style={{
            transform: isOpen
              ? upAndDownIconAnimation
                ? "rotate(180deg)"
                : "rotate(0deg)"
              : upAndDownIconAnimation
              ? "rotate(0deg)"
              : "rotate(-90deg)",
          }}
        >
          {customIcon ? customIcon : accordionIcon}
        </span>
      </div>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
};

export default Collapsed;
