import styles from "./styles.module.scss";

type ISntPassportBlockContentReadonlyProps = {
  children: JSX.Element | JSX.Element[] | React.ReactNode | string | null;
};

const SntPassportBlockContentReadonly: React.FC<ISntPassportBlockContentReadonlyProps> = ({ children }) => {
  return <div className={styles["b-snt-passport-block-content-readonly"]}>{children}</div>;
};

export default SntPassportBlockContentReadonly;
