import React, { useEffect, useState } from "react";
import AccordionContext from "./context";
import Collapsed from "./collapsed";
import CustomCollapsed from "./custom-collapsed";
import Expanded from "./expanded";
import classNames from "classnames/bind";
import styles from "./accordion.module.scss";
const cx = classNames.bind(styles);

interface IAccordion {
  children: React.ReactElement[] | React.ReactElement;
  initial?: string | number;
  isAsync?: boolean;
  callback?: (data: any) => Promise<any>;
  className?: string;
}

const Accordion: React.FC<IAccordion> = ({ children, initial = "", isAsync, callback, className }) => {
  const [opened, setOpen] = useState<string | number>(initial);

  const handleOpen = async (id: string | number) => {
    if (isAsync && callback) {
      try {
        await callback(id);
      } catch (error) {
        console.warn(error);
      }
    }

    if (String(id) === String(opened)) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  useEffect(() => {
    if (initial) setOpen(initial);
  }, [initial]);

  return (
    <AccordionContext.Provider
      value={{
        opened,
        handleOpen,
      }}
    >
      <section className={cx(className, { root: true })}>{children}</section>
    </AccordionContext.Provider>
  );
};

export { Accordion, Collapsed, CustomCollapsed, Expanded };
