import { Pagination, UKRegistry } from "@/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface UKRegistryState {
  details: UKRegistry.HouseRegistryDetails | null;
  isLoading: boolean;
  ownersSource: UKRegistry.OwnerSource | null;
  query: string;
  pagination: Pagination | null;
  apartments: UKRegistry.Apartment[];
  setCadastralNumberModal: {
    isOpen: boolean;
  };
  acceptAreaModal: {
    isOpen: boolean;
    isLoading: boolean;
  };
  deleteModal: {
    isOpen: boolean;
    isLoading: boolean;
    apartment: UKRegistry.Apartment | null;
  };
  editModal: {
    mode: "view" | "add" | "edit" | null;
    isOpen: boolean;
    isLoading: boolean;
    apartment: UKRegistry.Apartment | null;
  };
}

const UKRegistrySlice = createSlice({
  name: "uk-registry",

  initialState: {
    details: null,
    isLoading: false,
    ownersSource: null,
    query: "",
    pagination: null,
    apartments: [],
    setCadastralNumberModal: {
      isOpen: false,
    },
    acceptAreaModal: {
      isOpen: false,
      isLoading: false,
    },
    deleteModal: {
      isOpen: false,
      isLoading: false,
      apartment: null,
    },
    editModal: {
      mode: null,
      isOpen: false,
      isLoading: false,
      apartment: null,
    },
  } as UKRegistryState,

  reducers: {
    setDetails: (state, action: PayloadAction<UKRegistryState["details"]>) => {
      state.details = action.payload;
    },

    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },

    setOwnersSource: (state, action: PayloadAction<UKRegistryState["ownersSource"]>) => {
      state.ownersSource = action.payload;
    },

    setPagination: (state, action: PayloadAction<UKRegistryState["pagination"]>) => {
      state.pagination = action.payload;
    },

    setApartments: (state, action: PayloadAction<UKRegistryState["apartments"]>) => {
      state.apartments = action.payload;
    },

    pushApartments: (state, action: PayloadAction<UKRegistryState["apartments"]>) => {
      state.apartments.push(...action.payload);
    },

    toggleDeleteModal: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        apartment?: UKRegistryState["deleteModal"]["apartment"];
      }>
    ) => {
      if (action.payload.isOpen && action.payload.apartment) {
        state.deleteModal.isOpen = action.payload.isOpen;
        state.deleteModal.apartment = action.payload.apartment;
        return;
      }

      state.deleteModal.isOpen = false;
    },

    toggleDeleteModalLoading: (state, action: PayloadAction<boolean>) => {
      state.deleteModal.isLoading = action.payload;
    },

    removeApartmentFromList: (state, action: PayloadAction<UKRegistry.Apartment["Id"]>) => {
      const index = state.apartments.findIndex((it) => it.Id === action.payload);
      if (index >= 0) {
        state.apartments.splice(index, 1);
      }
    },

    updateApartmentById: (state, action: PayloadAction<UKRegistry.Apartment>) => {
      const sections = state.apartments.map((it) => {
        if (it.Id === action.payload.Id) {
          return { ...action.payload };
        }
        return it;
      });
      state.apartments = sections;
    },

    toggleEditModal: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        mode?: UKRegistryState["editModal"]["mode"];
        apartment?: UKRegistryState["editModal"]["apartment"];
      }>
    ) => {
      if (action.payload.isOpen) {
        state.editModal.isOpen = action.payload.isOpen;
        state.editModal.mode = action.payload.mode ?? "view";
        state.editModal.apartment = action.payload.apartment ?? null;
        return;
      }

      state.editModal.isOpen = false;
    },

    toggleEditModalLoading: (state, action: PayloadAction<boolean>) => {
      state.editModal.isLoading = action.payload;
    },

    toggleAcceptAreaModal: (state, action: PayloadAction<UKRegistryState["acceptAreaModal"]["isOpen"]>) => {
      state.acceptAreaModal.isOpen = action.payload;
    },

    toggleAcceptAreaModalLoading: (state, action: PayloadAction<UKRegistryState["acceptAreaModal"]["isLoading"]>) => {
      state.acceptAreaModal.isLoading = action.payload;
    },

    toggleSetCadastralNumberModal: (
      state,
      action: PayloadAction<UKRegistryState["setCadastralNumberModal"]["isOpen"]>
    ) => {
      state.setCadastralNumberModal.isOpen = action.payload;
    },
  },
});

export default UKRegistrySlice.reducer;
export * from "./actions";
export const {
  setDetails,
  toggleLoading,
  setQuery,
  setOwnersSource,
  setPagination,
  setApartments,
  pushApartments,
  toggleDeleteModal,
  toggleDeleteModalLoading,
  removeApartmentFromList,
  updateApartmentById,
  toggleEditModal,
  toggleEditModalLoading,
  toggleAcceptAreaModal,
  toggleAcceptAreaModalLoading,
  toggleSetCadastralNumberModal,
} = UKRegistrySlice.actions;
