import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import { Documents, House } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import { isCompanyTypeUK } from "@/app/store/slices/user";
import { uploadFileByDirectoryId } from "@/app/store/slices/documents/actions";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import SelectAddressSubmodal, { SelectAddressDefaultButton } from "@/components/SelectAddressSubmodal";
import BaseButton from "@/components/base-button";
import UploadingFileItem from "@/components/base-file-item/uploading-file-item";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IAddDocumentModalProps {
  isOpen: boolean;
  directory?: Documents.Directory | null;
  onClose: () => void;
}

const AddDocumentModal: React.FC<IAddDocumentModalProps> = ({ isOpen, directory, onClose }) => {
  const dispatch = useAppDispatch();
  const company = useSelector((state: RootState) => state.user.company);
  const isUK = useSelector(isCompanyTypeUK);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [selectedAddresses, setSelectedAddresses] = useState<Array<House.Item["Id"]>>([]);
  const [fileError, setFileError] = useState<string>("");
  const [housesError, setHousesError] = useState<string>("");
  const [dragActive, setDragActive] = React.useState(false);
  const [isSelectAddressSubmodalShown, setSelectAddressSubmodalShown] = useState<boolean>(false);

  const submodalPosition = useMemo(() => {
    const rect = containerRef.current?.getBoundingClientRect();
    return {
      left: rect ? `${rect.left + rect.width / 2}px` : 0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  const handleDrag = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true);
    } else if (event.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (event: React.DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event?.dataTransfer?.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0]);
    }
  };

  const checkFile = (file: File | Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onabort = reader.onerror = (e) => {
        return reject(`Неизвестная ошибка`);
      };

      reader.onload = (e) => {
        const kb = file.size / 1024;
        if (kb > 20480) {
          return reject(`Размер файла не должен превышать 20 МБ`);
        }
      };

      reader.onloadend = () => {
        return resolve(reader.result);
      };

      reader.readAsDataURL(file);
    });
  };

  const resetInput = () => {
    setFile(null);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleOnChange = (event: React.FormEvent) => {
    setFileError("");
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      checkFile(files[0])
        .then((res) => {
          setFile(files[0]);
        })
        .catch((error: string) => {
          setFileError(error);
          resetInput();
        });
    }
  };

  const handleError = (payload: any) => {
    if (payload.Errors) {
      Object.keys(payload.Errors).forEach((key: any) => {
        const message = Array.isArray(payload.Errors[key]) ? payload.Errors[key][0] : payload.Errors[key];
        switch (key) {
          case "Attachment": {
            setFileError(message);
            break;
          }

          case "Houses": {
            setHousesError(message);
            break;
          }
        }
      });
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!directory?.Id || !file) {
      return;
    }

    if (isUK && selectedAddresses.length === 0) {
      setHousesError("Выберите адреса");
      return;
    }

    setLoading(true);
    try {
      await dispatch(
        uploadFileByDirectoryId({
          directoryId: directory?.Id,
          body: {
            Attachment: file,
            Houses: !isUK ? company?.Addresses.map((it) => it.Id) ?? [] : selectedAddresses,
          },
        })
      ).unwrap();
      onClose();
    } catch (error) {
      console.log(error);
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    resetInput();

    if (isOpen && company?.Addresses && company?.Addresses.length > 0) {
      setSelectedAddresses(company?.Addresses.map((it) => it.Id) ?? []);
      setHousesError("");
      setFileError("");
    }
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} minWidth="545px" maxWidth="545px">
      <div ref={containerRef}>
        <BaseModalHeader title="Добавить документ" subtitle="Размер файла не более 20 MB" onClose={() => onClose()} />

        <BaseModalContent>
          <div className={cx({ wrapper: true })} onDragEnter={handleDrag}>
            <form className={cx({ form: true, has_error: !!fileError })} onSubmit={handleSubmit} data-error={fileError}>
              <input
                ref={inputRef}
                id="add-document"
                type="file"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,.jpg,.jpeg,.png"
                multiple={false}
                onChange={handleOnChange}
              />
              <label htmlFor="add-document" className={cx({ drag_active: dragActive, disabled: !!file })}>
                <div>
                  <svg width="49" height="48" viewBox="0 0 49 48" fill="none">
                    <path
                      d="M28.5 4H12.5C11.4391 4 10.4217 4.42143 9.67157 5.17157C8.92143 5.92172 8.5 6.93913 8.5 8V40C8.5 41.0609 8.92143 42.0783 9.67157 42.8284C10.4217 43.5786 11.4391 44 12.5 44H36.5C37.5609 44 38.5783 43.5786 39.3284 42.8284C40.0786 42.0783 40.5 41.0609 40.5 40V16L28.5 4Z"
                      fill="#CFDDFF"
                    />
                    <path
                      d="M28.5 4V12.8C28.5 13.9201 28.5 14.4802 28.718 14.908C28.9097 15.2843 29.2157 15.5903 29.592 15.782C30.0198 16 30.5799 16 31.7 16H40.5"
                      fill="#226DFF"
                    />
                  </svg>
                  <div className="center">
                    <p className="text--primary color--text-secondary sf-text-regular">
                      Перетащите в эту область или{" "}
                      <span role="button" className="color--primary">
                        загрузите
                      </span>
                    </p>
                    <p className="text--primary color--text-secondary sf-text-regular">
                      не более одного документа до 20 MB, PDF, DOC, DOCX, XLS, XLSX, TXT, JPG, JPEG, PNG
                    </p>
                  </div>
                </div>
              </label>
              {dragActive && (
                <div
                  className={styles.drag_zone}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
            {file && (
              <div className={styles.files}>
                <UploadingFileItem
                  file={file}
                  progress={true}
                  onDelete={() => {
                    setFile(null);
                    setFileError("");
                  }}
                />
              </div>
            )}

            {isUK && (
              <>
                <SelectAddressDefaultButton
                  errorMessage={housesError}
                  onClick={() => setSelectAddressSubmodalShown(true)}
                >
                  Показывать
                  {selectedAddresses?.length === company?.Addresses?.length
                    ? " по всем адресам"
                    : selectedAddresses?.length === 1
                    ? ` по ${selectedAddresses?.length} адресу`
                    : ` по ${selectedAddresses?.length ?? 0} адресам`}
                </SelectAddressDefaultButton>

                {isSelectAddressSubmodalShown && (
                  <SelectAddressSubmodal
                    position={submodalPosition}
                    isOpen={isSelectAddressSubmodalShown}
                    selected={selectedAddresses}
                    onClose={() => setSelectAddressSubmodalShown(false)}
                    setSelected={(ids) => setSelectedAddresses(ids)}
                  />
                )}
              </>
            )}
          </div>
        </BaseModalContent>

        <BaseModalActions>
          <BaseButton variant="secondary" onClick={() => onClose()}>
            Отмена
          </BaseButton>
          <BaseButton isLoading={isLoading} disabled={!file} onClick={handleSubmit}>
            Сохранить
          </BaseButton>
        </BaseModalActions>
      </div>
    </BaseModal>
  );
};

export default AddDocumentModal;
