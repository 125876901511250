import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import BaseButton from "@/components/base-button";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import { toggleRegistryUpdatedModal } from "@/app/store/slices/uk/rosreestr";

const RegistryUpdatedModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useSelector((state: RootState) => state.ukRosreestr.isRegistryUpdatedModalShow);

  const closeModal = () => {
    dispatch(toggleRegistryUpdatedModal(false));
  };

  return (
    <BaseModal isOpen={isOpen} maxWidth="520px" minWidth="520px">
      <BaseModalHeader title="Были внесены изменения в реестр" onClose={closeModal} />

      <BaseModalContent>
        <p className="text--primary sf-text-regular color--text-primary">
          На основе обновленного реестра будут зановы сформированы документы. <br /> Посмотреть и скачать документы
          можно в разделе «Мои собрания» – вкладка «Документы»
        </p>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton onClick={closeModal}>Понятно</BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default RegistryUpdatedModal;
