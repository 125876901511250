/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { OCCV2Order, Pagination } from "@/types";
import { OCCService } from "@/services/v2";
import { addIcon24, importantInfoIcon } from "@/consts/icons";
import { activeAddress } from "@/app/store/slices/user";
import { getDraftStatus } from "@/app/store/slices/dictionary";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import PageHeader from "@/components/PageHeader";
import BaseButton from "@/components/base-button";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import { Spinner } from "@/components/spinner";
import DraftOccItem from "../DraftOccItem";
import styles from "./styles.module.scss";

interface IOccDraftsListProps {
  isCreateOccCheckLoading?: boolean;
  isEmptyRegistryModalOpen: boolean;
  badge?: JSX.Element | JSX.Element[];
  createOcc: () => void;
  toggleEmptyRegistryModal: (value: boolean) => void;
  onSelectDraft: (occ: OCCV2Order.Item) => void;
}

const OccDraftsList: React.FC<IOccDraftsListProps> = ({
  isCreateOccCheckLoading = false,
  isEmptyRegistryModalOpen,
  badge,
  createOcc,
  toggleEmptyRegistryModal,
  onSelectDraft,
}) => {
  const address = useSelector(activeAddress);
  const draftStatus = useSelector(getDraftStatus);

  const [drafts, setDrafts] = useState<OCCV2Order.Item[]>([]);
  const [pagination, setPagination] = useState<Pagination>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [selectedOcc, setSelectedOcc] = useState<OCCV2Order.Item | null>(null);

  const { measureRef, isIntersecting, observer, hasMore } = useInfiniteScroll({ pagination });

  function handleDraftDelete(occ: OCCV2Order.Item) {
    setSelectedOcc(occ);
    setDeleteModalOpen(true);
  }

  async function deleteDraft() {
    if (!selectedOcc?.Id) {
      return;
    }

    setDeleteLoading(true);
    try {
      await OCCService.deleteOcc(selectedOcc.Id);
      setDrafts((prevState) => {
        const index = prevState.findIndex((it) => it.Id === selectedOcc.Id);
        return [...prevState.slice(0, index), ...prevState.slice(index + 1)];
      });
      setDeleteModalOpen(false);
    } catch (error) {
      console.warn(error);
    } finally {
      setDeleteLoading(false);
    }
  }

  async function fetchMoreData() {
    try {
      const { data } = await OCCService.getOCCList({
        StatusId: draftStatus?.id ?? undefined,
        FiasId: address?.FiasId ?? undefined,
        page: (pagination?.Index ?? 0) + 1,
      });

      setDrafts((prevState) => [...prevState, ...data.Data]);
      setPagination(data.Page);

      if (observer && isIntersecting && data.Page.Index === data.Page.Count) {
        observer.disconnect();
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
  }, [isIntersecting]);

  async function fetch() {
    setLoading(true);
    try {
      const { data } = await OCCService.getOCCList({
        StatusId: draftStatus?.id ?? undefined,
        FiasId: address?.FiasId ?? undefined,
      });
      setDrafts(data.Data);
      setPagination(data.Page);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetch();
  }, [address]);

  return (
    <div className={styles["b-drafts"]}>
      <PageHeader title="Создание собрания">
        <BaseButton isLoading={isCreateOccCheckLoading} startIcon={addIcon24()} onClick={createOcc}>
          Новое собрание
        </BaseButton>
      </PageHeader>

      {badge && <div className={styles["b-drafts__badge"]}>{badge}</div>}

      <div>
        <div className={styles["b-drafts__header"]}>
          <h2 className="headline-h2 sf-bold">
            Черновики собраний
            <span className="color--primary" style={{ marginLeft: "8px" }}>
              {pagination && hasMore ? `${(pagination?.Count - 1) * pagination?.Limit}+` : drafts.length}
            </span>
          </h2>
        </div>

        {isLoading ? (
          <div className={styles["b-drafts__loading"]}>
            <div className="flex middle center">
              <Spinner size={36} color="#226dff" />
            </div>
          </div>
        ) : (
          drafts.length > 0 && (
            <>
              <div className={styles["b-drafts__list"]}>
                {drafts.map((it) => (
                  <DraftOccItem
                    key={it.Uuid}
                    occ={it}
                    onDelete={() => handleDraftDelete(it)}
                    onSelect={() => onSelectDraft(it)}
                  />
                ))}
              </div>
              {hasMore && (
                <div ref={measureRef} className="flex center" style={{ padding: "12px 0" }}>
                  <Spinner color="#226dff" size={36} />
                </div>
              )}
            </>
          )
        )}
      </div>

      <BaseModal isOpen={isEmptyRegistryModalOpen} minWidth="508px" maxWidth="508px">
        <BaseModalHeader
          title="Загрузите реестр"
          icon={importantInfoIcon}
          onClose={() => toggleEmptyRegistryModal(false)}
        />

        <BaseModalContent>
          <p className="text--primary sf-text-regular" style={{ padding: "0 56px" }}>
            Создание собрания недоступно, так как не заполнен реестр участков. Заполните реестр участков и попробуйте
            снова
          </p>
        </BaseModalContent>

        <BaseModalActions>
          <BaseButton variant="secondary" onClick={() => toggleEmptyRegistryModal(false)}>
            Понятно
          </BaseButton>

          <NavLink to="/snt/registry">
            <BaseButton>Добавить реестр</BaseButton>
          </NavLink>
        </BaseModalActions>
      </BaseModal>

      <BaseModal isOpen={isDeleteModalOpen} minWidth="426px" maxWidth="426px">
        <BaseModalHeader title="Удалить черновик собрания?" onClose={() => setDeleteModalOpen(false)} />

        <BaseModalContent>
          <p className="text--primary sf-text-regular">
            Вы уверены что хотите удалить черновик{" "}
            {selectedOcc?.Number && <span className="sf-text-bold">№{selectedOcc?.Number}</span>}
          </p>
        </BaseModalContent>

        <BaseModalActions>
          <BaseButton variant="secondary" onClick={() => setDeleteModalOpen(false)}>
            Отменить
          </BaseButton>

          <BaseButton isLoading={isDeleteLoading} onClick={deleteDraft}>
            Удалить
          </BaseButton>
        </BaseModalActions>
      </BaseModal>
    </div>
  );
};

export default OccDraftsList;
