import { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import { RootState } from "@/app/store";
import { authSubBackground, logoIcon } from "@/consts/icons";
import AuthContext, { SendCodePayload } from "./context";
import AuthFormByLogin from "./forms/ByLogin";
import AuthFormByPhone from "./forms/ByPhone";
import AuthFormByPhoneCodeSended from "./forms/SetCode";
import styles from "./auth.module.scss";
import { Navigate } from "react-router";

const cx = classNames.bind(styles);

enum AuthBy {
  Login = "login",
  Phone = "phone",
}

function Authorization() {
  const { token } = useSelector((state: RootState) => state.user);

  const [tab, setTab] = useState<AuthBy>(AuthBy.Login);
  const [loginByPhoneDetails, setLoginByPhoneDetails] = useState<{
    phone: string;
    expiresAt: number | null;
  }>();
  const [isCodeSend, setCodeSend] = useState<boolean>(false);

  function setIsCodeSended(payload: SendCodePayload) {
    setCodeSend(payload.value);
    if (payload.value) {
      setLoginByPhoneDetails({
        phone: payload.phone ?? "",
        expiresAt: payload.expiresAt ?? null,
      });
    }
  }

  if (token) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <AuthContext.Provider
      value={{
        phone: loginByPhoneDetails?.phone ?? "",
        expiresAt: loginByPhoneDetails?.expiresAt ?? null,
        setIsCodeSended,
      }}
    >
      <div className={styles["p-auth"]}>
        <section className={styles["p-auth__wrapper"]}>
          <div className={styles["p-auth__header"]}>
            <p className="headline-h2 sf-bold center">
              Ваша учётная запись для доступа к <br />
              государственной информационной системе «Локоло»
            </p>
          </div>

          <div className={styles["p-auth__card"]}>
            {isCodeSend ? (
              <AuthFormByPhoneCodeSended />
            ) : (
              <>
                <div className={styles["p-auth__card_logo"]}>
                  {logoIcon()}
                  <h2 className="headline-h2 sf-bold color--text-primary">Авторизация</h2>
                </div>

                <div className={styles["p-auth__tabs"]}>
                  <span
                    className={cx("text--primary sf-semibold", { "tab--active": tab === AuthBy.Login })}
                    onClick={() => setTab(AuthBy.Login)}
                    role="button"
                  >
                    Логин
                  </span>
                  <span
                    className={cx("text--primary sf-semibold", { "tab--active": tab === AuthBy.Phone })}
                    onClick={() => setTab(AuthBy.Phone)}
                    role="button"
                  >
                    Номер телефона
                  </span>
                </div>

                {tab === AuthBy.Phone ? <AuthFormByPhone /> : <AuthFormByLogin />}
              </>
            )}
          </div>

          <div className={styles["p-auth__footer"]}>
            <div>
              <a href="/documents/private-policy.pdf" download className={`sf-text-regular headline-h4 ${styles.link}`}>
                Политика конфиденциальности
              </a>
            </div>
            <div>
              <a href="/documents/about-system.pdf" download className={`sf-text-regular headline-h4 ${styles.link}`}>
                Положение сервиса
              </a>
            </div>
            <div>
              <p>
                Техническая поддержка{" "}
                <a href="tel:+78435257063" className="sf-text-bold">
                  8 (843) 525-70-63
                </a>
                ,{" "}
                <a href="mailto:locolo.help@tatar.ru" className={styles.contact}>
                  locolo.help@tatar.ru
                </a>
              </p>
            </div>
          </div>
        </section>
        <div className={styles.subBackground}>{authSubBackground}</div>
      </div>
    </AuthContext.Provider>
  );
}

export default Authorization;
