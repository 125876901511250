import React from "react";

import styles from "./accordion.module.scss";
import { Box } from "@mui/material";
import { accordionIcon } from "../../consts/icons";

interface ICollapsed {
  children: React.ReactNode;
  handleOpen?: (id: number | string) => void;
  handleClick?: any;
  id: string | number;
  isOpen?: Boolean;
  style?: React.CSSProperties;
  iconStart?: Boolean;
  actions?: JSX.Element;
}

const Collapsed = React.memo(
  ({ children, handleOpen, handleClick, id, isOpen, style, iconStart = true, actions }: ICollapsed) => {
    const handleToggle = (event: any) => {
      if (handleOpen) {
        handleOpen(id);
      }

      if (handleClick) {
        handleClick(event);
      }
    };

    const renderContent = () =>
      iconStart ? (
        <>
          <Box
            className={styles.icon}
            sx={{
              transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
            }}
          >
            {accordionIcon}
          </Box>
          {children}
        </>
      ) : (
        <>
          {children}
          <Box
            className={styles.icon}
            sx={{
              transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
            }}
          >
            {accordionIcon}
          </Box>
        </>
      );

    return (
      <div
        className={styles.collapsed}
        style={{ ...styleCollapsed, ...style }}
        onClick={handleToggle}
        data-accordion-item-id={id}
        role="button"
      >
        {renderContent()}
        {actions && <div className={styles.actions}>{actions}</div>}
      </div>
    );
  }
);

const styleCollapsed = {
  display: "flex",
  gap: "8px",
  alignItems: "center",
};

export { Collapsed };
