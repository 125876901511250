import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Documents } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import { updateDocumentByDirectoryId } from "@/app/store/slices/documents/actions";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import BaseModal, { BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import SelectAddressSubmodal, { SelectAddressDefaultButton } from "@/components/SelectAddressSubmodal";
import BaseButton from "@/components/base-button";
import BaseTextarea from "@/components/base-textarea";
import { isCompanyTypeUK } from "@/app/store/slices/user";

interface IEditDocumentModalProps {
  isOpen: boolean;
  document: Documents.UsefulDocument;
  onClose: () => void;
}

const EditDocumentModal: React.FC<IEditDocumentModalProps> = ({ isOpen, document, onClose }) => {
  const dispatch = useAppDispatch();
  const company = useSelector((state: RootState) => state.user.company);
  const isUK = useSelector(isCompanyTypeUK);

  const containerRef = useRef<HTMLDivElement>(null);
  const [form, setForm] = useState<Documents.EditDocumentForm>(new Documents.EditDocumentForm());
  const [isSelectAddressSubmodalShown, setSelectAddressSubmodalShown] = useState<boolean>(false);

  const submodalPosition = useMemo(() => {
    const rect = containerRef.current?.getBoundingClientRect();
    return {
      left: rect ? `${rect.left + rect.width / 2}px` : 0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  const handleClose = () => {
    onClose();
  };

  const isFormValid = () => {
    const validator = Documents.EditDocumentFormValidator.isInvalid(form);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        Name: {
          ...form.Name,
          error: validator.Title,
        },
      }));
    }
    return !validator;
  };

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setForm((prevState) => ({
      ...prevState,
      Name: { ...prevState.Name, error: "" },
      error: "",
    }));

    if (!isFormValid()) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      await dispatch(
        updateDocumentByDirectoryId({
          directoryId: document.DirectoryId,
          fileId: document.Id,
          body: {
            Name: form.Name.value.trim(),
            Houses: !isUK ? company?.Addresses.map((it) => it.Id) : form.Houses,
          },
        })
      ).unwrap();
      onClose();
    } catch (error: any) {
      const errorData = error.Data;
      if (errorData?.Errors) {
        Object.keys(errorData.Errors).forEach((key: any) => {
          const message = Array.isArray(errorData.Errors[key]) ? errorData.Errors[key][0] : errorData.Errors[key];
          switch (key) {
            case "Name": {
              setForm((prevState) => ({
                ...prevState,
                Name: { ...form.Name, error: message },
              }));
              break;
            }

            default: {
              setForm((prevState) => ({ ...prevState, error: message }));
              dispatch(
                showSnackbar({ key: "create-documents-directory-error", body: message, type: SnackbarType.ERROR })
              );
              break;
            }
          }
        });
      } else {
        setForm((prevState) => ({ ...prevState, error: errorData?.Message }));
        dispatch(
          showSnackbar({
            key: "create-documents-directory-error",
            body: errorData?.Message ?? "Неизвестная ошибка",
            type: SnackbarType.ERROR,
          })
        );
      }
    } finally {
      setForm((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    setForm(new Documents.EditDocumentForm(document ?? undefined));
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} minWidth="459px" maxWidth="459px">
      <div ref={containerRef}>
        <BaseModalHeader title="Редактировать документ?" onClose={handleClose} />

        <BaseModalContent>
          <form
            onSubmit={handleOnSubmit}
            style={{ display: "flex", flexDirection: "column", gap: "20px", padding: "16px 0" }}
          >
            <BaseTextarea
              sx={{ height: "200px!important" }}
              value={form.Name.value}
              label="Наименование документа"
              required={form.Name.isRequired}
              placeholder="Например: (Полезный документ)"
              errorMessage={form.Name.error}
              maxlength={form.Name.maxlength}
              counter={true}
              onChange={(value) =>
                setForm((prevState) => ({
                  ...prevState,
                  Name: { ...prevState.Name, value },
                }))
              }
            />

            {isUK && (
              <>
                <SelectAddressDefaultButton onClick={() => setSelectAddressSubmodalShown(true)}>
                  Показывать
                  {form.Houses?.length === company?.Addresses?.length
                    ? " по всем адресам"
                    : form.Houses?.length === 1
                    ? ` по ${form.Houses?.length} адресу`
                    : ` по ${form.Houses?.length ?? 0} адресам`}
                </SelectAddressDefaultButton>

                {isSelectAddressSubmodalShown && (
                  <SelectAddressSubmodal
                    position={submodalPosition}
                    isOpen={isSelectAddressSubmodalShown}
                    selected={form.Houses}
                    onClose={() => setSelectAddressSubmodalShown(false)}
                    setSelected={(Houses) => setForm((prevState) => ({ ...prevState, Houses }))}
                  />
                )}
              </>
            )}
          </form>
        </BaseModalContent>

        <BaseModalActions>
          <BaseButton variant="secondary" onClick={handleClose}>
            Отменить
          </BaseButton>
          <BaseButton isLoading={form.isLoading} disabled={form.Name.value.length === 0} onClick={handleOnSubmit}>
            Сохранить
          </BaseButton>
        </BaseModalActions>
      </div>
    </BaseModal>
  );
};

export default EditDocumentModal;
