export namespace SntPassportEnums {
  export enum RoadSurfaceType {
    Soil = "SOIL",
    CrushedStone = "CRUSHED_STONE",
    AsphaltConcrete = "ASPHALT_CONCRETE",
    Other = "OTHER",
  }

  export enum WaterSupplyType {
    Well = "WELL",
    OpenReservoir = "OPEN_RESERVOIR",
    WaterSupplySystemOfSettlement = "WATER_SUPPLY_SYSTEM_OF_SETTLEMENT",
  }

  export enum ElectricitySupplyType {
    TransferredToResourceSupplyingOrganisations = "TRANSFERRED_TO_RESOURCE_SUPPLYING_ORGANISATIONS",
    OwnedBySnt = "OWNED_BY_SNT",
  }

  export enum InternalInfrastructureWorkType {
    ElectricitySupplyRepair = "ElectricitySupplyRepair",
    WaterSupplyRepair = "WaterSupplyRepair",
    InnerRoadRepair = "InnerRoadRepair",
    Other = "Other",
  }

  export enum ExternalInfrastructureWorkType {
    RoadRepair = "RoadRepair",
    ElectricitySupplyRepair = "ElectricitySupplyRepair",
    WaterSupplyRepair = "WaterSupplyRepair",
    TKORepair = "TKORepair",
    Other = "Other",
  }

  export function isNullOrUndefined(value: any) {
    return value === undefined || value === null;
  }
}
