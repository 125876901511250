import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import {
  toggleInstructions,
  toggleDeleteModal,
  toggleEditSectionModal,
  SntRegistryState,
} from "@/app/store/slices/snt/registry";
import {
  fetchSntDetails,
  setSearchQuery,
  setSntOwnerFilter,
  loadMoreSections,
} from "@/app/store/slices/snt/registry/actions";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import {
  tableToolbarAddIcon,
  tableToolbarUploadIcon,
  tableToolbarSaveIcon,
  gardeningMembers,
  notGardeningMembers,
  dropdownSecondaryChevron,
} from "@/consts/icons";
import { SntRegistryService } from "@/services/v2";
import Headline from "@/components/headline";
import BaseButton from "@/components/base-button";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import BaseQuerySearch from "@/components/base-query-search";
import BaseTooltip from "@/components/base-tooltip";
import { Spinner } from "@/components/spinner";
import UploadFileModal from "@/components/upload-file-modal";
import InstructionsModal from "./modals/instructions";
import DeleteModal from "./modals/delete";
import RegistryFileErrorsModal from "@/features/RegistryFileErrorsModal";
import EditSectionModal from "./modals/edit-section";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseTableToolbar,
  BaseContainer,
} from "@/components/base-table";
import SntRegistryItemRow from "./registryItemRow";
import styles from "./styles.module.scss";
import { SntRegistry } from "@/types";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import { isValid, parse } from "date-fns";

const canNotUpdateReestrTooltip =
  "Новый реестр загрузить нельзя, так как есть активное собрание. Загрузка будет доступна после завершения собрания";

const SntLandRegistry = () => {
  const dispatch = useAppDispatch();
  const { sections, isSectionsLoading, details, isSntOwner, query, pagination } = useSelector(
    (state: RootState) => state.sntRegistry
  );
  const { measureRef, isIntersecting, observer } = useInfiniteScroll();
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  const [isRegistryFileErrorsShown, setRegistryFileErrorsShown] = useState<boolean>(false);
  const [registryFileErrors, setRegistryFileErrors] = useState<string[]>([]);

  const hasMore = useMemo(() => pagination && pagination.Index < pagination.Count, [pagination]);
  const canNotUpdateReestr = useMemo(() => (details && !details.IsCanUpdateReestr) ?? false, [details]);
  const lastUpdated = useMemo(() => {
    if (!details?.SectionsOwnerLastUpdatedAt) {
      return null;
    }

    const date = parse(details?.SectionsOwnerLastUpdatedAt, "yyyy-MM-dd HH:mm:ss", new Date());
    if (isValid(date)) return date;
    return null;
  }, [details]);

  const showInstructionsModal = () => {
    dispatch(toggleInstructions(true));
  };

  const showDeleteModal = (section: SntRegistry.Section) => {
    dispatch(toggleDeleteModal({ isOpen: true, section }));
  };

  const showAddModal = () => {
    dispatch(toggleEditSectionModal({ isOpen: true, mode: "add" }));
  };

  const showViewModal = (section: SntRegistry.Section) => {
    dispatch(
      toggleEditSectionModal({
        isOpen: true,
        sectionId: section.Section.Id,
        mode: "view",
      })
    );
  };

  const showEditModal = (section: SntRegistry.Section) => {
    dispatch(
      toggleEditSectionModal({
        isOpen: true,
        sectionId: section.Section.Id,
        mode: "edit",
      })
    );
  };

  const updateSort = (isOwner: SntRegistryState["isSntOwner"]) => {
    dispatch(setSntOwnerFilter(isOwner));
  };

  const handleQueryChange = (value: string) => {
    dispatch(setSearchQuery(value));
  };

  const exportFile = async () => {
    if (details?.Id) {
      let params: any = {};
      if (query.length > 0) {
        params["SearchQuery"] = query;
      }
      if (typeof isSntOwner === "boolean") {
        params["IsSntOwner"] = isSntOwner;
      }
      const { data } = await SntRegistryService.exportRegistry({
        ...params,
        SntId: details?.Id,
        Limit: pagination ? pagination.Count * pagination.Limit : 1000,
      });
      let filename = "Реестр_участков.xlsx";
      filename = decodeURI(filename);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    }
  };

  async function handleChangeFile(file: File | null) {
    if (!file) return;

    try {
      await SntRegistryService.importRegistryFile(file);
      await dispatch(fetchSntDetails()).unwrap();
    } catch (error: any) {
      if (error?.response?.data?.Data?.Errors?.length > 0) {
        setRegistryFileErrors(error?.response?.data?.Data?.Errors);
        setRegistryFileErrorsShown(true);
        return;
      }

      dispatch(
        showSnackbar({
          key: "import-registry-file-error",
          body: error?.response?.data?.Data?.Message ?? "Неизвестная ошибка",
          type: SnackbarType.ERROR,
        })
      );
    }
  }

  const fetchMoreData = async () => {
    try {
      const { Index, Count } = await dispatch(loadMoreSections()).unwrap();
      if (observer && isIntersecting && Index === Count) {
        observer.disconnect();
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
  }, [isIntersecting]);

  useEffect(() => {
    dispatch(fetchSntDetails());
  }, []);

  return (
    <div>
      <div className={styles.header}>
        <h1 className="title-h1">Реестр участков</h1>
        <div className={styles.header_actions}>
          <BaseButton variant="tertiary" onClick={showInstructionsModal}>
            <span className={styles.instruction_btn} role="button">
              Как заполнять правильно?
            </span>
          </BaseButton>
          <div>
            <UploadFileModal
              title="Загрузить реестр участков"
              id="upload-snt-land-registry"
              onChangeFile={(file) => handleChangeFile(file)}
              disabled={canNotUpdateReestr}
            >
              <BaseTooltip title={canNotUpdateReestr ? canNotUpdateReestrTooltip : ""}>
                <BaseButton disabled={canNotUpdateReestr} onClick={(event) => event.preventDefault()}>
                  <span>Загрузить реестр участков</span>
                </BaseButton>
              </BaseTooltip>
            </UploadFileModal>
          </div>
          <div>
            <BaseQuerySearch
              style={{ width: "340px" }}
              value={query}
              onChange={handleQueryChange}
              placeholder="Поиск по ФИО или номеру участка"
            />
          </div>
        </div>
      </div>

      <div className={styles.statistics}>
        <div className={styles.statistics_title}>
          <span className="headline-h4 sf-text-bold">Отслеживать реестры участков стало проще</span>
        </div>
        <div className={styles.statistics_content}>
          <div>
            <span className="headline-h4 sf-text-bold">Количество участков</span>
            <p className="headline-h2 sf-bold">{details?.SectionsCount ?? "0"}</p>
          </div>
          <div>
            <span className="headline-h4 sf-text-bold">Количество садоводов</span>
            <p className="headline-h2 sf-bold">{details?.SectionsOwnerCount ?? "0"}</p>
          </div>
          <div>
            <span className="headline-h4 sf-text-bold">Количество членов садоводства</span>
            <p className="headline-h2 sf-bold">{details?.SectionsGardenOwnerCount ?? "0"}</p>
          </div>
        </div>
      </div>

      {query.length === 0 && isSntOwner === null && !isSectionsLoading && sections.length === 0 && (
        <div className={styles.badge_empty}>
          <Headline
            backgroundColor="#FFCFE6"
            iconColor="#AE6A8B"
            title="Для работы с системой загрузите реестр участков"
            text="Загрузите реестр участков в формате .xlsx  или пропустите шаг загрузки реестра и перейдите к ручному добавлению."
          >
            <span
              className={`text--primary sf-text-medium ${styles.instruction_btn}`}
              role="button"
              onClick={showInstructionsModal}
            >
              Как заполнять правильно?
            </span>
          </Headline>
        </div>
      )}

      <BaseContainer className={styles.table}>
        <BaseTableToolbar caption={lastUpdated ? `Последнее обновление: ${lastUpdated.formatDateTime()}` : ""}>
          <div className="flex middle" style={{ gap: "8px" }}>
            <BaseTooltip title="Добавить участок вручную">
              <BaseButton variant="icon-basic" onClick={showAddModal}>
                {tableToolbarAddIcon}
              </BaseButton>
            </BaseTooltip>
            <UploadFileModal
              title="Загрузить реестр участков"
              id="upload-snt-land-registry"
              onChangeFile={(file) => handleChangeFile(file)}
              disabled={canNotUpdateReestr}
            >
              <BaseTooltip title={canNotUpdateReestr ? canNotUpdateReestrTooltip : "Загрузить реестр участков"}>
                <BaseButton disabled={canNotUpdateReestr} variant="icon-basic">
                  {tableToolbarUploadIcon}
                </BaseButton>
              </BaseTooltip>
            </UploadFileModal>
          </div>

          <BaseTooltip title="Cкачать файл Excel">
            <BaseButton variant="icon-basic" disabled={sections.length === 0} onClick={exportFile}>
              {tableToolbarSaveIcon}
            </BaseButton>
          </BaseTooltip>

          <BaseDropdown
            className={styles.filters}
            placeholder="Фильтр"
            value={isSntOwner}
            isSelectable={true}
            disabled={query.length === 0 && isSntOwner === null && sections.length === 0}
            onSelect={(value) => updateSort(value)}
            customIcon={dropdownSecondaryChevron}
          >
            <div className={styles.filters_options}>
              <BaseDropdownMenuItem value={null} defaultHover>
                <span className="sf-text-medium">Все участки</span>
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={false} defaultHover>
                {notGardeningMembers}
                <span className="sf-text-regular">Не являются членами садоводства</span>
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={true} defaultHover>
                {gardeningMembers}
                <span className="sf-text-regular">Являются членами садоводства</span>
              </BaseDropdownMenuItem>
            </div>
          </BaseDropdown>
        </BaseTableToolbar>

        <BaseTable className="striped-rows">
          <BaseTableHead>
            <BaseTableRow>
              <BaseTableHeadCell>Номер участка</BaseTableHeadCell>
              <BaseTableHeadCell>Данные собственника</BaseTableHeadCell>
              <BaseTableHeadCell>Номер телефона</BaseTableHeadCell>
              <BaseTableHeadCell>Является членом садоводства</BaseTableHeadCell>
              <BaseTableHeadCell></BaseTableHeadCell>
            </BaseTableRow>
          </BaseTableHead>

          {isSectionsLoading ? (
            <BaseTableBody className={styles.empty} refProp={emptyBlockRef}>
              <BaseTableRow>
                <BaseTableCell colspan={5} style={{ height: `${emptyBlockHeight}px` }}>
                  <div className="flex center">
                    <Spinner color="#226dff" size={36} />
                  </div>
                </BaseTableCell>
              </BaseTableRow>
            </BaseTableBody>
          ) : sections.length ? (
            <>
              {sections.map((it, index) => (
                <SntRegistryItemRow
                  key={it.Section.Id}
                  section={it}
                  onOpen={() => showViewModal(it)}
                  onEdit={() => showEditModal(it)}
                  onDelete={() => showDeleteModal(it)}
                />
              ))}
              {hasMore && (
                <tbody className={styles.loading_more} ref={measureRef}>
                  <tr>
                    <td colSpan={5}>
                      <div className="flex center">
                        <Spinner color="#226dff" size={36} />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </>
          ) : (
            <BaseTableBody className={styles.empty} refProp={emptyBlockRef}>
              <BaseTableRow>
                <BaseTableCell colspan={5} style={{ height: `${emptyBlockHeight}px` }}>
                  <div className="center">
                    {query.length > 0 || isSntOwner !== null ? (
                      <p className="text--primary sf-pro-regular color--text-primary">Поиск не дал результатов</p>
                    ) : (
                      <>
                        <p className="text--primary sf-pro-regular color--text-primary">Реестра участков пока нет</p>
                        <div className="flex middle center">
                          <BaseButton variant="secondary" startIcon={tableToolbarAddIcon} onClick={showAddModal}>
                            Добавить участок вручную
                          </BaseButton>
                          <UploadFileModal
                            title="Загрузить реестр участков"
                            id="upload-snt-land-registry"
                            onChangeFile={(file) => handleChangeFile(file)}
                            disabled={canNotUpdateReestr}
                          >
                            <BaseTooltip title={canNotUpdateReestr ? canNotUpdateReestrTooltip : ""}>
                              <BaseButton
                                disabled={canNotUpdateReestr}
                                variant="secondary"
                                startIcon={tableToolbarUploadIcon}
                              >
                                Загрузить реестр участков
                              </BaseButton>
                            </BaseTooltip>
                          </UploadFileModal>
                        </div>
                      </>
                    )}
                  </div>
                </BaseTableCell>
              </BaseTableRow>
            </BaseTableBody>
          )}
        </BaseTable>
      </BaseContainer>

      <InstructionsModal />

      <DeleteModal />

      <RegistryFileErrorsModal
        isOpen={isRegistryFileErrorsShown}
        errors={registryFileErrors}
        onClose={() => setRegistryFileErrorsShown(false)}
      />

      <EditSectionModal />
    </div>
  );
};

export default SntLandRegistry;
