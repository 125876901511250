import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import {
  UKRosreestrState,
  pushApartments,
  setApartments,
  setDetails,
  setPagination,
  setQuery,
  setStatus,
  toggleLoading,
  toggleRosreestrDataLoadStartedModal,
  toggleStartRosreestrUploadLoading,
  toggleSystemMessage,
} from "./";
import { activeAddress, checkIfCadastralNumberExist } from "../../user";
import { UKRegistryService } from "@/services/v2";
import { Pagination, UKRegistry } from "@/types";
import { checkTotalArea, toggleSetCadastralNumberModal } from "../registry";

export const fetchRosreestrLoadInfo = createAsyncThunk<UKRegistry.RosreestrСomparison[], undefined>(
  "uk-rosreestr/fetch-rosreestr-load-info",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);
    dispatch(setQuery(""));
    dispatch(setStatus(null));
    dispatch(toggleLoading(true));
    dispatch(setDetails(null));
    dispatch(setApartments([]));
    dispatch(setPagination(null));
    dispatch(
      toggleSystemMessage({
        isShown: false,
        date: null,
        apartments: [],
        pagination: null,
      })
    );
    try {
      if (address?.FiasId) {
        const { data } = await UKRegistryService.getRosreestrDataLoadInfo(address?.FiasId);
        dispatch(setDetails(data.Data));
        if (!data.Data.LastLoadDate) {
          dispatch(toggleLoading(false));
          return [];
        }
        const apartmentsResponse = await dispatch(fetchApartmentsComparison()).unwrap();
        return apartmentsResponse;
      }

      throw Error("no address id");
    } catch (err: any) {
      dispatch(toggleLoading(false));
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

export const updateApartmentsComparisonOnBackground = createAsyncThunk<unknown, undefined>(
  "uk-rosreestr/update-apartments-comparison-on-background",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);
    try {
      if (address?.FiasId) {
        const { data } = await UKRegistryService.getApartmentsComparison({
          HouseFiasId: address?.FiasId,
          ApartmentNumber: rootState.ukRosreestr.query ? rootState.ukRosreestr.query : undefined,
          Status: rootState.ukRosreestr.status ? rootState.ukRosreestr.status : undefined,
          limit: rootState.ukRosreestr.apartments.length > 0 ? rootState.ukRosreestr.apartments.length : 50,
        });
        dispatch(setApartments(data.Data));
        dispatch(setPagination(data.Page));
        return data.Data;
      }
      return null;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);

export const fetchApartmentsComparison = createAsyncThunk<UKRegistry.RosreestrСomparison[], undefined>(
  "uk-rosreestr/fetch-apartments-сomparison",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);
    dispatch(toggleLoading(true));
    dispatch(setApartments([]));
    dispatch(setPagination(null));
    try {
      if (address?.FiasId) {
        const { data } = await UKRegistryService.getApartmentsComparison({
          HouseFiasId: address?.FiasId,
          ApartmentNumber: rootState.ukRosreestr.query ? rootState.ukRosreestr.query : undefined,
          Status: rootState.ukRosreestr.status ? rootState.ukRosreestr.status : undefined,
        });
        dispatch(setApartments(data.Data));
        dispatch(setPagination(data.Page));
        return data.Data;
      }

      throw Error("no address id");
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    } finally {
      dispatch(toggleLoading(false));
    }
  }
);

export const loadApartmentsComparison = createAsyncThunk<Pagination, undefined>(
  "uk-rosreestr/load-more-apartments-сomparison",
  async (data, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);
    try {
      if (address?.FiasId) {
        const { data } = await UKRegistryService.getApartmentsComparison({
          HouseFiasId: address?.FiasId,
          ApartmentNumber: rootState.ukRosreestr.query ? rootState.ukRosreestr.query : undefined,
          Status: rootState.ukRosreestr.status ? rootState.ukRosreestr.status : undefined,
          page: (rootState.ukRosreestr.pagination?.Index ?? 0) + 1,
        });
        dispatch(pushApartments(data.Data));
        dispatch(setPagination(data.Page));
        return data.Page;
      }

      throw Error("no id");
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

export const setSearchQuery = createAsyncThunk(
  "uk-rosreestr/set-search-query",
  async (query: string, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    if (rootState.ukRosreestr.query === query) {
      return;
    }

    dispatch(setQuery(query));
    dispatch(fetchApartmentsComparison());
  }
);

export const setStatusFilter = createAsyncThunk(
  "uk-registry/set-owners-source-filter",
  async (source: UKRosreestrState["status"], { rejectWithValue, getState, dispatch }) => {
    dispatch(setStatus(source));
    dispatch(fetchApartmentsComparison());
  }
);

export const startRosreestrDataUpload = createAsyncThunk<unknown, undefined>(
  "uk-rosreestr/start-rosreestr-data-upload",
  async (data, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);
    dispatch(toggleStartRosreestrUploadLoading(true));
    try {
      if (address?.FiasId) {
        const isExist = await dispatch(checkIfCadastralNumberExist()).unwrap();
        if (!isExist) {
          dispatch(toggleSetCadastralNumberModal(true));
          return;
        }
        const isAreaFilled = await dispatch(checkTotalArea()).unwrap();
        if (!isAreaFilled) {
          return;
        }

        await UKRegistryService.startRosreestrDataLoad(address?.FiasId);
        const { data } = await UKRegistryService.getRosreestrDataLoadInfo(address?.FiasId);
        dispatch(setDetails(data.Data));
        dispatch(toggleRosreestrDataLoadStartedModal(true));
        return data;
      }

      throw Error("no id");
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    } finally {
      dispatch(toggleStartRosreestrUploadLoading(false));
    }
  }
);
