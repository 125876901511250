import { Profile, ResponseAbstract } from "..";
import { Dictionary } from "../dictionary";
import { House } from "../house";
import { RegistryBase } from "./base";
import { DatepickerField, Form, FormFiled, FormValidator, SelectField } from "../ui";
import errorMessages from "@/helpers/errorMessages.json";
import { isValid, parse } from "date-fns";

export namespace UKRegistry {
  export type GetHouseApartmentsRequestBody = {
    HouseFiasId: House.Item["FiasId"];
    OwnerSearch?: string;
    Source?: OwnerSource;
  };

  export type Apartment = {
    Id: number;
    CadastralNumber: string;
    FiasId: string;
    Number: string;
    Address: string;
    Area: string;
    Type: Dictionary.ApartmentDictionaryItem<Dictionary.ApartmentType>["Name"];
    Owners: ApartmentOwner[];
  };

  export type ApartmentOwner = RegistryBase.Owner & {
    OGRN: string;
    TypeOwnership: Dictionary.ApartmentDictionaryItem<Dictionary.ApartmentOwnership>["ShortName"];
    SizeShares: string;
    DateRecordNumber: string;
    RecordDatetime: string;
    IsLegalEntity: EntityLegal;
    Source: OwnerSource;
  };

  export enum EntityLegal {
    Individual = 0,
    LegalEntity = 1,
  }

  export enum OwnerSource {
    Rosreestr = "ROSREESTR",
    LokoloMobileApp = "LOKOLO_MOBILE_APP",
    ManualLoading = "MANUAL_LOADING",
  }

  export type HouseDetails = {
    Id: House.Item["Id"];
    CadastralNumber: Profile.CadastralNumber["CadastralNumber"];
    FiasId: House.Item["FiasId"];
    Address: House.Item["Address"];
    ApartmentsResidentialArea: number;
    ApartmentsNonresidentialArea: number;
    OthersArea: number;
    Area: number | string;
    ApartmentsResidentialCount: number;
    ApartmentsNonresidentialCount: number;
    OthersCount: number;
    Count: number;
  };

  export type HouseRegistryDetails = HouseDetails & {
    UpdatedAt: number;
    LegalRelationsSubjectCount: {
      LegalEntityCount: number;
      IndividualCount: number;
    };
    IsCanUpdateReestr: boolean;
  };

  export type ApartmentFullDetails = Apartment & {
    House: HouseDetails;
  };

  export type ApartmentDetailsResponse = ResponseAbstract<ApartmentFullDetails> & {
    Included: RegistryBase.OwnerChangeInclude[];
  };

  export type CreatedApartmentOwner = {
    Id: RegistryBase.Owner["Id"];
    FullName: ApartmentOwner["FullName"];
    OGRN: ApartmentOwner["OGRN"];
    TypeOwnership: Dictionary.ApartmentDictionaryItem<Dictionary.ApartmentOwnership>["ShortName"];
    SizeShares: ApartmentOwner["SizeShares"];
    DateRecordNumber: ApartmentOwner["DateRecordNumber"];
    RecordDatetime: ApartmentOwner["RecordDatetime"];
  };

  export class ApartmentForm extends Form {
    readonly HouseId: HouseDetails["Id"] | null = null;
    readonly HouseFiasId: HouseDetails["FiasId"] | null = null;
    Number: FormFiled;
    Area: FormFiled;
    Type: SelectField<Dictionary.ApartmentDictionaryItem<Dictionary.ApartmentType> | null>;
    CadastralNumber: FormFiled;
    Owners: Array<CreatedApartmentOwner | ApartmentOwner> = [];
    CreatedOwners: CreatedApartmentOwner[] = [];
    UpdatedOwners: ApartmentOwner[] = [];
    DeletedOwners: Array<ApartmentOwner["Id"]> = [];
    // OwnersError: string = "";
    OwnersError: string[] = [];
    OwnerChange: RegistryBase.OwnerChangeInclude[] = [];

    constructor(
      house?: HouseDetails | House.Item,
      apartment?: Apartment,
      types?: Dictionary.ApartmentDictionaryItem<Dictionary.ApartmentType>[],
      ownerChange?: RegistryBase.OwnerChangeInclude[]
    ) {
      super();
      this.HouseId = house?.Id ?? null;
      this.HouseFiasId = house?.FiasId ?? null;
      this.Number = new FormFiled(apartment?.Number ?? "", true, false, 20);
      this.Area = new FormFiled(apartment?.Area ?? "", true, false);
      this.CadastralNumber = new FormFiled(apartment?.CadastralNumber ?? "", false);
      if (apartment?.Type && types && types.length > 0) {
        const type = types.find((it) => it.Name === apartment.Type || it.ShortName === apartment.Type);
        this.Type = new SelectField(type, true, false);
      } else this.Type = new SelectField(null, true, false);
      this.Owners = apartment?.Owners ?? [];
      this.OwnerChange = ownerChange ?? [];
    }
  }

  export class ApartmentFormValidator {
    static validateNumber(field: ApartmentForm["Number"]) {
      const errorMessage = FormValidator.getFieldErrorMessage(field.value.trim(), field.isRequired);

      const regex = /^[0-9А-Яа-я\s_-]*$/;
      if (
        (field.isRequired || (!field.isRequired && field.value.trim().length > 0)) &&
        !regex.test(field.value.trim())
      ) {
        return errorMessages.number.invalid;
      }

      return errorMessage;
    }

    static validateArea(field: ApartmentForm["Area"]) {
      const errorMessage = FormValidator.getFieldErrorMessage(field.value.trim(), field.isRequired);

      return errorMessage;
    }

    static validateOwners(owners: ApartmentForm["Owners"]) {
      if (owners.length === 0) return errorMessages.owners.empty;

      return "";
    }

    static validateType(field: ApartmentForm["Type"]) {
      const errorMessage = FormValidator.getFieldErrorMessage(field.value?.ShortName ?? "", field.isRequired);

      return errorMessage;
    }

    public static isInvalid(form: ApartmentForm) {
      const errors = {
        Number: this.validateNumber(form.Number),
        Area: this.validateArea(form.Area),
        CadastralNumber: FormValidator.getFieldErrorMessage(
          form.CadastralNumber.value.trim(),
          form.CadastralNumber.isRequired
        ),
        Owners: this.validateOwners(form.Owners),
        Type: this.validateType(form.Type),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export class OwnerForm extends Form {
    FullName: FormFiled;
    TypeOwnership: SelectField<Dictionary.ApartmentDictionaryItem<Dictionary.ApartmentOwnership> | null>;
    OGRN: FormFiled;
    SizeShares: FormFiled;
    DateRecordNumber: FormFiled;
    RecordDatetime: DatepickerField = new DatepickerField({ isRequired: true });

    constructor(
      owner?: ApartmentOwner | CreatedApartmentOwner,
      ownerships?: Dictionary.ApartmentDictionaryItem<Dictionary.ApartmentOwnership>[]
    ) {
      super();
      this.FullName = new FormFiled(owner?.FullName ?? "", true);
      this.OGRN = new FormFiled(owner?.OGRN ?? "", false);
      this.SizeShares = new FormFiled(owner?.SizeShares ?? "", true);
      this.DateRecordNumber = new FormFiled(owner?.DateRecordNumber ?? "", true);

      if (owner?.RecordDatetime && typeof owner?.RecordDatetime === "string") {
        this.RecordDatetime = new DatepickerField({
          value: parse(owner?.RecordDatetime, "dd.MM.yyyy", new Date()),
          isRequired: true,
        });
      } else if (owner?.RecordDatetime && typeof owner?.RecordDatetime === "object" && isValid(owner?.RecordDatetime)) {
        this.RecordDatetime = new DatepickerField({
          value: owner?.RecordDatetime,
          isRequired: true,
        });
      } else {
        this.RecordDatetime = new DatepickerField({ isRequired: true });
      }

      if (owner?.TypeOwnership && ownerships && ownerships.length > 0) {
        const ownership = ownerships.find(
          (it) => it.Name === owner?.TypeOwnership || it.ShortName === owner?.TypeOwnership
        );
        this.TypeOwnership = new SelectField(ownership, true, false);
      } else this.TypeOwnership = new SelectField(null, true, false);
    }
  }

  export class OwnerFormValidator {
    static validateFullName(field: OwnerForm["FullName"], OGRN: OwnerForm["OGRN"]) {
      const errorMessage = FormValidator.getFieldErrorMessage(field.value.trim(), field.isRequired);

      const regex = /^[\p{Script=Cyrillic}\s-]*$/u;
      if (
        OGRN.value.length === 0 &&
        (field.isRequired || (!field.isRequired && field.value.trim().length > 0)) &&
        !regex.test(field.value.trim())
      ) {
        return errorMessages.fullName.invalid.individual;
      }

      return errorMessage;
    }

    static validateOGRN(field: OwnerForm["OGRN"]) {
      const errorMessage = FormValidator.getFieldErrorMessage(field.value.trim(), field.isRequired);

      const regex = /^\d{13}$/gm;
      if (
        (field.isRequired || (!field.isRequired && field.value.trim().length > 0)) &&
        !regex.test(field.value.trim())
      ) {
        return errorMessages.OGRN.invalid;
      }

      return errorMessage;
    }

    static validateTypeOwnership(field: OwnerForm["TypeOwnership"]) {
      const errorMessage = FormValidator.getFieldErrorMessage(field.value?.ShortName ?? "", field.isRequired);

      return errorMessage;
    }

    static validateSizeShares(field: OwnerForm["SizeShares"], owners: Array<CreatedApartmentOwner | ApartmentOwner>) {
      const errorMessage = FormValidator.getFieldErrorMessage(field.value.trim(), field.isRequired);

      return errorMessage;
    }

    static validateRecordDatetime(field: OwnerForm["RecordDatetime"]) {
      if (!field.isRequired && !field.value) return "";

      if (!field.value) {
        return errorMessages.empty;
      }

      return "";
    }

    public static isInvalid(form: OwnerForm, owners: Array<CreatedApartmentOwner | ApartmentOwner>) {
      const errors = {
        FullName: this.validateFullName(form.FullName, form.OGRN),
        OGRN: this.validateOGRN(form.OGRN),
        TypeOwnership: this.validateTypeOwnership(form.TypeOwnership),
        SizeShares: this.validateSizeShares(form.SizeShares, owners),
        DateRecordNumber: FormValidator.getFieldErrorMessage(
          form.DateRecordNumber.value.trim(),
          form.DateRecordNumber.isRequired
        ),
        RecordDatetime: this.validateRecordDatetime(form.RecordDatetime),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export type CreateApartmentRequestBody = {
    HouseId: HouseDetails["Id"];
    HouseFiasId: HouseDetails["FiasId"];
    Number: Apartment["Number"];
    Area: Apartment["Area"];
    Type: Dictionary.ApartmentDictionaryItem<Dictionary.ApartmentType>["ShortName"];
    CadastralNumber: Apartment["CadastralNumber"];
    CreatedOwners: CreatedApartmentOwner[];
  };

  export type UpdateApartmentRequestBody = CreateApartmentRequestBody & {
    Id: Apartment["Id"];
    UpdatedOwners?: ApartmentOwner[];
    DeletedOwners?: Array<{ Id: ApartmentOwner["Id"] }>;
  };

  export type RosreestrDataLoadInfo = {
    IsLoaded: boolean;
    LastLoadDate?: string;
    CanReload: boolean;
    ReloadError?: string;
  };

  export type RosreestrСomparison = {
    Id: number;
    Number: Apartment["Number"];
    Area: Apartment["Area"];
    CadastralNumber: Apartment["CadastralNumber"];
    Type: Apartment["Type"];
    Status: {
      Key: RosreestrСomparisonStatus;
      Message: string;
    };
  };

  export enum RosreestrСomparisonStatus {
    ReestrDataMissing = "REESTR_DATA_MISSING",
    InProgress = "IN_PROGRESS",
    Match = "MATCH",
    DontMatch = "DONT_MATCH",
  }

  export type RosreestrСomparisonDetails = {
    Id: RosreestrСomparison["Id"];
    Apartment: {
      Number: Apartment["Number"];
      Reestr: ApartmentСomparison | null;
      Rosreestr: ApartmentСomparison | null;
      Message?: string;
      Differences: Array<keyof ApartmentСomparison>;
    };
    Owners: RosreestrСomparisonOwner[];
  };

  export class RosreestrСomparisonDetailsMapped {
    details: RosreestrСomparisonDetails;
    apartment: {
      initial: RosreestrСomparisonDetails["Apartment"];
      value: ApartmentСomparison | null;
      isDeleted: boolean;
      isCreated: boolean;
    };
    owners: Array<{
      initial: RosreestrСomparisonOwner;
      value: OwnerСomparison | null;
      isDeleted: boolean;
      isCreated: boolean;
    }>;

    constructor(details: RosreestrСomparisonDetails) {
      this.details = details;
      this.apartment = {
        initial: details.Apartment,
        value: details.Apartment.Reestr,
        isDeleted: false,
        isCreated: false,
      };
      this.owners = details.Owners.map((it) => ({
        initial: it,
        value: it.Reestr,
        isDeleted: false,
        isCreated: false,
      }));
    }
  }

  export type RosreestrСomparisonOwner = {
    FullName: ApartmentOwner["FullName"];
    Reestr: OwnerСomparison | null;
    Rosreestr: OwnerСomparison | null;
    Message?: string;
    Differences: Array<keyof OwnerСomparison>;
  };

  export type ApartmentСomparison = {
    Area: Apartment["Area"];
    CadastralNumber: Apartment["CadastralNumber"];
    Type: Apartment["Type"];
  };

  export type OwnerСomparison = {
    Id: ApartmentOwner["Id"] | number; // number – id from Rosreestr
    TypeOwnership: ApartmentOwner["TypeOwnership"];
    SizeShares: ApartmentOwner["SizeShares"];
    RecordDatetime: ApartmentOwner["RecordDatetime"];
    DateRecordNumber: ApartmentOwner["DateRecordNumber"];
    Ogrn: ApartmentOwner["OGRN"];
  };

  export enum SaveApartmentComparisonChangesType {
    Delete = "DELETE",
    Create = "CREATE",
    Change = "CHANGE",
  }

  export type SaveApartmentComparisonChangesRequestBody = {
    HouseFiasId: House.Item["FiasId"];
    Type: SaveApartmentComparisonChangesType;
    ApartmentFields?: Array<keyof ApartmentСomparison>;
    OwnersCreate?: Array<OwnerСomparison["Id"]>;
    OwnersDelete?: Array<OwnerСomparison["Id"]>;
    OwnersChange?: Array<{
      ReestrId: OwnerСomparison["Id"];
      RosreestrId: OwnerСomparison["Id"];
      Fields: Array<keyof OwnerСomparison>;
    }>;
  };
}
