import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import BaseModal, { BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import BaseInput from "@/components/base-input";
import BaseTextarea from "@/components/base-textarea";
import { RootState, useAppDispatch } from "@/app/store";
import { createQuestion, updateQuestion } from "@/app/store/slices/templates/actions";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { Templates, Dictionary } from "@/types";
import styles from "../management-template.module.scss";

interface IEditQuestionModal {
  isOpen: boolean;
  group: Templates.Group;
  question?: Templates.Question | null;
  onClose: () => void;
}

const EditQuestionModal = ({ isOpen, group, question, onClose }: IEditQuestionModal) => {
  const dispatch = useAppDispatch();
  const quorumsList = useSelector((state: RootState) => state.dictionary.quorums.list);
  const [form, setForm] = useState<Templates.EditQuestionForm>(new Templates.EditQuestionForm(group.Id));

  const handleClose = () => {
    onClose();
  };

  const updateFormFieldValue = (key: "Title" | "Value", value: string) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: { ...form[key], value },
    }));
  };

  const updateQuorumValue = (value: Dictionary.DictionaryItem["id"]) => {
    const type = quorumsList.find((it) => it.id === value);
    if (type) {
      setForm((prevState) => ({
        ...prevState,
        Quorum: { ...type },
      }));
    }
  };

  const handleError = (payload: any) => {
    if (payload.Errors) {
      Object.keys(payload.Errors).forEach((key: any) => {
        const message = Array.isArray(payload.Errors[key]) ? payload.Errors[key][0] : payload.Errors[key];
        switch (key) {
          case "Title": {
            setForm((prevState) => ({
              ...prevState,
              Title: { ...form.Title, error: message },
            }));
            break;
          }

          case "Value": {
            setForm((prevState) => ({
              ...prevState,
              Value: { ...form.Value, error: message },
            }));
            break;
          }

          case "Quorum": {
            setForm((prevState) => ({
              ...prevState,
              QuorumError: message,
            }));
            break;
          }

          default: {
            setForm((prevState) => ({ ...prevState, error: message }));
            dispatch(showSnackbar({ key: "edit-template-question-error", body: message, type: SnackbarType.ERROR }));
            break;
          }
        }
      });
    } else {
      setForm((prevState) => ({ ...prevState, error: payload.Message }));
      dispatch(showSnackbar({ key: "edit-template-question-error", body: payload.Message, type: SnackbarType.ERROR }));
    }
  };

  const isFormValid = () => {
    const validator = Templates.EditQuestionFormValidator.isInvalid(form);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        Title: {
          ...form.Title,
          error: validator.Title,
        },
        Value: {
          ...form.Value,
          error: validator.Value,
        },
        QuorumError: validator.Quorum,
      }));
    }
    return !validator;
  };

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });

    setForm((prevState) => ({
      ...prevState,
      error: "",
      QuorumError: "",
    }));

    if (!isFormValid()) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    if (form.Quorum) {
      try {
        if (question) {
          await dispatch(
            updateQuestion({
              ...question,
              Title: form.Title.value.trim(),
              Value: form.Value.value.trim(),
              QuorumId: form.Quorum.id,
            })
          ).unwrap();
        } else {
          await dispatch(
            createQuestion({
              GroupId: group.Id,
              QuorumId: form.Quorum.id,
              Title: form.Title.value.trim(),
              Value: form.Value.value.trim(),
            })
          ).unwrap();
        }
        onClose();
      } catch (error: any) {
        console.warn(error);
        handleError(error.Data);
      } finally {
        setForm((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    }
  };

  useEffect(() => {
    setForm(new Templates.EditQuestionForm(group.Id, question ?? undefined, quorumsList));
  }, [isOpen]);

  const isSubmitDisabled: boolean = useMemo(
    () =>
      form.Title.value.length === 0 ||
      (form.Title.maxlength && form.Title.value.length > form.Title.maxlength) ||
      form.Value.value.length === 0 ||
      (form.Value.maxlength && form.Value.value.length > form.Value.maxlength) ||
      form.Quorum === null,
    [form]
  );

  return (
    <BaseModal isOpen={isOpen} minWidth="670px" maxWidth="670px">
      <BaseModalHeader title={!!question ? "Редактировать шаблон" : "Добавить шаблон"} onClose={handleClose} />

      <BaseModalContent>
        <form className={styles.edit_question_form} onSubmit={handleOnSubmit}>
          <div>
            <BaseDropdown
              value={form.Quorum?.id ?? null}
              display={form.Quorum?.name}
              isSelectable={true}
              label="Кворум по вопросу повестки"
              placeholder="Не менее 2/3 от голосов, принявших участие в голосовании"
              variant="formfield"
              required={true}
              onSelect={updateQuorumValue}
            >
              {quorumsList.map((quorum) => (
                <BaseDropdownMenuItem key={quorum.id} value={quorum.id}>
                  <span className="sf-text-medium">{quorum.name}</span>
                </BaseDropdownMenuItem>
              ))}
            </BaseDropdown>
          </div>

          <div>
            <BaseInput
              value={form.Title.value}
              label="Заголовок вопроса"
              required={form.Title.isRequired}
              placeholder="Например: Выбрать систему для проведения общих собраний"
              errorMessage={form.Title.error}
              maxlength={form.Title.maxlength}
              counter={true}
              onChange={(value) => updateFormFieldValue("Title", value)}
            />
          </div>

          <div>
            <BaseTextarea
              minRows={1}
              value={form.Value.value}
              label="Введите текст вопроса (решения)"
              required={form.Value.isRequired}
              placeholder="Например: Применять государственную информационную систему Республики Татарстан «Локоло» при проведении общих собраний в форме заочного голосования с использованием системы"
              errorMessage={form.Value.error}
              maxlength={form.Value.maxlength}
              counter={true}
              onChange={(value) => updateFormFieldValue("Value", value)}
            />
          </div>
        </form>
      </BaseModalContent>

      <BaseModalActions variant="bordered">
        <BaseButton variant="secondary" onClick={onClose}>
          Отменить
        </BaseButton>
        <BaseButton isLoading={form.isLoading} disabled={isSubmitDisabled} onClick={handleOnSubmit}>
          {!!question ? "Сохранить" : "Добавить"}
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default EditQuestionModal;
