import { Spinner } from "@/components/spinner";

const CreateOccItemPageLoader: React.FC = () => {
  return (
    <div className="flex middle center" style={{ padding: "48px 24px", minHeight: "300px" }}>
      <Spinner size={36} color="#226dff" />
    </div>
  );
};

export default CreateOccItemPageLoader;
