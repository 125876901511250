import styles from "./contacts.module.scss";
import { UsefulContacts } from "../../types";
import { editIcon, clearIcon } from "../../consts/icons";
import BaseButton from "../../components/base-button";
import BaseTooltip from "../../components/base-tooltip";

interface IContactCollapsed {
  company: UsefulContacts.UsefulContactUnitedType;
  onEdit: (id: UsefulContacts.Item["Id"]) => void;
  onRemove: (id: UsefulContacts.Item["Id"]) => void;
}

const ContactCollapsed = ({ company, onEdit, onRemove }: IContactCollapsed) => {
  const handleEdit = (event: React.MouseEvent) => {
    event.stopPropagation();
    onEdit(company.Id);
  };

  const handleRemove = (event: React.MouseEvent) => {
    event.stopPropagation();
    onRemove(company.Id);
  };

  return (
    <div className={styles.toggler}>
      <span>{company.Name}</span>
      <div className={styles.actions}>
        <BaseTooltip title="Удалить">
          <BaseButton variant="icon-squared" onClick={(e) => handleRemove(e)}>
            {clearIcon}
          </BaseButton>
        </BaseTooltip>

        <BaseTooltip title="Редактировать">
          <BaseButton variant="icon-squared" onClick={(e) => handleEdit(e)}>
            {editIcon()}
          </BaseButton>
        </BaseTooltip>
      </div>
    </div>
  );
};

export default ContactCollapsed;
