import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import {
  SntsMapState,
  setMarkers,
  setSelectedSnt,
  setSelectedSntId,
  setSelectedLoading,
  removeSntMarker,
  updateSntMarker,
  setDeleteLoading,
  setUpdateGeoLoading,
  setDeleteOpen,
  setState,
  addSntMarker,
  setSelectSntLoading,
  setSelectSntModalOpen,
} from "./";
import { InstitutionSntMapService, SntPassportService } from "@/services/v2";
import { SntPassport, SntPassportMap, SntRegistry } from "@/types";

export const fetchSntMarkers = createAsyncThunk<SntsMapState["markers"], undefined>(
  "snts-map/fetch-markers",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const { data } = await InstitutionSntMapService.getList();
      await dispatch(setMarkers(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const selectMarker = createAsyncThunk<
  SntPassport.InstitutionSntItem | null,
  SntRegistry.SntDetails["Id"] | null
>("snts-map/select-marker", async (payload, { dispatch, getState, rejectWithValue }) => {
  if (!payload) {
    dispatch(setSelectedSntId(null));
    dispatch(setSelectedSnt(null));
    return null;
  }

  dispatch(setSelectedSntId(payload));
  dispatch(setSelectedLoading(true));
  try {
    const { data } = await SntPassportService.getSntById(payload);
    await dispatch(setSelectedSnt(data.Data));
    return data.Data;
  } catch (error: any) {
    await dispatch(setSelectedSnt(null));
    return rejectWithValue(error?.response?.data);
  } finally {
    dispatch(setSelectedLoading(false));
  }
});

export const removeMarker = createAsyncThunk<SntPassportMap.SntItem, undefined>(
  "snts-map/remove-marker",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const rootState = getState() as RootState;
    if (!rootState.sntsMap.selected.snt) {
      throw Error("no id");
    }

    dispatch(setDeleteLoading(true));
    try {
      const { data } = await InstitutionSntMapService.deleteSntGeopoint(rootState.sntsMap.selected.snt.Id);
      await dispatch(removeSntMarker(data.Data.Id));
      await dispatch(setDeleteOpen(false));
      await dispatch(setSelectedSntId(null));
      await dispatch(setSelectedSnt(null));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    } finally {
      dispatch(setDeleteLoading(false));
    }
  }
);

export const updateSntGeopoint = createAsyncThunk<SntPassportMap.SntItem, undefined>(
  "snts-map/update-snt-geopoint",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const rootState = getState() as RootState;
    if (!rootState.sntsMap.selected.snt) {
      throw Error("no id");
    }
    if (!rootState.sntsMap.geopoint) {
      throw Error("no selected geopoint");
    }

    dispatch(setUpdateGeoLoading(true));
    try {
      const { data } = await InstitutionSntMapService.updateSntGeopoint(
        rootState.sntsMap.selected.snt.Id,
        rootState.sntsMap.geopoint
      );
      await dispatch(updateSntMarker(data.Data));
      await dispatch(setState(SntPassportMap.MapState.Default));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    } finally {
      dispatch(setUpdateGeoLoading(false));
    }
  }
);

export const setSntForGeopoint = createAsyncThunk<SntPassportMap.SntItem, SntPassportMap.SntItem["Id"]>(
  "snts-map/set-snt-for-geopoint",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const rootState = getState() as RootState;
    if (!payload) {
      throw Error("no id");
    }

    const existedMarker = rootState.sntsMap.markers.find((it) => it.Id === payload);
    const _geopoint = existedMarker && existedMarker.Geopoint ? existedMarker.Geopoint : rootState.sntsMap.geopoint;
    if (!_geopoint) {
      throw Error("no selected geopoint");
    }

    dispatch(setSelectSntLoading(true));
    try {
      const { data } = await InstitutionSntMapService.updateSntGeopoint(payload, _geopoint);
      if (existedMarker) {
        await dispatch(removeSntMarker(existedMarker.Id));
      }
      await dispatch(addSntMarker(data.Data));
      await dispatch(setState(SntPassportMap.MapState.Default));
      dispatch(setSelectSntModalOpen(false));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    } finally {
      dispatch(setSelectSntLoading(false));
    }
  }
);

export const updateSntForGeopoint = createAsyncThunk<
  SntPassportMap.SntItem,
  { new: SntPassportMap.SntItem["Id"]; old: SntPassportMap.SntItem["Id"] }
>("snts-map/update-snt-for-geopoint", async (payload, { dispatch, getState, rejectWithValue }) => {
  const rootState = getState() as RootState;

  if (!payload) {
    throw Error("no id");
  }

  const oldMarker = rootState.sntsMap.markers.find((it) => it.Id === payload.old);
  if (payload.new === payload.old && oldMarker) {
    return oldMarker;
  }

  const geopoint = oldMarker?.Geopoint;
  if (!geopoint) {
    throw Error("no geopoint");
  }

  dispatch(setSelectSntLoading(true));
  try {
    const resp = await Promise.all([
      InstitutionSntMapService.deleteSntGeopoint(payload.old),
      InstitutionSntMapService.updateSntGeopoint(payload.new, geopoint),
    ]);
    await dispatch(removeSntMarker(payload.old));
    await dispatch(addSntMarker(resp[1].data.Data));
    dispatch(setSelectSntModalOpen(false));
    return resp[1].data.Data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data);
  } finally {
    dispatch(setSelectSntLoading(false));
  }
});
