import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import { getOwnersQuestionsResultsByOccId } from "@/app/store/slices/snt/myOcc/actions";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseContainer,
} from "@/components/base-table";
import styles from "./styles.module.scss";
import { OCC, Profile } from "@/types";

const SntMyOCCItemResultsOwners: React.FC = () => {
  const dispatch = useAppDispatch();

  const company = useSelector((state: RootState) => state.user.company);
  const occ = useSelector((state: RootState) => state.sntMyOcc.occ);
  const ownersResults = useSelector((state: RootState) => state.sntMyOcc.results.owners);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const fetch = async () => {
    setIsLoading(true);
    try {
      if (occ?.Order.Id) {
        await dispatch(getOwnersQuestionsResultsByOccId(occ?.Order.Id)).unwrap();
      }
    } catch (error: any) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onScroll = (e: React.SyntheticEvent) => {
    const scrollLeft = (e.target as HTMLElement)?.scrollLeft;
    setScrollLeft(scrollLeft);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={styles.owners}>
      <BaseContainer>
        <div
          className={`${styles.table_container} ${scrollLeft > 10 ? styles.table_shadowed : ""}`}
          onScroll={onScroll}
        >
          <BaseTable className={styles.sticky}>
            <BaseTableHead className={styles.table_head}>
              <BaseTableRow className={styles.table_header_row}>
                <BaseTableHeadCell className={styles.first_col}>
                  {company?.Type === Profile.CompanyType.SNT ? "Номер участка" : "Номер помещения"}
                </BaseTableHeadCell>
                <BaseTableHeadCell className={styles.second_col}>Данные собственника</BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            <BaseTableBody className={styles.table_body}>
              {ownersResults.map((result, i) => (
                <BaseTableRow key={`${result.ApartmentNumber}_${result.FullName}_${i}`}>
                  <BaseTableHeadCell className={styles.first_col}>
                    <span className="sf-text-semibold">{result.ApartmentNumber}</span>
                  </BaseTableHeadCell>
                  <BaseTableCell className={styles.second_col}>
                    <span className="sf-text-semibold">{result.FullName}</span>
                  </BaseTableCell>
                </BaseTableRow>
              ))}
            </BaseTableBody>
          </BaseTable>

          <BaseTable>
            <BaseTableHead className={styles.table_head}>
              <BaseTableRow className={styles.table_header_row}>
                <BaseTableHeadCell>Участие</BaseTableHeadCell>

                {company?.Type === Profile.CompanyType.SNT && <BaseTableHeadCell>Учитывается голос</BaseTableHeadCell>}

                {occ?.Questions.map((question) => (
                  <BaseTableHeadCell key={`head_${question.Id}`}>Вопрос №{question.Order}</BaseTableHeadCell>
                ))}
              </BaseTableRow>
            </BaseTableHead>

            <BaseTableBody className={styles.table_body}>
              {ownersResults.map((result, i) => (
                <BaseTableRow key={`${result.ApartmentNumber}_${result.FullName}_${i}`}>
                  <BaseTableCell>{result.IsParticipated ? "Да" : "Нет"}</BaseTableCell>

                  {company?.Type === Profile.CompanyType.SNT && (
                    <BaseTableCell>{result.VoteIsCounted ? "Да" : "Нет"}</BaseTableCell>
                  )}

                  {result.QuestionsResults.map((question) => (
                    <BaseTableCell key={`${result.ApartmentNumber}_${result.FullName}_${i}_${question.QuestionId}`}>
                      {question.ResultTypeKey === OCC.Decision.Agree ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.7071 3.29289C15.0976 3.68342 15.0976 4.31658 14.7071 4.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L1.29289 8.70711C0.902369 8.31658 0.902369 7.68342 1.29289 7.29289C1.68342 6.90237 2.31658 6.90237 2.70711 7.29289L6 10.5858L13.2929 3.29289C13.6834 2.90237 14.3166 2.90237 14.7071 3.29289Z"
                            fill="#3A3B3D"
                          />
                        </svg>
                      ) : question.ResultTypeKey === OCC.Decision.Disagree ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.19125 6.66325L12.5312 2.32325C12.9622 1.89225 13.6623 1.89225 14.0933 2.32325C14.5243 2.75425 14.5243 3.45425 14.0933 3.88525L9.75325 8.22525L14.0933 12.5653C14.5153 12.9862 14.5153 13.6713 14.0933 14.0933C13.6713 14.5153 12.9862 14.5153 12.5653 14.0933L8.22525 9.75325L3.88525 14.0933C3.45425 14.5243 2.75425 14.5243 2.32325 14.0933C1.89225 13.6623 1.89225 12.9622 2.32325 12.5312L6.66325 8.19125L2.32325 3.85125C1.90125 3.43025 1.90125 2.74525 2.32325 2.32325C2.74525 1.90125 3.43025 1.90125 3.85125 2.32325L8.19125 6.66325Z"
                            fill="#F20000"
                          />
                        </svg>
                      ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.4 7C14.731 7 15 7.269 15 7.6V8.4C15 8.731 14.731 9 14.4 9H1.6C1.269 9 1 8.731 1 8.4V7.6C1 7.269 1.269 7 1.6 7L14.4 7Z"
                            fill="#818C99"
                          />
                        </svg>
                      )}
                    </BaseTableCell>
                  ))}
                </BaseTableRow>
              ))}
            </BaseTableBody>
          </BaseTable>
        </div>
      </BaseContainer>
    </div>
  );
};

export default SntMyOCCItemResultsOwners;
