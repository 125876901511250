import RegistryDetailsTableGroup from "./group";
import styles from "./styles.module.scss";

interface IRegistryDetailsTableCardProps {
  title: string;
  children: JSX.Element | JSX.Element[];
}

const RegistryDetailsTableCard: React.FC<IRegistryDetailsTableCardProps> = ({ title, children }) => {
  return (
    <div className={styles.card}>
      <div>
        <p className="text--primary sf-text-semibold color--text-primary">{title}</p>
      </div>
      <div className={styles.list}>{children}</div>
    </div>
  );
};

export default RegistryDetailsTableCard;
export { RegistryDetailsTableCard, RegistryDetailsTableGroup };
