import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames/bind";
import { useAppDispatch } from "@/app/store";
import { SnackbarType } from "@/app/store/slices/snackbar";
import { hideSnackbar, Snackbar } from "@/app/store/slices/snackbar";
import { errorIcon, successIcon, deleteIcon, networkErrorIcon, closeIcon, doNotDisturbIcon } from "@/consts/icons";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

type ISnackbarToastProps = Omit<Snackbar, "key"> & {
  idKey: string;
};

const SnackbarToast: React.FC<ISnackbarToastProps> = ({
  idKey,
  title,
  body,
  type = SnackbarType.INFO,
  closeBtn = false,
  duration = 5000,
}) => {
  let timer: any = null;

  const dispatch = useAppDispatch();
  const [isOpen, setOpen] = useState(false);

  const nodeRef = useRef<HTMLDivElement>(null);

  const getIcon = (type: SnackbarType) => {
    switch (type) {
      case SnackbarType.ERROR:
        return errorIcon;
      case SnackbarType.SUCCESS:
        return successIcon;
      case SnackbarType.DELETE:
        return deleteIcon;
      case SnackbarType.NETWORK_ERROR:
        return networkErrorIcon;
      case SnackbarType.Block:
        return doNotDisturbIcon;

      default:
        return;
    }
  };

  const handleClose = () => {
    dispatch(hideSnackbar(idKey));
  };

  useEffect(() => {
    if (duration && typeof duration === "number") {
      timer = setTimeout(handleClose, duration);
    }

    setOpen(true);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <CSSTransition in={isOpen} nodeRef={nodeRef} timeout={300} classNames="alert" unmountOnExit>
      <div ref={nodeRef} className={cx("container", { "w-close": closeBtn })}>
        <div>
          {getIcon(type)}
          <div className={styles.content}>
            {title && <span className="sf-text-bold headline-h4 color--text-primary">{title}</span>}
            {body && <p className="sf-text-regular text--primary color--text-primary">{body}</p>}
          </div>
        </div>

        {closeBtn && (
          <span className={styles.close}>
            <span role="button" onClick={handleClose}>
              {closeIcon("#3A3B3D")}
            </span>
          </span>
        )}
      </div>
    </CSSTransition>
  );
};

export default SnackbarToast;
