import { OCCV2Order } from "@/types";
import BaseTooltip from "@/components/base-tooltip";
import styles from "./styles.module.scss";

interface IDraftOccItemProps {
  occ: OCCV2Order.Item;
  onSelect: () => void;
  onDelete: () => void;
}

const DraftOccItem: React.FC<IDraftOccItemProps> = ({ occ, onDelete, onSelect }) => {
  const handleLinkClicked = (event: React.SyntheticEvent) => {
    event.preventDefault();
    onSelect();
  };

  const handleDelete = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onDelete();
  };

  return (
    <div onClick={handleLinkClicked} className={styles["b-draft-occ"]}>
      <span>
        <h4 className="headline-h4 sf-text-bold">№{occ.Number}</h4>
        <span className="flex middle">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
            <g clipPath="url(#clip0_1939_30766)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 1.25C2.5 0.835786 2.83579 0.5 3.25 0.5C3.66421 0.5 4 0.835786 4 1.25V2.00169C4.24373 2 4.50929 2 4.8 2H7.2C7.49071 2 7.75627 2 8 2.00169V1.25C8 0.835786 8.33579 0.5 8.75 0.5C9.16421 0.5 9.5 0.835786 9.5 1.25V2.07662C9.84062 2.12428 10.1159 2.20159 10.362 2.32698C10.9265 2.6146 11.3854 3.07354 11.673 3.63803C12 4.27976 12 5.11984 12 6.8V7.2C12 8.88016 12 9.72024 11.673 10.362C11.3854 10.9265 10.9265 11.3854 10.362 11.673C9.72024 12 8.88016 12 7.2 12H4.8C3.11984 12 2.27976 12 1.63803 11.673C1.07354 11.3854 0.614601 10.9265 0.32698 10.362C0 9.72024 0 8.88016 0 7.2V6.8C0 5.11984 0 4.27976 0.32698 3.63803C0.614601 3.07354 1.07354 2.6146 1.63803 2.32698C1.88412 2.20159 2.15938 2.12428 2.5 2.07662V1.25ZM1.5 6.8C1.5 5.96751 1.50108 5.41895 1.53213 5H2.5H9.5H10.4679C10.4989 5.41895 10.5 5.96751 10.5 6.8V7.2C10.5 8.06483 10.4988 8.62324 10.4641 9.04825C10.4308 9.45574 10.3743 9.60682 10.3365 9.68099C10.1927 9.96323 9.96323 10.1927 9.68099 10.3365C9.60682 10.3743 9.45574 10.4308 9.04825 10.4641C8.62324 10.4988 8.06483 10.5 7.2 10.5H4.8C3.93517 10.5 3.37676 10.4988 2.95175 10.4641C2.54426 10.4308 2.39318 10.3743 2.31901 10.3365L1.66139 11.6272L2.31901 10.3365C2.03677 10.1927 1.8073 9.96323 1.66349 9.68099C1.6257 9.60682 1.56918 9.45574 1.53589 9.04825C1.50117 8.62324 1.5 8.06483 1.5 7.2V6.8ZM4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8H5C5.55228 8 6 7.55228 6 7C6 6.44772 5.55228 6 5 6H4Z"
                fill="#B8C1CC"
              />
            </g>
            <defs>
              <clipPath id="clip0_1939_30766">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span className="text--primary sf-text-regular">{occ.UpdatedAt}</span>
        </span>
      </span>

      <span className="flex middle">
        <BaseTooltip title="Удалить">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" onClick={handleDelete}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9 2.3C9 2.01997 9 1.87996 8.9455 1.773C8.89757 1.67892 8.82108 1.60243 8.727 1.5545C8.62004 1.5 8.48003 1.5 8.2 1.5H7.8C7.51997 1.5 7.37996 1.5 7.273 1.5545C7.17892 1.60243 7.10243 1.67892 7.0545 1.773C7 1.87996 7 2.01997 7 2.3V3H9V2.3ZM5.5 2.4V3.09091L1.71304 3.43518C1.30921 3.47189 1 3.81048 1 4.21598C1 4.64898 1.35102 5 1.78402 5H5.5H10.5H14.216C14.649 5 15 4.64898 15 4.21598C15 3.81048 14.6908 3.47189 14.287 3.43518L10.5 3.09091V2.4C10.5 1.55992 10.5 1.13988 10.3365 0.819014C10.1927 0.536771 9.96323 0.3073 9.68099 0.16349C9.36012 0 8.94008 0 8.1 0H7.9C7.05992 0 6.63988 0 6.31901 0.16349C6.03677 0.3073 5.8073 0.536771 5.66349 0.819014C5.5 1.13988 5.5 1.55992 5.5 2.4ZM3.0969 6.30816C3.04746 6.42715 3.06454 6.58088 3.0987 6.88834L3.76278 12.865C3.84667 13.62 3.88861 13.9975 4.06276 14.2824C4.21617 14.5333 4.43996 14.7336 4.70631 14.8584C5.00868 15 5.38849 15 6.1481 15H9.8519C10.6115 15 10.9913 15 11.2937 14.8584C11.56 14.7336 11.7838 14.5333 11.9372 14.2824C12.1114 13.9975 12.1533 13.62 12.2372 12.865L12.9013 6.88835C12.9355 6.58088 12.9525 6.42715 12.9031 6.30816C12.8597 6.20366 12.7821 6.11697 12.683 6.06228C12.5702 6 12.4155 6 12.1062 6H3.89381C3.58446 6 3.42978 6 3.31698 6.06228C3.21791 6.11697 3.14032 6.20366 3.0969 6.30816Z"
              fill="#818C99"
            />
          </svg>
        </BaseTooltip>
      </span>
    </div>
  );
};

export default DraftOccItem;
