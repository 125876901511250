import { useEffect, useState } from "react";
import BaseModal, { BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import BaseInput from "@/components/base-input";
import { useAppDispatch } from "@/app/store";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";
import { Documents } from "@/types";
import { createDirectory, updateDirectory } from "@/app/store/slices/documents/actions";
import styles from "../styles.module.scss";

interface IEditDirectoryModalProps {
  isOpen: boolean;
  directory?: Documents.Directory | null;
  onClose: () => void;
  onDelete: () => void;
}

const EditDirectoryModal: React.FC<IEditDirectoryModalProps> = ({ isOpen, directory, onClose, onDelete }) => {
  const dispatch = useAppDispatch();
  const [form, setForm] = useState<Documents.EditDirectoryForm>(new Documents.EditDirectoryForm());

  const handleClose = () => {
    onClose();
  };

  const handleDeleteDirectory = () => {
    onDelete();
  };

  const isFormValid = () => {
    const validator = Documents.EditDirectoryFormValidator.isInvalid(form);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        Name: {
          ...form.Name,
          error: validator.Title,
        },
      }));
    }
    return !validator;
  };

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setForm((prevState) => ({
      ...prevState,
      Name: { ...prevState.Name, error: "" },
      error: "",
    }));

    if (!isFormValid()) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      if (directory?.Id) {
        await dispatch(
          updateDirectory({
            ...directory,
            Name: form.Name.value.trim(),
          })
        ).unwrap();
      } else {
        await dispatch(createDirectory(form.Name.value.trim())).unwrap();
      }
      onClose();
    } catch (error: any) {
      const errorData = error.Data;
      console.log(error);
      if (errorData?.Errors) {
        Object.keys(errorData.Errors).forEach((key: any) => {
          const message = Array.isArray(errorData.Errors[key]) ? errorData.Errors[key][0] : errorData.Errors[key];
          switch (key) {
            case "Name": {
              setForm((prevState) => ({
                ...prevState,
                Name: { ...form.Name, error: message },
              }));
              break;
            }

            default: {
              setForm((prevState) => ({ ...prevState, error: message }));
              dispatch(
                showSnackbar({ key: "create-documents-directory-error", body: message, type: SnackbarType.ERROR })
              );
              break;
            }
          }
        });
      } else {
        setForm((prevState) => ({ ...prevState, error: errorData?.Message }));
        dispatch(
          showSnackbar({
            key: "create-documents-directory-error",
            body: errorData?.Message ?? "Неизвестная ошибка",
            type: SnackbarType.ERROR,
          })
        );
      }
    } finally {
      setForm((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    setForm(new Documents.EditDirectoryForm(directory ?? undefined));
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} minWidth="459px" maxWidth="459px">
      <BaseModalHeader title={!!directory ? "Редактировать папку" : "Создание папки"} onClose={handleClose} />

      <BaseModalContent>
        <form onSubmit={handleOnSubmit} style={{ padding: "16px 0" }}>
          <BaseInput
            value={form.Name.value}
            label="Название папки"
            required={form.Name.isRequired}
            placeholder="Например: (Новая папка)"
            errorMessage={form.Name.error}
            maxlength={form.Name.maxlength}
            counter={true}
            onChange={(value) =>
              setForm((prevState) => ({
                ...prevState,
                Name: { ...prevState.Name, value },
              }))
            }
          />
        </form>
      </BaseModalContent>

      <BaseModalActions className={!!directory ? styles.edit_dir_footer : ""}>
        {!!directory ? (
          <BaseButton variant="secondary" appearance="negative" onClick={handleDeleteDirectory}>
            Удалить папку
          </BaseButton>
        ) : (
          <BaseButton variant="secondary" onClick={onClose}>
            Отменить
          </BaseButton>
        )}
        <BaseButton isLoading={form.isLoading} disabled={form.Name.value.length === 0} onClick={handleOnSubmit}>
          {!!directory ? "Сохранить" : "Создать"}
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default EditDirectoryModal;
