import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { RootState } from "@/app/store";
import CreateOccItemLayout from "@/features/CreateOccNew/OccItem/OccLayout";

const SntCreateOCCLayout: React.FC = () => {
  const location = useLocation();

  const occId = useSelector((state: RootState) => state.createOcc.occId);

  const activeStep: number = useMemo(() => {
    switch (location.pathname) {
      case `/snt/create-occ/${occId}/create`:
        return 1;
      case `/snt/create-occ/${occId}/agenda`:
        return 2;
      case `/snt/create-occ/${occId}/preview`:
        return 3;
      case `/snt/create-occ/${occId}/finish`:
        return 4;

      default:
        return 1;
    }
  }, [location, occId]);

  return (
    <div>
      <CreateOccItemLayout
        steps={["Порядок собрания", "Создание повестки", "Просмотр собрания", "Создание завершено"]}
        activeStep={activeStep}
        isBlockerProceed={(blocker) => blocker?.location?.pathname?.startsWith("/snt/create-occ/") ?? false}
        createNewPath={"/snt/create-occ/new/create"}
        draftsListPath={"/snt/create-occ"}
      />
    </div>
  );
};

export default SntCreateOCCLayout;
