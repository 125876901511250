import { BaseTableBody, BaseTableRow, BaseTableHeadCell, BaseTableCell } from "@/components/base-table";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import { moreBtnIcon, gardeningMembers, notGardeningMembers } from "@/consts/icons";
import { SntRegistry } from "@/types";

enum Actions {
  OPEN = "open",
  EDIT = "edit",
  DELETE = "delete",
}

interface ISntRegistryItemRow {
  section: SntRegistry.Section;
  onOpen?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

const SntRegistryItemRow: React.FC<ISntRegistryItemRow> = ({ section, onOpen, onEdit, onDelete }) => {
  function onSelectHandle(value: Actions) {
    switch (value) {
      case Actions.OPEN: {
        if (onOpen) onOpen();
        return;
      }
      case Actions.EDIT: {
        if (onEdit) onEdit();
        return;
      }
      case Actions.DELETE: {
        if (onDelete) onDelete();
        return;
      }
    }
  }

  return (
    <BaseTableBody>
      {section.Owners.map((owner, index) => (
        <BaseTableRow key={`${section.Section.Id}-${owner.Id}`}>
          <BaseTableHeadCell>
            <span className="sf-text-semibold">{index === 0 ? section.Section.Number : ""}</span>
          </BaseTableHeadCell>
          <BaseTableCell>
            <span className="sf-text-semibold">{owner.FullName}</span>
          </BaseTableCell>
          <BaseTableCell>{owner.Phone ?? "-"}</BaseTableCell>
          <BaseTableCell>
            <span className="flex middle" style={{ gap: "8px" }}>
              {owner.IsMember ? (
                <>
                  {gardeningMembers}
                  <span>Да</span>
                </>
              ) : (
                <>
                  {notGardeningMembers}
                  <span>Нет</span>
                </>
              )}
            </span>
          </BaseTableCell>
          <BaseTableCell>
            {index === 0 && (
              <BaseDropdown customToggler={moreBtnIcon} onSelect={(value: Actions) => onSelectHandle(value)}>
                <BaseDropdownMenuItem value={Actions.OPEN} defaultHover>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13 2.5H3C2.72386 2.5 2.5 2.72386 2.5 3V13C2.5 13.2761 2.72386 13.5 3 13.5H13C13.2761 13.5 13.5 13.2761 13.5 13V3C13.5 2.72386 13.2761 2.5 13 2.5ZM3 1C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1H3ZM4 4.75C4 4.33579 4.33579 4 4.75 4H8.25C8.66421 4 9 4.33579 9 4.75C9 5.16421 8.66421 5.5 8.25 5.5H4.75C4.33579 5.5 4 5.16421 4 4.75ZM5 7C4.44772 7 4 7.44772 4 8V11C4 11.5523 4.44772 12 5 12H11C11.5523 12 12 11.5523 12 11V8C12 7.44772 11.5523 7 11 7H5Z"
                      fill="#3A3B3D"
                    />
                  </svg>
                  <span className="sf-text-regular">Подробнее</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={Actions.EDIT} defaultHover={true}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.40499 3.18401L4.69699 7.89201C4.69499 7.89401 4.44699 8.16301 4.34799 8.68401C4.20799 9.42501 4.10599 9.96501 4.00799 10.485C3.97999 10.633 4.02799 10.782 4.11499 10.885C4.21799 10.972 4.36699 11.02 4.51499 10.992C5.03499 10.894 5.57499 10.792 6.31599 10.652C6.83799 10.553 7.10699 10.304 7.10799 10.303L8.31399 9.09801L11.816 5.59501L9.40499 3.18401ZM11.38 1.20901C11.658 0.930006 12.111 0.930006 12.39 1.20901L13.791 2.61001C14.07 2.88901 14.07 3.34201 13.791 3.62001L12.84 4.57101L10.429 2.16001L11.38 1.20901ZM3 13C2.44772 13 2 13.4477 2 14C2 14.5523 2.44772 15 3 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H3Z"
                      fill="#3A3B3D"
                    />
                  </svg>
                  <span className="sf-text-regular">Редактировать</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={Actions.DELETE} defaultHover>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M11.2111 3.98273C12.2599 4.68477 12.7327 6.01428 12.2776 7.23491L6.91131 5.32799C7.36636 4.10735 8.60383 3.38557 9.8695 3.506L10.6327 1.45876C10.7676 1.09706 11.1772 0.910574 11.5476 1.04222C11.9181 1.17387 12.1091 1.5738 11.9743 1.93549L11.2111 3.98273ZM8.9263 14.7747C8.20578 14.6315 7.45192 14.42 6.6647 14.1403C5.80791 13.8358 5.03463 13.4942 4.34485 13.1155L4.80343 12.0165C4.82965 11.9537 4.81613 11.8817 4.76878 11.832C4.70162 11.7616 4.58869 11.7577 4.51656 11.8232L3.59061 12.6649C2.98127 12.2685 2.45107 11.837 2 11.3702C2.97659 8.75058 4.30871 6.87536 5.99637 5.74453L12.7043 8.12818C13.2526 10.0536 13.0384 12.3261 12.0618 14.9457C11.3581 15.026 10.6108 15.0172 9.82001 14.9195V13.341C9.82001 13.2448 9.74011 13.1668 9.64155 13.1668C9.56618 13.1668 9.49894 13.213 9.47354 13.2823L8.9263 14.7747Z"
                      fill="#3A3B3D"
                    />
                  </svg>
                  <span className="sf-text-regular">Удалить</span>
                </BaseDropdownMenuItem>
              </BaseDropdown>
            )}
          </BaseTableCell>
        </BaseTableRow>
      ))}
    </BaseTableBody>
  );
};

export default SntRegistryItemRow;
