import { SntPassportAnalytics } from "@/types";
import styles from "../styles.module.scss";

interface IAnalyticsCountPercentCardProps {
  card: SntPassportAnalytics.CardCount;
}

const AnalyticsCountPercentCard: React.FC<IAnalyticsCountPercentCardProps> = ({ card }) => {
  return (
    <div className={styles["b-card--count"]}>
      <h4 className="headline-h4 sf-text-bold color--text-primary">{card.Title}</h4>
      <p className="headline-h1 sf-bold color--primary">{card.Count}</p>
    </div>
  );
};

export default AnalyticsCountPercentCard;
