import { useEffect, useState } from "react";

import styles from "./checkbox.module.scss";
import { allCheck, checkIcon } from "../../consts/icons";

interface ICheckbox {
  onChange?: (value: boolean) => void;
  all?: boolean;
  checked?: boolean;
  disabled?: boolean;
}

const Checkbox = ({ onChange, all, checked, disabled }: ICheckbox) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    if (onChange) {
      const newCheckedValue = !isChecked;
      setIsChecked(newCheckedValue);
      onChange(newCheckedValue);
    }
  };

  useEffect(() => {
    setIsChecked(checked ?? false);
  }, [checked]);

  return (
    <label className={styles.label}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        className={styles.input}
        disabled={disabled}
      />
      <div className={styles.check}>{all ? allCheck : checkIcon}</div>
    </label>
  );
};

export default Checkbox;
