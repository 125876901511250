import { TextareaAutosize } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTextArea = styled(TextareaAutosize)({
  "&": {
    padding: "12px",
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "SF Pro Text",
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: "-0.154px",
    borderRadius: "10px",
    outline: "none",
    resize: "none",
    // borderColor: "rgba(43, 57, 73, 0.16)",
    minHeight: "40px",
    width: "calc(100% - 24px)",
    boxSizing: "content-box",
  },
  "&::-webkit-input-placeholder": {
    color: "#818C99",
    opacity: "1",
  },
  "&:hover": {
    borderColor: "rgba(0, 0, 0, 0.87)",
  },
  "&:focus": {
    borderWidth: "1px",
    borderColor: "#226dff",
  },
});

export default StyledTextArea;
