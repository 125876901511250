import { createAsyncThunk } from "@reduxjs/toolkit";
import { OCC, OCCV2Author } from "@/types";
import { OCCService } from "@/services/v2";
import {
  toggleOccCreateLoading,
  setOccId,
  setOrder,
  setQuestions,
  toggleAcceptOccLoading,
  setPlannedEvents,
  setUnsavedOccBody,
  setUnsavedQuestions,
  setUnsavedQuestionsToDelete,
  setIsSaved,
  setAuthor,
  setAdministrator,
} from "./";
import {
  getIntramuralExtramuralForm,
  getLokoloLocation,
  selectFormById,
  selectTypeById,
} from "@/app/store/slices/dictionary";
import { RootState } from "@/app/store";
import { addHours } from "date-fns";

export const reset = createAsyncThunk("create-occ/reset", async (payload, { rejectWithValue, dispatch, getState }) => {
  dispatch(setOccId(null));
  dispatch(setOrder(null));
  dispatch(setQuestions([]));
  dispatch(setPlannedEvents(null));
});

export const getOccOrder = createAsyncThunk<OCC.OccOrderItemFull, undefined>(
  "create-occ/get-occ-order",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const state = (getState() as RootState).createOcc;
    if (!state.occId) throw Error("no occ id");

    try {
      if (state.order && state.order.Id === state.occId && "StatusName" in state.order) {
        return state.order;
      }

      const { data } = await OCCService.getOCCOrder(state.occId);
      dispatch(setOrder(data.Data));

      return data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    } finally {
      dispatch(toggleOccCreateLoading(false));
    }
  }
);

export const createOcc = createAsyncThunk<OCC.OccOrderBase["Id"], OCC.CreateOCCBody>(
  "create-occ/create-occ",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const rootState = getState() as RootState;
    const lokoloLocation = getLokoloLocation(rootState);
    const body: OCC.CreateOCCBody = {
      ...payload,
      LocationId: lokoloLocation?.id ?? 1,
    };

    try {
      dispatch(toggleOccCreateLoading(true));
      const { data } = await OCCService.createOCC(body);
      await dispatch(setOccId(data.Data));
      await dispatch(getOccDetails());

      return data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    } finally {
      dispatch(toggleOccCreateLoading(false));
    }
  }
);

export const fetchOccQustions = createAsyncThunk<OCC.Question[], boolean | undefined>(
  "create-occ/fetch-occ-questions",
  async (force = false, { rejectWithValue, dispatch, getState }) => {
    const state = (getState() as RootState).createOcc;
    if (!state.occId) throw Error("no occ id");

    try {
      if (state.order && state.order.Id === state.occId && "StatusName" in state.order && !force) {
        return state.questions;
      }

      const { data } = await OCCService.getOCCQuestions(state.occId);
      dispatch(setQuestions(data.Data));
      return data.Data;
    } catch (err: any) {
      if (err.status === 403 || err.status === 404) {
        return rejectWithValue({ status: err.status });
      }

      return rejectWithValue(err?.response?.data);
    }
  }
);

export const createOccQuestion = createAsyncThunk(
  "create-occ/create-occ-question",
  async (payload: OCC.CreateQuestion, { rejectWithValue, dispatch, getState }) => {
    try {
      const occId = (getState() as RootState).createOcc.occId;
      if (!occId) throw Error("no occ id");

      const body: OCC.CreateQuestionBody = {
        OccId: occId,
        Questions: [{ ...payload }],
      };
      const response = await OCCService.createQuestion(body);
      await dispatch(fetchOccQustions(true));

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const deleteOccQuestion = createAsyncThunk(
  "create-occ/delete-occ-question",
  async (payload: OCC.Question["Id"], { rejectWithValue, dispatch }) => {
    try {
      const response = await OCCService.deleteQuestion(payload);
      await dispatch(fetchOccQustions(true));

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getOccDetails = createAsyncThunk<OCC.OccFullDetails, undefined>(
  "create-occ/get-occ-details",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const occId = (getState() as RootState).createOcc.occId;
    if (!occId) throw Error("no occ id");

    try {
      const { data } = await OCCService.getOCCFullDetails(occId);
      dispatch(setQuestions(data.Data.Questions));
      dispatch(setOrder(data.Data.Order));
      dispatch(setPlannedEvents(data.Data.PlannedEvents));
      dispatch(setAdministrator(data.Data.Administrator as any));
      dispatch(setAuthor(data.Data.Author as any));
      return data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const acceptOccDetailsAndSave = createAsyncThunk<boolean, undefined>(
  "create-occ/accept-occ-details-n-save",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const occId = (getState() as RootState).createOcc.occId;
    if (!occId) throw Error("no occ id");

    dispatch(toggleAcceptOccLoading(true));
    try {
      const { data } = await OCCService.acceptOCC(occId);
      await dispatch(getOccDetails()).unwrap();
      return data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    } finally {
      dispatch(toggleAcceptOccLoading(false));
    }
  }
);

export const saveLocalOccChanges = createAsyncThunk<
  unknown,
  {
    occ?: OCC.CreateOCCBody;
    question?: OCC.CreateQuestion;
    deleteQuestion?: OCC.Question["Id"];
  }
>("create-occ/save-local-occ", async (payload, { rejectWithValue, dispatch, getState }) => {
  try {
    const rootState = getState() as RootState;
    const { order, questions, unsavedBody, plannedEvents } = rootState.createOcc;

    if (payload.occ) {
      const OCCType = selectTypeById(payload.occ.TypeId)(rootState);
      const OCCForm = selectFormById(payload.occ.FormId)(rootState);
      const _order: OCC.OccOrderItemFull = {
        ...(order as OCC.OccOrderItemFull),

        StartTime: payload.occ.StartTimestamp,
        EndTime: payload.occ.EndTimestamp,
        TypeId: payload.occ.TypeId,
        TypeName: OCCType?.name ?? "",
        FormId: payload.occ.FormId,
        FormName: OCCForm?.name ?? "",
        StartDate: new Date(payload.occ.StartTimestamp.parseFromEpochSeconds()).formatByPattern("dd.MM.yyyy"),
        EndDate: new Date(payload.occ.EndTimestamp.parseFromEpochSeconds()).formatByPattern("dd.MM.yyyy"),
        Number: payload.occ.Number,
        ProcedureForFamiliarizationWithMaterialsOfOCC: payload.occ.ProcedureForFamiliarizationWithMaterialsOfOCC,
        ProcedureForMakingWrittenDecisions: payload.occ.ProcedureForMakingWrittenDecisions,
        PublicationDate: new Date(payload.occ.PublicationTimestamp.parseFromEpochSeconds()).formatByPattern(
          "dd.MM.yyyy"
        ),
        PublicationTime: payload.occ.PublicationTimestamp,
      };
      dispatch(setUnsavedOccBody(payload.occ));
      dispatch(setOrder(_order));

      const PublicationDate = new Date(payload.occ.PublicationTimestamp.parseFromEpochSeconds());
      const StartDate = new Date(payload.occ.StartTimestamp.parseFromEpochSeconds());
      const EndDate = new Date(payload.occ.EndTimestamp.parseFromEpochSeconds());
      const CreateProtocolDate = addHours(EndDate, 1);

      dispatch(
        setPlannedEvents({
          Publication: {
            Text: plannedEvents?.Publication.Text ?? "",
            Date: PublicationDate.formatByPattern("d MMMM"),
          },
          Start: {
            Text: plannedEvents?.Start.Text ?? "",
            Date: StartDate.formatByPattern("d MMMM HH:mm"),
          },
          End: {
            Text: plannedEvents?.End.Text ?? "",
            Date: EndDate.formatByPattern("d MMMM HH:mm"),
          },
          CreateProtocol: {
            Text: plannedEvents?.CreateProtocol.Text ?? "",
            Date: CreateProtocolDate.formatByPattern("d MMMM HH:mm"),
          },
        })
      );
    }

    if (payload.question) {
      const questionId = payload.question.Id ?? questions[questions.length - 1]?.Id * -1 ?? -1;
      const questionFormatted = {
        Id: questionId,
        QuorumId: payload.question.QuorumId,
        Title: payload.question.Title,
        Value: payload.question.Value,
        Order: payload.question.Order ?? questions[questions.length - 1]?.Order + 1 ?? 1,
        OnlyHorticultureMembers: !payload.question.AvailableForAllSectionOwner,
        Files: payload.question.Files ?? [],
      };

      dispatch(
        setQuestions(
          questions.find((it) => it.Id === questionId)
            ? questions.map((it) => {
                if (it.Id === questionId) {
                  return {
                    ...questionFormatted,
                  };
                }
                return it;
              })
            : [...questions, questionFormatted]
        )
      );

      dispatch(
        setUnsavedQuestions(
          unsavedBody.questions.find((it) => it.Id === questionId)
            ? unsavedBody.questions.map((it) => {
                if (it?.Id === questionId) {
                  return {
                    ...payload.question,
                    Id: questionId,
                    FileIds: payload.question?.Files?.map((it) => it.Id) ?? [],
                  } as OCC.CreateQuestion;
                }
                return it;
              })
            : [...unsavedBody.questions, { ...payload.question, Id: questionId }]
        )
      );
    }

    if (payload.deleteQuestion) {
      const index = questions.findIndex((it) => it.Id === payload.deleteQuestion);
      if (index) {
        setQuestions([...questions.slice(0, index), ...questions.slice(index + 1)]);
      }

      const unsavedIndex = unsavedBody.questions.findIndex((it) => it.Id === payload.deleteQuestion);
      if (unsavedIndex) {
        setUnsavedQuestions([
          ...unsavedBody.questions.slice(0, unsavedIndex),
          ...unsavedBody.questions.slice(unsavedIndex + 1),
        ]);
      }

      if (payload.deleteQuestion >= 0) {
        dispatch(setUnsavedQuestionsToDelete([...unsavedBody.deleteQuestions, payload.deleteQuestion]));
      }
    }
  } catch (err: any) {
    return rejectWithValue(err?.response?.data);
  }
});

export const updateOcc = createAsyncThunk<OCC.OccOrderBase["Id"], undefined>(
  "create-occ/update-occ",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const rootState = getState() as RootState;
    const order = rootState.createOcc.order;
    const { occ, questions, deleteQuestions } = rootState.createOcc.unsavedBody;
    const lokoloLocation = getLokoloLocation(rootState);
    const intramuralExtramuralForm = getIntramuralExtramuralForm(rootState);

    const body: OCC.UpdateRequestBody = {
      OccId: occ?.OccId ?? order?.Id,
      TypeId: occ?.TypeId ?? order?.TypeId ?? 0,
      FormId: occ?.FormId ?? (order as OCC.OccOrderItemFull)?.FormId,
      Number: occ?.Number ?? order?.Number ?? "",
      ProcedureForMakingWrittenDecisions:
        occ?.ProcedureForMakingWrittenDecisions ?? (order as OCC.OccOrderItemFull)?.ProcedureForMakingWrittenDecisions,
      ProcedureForFamiliarizationWithMaterialsOfOCC:
        occ?.ProcedureForFamiliarizationWithMaterialsOfOCC ??
        (order as OCC.OccOrderItemFull)?.ProcedureForFamiliarizationWithMaterialsOfOCC,
      StartTimestamp: occ?.StartTimestamp ?? order?.StartTime ?? 0,
      EndTimestamp: occ?.EndTimestamp ?? order?.EndTime ?? 0,
      PublicationTimestamp: occ?.PublicationTimestamp ?? (order as OCC.OccOrderItemFull)?.PublicationTime ?? 0,
      LocationId: lokoloLocation?.id ?? 1,
      IntramuralLocation:
        occ?.FormId === intramuralExtramuralForm?.id && occ?.IntramuralLocation ? occ?.IntramuralLocation : "",
      Questions:
        questions.length > 0
          ? questions.map((it) => ({
              ...it,
              Id: it?.Id && it.Id >= 0 ? it.Id : undefined,
              FileIds: it.Files && it.Files?.length > 0 ? it.Files?.map((it) => it.Id) : it.FileIds,
            }))
          : [],
      DeleteQuestions: deleteQuestions.length > 0 ? deleteQuestions : [],
    };

    try {
      dispatch(toggleAcceptOccLoading(true));
      const { data } = await OCCService.updateOCC(body);
      dispatch(setIsSaved(true));

      return data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    } finally {
      dispatch(toggleAcceptOccLoading(false));
    }
  }
);

export const fetchOccAuthor = createAsyncThunk<OCCV2Author.Person | null, undefined>(
  "create-occ/fetch-occ-author",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const state = (getState() as RootState).createOcc;
    if (!state.occId) throw Error("no occ id");

    try {
      if (state.order && state.order.Id === state.occId && state.author) {
        return state.author;
      }

      const { data } = await OCCService.getOCCAuthor(state.occId);
      if (typeof data.Data === "object" && "Name" in data.Data) {
        dispatch(setAuthor(data.Data));
        return data.Data;
      }
      return null;
    } catch (err: any) {
      if (err.status === 403 || err.status === 404) {
        return rejectWithValue({ status: err.status });
      }

      return rejectWithValue(err?.response?.data);
    }
  }
);

export const fetchOccAdministrator = createAsyncThunk<OCCV2Author.Person | null, undefined>(
  "create-occ/fetch-occ-administrator",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const state = (getState() as RootState).createOcc;
    if (!state.occId) throw Error("no occ id");

    try {
      if (state.order && state.order.Id === state.occId && state.administrator) {
        return state.administrator;
      }

      const { data } = await OCCService.getOCCAdministrator(state.occId);
      if (typeof data.Data === "object" && "Name" in data.Data) {
        dispatch(setAdministrator(data.Data));
        return data.Data;
      }
      return null;
    } catch (err: any) {
      if (err.status === 403 || err.status === 404) {
        return rejectWithValue({ status: err.status });
      }

      return rejectWithValue(err?.response?.data);
    }
  }
);

export const setOccAdministrator = createAsyncThunk<unknown, OCCV2Author.SetAdministratorBody>(
  "create-occ/set-occ-administrator",
  async (body, { rejectWithValue, dispatch, getState }) => {
    const state = (getState() as RootState).createOcc;

    try {
      await OCCService.setOCCAdministrator(body);
      if (state?.occId) {
        const { data } = await OCCService.getOCCAdministrator(state.occId);
        dispatch(setAdministrator(data.Data));
        return data.Data;
      }
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const setOccAuthor = createAsyncThunk<unknown, OCCV2Author.SetAuthorBody>(
  "create-occ/set-occ-author",
  async (body, { rejectWithValue, dispatch, getState }) => {
    const state = (getState() as RootState).createOcc;

    try {
      await OCCService.setOCCAuthor(body);
      if (state?.occId) {
        const { data } = await OCCService.getOCCAuthor(state.occId);
        dispatch(setAuthor(data.Data));
        return data.Data;
      }
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);
