import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./styles.module.scss";

interface IBubleItemProps {
  width: React.CSSProperties["width"];
  height: React.CSSProperties["height"];
  backgroundColor: React.CSSProperties["backgroundColor"];
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  className?: string;
}

const BubleItem: React.FC<IBubleItemProps> = ({ width, height, backgroundColor, children, className }) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <CSSTransition in={isOpen} nodeRef={nodeRef} timeout={1000} classNames={{ ...styles }} unmountOnExit>
      <div
        ref={nodeRef}
        className={[className, styles["b-bubble"]].join(" ")}
        style={{ width, height, backgroundColor }}
      >
        {children}
      </div>
    </CSSTransition>
  );
};

export default BubleItem;
