import { forwardRef } from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { Documents } from "@/types";
import { chevronRight16Icon } from "@/consts/icons";
import { CustomCollapsed, Expanded } from "@/components/base-accordion";
import BaseButton from "@/components/base-button";
import BaseFileItem from "@/components/base-file-item";
import styles from "./styles.module.scss";

interface IDirectoryProps {
  directory: Documents.DirectoryWithFiles;
  dragHandleProps: DraggableProvided["dragHandleProps"];
  snapshot: DraggableStateSnapshot;
  onAddDocument: () => void;
  onEditDirectory: () => void;
  onEditDocument: (document: Documents.UsefulDocument) => void;
  onRemoveDocument: (document: Documents.UsefulDocument) => void;
}
export type Ref = HTMLDivElement;

const Directory = forwardRef<Ref, IDirectoryProps & DraggableProvided["draggableProps"]>(
  (
    {
      directory,
      onAddDocument,
      onEditDirectory,
      onEditDocument,
      onRemoveDocument,
      dragHandleProps,
      snapshot,
      ...props
    },
    ref
  ) => {
    const handleEditDirectory = (event: React.SyntheticEvent) => {
      event?.stopPropagation();
      onEditDirectory();
    };

    const handleAddDocument = (event: React.SyntheticEvent) => {
      event?.stopPropagation();
      onAddDocument();
    };

    const handleEditDocument = (document: Documents.UsefulDocument) => {
      onEditDocument(document);
    };

    const handleRemoveDocument = (document: Documents.UsefulDocument) => {
      onRemoveDocument(document);
    };

    const openDocument = (path: Documents.UsefulDocument["Path"]) => {
      window.open(path);
    };

    return (
      <div className={styles.directory_wrapper} ref={ref} {...props}>
        <CustomCollapsed id={directory?.directory?.Id}>
          <div className={styles.directory}>
            <div className="flex middle between">
              <div className={styles.title_container}>
                <span {...dragHandleProps} className="flex middle center">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.5 4.5C6.32843 4.5 7 3.82843 7 3C7 2.17157 6.32843 1.5 5.5 1.5C4.67157 1.5 4 2.17157 4 3C4 3.82843 4.67157 4.5 5.5 4.5ZM10.5 4.5C11.3284 4.5 12 3.82843 12 3C12 2.17157 11.3284 1.5 10.5 1.5C9.67157 1.5 9 2.17157 9 3C9 3.82843 9.67157 4.5 10.5 4.5ZM7 8C7 8.82843 6.32843 9.5 5.5 9.5C4.67157 9.5 4 8.82843 4 8C4 7.17157 4.67157 6.5 5.5 6.5C6.32843 6.5 7 7.17157 7 8ZM10.5 9.5C11.3284 9.5 12 8.82843 12 8C12 7.17157 11.3284 6.5 10.5 6.5C9.67157 6.5 9 7.17157 9 8C9 8.82843 9.67157 9.5 10.5 9.5ZM7 13C7 13.8284 6.32843 14.5 5.5 14.5C4.67157 14.5 4 13.8284 4 13C4 12.1716 4.67157 11.5 5.5 11.5C6.32843 11.5 7 12.1716 7 13ZM10.5 14.5C11.3284 14.5 12 13.8284 12 13C12 12.1716 11.3284 11.5 10.5 11.5C9.67157 11.5 9 12.1716 9 13C9 13.8284 9.67157 14.5 10.5 14.5Z"
                      fill="#99A2AD"
                    />
                  </svg>
                </span>
                <div className={styles.title}>
                  <h3 className="color--text-primary headline-h3 sf-bold">{directory.directory.Name}</h3>
                  <span className="color--primary headline-h3 sf-bold">{directory.directory.FilesCount}</span>
                  <span role="spinbutton" className="flex middle center">
                    {chevronRight16Icon("#818C99")}
                  </span>
                </div>
              </div>
              <span
                className="color--primary sf-text-regular text--primary"
                role="button"
                onClick={(e) => handleEditDirectory(e)}
              >
                Редактировать папку
              </span>
            </div>

            <div className={styles.add_document}>
              <BaseButton
                style={{ padding: "10px 16px" }}
                startIcon={
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11 4.1665C11 3.61422 10.5523 3.1665 9.99999 3.1665C9.44771 3.1665 8.99999 3.61422 8.99999 4.1665V8.99984H4.16666C3.61437 8.99984 3.16666 9.44755 3.16666 9.99984C3.16666 10.5521 3.61437 10.9998 4.16666 10.9998H8.99999V15.8332C8.99999 16.3855 9.44771 16.8332 9.99999 16.8332C10.5523 16.8332 11 16.3855 11 15.8332V10.9998H15.8333C16.3856 10.9998 16.8333 10.5521 16.8333 9.99984C16.8333 9.44755 16.3856 8.99984 15.8333 8.99984H11V4.1665Z"
                      fill="#226DFF"
                    />
                  </svg>
                }
                size="medium"
                variant="secondary"
                onClick={(e) => handleAddDocument(e)}
              >
                Добавить документ
              </BaseButton>
            </div>
          </div>
        </CustomCollapsed>

        <Expanded id={directory?.directory?.Id}>
          {directory.documents.length > 0 && (
            <div className={styles.files_list}>
              {directory.documents.map((document, i) => (
                <BaseFileItem key={i} file={document} onSelect={() => openDocument(document.Path)}>
                  <span
                    className="color--primary--btn-hover"
                    role="button"
                    onClick={() => handleEditDocument(document)}
                  >
                    <span className="sf-text-regular">Редактировать</span>
                  </span>
                  <span
                    className="color--text-error--btn-hover"
                    role="button"
                    onClick={() => handleRemoveDocument(document)}
                  >
                    <span className="sf-text-regular">Удалить</span>
                  </span>
                </BaseFileItem>
              ))}
            </div>
          )}
        </Expanded>
      </div>
    );
  }
);

export default Directory;
