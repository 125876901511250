import { Outlet } from "react-router";
import { paths } from "@/consts/routes";
import PageHeader from "@/components/PageHeader";
import SegmentedСontrols from "@/components/SegmentedСontrols";
import styles from "./styles.module.scss";

function SntPassportLayout() {
  return (
    <>
      <PageHeader title="Паспорт садоводства" />

      <div className={styles.tabs_wrapper}>
        <SegmentedСontrols
          list={[
            {
              text: "Данные правления",
              link: paths.snt().fullPath().passport.main,
            },
            {
              text: "Данные Минсельхоза",
              link: paths.snt().fullPath().passport.minselhoz,
            },
          ]}
        />
      </div>

      <div>
        <Outlet />
      </div>
    </>
  );
}

export default SntPassportLayout;
