import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { SntPassportEnums } from "@/types";
import {
  SntPassportState,
  setAssociationsList,
  setRoadSurfaceTypesList,
  setWaterSupplyTypesList,
  setElectricitySupplyTypesList,
  setInternalInfrastructureWorkTypesList,
  setExternalInfrastructureWorkTypesList,
} from "./";
import { SntPassportService } from "@/services/v2";

export const fetchRoadSurfaceTypesList = createAsyncThunk<SntPassportState["roadSurfaceTypesList"], undefined>(
  "snt-passport/fetch-road-surface-types",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    if (rootState.sntPassport.roadSurfaceTypesList.length > 0) {
      return rootState.sntPassport.roadSurfaceTypesList;
    }

    try {
      const { data } = await SntPassportService.getFieldGuideByField<SntPassportEnums.RoadSurfaceType>(
        "RoadSurfaceType"
      );
      dispatch(setRoadSurfaceTypesList(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    }
  }
);

export const fetchWaterSupplyTypesList = createAsyncThunk<SntPassportState["waterSupplyTypesList"], undefined>(
  "snt-passport/fetch-water-supply-types",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    if (rootState.sntPassport.waterSupplyTypesList.length > 0) {
      return rootState.sntPassport.waterSupplyTypesList;
    }

    try {
      const { data } = await SntPassportService.getFieldGuideByField<SntPassportEnums.WaterSupplyType>(
        "WaterSupplyType"
      );
      dispatch(setWaterSupplyTypesList(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    }
  }
);

export const fetchElectricitySupplyTypesList = createAsyncThunk<
  SntPassportState["electricitySupplyTypesList"],
  undefined
>("snt-passport/fetch-electricity-supply-types", async (payload, { rejectWithValue, getState, dispatch }) => {
  const rootState = getState() as RootState;
  if (rootState.sntPassport.electricitySupplyTypesList.length > 0) {
    return rootState.sntPassport.electricitySupplyTypesList;
  }

  try {
    const { data } = await SntPassportService.getFieldGuideByField<SntPassportEnums.ElectricitySupplyType>(
      "ElectricitySupplyType"
    );
    dispatch(setElectricitySupplyTypesList(data.Data));
    return data.Data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.Data?.Message);
  }
});

export const fetchInternalInfrastructureWorkTypesList = createAsyncThunk<
  SntPassportState["internalInfrastructureWorkTypesList"],
  undefined
>("snt-passport/internal-infrastructure-work-types", async (payload, { rejectWithValue, getState, dispatch }) => {
  const rootState = getState() as RootState;
  if (rootState.sntPassport.internalInfrastructureWorkTypesList.length > 0) {
    return rootState.sntPassport.internalInfrastructureWorkTypesList;
  }

  try {
    const { data } = await SntPassportService.getFieldGuideByField<SntPassportEnums.InternalInfrastructureWorkType>(
      "InternalInfrastructureWorkType"
    );
    dispatch(setInternalInfrastructureWorkTypesList(data.Data));
    return data.Data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.Data?.Message);
  }
});

export const fetchExternalInfrastructureWorkTypesList = createAsyncThunk<
  SntPassportState["externalInfrastructureWorkTypesList"],
  undefined
>("snt-passport/external-infrastructure-work-types", async (payload, { rejectWithValue, getState, dispatch }) => {
  const rootState = getState() as RootState;
  if (rootState.sntPassport.externalInfrastructureWorkTypesList.length > 0) {
    return rootState.sntPassport.externalInfrastructureWorkTypesList;
  }

  try {
    const { data } = await SntPassportService.getFieldGuideByField<SntPassportEnums.ExternalInfrastructureWorkType>(
      "ExternalInfrastructureWorkType"
    );
    dispatch(setExternalInfrastructureWorkTypesList(data.Data));
    return data.Data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.Data?.Message);
  }
});

export const fetchAssociationsList = createAsyncThunk<SntPassportState["associationsList"], undefined>(
  "snt-passport/fetch-associations",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    if (rootState.sntPassport.associationsList.length > 0) {
      return rootState.sntPassport.associationsList;
    }

    try {
      const { data } = await SntPassportService.getAssociationsList();
      dispatch(setAssociationsList(data.data));
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.Data?.Message);
    }
  }
);

export const fetchSntPassportDictionaries = createAsyncThunk<undefined, undefined>(
  "snt-passport/fetch-dictionaries",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      await Promise.all([
        dispatch(fetchRoadSurfaceTypesList()).unwrap(),
        dispatch(fetchWaterSupplyTypesList()).unwrap(),
        dispatch(fetchElectricitySupplyTypesList()).unwrap(),
        dispatch(fetchInternalInfrastructureWorkTypesList()).unwrap(),
        dispatch(fetchExternalInfrastructureWorkTypesList()).unwrap(),
        dispatch(fetchAssociationsList()).unwrap(),
      ]);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);
