import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Access } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import { chevronDownIcon } from "@/consts/icons";
import { isCompanyTypeUK } from "@/app/store/slices/user";
import { toggleAddAccessModalOpen, toggleErrorModalOpen } from "@/app/store/slices/access";
import { setRoleForUser } from "@/app/store/slices/access/actions";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import SelectAddressSubmodal, { SelectAddressDefaultButton } from "@/components/SelectAddressSubmodal";
import PhoneInput from "@/components/base-input/phoneInput";
import BaseButton from "@/components/base-button";
import BaseTooltip from "@/components/base-tooltip";
import styles from "./styles.module.scss";

const SetRoleForUserModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  const isUK = useSelector(isCompanyTypeUK);
  const company = useSelector((state: RootState) => state.user.company);
  const roles = useSelector((state: RootState) => state.access.roles);
  const isOpen = useSelector((state: RootState) => state.access.setRoleDetails.isAddAccessModalOpen);

  const [form, setForm] = useState<Access.SetRoleForm>(new Access.SetRoleForm(roles));
  const [isTooltipShown, setTooltipShown] = useState<boolean>(false);
  const [isSelectAddressSubmodalShown, setSelectAddressSubmodalShown] = useState<boolean>(false);

  const submodalPosition = useMemo(() => {
    const rect = containerRef.current?.getBoundingClientRect();
    return {
      left: rect ? `${rect.left + rect.width / 2}px` : 0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  function handleTooltipClose() {
    setTooltipShown(false);
  }

  function handleTooltipOpen(event: React.SyntheticEvent) {
    if (roles.length === 1) {
      event.stopPropagation();
      setTooltipShown(true);
    }
  }

  function isFormValid() {
    const validator = Access.SetRoleFormValidator.isInvalid(form, isUK);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        Phone: {
          ...form.Phone,
          error: validator.Phone,
        },
        HouseIdsError: validator.HouseIds,
      }));
    }
    return !validator;
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });

    setForm((prevState) => ({
      ...prevState,
      error: "",
      HouseIdsError: "",
    }));

    if (!isFormValid() || !form.Role?.Key) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      await dispatch(
        setRoleForUser({
          Phone: form.Phone.value,
          Role: form.Role?.Key,
          HouseIds: isUK ? form.HouseIds : undefined,
        })
      ).unwrap();
    } catch (error: any) {
      const errorData = error.Data;
      if (errorData?.Errors) {
        Object.keys(errorData.Errors).forEach((key: any) => {
          const message = Array.isArray(errorData.Errors[key]) ? errorData.Errors[key][0] : errorData.Errors[key];
          switch (key) {
            case "HouseIds":
              setForm((prevState) => ({
                ...prevState,
                HouseIdsError: message,
              }));
              break;

            default:
              setForm((prevState) => ({
                ...prevState,
                Phone: {
                  ...form.Phone,
                  error: message,
                },
              }));
          }
        });
      } else if (errorData?.ErrorCode === "other") {
        setForm((prevState) => ({
          ...prevState,
          Phone: {
            ...form.Phone,
            error: errorData?.Message,
          },
        }));
      } else {
        dispatch(toggleErrorModalOpen(true));
        close();
      }
    } finally {
      setForm((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }

  function close() {
    dispatch(toggleAddAccessModalOpen(false));
  }

  function clearPhoneField() {
    setForm((prevState) => ({
      ...prevState,
      Phone: { ...form.Phone, value: "+7" },
    }));
  }

  useEffect(() => {
    if (isOpen) {
      setForm(new Access.SetRoleForm(roles, company?.Addresses));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} minWidth="520px" maxWidth="520px">
      <div ref={containerRef}>
        <BaseModalHeader title="Предоставить доступ" onClose={close} />

        <BaseModalContent>
          <form className={styles["b-set-role-form"]} onSubmit={handleSubmit}>
            <div>
              <p className="sf-text-semibold color--text-primary text--primary">
                Номер телефона{" "}
                <span className="sf-text-regular color--text-success">(проверьте правильность ввода)</span>
              </p>
              <PhoneInput
                value={form.Phone.value}
                required={form.Phone.isRequired}
                mask="+7 999 999 99 99"
                maskPlaceholder={null}
                errorMessage={form.Phone.error}
                onChange={(value) =>
                  setForm((prevState) => ({
                    ...prevState,
                    Phone: { ...form.Phone, value: value.length > 0 ? value : "+7" },
                  }))
                }
                endAdornment={
                  form.Phone.value !== "+7" && (
                    <svg
                      className={styles["b-set-role-form__phone-clear"]}
                      onClick={clearPhoneField}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.988 0C12.396 0 15.976 3.58 15.976 7.988C15.976 12.396 12.396 15.976 7.988 15.976C3.58 15.976 0 12.396 0 7.988C0 3.58 3.58 0 7.988 0ZM7.988 6.565L6.173 4.75C5.781 4.358 5.143 4.358 4.75 4.75C4.358 5.143 4.358 5.781 4.75 6.173L6.565 7.988L4.757 9.796C4.365 10.188 4.365 10.826 4.757 11.219C5.15 11.611 5.788 11.611 6.18 11.219L7.988 9.411L9.803 11.226C10.195 11.618 10.833 11.618 11.226 11.226C11.618 10.833 11.618 10.195 11.226 9.803L9.411 7.988L11.219 6.18C11.611 5.788 11.611 5.15 11.219 4.757C10.826 4.365 10.188 4.365 9.796 4.757L7.988 6.565Z"
                        fill="#818C99"
                      />
                    </svg>
                  )
                }
              />
            </div>

            <div>
              <p className="sf-text-semibold color--text-primary text--primary">Выбранная роль</p>
              <BaseDropdown
                value={form.Role?.Key ?? null}
                display={form.Role?.Value}
                variant="formfield"
                isSelectable={true}
                onSelect={(value) => {
                  const Role = roles.find((it) => it.Key === value);
                  if (Role) {
                    setForm((prevState) => ({
                      ...prevState,
                      Role,
                    }));
                  }
                }}
                customToggler={
                  roles.length === 1 ? (
                    <div className={styles["b-set-role-form__role-field"]} onClick={handleTooltipOpen}>
                      <span className={styles["b-set-role-form__role-field_value"]}>
                        <span className="sf-text-regular color--text-primary">{form.Role?.Value}</span>
                      </span>

                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <span className={styles["b-set-role-form__role-field_chevron"]}>
                          <BaseTooltip title="Других ролей пока нет в системе" open={isTooltipShown}>
                            {chevronDownIcon("#B8C1CC")}
                          </BaseTooltip>
                        </span>
                      </ClickAwayListener>
                    </div>
                  ) : undefined
                }
              >
                {roles.map((role) => (
                  <BaseDropdownMenuItem key={role.Key} value={role.Key}>
                    <span className="sf-text-medium">{role?.Value}</span>
                  </BaseDropdownMenuItem>
                ))}
              </BaseDropdown>
            </div>

            {isUK && (
              <>
                <SelectAddressDefaultButton
                  errorMessage={form.HouseIdsError}
                  onClick={() => setSelectAddressSubmodalShown(true)}
                >
                  Доступ
                  {form.HouseIds.length === company?.Addresses?.length
                    ? " ко всем адресам"
                    : form.HouseIds.length === 1
                    ? ` к ${form.HouseIds.length} адресу`
                    : ` к ${form.HouseIds.length} адресам`}
                </SelectAddressDefaultButton>

                {isSelectAddressSubmodalShown && (
                  <SelectAddressSubmodal
                    position={submodalPosition}
                    isOpen={isSelectAddressSubmodalShown}
                    selected={form.HouseIds}
                    onClose={() => setSelectAddressSubmodalShown(false)}
                    setSelected={(ids) =>
                      setForm((prevState) => ({
                        ...prevState,
                        HouseIds: ids,
                      }))
                    }
                  />
                )}
              </>
            )}
          </form>
        </BaseModalContent>

        <BaseModalActions>
          <BaseButton variant="secondary" onClick={close}>
            Отменить
          </BaseButton>
          <BaseButton isLoading={form.isLoading} onClick={handleSubmit}>
            Далее
          </BaseButton>
        </BaseModalActions>
      </div>
    </BaseModal>
  );
};

export default SetRoleForUserModal;
