import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Access } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import { chevronDownIcon } from "@/consts/icons";
import { isCompanyTypeUK } from "@/app/store/slices/user";
import { updateUserRoleHouses } from "@/app/store/slices/access/actions";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import SelectAddressSubmodal, { SelectAddressDefaultButton } from "@/components/SelectAddressSubmodal";
import PhoneInput from "@/components/base-input/phoneInput";
import BaseButton from "@/components/base-button";
import BaseTooltip from "@/components/base-tooltip";
import styles from "../styles.module.scss";

interface IEditUserRoleModalProps {
  user: Access.User;
  isOpen: boolean;
  onClose: () => void;
}

const EditUserRoleModal: React.FC<IEditUserRoleModalProps> = ({ user, isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  const isUK = useSelector(isCompanyTypeUK);
  const company = useSelector((state: RootState) => state.user.company);
  const roles = useSelector((state: RootState) => state.access.roles);

  const [form, setForm] = useState<Access.EditRoleForm>(new Access.EditRoleForm(user));
  const [isTooltipShown, setTooltipShown] = useState<boolean>(false);
  const [isSelectAddressSubmodalShown, setSelectAddressSubmodalShown] = useState<boolean>(false);

  const submodalPosition = useMemo(() => {
    const rect = containerRef.current?.getBoundingClientRect();
    return {
      left: rect ? `${rect.left + rect.width / 2}px` : 0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  function handleTooltipClose() {
    setTooltipShown(false);
  }

  function handleTooltipOpen(event: React.SyntheticEvent) {
    if (roles.length === 1) {
      event.stopPropagation();
      setTooltipShown(true);
    }
  }

  function isFormValid() {
    const validator = Access.EditRoleFormValidator.isInvalid(form, isUK);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        HouseIdsError: validator.HouseIds,
      }));
    }
    return !validator;
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });

    setForm((prevState) => ({
      ...prevState,
      error: "",
      HouseIdsError: "",
    }));

    if (!isFormValid()) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      await dispatch(
        updateUserRoleHouses({
          UserId: user.User.Id,
          HouseIds: form.HouseIds,
        })
      ).unwrap();
      onClose();
    } catch (error: any) {
      const errorData = error.Data;
      console.log(errorData);
    } finally {
      setForm((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }

  useEffect(() => {
    if (isOpen) {
      setForm(new Access.EditRoleForm(user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} minWidth="520px" maxWidth="520px">
      <div ref={containerRef}>
        <BaseModalHeader title="Редактировать" onClose={onClose} />

        <BaseModalContent>
          <form className={styles["b-set-role-form"]} onSubmit={handleSubmit}>
            <div>
              <PhoneInput
                label="Номер телефона"
                value={user.User.Phone}
                locked={true}
                mask="+7 999 999 99 99"
                maskPlaceholder={null}
                onChange={() => {}}
              />
            </div>

            <div>
              <p className="sf-text-semibold color--text-primary text--primary">Выбранная роль</p>
              <BaseDropdown
                variant="formfield"
                isSelectable={true}
                onSelect={(value) => {}}
                customToggler={
                  <div className={styles["b-set-role-form__role-field"]} onClick={handleTooltipOpen}>
                    <span className={styles["b-set-role-form__role-field_value"]}>
                      <span className="sf-text-regular color--text-primary">{user.Role.Value}</span>
                    </span>

                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <span className={styles["b-set-role-form__role-field_chevron"]}>
                        <BaseTooltip title="Других ролей пока нет в системе" open={isTooltipShown}>
                          {chevronDownIcon("#B8C1CC")}
                        </BaseTooltip>
                      </span>
                    </ClickAwayListener>
                  </div>
                }
              >
                {roles.map((role) => (
                  <BaseDropdownMenuItem key={role.Key} value={role.Key}>
                    <span className="sf-text-medium">{role?.Value}</span>
                  </BaseDropdownMenuItem>
                ))}
              </BaseDropdown>
            </div>

            {isUK && (
              <>
                <SelectAddressDefaultButton
                  errorMessage={form.HouseIdsError}
                  onClick={() => setSelectAddressSubmodalShown(true)}
                >
                  Доступ
                  {form.HouseIds.length === company?.Addresses?.length
                    ? " ко всем адресам"
                    : form.HouseIds.length === 1
                    ? ` к ${form.HouseIds.length} адресу`
                    : ` к ${form.HouseIds.length} адресам`}
                </SelectAddressDefaultButton>

                {isSelectAddressSubmodalShown && (
                  <SelectAddressSubmodal
                    position={submodalPosition}
                    isOpen={isSelectAddressSubmodalShown}
                    selected={form.HouseIds}
                    onClose={() => setSelectAddressSubmodalShown(false)}
                    setSelected={(ids) =>
                      setForm((prevState) => ({
                        ...prevState,
                        HouseIds: ids,
                      }))
                    }
                  />
                )}
              </>
            )}
          </form>
        </BaseModalContent>

        <BaseModalActions>
          <BaseButton isLoading={form.isLoading} onClick={handleSubmit}>
            Сохранить
          </BaseButton>
        </BaseModalActions>
      </div>
    </BaseModal>
  );
};

export default EditUserRoleModal;
