import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { paths } from "@/consts/routes";
import { privacyIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleSetCadastralNumberModal } from "@/app/store/slices/uk/registry";
import BaseModal, { BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";

const SetCadastralNumberModal: React.FC = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const { setCadastralNumberModal } = useSelector((state: RootState) => state.ukRegistry);

  function onClose() {
    dispatch(toggleSetCadastralNumberModal(false));
  }

  function navigateToSettings() {
    navigation(paths.uk().fullPath().cadastralNumber);
  }

  return (
    <BaseModal isOpen={setCadastralNumberModal.isOpen} minWidth="520px">
      <BaseModalHeader
        variant="info-iconed"
        iconBgColor="#FFF5CE"
        icon={privacyIcon}
        title="Укажите кадастровый номер дома"
        onClose={onClose}
      />

      <BaseModalContent>
        <p className="sf-text-regular text--primary color--text-primary">
          Для отправки запроса в Росреестр, необходимо указать кадастровый номер дома в настройках системы.
        </p>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton onClick={navigateToSettings}>Перейти в настройки</BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default SetCadastralNumberModal;
