import { arrowRightWhiteIcon } from "@/consts/icons";
import BaseButton from "@/components/base-button";
import styles from "./styles.module.scss";

type ISntPassportBottomBarProps = {
  publicationText: string;
  publishBtnText?: string;
  isSaveLoading?: boolean;
  isPublishLoading?: boolean;
  onSave?: () => void;
  onPublish?: () => void;
};

const SntPassportBottomBar: React.FC<ISntPassportBottomBarProps> = ({
  publicationText,
  publishBtnText = "Опубликовать",
  isSaveLoading = false,
  isPublishLoading = false,
  onSave,
  onPublish,
}) => {
  function handleOnSave() {
    if (onSave) {
      onSave();
    }
  }
  function handleOnPublish() {
    if (onPublish) {
      onPublish();
    }
  }

  return (
    <div className={styles["b-snt-passport-bottom-bar"]}>
      <div>
        <p className="headline-h4 sf-text-bold color--text-secondary">{publicationText}</p>
      </div>
      <div>
        <BaseButton variant="secondary" isLoading={isSaveLoading} onClick={handleOnSave}>
          Просто сохранить
        </BaseButton>
        <BaseButton
          variant="primary"
          endIcon={arrowRightWhiteIcon}
          isLoading={isPublishLoading}
          onClick={handleOnPublish}
        >
          {publishBtnText}
        </BaseButton>
      </div>
    </div>
  );
};

export default SntPassportBottomBar;
