import { SntPassport, SntPassportMap } from "@/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface SntsMapState {
  markers: SntPassportMap.SntItem[];
  state: SntPassportMap.MapState;
  geopoint: SntPassportMap.Geopoint | null;
  isSelectSntModalOpen: boolean;
  isSelectSntLoading: boolean;

  selected: {
    sntId: SntPassportMap.SntItem["Id"] | null;
    snt: SntPassport.InstitutionSntItem | null;
    isLoading: boolean;
    isDeleteOpen: boolean;
    isDeleteLoading: boolean;
    isUpdateGeoLoading: boolean;
  };
}

const sntsMapSlice = createSlice({
  name: "snts-map",

  initialState: {
    markers: [],
    state: SntPassportMap.MapState.Default,
    geopoint: null,
    isSelectSntModalOpen: false,
    isSelectSntLoading: false,

    selected: {
      sntId: null,
      snt: null,
      isLoading: false,
      isDeleteOpen: false,
      isDeleteLoading: false,
      isUpdateGeoLoading: false,
    },
  } as SntsMapState,

  reducers: {
    setMarkers: (state, action: PayloadAction<SntsMapState["markers"]>) => {
      state.markers = action.payload;
    },

    setState: (state, action: PayloadAction<SntsMapState["state"]>) => {
      state.state = action.payload;
      state.geopoint = null;
    },

    setGeopoint: (state, action: PayloadAction<SntsMapState["geopoint"]>) => {
      state.geopoint = action.payload;
    },

    setSelectedSntId: (state, action: PayloadAction<SntsMapState["selected"]["sntId"]>) => {
      state.selected.sntId = action.payload;
    },

    setSelectedSnt: (state, action: PayloadAction<SntsMapState["selected"]["snt"]>) => {
      state.selected.snt = action.payload;
    },

    setSelectedLoading: (state, action: PayloadAction<SntsMapState["selected"]["isLoading"]>) => {
      state.selected.isLoading = action.payload;
    },

    removeSntMarker: (state, action: PayloadAction<SntPassportMap.SntItem["Id"]>) => {
      if (state.selected.snt?.Geopoint) {
        state.selected.snt.Geopoint = null;
      }

      const index = state.markers.findIndex((it) => it.Id === action.payload);
      if (index >= 0) {
        state.markers.splice(index, 1);
      }
    },

    updateSntMarker: (state, action: PayloadAction<SntPassportMap.SntItem>) => {
      if (state.selected.snt?.Id === action.payload.Id) {
        state.selected.snt.Geopoint = action.payload.Geopoint;
      }

      const index = state.markers.findIndex((it) => it.Id === action.payload.Id);
      if (index >= 0) {
        state.markers[index].Geopoint = action.payload.Geopoint;
      }
    },

    addSntMarker: (state, action: PayloadAction<SntPassportMap.SntItem>) => {
      const index = state.markers.findIndex((it) => it.Id === action.payload.Id);
      if (index >= 0) {
        state.markers[index].Geopoint = action.payload.Geopoint;
      } else {
        state.markers.push(action.payload);
      }

      if (state.selected.snt?.Id === action.payload.Id) {
        state.selected.snt.Geopoint = action.payload.Geopoint;
      }
    },

    setDeleteOpen: (state, action: PayloadAction<SntsMapState["selected"]["isDeleteOpen"]>) => {
      state.selected.isDeleteOpen = action.payload;
    },

    setDeleteLoading: (state, action: PayloadAction<SntsMapState["selected"]["isDeleteLoading"]>) => {
      state.selected.isDeleteLoading = action.payload;
    },

    setUpdateGeoLoading: (state, action: PayloadAction<SntsMapState["selected"]["isUpdateGeoLoading"]>) => {
      state.selected.isUpdateGeoLoading = action.payload;
    },

    setSelectSntModalOpen: (state, action: PayloadAction<SntsMapState["isSelectSntModalOpen"]>) => {
      state.isSelectSntModalOpen = action.payload;
    },

    setSelectSntLoading: (state, action: PayloadAction<SntsMapState["isSelectSntLoading"]>) => {
      state.isSelectSntLoading = action.payload;
    },
  },
});

export default sntsMapSlice.reducer;
export * from "./actions";
export const {
  setMarkers,
  setGeopoint,
  setState,
  setSelectedSnt,
  setSelectedSntId,
  setSelectedLoading,
  removeSntMarker,
  updateSntMarker,
  addSntMarker,
  setDeleteOpen,
  setDeleteLoading,
  setUpdateGeoLoading,
  setSelectSntModalOpen,
  setSelectSntLoading,
} = sntsMapSlice.actions;
