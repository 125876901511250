import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IBaseModalContentProps {
  children: React.ReactNode;
  sx?: React.CSSProperties;
  className?: string;
}

const BaseModalContent = ({ children, sx = { padding: "0 24px" }, className }: IBaseModalContentProps) => {
  return (
    <div className={cx(className, { content: true })} style={sx}>
      {children}
    </div>
  );
};

export default BaseModalContent;
