import { useMemo } from "react";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

interface IStepperButtonProps {
  children: React.ReactNode;
  step: number;
  activeStep: number;
}

const StepperButton: React.FC<IStepperButtonProps> = ({ children, step, activeStep }) => {
  const isPassed: boolean = useMemo(() => step < activeStep || (step === 4 && step === activeStep), [step, activeStep]);
  const isActive: boolean = useMemo(() => step === activeStep, [step, activeStep]);

  return (
    <div className={styles["b-stepper-button"]}>
      <div className={cx("flex middle", { "b-stepper-button--passed": isPassed })}>
        <span className="flex middle center">
          {isPassed ? (
            <svg width="27" height="26" viewBox="0 0 27 26" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.25 13C22.25 17.9706 18.2206 22 13.25 22C8.27944 22 4.25 17.9706 4.25 13C4.25 8.02944 8.27944 4 13.25 4C18.2206 4 22.25 8.02944 22.25 13ZM17.0268 10.2344C17.3243 10.5468 17.3243 11.0533 17.0268 11.3657L12.8363 15.7657C12.5388 16.0781 12.0563 16.0781 11.7588 15.7657L9.47308 13.3657C9.17554 13.0533 9.17554 12.5468 9.47308 12.2344C9.77063 11.9219 10.253 11.9219 10.5506 12.2344L12.2975 14.0687L15.9493 10.2344C16.2468 9.92193 16.7292 9.92193 17.0268 10.2344Z"
                fill="#0BB261"
              />
              <circle cx="13.25" cy="13" r="12" stroke="#0BB261" strokeWidth="2" />
            </svg>
          ) : isActive ? (
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 22C17.9706 22 22 17.9706 22 13C22 8.02944 17.9706 4 13 4C8.02944 4 4 8.02944 4 13C4 17.9706 8.02944 22 13 22ZM13 16.6C14.9882 16.6 16.6 14.9882 16.6 13C16.6 11.0118 14.9882 9.4 13 9.4C11.0118 9.4 9.4 11.0118 9.4 13C9.4 14.9882 11.0118 16.6 13 16.6Z"
                fill="#0BB261"
              />
              <circle cx="13" cy="13" r="12" stroke="#0BB261" strokeWidth="2" />
            </svg>
          ) : (
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.25 19C15.2206 19 19.25 14.9706 19.25 10C19.25 5.02944 15.2206 1 10.25 1C5.27944 1 1.25 5.02944 1.25 10C1.25 14.9706 5.27944 19 10.25 19ZM10.25 13.6C12.2382 13.6 13.85 11.9882 13.85 10C13.85 8.01178 12.2382 6.4 10.25 6.4C8.26178 6.4 6.65 8.01178 6.65 10C6.65 11.9882 8.26178 13.6 10.25 13.6Z"
                fill="#A6B4C3"
              />
            </svg>
          )}
        </span>
      </div>
      <span className="text--primary sf-text-medium">{children}</span>
    </div>
  );
};

export default StepperButton;
