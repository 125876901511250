import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { paths } from "@/consts/routes";
import CreateOccItemCreateAgendaStep from "@/features/CreateOccNew/OccItem/Steps/Finish";

const UKCreateOCCFinishStep: React.FC = () => {
  const occId = useSelector((state: RootState) => state.createOcc.occId);

  return (
    <div>
      <CreateOccItemCreateAgendaStep
        adminTitle="Что дальше делать администратору собрания?"
        adminList={[
          "Важно понимать, что с администратора собрания не снимается обязанность информировать собственников о предстоящем собрании иными доступными способами, так как не все собственники зарегистрированы в приложении Локоло",
          "Используйте сформированные документы в разделе “Мои собрания” вкладки “Документы” для проведения всех необходимых процедур проведения общих собраний собственников.",
          "Вносите Решения собственников, принятые на бумажном носителе в систему в разделе “Мои собрания” вкладки “Внести решение”.",
          "Следите за наличием кворума в разделе “Мои собрания” вкладки “Результаты”",
        ]}
        prevLink={paths
          .uk()
          .fullPath()
          .createOCC.item.preview(occId ?? "new")}
        createNewPath={paths.uk().fullPath().createOCC.item.create("new")}
      />
    </div>
  );
};

export default UKCreateOCCFinishStep;
