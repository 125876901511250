import { House } from "./house";
import { Profile } from "./profile";
import { Form, FormFiled, FormValidator } from "./ui";
import errorMessages from "../helpers/errorMessages.json";

export namespace UsefulContacts {
  export type CreateContactBody = {
    Name: string;
    PhoneNum: string;
    Email: string;
    Address: string;
    AllHouses: boolean;
    Houses: Array<House.Item["Id"]>;
    Geopoint: Geopoint | null;
  };

  export type UpdateContactBody = CreateContactBody & {
    Id: number;
  };

  export type Item = UpdateContactBody & {
    Houses: Array<House.Item>;
  };

  export type UsefulContactId = Item["Id"] | null;

  export type UsefulContactUnitedType = Item | Profile.CompanyData;

  export type Geopoint = {
    Lat?: number | string;
    Lon?: number | string;
  };

  export type UpdateMyContactsBody = {
    Phone: string;
    Email: string;
    Site?: string | null;
    Address: string;
  };

  export class EditContactForm extends Form {
    Name: FormFiled = new FormFiled();
    PhoneNum: FormFiled = new FormFiled();
    Email: FormFiled = new FormFiled();
    Address: FormFiled = new FormFiled();
    Houses: Array<House.Item["Id"]> = [];
    HousesError: string = "";
    Geopoint: Geopoint | null = null;
    Site?: FormFiled = new FormFiled();

    constructor({
      contacts,
      company,
      housesIds,
    }: {
      contacts?: Item;
      company?: Profile.CompanyData;
      housesIds?: Array<House.Item["Id"]>;
    }) {
      super();
      this.Name = new FormFiled(contacts?.Name ?? company?.Name ?? "", true);
      this.PhoneNum = new FormFiled(contacts?.PhoneNum ?? company?.Phone ?? "", true);
      this.Email = new FormFiled(contacts?.Email ?? company?.Email ?? "", !!company);
      this.Address = new FormFiled(contacts?.Address ?? company?.Address ?? "", !!company);
      this.Geopoint = contacts?.Geopoint ?? null;
      this.Houses =
        (contacts?.Houses as Array<House.Item>)?.map((it) => it.Id) ??
        company?.Addresses?.map((it) => it.Id) ??
        housesIds;

      if (!!company) {
        this.Site = new FormFiled(company?.Site ?? "");
      } else {
        this.Site = undefined;
      }
    }
  }

  export class EditContactFormValidator {
    private static instance: EditContactFormValidator;

    public static get(): EditContactFormValidator {
      if (!EditContactFormValidator.instance) {
        EditContactFormValidator.instance = new EditContactFormValidator();
      }
      return EditContactFormValidator.instance;
    }

    validateName(name: EditContactForm["Name"]) {
      return FormValidator.getFieldErrorMessage(name.value.trim(), name.isRequired);
    }

    // validateAddress(address: EditContactForm["Address"], geopoint: EditContactForm["Geopoint"], isUK: boolean = false) {
    //   const addressErrorMessage = FormValidator.getFieldErrorMessage(address.value.trim(), address.isRequired);

    //   if (!addressErrorMessage && address.value.trim().length > 0 && !isUK && !geopoint) {
    //     return errorMessages.address.invalid;
    //   }

    //   return addressErrorMessage;
    // }

    validateHouses(houses: EditContactForm["Houses"]) {
      if (houses.length === 0) return errorMessages.houses.empty;

      return "";
    }

    isInvalid(form: EditContactForm, isUK: boolean = false) {
      const errors = {
        Name: this.validateName(form.Name),
        Email: FormValidator.getEmailErrorMessage(form.Email.value.trim(), form.Email.isRequired),
        PhoneNum: FormValidator.getPhoneErrorMessage(form.PhoneNum.value.trim(), form.PhoneNum.isRequired),
        Address: FormValidator.getFieldErrorMessage(form.Address.value.trim(), form.Address.isRequired),
        Houses: this.validateHouses(form.Houses),
        Site: form.Site
          ? FormValidator.getWebsiteErrorMessage(form.Site.value.trim(), form.Site.isRequired)
          : undefined,
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }
}
