import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { OCC, Profile } from "@/types";
import BaseTooltip from "@/components/base-tooltip";
import QuestionDecisionRadio from "./decision-checkbox";
import styles from "./styles.module.scss";

interface IQuestionDecisionProps {
  question: OCC.Question;
  decision: OCC.OwnerDecision["Result"];
  setDecision: (value: OCC.OwnerDecision["Result"]) => void;
  owner: OCC.RegistryOwner | null;
}

const QuestionDecision: React.FC<IQuestionDecisionProps> = ({ question, decision, setDecision, owner }) => {
  const company = useSelector((state: RootState) => state.user.company);

  const isDisabled: boolean = useMemo(() => {
    return company?.Type === Profile.CompanyType.SNT && question.OnlyHorticultureMembers && !owner?.IsMember;
  }, [company?.Type, question, owner]);

  return (
    <div className={styles.question_decision}>
      <div>
        <span className={styles.number}>
          <span className="headline-h5 sf-text-bold color--text-primary">{question.Order}</span>
        </span>
      </div>

      <div className={styles.question_decision_content}>
        <div className={styles.question_decision_text}>
          <p className="sf-text-semibold text--primary color--text-primary">{question.Title}</p>
          <p className="sf-text-regular text--primary color--text-primary">{question.Value}</p>
        </div>

        <div className="flex">
          <BaseTooltip
            title={isDisabled ? "Голосование по данному вопросу доступно только для членов садоводства" : ""}
            placement="right"
          >
            <div className={styles.question_decision_buttons}>
              <QuestionDecisionRadio
                value={OCC.Decision.Agree}
                selected={decision}
                onChange={setDecision}
                disabled={isDisabled}
              />
              <QuestionDecisionRadio
                value={OCC.Decision.Disagree}
                selected={decision}
                onChange={setDecision}
                disabled={isDisabled}
              />
              <QuestionDecisionRadio
                value={OCC.Decision.Abstain}
                selected={decision}
                onChange={setDecision}
                disabled={isDisabled}
              />
            </div>
          </BaseTooltip>
        </div>
      </div>
    </div>
  );
};

export default QuestionDecision;
