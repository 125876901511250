import { createContext } from "react";

type AccordionContextValue = {
  opened: string | number;
  handleOpen: (value: AccordionContextValue["opened"]) => void;
};

const AccordionContext = createContext<AccordionContextValue | null>(null);

export default AccordionContext;
