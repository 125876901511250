import { useMemo } from "react";
import { useSelector } from "react-redux";
import { HouseUsers } from "@/types/house-users";
import { RootState } from "@/app/store";

type IUserStatusProps = {
  status: HouseUsers.StatusKey;
  name?: string;
};

const UserStatus: React.FC<IUserStatusProps> = ({ status, name = "" }) => {
  const statuses = useSelector((state: RootState) => state.houseUsers.statuses);

  const formatted: { color: string; name: string } = useMemo(() => {
    const text = statuses.find((it) => it.key === status)?.name ?? status;
    switch (status) {
      case HouseUsers.StatusKey.ConfirmedOwnership:
        return { color: "var(--text-success)", name: text };

      case HouseUsers.StatusKey.UnconfirmedOwnership:
        return { color: "var(--text-error)", name: text };

      case HouseUsers.StatusKey.ConfirmingOwnership:
        return { color: "#99A0AD", name: text };

      case HouseUsers.StatusKey.Resident:
        return { color: "#FF8000", name: text };

      case HouseUsers.StatusKey.Tenant:
        return { color: "#00A1F0", name: text };

      case HouseUsers.StatusKey.NotOwner:
        return { color: "var(--text-error)", name: text };

      default:
        return { color: "var(--text-primary)", name: text };
    }
  }, [status, statuses]);

  return (
    <p className="text--primary sf-text-regular" style={{ color: formatted.color }}>
      {name ? name : formatted.name}
    </p>
  );
};

export default UserStatus;
