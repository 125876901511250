import { SntPassportAnalytics } from "@/types";
import { isNullOrUndefined } from "@/helpers/isNullOrUndefined";
import styles from "../styles.module.scss";

interface IAnalyticsWorkScopeArraySumCardCardProps {
  card: SntPassportAnalytics.CardWorkScopeArraySum;
}

const AnalyticsWorkScopeArraySumCardCard: React.FC<IAnalyticsWorkScopeArraySumCardCardProps> = ({ card }) => {
  return (
    <div>
      <div className={styles["b-card--work-scope"]}>
        {card.WorkScopes.map((it) => (
          <div className={styles["b-card--work-scope__item"]} key={it.Title}>
            <p className="text--primary sf-text-medium color--text-primary">{it.Title}</p>
            <span className="headline-h2 sf-bold color--text-primary">{it.Value}</span>
          </div>
        ))}
        {!card.IsSummary && <div className={styles["b-card--work-scope__stick"]}></div>}
        <div className={styles["b-card--work-scope__item"]}>
          <p className="text--primary sf-text-medium color--text-primary">{card.Title}</p>
          <span className="headline-h2 sf-bold color--text-success">
            {card.Sum?.formatCompact()}
            {!isNullOrUndefined(card.Sum) && " ₽"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsWorkScopeArraySumCardCard;
