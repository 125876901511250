import { SntPassportAnalytics } from "@/types";
import Chart from "@/components/Chart";
import styles from "../styles.module.scss";

interface IAnalyticsRingGraphWithTotalCountCardProps {
  card: SntPassportAnalytics.CardRingGraphWithTotalCount;
}

const AnalyticsRingGraphWithTotalCountCard: React.FC<IAnalyticsRingGraphWithTotalCountCardProps> = ({ card }) => {
  function formatMillions(value: number) {
    return value.formatCompact();
  }

  return (
    <div className={styles["b-card--ring-graph-with-total-count"]}>
      <h4 className="headline-h4 sf-text-bold color--text-primary">{card.Title}</h4>
      <Chart
        data={card.Values.map((it) => it.Value)}
        total={card.TotalCount ? card.TotalCount : card.Values.reduce((prev, curr) => prev + curr.Value, 0)}
        colors={["#226DFF", "#97D218"]}
        legendBgColors={["#CCE0F8", "#F2F8CC"]}
        labels={card.Values.map((it) => it.Title)}
        formatLegend={(value) => (typeof value === "number" ? value.formatCompact() : value)}
        formatSelected={formatMillions}
      />
    </div>
  );
};

export default AnalyticsRingGraphWithTotalCountCard;
