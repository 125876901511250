import { useEffect, useRef, useState } from "react";

const useEmptyBlockHeight = () => {
  const emptyBlockRef = useRef<HTMLDivElement>(null);

  const [emptyBlockHeight, setEmptyBlockHeight] = useState<number>(0);

  function getEmptyBlockHeight() {
    if (emptyBlockRef.current) {
      const rect = emptyBlockRef.current.getBoundingClientRect();
      setEmptyBlockHeight(window.innerHeight - rect.top - 24); // 24 - bottom padding
    }
  }

  useEffect(() => {
    getEmptyBlockHeight();

    window.addEventListener("resize", getEmptyBlockHeight, true);

    return () => {
      window.removeEventListener("resize", getEmptyBlockHeight, true);
    };
  }, [emptyBlockRef]);

  return { emptyBlockHeight, emptyBlockRef };
};

export default useEmptyBlockHeight;
