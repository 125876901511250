import { Dictionary } from "./dictionary";
import { Form, FormFiled, FormValidator } from "./ui";

export namespace Templates {
  export type Group = {
    Id: number;
    Title: string;
    Order: number;
  };

  export class GroupWithQuestions {
    key: string | number;
    group: Group;
    questions: Question[];
    isDefault: boolean = false;

    constructor(group: Group, isDefault: boolean = false) {
      this.group = { ...group };
      this.questions = [];
      this.isDefault = isDefault;
      this.key = `${group.Id}--${isDefault ? "default" : "custom"}`;
    }
  }

  export type Question = {
    Id: number;
    GroupId: Group["Id"];
    GroupTitle: Group["Title"];
    QuorumId: Dictionary.DictionaryItem<Dictionary.Quorum>["id"];
    QuorumName: Dictionary.DictionaryItem<Dictionary.Quorum>["name"];
    Title: string;
    Value: string;
    Order: number;
  };

  export type CreateQuestionBody = {
    GroupId: Group["Id"];
    QuorumId: Dictionary.DictionaryItem<Dictionary.Quorum>["id"];
    Title: string;
    Value: string;
    Order?: number;
  };

  export const mandatoryQuestions: GroupWithQuestions = {
    group: {
      Id: -1,
      Title: "Обязательные вопросы",
      Order: 0,
    },
    questions: [
      {
        Id: -1,
        GroupId: -1,
        GroupTitle: "Обязательные вопросы",
        QuorumId: 1,
        QuorumName: "Простое большинство от голосов",
        Title: "Об определении администратора общего собрания собственников",
        Value:
          "Выбрать администратором общего собрания собственников дома по адресу _________ на использование государственной информационной системы «Локоло» при проведении общего собрания собственников в форме заочного голосования с использованием системы если администратор физическое лицо: _________ (фамилия) _______ (имя) _________ (отчество), серия _____ номер _____ паспорта, место постоянного проживания _____________, адрес электронной почты _________________,  контактный номер __________________ если администратор юридическое лицо: _________ (фирменное наименование), организационно-правовая форма__________________, адрес место нахождения______________, почтовый адрес ___________, номер контактного телефона ______________, официальный сайт __________",
        Order: 0,
      },
      {
        Id: -2,
        GroupId: -1,
        GroupTitle: "Обязательные вопросы",
        QuorumId: 1,
        QuorumName: "Простое большинство от голосов",
        Title: "О порядке приема администратором общего собрания сообщений о проведении общих собраний",
        Value:
          "Определить порядок приема администратором общего собрания сообщений о проведении общих собраний в письменной форме по адресу ____ в период с __ часов до ___часов (устанавливает дни недели либо календарные дни), заказным письмом по адресу место нахождения администратора по адресу ____. Сообщение о проведении общего собрания собственников составить в виде заявления в свободной форме с указанием:\n" +
          "полного ФИО заявителя (либо наименования для ЮЛ); \n" +
          "адреса собственности;\n" +
          "реквизитов о праве собственности;\n" +
          "вопросы повестки;\n" +
          "необходимых документов к вопросам;\n" +
          "Даты проведения общего собрания;\n" +
          "подписи заявителя;\n" +
          "даты подписи заявления;",
        Order: 0,
      },
      {
        Id: -3,
        GroupId: -1,
        GroupTitle: "Обязательные вопросы",
        QuorumId: 1,
        QuorumName: "Простое большинство от голосов",
        Title: "О порядке приема решений собственников",
        Value:
          "Определить порядок приема администратором общего собрания решений собственников помещений в многоквартирном доме по вопросам, поставленным на голосование, в письменной форме по адресу ________ в период с ____ часов до ____ часов (устанавливает дни недели либо календарные дни), либо заказным письмом по адресу место нахождения администратора по адресу __________. Голосование осуществляется посредством передачи администратору собрания решения, оформленного в письменной форме решений собственников помещений в многоквартирном доме по вопросам, поставленным на голосование, до даты и времени окончания такого голосования",
        Order: 0,
      },
      {
        Id: -4,
        GroupId: -1,
        GroupTitle: "Обязательные вопросы",
        QuorumId: 1,
        QuorumName: "Простое большинство от голосов",
        Title: "О продолжительности голосования по вопросам повестки дня общего собрания собственников",
        Value:
          "Определить продолжительность голосования по вопросам повестки дня общего собрания собственников помещений в многоквартирном доме в форме заочного голосования с использованием системы ____  дней с даты и времени начала проведения голосования  (укажите количество дней. Продолжительность должна составлять не менее чем семь дней и не более чем шестьдесят дней)",
        Order: 0,
      },
    ],
    isDefault: true,
    key: "-1--default",
  };

  export class EditGroupForm extends Form {
    Title: FormFiled = new FormFiled();

    constructor(group?: Group) {
      super();
      this.Title = new FormFiled(group?.Title ?? "", true, false, 32);
    }
  }

  export class EditGroupFormValidator {
    public static isInvalid(form: EditGroupForm) {
      const errors = {
        Title: FormValidator.getFieldErrorMessage(form.Title.value.trim(), form.Title.isRequired),
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }

  export class EditQuestionForm extends Form {
    readonly groupId: Group["Id"];
    readonly questionId: Question["Id"] | null = null;
    Quorum: Dictionary.DictionaryItem<Dictionary.Quorum> | null = null;
    QuorumError: string = "";
    Title: FormFiled;
    Value: FormFiled;

    constructor(groupId: Group["Id"], question?: Question, quorums?: Dictionary.DictionaryItem<Dictionary.Quorum>[]) {
      super();
      this.groupId = groupId;
      this.questionId = question?.Id ?? null;
      this.Title = new FormFiled(question?.Title ?? "", true, false, 1000, 10);
      this.Value = new FormFiled(question?.Value ?? "", true, false, 5000, 20);
      if (question?.QuorumId && quorums && quorums.length > 0) {
        const quorum = quorums.find((it) => it.id === question?.QuorumId);
        this.Quorum = quorum ?? null;
      } else this.Quorum = null;
    }
  }

  export class EditQuestionFormValidator {
    public static validateTitle(title: EditQuestionForm["Title"]) {
      const message = FormValidator.getFieldErrorMessage(title.value.trim(), title.isRequired);

      if (!message && title.minlength && title.value.trim().length <= title.minlength) {
        return `Заголовок вопроса должен быть не менее ${title.minlength} символов`;
      }

      if (!message && title.maxlength && title.value.trim().length > title.maxlength) {
        return `Заголовок вопроса должен быть не более ${title.maxlength} символов`;
      }

      return message;
    }

    public static validateValue(value: EditQuestionForm["Value"]) {
      const message = FormValidator.getFieldErrorMessage(value.value.trim(), value.isRequired);

      if (!message && value.minlength && value.value.trim().length <= value.minlength) {
        return `Текст вопроса должен быть не менее ${value.minlength} символов`;
      }

      if (!message && value.maxlength && value.value.trim().length > value.maxlength) {
        return `Текст вопроса должен быть не более ${value.maxlength} символов`;
      }

      return message;
    }

    public static isInvalid(form: EditQuestionForm) {
      const errors = {
        Title: this.validateTitle(form.Title),
        Value: this.validateValue(form.Value),
        Quorum: "",
      };

      if (Object.values(errors).some((it) => !!it)) return errors;

      return false;
    }
  }
}
