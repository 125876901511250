/* eslint-disable no-extend-native */
declare global {
  interface String {
    formatPhone: (divider?: string) => string;
    capitalize: () => string;
  }
}

String.prototype.formatPhone = function (divider: string = " "): string {
  return this?.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, `$1 $2 $3${divider}$4${divider}$5`) ?? "";
};

String.prototype.capitalize = function (): string {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export type {};
