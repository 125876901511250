import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleAcceptAreaModal, toggleAcceptAreaModalLoading } from "@/app/store/slices/uk/registry";
import { saveHouseArea } from "@/app/store/slices/uk/registry/actions";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import NumberInput from "@/components/base-input/NumberInput";
import styles from "./styles.module.scss";
import { FormValidator } from "@/types";

const AcceptTotalAreaModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { acceptAreaModal } = useSelector((state: RootState) => state.ukRegistry);

  const [field, setField] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setErrorMessage("");
    const validation = FormValidator.getFieldErrorMessage(field.trim(), true);

    if (validation) {
      setErrorMessage(validation);
      return;
    }

    dispatch(toggleAcceptAreaModalLoading(true));
    try {
      await dispatch(saveHouseArea(field)).unwrap();
      onClose();
    } catch (error: any) {
      if (error.Data?.Errors) {
        Object.keys(error.Data.Errors).forEach((key: any) => {
          const message = Array.isArray(error.Data.Errors[key]) ? error.Data.Errors[key][0] : error.Data.Errors[key];
          setErrorMessage(message);
        });
      } else {
        setErrorMessage(error.Data?.Message);
      }
    }
  }

  function onClose() {
    dispatch(toggleAcceptAreaModal(false));
  }

  return (
    <BaseModal isOpen={acceptAreaModal.isOpen} minWidth="520px" maxWidth="520px">
      <BaseModalHeader
        title="Для работы с реестрами введите общую площадь помещений в собственности"
        onClose={onClose}
      />

      <BaseModalContent>
        <form className={styles.content} onSubmit={handleSubmit}>
          <p className="sf-text-regular text--primary color--text-primary">
            Введенное значение будет учитываться при расчете результатов голосования. Если в доме есть помещения,
            купленные по договору долевого строительства, которые в собственность ещё не оформлены, они тоже принимаются
            в расчёт.
          </p>

          <NumberInput
            value={field}
            label="Общая площадь помещений в собственности (м²)"
            placeholder="Введите площадь"
            errorMessage={errorMessage}
            onChange={(value) => setField(value)}
          />
        </form>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton variant="secondary" onClick={onClose}>
          Отменить
        </BaseButton>
        <BaseButton onClick={handleSubmit}>Сохранить</BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default AcceptTotalAreaModal;
