import client from "./config";

export namespace QuestionsService {
  export function getGroups() {
    return client.get<unknown>("/api/default-data/occ-questions/get-groups");
  }

  export function getQuestionsByGrpup(GroupId: number) {
    return client.get<unknown>("/api/default-data/occ-questions/get-questions-by-group", { params: { GroupId } });
  }
}
