import { RootState } from "@/app/store";
import { useSelector } from "react-redux";
import CreateOccItemCreateAgendaStep from "@/features/CreateOccNew/OccItem/Steps/Finish";

const SntCreateOCCFinishStep: React.FC = () => {
  const occId = useSelector((state: RootState) => state.createOcc.occId);

  return (
    <div>
      <CreateOccItemCreateAgendaStep
        adminTitle="Что можете сделать Вы?"
        adminList={[
          "Важно понимать, что с членов правления садоводства не снимается обязанность информировать садоводов о предстоящем собрании иными доступными способами, так как не все садоводы зарегистрированы в приложении «Локоло»",
          "Используйте сформированные документы в разделе «Мои собрания» вкладки «Документы» для осуществления всех необходимых процедур проведения общих собраний.",
          "Вносите решения садоводов, принятые на бумажном носителе, в систему в разделе «Мои собрания» вкладки «Внести решение».",
          "Следите за наличием кворума в разделе «Мои собрания» вкладки «Результаты»",
        ]}
        prevLink={`/snt/create-occ/${occId}/preview`}
        createNewPath={"/snt/create-occ/new/create"}
      />
    </div>
  );
};

export default SntCreateOCCFinishStep;
