import styles from "./styles.module.scss";

type ISntPassportBlockContentFormProps = {
  children: JSX.Element | JSX.Element[] | React.ReactNode | string | null;
};

const SntPassportBlockContentForm: React.FC<ISntPassportBlockContentFormProps> = ({ children }) => {
  return <div className={styles["b-snt-passport-block-content-form"]}>{children}</div>;
};

export default SntPassportBlockContentForm;
