import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import AccordionContext from "./context";
import classNames from "classnames/bind";
import styles from "./accordion.module.scss";

const cx = classNames.bind(styles);

interface IExpanded {
  children: React.ReactNode;
  id: string | number;
  className?: string;
}

const Expanded: React.FC<IExpanded> = ({ children, id, className }) => {
  const context = useContext(AccordionContext);

  const isOpen: boolean = useMemo(() => {
    return id === context?.opened;
  }, [context?.opened, id]);

  const containerRef = useRef<HTMLDivElement>(null);
  const [transitionDuration, setTransitionDuration] = useState<number>(300);
  const [maxHeight, setMaxHeight] = useState<number>(0);

  useEffect(() => {
    if (containerRef.current) {
      if (isOpen) {
        const height = containerRef.current.scrollHeight;

        setTransitionDuration(300 + height / 2);
        setMaxHeight(height);
      }

      if (!isOpen) {
        setMaxHeight(0);
      }
    }

    if (containerRef.current) {
      if (isOpen) {
        const height = containerRef.current.scrollHeight;
        setTransitionDuration(300 + height / 2);
        setMaxHeight(height);
      } else {
        setMaxHeight(0);
      }
    }
  }, [isOpen, children]);

  return (
    <div
      ref={containerRef}
      className={cx(className, { expanded: true })}
      style={{ maxHeight: `${maxHeight}px`, transitionDuration: `${transitionDuration}ms` }}
    >
      <div>{children}</div>
    </div>
  );
};

export default Expanded;
