import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import {
  analyticsIcon,
  chevronRight24Icon,
  dropdownSecondaryChevron,
  gardeningMembers,
  notGardeningMembers,
} from "@/consts/icons";
import { paths } from "@/consts/routes";
import { RootState, useAppDispatch } from "@/app/store";
import { setLokoloUserExists, setQuery, setQueryColumn } from "@/app/store/slices/snt/passport";
import { Pagination, SntPassport } from "@/types";
import { SntPassportService } from "@/services/v2";
import useDebounce from "@/hooks/useDebounce";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import PageHeader from "@/components/PageHeader";
import SearchByQueryType from "@/components/SearchByQueryType";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import {
  BaseTable,
  BaseContainer,
  BaseTableToolbar,
  BaseTableHead,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableBody,
  BaseTableCell,
} from "@/components/base-table";
import { Spinner } from "@/components/spinner";
import BaseButton from "@/components/base-button";
import styles from "./styles.module.scss";

const InstitutionSntList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { measureRef, isIntersecting, observer } = useInfiniteScroll();
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  const { query, queryColumn, isLokoloUserExists } = useSelector((state: RootState) => state.sntPassport);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination | null>();
  const [sntList, setSntList] = useState<SntPassport.InstitutionSntItem[]>([]);
  const [debouncedQuery] = useDebounce(query, 300);

  const hasMore = useMemo(() => pagination && pagination.Index < pagination.Count, [pagination]);

  function handleSelect(snt: SntPassport.InstitutionSntItem) {
    navigate(paths.institution(snt.Id.toString()).sntItem);
  }

  function handleQueryColumnChange(column: SntPassport.QueryColumn) {
    if (queryColumn === column) {
      return;
    }

    dispatch(setQueryColumn(column));
    if (query.length > 0) {
      fetchList(column);
    }
  }

  function setQueryValue(value: string) {
    dispatch(setQuery(value));
  }

  function setLokoloUserExistsValue(value: boolean | null) {
    dispatch(setLokoloUserExists(value));
  }

  async function fetchList(forceColumn?: SntPassport.QueryColumn) {
    setPagination(null);
    setLoading(true);

    try {
      const { data } = await SntPassportService.getSntList({
        Value: query,
        Column: forceColumn ?? queryColumn,
        Page: 1,
        LokoloUserExists: isLokoloUserExists ?? undefined,
      });
      setSntList(data.Data);
      setPagination(data.Page);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchMoreData() {
    try {
      const { data } = await SntPassportService.getSntList({
        Value: query,
        Column: queryColumn,
        Page: (pagination?.Index ?? 0) + 1,
        LokoloUserExists: isLokoloUserExists ?? undefined,
      });
      setSntList((prevState) => [...prevState, ...data.Data]);
      setPagination(data.Page);
      if (observer && isIntersecting && data.Page.Index === data.Page.Count) {
        observer.disconnect();
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
  }, [isIntersecting]);

  useEffect(() => {
    fetchList();
  }, [isLokoloUserExists, debouncedQuery]);

  return (
    <>
      <PageHeader title="Садоводства Республики Татарстан">
        <SearchByQueryType
          query={query}
          type={queryColumn}
          typeOptions={[
            { value: SntPassport.QueryColumn.Inn, text: "По ИНН" },
            { value: SntPassport.QueryColumn.Name, text: "По наименованию" },
            { value: SntPassport.QueryColumn.MunicipalDistrict, text: "По местоположению" },
          ]}
          onQueryChange={setQueryValue}
          onTypeChange={(value) => handleQueryColumnChange(value as SntPassport.QueryColumn)}
        />
      </PageHeader>

      <div className={styles.toolbar}>
        <NavLink to={paths.institution().analytics}>
          <BaseButton variant="secondary" appearance="neutral" endIcon={analyticsIcon}>
            Аналитика
          </BaseButton>
        </NavLink>
      </div>

      <div>
        <BaseContainer className={styles.table}>
          <BaseTableToolbar>
            <BaseDropdown
              className={styles.filters}
              placeholder="Фильтр"
              value={isLokoloUserExists}
              isSelectable={true}
              onSelect={(value) => setLokoloUserExistsValue(value)}
              customIcon={dropdownSecondaryChevron}
            >
              <div className={styles.filters_options}>
                <BaseDropdownMenuItem value={null} defaultHover>
                  <span className="sf-text-medium">Все</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={false} defaultHover>
                  {notGardeningMembers}
                  <span className="sf-text-regular">Нет учетной записи</span>
                </BaseDropdownMenuItem>
                <BaseDropdownMenuItem value={true} defaultHover>
                  {gardeningMembers}
                  <span className="sf-text-regular">Есть учетная запись</span>
                </BaseDropdownMenuItem>
              </div>
            </BaseDropdown>
          </BaseTableToolbar>

          <div className={styles.table_container}>
            <BaseTable className={styles.table_itself}>
              <BaseTableHead>
                <BaseTableRow className={styles.table_head_row}>
                  <BaseTableHeadCell>Район</BaseTableHeadCell>
                  <BaseTableHeadCell>Краткое наименование садоводства</BaseTableHeadCell>
                  <BaseTableHeadCell>ИНН</BaseTableHeadCell>
                  <BaseTableHeadCell>Учетная запись Локоло</BaseTableHeadCell>
                  <BaseTableHeadCell></BaseTableHeadCell>
                </BaseTableRow>
              </BaseTableHead>

              {isLoading ? (
                <BaseTableBody className={styles.empty} refProp={emptyBlockRef}>
                  <BaseTableRow>
                    <BaseTableCell colspan={7} style={{ height: `${emptyBlockHeight}px` }}>
                      <div className="flex center">
                        <Spinner color="#226dff" size={36} />
                      </div>
                    </BaseTableCell>
                  </BaseTableRow>
                </BaseTableBody>
              ) : sntList.length > 0 ? (
                <BaseTableBody className={styles.table_body}>
                  {sntList.map((snt) => (
                    <BaseTableRow key={snt.Id} className={styles.table_row} onClick={() => handleSelect(snt)}>
                      <BaseTableHeadCell>
                        <span className="sf-text-semibold">{snt.MunicipalDistrict}</span>
                      </BaseTableHeadCell>
                      <BaseTableCell>
                        <span>{snt.Name}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span>{snt.Inn}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span>{snt.LokoloUserExists ? "Есть" : "Нет"}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span className={styles.table_row_icon}>{chevronRight24Icon("var(--text-secondary)")}</span>
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                  {hasMore && (
                    <tr className={styles.loading_more} ref={measureRef}>
                      <td colSpan={7}>
                        <div className="flex center">
                          <Spinner color="#226dff" size={36} />
                        </div>
                      </td>
                    </tr>
                  )}
                </BaseTableBody>
              ) : (
                <BaseTableBody className={styles.empty} refProp={emptyBlockRef}>
                  <BaseTableRow>
                    <BaseTableCell colspan={7} style={{ height: `${emptyBlockHeight}px` }}>
                      <div className="center">
                        {query.length > 0 || isLokoloUserExists !== null ? (
                          <p className="text--primary sf-pro-regular color--text-primary">Поиск не дал результатов</p>
                        ) : (
                          <p className="text--primary sf-pro-regular color--text-primary">Садоводств пока нет</p>
                        )}
                      </div>
                    </BaseTableCell>
                  </BaseTableRow>
                </BaseTableBody>
              )}
            </BaseTable>
          </div>
        </BaseContainer>
      </div>
    </>
  );
};

export default InstitutionSntList;
