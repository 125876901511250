import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum SnackbarType {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
  DELETE = "delete",
  NETWORK_ERROR = "network-error",
  Block = "BLOCK",
}

export type Snackbar = {
  key: string;
  title?: string;
  body?: string;
  type?: SnackbarType;
  closeBtn?: boolean;
  duration?: number | "infinite";
};

interface SnackbarState {
  toasts: Snackbar[];
}

const initialState: SnackbarState = {
  toasts: [],
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    hideSnackbar: (state, action: PayloadAction<Snackbar["key"]>) => {
      const existIndex = state.toasts.findIndex((it) => it.key === action.payload);
      if (existIndex >= 0) {
        state.toasts.splice(existIndex, 1);
      }
    },
    showSnackbar: (state, action: PayloadAction<Snackbar>) => {
      const existIndex = state.toasts.findIndex((it) => it.key === action.payload.key);
      if (existIndex >= 0) {
        state.toasts.splice(existIndex, 1);
      }
      state.toasts.push(action.payload);
    },
  },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
