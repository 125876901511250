import client, { formDataClient } from "./config";
import { objectToFormData } from "@/helpers/common";
import {
  ResponseAbstract,
  OCC,
  House,
  Dictionary,
  ResponseAbstractWithPagination,
  OCCV2Order,
  OCCV2Author,
} from "@/types";

export namespace OCCService {
  export function getOCCList({
    StatusId,
    FiasId,
    Number,
    IsActual,
    page,
    limit = 50,
  }: {
    StatusId?: Dictionary.DictionaryItem<Dictionary.OCCStatus>["id"];
    FiasId?: House.Item["FiasId"];
    Number?: string;
    IsActual?: boolean;
    page?: number;
    limit?: number;
  }) {
    return client.get<ResponseAbstractWithPagination<OCC.OccOrderItem[]>>("/api/occ/get-all", {
      params: { StatusId, FiasId, Number, IsActual, page, limit },
    });
  }

  export function createOCC(body: OCC.CreateOCCBody) {
    return client.post<ResponseAbstract<OCC.OccOrderBase["Id"]>>("/api/occ/create", body);
  }

  export function updateOCC(body: OCC.UpdateRequestBody) {
    return client.post<ResponseAbstract<OCC.OccOrderBase["Id"]>>("/api/occ/update", body);
  }

  export function getOCCFullDetails(OccId: OCC.OccOrderBase["Id"]) {
    return client.get<ResponseAbstract<OCC.OccFullDetails>>("/api/occ/get", { params: { OccId } });
  }

  export function acceptOCC(OccId: OCC.OccOrderBase["Id"]) {
    return client.post<ResponseAbstract<boolean>>("/api/occ/start", { OccId });
  }

  export function getOCCOrder(OccId: OCC.OccOrderBase["Id"]) {
    return client.get<ResponseAbstract<OCC.OccOrderItemFull>>("/api/occ/get-order", { params: { OccId } });
  }

  export function deleteOcc(OccId: OCC.OccOrderBase["Id"]) {
    return client.post<ResponseAbstract<boolean>>("/api/occ/delete", { OccId });
  }

  export function getOCCFiles(OccId: OCC.OccOrderBase["Id"]) {
    return client.get<ResponseAbstract<{ Documents: OCC.OCCDocument[] }>>("/api/occ/get-documents", {
      params: { OccId },
    });
  }

  export function getOwnerReestr(OccId: OCC.OccOrderBase["Id"]) {
    return client.get<ResponseAbstract<{ Owners: OCC.RegistryOwner[] }>>("/api/occ/get-owner-reestr", {
      params: { OccId },
    });
  }

  export function getOwnerReestrById(OccId: OCC.OccOrderBase["Id"], OwnerReestrId: OCC.RegistryOwner["Id"]) {
    return client.get<ResponseAbstract<OCC.RegistryOwner>>("/api/occ/get-owner-reestr-by-id", {
      params: { OccId, OwnerReestrId },
    });
  }

  export function getOwnerResult(OccId: OCC.OccOrderBase["Id"], OwnerId: OCC.RegistryOwner["Id"]) {
    return client.get<ResponseAbstract<OCC.OwnerOCCResults>>("/api/occ/get-owner-result", {
      params: { OccId, OwnerId },
    });
  }

  export function saveOwnerResult(body: OCC.SetOwnerResultRequestBody) {
    const formdata = objectToFormData(body);

    return formDataClient.post<ResponseAbstract<boolean>>("/api/occ/set-owner-result", formdata);
  }

  export function getProgress(OccId: OCC.OccOrderBase["Id"]) {
    return client.get<ResponseAbstract<OCC.Progress>>("/api/occ/get-progress", { params: { OccId } });
  }

  export function getQuestionsResult(OccId: OCC.OccOrderBase["Id"]) {
    return client.get<ResponseAbstract<{ Questions: Array<OCC.QuestionResult> }>>("/api/occ/get-questions-result", {
      params: { OccId },
    });
  }

  export function getOwnersQuestionsResults(OccId: OCC.OccOrderBase["Id"]) {
    return client.get<ResponseAbstract<{ OccOwnersQuestionsResults: Array<OCC.OwnersQuestionsResultsResponse> }>>(
      "/api/occ/get-owners-questions-results",
      { params: { OccId } }
    );
  }

  // Author
  export function getOCCAuthor(OccId: OCCV2Order.Base["Id"]) {
    return client.get<ResponseAbstract<OCCV2Author.Person>>("/api/occ/get-author", { params: { OccId } });
  }

  export function setOCCAuthor(body: OCCV2Author.SetAuthorBody) {
    return client.post<ResponseAbstract<boolean>>("/api/occ/set-author", body);
  }

  // Administrator
  export function getOCCAdministrator(OccId: OCCV2Order.Base["Id"]) {
    return client.get<ResponseAbstract<OCCV2Author.Person>>("/api/occ/get-administrator", { params: { OccId } });
  }

  export function setOCCAdministrator(body: OCCV2Author.SetAdministratorBody) {
    return client.post<ResponseAbstract<boolean>>("/api/occ/set-administrator", body);
  }

  // QUESTIONS
  export function getOCCQuestions(OccId: OCC.OccOrderBase["Id"]) {
    return client.get<ResponseAbstract<OCC.Question[]>>("/api/occ/get-questions", { params: { OccId } });
  }

  export function createQuestion(body: OCC.CreateQuestionBody) {
    return client.post<ResponseAbstract<boolean>>("/api/occ/set-questions", body);
  }

  export function deleteQuestion(OccQuestionId: OCC.OccOrderBase["Id"]) {
    return client.post<ResponseAbstract<boolean>>("/api/occ/delete-question", { OccQuestionId });
  }
}
