import { Outlet, useNavigate } from "react-router";
import { paths } from "@/consts/routes";
import PageHeader from "@/components/PageHeader";
import SegmentedСontrols from "@/components/SegmentedСontrols";

const InstitutionAnalytics: React.FC = () => {
  const navigate = useNavigate();

  function handleBackClick() {
    navigate(paths.institution().root);
  }

  return (
    <>
      <div style={{ background: "white", padding: "24px 24px 0" }}>
        <PageHeader title="Аналитика" defaultLink={paths.institution().root} onLinkClick={handleBackClick}>
          <SegmentedСontrols
            list={[
              {
                text: "По гос.поддержке",
                link: paths.institution().fullPath().analyticsStateSupport,
              },
              {
                text: "По инфраструктуре",
                link: paths.institution().fullPath().analyticsInfrastructure,
              },
            ]}
          />
        </PageHeader>
      </div>

      <div>
        <Outlet />
      </div>
    </>
  );
};

export default InstitutionAnalytics;
