import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ukRoleIcon, sntRoleIcon, successIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { isCompanyTypeUK } from "@/app/store/slices/user";
import { toggleSuccessModalOpen } from "@/app/store/slices/access";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";
import styles from "./styles.module.scss";

const SuccessModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const isUK = useSelector(isCompanyTypeUK);
  const { roles, setRoleDetails } = useSelector((state: RootState) => state.access);

  const role = useMemo(() => {
    return roles.find((it) => it.Key === setRoleDetails.Role);
  }, [roles, setRoleDetails]);

  const close = () => {
    dispatch(toggleSuccessModalOpen(false));
  };

  const locoloAccessIcon = isUK ? ukRoleIcon : sntRoleIcon;

  return (
    <BaseModal isOpen={setRoleDetails.isSuccessModalOpen} minWidth="520px" maxWidth="520px">
      <BaseModalHeader
        variant="info-iconed"
        iconBgColor="#CFF3E1"
        icon={successIcon}
        title="Доступ успешно предоставлен!"
        onClose={close}
      />

      <BaseModalContent>
        <div className={styles["success-modal-content"]}>
          <p className="text--primary sf-text-regular color--text-primary">
            Выбранная роль привязана к номеру телефона:
          </p>
          <div
            className={[
              styles["success-modal-content__role"],
              isUK ? styles["success-modal-content__role_uk"] : styles["success-modal-content__role_snt"],
            ].join(" ")}
          >
            {locoloAccessIcon}
            {role && <span className="sf-text-medium color--text-primary">{role.Value}</span>}
          </div>
          <h4 className="headline-h4 sf-text-bold">{setRoleDetails.Phone.formatPhone()}</h4>
        </div>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton onClick={close}>Понятно</BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default SuccessModal;
