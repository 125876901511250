import { Pagination, UKRegistry } from "@/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface UKRosreestrState {
  details: UKRegistry.RosreestrDataLoadInfo | null;
  query: string;
  status: UKRegistry.RosreestrСomparisonStatus | null;
  isLoading: boolean;
  apartments: UKRegistry.RosreestrСomparison[];
  pagination: Pagination | null;
  isStartRosreestrUploadLoading: boolean;
  isRosreestrDataLoadStartedModalShown: boolean;
  isRegistryUpdatedModalShow: boolean;
  isHouseDetailsUpdatingModalShown: boolean;
  houseDetailsCompliantModal: {
    isShown: boolean;
    comparisonId: UKRegistry.RosreestrСomparison["Id"] | null;
    apartmentNumber: UKRegistry.RosreestrСomparison["Number"] | null;
  };
  apartmentСomparisonModal: {
    isShown: boolean;
    comparisonId: UKRegistry.RosreestrСomparison["Id"] | null;
  };
  isSizeSharesErrorModalShown: boolean;
  systemMessage: {
    isShown: boolean;
    date: number | null;
    isLoading: boolean;
    apartments: UKRegistry.RosreestrСomparison[];
    pagination: Pagination | null;
  };
}

const UKRosreestrSlice = createSlice({
  name: "uk-rosreestr",

  initialState: {
    details: null,
    query: "",
    status: null,
    isLoading: false,
    apartments: [],
    pagination: null,
    isStartRosreestrUploadLoading: false,
    isRosreestrDataLoadStartedModalShown: false,
    isRegistryUpdatedModalShow: false,
    isHouseDetailsUpdatingModalShown: false,
    houseDetailsCompliantModal: {
      isShown: false,
      comparisonId: null,
      apartmentNumber: null,
    },
    apartmentСomparisonModal: {
      isShown: false,
      comparisonId: null,
    },
    isSizeSharesErrorModalShown: false,
    systemMessage: {
      isShown: false,
      date: null,
      isLoading: false,
      apartments: [],
      pagination: null,
    },
  } as UKRosreestrState,

  reducers: {
    setDetails: (state, action: PayloadAction<UKRosreestrState["details"]>) => {
      state.details = action.payload;
    },

    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },

    setStatus: (state, action: PayloadAction<UKRosreestrState["status"]>) => {
      state.status = action.payload;
    },

    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setApartments: (state, action: PayloadAction<UKRosreestrState["apartments"]>) => {
      state.apartments = action.payload;
    },

    pushApartments: (state, action: PayloadAction<UKRosreestrState["apartments"]>) => {
      state.apartments.push(...action.payload);
    },

    setPagination: (state, action: PayloadAction<UKRosreestrState["pagination"]>) => {
      state.pagination = action.payload;
    },

    toggleRegistryUpdatedModal: (state, action: PayloadAction<UKRosreestrState["isRegistryUpdatedModalShow"]>) => {
      state.isRegistryUpdatedModalShow = action.payload;
    },

    toggleRosreestrDataLoadStartedModal: (
      state,
      action: PayloadAction<UKRosreestrState["isRosreestrDataLoadStartedModalShown"]>
    ) => {
      state.isRosreestrDataLoadStartedModalShown = action.payload;
    },

    toggleHouseDetailsUpdatingModal: (
      state,
      action: PayloadAction<UKRosreestrState["isHouseDetailsUpdatingModalShown"]>
    ) => {
      state.isHouseDetailsUpdatingModalShown = action.payload;
    },

    toggleHouseDetailsCompliantModal: (
      state,
      action: PayloadAction<{
        isShown: boolean;
        comparisonId?: UKRosreestrState["houseDetailsCompliantModal"]["comparisonId"];
        apartmentNumber?: UKRosreestrState["houseDetailsCompliantModal"]["apartmentNumber"];
      }>
    ) => {
      if (action.payload.isShown && action.payload.comparisonId) {
        state.houseDetailsCompliantModal.isShown = action.payload.isShown;
        state.houseDetailsCompliantModal.comparisonId = action.payload.comparisonId;
        state.houseDetailsCompliantModal.apartmentNumber = action.payload.apartmentNumber ?? null;
        return;
      }

      state.houseDetailsCompliantModal.isShown = false;
    },

    toggleApartmentСomparisonModal: (
      state,
      action: PayloadAction<{
        isShown: boolean;
        comparisonId?: UKRosreestrState["apartmentСomparisonModal"]["comparisonId"];
      }>
    ) => {
      if (action.payload.isShown && action.payload.comparisonId) {
        state.apartmentСomparisonModal.isShown = action.payload.isShown;
        state.apartmentСomparisonModal.comparisonId = action.payload.comparisonId;
        return;
      }

      state.apartmentСomparisonModal.isShown = false;
    },

    toggleStartRosreestrUploadLoading: (state, action: PayloadAction<boolean>) => {
      state.isStartRosreestrUploadLoading = action.payload;
    },

    toggleSizeSharesErrorModal: (state, action: PayloadAction<UKRosreestrState["isSizeSharesErrorModalShown"]>) => {
      state.isSizeSharesErrorModalShown = action.payload;
    },

    toggleSystemMessage: (
      state,
      action: PayloadAction<{
        isShown: boolean;
        date?: UKRosreestrState["systemMessage"]["date"];
        apartments?: UKRosreestrState["systemMessage"]["apartments"];
        pagination?: UKRosreestrState["systemMessage"]["pagination"];
      }>
    ) => {
      if (!action.payload.isShown) {
        state.systemMessage.isShown = action.payload.isShown;
        return;
      }

      if (action.payload.date) {
        state.systemMessage.date = action.payload.date;
      }
      if (action.payload.apartments) {
        state.systemMessage.apartments = action.payload.apartments;
      }
      if (action.payload.pagination) {
        state.systemMessage.pagination = action.payload.pagination;
      }
      if (action.payload.isShown) {
        state.systemMessage.isLoading = false;
      }
      state.systemMessage.isShown = action.payload.isShown;
    },

    toggleSystemMessageLoading: (state, action: PayloadAction<UKRosreestrState["systemMessage"]["isLoading"]>) => {
      state.systemMessage.isLoading = action.payload;
    },
  },
});

export default UKRosreestrSlice.reducer;
export * from "./actions";
export const {
  setDetails,
  setQuery,
  setStatus,
  toggleLoading,
  setApartments,
  pushApartments,
  setPagination,
  toggleRegistryUpdatedModal,
  toggleRosreestrDataLoadStartedModal,
  toggleHouseDetailsUpdatingModal,
  toggleHouseDetailsCompliantModal,
  toggleApartmentСomparisonModal,
  toggleStartRosreestrUploadLoading,
  toggleSizeSharesErrorModal,
  toggleSystemMessage,
  toggleSystemMessageLoading,
} = UKRosreestrSlice.actions;
