import { OCC, OCCV2Order } from "@/types";

export const paths = {
  baseUrl: "/",
  authorization: "/auth",
  createOcc: "/occ/create",
  createOccFinish: "/occ/create/finish/:occId",
  myOcc: "/my-occ",
  myOccById(
    occId: OCC.OccOrderBase["Id"] | ":occId" = ":occId",
    ownerId: OCC.RegistryOwner["Id"] | ":ownerId" = ":ownerId"
  ) {
    return {
      index: `/my-occ/${occId}`,
      details: `/my-occ/${occId}/details`,
      results: `/my-occ/${occId}/results`,
      resultsMeeting: `/my-occ/${occId}/results/meeting`,
      resultsQuestions: `/my-occ/${occId}/results/questions`,
      resultsOwners: `/my-occ/${occId}/results/owners`,
      questions: `/my-occ/${occId}/questions`,
      events: `/my-occ/${occId}/events`,
      documents: `/my-occ/${occId}/documents`,
      decision: `/my-occ/${occId}/decision`,
      decisionByOwner: `/my-occ/${occId}/decision/${ownerId}`,
    };
  },
  contacts: "/contacts",
  managementTemplate: "/management-template",
  settings: "/settings",
  documents: "/useful-documents",
  accessSettings: "/access",
  users: "/users",

  institution(sntId: string | ":sntId" = ":sntId") {
    const root = "/institution";
    return {
      root,
      map: "map",
      sntsList: "snts",
      analytics: "analytics",
      analyticsStateSupport: "state-support",
      analyticsInfrastructure: "infrastructure",
      sntItem: sntId.toString(),
      sntItemAgricultureMinistry: "main",
      sntItemBoard: "board",
      sntItemSummary: "summary",

      fullPath() {
        return {
          root,
          map: `${root}/${this.map}`,
          sntsList: `${root}/${this.sntsList}`,
          analytics: `${root}/${this.sntsList}/${this.analytics}`,
          analyticsStateSupport: `${root}/${this.sntsList}/${this.analytics}/${this.analyticsStateSupport}`,
          analyticsInfrastructure: `${root}/${this.sntsList}/${this.analytics}/${this.analyticsInfrastructure}`,
          sntItem: `${root}/${this.sntsList}/${sntId}`,
          sntItemAgricultureMinistry: `${root}/${this.sntsList}/${sntId}/${this.sntItemAgricultureMinistry}`,
          sntItemBoard: `${root}/${this.sntsList}/${sntId}/${this.sntItemBoard}`,
          sntItemSummary: `${root}/${this.sntsList}/${sntId}/summary`,
        };
      },
    };
  },

  snt() {
    const root = "/snt";
    return {
      root,
      passport: {
        root: "passport",
        main: "main",
        minselhoz: "minselhoz",
      },

      fullPath() {
        return {
          passport: {
            root: `${this.root}/${this.passport.root}`,
            main: `${this.root}/${this.passport.root}/${this.passport.main}`,
            minselhoz: `${this.root}/${this.passport.root}/${this.passport.minselhoz}`,
          },
        };
      },
    };
  },

  uk() {
    return {
      root: "uk",
      registry: {
        subroot: "registry",
        main: "main",
        rosreestr: "rosreestr",
        analytics: "analytics",
      },
      createOCC: {
        subroot: "create-occ",
        item: {
          layout: "create-occ/:occId",
          create: "create",
          author: "author",
          administrator: "administrator",
          agenda: "agenda",
          preview: "preview",
          finish: "finish",
        },
      },
      houses: "my-houses",
      cadastralNumber: "cadastral-number",
      giszhkh: "giszhkh",

      fullPath() {
        return {
          root: `/${this.root}}`,
          registry: {
            subroot: `/${this.root}/${this.registry.subroot}`,
            main: `/${this.root}/${this.registry.subroot}/${this.registry.main}`,
            rosreestr: `/${this.root}/${this.registry.subroot}/${this.registry.rosreestr}`,
            analytics: `/${this.root}/${this.registry.subroot}/${this.registry.analytics}`,
          },
          createOCC: {
            subroot: `/${this.root}/${this.createOCC.subroot}`,
            item: {
              layout: (occId: OCCV2Order.Item["Id"] | "new" = "new") => `${this.fullPath().createOCC.subroot}/${occId}`,
              create: (occId: OCCV2Order.Item["Id"] | "new" = "new") =>
                `${this.fullPath().createOCC.item.layout(occId)}/${this.createOCC.item.create}`,
              author: (occId: OCCV2Order.Item["Id"] | "new" = "new") =>
                `${this.fullPath().createOCC.item.layout(occId)}/${this.createOCC.item.author}`,
              administrator: (occId: OCCV2Order.Item["Id"] | "new" = "new") =>
                `${this.fullPath().createOCC.item.layout(occId)}/${this.createOCC.item.administrator}`,
              agenda: (occId: OCCV2Order.Item["Id"] | "new" = "new") =>
                `${this.fullPath().createOCC.item.layout(occId)}/${this.createOCC.item.agenda}`,
              preview: (occId: OCCV2Order.Item["Id"] | "new" = "new") =>
                `${this.fullPath().createOCC.item.layout(occId)}/${this.createOCC.item.preview}`,
              finish: (occId: OCCV2Order.Item["Id"] | "new" = "new") =>
                `${this.fullPath().createOCC.item.layout(occId)}/${this.createOCC.item.finish}`,
            },
          },
          houses: `/${this.root}/${this.houses}`,
          cadastralNumber: `/${this.root}/${this.cadastralNumber}`,
          giszhkh: `/${this.root}/${this.giszhkh}`,
        };
      },
    };
  },
};
