import { OCCV2Order } from "./order";
import { OCCV2Questions } from "./questions";
import { OCCV2Author } from "./author";

export namespace OCCV2 {
  export type Details = {
    Order: OCCV2Order.ItemFull;
    Author: OCCV2Author.Person;
    Administrator: OCCV2Author.Person;
    Questions: Array<OCCV2Questions.Item>;
    PlannedEvents: {
      Publication: PlannedEvent;
      Start: PlannedEvent;
      End: PlannedEvent;
      CreateProtocol: PlannedEvent;
    };
  };

  export type PlannedEvent = {
    Date: string;
    Text: string;
  };
}

export * from "./order";
export * from "./questions";
export * from "./author";
