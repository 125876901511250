import { SntPassportAgricultureMinistry } from "./";
import { SntPassportEnums } from "../enums";
import { Dictionary, Form, FormFiled, FormValidator, SelectField } from "@/types";

export namespace SntPassportAgricultureMinistryForms {
  export class WorkTypeBaseForm {
    WorkType: SelectField<Dictionary.AlternativeDictionaryItem<
      SntPassportEnums.ExternalInfrastructureWorkType | SntPassportEnums.InternalInfrastructureWorkType
    > | null> = new SelectField(null, true);
    BasisDocumentName: FormFiled = new FormFiled("", true, false, 50, 3);
    Note: FormFiled = new FormFiled("", false, false, 50, 3);
    WorkCost: FormFiled = new FormFiled("", true);

    // RoadRepair, InnerRoadRepair
    SurfaceType: SelectField<Dictionary.AlternativeDictionaryItem<SntPassportEnums.RoadSurfaceType> | null> =
      new SelectField(null);
    SurfaceTypeKeyOther: FormFiled = new FormFiled("");

    // RoadRepair
    SCDF: FormFiled = new FormFiled("");
    RoadLength: FormFiled = new FormFiled("");

    // ElectricitySupplyRepair, InternalElectricitySupplyRepair
    LEPWorkScope: FormFiled = new FormFiled("");
    KTPWorkScope: FormFiled = new FormFiled("");

    // InternalElectricitySupplyRepair
    ASKUEWorkScope: FormFiled = new FormFiled("");

    // WaterSupplyRepair
    WellWorkScope: FormFiled = new FormFiled("");
    ReservoirWorkScope: FormFiled = new FormFiled("");
    PumpWorkScope: FormFiled = new FormFiled("");
    WaterPipesWorkScope: FormFiled = new FormFiled("");

    // TKORepair
    TKOAreaWorkScope: FormFiled = new FormFiled("");
    BunkerWorkScope: FormFiled = new FormFiled("");
    ContainerWorkScope: FormFiled = new FormFiled("");

    // Other
    WorkTypeKeyOther: FormFiled = new FormFiled("");

    // RoadRepair, InnerRoadRepair, InternalWaterSupplyRepair, Other
    WorkScope: FormFiled = new FormFiled("", true);

    constructor(
      data?:
        | SntPassportAgricultureMinistry.WorkTypeBase
        | SntPassportAgricultureMinistry.ElectricitySupplyRepairWorkType
        | SntPassportAgricultureMinistry.TKORepairWorkType
        | SntPassportAgricultureMinistry.WaterSupplyRepairWorkType
        | SntPassportAgricultureMinistry.RoadRepairWorkType
        | SntPassportAgricultureMinistry.InternalElectricitySupplyRepairWorkType
        | SntPassportAgricultureMinistry.InnerRoadRepairWorkType
        | SntPassportAgricultureMinistry.InternalWaterSupplyRepairWorkType
        | SntPassportAgricultureMinistry.OtherWorkType
        | null,
      workType?: Dictionary.AlternativeDictionaryItem<
        SntPassportEnums.ExternalInfrastructureWorkType | SntPassportEnums.InternalInfrastructureWorkType
      >,
      roadSurfaceTypes?: Dictionary.AlternativeDictionaryItem<SntPassportEnums.RoadSurfaceType>[]
    ) {
      if (data) {
        this.WorkType = new SelectField(workType ?? null, true);
        this.BasisDocumentName = new FormFiled(data.BasisDocumentName ?? "", true);
        this.Note = new FormFiled(data.Note ?? "");
        this.WorkCost = new FormFiled(data.WorkCost?.toString() ?? "", true);

        // RoadRepair, InnerRoadRepair
        if ("SurfaceTypeKey" in data) {
          const roadSurface = roadSurfaceTypes?.find((it) => it.Key === data?.SurfaceTypeKey);
          this.SurfaceType = new SelectField(roadSurface ?? null);
          this.SurfaceTypeKeyOther = new FormFiled(data.SurfaceTypeKeyOther ?? "");
        }

        // RoadRepair
        if ("SCDF" in data) {
          this.SCDF = new FormFiled(data.SCDF?.toString() ?? "");
        }
        if ("RoadLength" in data) {
          this.RoadLength = new FormFiled(data.RoadLength?.toString() ?? "");
        }

        // ElectricitySupplyRepair, InternalElectricitySupplyRepair
        if ("LEPWorkScope" in data) {
          this.LEPWorkScope = new FormFiled(data.LEPWorkScope?.toString() ?? "");
        }
        if ("KTPWorkScope" in data) {
          this.KTPWorkScope = new FormFiled(data.KTPWorkScope?.toString() ?? "");
        }

        // InternalElectricitySupplyRepair
        if ("ASKUEWorkScope" in data) {
          this.ASKUEWorkScope = new FormFiled(data.ASKUEWorkScope?.toString() ?? "");
        }

        // WaterSupplyRepair
        if ("WellWorkScope" in data) {
          this.WellWorkScope = new FormFiled(data.WellWorkScope?.toString() ?? "");
        }
        if ("ReservoirWorkScope" in data) {
          this.ReservoirWorkScope = new FormFiled(data.ReservoirWorkScope?.toString() ?? "");
        }
        if ("PumpWorkScope" in data) {
          this.PumpWorkScope = new FormFiled(data.PumpWorkScope?.toString() ?? "");
        }
        if ("WaterPipesWorkScope" in data) {
          this.WaterPipesWorkScope = new FormFiled(data.WaterPipesWorkScope?.toString() ?? "");
        }

        // TKORepair
        if ("TKOAreaWorkScope" in data) {
          this.TKOAreaWorkScope = new FormFiled(data.TKOAreaWorkScope?.toString() ?? "");
        }
        if ("BunkerWorkScope" in data) {
          this.BunkerWorkScope = new FormFiled(data.BunkerWorkScope?.toString() ?? "");
        }
        if ("ContainerWorkScope" in data) {
          this.ContainerWorkScope = new FormFiled(data.ContainerWorkScope?.toString() ?? "");
        }

        // Other
        if ("Name" in data) {
          this.WorkTypeKeyOther = new FormFiled(data.Name ?? "");
        }

        // RoadRepair, InnerRoadRepair, InternalWaterSupplyRepair, Other
        if ("WorkScope" in data) {
          this.WorkScope = new FormFiled(data.WorkScope?.toString() ?? "", true);
        }
      }
    }
  }

  export class ExternalInfrastructureForm extends Form {
    groups: WorkTypeBaseForm[] = [];

    constructor(
      data?: SntPassportAgricultureMinistry.AgricultureMinistryData["ExternalInfrastructure"],
      workTypes?: Dictionary.AlternativeDictionaryItem<SntPassportEnums.ExternalInfrastructureWorkType>[],
      roadSurfaceTypes?: Dictionary.AlternativeDictionaryItem<SntPassportEnums.RoadSurfaceType>[]
    ) {
      super();
      if (data && (typeof data === "object" || typeof data === "function") && data !== null) {
        (Object.keys(data) as Array<keyof typeof data>).forEach((key) => {
          const workType = workTypes?.find((it) => it.Key === key);
          if (workType) {
            this.groups.push(new WorkTypeBaseForm(data[key], workType, roadSurfaceTypes));
          }
        });
      }
    }
  }

  export class InternalInfrastructureForm extends Form {
    groups: WorkTypeBaseForm[] = [];

    constructor(
      data?: SntPassportAgricultureMinistry.AgricultureMinistryData["InternalInfrastructure"],
      workTypes?: Dictionary.AlternativeDictionaryItem<SntPassportEnums.InternalInfrastructureWorkType>[],
      roadSurfaceTypes?: Dictionary.AlternativeDictionaryItem<SntPassportEnums.RoadSurfaceType>[]
    ) {
      super();
      if (data && (typeof data === "object" || typeof data === "function") && data !== null) {
        (Object.keys(data) as Array<keyof typeof data>).forEach((key) => {
          const workType = workTypes?.find((it) => it.Key === key);
          if (workType) {
            this.groups.push(new WorkTypeBaseForm(data[key], workType, roadSurfaceTypes));
          }
        });
      }
    }
  }

  export function saveRequestBody(
    external: ExternalInfrastructureForm,
    internal: InternalInfrastructureForm
  ): SntPassportAgricultureMinistry.AgricultureMinistryData {
    function bodyByWorkType(
      workType: SntPassportEnums.ExternalInfrastructureWorkType | SntPassportEnums.InternalInfrastructureWorkType,
      form: WorkTypeBaseForm | null,
      isInternal: boolean = false
    ):
      | SntPassportAgricultureMinistry.WorkTypeBase
      | SntPassportAgricultureMinistry.ElectricitySupplyRepairWorkType
      | SntPassportAgricultureMinistry.TKORepairWorkType
      | SntPassportAgricultureMinistry.WaterSupplyRepairWorkType
      | SntPassportAgricultureMinistry.RoadRepairWorkType
      | SntPassportAgricultureMinistry.InternalElectricitySupplyRepairWorkType
      | SntPassportAgricultureMinistry.InnerRoadRepairWorkType
      | SntPassportAgricultureMinistry.InternalWaterSupplyRepairWorkType
      | SntPassportAgricultureMinistry.OtherWorkType
      | null {
      if (!form || !workType) return null;

      if (!isInternal && workType === SntPassportEnums.ExternalInfrastructureWorkType.RoadRepair) {
        return {
          BasisDocumentName: form.BasisDocumentName.value ? form.BasisDocumentName.value : null,
          Note: form.Note.value ? form.Note.value : null,
          WorkCost: form.WorkCost.value ? parseFloat(form.WorkCost.value) : null,
          SCDF: form.SCDF.value ? parseInt(form.SCDF.value) : null,
          RoadLength: form.RoadLength.value ? parseInt(form.RoadLength.value) : null,
          SurfaceTypeKey: form.SurfaceType.value?.Key ?? null,
          SurfaceTypeKeyOther:
            form.SurfaceType.value?.Key === SntPassportEnums.RoadSurfaceType.Other
              ? form.SurfaceTypeKeyOther.value
              : null,
          WorkScope: form.WorkScope.value ? parseFloat(form.WorkScope.value) : null,
        } as SntPassportAgricultureMinistry.RoadRepairWorkType;
      }

      if (!isInternal && workType === SntPassportEnums.ExternalInfrastructureWorkType.ElectricitySupplyRepair) {
        return {
          BasisDocumentName: form.BasisDocumentName.value ? form.BasisDocumentName.value : null,
          Note: form.Note.value ? form.Note.value : null,
          WorkCost: form.WorkCost.value ? parseFloat(form.WorkCost.value) : null,
          LEPWorkScope: form.LEPWorkScope.value ? parseFloat(form.LEPWorkScope.value) : null,
          KTPWorkScope: form.KTPWorkScope.value ? parseFloat(form.KTPWorkScope.value) : null,
        } as SntPassportAgricultureMinistry.ElectricitySupplyRepairWorkType;
      }

      if (!isInternal && workType === SntPassportEnums.ExternalInfrastructureWorkType.WaterSupplyRepair) {
        return {
          BasisDocumentName: form.BasisDocumentName.value ? form.BasisDocumentName.value : null,
          Note: form.Note.value ? form.Note.value : null,
          WorkCost: form.WorkCost.value ? parseFloat(form.WorkCost.value) : null,
          WellWorkScope: form.WellWorkScope.value ? parseFloat(form.WellWorkScope.value) : null,
          ReservoirWorkScope: form.ReservoirWorkScope.value ? parseFloat(form.ReservoirWorkScope.value) : null,
          PumpWorkScope: form.PumpWorkScope.value ? parseFloat(form.PumpWorkScope.value) : null,
          WaterPipesWorkScope: form.WaterPipesWorkScope.value ? parseFloat(form.WaterPipesWorkScope.value) : null,
        } as SntPassportAgricultureMinistry.WaterSupplyRepairWorkType;
      }

      if (!isInternal && workType === SntPassportEnums.ExternalInfrastructureWorkType.TKORepair) {
        return {
          BasisDocumentName: form.BasisDocumentName.value ? form.BasisDocumentName.value : null,
          Note: form.Note.value ? form.Note.value : null,
          WorkCost: form.WorkCost.value ? parseFloat(form.WorkCost.value) : null,
          TKOAreaWorkScope: form.TKOAreaWorkScope.value ? parseFloat(form.TKOAreaWorkScope.value) : null,
          BunkerWorkScope: form.BunkerWorkScope.value ? parseFloat(form.BunkerWorkScope.value) : null,
          ContainerWorkScope: form.ContainerWorkScope.value ? parseFloat(form.ContainerWorkScope.value) : null,
        } as SntPassportAgricultureMinistry.TKORepairWorkType;
      }

      if (isInternal && workType === SntPassportEnums.InternalInfrastructureWorkType.InnerRoadRepair) {
        return {
          BasisDocumentName: form.BasisDocumentName.value ? form.BasisDocumentName.value : null,
          Note: form.Note.value ? form.Note.value : null,
          WorkCost: form.WorkCost.value ? parseFloat(form.WorkCost.value) : null,
          SurfaceTypeKey: form.SurfaceType.value?.Key ?? null,
          SurfaceTypeKeyOther:
            form.SurfaceType.value?.Key === SntPassportEnums.RoadSurfaceType.Other
              ? form.SurfaceTypeKeyOther.value
              : null,
          WorkScope: form.WorkScope.value ? parseFloat(form.WorkScope.value) : null,
        } as SntPassportAgricultureMinistry.InnerRoadRepairWorkType;
      }

      if (isInternal && workType === SntPassportEnums.InternalInfrastructureWorkType.ElectricitySupplyRepair) {
        return {
          BasisDocumentName: form.BasisDocumentName.value ? form.BasisDocumentName.value : null,
          Note: form.Note.value ? form.Note.value : null,
          WorkCost: form.WorkCost.value ? parseFloat(form.WorkCost.value) : null,
          LEPWorkScope: form.LEPWorkScope.value ? parseFloat(form.LEPWorkScope.value) : null,
          KTPWorkScope: form.KTPWorkScope.value ? parseFloat(form.KTPWorkScope.value) : null,
          ASKUEWorkScope: form.ASKUEWorkScope.value ? parseFloat(form.ASKUEWorkScope.value) : null,
        } as SntPassportAgricultureMinistry.InternalElectricitySupplyRepairWorkType;
      }

      if (isInternal && workType === SntPassportEnums.InternalInfrastructureWorkType.WaterSupplyRepair) {
        return {
          BasisDocumentName: form.BasisDocumentName.value ? form.BasisDocumentName.value : null,
          Note: form.Note.value ? form.Note.value : null,
          WorkCost: form.WorkCost.value ? parseFloat(form.WorkCost.value) : null,
          WorkScope: form.WorkScope.value ? parseFloat(form.WorkScope.value) : null,
        } as SntPassportAgricultureMinistry.InternalWaterSupplyRepairWorkType;
      }

      if (
        workType === SntPassportEnums.ExternalInfrastructureWorkType.Other ||
        workType === SntPassportEnums.InternalInfrastructureWorkType.Other
      ) {
        return {
          Name: form.WorkTypeKeyOther.value ? form.WorkTypeKeyOther.value : null,
          BasisDocumentName: form.BasisDocumentName.value ? form.BasisDocumentName.value : null,
          WorkScope: form.WorkScope.value ? parseFloat(form.WorkScope.value) : null,
          WorkCost: form.WorkCost.value ? parseFloat(form.WorkCost.value) : null,
        } as SntPassportAgricultureMinistry.OtherWorkType;
      }

      return {
        BasisDocumentName: form.BasisDocumentName.value ? form.BasisDocumentName.value : null,
        Note: form.Note.value ? form.Note.value : null,
        WorkScope: form.WorkScope.value ? parseFloat(form.WorkScope.value) : null,
        WorkCost: form.WorkCost.value ? parseFloat(form.WorkCost.value) : null,
      } as SntPassportAgricultureMinistry.WorkTypeBase;
    }

    let ExternalInfrastructure: SntPassportAgricultureMinistry.AgricultureMinistryData["ExternalInfrastructure"] = {
      [SntPassportEnums.ExternalInfrastructureWorkType.ElectricitySupplyRepair]: {
        BasisDocumentName: null,
        WorkCost: null,
        Note: null,
        LEPWorkScope: null,
        KTPWorkScope: null,
      },
      [SntPassportEnums.ExternalInfrastructureWorkType.TKORepair]: {
        BasisDocumentName: null,
        WorkCost: null,
        Note: null,
        TKOAreaWorkScope: null,
        BunkerWorkScope: null,
        ContainerWorkScope: null,
      },
      [SntPassportEnums.ExternalInfrastructureWorkType.WaterSupplyRepair]: {
        BasisDocumentName: null,
        WorkCost: null,
        Note: null,
        WellWorkScope: null,
        ReservoirWorkScope: null,
        PumpWorkScope: null,
        WaterPipesWorkScope: null,
      },
      [SntPassportEnums.ExternalInfrastructureWorkType.RoadRepair]: {
        BasisDocumentName: null,
        WorkCost: null,
        Note: null,
        WorkScope: null,
        RoadLength: null,
        SCDF: null,
        SurfaceTypeKey: null,
        SurfaceTypeKeyOther: null,
      },
      [SntPassportEnums.ExternalInfrastructureWorkType.Other]: {
        BasisDocumentName: null,
        WorkCost: null,
        Note: null,
        WorkScope: null,
        Name: null,
      },
    };
    let InternalInfrastructure: SntPassportAgricultureMinistry.AgricultureMinistryData["InternalInfrastructure"] = {
      [SntPassportEnums.InternalInfrastructureWorkType.ElectricitySupplyRepair]: {
        BasisDocumentName: null,
        WorkCost: null,
        Note: null,
        LEPWorkScope: null,
        KTPWorkScope: null,
        ASKUEWorkScope: null,
      },
      [SntPassportEnums.InternalInfrastructureWorkType.InnerRoadRepair]: {
        BasisDocumentName: null,
        WorkCost: null,
        Note: null,
        WorkScope: null,
        SurfaceTypeKey: null,
        SurfaceTypeKeyOther: null,
      },
      [SntPassportEnums.InternalInfrastructureWorkType.WaterSupplyRepair]: {
        BasisDocumentName: null,
        WorkCost: null,
        Note: null,
        WorkScope: null,
      },
      [SntPassportEnums.InternalInfrastructureWorkType.Other]: {
        BasisDocumentName: null,
        WorkCost: null,
        Note: null,
        WorkScope: null,
        Name: null,
      },
    };

    external.groups.forEach((it) => {
      if (it.WorkType.value?.Key) {
        if (ExternalInfrastructure === null) {
          ExternalInfrastructure = {};
        }
        ExternalInfrastructure[it.WorkType.value.Key as SntPassportEnums.ExternalInfrastructureWorkType] =
          bodyByWorkType(it.WorkType.value.Key, it, false) as any;
      }
    });

    internal.groups.forEach((it) => {
      if (it.WorkType.value?.Key) {
        if (InternalInfrastructure === null) {
          InternalInfrastructure = {};
        }
        InternalInfrastructure[it.WorkType.value.Key as SntPassportEnums.InternalInfrastructureWorkType] =
          bodyByWorkType(it.WorkType.value.Key, it, true) as any;
      }
    });

    return {
      ExternalInfrastructure: ExternalInfrastructure,
      InternalInfrastructure: InternalInfrastructure,
    };
  }

  export class ExternalInfrastructureFormValidator {
    public static isInvalid(_form: ExternalInfrastructureForm, needCheckRequired: boolean = false) {
      const errors = _form.groups.map((form, index) => ({
        WorkType: validateWorkType(form.WorkType, needCheckRequired && form.WorkType.isRequired, _form, index),
        BasisDocumentName: FormValidator.validateByLength(
          form.BasisDocumentName.value,
          needCheckRequired && form.BasisDocumentName.isRequired,
          3,
          50,
          "Необходимо ввести от трех до пятидесяти символов"
        ),
        Note: FormValidator.validateByLength(
          form.Note.value,
          needCheckRequired && form.Note.isRequired,
          3,
          50,
          "Необходимо ввести от трех до пятидесяти символов"
        ),
        WorkCost: FormValidator.getFieldErrorMessage(
          form.WorkCost.value.trim(),
          needCheckRequired && form.WorkCost.isRequired
        ),

        // RoadRepair
        SCDF: FormValidator.validateByLength(
          form.SCDF.value,
          needCheckRequired && form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.RoadRepair,
          3,
          8,
          "Необходимо ввести от трех до восьми символов"
        ),
        RoadLength: FormValidator.validateByLength(
          form.RoadLength.value,
          needCheckRequired && form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.RoadRepair,
          1,
          6,
          "Необходимо ввести от одного до шести символов"
        ),
        SurfaceType: FormValidator.getSelectFieldErrorMessage(
          form.SurfaceType.value,
          needCheckRequired && form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.RoadRepair
        ),
        SurfaceTypeKeyOther: validateSurfaceTypeKeyOther(
          form.SurfaceTypeKeyOther,
          needCheckRequired &&
            form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.RoadRepair &&
            form.SurfaceType.value?.Key === SntPassportEnums.RoadSurfaceType.Other
        ),

        // ElectricitySupplyRepair
        LEPWorkScope: validateWorkScope(
          form.LEPWorkScope,
          [form.LEPWorkScope, form.KTPWorkScope],
          needCheckRequired &&
            form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.ElectricitySupplyRepair
        ),
        KTPWorkScope: validateWorkScope(
          form.KTPWorkScope,
          [form.LEPWorkScope, form.KTPWorkScope],
          needCheckRequired &&
            form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.ElectricitySupplyRepair
        ),

        // WaterSupplyRepair
        WellWorkScope: validateWorkScope(
          form.WellWorkScope,
          [form.WellWorkScope, form.ReservoirWorkScope, form.PumpWorkScope, form.WaterPipesWorkScope],
          needCheckRequired &&
            form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.WaterSupplyRepair
        ),
        ReservoirWorkScope: validateWorkScope(
          form.ReservoirWorkScope,
          [form.WellWorkScope, form.ReservoirWorkScope, form.PumpWorkScope, form.WaterPipesWorkScope],
          needCheckRequired &&
            form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.WaterSupplyRepair
        ),
        PumpWorkScope: validateWorkScope(
          form.PumpWorkScope,
          [form.WellWorkScope, form.ReservoirWorkScope, form.PumpWorkScope, form.WaterPipesWorkScope],
          needCheckRequired &&
            form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.WaterSupplyRepair
        ),
        WaterPipesWorkScope: validateWorkScope(
          form.WaterPipesWorkScope,
          [form.WellWorkScope, form.ReservoirWorkScope, form.PumpWorkScope, form.WaterPipesWorkScope],
          needCheckRequired &&
            form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.WaterSupplyRepair
        ),

        // TKORepair
        TKOAreaWorkScope: validateWorkScope(
          form.TKOAreaWorkScope,
          [form.TKOAreaWorkScope, form.BunkerWorkScope, form.ContainerWorkScope],
          needCheckRequired && form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.TKORepair
        ),
        BunkerWorkScope: validateWorkScope(
          form.BunkerWorkScope,
          [form.TKOAreaWorkScope, form.BunkerWorkScope, form.ContainerWorkScope],
          needCheckRequired && form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.TKORepair
        ),
        ContainerWorkScope: validateWorkScope(
          form.ContainerWorkScope,
          [form.TKOAreaWorkScope, form.BunkerWorkScope, form.ContainerWorkScope],
          needCheckRequired && form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.TKORepair
        ),

        // Other
        WorkTypeKeyOther: FormValidator.validateByLength(
          form.WorkTypeKeyOther.value,
          needCheckRequired && form.WorkType.value?.Key === SntPassportEnums.ExternalInfrastructureWorkType.Other,
          3,
          50,
          "Необходимо ввести от трех до пятидесяти символов"
        ),

        // RoadRepair, Other
        WorkScope: validateWorkScope(
          form.WorkScope,
          [form.WorkScope],
          needCheckRequired &&
            !!form.WorkType.value?.Key &&
            [
              SntPassportEnums.ExternalInfrastructureWorkType.RoadRepair.toString(),
              SntPassportEnums.ExternalInfrastructureWorkType.Other.toString(),
            ].includes(form.WorkType.value.Key)
        ),
      }));

      if (errors.some((it) => it && Object.values(it).some((y) => !!y))) return errors;

      return false;
    }
  }

  export class InternalInfrastructureFormValidator {
    public static isInvalid(_form: InternalInfrastructureForm, needCheckRequired: boolean = false) {
      const errors = _form.groups.map((form, index) => ({
        WorkType: validateWorkType(form.WorkType, needCheckRequired && form.WorkType.isRequired, _form, index),
        BasisDocumentName: FormValidator.validateByLength(
          form.BasisDocumentName.value,
          needCheckRequired && form.BasisDocumentName.isRequired,
          3,
          50,
          "Необходимо ввести от трех до пятидесяти символов"
        ),
        Note: FormValidator.validateByLength(
          form.Note.value,
          needCheckRequired && form.Note.isRequired,
          3,
          50,
          "Необходимо ввести от трех до пятидесяти символов"
        ),
        WorkCost: FormValidator.getFieldErrorMessage(
          form.WorkCost.value.trim(),
          needCheckRequired && form.WorkCost.isRequired
        ),

        // InnerRoadRepair
        SurfaceType: FormValidator.getSelectFieldErrorMessage(
          form.SurfaceType.value,
          needCheckRequired &&
            form.WorkType.value?.Key === SntPassportEnums.InternalInfrastructureWorkType.InnerRoadRepair
        ),
        SurfaceTypeKeyOther: validateSurfaceTypeKeyOther(
          form.SurfaceTypeKeyOther,
          needCheckRequired &&
            form.WorkType.value?.Key === SntPassportEnums.InternalInfrastructureWorkType.InnerRoadRepair &&
            form.SurfaceType.value?.Key === SntPassportEnums.RoadSurfaceType.Other
        ),

        // ElectricitySupplyRepair
        LEPWorkScope: validateWorkScope(
          form.LEPWorkScope,
          [form.LEPWorkScope, form.KTPWorkScope, form.ASKUEWorkScope],
          needCheckRequired &&
            form.WorkType.value?.Key === SntPassportEnums.InternalInfrastructureWorkType.ElectricitySupplyRepair
        ),
        KTPWorkScope: validateWorkScope(
          form.KTPWorkScope,
          [form.LEPWorkScope, form.KTPWorkScope, form.ASKUEWorkScope],
          needCheckRequired &&
            form.WorkType.value?.Key === SntPassportEnums.InternalInfrastructureWorkType.ElectricitySupplyRepair
        ),
        ASKUEWorkScope: validateWorkScope(
          form.ASKUEWorkScope,
          [form.LEPWorkScope, form.KTPWorkScope, form.ASKUEWorkScope],
          needCheckRequired &&
            form.WorkType.value?.Key === SntPassportEnums.InternalInfrastructureWorkType.ElectricitySupplyRepair
        ),

        // Other
        WorkTypeKeyOther: FormValidator.validateByLength(
          form.WorkTypeKeyOther.value,
          needCheckRequired && form.WorkType.value?.Key === SntPassportEnums.InternalInfrastructureWorkType.Other,
          3,
          50,
          "Необходимо ввести от трех до пятидесяти символов"
        ),

        // InnerRoadRepair, WaterSupplyRepair, Other
        WorkScope: validateWorkScope(
          form.WorkScope,
          [form.WorkScope],
          needCheckRequired &&
            !!form.WorkType.value?.Key &&
            [
              SntPassportEnums.InternalInfrastructureWorkType.InnerRoadRepair.toString(),
              SntPassportEnums.InternalInfrastructureWorkType.WaterSupplyRepair.toString(),
              SntPassportEnums.InternalInfrastructureWorkType.Other.toString(),
            ].includes(form.WorkType.value.Key)
        ),
      }));

      if (errors.some((it) => it && Object.values(it).some((y) => !!y))) return errors;

      return false;
    }
  }

  function validateWorkType(
    field: WorkTypeBaseForm["WorkType"],
    isRequired: boolean,
    form: ExternalInfrastructureForm,
    index: number
  ) {
    let errorMessage = FormValidator.getSelectFieldErrorMessage(field.value, isRequired);

    const sameWorkType = form.groups.slice(0, index).find((it, i) => it.WorkType.value?.Key === field.value?.Key);
    if (sameWorkType) {
      return "Вид работ не должен повторяться";
    }

    return errorMessage;
  }

  function validateSurfaceTypeKeyOther(field: WorkTypeBaseForm["SurfaceTypeKeyOther"], isRequired: boolean) {
    let errorMessage = FormValidator.validateByLength(
      field.value,
      isRequired,
      3,
      50,
      "Необходимо ввести от трех до пятидесяти символов"
    );

    const regex = /^[\p{Script=Cyrillic} ]+$/u;
    if (
      !errorMessage &&
      (isRequired || (!isRequired && field.value.trim().length > 0)) &&
      !regex.test(field.value.trim())
    ) {
      return "Неверный формат. Покрытие должно содержать только кириллицу";
    }

    return errorMessage;
  }

  function validateWorkScope(field: FormFiled, workScopeFields: Array<FormFiled>, needCheckRequired: boolean) {
    const int = parseInt(field.value);
    let errorMessage = FormValidator.validateByLength(
      int.toString(),
      field.isRequired && needCheckRequired,
      1,
      8,
      "Доступен ввод от одного до восьми символов целого числа и до трех символов после запятой"
    );

    if (needCheckRequired && !errorMessage && workScopeFields.every((it) => it.value.length === 0)) {
      return "Должно быть заполнено хотя бы одно поле для ввода показателей по объемам";
    }

    return errorMessage;
  }
}
