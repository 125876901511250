import { useCallback, useState } from "react";
import { OCC, Profile } from "@/types";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
import BaseTooltip from "@/components/base-tooltip";
import { notGardeningMembers } from "@/consts/icons";
import { selectQuorumById } from "@/app/store/slices/dictionary";
import { RootState } from "@/app/store";

const cx = classNames.bind(styles);

interface IOccQuestionItemProps {
  question: OCC.Question;
  onSelect?: () => void;
}

const OccQuestionItem: React.FC<IOccQuestionItemProps> = ({ question, onSelect }) => {
  const [isContentMultiline, setContentMultiline] = useState<boolean>(false);
  const company = useSelector((state: RootState) => state.user.company);
  const quorum = useSelector(selectQuorumById(question.QuorumId));

  const onRefChange = useCallback((node: HTMLParagraphElement) => {
    setContentMultiline(node && node.offsetHeight > 20);
  }, []);

  const handleSelect = () => {
    if (onSelect) {
      onSelect();
    }
  };

  return (
    <div className={cx({ question_item: true, selectable: onSelect })} onClick={handleSelect}>
      <div className={styles.question_item__header}>
        <h4 className="headline-h4 sf-text-bold">Вопрос {question.Order}.</h4>
        <div className="flex middle">
          <span className="text--primary sf-text-regular">{quorum?.name}</span>
          {company?.Type === Profile.CompanyType.SNT && !question.OnlyHorticultureMembers && (
            <BaseTooltip title="Голосование доступно для собственников, не являющихся членами садоводства">
              <span className="flex">{notGardeningMembers}</span>
            </BaseTooltip>
          )}
        </div>
      </div>

      {question.Files.length > 0 && (
        <div className={styles.question_item__files}>
          <div className="flex middle">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clipPath="url(#clip0_2124_10693)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.5 14C2.5 14.2761 2.72386 14.5 3 14.5H13C13.2761 14.5 13.5 14.2761 13.5 14V5.5H10.5C9.94772 5.5 9.5 5.05228 9.5 4.5V1.5H3C2.72386 1.5 2.5 1.72386 2.5 2V14ZM15 14V5.5V5.32843C15 4.79799 14.7893 4.28929 14.4142 3.91421L11.0858 0.585786C10.7107 0.210714 10.202 0 9.67157 0H9.5H3C1.89543 0 1 0.895431 1 2V14C1 15.1046 1.89543 16 3 16H13C14.1046 16 15 15.1046 15 14ZM4.75 5C4.33579 5 4 5.33579 4 5.75C4 6.16421 4.33579 6.5 4.75 6.5H7.25C7.66421 6.5 8 6.16421 8 5.75C8 5.33579 7.66421 5 7.25 5H4.75ZM4 8.75C4 8.33579 4.33579 8 4.75 8H11.25C11.6642 8 12 8.33579 12 8.75C12 9.16421 11.6642 9.5 11.25 9.5H4.75C4.33579 9.5 4 9.16421 4 8.75ZM4.75 11C4.33579 11 4 11.3358 4 11.75C4 12.1642 4.33579 12.5 4.75 12.5H11.25C11.6642 12.5 12 12.1642 12 11.75C12 11.3358 11.6642 11 11.25 11H4.75Z"
                  fill="#3A3B3D"
                />
              </g>
              <defs>
                <clipPath id="clip0_2124_10693">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="text--primary sf-text-regular">
              Приложенный файл {question.Files.length > 1 && `(${question.Files.length})`}
            </span>
          </div>
        </div>
      )}

      <p className={`text--primary sf-text-semibold ${styles.question_item__title}`}>{question.Title}</p>
      <p
        ref={onRefChange}
        className={`text--primary sf-text-regular ${styles.question_item__content} ${
          isContentMultiline ? styles.question_item__content_multiline : ""
        }`}
      >
        {question.Value}
      </p>
    </div>
  );
};

export default OccQuestionItem;
