import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router";
import { useSelector } from "react-redux";
import { SntPassport, SntPassportBoard, SntPassportEnums } from "@/types";
import { SntPassportService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import { fetchSntPassportDictionaries } from "@/app/store/slices/snt/passport";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import SntPassportContent from "@/components/SntPassport/Content";
import SntPassportBlockWrapper from "@/components/SntPassport/BlockWrapper";
import SntPassportBlockContentReadonly from "@/components/SntPassport/BlockContent/Readonly";
import { Spinner } from "@/components/spinner";
import styles from "./styles.module.scss";

const InstitutionSntItemBoard: React.FC = () => {
  const urlParams = useParams();
  const dispatch = useAppDispatch();

  const { sntData } = useOutletContext<{ sntData: SntPassport.InstitutionSntItem }>();
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [boardData, setBoardData] = useState<SntPassportBoard.BoardDataResponse>();
  const { electricitySupplyTypesList, waterSupplyTypesList, roadSurfaceTypesList, associationsList } = useSelector(
    (state: RootState) => state.sntPassport
  );

  async function fetchBoardData() {
    if (!urlParams.sntId) {
      setIsLoading(false);
      return;
    }

    try {
      await dispatch(fetchSntPassportDictionaries()).unwrap();
      const { data } = await SntPassportService.getBoardData(parseInt(urlParams.sntId));
      setBoardData(data.Data);
      return data.Data.Form;
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchBoardData();
  }, []);

  if (isLoading) {
    return (
      <div
        ref={emptyBlockRef}
        className="center"
        style={{ display: "flex", alignItems: "center", height: `${emptyBlockHeight}px`, minHeight: "350px" }}
      >
        <Spinner size={36} />
      </div>
    );
  }

  if (boardData?.Form && boardData?.PublicationDatetime) {
    return (
      <div className={styles["p-institution-snt-board"]}>
        {boardData.PublicationDatetime && (
          <div className={styles["p-institution-snt-board__publication"]}>
            <p className="text--primary color--text-secondary sf-text-regular">
              Последнее обновление {new Date(boardData.PublicationDatetime.parseFromEpochSeconds()).formatDateTime()}
            </p>
          </div>
        )}

        <SntPassportContent>
          <SntPassportBlockWrapper title={sntData?.Name ?? ""}>
            <div>
              <SntPassportBlockContentReadonly>
                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Год основания:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form.Details.FoundationYear ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Дата государственной регистрации:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form.Details.RegistrationDate ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">ИНН:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form.Details.INN ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Район:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form.Details.MunicipalDistrict ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Наличие членства в ассоциации:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form.Details.AssociationMembership ? "Да" : "Нет"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Ассоциация:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form.Details.AssociationId
                      ? associationsList.find((it) => it.Id === boardData?.Form.Details.AssociationId)?.Name ?? "–"
                      : "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">
                    Кадастровый номер земель общего пользования:
                  </h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form.Details.CadastralNumber ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">ФИО председателя:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form.Details.ChairmanName ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Контактный телефон председателя:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form.Details.ChairmanPhone ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Электронная почта председателя:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form.Details.ChairmanEmail ?? "–"}
                  </p>
                </div>
              </SntPassportBlockContentReadonly>
            </div>
          </SntPassportBlockWrapper>

          <SntPassportBlockWrapper title="Количественные показатели">
            <div>
              <SntPassportBlockContentReadonly>
                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">
                    Общая площадь территории садоводства (м2):
                  </h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.QuantitativeData?.TotalArea?.formatRU() ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">
                    Площадь земель общего пользования (м2):
                  </h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.QuantitativeData?.PublicArea?.formatRU() ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Площадь участков садоводов (м2):</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.QuantitativeData?.SectionsArea?.formatRU() ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Общее количество участков:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.QuantitativeData?.TotalSectionsCount?.formatRU() ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Количество используемых участков:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.QuantitativeData?.UsedSectionsCount?.formatRU() ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Количество неиспользуемых участков:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.QuantitativeData?.UnUsedSectionsCount?.formatRU() ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">
                    Количество участков зарегистрированных в собственность:
                  </h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.QuantitativeData?.RegisteredSectionsCount?.formatRU() ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Количество домов признанных жилыми:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.QuantitativeData?.ResidentialBuildingsCount?.formatRU() ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">
                    Количество зарегистрированных в садоводстве граждан:
                  </h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.QuantitativeData?.RegisteredCitizensCount?.formatRU() ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">
                    Совокупный размер всех видов взносов за 1 сотку:
                  </h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.QuantitativeData?.ContributionsAggregateAmount?.formatCurrency() ?? "–"}
                  </p>
                </div>
              </SntPassportBlockContentReadonly>
            </div>
          </SntPassportBlockWrapper>

          <SntPassportBlockWrapper title="Инфраструктура">
            <div>
              <h4 className="headline-h4 sf-text-bold color--text-primary">Электроснабжение</h4>
              <SntPassportBlockContentReadonly>
                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Наличие электроснабжения:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.ElectricitySupply?.IsAvailable
                      ? "Да"
                      : boardData?.Form?.Infrastructure?.ElectricitySupply?.IsAvailable === false
                      ? "Нет"
                      : "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Электросети:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.ElectricitySupply?.TypeKey
                      ? electricitySupplyTypesList.find(
                          (it) => it.Key === boardData?.Form?.Infrastructure?.ElectricitySupply?.TypeKey
                        )?.Value ?? "–"
                      : "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Наличие КТП:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.ElectricitySupply?.CTS
                      ? "Да"
                      : boardData?.Form?.Infrastructure?.ElectricitySupply?.CTS === false
                      ? "Нет"
                      : "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Длина ЛЭП:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.ElectricitySupply?.PowerLineLength ?? "–"}
                  </p>
                </div>
              </SntPassportBlockContentReadonly>
            </div>

            <div>
              <h4 className="headline-h4 sf-text-bold color--text-primary">Водоснабжение</h4>
              <SntPassportBlockContentReadonly>
                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Наличие водоснабжения:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.WaterSupply?.IsAvailable
                      ? "Да"
                      : boardData?.Form?.Infrastructure?.WaterSupply?.IsAvailable === false
                      ? "Нет"
                      : "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Источник водоснабжения:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.WaterSupply?.TypeKey
                      ? waterSupplyTypesList
                          .filter((it) => boardData?.Form?.Infrastructure?.WaterSupply?.TypeKey?.includes(it.Key))
                          ?.map((it) => it.Value)
                          .join(", ") ?? "–"
                      : "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">
                    Номер лицензии водоснабжения, год выдачи:
                  </h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.WaterSupply?.License ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Количество скважин:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.WaterSupply?.WaterWellCount ?? "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">
                    Наличие сезонного пожарного водоснабжения:
                  </h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.WaterSupply?.SeasonalFireWaterSupply
                      ? "Да"
                      : boardData?.Form?.Infrastructure?.WaterSupply?.SeasonalFireWaterSupply === false
                      ? "Нет"
                      : "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">
                    Наличие круглогодичного пожарного водоснабжения:
                  </h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.WaterSupply?.YearRoundFireWaterSupply
                      ? "Да"
                      : boardData?.Form?.Infrastructure?.WaterSupply?.YearRoundFireWaterSupply === false
                      ? "Нет"
                      : "–"}
                  </p>
                </div>
              </SntPassportBlockContentReadonly>
            </div>

            <div>
              <h4 className="headline-h4 sf-text-bold color--text-primary">Газоснабжение</h4>
              <SntPassportBlockContentReadonly>
                <div className="col-3-field">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Газификация садоводства:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.GasSupply?.IsAvailable
                      ? "Да"
                      : boardData?.Form?.Infrastructure?.GasSupply?.IsAvailable === false
                      ? "Нет"
                      : "–"}
                  </p>
                </div>

                <div className="col-3-field">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Год ввода в эксплуатацию:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.GasSupply?.CommissioningYear ?? "–"}
                  </p>
                </div>

                <div className="col-3-field">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">
                    Количество подкл.домов к сетевому газу:
                  </h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.GasSupply?.ConnectedBuildingsCount ?? "–"}
                  </p>
                </div>
              </SntPassportBlockContentReadonly>
            </div>

            <div>
              <h4 className="headline-h4 sf-text-bold color--text-primary">ТКО</h4>
              <SntPassportBlockContentReadonly>
                <div className="col-3-field">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Площадки для сбора ТКО (кол-во):</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.TKO?.WasteCollectionSitesCount ?? "–"}
                  </p>
                </div>

                <div className="col-3-field">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Бункер (кол-во):</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.TKO?.HoppersCount ?? "–"}
                  </p>
                </div>

                <div className="col-3-field">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Контейнер (кол-во):</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.TKO?.ContainersCount ?? "–"}
                  </p>
                </div>
              </SntPassportBlockContentReadonly>
            </div>

            <div>
              <h4 className="headline-h4 sf-text-bold color--text-primary">Внешняя дорога к садоводству</h4>
              <SntPassportBlockContentReadonly>
                <div className="col-3-field">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Год постройки:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.SectionOuterRoad?.CommissioningYear ?? "–"}
                  </p>
                </div>

                <div className="col-3-field">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Изношенность покрытия, %:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.SectionOuterRoad?.SurfaceWorn ?? "–"}
                  </p>
                </div>

                <div className="col-3-field">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">
                    Расстояние до ост. общ-го транспорта (км):
                  </h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.SectionOuterRoad?.PublicTransportStopsDistance ?? "–"}
                  </p>
                </div>
              </SntPassportBlockContentReadonly>
            </div>

            <div>
              <h4 className="headline-h4 sf-text-bold color--text-primary">Внутренние дороги</h4>
              <SntPassportBlockContentReadonly>
                <div className="col-3-field">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Протяженность (м, км):</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.SectionInnerRoad?.Length ?? "–"}
                  </p>
                </div>

                <div className="col-3-field">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Ширина аллей:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.SectionInnerRoad?.AlleyWidth ?? "–"}
                  </p>
                </div>

                <div className="col-3-field">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Покрытие:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.SectionInnerRoad?.SurfaceTypeKey ===
                      SntPassportEnums.RoadSurfaceType.Other &&
                    boardData?.Form?.Infrastructure?.SectionInnerRoad?.SurfaceTypeKeyOther
                      ? boardData?.Form?.Infrastructure?.SectionInnerRoad?.SurfaceTypeKeyOther
                      : boardData?.Form?.Infrastructure?.SectionInnerRoad?.SurfaceTypeKey
                      ? roadSurfaceTypesList.find(
                          (it) => it.Key === boardData?.Form?.Infrastructure?.SectionInnerRoad?.SurfaceTypeKey
                        )?.Value ?? "–"
                      : "–"}
                  </p>
                </div>
              </SntPassportBlockContentReadonly>
            </div>

            <div>
              <h4 className="headline-h4 sf-text-bold color--text-primary">Дополнительная информация</h4>
              <SntPassportBlockContentReadonly>
                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Оптоволоконная сеть (Интернет):</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.Additional?.FiberOptic
                      ? "Да"
                      : boardData?.Form?.Infrastructure?.Additional?.FiberOptic === false
                      ? "Нет"
                      : "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Наличие помещения охраны:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.Additional?.SecurityRoom
                      ? "Да"
                      : boardData?.Form?.Infrastructure?.Additional?.SecurityRoom === false
                      ? "Нет"
                      : "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Наличие систем контроля доступа:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.Additional?.AccessControlSystems
                      ? "Да"
                      : boardData?.Form?.Infrastructure?.Additional?.AccessControlSystems === false
                      ? "Нет"
                      : "–"}
                  </p>
                </div>

                <div>
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Наличие систем видеонаблюдения:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.Additional?.VideoSurveillanceSystems
                      ? "Да"
                      : boardData?.Form?.Infrastructure?.Additional?.VideoSurveillanceSystems === false
                      ? "Нет"
                      : "–"}
                  </p>
                </div>

                <div className="full-field">
                  <h4 className="headline-h4 sf-text-bold color--text-primary">Наличие ограждения:</h4>
                  <p className="text--primary sf-text-regular color--text-secondary">
                    {boardData?.Form?.Infrastructure?.Additional?.Fence
                      ? "Да"
                      : boardData?.Form?.Infrastructure?.Additional?.Fence === false
                      ? "Нет"
                      : "–"}
                  </p>
                </div>
              </SntPassportBlockContentReadonly>
            </div>
          </SntPassportBlockWrapper>
        </SntPassportContent>
      </div>
    );
  }

  return (
    <div
      ref={emptyBlockRef}
      className="center"
      style={{ display: "flex", alignItems: "center", height: `${emptyBlockHeight}px`, minHeight: "350px" }}
    >
      <p className="text--primary sf-pro-regular color--text-primary">Данных от правления садоводства пока нет</p>
    </div>
  );
};

export default InstitutionSntItemBoard;
