import { useContext, useMemo } from "react";
import { UKRegistry } from "@/types";
import AccordionContext from "@/components/base-accordion/context";
import BaseButton from "@/components/base-button";
import ApartmentСomparisonAccordionCard from "../AccordionCard";
import { RegistryDetailsTableCard, RegistryDetailsTableGroup } from "../RegistryDetailsTableCard";
import styles from "../styles.module.scss";

interface IApartmentDetailsComparisonProps {
  comparison: UKRegistry.RosreestrСomparisonDetailsMapped["apartment"];
  updateData: (key: keyof UKRegistry.ApartmentСomparison, updating: boolean) => void;
  onAdd: () => void;
  onRemove: () => void;
}

const ApartmentDetailsComparison: React.FC<IApartmentDetailsComparisonProps> = ({
  comparison,
  updateData,
  onAdd,
  onRemove,
}) => {
  const context = useContext(AccordionContext);

  const successText: string = useMemo(() => {
    if (!comparison.initial.Reestr && comparison.initial.Rosreestr && comparison.isCreated) {
      return "Добавлено";
    }

    if (comparison.initial.Reestr && comparison.initial.Rosreestr && comparison.initial.Differences.length === 0) {
      return "Расхождений нет";
    }

    return "";
  }, [comparison]);

  const errorText: string = useMemo(() => {
    if (!comparison.initial.Reestr && comparison.initial.Rosreestr && !comparison.isCreated) {
      return "Помещение отсутствует в вашем реестре";
    }

    if (comparison.initial.Reestr && !comparison.initial.Rosreestr) {
      return comparison.isDeleted ? "Удалено" : "Помещение отсутствует в Росреестре";
    }

    if (comparison.initial.Reestr && comparison.initial.Rosreestr && comparison.initial.Differences.length > 0) {
      return "Расхождения с данными Росреестра";
    }

    return "";
  }, [comparison]);

  function handleUpdateData(key: keyof UKRegistry.ApartmentСomparison, updating: boolean) {
    if (updateData) {
      updateData(key, updating);
    }
  }

  function addToMyReestr(event: React.SyntheticEvent) {
    event.stopPropagation();
    if (!comparison.isCreated) {
      context?.handleOpen("");
    }

    onAdd();
  }

  function removeFromMyReestr(event: React.SyntheticEvent) {
    event.stopPropagation();
    if (!comparison.isDeleted) {
      context?.handleOpen("");
    }

    onRemove();
  }

  return (
    <ApartmentСomparisonAccordionCard
      title={`Помещение №${comparison.initial.Number ?? "–"}`}
      id="apartment"
      succesText={successText}
      errorText={errorText}
      togglerPrefix="данные помещения"
      actionButton={
        comparison.initial.Reestr && !comparison.initial.Rosreestr ? (
          comparison.isDeleted ? (
            <BaseButton size="medium" onClick={removeFromMyReestr}>
              Вернуть
            </BaseButton>
          ) : (
            <BaseButton size="medium" onClick={removeFromMyReestr}>
              Удалить помещение из моего реестра
            </BaseButton>
          )
        ) : !comparison.initial.Reestr && comparison.initial.Rosreestr ? (
          comparison.isCreated ? (
            <BaseButton size="medium" onClick={addToMyReestr}>
              Не добавлять
            </BaseButton>
          ) : (
            <BaseButton size="medium" onClick={addToMyReestr}>
              Добавить
            </BaseButton>
          )
        ) : null
      }
    >
      <div className={styles.accordion_content}>
        <h5 className="headline-h5 sf-text-medium color--text-secondary">Данные помещения:</h5>
        <div>
          {comparison.value && (
            <RegistryDetailsTableCard title="В моем реестре:">
              <RegistryDetailsTableGroup
                title="Площадь (м²)"
                value={comparison.value.Area ?? "–"}
                isHasDifference={
                  comparison.initial.Differences.includes("Area") &&
                  comparison.initial.Reestr?.Area !== comparison.initial.Rosreestr?.Area
                }
                comparison={comparison.initial.Rosreestr?.Area}
                toggleUpdate={(updating) => handleUpdateData("Area", updating)}
              />
              <RegistryDetailsTableGroup
                title="Кадастровый номер:"
                value={comparison.value.CadastralNumber ?? "–"}
                isHasDifference={
                  comparison.initial.Differences.includes("CadastralNumber") &&
                  comparison.initial.Reestr?.CadastralNumber !== comparison.initial.Rosreestr?.CadastralNumber
                }
                comparison={comparison.initial.Rosreestr?.CadastralNumber}
                toggleUpdate={(updating) => handleUpdateData("CadastralNumber", updating)}
              />
              <RegistryDetailsTableGroup
                title="Тип"
                value={comparison.value.Type ?? "–"}
                isHasDifference={
                  comparison.initial.Differences.includes("Type") &&
                  comparison.initial.Reestr?.Type !== comparison.initial.Rosreestr?.Type
                }
                comparison={comparison.initial.Rosreestr?.Type}
                toggleUpdate={(updating) => handleUpdateData("Type", updating)}
              />
            </RegistryDetailsTableCard>
          )}

          {comparison.initial.Rosreestr && (
            <RegistryDetailsTableCard title="В Росреестре:">
              <RegistryDetailsTableGroup title="Площадь (м²)" value={comparison.initial.Rosreestr.Area ?? "–"} />
              <RegistryDetailsTableGroup
                title="Кадастровый номер:"
                value={comparison.initial.Rosreestr.CadastralNumber ?? "–"}
              />
              <RegistryDetailsTableGroup title="Тип" value={comparison.initial.Rosreestr.Type ?? "–"} />
            </RegistryDetailsTableCard>
          )}
        </div>
      </div>
    </ApartmentСomparisonAccordionCard>
  );
};

export default ApartmentDetailsComparison;
