import styles from "./styles.module.scss";

type ISntPassportBlockWrapperProps = {
  title: string;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
  headerAction?: JSX.Element | JSX.Element[] | React.ReactNode;
};

const SntPassportBlockWrapper: React.FC<ISntPassportBlockWrapperProps> = ({ title, children, headerAction }) => {
  return (
    <div className={styles["b-snt-passport-block"]}>
      <div className={styles["b-snt-passport-block__header"]}>
        <h3 className="headline-h3 sf-bold color--primary">{title}</h3>
        {headerAction}
      </div>
      {children ? <div className={styles["b-snt-passport-block__content"]}>{children}</div> : null}
    </div>
  );
};

export default SntPassportBlockWrapper;
