import { isValidPhoneNumber } from "libphonenumber-js";
import parseMax from "libphonenumber-js/max";
import { isValidEmail } from "../helpers/validation";
import errorMessages from "../helpers/errorMessages.json";

export class FormFiled {
  value: string = "";
  error: string = "";
  isRequired: boolean = false;
  isDisabled: boolean = false;
  maxlength: number | null = null;
  minlength: number | null = null;

  constructor(
    value?: string,
    isRequired: boolean = false,
    isDisabled: boolean = false,
    maxlength: number | null = null,
    minlength: number | null = null
  ) {
    this.value = value || "";
    this.isRequired = isRequired;
    this.isDisabled = isDisabled;
    this.maxlength = maxlength;
    this.minlength = minlength;
  }
}

export class SelectField<T> {
  value: T | null = null;
  error: string = "";
  isRequired: boolean = false;
  isDisabled: boolean = false;

  constructor(value?: T | null, isRequired: boolean = false, isDisabled: boolean = false) {
    this.value = value ?? null;
    this.isRequired = isRequired;
    this.isDisabled = isDisabled;
  }
}

export class MultipleSelectField<T> {
  value: Array<T> = [];
  error: string = "";
  isRequired: boolean = false;
  isDisabled: boolean = false;

  constructor(value?: Array<T>, isRequired: boolean = false, isDisabled: boolean = false) {
    this.value = value ?? [];
    this.isRequired = isRequired;
    this.isDisabled = isDisabled;
  }
}

export class DatepickerField {
  value: Date | null = null;
  error: string = "";
  isRequired: boolean = false;
  isDisabled: boolean = false;
  minDate: Date | null = null;
  maxDate: Date | null = null;

  constructor({
    value,
    isRequired = false,
    isDisabled = false,
    minDate,
    maxDate,
  }: {
    value?: Date;
    isRequired?: boolean;
    isDisabled?: boolean;
    minDate?: Date;
    maxDate?: Date;
  }) {
    this.value = value ?? null;
    this.isRequired = isRequired;
    this.isDisabled = isDisabled;
    this.minDate = minDate ?? null;
    this.maxDate = maxDate ?? null;
  }
}

export class CheckboxField {
  value: boolean | null = null;
  error: string = "";
  isRequired: boolean = false;
  isDisabled: boolean = false;

  constructor(value?: boolean | null, isRequired: boolean = false, isDisabled: boolean = false) {
    this.value = value ?? null;
    this.isRequired = isRequired;
    this.isDisabled = isDisabled;
  }
}

export class Form {
  error: string = "";
  isLoading: boolean = false;
}

export class FormValidator {
  public static getFieldErrorMessage(value: string, isRequired: boolean = false) {
    if (!isRequired && !value) return "";

    if (!value) {
      return errorMessages.empty;
    }

    return "";
  }

  public static getEmailErrorMessage(email: string, isRequired: boolean = false) {
    if (!isRequired && !email) return "";

    if (!email) {
      return errorMessages.empty;
    }

    if (email?.length > 254) {
      return errorMessages.email.invalid;
    }

    if (!isValidEmail(email)) {
      return errorMessages.email.invalid;
    }

    const parts = email.split("@");
    if (parts[0].length > 64) {
      return errorMessages.email.invalid;
    }

    const domainParts = parts[1].split(".");
    if (domainParts.some((part) => part.length > 63)) {
      return errorMessages.email.invalid;
    }

    return "";
  }

  public static getPhoneErrorMessage(phone: string, isRequired: boolean = false) {
    if (!isRequired && !phone) return "";

    if (!phone) {
      return errorMessages.empty;
    }

    const possibleTypes = ["FIXED_LINE_OR_MOBILE", "FIXED_LINE", "MOBILE"];
    const type = parseMax(phone)?.getType();
    if (!(isValidPhoneNumber(phone, "RU") && type && possibleTypes.includes(type))) {
      return errorMessages.phone.invalid;
    }

    return "";
  }

  public static getWebsiteErrorMessage(website: string, isRequired: boolean = false) {
    if (!isRequired && !website) return "";

    if (!website) {
      return errorMessages.empty;
    }

    const pattern = new RegExp(
      "^([a-zA-Z]+:\\/\\/)?" + // protocol
        "((([a-z\\d\\u0400-\\u04FF]([a-z\\d-\\u0400-\\u04FF]*[a-z\\d\\u0400-\\u04FF])*)\\.)+[a-z\\u0400-\\u04FF]{2,}|" + // domain name with Cyrillic support
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+\\u0400-\\u04FF]*)*" + // path with Cyrillic support
        "(\\?[;&a-z\\d%_.~+=-\\u0400-\\u04FF]*)?" + // query string with Cyrillic support
        "(\\#[-a-z\\d_\\u0400-\\u04FF]*)?$", // fragment locator with Cyrillic support
      "i"
    );

    if (!pattern.test(website)) {
      return errorMessages.website.invalid;
    }

    return "";
  }

  public static validateCadastralNumber(
    cadastr: string,
    isRequired: boolean = false,
    type: "section" | "house" | "default" = "section"
  ) {
    if (!isRequired && !cadastr) return "";

    if (!cadastr) {
      return errorMessages.empty;
    }

    const regex = /^\d{2}:\d{2}:\d{6,7}:\d{1,4}$/gm;
    if ((isRequired || (!isRequired && cadastr.trim().length > 0)) && !regex.test(cadastr.trim())) {
      return errorMessages.cadastralNumber.invalid[type];
    }

    return "";
  }

  public static getCheckboxFieldErrorMessage(value: CheckboxField["value"], isRequired: boolean = false) {
    if (isRequired && value === null) return errorMessages.empty;

    return "";
  }

  public static getSelectFieldErrorMessage<T>(value: SelectField<T>["value"], isRequired: boolean = false) {
    if (isRequired && value === null) return errorMessages.empty;

    return "";
  }

  public static validateByLength(
    value: FormFiled["value"],
    isRequired: boolean = false,
    minLength: number,
    maxLength: number,
    limitsErrorMessage: string
  ) {
    if (isRequired && value.length === 0) return errorMessages.empty;

    if (value.length > 0 && (value.length < minLength || value.length > maxLength)) {
      return limitsErrorMessage;
    }

    return "";
  }

  public static validateCyrillicField(
    value: FormFiled["value"],
    isRequired: boolean = false,
    errorMessage: string = "Поле может содержать только кириллицу"
  ) {
    const regex = /^[\p{Script=Cyrillic}\s-]*$/u;

    if ((isRequired || (!isRequired && value.trim().length > 0)) && !regex.test(value.trim())) {
      return errorMessage;
    }

    return FormValidator.getFieldErrorMessage(value.trim(), isRequired);
  }
}
