import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { paths } from "@/consts/routes";
import { UKRegistryService } from "@/services/v2";
import { RootState, useAppDispatch } from "@/app/store";
import {
  setSearchQuery as setRegistrySearchQuery,
  toggleSetCadastralNumberModal,
} from "@/app/store/slices/uk/registry";
import { activeAddress } from "@/app/store/slices/user";
import {
  setSearchQuery as setRosreestrSearchQuery,
  toggleSystemMessage,
  toggleSystemMessageLoading,
  updateApartmentsComparisonOnBackground,
} from "@/app/store/slices/uk/rosreestr";
import BaseButton from "@/components/base-button";
import BaseQuerySearch from "@/components/base-query-search";
import SegmentedСontrols from "@/components/SegmentedСontrols";
import AcceptTotalAreaModal from "./modals/AcceptTotalAreaModal";
import SetCadastralNumberModal from "./modals/SetCadastralNumberModal";
import RosreestrDataLoadStartedModal from "./modals/RosreestrDataLoadStartedModal";
import RegistryInstructionsModal from "@/features/RegistryInstructionsModal";
import SystemMessage from "@/features/SystemMessage";
import styles from "./styles.module.scss";
import { SnackbarType, showSnackbar } from "@/app/store/slices/snackbar";

const UKRegistry = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const address = useSelector(activeAddress);
  const registryQuery = useSelector((state: RootState) => state.ukRegistry.query);
  const rosreestrQuery = useSelector((state: RootState) => state.ukRosreestr.query);
  const systemMessage = useSelector((state: RootState) => state.ukRosreestr.systemMessage);

  const [isInstructionsModalOpen, setInstructionsModalOpen] = useState<boolean>(false);

  function handleRegistryQueryChange(value: string) {
    dispatch(setRegistrySearchQuery(value));
  }

  function handleRosreestrQueryChange(value: string) {
    dispatch(setRosreestrSearchQuery(value));
  }

  function closeSystemMessage() {
    dispatch(toggleSystemMessage({ isShown: false }));
  }

  async function saveReestrMissingData() {
    dispatch(toggleSystemMessageLoading(true));
    try {
      if (!address) {
        throw Error("no address");
      }

      await UKRegistryService.saveReestrMissingData(address.FiasId);
      await dispatch(updateApartmentsComparisonOnBackground()).unwrap();
      dispatch(toggleSystemMessage({ isShown: false }));
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "save-reestr-missing-data",
          body: error?.response?.data?.Data?.Message ?? "Неизвестная ошибка. Попробуйте позже еще раз",
          type: SnackbarType.ERROR,
        })
      );
    } finally {
      dispatch(toggleSystemMessageLoading(false));
    }
  }

  useEffect(() => {
    return () => {
      dispatch(toggleSetCadastralNumberModal(false));
    };
  }, []);

  return (
    <div>
      {location.pathname === paths.uk().fullPath().registry.rosreestr && (
        <SystemMessage
          name={`rosreestr--address-${address?.Id ?? "null"}`}
          date={systemMessage.date ? new Date(systemMessage.date).formatDateTime() : ""}
          isOpen={systemMessage.isShown}
          onClose={closeSystemMessage}
        >
          <div className={styles.rosreestr_message}>
            <h4 className="headline-h4 sf-text-bold color--text-primary">
              Найдены помещения, которых нет в вашем реестре
            </h4>
            {systemMessage.apartments.length > 0 && (
              <p className="text--primary sf-text-regular color--text-primary">
                {systemMessage.apartments.map((it) => it.Number).join(", ")}
                {systemMessage.pagination?.Count && systemMessage.pagination.Count > 1
                  ? ` и еще ${systemMessage.pagination.Count * systemMessage.pagination.Limit - 20}+`
                  : ""}
              </p>
            )}
            <BaseButton isLoading={systemMessage.isLoading} onClick={saveReestrMissingData}>
              Добавить помещения
            </BaseButton>
          </div>
        </SystemMessage>
      )}

      <div className={styles.header}>
        <h1 className="title-h1">Реестр собственников</h1>
        <div className={styles.header_actions}>
          {location.pathname === paths.uk().fullPath().registry.rosreestr ? (
            <div>
              <BaseQuerySearch
                style={{ width: "340px" }}
                value={rosreestrQuery}
                onChange={handleRosreestrQueryChange}
                placeholder="Поиск по номеру помещения"
              />
            </div>
          ) : (
            <>
              <BaseButton variant="tertiary" onClick={() => setInstructionsModalOpen(true)}>
                <span className={styles.instruction_btn}>Как загрузить реестр?</span>
              </BaseButton>

              <div>
                <BaseQuerySearch
                  style={{ width: "340px" }}
                  value={registryQuery}
                  onChange={handleRegistryQueryChange}
                  placeholder="Поиск по данным собственника"
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.tabs_wrapper}>
        <SegmentedСontrols
          list={[
            {
              text: "Мой реестр",
              link: paths.uk().fullPath().registry.main,
            },
            {
              text: "Данные из Росреестра",
              link: paths.uk().fullPath().registry.rosreestr,
            },
          ]}
        />
      </div>

      <Outlet />

      <AcceptTotalAreaModal />
      <SetCadastralNumberModal />
      <RosreestrDataLoadStartedModal />

      <RegistryInstructionsModal
        isOpen={isInstructionsModalOpen}
        title="Как загрузить реестр?"
        templateLink="/documents/template.xlsx"
        instructionsLink="/Инструкция_Шаблон_загрузки_реестра_собственников.pdf"
        onClose={() => setInstructionsModalOpen(false)}
      >
        Для работы системы необходимо загрузить реестр собственников, составленный по шаблону для заполнения.
        Ознакомиться с инструкцией по заполнению шаблона вы можете скачав соответствующий файл.
      </RegistryInstructionsModal>
    </div>
  );
};

export default UKRegistry;
