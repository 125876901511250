import { Pagination } from "@/types";
import { useCallback, useMemo, useState } from "react";

const useInfiniteScroll = ({ pagination }: { pagination?: Pagination } = {}) => {
  const [observer, setOserver] = useState<IntersectionObserver | null>(null);
  const [isIntersecting, setIntersecting] = useState<boolean>(false);

  const hasMore = useMemo(() => pagination && pagination.Index < pagination.Count, [pagination]);

  const measureRef = useCallback((node: any) => {
    if (node) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIntersecting(entry.isIntersecting);
        },
        { root: null, rootMargin: "0px", threshold: 0 }
      );

      observer.observe(node);
      setOserver(observer);
    }
  }, []);

  return { measureRef, isIntersecting, observer, hasMore };
};

export default useInfiniteScroll;
