import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar, hideSnackbar, SnackbarType } from "../../app/store/slices/snackbar";

const NetworkListener = () => {
  const dispatch = useDispatch();
  const EVENTS = ["online", "offline", "load"];

  function showNetworkError(value: boolean) {
    const key = "network-error";
    if (!value) {
      return dispatch(hideSnackbar(key));
    }

    return dispatch(
      showSnackbar({
        key,
        title: "Нет сети",
        body: "Подключение к системе отсутствует",
        duration: "infinite",
        type: SnackbarType.NETWORK_ERROR,
      })
    );
  }

  function updateOnlineStatus() {
    fetch(String(process.env.REACT_APP_HOST), {
      method: "GET",
      mode: "no-cors",
    })
      .then(() => {
        showNetworkError(false);
      })
      .catch((e) => {
        showNetworkError(true);
      });
  }

  useEffect(() => {
    EVENTS.forEach((event) => window.addEventListener(event, updateOnlineStatus));

    return () => {
      EVENTS.forEach((event) => window.removeEventListener(event, updateOnlineStatus));
    };
  }, []);

  return null;
};

export default NetworkListener;
