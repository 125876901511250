import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { SntPassportService } from "@/services/v2";
import { SntPassportAgricultureMinistry, SntPassportEnums } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import { fetchSntPassportDictionaries } from "@/app/store/slices/snt/passport";
import { activeAddress } from "@/app/store/slices/user";
import useEmptyBlockHeight from "@/hooks/useEmptyBlockHeight";
import SntPassportYearsFilter from "@/components/SntPassport/YearsFilter";
import SntPassportContent from "@/components/SntPassport/Content";
import SntPassportBlockWrapper from "@/components/SntPassport/BlockWrapper";
import SntPassportBlockContentReadonly from "@/components/SntPassport/BlockContent/Readonly";
import { Spinner } from "@/components/spinner";
import styles from "./styles.module.scss";

const SntPassportAgricultureMinistryPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const { emptyBlockHeight, emptyBlockRef } = useEmptyBlockHeight();

  const address = useSelector(activeAddress);
  const { roadSurfaceTypesList, externalInfrastructureWorkTypesList, internalInfrastructureWorkTypesList } =
    useSelector((state: RootState) => state.sntPassport);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [agricultureMinistryData, setAgricultureMinistryData] =
    useState<SntPassportAgricultureMinistry.AgricultureMinistryDataResponse>();

  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    let years = [];
    for (let i = currentYear; i >= 2020; i--) {
      years.push(i);
    }
    return years;
  }, []);

  const filter = useMemo(() => {
    const params = searchParams.get("year");
    return params ? parseInt(params) : years[0];
  }, [years, searchParams]);

  function handleFilterChange(year: number) {
    setSearchParams({ year: year.toString() });
  }

  async function fetchData() {
    if (!address?.Id) {
      return;
    }

    setIsLoading(true);

    try {
      await dispatch(fetchSntPassportDictionaries()).unwrap();
      const { data } = await SntPassportService.getAgricultureMinistryData(address?.Id, filter);
      setAgricultureMinistryData(data.Data);
      return data.Data.Form;
    } catch (error: any) {
      setAgricultureMinistryData(undefined);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [filter]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", padding: "12px 0", minHeight: "48px" }}>
        <h3 className="headline-h3 sf-bold color--text-primary">Данные по государственной поддержке</h3>
      </div>

      <SntPassportYearsFilter value={filter} options={years} onChange={handleFilterChange} />

      {isLoading ? (
        <div
          ref={emptyBlockRef}
          className="center"
          style={{ display: "flex", alignItems: "center", height: `${emptyBlockHeight}px`, minHeight: "350px" }}
        >
          <Spinner size={36} />
        </div>
      ) : agricultureMinistryData?.Form && agricultureMinistryData.PublicationDatetime ? (
        <div className={styles["p-agriculture-ministry"]}>
          {agricultureMinistryData.PublicationDatetime && (
            <div className={styles["p-agriculture-ministry__publication"]}>
              <p className="text--primary color--text-secondary sf-text-regular">
                Последнее обновление{" "}
                {new Date(agricultureMinistryData.PublicationDatetime.parseFromEpochSeconds()).formatDateTime()}
              </p>
            </div>
          )}

          <SntPassportContent>
            {agricultureMinistryData?.Form.ExternalInfrastructure ? (
              <SntPassportBlockWrapper title="Внешняя инфраструктура">
                {Object.keys(agricultureMinistryData?.Form.ExternalInfrastructure).map(
                  (key, index) =>
                    agricultureMinistryData?.Form.ExternalInfrastructure && (
                      <div key={index}>
                        <SntPassportBlockContentReadonly>
                          <div>
                            <h4 className="headline-h4 sf-text-bold color--text-primary">Вид работ:</h4>
                            <p className="text--primary sf-text-regular color--text-secondary">
                              {key === SntPassportEnums.ExternalInfrastructureWorkType.Other &&
                              agricultureMinistryData?.Form?.ExternalInfrastructure?.Other?.Name
                                ? agricultureMinistryData?.Form?.ExternalInfrastructure?.Other?.Name
                                : key
                                ? externalInfrastructureWorkTypesList.find((it) => it.Key === key)?.Value ?? "–"
                                : "–"}
                            </p>
                          </div>
                          <div>
                            <h4 className="headline-h4 sf-text-bold color--text-primary">
                              Наименования документа основания:
                            </h4>
                            <p className="text--primary sf-text-regular color--text-secondary">
                              {agricultureMinistryData.Form.ExternalInfrastructure[
                                key as keyof typeof agricultureMinistryData.Form.ExternalInfrastructure
                              ]?.BasisDocumentName ?? "–"}
                            </p>
                          </div>

                          {key === SntPassportEnums.ExternalInfrastructureWorkType.ElectricitySupplyRepair && (
                            <>
                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">
                                  ЛЭП, объем работ (км.):
                                </h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {(
                                    agricultureMinistryData.Form.ExternalInfrastructure[
                                      key as keyof typeof agricultureMinistryData.Form.ExternalInfrastructure
                                    ] as SntPassportAgricultureMinistry.ElectricitySupplyRepairWorkType
                                  )?.LEPWorkScope?.formatRU() ?? "–"}
                                </p>
                              </div>

                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">
                                  КТП, объем работ (шт.):
                                </h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {(
                                    agricultureMinistryData.Form.ExternalInfrastructure[
                                      key as keyof typeof agricultureMinistryData.Form.ExternalInfrastructure
                                    ] as SntPassportAgricultureMinistry.ElectricitySupplyRepairWorkType
                                  )?.KTPWorkScope?.formatRU() ?? "–"}
                                </p>
                              </div>
                            </>
                          )}

                          {key === SntPassportEnums.ExternalInfrastructureWorkType.WaterSupplyRepair && (
                            <>
                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">
                                  Скважина, объем работ (шт.):
                                </h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {(
                                    agricultureMinistryData.Form.ExternalInfrastructure[
                                      key as keyof typeof agricultureMinistryData.Form.ExternalInfrastructure
                                    ] as SntPassportAgricultureMinistry.WaterSupplyRepairWorkType
                                  )?.WellWorkScope?.formatRU() ?? "–"}
                                </p>
                              </div>

                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">
                                  ВНБ и резервуар, объем работ (шт.):
                                </h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {(
                                    agricultureMinistryData.Form.ExternalInfrastructure[
                                      key as keyof typeof agricultureMinistryData.Form.ExternalInfrastructure
                                    ] as SntPassportAgricultureMinistry.WaterSupplyRepairWorkType
                                  )?.ReservoirWorkScope?.formatRU() ?? "–"}
                                </p>
                              </div>

                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">
                                  Насос, объем работ (шт.):
                                </h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {(
                                    agricultureMinistryData.Form.ExternalInfrastructure[
                                      key as keyof typeof agricultureMinistryData.Form.ExternalInfrastructure
                                    ] as SntPassportAgricultureMinistry.WaterSupplyRepairWorkType
                                  )?.PumpWorkScope?.formatRU() ?? "–"}
                                </p>
                              </div>

                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">
                                  Водопровод, объем работ (км.):
                                </h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {(
                                    agricultureMinistryData.Form.ExternalInfrastructure[
                                      key as keyof typeof agricultureMinistryData.Form.ExternalInfrastructure
                                    ] as SntPassportAgricultureMinistry.WaterSupplyRepairWorkType
                                  )?.WaterPipesWorkScope?.formatRU() ?? "–"}
                                </p>
                              </div>
                            </>
                          )}

                          {key === SntPassportEnums.ExternalInfrastructureWorkType.TKORepair && (
                            <>
                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">
                                  Площадка ТКО, объем работ (шт.):
                                </h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {(
                                    agricultureMinistryData.Form.ExternalInfrastructure[
                                      key as keyof typeof agricultureMinistryData.Form.ExternalInfrastructure
                                    ] as SntPassportAgricultureMinistry.TKORepairWorkType
                                  )?.TKOAreaWorkScope?.formatRU() ?? "–"}
                                </p>
                              </div>

                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">
                                  Бункер, объем работ (шт.):
                                </h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {(
                                    agricultureMinistryData.Form.ExternalInfrastructure[
                                      key as keyof typeof agricultureMinistryData.Form.ExternalInfrastructure
                                    ] as SntPassportAgricultureMinistry.TKORepairWorkType
                                  )?.BunkerWorkScope?.formatRU() ?? "–"}
                                </p>
                              </div>

                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">
                                  Контейнер, объем работ (шт.):
                                </h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {(
                                    agricultureMinistryData.Form.ExternalInfrastructure[
                                      key as keyof typeof agricultureMinistryData.Form.ExternalInfrastructure
                                    ] as SntPassportAgricultureMinistry.TKORepairWorkType
                                  )?.ContainerWorkScope?.formatRU() ?? "–"}
                                </p>
                              </div>
                            </>
                          )}

                          {[
                            SntPassportEnums.ExternalInfrastructureWorkType.RoadRepair.toString(),
                            SntPassportEnums.ExternalInfrastructureWorkType.Other.toString(),
                          ].includes(key) && (
                            <div>
                              <h4 className="headline-h4 sf-text-bold color--text-primary">Объем работ (км.,шт.):</h4>
                              <p className="text--primary sf-text-regular color--text-secondary">
                                {(
                                  agricultureMinistryData.Form.ExternalInfrastructure[
                                    key as keyof typeof agricultureMinistryData.Form.ExternalInfrastructure
                                  ] as SntPassportAgricultureMinistry.OtherWorkType
                                )?.WorkScope?.formatRU() ?? "–"}
                              </p>
                            </div>
                          )}

                          <div>
                            <h4 className="headline-h4 sf-text-bold color--text-primary">Стоимость работ (₽):</h4>
                            <p className="text--primary sf-text-regular color--text-secondary">
                              {agricultureMinistryData.Form.ExternalInfrastructure[
                                key as keyof typeof agricultureMinistryData.Form.ExternalInfrastructure
                              ]?.WorkCost?.formatCurrency() ?? "–"}
                            </p>
                          </div>
                          {key === SntPassportEnums.ExternalInfrastructureWorkType.RoadRepair && (
                            <>
                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">СКДФ:</h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {agricultureMinistryData.Form.ExternalInfrastructure?.RoadRepair?.SCDF ?? "–"}
                                </p>
                              </div>

                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">Протяженность (м, км):</h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {agricultureMinistryData.Form.ExternalInfrastructure?.RoadRepair?.RoadLength?.formatRU() ??
                                    "–"}
                                </p>
                              </div>

                              <div className="full-field">
                                <h4 className="headline-h4 sf-text-bold color--text-primary">Покрытие:</h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {agricultureMinistryData.Form.ExternalInfrastructure?.RoadRepair?.SurfaceTypeKey ===
                                    SntPassportEnums.RoadSurfaceType.Other &&
                                  agricultureMinistryData.Form.ExternalInfrastructure?.RoadRepair?.SurfaceTypeKeyOther
                                    ? agricultureMinistryData.Form.ExternalInfrastructure?.RoadRepair
                                        ?.SurfaceTypeKeyOther
                                    : agricultureMinistryData.Form.ExternalInfrastructure?.RoadRepair?.SurfaceTypeKey
                                    ? roadSurfaceTypesList.find(
                                        (it) =>
                                          it.Key ===
                                          agricultureMinistryData.Form.ExternalInfrastructure?.RoadRepair
                                            ?.SurfaceTypeKey
                                      )?.Value ?? "–"
                                    : "–"}
                                </p>
                              </div>
                            </>
                          )}

                          <div className="full-field">
                            <h4 className="headline-h4 sf-text-bold color--text-primary">Примечание:</h4>
                            <p className="text--primary sf-text-regular color--text-secondary">
                              {agricultureMinistryData.Form.ExternalInfrastructure[
                                key as keyof typeof agricultureMinistryData.Form.ExternalInfrastructure
                              ]?.Note ?? "–"}
                            </p>
                          </div>
                        </SntPassportBlockContentReadonly>
                      </div>
                    )
                )}
              </SntPassportBlockWrapper>
            ) : null}

            {agricultureMinistryData?.Form.InternalInfrastructure ? (
              <SntPassportBlockWrapper title="Внутренняя инфраструктура">
                {Object.keys(agricultureMinistryData?.Form.InternalInfrastructure).map(
                  (key, index) =>
                    agricultureMinistryData?.Form.InternalInfrastructure && (
                      <div key={index}>
                        <SntPassportBlockContentReadonly>
                          <div>
                            <h4 className="headline-h4 sf-text-bold color--text-primary">Вид работ:</h4>
                            <p className="text--primary sf-text-regular color--text-secondary">
                              {key === SntPassportEnums.InternalInfrastructureWorkType.Other &&
                              agricultureMinistryData?.Form?.InternalInfrastructure?.Other?.Name
                                ? agricultureMinistryData?.Form?.InternalInfrastructure?.Other?.Name
                                : key
                                ? internalInfrastructureWorkTypesList.find((it) => it.Key === key)?.Value ?? "–"
                                : "–"}
                            </p>
                          </div>

                          <div>
                            <h4 className="headline-h4 sf-text-bold color--text-primary">
                              Наименования документа основания:
                            </h4>
                            <p className="text--primary sf-text-regular color--text-secondary">
                              {agricultureMinistryData.Form.InternalInfrastructure[
                                key as keyof typeof agricultureMinistryData.Form.InternalInfrastructure
                              ]?.BasisDocumentName ?? "–"}
                            </p>
                          </div>

                          {key === SntPassportEnums.InternalInfrastructureWorkType.ElectricitySupplyRepair ? (
                            <>
                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">
                                  ЛЭП, объем работ (км.):
                                </h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {(
                                    agricultureMinistryData.Form.InternalInfrastructure[
                                      key as keyof typeof agricultureMinistryData.Form.InternalInfrastructure
                                    ] as SntPassportAgricultureMinistry.InternalElectricitySupplyRepairWorkType
                                  )?.LEPWorkScope?.formatRU() ?? "–"}
                                </p>
                              </div>

                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">
                                  АСКУЭ, объем работ (шт.):
                                </h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {(
                                    agricultureMinistryData.Form.InternalInfrastructure[
                                      key as keyof typeof agricultureMinistryData.Form.InternalInfrastructure
                                    ] as SntPassportAgricultureMinistry.InternalElectricitySupplyRepairWorkType
                                  )?.ASKUEWorkScope?.formatRU() ?? "–"}
                                </p>
                              </div>

                              <div>
                                <h4 className="headline-h4 sf-text-bold color--text-primary">
                                  КТП, объем работ (шт.):
                                </h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {(
                                    agricultureMinistryData.Form.InternalInfrastructure[
                                      key as keyof typeof agricultureMinistryData.Form.InternalInfrastructure
                                    ] as SntPassportAgricultureMinistry.InternalElectricitySupplyRepairWorkType
                                  )?.KTPWorkScope?.formatRU() ?? "–"}
                                </p>
                              </div>
                            </>
                          ) : (
                            <div>
                              <h4 className="headline-h4 sf-text-bold color--text-primary">
                                {key === SntPassportEnums.InternalInfrastructureWorkType.InnerRoadRepair
                                  ? "Протяженность (м, км)"
                                  : key === SntPassportEnums.InternalInfrastructureWorkType.WaterSupplyRepair
                                  ? "Объем работ (км.)"
                                  : "Объем работ (км.,шт.)"}
                                :
                              </h4>
                              <p className="text--primary sf-text-regular color--text-secondary">
                                {(
                                  agricultureMinistryData.Form.InternalInfrastructure[
                                    key as keyof typeof agricultureMinistryData.Form.InternalInfrastructure
                                  ] as SntPassportAgricultureMinistry.OtherWorkType
                                )?.WorkScope?.formatRU() ?? "–"}
                              </p>
                            </div>
                          )}

                          <div>
                            <h4 className="headline-h4 sf-text-bold color--text-primary">Стоимость работ (₽):</h4>
                            <p className="text--primary sf-text-regular color--text-secondary">
                              {agricultureMinistryData.Form.InternalInfrastructure[
                                key as keyof typeof agricultureMinistryData.Form.InternalInfrastructure
                              ]?.WorkCost?.formatCurrency() ?? "–"}
                            </p>
                          </div>

                          {key === SntPassportEnums.InternalInfrastructureWorkType.InnerRoadRepair && (
                            <>
                              <div className="full-field">
                                <h4 className="headline-h4 sf-text-bold color--text-primary">Покрытие:</h4>
                                <p className="text--primary sf-text-regular color--text-secondary">
                                  {agricultureMinistryData.Form.InternalInfrastructure?.InnerRoadRepair
                                    ?.SurfaceTypeKey === SntPassportEnums.RoadSurfaceType.Other &&
                                  agricultureMinistryData.Form.InternalInfrastructure?.InnerRoadRepair
                                    ?.SurfaceTypeKeyOther
                                    ? agricultureMinistryData.Form.InternalInfrastructure?.InnerRoadRepair
                                        ?.SurfaceTypeKeyOther
                                    : agricultureMinistryData.Form.InternalInfrastructure?.InnerRoadRepair
                                        ?.SurfaceTypeKey
                                    ? roadSurfaceTypesList.find(
                                        (it) =>
                                          it.Key ===
                                          agricultureMinistryData.Form.InternalInfrastructure?.InnerRoadRepair
                                            ?.SurfaceTypeKey
                                      )?.Value ?? "–"
                                    : "–"}
                                </p>
                              </div>
                            </>
                          )}

                          <div className="full-field">
                            <h4 className="headline-h4 sf-text-bold color--text-primary">Примечание:</h4>
                            <p className="text--primary sf-text-regular color--text-secondary">
                              {agricultureMinistryData.Form.InternalInfrastructure[
                                key as keyof typeof agricultureMinistryData.Form.InternalInfrastructure
                              ]?.Note ?? "–"}
                            </p>
                          </div>
                        </SntPassportBlockContentReadonly>
                      </div>
                    )
                )}
              </SntPassportBlockWrapper>
            ) : null}
          </SntPassportContent>
        </div>
      ) : (
        <div
          ref={emptyBlockRef}
          className="center"
          style={{ display: "flex", alignItems: "center", height: `${emptyBlockHeight}px`, minHeight: "350px" }}
        >
          <p className="text--primary sf-pro-regular color--text-primary">Данных от Минсельхоза пока нет</p>
        </div>
      )}
    </div>
  );
};

export default SntPassportAgricultureMinistryPage;
