import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import { toggleHouseDetailsUpdatingModal } from "@/app/store/slices/uk/rosreestr";
import BaseModal, { BaseModalActions, BaseModalHeader } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";

const HouseDetailsUpdatingModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const isOpen = useSelector((state: RootState) => state.ukRosreestr.isHouseDetailsUpdatingModalShown);

  function onClose() {
    dispatch(toggleHouseDetailsUpdatingModal(false));
  }

  return (
    <BaseModal isOpen={isOpen} minWidth="520px">
      <BaseModalHeader
        variant="info-iconed"
        iconBgColor="#E6E8EB"
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M12.5 8C12.5 7.44772 12.0523 7 11.5 7C10.9477 7 10.5 7.44772 10.5 8V12.5C10.5 12.9168 10.7586 13.29 11.1489 13.4363L15.1489 14.9363C15.666 15.1302 16.2424 14.8682 16.4363 14.3511C16.6302 13.834 16.3682 13.2576 15.8511 13.0637L12.5 11.807V8Z"
              fill="#99A0AD"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
              fill="#99A0AD"
            />
          </svg>
        }
        title="Данные по помещению обновляются, это может занять 1-2 дня"
        onClose={onClose}
      />

      <BaseModalActions>
        <BaseButton onClick={onClose}>Понятно</BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default HouseDetailsUpdatingModal;
