import { Box } from "@mui/material";
import styles from "./contacts.module.scss";
import { Profile, UsefulContacts } from "../../types";

const ContactExpanded = ({ company }: { company: UsefulContacts.UsefulContactUnitedType }) => {
  return (
    <div className={styles.container}>
      <Box className={styles.item}>
        <span>Полное наименование компании:</span>
        <p>{company.Name}</p>
      </Box>

      {company.Address && (
        <Box className={styles.item}>
          <span>Адрес:</span>
          <p>{company.Address}</p>
        </Box>
      )}

      <Box className={styles.item}>
        <span>Телефон:</span>
        <p>{(company as UsefulContacts.Item).PhoneNum ?? (company as Profile.CompanyData).Phone}</p>
      </Box>

      {(company as Profile.CompanyData).Site && (
        <Box className={styles.item}>
          <span>Сайт:</span>
          <p>{(company as Profile.CompanyData).Site}</p>
        </Box>
      )}

      {company.Email && (
        <Box className={styles.item}>
          <span>Электронная почта:</span>
          <p>{company.Email}</p>
        </Box>
      )}
    </div>
  );
};

export default ContactExpanded;
