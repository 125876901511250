import { useState } from "react";
import { useNavigate } from "react-router";
import { OCCV2Order } from "@/types";
import DraftsList from "@/features/CreateOccNew/DraftsList";
import CreateOCCCheckHouseModal from "@/features/CreateOccNew/CheckHouseModal";
import { paths } from "@/consts/routes";

const UKCreateOCC: React.FC = () => {
  const navigate = useNavigate();

  const [isHouseCheckModalOpen, setHouseCheckModalOpen] = useState<boolean>(false);
  const [isEmptyRegistryModalOpen, setEmptyRegistryModalOpen] = useState<boolean>(false);

  function onSelectDraft(occ: OCCV2Order.Item) {
    navigate(paths.uk().fullPath().createOCC.item.create(occ.Id), { state: occ.Id });
  }

  async function onCreateOcc() {
    setHouseCheckModalOpen(true);
  }

  return (
    <div>
      <DraftsList
        isEmptyRegistryModalOpen={isEmptyRegistryModalOpen}
        createOcc={onCreateOcc}
        toggleEmptyRegistryModal={setEmptyRegistryModalOpen}
        onSelectDraft={(occ) => onSelectDraft(occ)}
      />

      <CreateOCCCheckHouseModal isOpen={isHouseCheckModalOpen} onClose={() => setHouseCheckModalOpen(false)} />
    </div>
  );
};

export default UKCreateOCC;
