import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import { setDeleteOpen, removeMarker } from "@/app/store/slices/snt/sntsMap";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";
import BaseButton from "@/components/base-button";

const DeleteMapMakerModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const { selected } = useSelector((state: RootState) => state.sntsMap);

  async function onClose() {
    dispatch(setDeleteOpen(false));
  }

  async function deleteMarker() {
    if (!selected.snt?.Id) {
      return;
    }

    dispatch(removeMarker());
  }

  return (
    <BaseModal isOpen={!!selected.snt && selected.isDeleteOpen} minWidth="426px" maxWidth="426px">
      <BaseModalHeader title="Удалить метку с карты?" onClose={() => onClose()} />

      <BaseModalContent>
        <p className="text--primary sf-text-regular">Вы уверены что хотите удалить ранее проставленную метку?</p>
      </BaseModalContent>

      <BaseModalActions>
        <BaseButton variant="secondary" onClick={() => onClose()}>
          Отменить
        </BaseButton>

        <BaseButton isLoading={selected.isDeleteLoading} disabled={!selected.snt?.Id} onClick={deleteMarker}>
          Удалить
        </BaseButton>
      </BaseModalActions>
    </BaseModal>
  );
};

export default DeleteMapMakerModal;
