import { useRef } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import BaseModalActions from "./actions";
import BaseModalHeader from "./header";
import BaseModalContent from "./content";
import styles from "./styles.module.scss";

interface IBaseModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  minWidth?: string;
  maxWidth?: string;
  onEntered?: () => void;
  onEnter?: () => void;
}

const BaseModal: React.FC<IBaseModalProps> = ({
  children,
  isOpen,
  minWidth = "420px",
  maxWidth = "420px",
  onEntered,
  onEnter,
}) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  function handleOnEntered() {
    if (onEntered) {
      onEntered();
    }
  }

  function handleOnEnter() {
    if (onEnter) {
      onEnter();
    }
  }

  return createPortal(
    <CSSTransition
      in={isOpen}
      nodeRef={nodeRef}
      timeout={300}
      classNames={{ ...styles }}
      unmountOnExit
      onEntered={handleOnEntered}
      onEnter={handleOnEnter}
    >
      <div ref={nodeRef} className={styles.base}>
        <div className={styles.backdrop}></div>
        <div className={styles.wrapper} style={{ minWidth, maxWidth }}>
          {children}
        </div>
      </div>
    </CSSTransition>,
    document.body
  );
};

export default BaseModal;
export { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions };
