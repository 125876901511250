import { House } from "./house";
import { OCC } from "./occ";

export namespace Profile {
  export type AuthResponse = {
    Code: string;
    Token: string;
  };

  export enum UserRole {
    Company = "company",
    Institution = "institution",
  }

  export enum CompanyType {
    UK = "uk",
    SNT = "snt",
  }

  export enum InstitutionType {
    AgricultureMinistry = "AGRICULTURE_MINISTRY",
    Municipality = "MUNICIPALITY",
  }

  export type User = {
    id: number;
    name: string;
    email: string;
    role: UserRole;
    api_user_id: number;
    active_address: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    phone_num: string;
    company_id?: number;
    company_type?: CompanyType;
    institution_id?: number | null;
    institution_type?: InstitutionType | null;
  };

  export type CompanyData = {
    Id: number;
    Name: string;
    Type: CompanyType;
    LegalForm: string;
    OGRN: string;
    FoundationAgreementDetails: string;
    Phone: string;
    Email: string;
    Site: string;
    Address: string;
    Addresses: Array<House.Item>;
  };

  export type InstitutionData = {
    Id: string;
    Name: string;
    Type: InstitutionType;
    CreatedAt: string | null;
    Site: string;
    Address: string;
    MunicipalDistricts: Array<MunicipalDistrict>;
  };

  export type MunicipalDistrict = {
    Id: number;
    Name: string;
  };

  export type CadastralNumber = {
    AddressId: House.Item["Id"];
    CadastralNumber: House.Item["CadastralNumber"];
    Status: {
      Key: CadastralNumberStatus;
      Value: string;
    };
  };

  export enum CadastralNumberStatus {
    New = "NEW",
    Confirmed = "CONFIRMED",
    Canceled = "CANCELED",
  }

  export type HousesWithActualOCC = {
    Address: House.Item["Address"];
    FiasId: House.Item["FiasId"];
    Reestr: {
      RosreestrRequestExist: boolean;
      ApartmentsCount: number;
      OwnersCount: number;
    };
    Lokolo: {
      UsersCount: number;
      ConfirmedOwnersCount: number;
    };
    Occ: {
      Id: OCC.OccOrderBase["Id"];
      Status: OCC.OccOrderItem["Status"];
      StatusId: OCC.OccOrderBase["StatusId"];
      VotingPeriod: string;
    } | null;
  };
}
