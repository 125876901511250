import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router";
import { SntPassport } from "@/types";
import { SntPassportService } from "@/services/v2";

const InstitutionSntItem: React.FC = () => {
  const urlParams = useParams();

  const [sntData, setSntData] = useState<SntPassport.InstitutionSntItem>();

  async function fetchSnt() {
    if (!urlParams.sntId) {
      return;
    }

    try {
      const { data } = await SntPassportService.getSntById(parseInt(urlParams.sntId));
      setSntData(data.Data);
    } catch (error: any) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchSnt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet context={{ sntData }} />;
};

export default InstitutionSntItem;
