import { useSelector } from "react-redux";
import { HouseUsers } from "@/types/house-users";
import { sntRoleIcon, ukRoleIcon } from "@/consts/icons";
import { isCompanyTypeUK } from "@/app/store/slices/user";
import styles from "./styles.module.scss";

type IUserCardProps = {
  user: HouseUsers.User;
};

const UserCard: React.FC<IUserCardProps> = ({ user }) => {
  const isUK = useSelector(isCompanyTypeUK);

  return (
    <div className={styles.card}>
      <div>
        <div className={styles.avatar}>
          <div>
            <img src={user.Avatar} alt="" />
          </div>
          {user.Role && <span className={styles.role}>{isUK ? ukRoleIcon : sntRoleIcon}</span>}
        </div>
        <div className={styles.user_name}>
          <h4 className="headline-h4 sf-text-bold">{user?.Fio}</h4>
          <p className="text--primary sf-text-regular color--text-primary">{user.Phone.formatPhone()}</p>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
