import { useContext, useEffect, useRef } from "react";
import mapboxGl from "mapbox-gl";
import { SntPassportMap } from "@/types";
import MapContext from "../context";
import { markerIcon, addMarkerIcon } from "./icons";
import "./styles.scss";

export enum MarkerVariant {
  Default = "default",
  AddingNew = "adding-new",
}

interface IMapboxMarkerProps {
  geopoint: SntPassportMap.Geopoint;
  label: string;
  variant?: MarkerVariant;
  selected?: boolean;
  onSelect?: () => void;
}

const MapboxMarker: React.FC<IMapboxMarkerProps> = ({
  geopoint,
  label,
  variant = MarkerVariant.Default,
  selected,
  onSelect,
}) => {
  const context = useContext(MapContext);

  const elementRef = useRef<HTMLDivElement>(null);
  const markerRef = useRef<InstanceType<typeof mapboxGl.Marker>>();

  function onClick() {
    if (onSelect) {
      onSelect();
    }
  }

  useEffect(() => {
    if (!elementRef.current || !context?.map) {
      return;
    }

    markerRef.current = new mapboxGl.Marker(elementRef.current)
      .setLngLat([geopoint.Lon, geopoint.Lat])
      .addTo(context.map);

    return () => {
      markerRef.current?.remove();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geopoint, context?.map]);

  return (
    <div ref={elementRef}>
      <div className={`b-marker b-marker-variant--${variant}`}>
        <div className="b-marker__tooltip" onClick={onClick} title={label}>
          <span className="table--small sf-text-semibold color--text-white">{label}</span>
        </div>
        <span className={selected ? "b-marker--selected" : ""} onClick={onClick}>
          {variant === MarkerVariant.AddingNew ? addMarkerIcon : markerIcon}
        </span>
      </div>
    </div>
  );
};

export default MapboxMarker;
