/* eslint-disable no-extend-native */
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import { ru } from "date-fns/locale";

declare global {
  interface Date {
    isValid: () => boolean;
    formatByPattern: (pattern: string) => string;
    formatDate: () => string;
    formatDateTime: () => string;
    parseToEpochSeconds: () => number;
  }
}

Date.prototype.isValid = function (): boolean {
  return isValid(this);
};

Date.prototype.formatByPattern = function (pattern: string): string {
  return format(this, pattern, { locale: ru });
};

Date.prototype.formatDate = function (): string {
  return this.formatByPattern("dd.MM.yyyy");
};

Date.prototype.formatDateTime = function (): string {
  return this.formatByPattern("dd.MM.yyyy, HH:mm");
};

Date.prototype.parseToEpochSeconds = function (): number {
  return parseInt((this.getTime() / 1000) as any);
};
