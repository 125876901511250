import React, { useEffect, useRef, useState } from "react";
import styles from "./accordion.module.scss";

interface IExpanded {
  children: React.ReactNode;
  isOpen?: boolean;
  style?: React.CSSProperties;
  async?: boolean;
  loaded?: boolean;
}

const Expanded = React.memo(({ style, children, isOpen, async, loaded }: IExpanded) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [transitionDuration, setTransitionDuration] = useState<number>(300);
  const [maxHeight, setMaxHeight] = useState<number>(0);

  useEffect(() => {
    const isLoaded = loaded && async;

    if (isLoaded && containerRef.current) {
      if (isOpen) {
        const height = containerRef.current.scrollHeight;

        setTransitionDuration(300 + height / 2);
        setMaxHeight(height);
      }

      if (!isOpen) {
        setMaxHeight(0);
      }
    }

    if (containerRef.current && !async) {
      if (isOpen) {
        const height = containerRef.current.scrollHeight;
        setTransitionDuration(300 + height / 2);
        setMaxHeight(height);
      } else {
        setMaxHeight(0);
      }
    }
  }, [isOpen, loaded]);

  return (
    <div
      ref={containerRef}
      className={styles.expanded}
      style={{ ...style, maxHeight: `${maxHeight}px`, transitionDuration: `${transitionDuration}ms` }}
    >
      {<div>{children}</div>}
    </div>
  );
});

export { Expanded };
