import { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { NavLinkType } from "../../sidebar";
import { ChevronRight16Icon } from "@/consts/icons-v2";
import { CustomCollapsed, Expanded } from "@/components/base-accordion";
import AccordionContext from "@/components/base-accordion/context";
import NavigationItem from "../";
import styles from "../styles.module.scss";

interface INavigationItemProps {
  link: NavLinkType;
  filterChildren?: (children: NavLinkType[]) => NavLinkType[];
}

const NestedNavigationItem: React.FC<INavigationItemProps> = ({ link, filterChildren }) => {
  const location = useLocation();
  const context = useContext(AccordionContext);

  const children = link.children && filterChildren ? filterChildren(link.children) : link.children;

  useEffect(() => {
    if (context?.opened !== link.text && link.children?.some((it) => it.path === location.pathname)) {
      context?.handleOpen(link.text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="b-navigation-item">
      <CustomCollapsed id={link.text}>
        <div className={styles["b-navigation-item__btn"]}>
          <div>
            <div className={styles["b-navigation-item__btn_icon"]}>{link.icon}</div>
            <span className="sf-text-medium text--primary color--text-primary">{link.text}</span>
          </div>
          <span className={styles["b-navigation-item__btn_chevron"]} role="spinbutton">
            {ChevronRight16Icon("var(--icons-primary)")}
          </span>
        </div>
      </CustomCollapsed>

      <Expanded id={link.text}>
        <div className={styles["b-navigation-item__children"]}>
          {children?.map((it) => (
            <NavigationItem key={it.text} link={it} action={ChevronRight16Icon("var(--icons-primary)")} />
          ))}
        </div>
      </Expanded>
    </div>
  );
};

export default NestedNavigationItem;
